import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {Grid, withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styles
import styles from './styles';
import {pageViewAnalyst} from "../../configurations/config";
import countries from "../../utils/Countries";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import cities from "../../utils/Cities";
import {ExploreEventsRequest} from "../../generated/frontend-community-event-service_pb";
import {exploreEvent} from "../../grpcRequests/events-request";
import Card from "@material-ui/core/Card";
import {Link} from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {formatDate} from "../../utils/converter/dateConverter";
import CircularProgress from "@material-ui/core/CircularProgress";
import {EventsLoader} from "../../components/Skeleton/EventsSket";
import {removeHyphen, removeSpace} from "../../utils/converter/common";
import EventStickyInfo from "../Groups/component/StickyInfo/EventStickyInfo";

const defaultPageSize = 30;

class Explore extends Component {

    state = {
        countryName: this.props.match.params.countryName ? removeHyphen(this.props.match.params.countryName) : '',
        countryCode: '',
        cityName: '',

        myEventLists: [],
        isLoadingEventList: false,
        cursor: '',
        previousCursor: '',
        loadingMore: false,

        searchingMsg: '',

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        pageViewAnalyst('Explore', this.props.location.pathname);
        this.exlpEvents('', defaultPageSize, [], '', this.state.countryName, this.state.cityName);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.match.params.countryName !== this.props.match.params.countryName) {
            this.setState({
                countryName: nextProps.match.params.countryName
            });
            this.reset();
            this.exlpEvents('', defaultPageSize, [], '', nextProps.match.params.countryName, '');
        } else {
            this.setState({
                countryName: '',
                cityName: '',
            });
            this.reset();
            this.exlpEvents('', defaultPageSize, [], '', '', '');
        }
    }

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    handleCountryChange = () => (event, newValue) => {
        if (newValue && newValue.label) {
            this.setState({countryName: newValue.label});
            this.setState({countryCode: newValue.code});
            this.setState({cityName: ''});
            this.reset();
            window.history.pushState('', '', '/explore/' + removeSpace(newValue.label));
            this.exlpEvents('', defaultPageSize, [], '', newValue.label, '');
            return;
        } else {
            this.setState({countryName: ''});
            window.history.pushState('', '', '/explore');
            this.exlpEvents('', defaultPageSize, [], '', '', '');
        }
    };

    handleCityChange = () => (event, newValue) => {
        if (newValue) {
            this.setState({cityName: newValue,});
            this.reset();
            this.exlpEvents('', defaultPageSize, [], '', this.state.countryName, newValue);
            return;
        } else {
            this.setState({cityName: ''});
            this.exlpEvents('', defaultPageSize, [], '', this.state.countryName, '');
        }
    };

    reset = () => {
        this.setState({
            myEventLists: [],
            isLoadingEventList: false,
            cursor: '',
            previousCursor: '',
            loadingMore: false,
            searchingMsg: '',
        })
    };

    loadMore = (cursorValue, pageSize, previousData, prevCursor) => {
        this.setState({loadingMore: true});
        this.exlpEvents(cursorValue, pageSize, previousData, prevCursor, this.state.countryName, this.state.cityName);
    };

    exlpEvents = (cursorValue, pageSize, previousData, prevCursor, country, city) => {
        let self = this;
        self.setState({
            isLoadingEventList: !prevCursor,
        });
        const req = new ExploreEventsRequest();
        req.setCountry(country);
        req.setCity(city);
        req.setNextPageCurse(cursorValue);
        req.setPageSize(pageSize);
        exploreEvent(req, 3).then(function (response) {
            let events = previousData;
            for (let i = 0; i < response.getEventList().length; i++) {
                let newEvent = response.getEventList()[i];
                events.push(newEvent);
            }
            const cursor = response.getNextPageCursor();
            const isLoadingEventList = false;
            self.setState({
                myEventLists: events,
                isLoadingEventList,
                previousCursor: prevCursor,
                cursor,
                loadingMore: false
            });
        }).catch(function (error) {
            const isLoadingEventList = false;
            self.setState({
                isLoadingEventList,
                loadingMore: false
            });
            self.snackbar(true, 'Got Error While fetching User Events', 'error', 5000)
        })
    };

    exploreCommunity() {
        const {history} = this.props;
        let countryName = this.state.countryName ? this.state.countryName : '';
        history.push('/explore/community/' + countryName);
    };

    exploreNewEvents() {
        const {history} = this.props;
        let pushTo = this.state.countryName ? '/' + removeSpace(this.state.countryName) : '';
        history.push('/explore/events' + pushTo);
    };

    render() {
        const {classes} = this.props;
        const {countryName, cityName, countryCode, cursor, isLoadingEventList, loadingMore, myEventLists, previousCursor, searchingMsg} = this.state;
        const rootGroupsURl = '/c/';
        return (
            <DashboardLayout title={`Explore ${countryName ? countryName : ''}`} onBack="/home">
                <div className={classes.root}>
                    <Typography
                        variant="subtitle1"
                        style={{fontWeight: 600, color: '#686868'}}
                    >Explore the events around the world.
                    </Typography>
                    <br/>
                    <div>
                        <Typography
                            component="div"
                            variant="h6"
                            style={{fontWeight: 900}}
                        >
                            <span>Explore </span>
                        </Typography>
                    </div>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                            <Autocomplete
                                value={countryName}
                                id="country-select"
                                style={{width: '100%'}}
                                options={countries}
                                classes={{
                                    option: classes.option,
                                }}
                                getOptionLabel={option => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    return option.label;
                                }}
                                onChange={this.handleCountryChange()}
                                renderOption={option => (
                                    <React.Fragment>
                                        {option.label}
                                    </React.Fragment>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="Select Country"
                                    />
                                )}
                            />
                        </Grid>
                        {countryName ? (
                            <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                                <Autocomplete
                                    value={cityName}
                                    id="city-select"
                                    style={{width: '100%'}}
                                    options={cities[countryName] ? cities[countryName] : []}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    getOptionLabel={option => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        return option;
                                    }}
                                    onChange={this.handleCityChange()}
                                    renderOption={option => (
                                        <React.Fragment>
                                            {option}
                                        </React.Fragment>
                                    )}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Select City"
                                        />
                                    )}
                                />
                            </Grid>
                        ) : null}
                    </Grid>
                    <br/>
                    <div>
                        <div>
                            <div className={classes.floatLeft}>
                                <Typography
                                    variant="h6"
                                    style={{fontWeight: 900}}
                                >
                                    <span>Events </span>
                                    <span>{countryName ? ' in ' + countryName : ''}{cityName ? ',' + cityName : ''}</span>
                                </Typography>
                            </div>
                            <div className={classes.floatRight}>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    onClick={() => this.exploreNewEvents()}
                                    className={classes.buttonView}
                                >
                                    Explore All
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.simpleUnderline}></div>
                        <br/>
                        {!isLoadingEventList ? (
                            <div>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    {myEventLists.map(event => (
                                        <Grid item xs={12} md={4} sm={4} lg={6} xl={4}
                                              key={event.getEventId()}>
                                            <Card className={classes.singleCardEvent}>
                                                <EventStickyInfo event={event}/>
                                                <Link
                                                    to={`${rootGroupsURl}${event.getGroupId()}/events/${event.getEventId()}`}>
                                                    <div className={classes.groupDetailImageWrapper}>
                                                        <CardMedia
                                                            className={classes.mediaEvent}
                                                            alt={event.getTitle()}
                                                            image={event.getEventCoverImage() ? event.getEventCoverImage() : '/images/eventDefault.png'}
                                                            title={event.getTitle()}
                                                        />
                                                    </div>
                                                </Link>
                                                <CardContent className={classes.upComingEventsWrapper}>
                                                    <div className={classes.groupName}>
                                                        <Link
                                                            to={`${rootGroupsURl}${event.getGroupId()}/events/${event.getEventId()}`}>
                                                            <Typography
                                                                component="div"
                                                                className={classes.name}>
                                                                <span>
                                                                    {event.getTitle()}
                                                                </span>
                                                            </Typography>
                                                        </Link>
                                                    </div>
                                                    <Typography variant="body2" className={classes.count}>
                                                        <span>{formatDate(event.getStartFrom(), 'MMM dd, p')}</span>
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                                {cursor && previousCursor !== cursor ? (
                                    <div style={{textAlign: 'center'}}>
                                        <Button variant="contained" size="medium" color="secondary"
                                                style={{margin: '15px 0', width: '300px'}}
                                                onClick={() => this.loadMore(cursor, defaultPageSize, myEventLists, cursor)}
                                                disabled={loadingMore}>
                                            {loadingMore ? (<span> Loading <CircularProgress size={15}/></span>) : (
                                                <span>Load more</span>)}
                                        </Button>
                                    </div>
                                ) : (<span></span>)}
                            </div>
                        ) : (<EventsLoader/>)}
                    </div>
                    {/*<br/>*/}
                    {/*<div>*/}
                    {/*    <div>*/}
                    {/*        <div className={classes.floatLeft}>*/}
                    {/*            <Typography*/}
                    {/*                variant="h6"*/}
                    {/*                style={{fontWeight: 900}}*/}
                    {/*            >*/}
                    {/*                <span>Communities </span> <span>{countryName ? ' in '+ countryName  : ''}{cityName ? ','+ cityName : ''}</span>*/}
                    {/*            </Typography>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className={classes.floatRight}>*/}
                    {/*        <Typography*/}
                    {/*            component="span"*/}
                    {/*            variant="body2"*/}
                    {/*            onClick={() => this.exploreCommunity()}*/}
                    {/*            className={classes.buttonView}*/}
                    {/*        >*/}
                    {/*            Explore All*/}
                    {/*        </Typography>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={classes.simpleUnderline}></div>*/}
                    {/*<br/>*/}
                </div>

            </DashboardLayout>
        );
    }
}

Explore.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Explore);
