import React, {useEffect} from 'react';
import {useTheme, withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Slide from "@material-ui/core/Slide";
import {CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";

const ApiURL = 'https://sag.dallas-hamrostack.com/gameDetail/';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function CountryDetails(props) {
    const [open, setOpen] = React.useState(props.open ? props.open : false);
    const [countryName, setCountryName] = React.useState(props.countryName);
    const [loadingCountryDetails, setLoadingCountryDetails] = React.useState(true);
    const [errMessage, setErrMessage] = React.useState('');
    const [table, setTable] = React.useState(props.tableData ? props.tableData : '');
    const [countryDetailsList, setCountryDetailsList] = React.useState([]);

    useEffect(() => {
        fetchCountryDetails();
    }, []);

    function fetchCountryDetails() {
        setLoadingCountryDetails(true);
        axios.get(ApiURL + countryName).then(function (response) {
            const medalInfo = response.data.participantStat;
            const data = response.data.countryGameInfoList;
            setCountryDetailsList(data);
            if (!props.tableData) setTable(medalInfo);
            setLoadingCountryDetails(false);
        }).catch(function (error) {
            setLoadingCountryDetails(false);
            setErrMessage(error.message)
        });

    }

    const handleClose = () => {
        setOpen(false);
        if (window.location.search) goToPath();
        sendResponseToParent(true);
    };

    function goToPath() {
        let goToURL = window.location.pathname.split("?")[0];
        window.history.pushState('', '', goToURL);
    }

    const sendResponseToParent = (closeStatus) => {
        props.sendCloseResponse(closeStatus);
    };

    const screenWidth = useWidth();
    const isMobile = ['xs'].includes(screenWidth);

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}
                    fullScreen={(screenWidth === 'xs' || screenWidth === 'sm') ? true : false}
                    fullWidth={(screenWidth === 'xs' || screenWidth === 'sm') ? false : true}
                    maxWidth={(screenWidth === 'xs' || screenWidth === 'sm') ? '' : 'md'}
                    TransitionComponent={Transition}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {countryName}
                </DialogTitle>
                <DialogContent dividers>
                    <div>
                        <div>
                            <Typography gutterBottom variant='h6'>
                                Country Medals
                            </Typography>
                            <br/>
                            {table && table.name ? (
                                <div style={{padding: '10px'}}>
                                    <Grid
                                        container
                                        spacing={isMobile ? 2 : 3}
                                        style={isMobile ? {
                                            borderTop: '6px solid #ffffff',
                                            padding: '8px !important',
                                            background: 'aliceblue'
                                        } : {
                                            borderTop: '1px solid #ffffff',
                                            padding: '10px',
                                            background: 'aliceblue'
                                        }}>
                                        <Grid item xs={3} md={4} sm={4} lg={4}>
                                            <Typography component="div" style={{display: 'inline-flex'}}>
                                                <span style={{fontSize: '14px', fontWeight: 600, textAlign: 'center'}}>
                                                    <Avatar alt='Country Flag'
                                                            title="Country Flag"
                                                            style={{
                                                                backgroundImage: `url('${table.flagUrl}')`,
                                                                backgroundRepeat: 'round',
                                                                backgroundColor: 'unset',
                                                                margin: 'auto',
                                                                top: '0',
                                                                width: '30px',
                                                                height: '20px',
                                                                borderRadius: '0',
                                                                display: 'inline-flex'
                                                            }}/>
                                                </span>&nbsp;&nbsp;&nbsp;
                                                {!isMobile ? (
                                                    <span
                                                        style={{
                                                            fontSize: '15px',
                                                            fontWeight: 600
                                                        }}>{table.name}</span>) : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} md={2} sm={2} lg={2}
                                              style={{padding: '10px 5px'}}>
                                            <Typography component="div" style={{display: 'inline-flex'}}>
                                            <span style={{fontSize: '14px', fontWeight: 600, textAlign: 'center'}}>
                                                    <Avatar alt='gold'
                                                            title="Gold Medal"
                                                            style={{
                                                                backgroundImage: `url('/images/icons/Gold.png')`,
                                                                backgroundRepeat: 'round',
                                                                backgroundColor: 'unset',
                                                                margin: 'auto',
                                                                top: '0',
                                                                width: '20px',
                                                                height: '20px',
                                                                display: 'inline-flex'
                                                            }}/>
                                                </span>&nbsp;&nbsp;
                                                <span style={{
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    textAlign: 'center'
                                                }}>{table.gold}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} md={2} sm={2} lg={2}
                                              style={{padding: '10px 5px'}}>
                                            <Typography component="div" style={{display: 'inline-flex'}}>
                                            <span style={{fontSize: '14px', fontWeight: 600, textAlign: 'center'}}>
                                                    <Avatar alt='Silver'
                                                            title="Silver Medal"
                                                            style={{
                                                                backgroundImage: `url('/images/icons/Silver.png')`,
                                                                backgroundRepeat: 'round',
                                                                backgroundColor: 'unset',
                                                                margin: 'auto',
                                                                top: '0',
                                                                width: '20px',
                                                                height: '20px',
                                                                display: 'inline-flex'
                                                            }}/>
                                                </span>&nbsp;&nbsp;
                                                <span style={{
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    textAlign: 'center'
                                                }}>{table.silver}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} md={2} sm={2} lg={2}
                                              style={{padding: '10px 5px'}}>
                                            <Typography component="div" style={{display: 'inline-flex'}}>
                                            <span style={{fontSize: '14px', fontWeight: 600, textAlign: 'center'}}>
                                                    <Avatar alt='Bronze'
                                                            title="Bronze Medal"
                                                            style={{
                                                                backgroundImage: `url('/images/icons/Bronze.png')`,
                                                                backgroundRepeat: 'round',
                                                                backgroundColor: 'unset',
                                                                margin: 'auto',
                                                                top: '0',
                                                                width: '20px',
                                                                height: '20px',
                                                                display: 'inline-flex'
                                                            }}/>
                                                </span>&nbsp;&nbsp;
                                                <span style={{
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    textAlign: 'center'
                                                }}>{table.bronze}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} md={2} sm={2} lg={2}
                                              style={{padding: '10px 5px'}}>
                                            <Typography component="div"
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 600,
                                                            textAlign: 'center'
                                                        }}>
                                                {table.total}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            ) : null}
                        </div>
                        <br/>
                        <br/>
                        <div>
                            {!loadingCountryDetails ? (
                                <div>
                                    <div>
                                        {countryDetailsList.length > 0 ? (
                                            <div>
                                                <CardContent style={{padding: '0'}}>
                                                    <PerfectScrollbar>
                                                        <Typography gutterBottom variant='h6'>
                                                            Medals Details
                                                        </Typography>
                                                        <div style={{minWidth: 800}}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow style={{
                                                                        borderTop: '6px solid #ffffff',
                                                                        padding: '8px !important',
                                                                        background: 'aliceblue'
                                                                    }}>
                                                                        <TableCell style={{
                                                                            fontSize: '16px',
                                                                            fontWeight: 600,
                                                                            padding: '23px 10px'
                                                                        }}> S.N</TableCell>
                                                                        <TableCell style={{
                                                                            fontSize: '16px',
                                                                            fontWeight: 600,
                                                                            padding: '23px 10px'
                                                                        }}>Game Name</TableCell>
                                                                        <TableCell style={{
                                                                            fontSize: '16px',
                                                                            fontWeight: 600,
                                                                            padding: '23px 10px'
                                                                        }}>Gender Stream</TableCell>
                                                                        <TableCell style={{
                                                                            fontSize: '16px',
                                                                            fontWeight: 600,
                                                                            padding: '23px 10px'
                                                                        }}>Medal</TableCell>
                                                                        <TableCell style={{
                                                                            fontSize: '16px',
                                                                            fontWeight: 600,
                                                                            padding: '23px 10px',
                                                                            textAlign: 'center'
                                                                        }}>Event Details</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {countryDetailsList.map((country, index) => (
                                                                        <TableRow
                                                                            hover
                                                                            key={index + 1}
                                                                        >
                                                                            <TableCell style={{
                                                                                fontSize: '15px',
                                                                                padding: '15px 10px'
                                                                            }}>{index + 1}</TableCell>
                                                                            <TableCell style={{
                                                                                fontSize: '15px',
                                                                                padding: '15px 10px',
                                                                                fontWeight: 600
                                                                            }}>
                                                                                {country.gameName}
                                                                            </TableCell>
                                                                            <TableCell style={{
                                                                                fontSize: '15px',
                                                                                padding: '15px 10px'
                                                                            }}>
                                                                                {country.genderStream}
                                                                            </TableCell>
                                                                            <TableCell style={{
                                                                                fontSize: '15px',
                                                                                padding: '15px 10px'
                                                                            }}>
                                                                                {country.medal}
                                                                            </TableCell>
                                                                            <TableCell style={{
                                                                                fontSize: '15px',
                                                                                padding: '15px 10px',
                                                                            }}>
                                                                                {country.eventDetail}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </div>
                                                    </PerfectScrollbar>
                                                </CardContent>
                                            </div>
                                        ) : (<div><span>{errMessage}</span></div>)}
                                    </div>
                                    <br/>
                                </div>
                            ) : (
                                <div style={{minHeight: '70px', textAlign: 'center'}}>
                                    <div><CircularProgress size={20}/></div>
                                    <Typography component="div" variant="body2">
                                        Loading Medal Details
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
