import React from "react";
import * as dateFns from 'date-fns/';
import "./calendar.css";
import {GetGroupEventsRequest} from "../../generated/frontend-community-event-service_pb";
import {getGroupsEvents} from "../../grpcRequests/events-request";
import usedString, {getRoleString} from "../../utils/mapper/enumToStringMapper";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {GetGroupRequest} from "../../generated/frontend-community-group-service_pb";
import {getGroupDetails} from "../../grpcRequests/groups-request";
import {Typography, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./styles";
import {timeEvent} from "../../utils/converter/dateConverter";
import {eventAnalyst} from "../../configurations/config";

let yearEvents = [];

class Calendar extends React.Component {
    state = {
        currentMonth: new Date(),
        selectedDate: new Date(),
        allYearEvents: [],
        eventDate: [],
        userRole: null,
        title: ''
    };

    componentDidMount() {
        yearEvents = [];
        this.getUserRole();
        if (this.props.groupID) {
            eventAnalyst('community_calendar', 'community_calendar_loaded', this.props.groupID + '-calendar', this.props.groupID + '-calendar-load-success');
            this.fetchUpcomingEvents(new Date().getFullYear(), -1);
        } else {
            eventAnalyst('user_calendar', 'user_calendar_loaded', 'user-calendar', 'user-calendar-load-success')
        }
    }

    getUserRole = () => {
        let self = this;
        const req = new GetGroupRequest();
        req.setGroupId(this.props.groupID);
        getGroupDetails(req, 3).then(function (response) {
            const group = response.getGroup();
            const title = group.getName();
            const userRole = getRoleString(group.getRole());
            if (userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT) {
                self.setState({
                    userRole, title
                })
            }
        }).catch(function (error) {

        })
    };

    fetchUpcomingEvents = (year, month) => {
        let self = this;
        const req = new GetGroupEventsRequest();
        req.setGroupId(this.props.groupID);
        req.setYear(year);
        req.setMonth(month + 1);
        req.setCursor();
        req.setPageSize();
        getGroupsEvents(req, 3).then(function (response) {
            self.setState({allYearEvents: response.getEventDetailList()});
        }).catch(function (error) {

        })
    };

    renderHeader = () => {
        const dateFormat = "yyyy MMMM";
        return (
            <div className="header row flex-middle">
                <div className="col col-start">
                    <span className="prevMonth">
                        <span onClick={this.prevMonth} className="icon">chevron_left </span>
                        {/*<span className="monthPre">{dateFns.format(dateFns.subMonths(this.state.currentMonth, 1), "MMM")}</span>*/}
                    </span>
                    <span className="prevMonth">
                        {/*<span className="monthPre">{dateFns.format(dateFns.addMonths(this.state.currentMonth, 1), "MMM")}</span>*/}
                        <span onClick={this.nextMonth} className="icon">chevron_right</span>
                    </span>
                </div>
                <div className="col col-center">
                    <span className="currentMonth"
                          onClick={this.dateNow}>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
                </div>
                <div className="col col-end">
                    <span className="currentMonthRead">&nbsp;</span>
                </div>
            </div>
        );
    };

    renderDays() {
        const dateFormat = "EEE";
        const days = [];

        let startDate = dateFns.startOfWeek(this.state.currentMonth);

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
                </div>
            );
        }
        return <div className="days row">{days}</div>;
    }


    renderCells = () => {
        const {currentMonth, selectedDate, allYearEvents} = this.state;
        const monthStart = dateFns.startOfMonth(currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfWeek(monthStart);
        const endDate = dateFns.endOfWeek(monthEnd);

        const dateFormat = "d";
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";

        let self = this;
        allYearEvents.forEach(function (eventDetail) {
            var getDateTimeStamp = new Date(new Date(eventDetail.getEvent().getStartFrom()).toDateString()).getTime();
            yearEvents[getDateTimeStamp] = eventDetail;
        });
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <div
                        className={`col cell ${
                            !dateFns.isSameMonth(day, monthStart)
                                ? "disabled"
                                : dateFns.isSameDay(day, selectedDate) ? "selected" : ""
                        }`}
                        key={day}
                        onClick={() => this.onDateClick(cloneDay)}
                    >
                        <div
                            className={`${!yearEvents[new Date(day).getTime()] ? "" : yearEvents[new Date(day).getTime()].getEvent().getPublicHoliday() ? "holidayDate" : ""}`}>
                        <span
                            className={`dateEvent`}>
                            {
                                yearEvents[new Date(day).getTime()] ? yearEvents[new Date(day).getTime()].getEvent().getTitle() : ''
                            }
                        </span>
                            <span
                                className={`number`}>{formattedDate}</span>

                        </div>
                    </div>
                );
                day = dateFns.addDays(day, 1);
            }
            rows.push(
                <div className="row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    };

    onDateClick = day => {
        // console.log(day);
    };

    dateNow = () => {
        this.setState({
            currentMonth: new Date()
        });
    };
    nextMonth = () => {
        const newDate = dateFns.addMonths(this.state.currentMonth, 1);
        this.setState({
            currentMonth: newDate
        });
        if (dateFns.format(this.state.currentMonth, 'yyyy') !== dateFns.format(newDate, 'yyyy')) {
            this.fetchUpcomingEvents(dateFns.format(newDate, 'yyyy'), -1);
        }
    };

    prevMonth = () => {
        const newDate = dateFns.subMonths(this.state.currentMonth, 1);
        this.setState({
            currentMonth: newDate
        });
        if (dateFns.format(this.state.currentMonth, 'yyyy') !== dateFns.format(newDate, 'yyyy')) {
            this.fetchUpcomingEvents(dateFns.format(newDate, 'yyyy'), -1);
        }
    };

    renderEvents = () => {
        const {classes} = this.props;
        const {allYearEvents} = this.state;
        const rootGroupsURl = '/c/' + this.props.groupID;
        const monthEvents = [];
        const month = this.state.currentMonth;
        let startOfMonth = new Date(new Date(month).getFullYear(), new Date(month).getMonth(), 1).getTime();
        let endOfMonth = new Date(new Date(month).getFullYear(), new Date(month).getMonth() + 1, 0).getTime();

        allYearEvents.forEach(function (eventDetail) {
            var getDateTimeStamp = new Date(new Date(eventDetail.getEvent().getStartFrom()).toDateString()).getTime();
            if (getDateTimeStamp >= startOfMonth && getDateTimeStamp <= endOfMonth) {
                monthEvents.push(eventDetail);
            }
        });
        return (
            <div>
                <div>
                    {monthEvents.length > 0 ? (
                        <div>
                            <div>
                                <Typography
                                    variant="h6"
                                    style={{fontWeight: 600}}
                                >Month Events</Typography>
                            </div>
                            <br/>
                            <div className={classes.events}>
                                {monthEvents.map(event => (
                                    <Link to={`${rootGroupsURl}/events/${event.getEvent().getEventId()}`}
                                          className={classes.linkTO}>
                                        <div
                                            className={`${event.getEvent().getPublicHoliday() ? classes.holiday : ''} ${classes.eventLists} `}>
                                            <div className={classes.dateWrapper}>
                                                <div
                                                    className={classes.monthEvent}>{dateFns.format(new Date(event.getEvent().getStartFrom()), "MMMM")}</div>
                                                <div
                                                    className={classes.dateEvent}>{new Date(event.getEvent().getStartFrom()).getDate()}</div>
                                                <div
                                                    className={classes.dayEvent}>{dateFns.format(new Date(event.getEvent().getStartFrom()), "EEE")}</div>
                                            </div>
                                            <div className={classes.eventWrapper}>
                                                <div className={classes.eventTitle}>{event.getEvent().getTitle()}</div>
                                                <div
                                                    className={classes.location}>at {event.getEvent().getLocationString()} |
                                                    Hosted By
                                                    : {event.getEvent().getMetaDataMap().get('OWNER_GROUP') ? JSON.parse(event.getEvent().getMetaDataMap().get('OWNER_GROUP')).accountName : event.getEvent().getOwnerInfo().getAccountName()}</div>
                                            </div>
                                            <div
                                                className={classes.timeRemaining}>{timeEvent(new Date(event.getEvent().getStartFrom()))}</div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>) : (<div></div>)}
                </div>
            </div>
        );
    };

    render() {
        const rootGroupsURl = '/c/';
        const mainGroupID = this.props.groupID;
        const userRole = this.state.userRole;
        const {title} = this.state;

        return (
            <div>
                <div className="addEventWrapper">
                    {userRole === usedString.ADMIN ? (
                        <div className="floatRight">
                            <div className="buttonWrapper">
                                <Link
                                    to={`${rootGroupsURl}${mainGroupID}/addEvent`}>
                                    <Button variant="outlined" color="primary"
                                            className="button">
                                        Add Event
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    ) : (<div></div>)}
                </div>
                <div className='calendarTitle'>
                    {title ? title + "'s" : ''} Calendar
                </div>
                <div className="calendar">
                    {this.renderHeader()}
                    {this.renderDays()}
                    {this.renderCells()}
                </div>
                <br/>
                {mainGroupID ? (
                    <div>{this.renderEvents()}</div>
                ) : (<div></div>)}
            </div>
        );
    }
}

Calendar.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Calendar);
