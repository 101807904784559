import React, {useEffect} from 'react';
import {useTheme, withStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {AddCircle as AddIcon, KeyboardBackspace as BackButton, RemoveCircle as RemoveIcon} from "@material-ui/icons";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import {Elements, StripeProvider} from "react-stripe-elements";
import StripeCheckOut from "../Checkout/StripeCheckOut";
import Slide from "@material-ui/core/Slide";
import {Link} from "react-router-dom";
import {initializeStripe, isLogin, removeUserData, userInfo} from "../../configurations/global-config";
import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton,} from 'react-share';
import {timeEvent} from "../../utils/converter/dateConverter";
import globalConfig from "../../configurations/configVariables";
import usedString, {emailFormat, getCountryCurrencySymbol, payment} from "../../utils/mapper/enumToStringMapper";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PayeeInfo} from "../../generated/frontend-ticket-common-types_pb";
import {PaymentMethod, PaymentRequest} from "../../generated/frontend-community-ticket-payment-service_pb";
import {makeTicketPayment} from "../../grpcRequests/tickets-request";
import {fixedFloat} from "../../utils/converter/common";
import CircleIcon from "@material-ui/icons/CheckCircle";
import classNames from 'classnames';
import KhaltiCheckout from "khalti-web";
import KhaltiCheckOut from "../Checkout/KhaltiCheckOut";
import EsewaCheckOut from "../Checkout/EsewaCheckOut";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}

export default function BuyTickets(props) {
    let buyerP,buyerN,buyerE,buyerA,buyerTicketCount,buyerPaymentMethod,buyerTicketDesc,isEmailCorrect;
    let fieldData;
    let userData;
    if(localStorage.getItem('userData')){
        fieldData=localStorage.getItem('userData');
        userData = JSON.parse(atob(fieldData));
    }else if(props.fieldDatas){
        fieldData = props.fieldDatas;
    }
    if(fieldData && userData.ticketId){
        buyerN=userData.buyerName;
        buyerE=userData.buyerEmail;
        buyerA=userData.buyerAddress;
        buyerP=userData.buyerPhone;
        buyerTicketCount=userData.noOfTicket;
        buyerPaymentMethod=userData.paymentMethod;
        buyerTicketDesc=userData.description;
        isEmailCorrect= emailFormat.test(userData.buyerEmail)
    }
    const [open, setOpen] = React.useState(props.openTicket);
    const [error, setError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [ticketId, setTicketId] = React.useState(props.ticketId);
    const [ticketName, setTicketName] = React.useState(props.ticketName);
    const [ticketDesc, setTicketDesc] = React.useState(props.ticketDesc);
    const [ticketPrice, setTicketPrice] = React.useState(props.ticketPrice);
    const [ticketCurrency, setTicketCurrency] = React.useState(props.ticketCurrency);
    const [isTicketSoldOut, setIsTicketSoldOut] = React.useState(props.ticketSoldOut);
    const [maxPurchaseCount, setMaxPurchaseCount] = React.useState(props.maxPurchase >= 40 ? 40 : props.maxPurchase);
    const [maxTicketCount, setMaxTicketCount] = React.useState(props.maxTicket);
    const [availableCount, setAvailableCount] = React.useState(props.available);
    const [ticketExpTime, setTicketExpTime] = React.useState(props.ticketExp);
    const [eventName, setEventName] = React.useState(props.eventName);
    const [eventImage, setEventImage] = React.useState(props.eventImage);
    const [openTicketEntry, setOpenTicketEntry] = React.useState(true);
    const [eventID, setEventID] = React.useState(props.eventId);
    const [groupID, setGroupID] = React.useState(props.groupID);
    const [purchaseMessage, setPurchaseMessage] = React.useState('');
    //Free Ticket
    const [isFreeTicket, setIsFreeTicket] = React.useState(props.isFreeTicket);
    const [buyingFree, setBuyingFree] = React.useState(false);
    const [continuePaying, setContinuePaying] = React.useState(false);
    const [buyingFreeMsg, setBuyingFreeMsg] = React.useState('');

    const [confirmationId, setConfirmationId] = React.useState('');
    const [ticketTypeId, setTicketTypeId] = React.useState('');
    const [totalTicketBought, setTotalTicketBought] = React.useState('');
    const [ticAmount, setTicAmount] = React.useState('');

    //Buyers
    const [changeContactInformation, setChangeContactInformation] = React.useState(true);
    const [buyerName, setBuyerName] = React.useState(buyerN ? buyerN : userInfo().displayName ? userInfo().displayName : '');
    const [buyerAddress, setBuyerAddress] = React.useState(buyerA ? buyerA : '');
    const [buyersDescription, setBuyersDescription] = React.useState(buyerTicketDesc ? buyerTicketDesc : '');
    const [buyerEmail, setBuyerEmail] = React.useState(buyerE ? buyerE : userInfo().email ? userInfo().email : '');
    const [buyerPhone, setBuyerPhone] = React.useState(buyerP ? buyerP : '');
    const [paymentMethod, setPaymentMethod] = React.useState(buyerPaymentMethod ? buyerPaymentMethod : props.ticketCurrency === "NPR" ? payment.Esewa : payment.Stripe);
    const [noOfTicket, setNoOfTicket] = React.useState(buyerTicketCount ? buyerTicketCount : 1);

    const [boughtTicket, setBoughtTicket] = React.useState(false);
    const [boughtTime, setBoughtTime] = React.useState('');
    const [buyingAnother, setBuyingAnother] = React.useState(false);
    const [correctEmail, setCorrectEmail] = React.useState(isEmailCorrect ? isEmailCorrect : userInfo().email && emailFormat.test(userInfo().email));
    const [khaltiToken, setKhaltiToken] = React.useState('');
    const [esewaToken, setEsewaToken] = React.useState('');
    const [esewaPID, setEsewaPID] = React.useState('');

    const screenWidth = useWidth();

    useEffect(() => {
        let refId = window.location.search ? (new URLSearchParams(window.location.search).get('refId')) : '';
        let oId = window.location.search ? (new URLSearchParams(window.location.search).get('oid')) : '';
        let urlTicketId = window.location.search && new URLSearchParams(window.location.search).get('oid') ? (new URLSearchParams(window.location.search).get('oid')).split('_')[0] : '';

        if (refId && urlTicketId===ticketId){
            setOpenTicketEntry(false);
            setEsewaToken(refId);
            setEsewaPID(oId);
        }
    }, []);

    if (paymentMethod === payment.Stripe) {
        initializeStripe();
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        reset();
        goToPath();
    };

    const handleBoughtTicketClose = () => {
        reset();
        goToPath();
        sendResponseToParent(true, true);
    };


    function goToPath() {
        let goToURL= window.location.origin+'/c/'+groupID+'/events/'+eventID;
        window.history.pushState('', '', goToURL);
    }

    function reset() {
        removeUserData();
        setOpen(false);
        setBoughtTicket(false);
        setOpenTicketEntry(true);
        setNoOfTicket(1);
        setBuyingAnother(false);
        setBuyerName( userInfo().displayName ? userInfo().displayName : '');
        setBuyerEmail( userInfo().email ? userInfo().email : '');
        setBuyerAddress('');
        setBuyerPhone('');
        setPaymentMethod(props.ticketCurrency === "NPR" ? payment.Esewa : payment.Stripe);
        setBuyersDescription('');
    }

    const buyMore = () => {
        setBoughtTicket(false);
        setOpenTicketEntry(true);
        setBuyingAnother(true);
        setNoOfTicket(1);
        setBuyingFreeMsg('');
    };

    const viewTickets = () => {
        const {history} = this.props;
        history.push('/tickets');
    };

    const changeNoOfTicketToBuy = () => {
        setOpenTicketEntry(true);
    };

    const changePaymentMethod = (method) => {
        setPaymentMethod(method);
    };

    const sendResponseToParent = (purchaseStatus, closeStatus) => {
        props.sendCloseResponse(purchaseStatus, closeStatus);
    };

    const validateFields = (fieldValue) => {
        let noOfTicket = fieldValue;
        let totalTicket;
        if (!noOfTicket) {
            return {
                'err': true,
                'msg': 'Not a valid number'
            }
        } else if (typeof noOfTicket !== 'number') {
            let result = (noOfTicket - Math.floor(noOfTicket)) !== 0;
            if (result) {
                return {
                    'err': true,
                    'msg': 'Should not contain decimal value'
                }
            } else if (Math.sign(noOfTicket) !== 1) {
                return {
                    'err': true,
                    'msg': 'Should not be negative'
                }
            } else {
                totalTicket = parseInt(noOfTicket);
                return {
                    'err': false,
                    'msg': '',
                    'newValue': totalTicket
                }
            }
        } else {
            totalTicket = noOfTicket;
            return {
                'err': false,
                'msg': '',
                'newValue': totalTicket
            }
        }
    };


    const handleProceed = () => {
        setError(false);
    };

    const handleContinue = (setField) => {
        setError(false);
        setContinuePaying(true);
        if (setField) {
            setChangeContactInformation(true);
        } else setChangeContactInformation(false);
        // validate no of ticket to purchase
        const validateNoOfTicketToBuy = validateFields(noOfTicket);
        if (validateNoOfTicketToBuy.err) {
            setError(validateNoOfTicketToBuy.err);
            setError(validateNoOfTicketToBuy.err);
            setErrorMsg(validateNoOfTicketToBuy.msg);
        } else {
            setNoOfTicket(validateNoOfTicketToBuy.newValue)
        }
        if (!validateNoOfTicketToBuy.err) {
            if (paymentMethod === payment.Stripe) {
                setOpenTicketEntry(false);
                setContinuePaying(false);
            } else if (paymentMethod === payment.Khalti) {
                khaltiPayment();
            } else if (paymentMethod === payment.Esewa) {
                esewaPayment();
            }
        }
    };

    const khaltiPayment = () => {

        let khaltiConfig = {
            // replace this key with yours
            "publicKey": globalConfig.khaltiKey,
            "productIdentity": ticketId,
            "productName": ticketName,
            // "productUrl": window.location.href,
            "productUrl": 'https://www.hamropatro.com',
            "eventHandler": {
                onSuccess(payload) {
                    setOpen(true);
                    setOpenTicketEntry(false);
                    setKhaltiToken(payload.token);
                },
                onError(error) {
                },
                onClose() {
                    setOpen(true);
                    setContinuePaying(false);
                }
            }
        };

        let khaltiCkOut = new KhaltiCheckout(khaltiConfig);
        khaltiCkOut.show({amount: (noOfTicket * ticketPrice) * 100, mobile: buyerPhone});
        setOpen(false);
    };

    const esewaPayment = () => {
        let createReqJson= {};
        createReqJson.buyerName=buyerName;
        createReqJson.buyerEmail=buyerEmail;
        createReqJson.buyerAddress=buyerAddress;
        createReqJson.buyerPhone=buyerPhone;
        createReqJson.noOfTicket=noOfTicket;
        createReqJson.paymentMethod=paymentMethod;
        createReqJson.description=buyersDescription;
        createReqJson.ticketId=ticketId;

        let convertedb64= btoa(JSON.stringify(createReqJson));
        localStorage.setItem('userData',convertedb64);

        let path=globalConfig.esewaURL;
        let rootURL= window.location.origin;
        let redirectPath= rootURL+'/c/'+groupID+'/events/'+eventID+'/ticket/'+ticketId;
        let errPath= rootURL+'/c/'+groupID+'/events/'+eventID+'/ticket/'+ticketId + '?esewaPayment=false';

        let esewaPaymentId= ticketId+'_'+new Date().getTime();

        let params= {
            amt: fixedFloat(noOfTicket * ticketPrice, 2),
            psc: 0,
            pdc: 0,
            txAmt: 0,
            tAmt: fixedFloat(noOfTicket * ticketPrice, 2),
            pid: esewaPaymentId,
            scd: globalConfig.esewaSCD,
            su: redirectPath,
            fu: errPath
        };
        var form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", path);

        for(var key in params) {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);
            form.appendChild(hiddenField);
        }

        document.body.appendChild(form);
        form.submit();
    };

    const buyFreeTicket = () => {
        let timeStamp = new Date().getTime();
        setBuyingFree(true);
        setBuyingFreeMsg('');
        let payeeInfo = new PayeeInfo();
        payeeInfo.setName(buyerName);
        payeeInfo.setEmail(buyerEmail);
        payeeInfo.setAddress(buyerAddress);
        payeeInfo.setPhone(buyerPhone);
        const req = new PaymentRequest();
        req.setSourceId(eventID); //event ID
        req.setTicketTypeId(ticketId); //tickcet iD
        req.setPaymentmethod(PaymentMethod.RSVP);
        req.setPaymentToken(usedString.FREE_TICKET); //token id
        req.setPurchaseQuantity(noOfTicket);
        req.setUniqueIdentifier(timeStamp.toString());
        req.setPayeeInfo(payeeInfo);
        req.setAppId(globalConfig.appId);
        const metadata = req.getMetaDataMap();
        metadata.set("buyer_desc", (buyersDescription));
        paymentAPI(req, 3);
    };

    const paymentAPI = (req, retry) => {
        makeTicketPayment(req).then(function (response) {
            setBuyingFree(false);
            if (response.getPaymentSuccessful() && response.getPaymentConfirmationId() && response.getPaymentConfirmationId() !== '') {
                setBuyingFreeMsg('Payment Success');
                getResFrmChild(true, response.getPaymentConfirmationId(), response.getTicketTypeId(), response.getTicketIdList().length, response.getChargedAmount(), response.getPaymentTimestamp());
            } else {
                setBuyingFreeMsg('Payment failed');
                getResFrmChild(false, true, '', '', '', '');
            }
        }).catch(function (error) {
            if (error.code === 14) {
                if (retry === 1) {
                    setBuyingFree(false);
                    setBuyingFreeMsg('Payment failed');
                } else {
                    setTimeout(() => {
                        paymentAPI(req, retry - 1)
                    }, 10);
                }
            } else {
                setBuyingFree(false);
                setBuyingFreeMsg(error.message);
            }
        })
    };
    const getResFrmChild = (checkoutStatus, confirmationId, ticketTypeId, totalTicket, chargedAmount, time) => {
        setNoOfTicket(1);
        if (checkoutStatus) {
            setBoughtTicket(true);
            setConfirmationId(confirmationId);
            setTicketTypeId(ticketTypeId);
            setTotalTicketBought(totalTicket);
            setTicAmount(chargedAmount);
            setBoughtTime(time);
        }
    };

    const handleNoOfTicket = event => {
        setPurchaseMessage('');
        setError(false);
        setNoOfTicket(event.target.value);
    };

    const handleChangeField = name => event => {
        setBuyerName(event.target.value);
    };

    const handleAddressChange = name => event => {
        setBuyerAddress(event.target.value);
    };

    const handleDescriptionChange = name => event => {
        setBuyersDescription(event.target.value);
    };

    const handleEmailChange = name => event => {
        setBuyerEmail(event.target.value);
        if (event.target.value && emailFormat.test(event.target.value)) {
            setCorrectEmail(true)
        } else {
            setCorrectEmail(false)
        }
    };

    const handlePhoneChange = name => event => {
        setBuyerPhone(event.target.value);
    };


    const increase = () => {
        setPurchaseMessage('');
        setError(false);
        if (typeof noOfTicket !== 'number')
            setNoOfTicket(1);
        else if (noOfTicket >= 1)
            setNoOfTicket(noOfTicket + 1);
    };

    const decrease = () => {
        setPurchaseMessage('');
        setError(false);
        if (typeof noOfTicket !== 'number')
            setNoOfTicket(1);
        else if (noOfTicket > 1)
            setNoOfTicket(noOfTicket - 1);
    };

    const shareUrl = window.location.href;
    const title = 'Just bought tickets. Find event tickets here.';

    const continuePay = <div>
        {isFreeTicket ? (
            <div>
                <Button color="secondary"
                        onClick={() => buyFreeTicket()}
                        variant="contained"
                        disabled={!paymentMethod || buyingFree || (noOfTicket > maxPurchaseCount) || (noOfTicket > availableCount) || noOfTicket <= 0 || !buyerName || !buyerEmail || !correctEmail || (paymentMethod === payment.Khalti || paymentMethod === payment.Esewa ? !buyerPhone : false)}>
                    {buyingFree ? (
                        <span>Submitting&nbsp; &nbsp;<CircularProgress size={12}/> </span>) : 'Submit'}
                </Button>
            </div>
        ) : (
            <div>
                {changeContactInformation ? (
                    <Button onClick={() => handleContinue(true)} color="secondary"
                            variant="contained"
                            disabled={!paymentMethod || continuePaying || (noOfTicket > maxPurchaseCount) || (noOfTicket > availableCount) || noOfTicket <= 0 || !buyerName || !buyerEmail || !correctEmail || (paymentMethod === payment.Khalti || paymentMethod === payment.Esewa ? !buyerPhone : false)}>
                        {continuePaying ? (
                            <span>Pleases Wait&nbsp; &nbsp;<CircularProgress size={12}/> </span>) : ' Continue'}
                    </Button>
                ) : (
                    <Button onClick={() => handleContinue(false)} color="secondary"
                            variant="contained"
                            disabled={!paymentMethod || continuePaying || (noOfTicket > maxPurchaseCount) || (noOfTicket > availableCount) || noOfTicket <= 0 || (paymentMethod === payment.Khalti || paymentMethod === payment.Esewa ? !buyerPhone : false)}>
                        {continuePaying ? (
                            <span>Pleases Wait&nbsp; &nbsp;<CircularProgress size={12}/> </span>) : ' Continue'}
                    </Button>
                )}
            </div>
        )}
    </div>;

    return (
        <div style={{textAlign: 'center'}}>
            <Button variant="contained" color="secondary" onClick={handleClickOpen} fullWidth
                    style={{fontSize: '10px', fontWeight: 600}}
                    disabled={ticketExpTime < new Date().getTime()}>
                {ticketExpTime < new Date().getTime() ? 'Expired' : 'Purchase'}
            </Button>
            {boughtTicket === true ? (
                <div>
                    <Dialog onClose={handleBoughtTicketClose} aria-labelledby="customized-dialog-title" open={open}
                            fullScreen={(screenWidth === 'xs' || screenWidth === 'sm')}
                            fullWidth={(!(screenWidth === 'xs' || screenWidth === 'sm'))}
                            disableBackdropClick
                            TransitionComponent={Transition}
                            disableEscapeKeyDown>
                        <DialogTitle id="customized-dialog-title" onClose={handleBoughtTicketClose}>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={0} style={{textAlign: 'center', marginTop: '45px'}}>
                                <Grid item xs={12} sm={12} style={{padding: '10px'}}>
                                    <Grid container spacing={3} style={{textAlign: 'center'}}>
                                        <Grid item xs={12} sm={12}>
                                            <div style={{padding: '10px 0', marginBottom: '-1px'}}>
                                                <Typography variant="h6" component="div" style={{fontWeight: 600}}>
                                                    Thank you !!!
                                                </Typography>
                                                <Typography variant="h6" component="div" style={{
                                                    fontSize: '16px',
                                                    lineHeight: 1.4,
                                                    padding: '10px 5px'
                                                }}>
                                                    {!isFreeTicket ? (<span>
                                                    You've just bought <span
                                                            style={{fontWeight: 600}}>{totalTicketBought} '{ticketName}'</span> tickets
                                                    at <span
                                                            style={{fontWeight: 600}}>{ticketCurrency} {getCountryCurrencySymbol(ticketCurrency)}{ticAmount}</span>.
                                                            We've send tickets details to the email address <b>{buyerEmail}</b>.</span>
                                                    ) : (
                                                        <span>
                                                    You've just bought <span
                                                            style={{fontWeight: 600}}>{totalTicketBought} free '{ticketName}'</span> tickets.
                                                            We've send tickets details to the email address <b>{buyerEmail}</b>.</span>
                                                    )}
                                                </Typography>
                                                <Typography variant="body2" component="div"
                                                            style={{
                                                                fontWeight: 600,
                                                                color: '#686868',
                                                                padding: '5px'
                                                            }}>
                                                    Payment Confirmation Id <b>{confirmationId}</b>
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <br/>
                                        <Grid item xs={12} sm={12}>
                                            <Link
                                                to={isLogin() ? `/tickets/${eventID}/${ticketTypeId}/ts/${boughtTime}` : `/tickets/${eventID}/${ticketTypeId}/pid/${confirmationId}`}>
                                                <Button autoFocus color="secondary" variant="contained">
                                                    View Tickets
                                                </Button>
                                            </Link>
                                        </Grid>
                                        <br/>
                                        {!isFreeTicket ? (
                                            <Grid item xs={12} sm={12}>
                                                <div>
                                                    <span
                                                        style={{color: '#625b5b'}}> If you want to buy more tickets. </span>
                                                    (<span onClick={buyMore} style={{
                                                    color: '#3377e0',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        textDecoration: 'underline'
                                                    }
                                                }}>Click Here
                                                </span>)
                                                </div>
                                            </Grid>
                                        ) : null}
                                        <br/>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h6" component="div" style={{fontWeight: 600}}>
                                                Social Share
                                            </Typography>
                                            <div style={{display: "inline-flex"}}>
                                                <FacebookShareButton
                                                    url={shareUrl}
                                                    quote={title}
                                                    className="socialShareIcons">
                                                    <FacebookIcon
                                                        size={32}
                                                        round/>
                                                </FacebookShareButton>
                                                <TwitterShareButton
                                                    url={shareUrl}
                                                    title={title}
                                                    className="socialShareIcons">
                                                    <TwitterIcon
                                                        size={32}
                                                        round/>
                                                </TwitterShareButton>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </div>
            ) : (
                <Dialog onClose={buyingAnother ? handleBoughtTicketClose : handleClose}
                        aria-labelledby="customized-dialog-title" open={open}
                        fullScreen={(screenWidth === 'xs' || screenWidth === 'sm') ? true : false}
                        fullWidth={(screenWidth === 'xs' || screenWidth === 'sm') ? false : true}
                        maxWidth={(screenWidth === 'xs' || screenWidth === 'sm') ? '' : 'md'}
                        disableBackdropClick
                        TransitionComponent={Transition}
                        disableEscapeKeyDown>
                    <DialogTitle id="customized-dialog-title"
                                 onClose={buyingAnother ? handleBoughtTicketClose : handleClose}>
                        Purchase Ticket
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={0}>
                            {openTicketEntry ? (
                                <Grid item xs={12} sm={screenWidth === 'sm' ? 12 : 8}
                                      style={(screenWidth === 'xs' || screenWidth === 'sm') ? {padding: '10px'} : {padding: '5px 36px 24px'}}>
                                    <Grid container spacing={3}>
                                        {buyingAnother ? (
                                            <Grid item xs={12} sm={12}>
                                                <div style={{
                                                    padding: '10px',
                                                    borderRadius: '5px',
                                                    background: '#66aef1d4',
                                                    color: '#253d49',
                                                    fontSize: '16px',
                                                    clear: 'both'
                                                }}>Buying another ticket.
                                                </div>
                                            </Grid>
                                        ) : null}
                                        <Grid item xs={12} sm={7}>
                                            <Typography component="span" variant="h6"
                                                        style={{color: '#39364F', fontWeight: '900'}}>
                                                {ticketName}
                                            </Typography>
                                            <Typography component="div" style={{
                                                color: '#39364F',
                                                fontWeight: '600',
                                                fontSize: '16px'
                                            }}>
                                                {isFreeTicket ? 'FREE TICKET' : ticketCurrency + ' ' + getCountryCurrencySymbol(ticketCurrency) + ticketPrice}
                                            </Typography>

                                            <Typography component="div"
                                                        style={{fontSize: '13px', color: '#7b798d'}}>
                                                Ticket sales ends {timeEvent(ticketExpTime)}
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <div>
                                                <div style={{display: "flex"}}>
                                                    <div style={{padding: "15px 10px"}}>
                                                        <IconButton aria-label="Send"
                                                                    title="Decrease"
                                                                    size="small"
                                                                    onClick={decrease}
                                                                    color={"secondary"}>
                                                            <RemoveIcon/>
                                                        </IconButton>
                                                    </div>
                                                    <TextField
                                                        required
                                                        variant="outlined"
                                                        id="noOfTicket"
                                                        name="noOfTicket"
                                                        type="number"
                                                        min="1"
                                                        style={{width: '110px'}}
                                                        value={noOfTicket}
                                                        onChange={handleNoOfTicket}
                                                        autoComplete="Maximum Tickets"
                                                    />
                                                    <div style={{padding: "15px 10px"}}>
                                                        <IconButton aria-label="Send"
                                                                    title="Increase"
                                                                    size="small"
                                                                    onClick={increase}
                                                                    color={"secondary"}>
                                                            <AddIcon/>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                                <Typography component="div">
                                                    {noOfTicket <= 0 ? (
                                                        <Typography component="span" style={{
                                                            fontSize: '14px',
                                                            paddingLeft: '5px',
                                                            color: '#b71c1c'
                                                        }}><span>Should be valid number </span>
                                                        </Typography>
                                                    ) : (<span></span>)}
                                                    {error ? (<span style={{
                                                        fontSize: '14px',
                                                        paddingLeft: '5px',
                                                        color: '#b71c1c'
                                                    }}>{errorMsg} </span>) : (<span></span>)}
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography
                                                component="div"
                                                color="textPrimary"
                                                style={{whiteSpace: 'pre-line'}}
                                            > {ticketDesc}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            {noOfTicket > maxPurchaseCount ? (
                                                <Typography component="div" style={{
                                                    padding: '10px 15px',
                                                    margin: '10px 0',
                                                    background: "#ffbd21",
                                                    borderRadius: '5px'
                                                }}>
                                                    <span>Sorry, You can only buy {maxPurchaseCount} tickets at a time.</span>
                                                </Typography>
                                            ) : (<span>
                                                        {noOfTicket > availableCount ? (
                                                            <Typography component="div" style={{
                                                                padding: '10px 15px',
                                                                margin: '10px 0',
                                                                background: "#ffbd21",
                                                                borderRadius: '5px'
                                                            }}>
                                                                <span>Sorry, {noOfTicket} {noOfTicket > 1 ? 'tickets are' : 'ticket is'} not available.</span>
                                                            </Typography>
                                                        ) : (<span></span>)}
                                                    </span>)}
                                        </Grid>
                                    </Grid>
                                    {!isFreeTicket ? (
                                        <div>
                                            <div style={{clear: 'both', borderBottom: '1px solid #e5e5e5'}}></div>
                                            <div style={{padding: '10px 0', marginBottom: '-1px'}}>
                                                <Typography variant="h6" component="span" style={{fontWeight: 600}}>
                                                    Choose Payment Method
                                                </Typography>
                                            </div>
                                            <div style={{width: '100%', overflow: 'auto'}}>
                                                <div style={{display: 'inline-flex'}}>
                                                    <Card onClick={() => changePaymentMethod(payment.Stripe)}
                                                          className={classNames('paymentCardWrapper')}
                                                          style={paymentMethod === payment.Stripe ?
                                                              {
                                                                  width: '80px',
                                                                  boxShadow: 'none',
                                                                  padding: '10px',
                                                                  border: '1px solid #458c48',
                                                                  cursor: 'pointer',
                                                                  margin: '5px',
                                                                  textAlign: 'center',
                                                              } :
                                                              {
                                                                  width: '80px',
                                                                  boxShadow: 'none',
                                                                  padding: '10px',
                                                                  border: '1px solid #f1f1f1',
                                                                  cursor: 'pointer',
                                                                  margin: '5px',
                                                                  textAlign: 'center',
                                                                  opacity: '0.8'
                                                              }}>
                                                        <CardMedia
                                                            style={{height: 0, paddingTop: '66.25%'}}
                                                            alt="cardPayment"
                                                            image='/images/icons/cards.png'
                                                            title='Card Payment'
                                                        />
                                                        <Typography variant="body2" component="div" style={{
                                                            fontWeight: 600,
                                                            lineHeight: '1.2',
                                                            paddingTop: '7px',
                                                            textAlign: 'center',
                                                            display: 'inline-flex',
                                                            alignItems: 'center'
                                                        }}>
                                                            Card {paymentMethod === payment.Stripe ? (<span> <CircleIcon
                                                            fontSize='small'
                                                            style={{color: '#458c48', padding: '2px'}}/></span>) : null}
                                                        </Typography>
                                                    </Card>
                                                    {ticketCurrency === 'NPR' ? (
                                                        <Card onClick={() => changePaymentMethod(payment.Esewa)}
                                                              className={classNames('paymentCardWrapper')}
                                                              style={paymentMethod === payment.Esewa ?
                                                                  {
                                                                      width: '80px',
                                                                      boxShadow: 'none',
                                                                      padding: '10px',
                                                                      border: '1px solid #458c48',
                                                                      cursor: 'pointer',
                                                                      margin: '5px',
                                                                      textAlign: 'center',
                                                                  } :
                                                                  {
                                                                      width: '80px',
                                                                      boxShadow: 'none',
                                                                      padding: '10px',
                                                                      border: '1px solid #f1f1f1',
                                                                      cursor: 'pointer',
                                                                      margin: '5px',
                                                                      textAlign: 'center',
                                                                      opacity: '0.8'
                                                                  }}>
                                                            <CardMedia
                                                                style={{height: 0, paddingTop: '66.25%'}}
                                                                alt="EsewaPayment"
                                                                image='/images/icons/esewa.png'
                                                                title='Esewa Payment'
                                                            />
                                                            <Typography variant="body2" component="div" style={{
                                                                fontWeight: 600,
                                                                lineHeight: '1.2',
                                                                paddingTop: '7px',
                                                                textAlign: 'center',
                                                                display: 'inline-flex',
                                                                alignItems: 'center'
                                                            }}>
                                                                Esewa {paymentMethod === payment.Esewa ? (
                                                                <span> <CircleIcon
                                                                    fontSize='small' style={{
                                                                    color: '#458c48',
                                                                    padding: '2px'
                                                                }}/></span>) : null}
                                                            </Typography>
                                                        </Card>):null}
                                                    {ticketCurrency === 'NPR' ? (
                                                        <Card onClick={() => changePaymentMethod(payment.Khalti)}
                                                              className={classNames('paymentCardWrapper')}
                                                              style={paymentMethod === payment.Khalti ?
                                                                  {
                                                                      width: '80px',
                                                                      boxShadow: 'none',
                                                                      padding: '10px',
                                                                      border: '1px solid #458c48',
                                                                      cursor: 'pointer',
                                                                      margin: '5px',
                                                                      textAlign: 'center',
                                                                  } :
                                                                  {
                                                                      width: '80px',
                                                                      boxShadow: 'none',
                                                                      padding: '10px',
                                                                      border: '1px solid #f1f1f1',
                                                                      cursor: 'pointer',
                                                                      margin: '5px',
                                                                      textAlign: 'center',
                                                                      opacity: '0.8'
                                                                  }}>
                                                            <CardMedia
                                                                style={{height: 0, paddingTop: '66.25%'}}
                                                                alt="khaltiPayment"
                                                                image='/images/icons/khalti.png'
                                                                title='Khalti Payment'
                                                            />
                                                            <Typography variant="body2" component="div" style={{
                                                                fontWeight: 600,
                                                                lineHeight: '1.2',
                                                                paddingTop: '7px',
                                                                textAlign: 'center',
                                                                display: 'inline-flex',
                                                                alignItems: 'center'
                                                            }}>
                                                                Khalti {paymentMethod === payment.Khalti ? (
                                                                <span> <CircleIcon
                                                                    fontSize='small' style={{
                                                                    color: '#458c48',
                                                                    padding: '2px'
                                                                }}/></span>) : null}
                                                            </Typography>
                                                        </Card>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    ):null}
                                    <br/>
                                    <div style={{clear: 'both', borderBottom: '1px solid #e5e5e5'}}></div>
                                    <div style={{padding: '10px 0', marginBottom: '-1px'}}>
                                        <Typography variant="h6" component="span" style={{fontWeight: 600}}>
                                            Contact Information
                                        </Typography>
                                    </div>
                                    <br/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                error={!buyerName}
                                                variant="outlined"
                                                id="buyerName"
                                                name="buyerName"
                                                label="Full Name (Required)"
                                                fullWidth
                                                value={buyerName}
                                                onChange={handleChangeField('buyerName')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                error={!correctEmail}
                                                variant="outlined"
                                                id="buyerEmail"
                                                name="buyerEmail"
                                                label="Email (Required)"
                                                fullWidth
                                                value={buyerEmail}
                                                onChange={handleEmailChange('buyerEmail')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                id="buyerPhone"
                                                name="buyerPhone"
                                                error={paymentMethod === payment.Khalti || paymentMethod === payment.Esewa ? !buyerPhone : false}
                                                label={paymentMethod === payment.Khalti || paymentMethod === payment.Esewa ? 'Phone Number (Required)' : 'Phone Number (Optional)'}
                                                helperText={paymentMethod === payment.Khalti || paymentMethod === payment.Esewa ? 'Please use registered Phone Number' : ''}
                                                fullWidth
                                                value={buyerPhone}
                                                onChange={handlePhoneChange('buyerPhone')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="outlined"
                                                id="buyerAddress"
                                                name="buyerAddress"
                                                label="Full Address (Optional)"
                                                fullWidth
                                                value={buyerAddress}
                                                onChange={handleAddressChange('buyerAddress')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                variant="outlined"
                                                id="buyersDescription"
                                                name="buyersDescription"
                                                label="Description (Optional)"
                                                multiline
                                                fullWidth
                                                rows="2"
                                                rowsMax="5"
                                                value={buyersDescription}
                                                onChange={handleDescriptionChange('buyersDescription')}
                                            />
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    {buyingFreeMsg ? (<div style={{
                                        padding: '10px 15px',
                                        margin: '10px 0',
                                        background: "#ffbd21",
                                        borderRadius: '5px'
                                    }}>{buyingFreeMsg}&nbsp;</div>) : (<div>&nbsp;</div>)}
                                    {(screenWidth === 'sm' || screenWidth === 'xs') ? null :
                                        <DialogActions>
                                            {continuePay}
                                        </DialogActions>
                                    }
                                </Grid>
                            ) : (
                                <Grid item xs={12} sm={screenWidth === 'sm' ? 12 : 8}
                                      style={(screenWidth === 'xs' || screenWidth === 'sm') ? {padding: '10px'} : {padding: '5px 36px 24px'}}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} style={{padding: '0'}}>
                                            <IconButton
                                                title="Change No of tickets"
                                                onClick={changeNoOfTicketToBuy}
                                            >
                                                <BackButton/>
                                            </IconButton>
                                            <Typography
                                                variant="span"
                                            > Purchased By - {buyerName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography
                                                variant="subtitle1"
                                                style={{fontWeight: 600}}>Paying
                                                for <b>{noOfTicket} '{ticketName}'</b> tickets
                                                at <b>{ticketCurrency} {getCountryCurrencySymbol(ticketCurrency)}{fixedFloat(noOfTicket * ticketPrice, 2)}</b>.
                                            </Typography>
                                            <Typography
                                                variant="div"
                                            > {paymentMethod ? `Payment Method: ${paymentMethod}` : ''}
                                            </Typography>
                                        </Grid>
                                        {paymentMethod === payment.Stripe ? (
                                            <Grid item xs={12} sm={12}>
                                                <div style={{padding: '10px 0', marginBottom: '-1px'}}>
                                                    <Typography variant="h6" component="span"
                                                                style={{fontWeight: 600}}>
                                                        Card Details
                                                    </Typography>
                                                </div>
                                                <StripeProvider apiKey={globalConfig.stripeKey}>
                                                    <div>
                                                        <Elements>
                                                            <StripeCheckOut passEventId={eventID}
                                                                            paymentType={paymentMethod}
                                                                            passTicketId={ticketId}
                                                                            buyerName={buyerName}
                                                                            buyerEmail={buyerEmail}
                                                                            buyerAddress={buyerAddress}
                                                                            buyerPhone={buyerPhone}
                                                                            noOfTicket={noOfTicket}
                                                                            sendCloseCheckout={getResFrmChild}
                                                                            ticketName={ticketName}
                                                                            buyersDesc={buyersDescription}
                                                                            ticketCost={ticketCurrency + ' ' + (noOfTicket * ticketPrice)}/>
                                                        </Elements>
                                                    </div>
                                                </StripeProvider>
                                            </Grid>
                                        ) : null}
                                        {paymentMethod === payment.Khalti ? (
                                            <Grid item xs={12} sm={12}>
                                                <div style={{padding: '10px 0', marginBottom: '-1px'}}>
                                                    <Typography variant="h6" component="span"
                                                                style={{fontWeight: 600}}>
                                                        Khalti Payment
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <KhaltiCheckOut passEventId={eventID}
                                                                    paymentType={paymentMethod}
                                                                    khaltiTok={khaltiToken}
                                                                    passTicketId={ticketId}
                                                                    buyerName={buyerName}
                                                                    buyerEmail={buyerEmail}
                                                                    buyerAddress={buyerAddress}
                                                                    buyerPhone={buyerPhone}
                                                                    noOfTicket={noOfTicket}
                                                                    buyersDesc={buyersDescription}
                                                                    sendCloseCheckout={getResFrmChild}
                                                                    ticketName={ticketName}
                                                                    ticketCost={ticketCurrency + ' ' + (noOfTicket * ticketPrice)}/>
                                                </div>
                                            </Grid>
                                        ) : null}
                                        {paymentMethod === payment.Esewa ? (
                                            <Grid item xs={12} sm={12}>
                                                <div style={{padding: '10px 0', marginBottom: '-1px'}}>
                                                    <Typography variant="h6" component="span"
                                                                style={{fontWeight: 600}}>
                                                        Esewa Payment
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <EsewaCheckOut
                                                        passEventId={eventID}
                                                        paymentType={paymentMethod}
                                                        esewaTok={esewaToken}
                                                        passTicketId={ticketId}
                                                        buyerName={buyerName}
                                                        buyerEmail={buyerEmail}
                                                        buyerAddress={buyerAddress}
                                                        buyerPhone={buyerPhone}
                                                        noOfTicket={noOfTicket}
                                                        buyersDesc={buyersDescription}
                                                        esewaPId={esewaPID}
                                                        sendCloseCheckout={getResFrmChild}
                                                        ticketName={ticketName}
                                                        ticketCost={ticketCurrency + ' ' + (noOfTicket * ticketPrice)}/>
                                                </div>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            )}
                            {(screenWidth === 'sm' || screenWidth === 'xs') ? null :
                                <Grid item xs={12} sm={4} style={{padding: '10px', background: 'aliceblue'}}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} style={{padding: '0'}}>
                                            <Card>
                                                <CardMedia
                                                    style={{height: 0, paddingTop: '52.25%'}}
                                                    alt={eventName}
                                                    image={eventImage ? eventImage : '/images/eventDefault.png'}
                                                    title={eventName}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h6" style={{fontSize: '15px'}}>
                                                {eventName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h6" component="span"
                                                        style={{fontWeight: 600, fontSize: '15px'}}>
                                                Order Details
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <div>
                                                <Typography component="div"
                                                            style={{float: "left", fontSize: '14px'}}>
                                                    {noOfTicket} x {ticketName}
                                                </Typography>
                                                <Typography component="div"
                                                            style={{float: "right", fontSize: '14px'}}>
                                                    {!isFreeTicket ? (<span>
                                                    {getCountryCurrencySymbol(ticketCurrency)}{fixedFloat(noOfTicket * ticketPrice, 2)}</span>) : null}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {!isFreeTicket ? (
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} style={{padding: '0'}}>
                                                <div style={{
                                                    clear: 'both',
                                                    borderBottom: '1px solid #e5e5e5'
                                                }}></div>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Typography component="div"
                                                            style={{float: "left", fontSize: '14px'}}>
                                                    Sub Total
                                                </Typography>
                                                <Typography component="div"
                                                            style={{float: "right", fontSize: '14px'}}>
                                                    {getCountryCurrencySymbol(ticketCurrency)}{fixedFloat(noOfTicket * ticketPrice, 2)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} style={{padding: '0'}}>
                                                <div style={{
                                                    clear: 'both',
                                                    borderBottom: '1px solid #e5e5e5'
                                                }}></div>
                                            </Grid>
                                            <br/>
                                            <Grid item xs={12} sm={12}>
                                                <Typography component="div" style={{
                                                    float: "left",
                                                    fontSize: '16px',
                                                    fontWeight: '600'
                                                }}>
                                                    Total
                                                </Typography>
                                                <Typography component="span" style={{
                                                    float: 'right',
                                                    fontSize: '16px',
                                                    fontWeight: '600'
                                                }}>
                                                    {ticketCurrency} {getCountryCurrencySymbol(ticketCurrency)}{fixedFloat(noOfTicket * ticketPrice, 2)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                {!isFreeTicket && paymentMethod === payment.Stripe ? (
                                                    <div>
                                                        <div style={{width: '100%', overflow: 'auto'}}>
                                                            <div style={{display: 'inline-flex'}}>
                                                                <Card style={{
                                                                    width: '50px',
                                                                    boxShadow: 'none',
                                                                    padding: '8px',
                                                                    marginLeft: '-8px',
                                                                    background: 'transparent'
                                                                }}>
                                                                    <CardMedia
                                                                        style={{height: 0, paddingTop: '66.25%'}}
                                                                        alt="Stripe"
                                                                        image='/images/icons/masterCard.png'
                                                                        title='Stripe'
                                                                    />
                                                                </Card>
                                                                <Card style={{
                                                                    width: '50px',
                                                                    boxShadow: 'none',
                                                                    padding: '8px',
                                                                    background: 'transparent'
                                                                }}>
                                                                    <CardMedia
                                                                        style={{height: 0, paddingTop: '66.25%'}}
                                                                        alt="Stripe"
                                                                        image='/images/icons/visaCard.png'
                                                                        title='Stripe'
                                                                    />
                                                                </Card>
                                                                <Card style={{
                                                                    width: '50px',
                                                                    boxShadow: 'none',
                                                                    padding: '6px',
                                                                    background: 'transparent'
                                                                }}>
                                                                    <CardMedia
                                                                        style={{height: 0, paddingTop: '66.25%'}}
                                                                        alt="Stripe"
                                                                        image='/images/icons/americanExpress.png'
                                                                        title='Stripe'
                                                                    />
                                                                </Card>
                                                                <Card style={{
                                                                    width: '50px',
                                                                    boxShadow: 'none',
                                                                    padding: '6px',
                                                                    background: 'transparent'
                                                                }}>
                                                                    <CardMedia
                                                                        style={{height: 0, paddingTop: '66.25%'}}
                                                                        alt="Stripe"
                                                                        image='/images/icons/discover.png'
                                                                        title='Stripe'
                                                                    />
                                                                </Card>
                                                                {/*<Card style={{width: '50px', boxShadow: 'none',padding:'8px'}}>*/}
                                                                {/*    <CardMedia*/}
                                                                {/*        style={{height: 0, paddingTop: '66.25%'}}*/}
                                                                {/*        alt="Stripe"*/}
                                                                {/*        image='/images/icons/unionPay.png'*/}
                                                                {/*        title='Stripe'*/}
                                                                {/*    />*/}
                                                                {/*</Card>*/}
                                                                {/*<Card style={{width: '50px', boxShadow: 'none',padding:'8px'}}>*/}
                                                                {/*    <CardMedia*/}
                                                                {/*        style={{height: 0, paddingTop: '66.25%'}}*/}
                                                                {/*        alt="Stripe"*/}
                                                                {/*        image='/images/icons/jcb.png'*/}
                                                                {/*        title='Stripe'*/}
                                                                {/*    />*/}
                                                                {/*</Card>*/}
                                                                {/*<Card style={{width: '50px', boxShadow: 'none',padding:'8px'}}>*/}
                                                                {/*    <CardMedia*/}
                                                                {/*        style={{height: 0, paddingTop: '66.25%'}}*/}
                                                                {/*        alt="Stripe"*/}
                                                                {/*        image='/images/icons/dinnersClub.png'*/}
                                                                {/*        title='Stripe'*/}
                                                                {/*    />*/}
                                                                {/*</Card>*/}
                                                            </div>
                                                        </div>
                                                        <Typography component="div"
                                                                    style={{fontSize: '12px', color: '#7b798d'}}>
                                                            Payment processed by <a href="https://stripe.com/"
                                                                                    target="_blank"
                                                                                    style={{textDecoration: 'underline'}}>Stripe</a>
                                                        </Typography>
                                                        <br/>
                                                        <Typography component="div" style={{fontSize: '12px', color: '#7b798d'}}>
                                                            For Any Queries, contact us  at <br/>
                                                            support@hamropatro.com <br/>
                                                            01-4780563
                                                        </Typography>
                                                    </div>
                                                ) : null}
                                                {!isFreeTicket && paymentMethod === payment.Khalti ? (
                                                    <div>
                                                        <Typography component="div"
                                                                    style={{fontSize: '12px', color: '#7b798d'}}>
                                                            Payment managed by <a href="https://khalti.com/"
                                                                                  target="_blank"
                                                                                  style={{textDecoration: 'underline'}}>Khalti</a>
                                                        </Typography>
                                                        <br/>
                                                        <Typography component="div" style={{fontSize: '12px', color: '#7b798d'}}>
                                                            For Any Queries, contact us  at <br/>
                                                            support@hamropatro.com <br/>
                                                            01-4780563
                                                        </Typography>
                                                    </div>
                                                ) : null}
                                                {!isFreeTicket && paymentMethod === payment.Esewa ? (
                                                    <div>
                                                        <Typography component="div"
                                                                    style={{fontSize: '12px', color: '#7b798d'}}>
                                                            Payment managed by <a href="https://esewa.com.np/"
                                                                                  target="_blank"
                                                                                  style={{textDecoration: 'underline'}}>Esewa</a>
                                                        </Typography>
                                                        <br/>
                                                        <Typography component="div" style={{fontSize: '12px', color: '#7b798d'}}>
                                                            For Any Queries, contact us  at <br/>
                                                            support@hamropatro.com <br/>
                                                            01-4780563
                                                        </Typography>
                                                    </div>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            }
                        </Grid>
                    </DialogContent>
                    {(screenWidth === 'xs' || screenWidth === 'sm') && openTicketEntry ? (
                        <DialogActions>
                            {!isFreeTicket ? (
                                <div>Paying <span
                                    style={{fontWeight: 600}}>{ticketCurrency} {getCountryCurrencySymbol(ticketCurrency)}{fixedFloat(noOfTicket * ticketPrice, 2)}</span>
                                </div>
                            ) : null}
                            {continuePay}
                        </DialogActions>
                    ) : null}
                </Dialog>
            )}
        </div>
    );
}
