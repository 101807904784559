const usedString = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    BLOCKED: 'BLOCKED',
    EMPTY: 'EMPTY',
    NONE: 'NONE',
    PRIVATE: 'PRIVATE',
    PUBLIC: 'PUBLIC',
    DEFAULT: 'DEFAULT',
    PARTICIPANT: 'PARTICIPANT',
    ADMIN: 'ADMIN',
    MEMBER: 'MEMBER',
    GOING: 'GOING',
    INTERESTED: 'INTERESTED',
    DECLINED: 'DECLINED',
    NOT_GOING: 'NOT_GOING',
    MODERATOR: 'MODERATOR',
    Public: 'Public',
    Private: 'Private',
    USED: 'USED',
    FREE_TICKET: 'FREE_TICKET',
    FREE: 'FREE',
    Open: 'Open',
    Closed: 'Closed',
    Canceled: 'Canceled',
    Postponed: 'Postponed',
    None: 'None',
};

export const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const payment = {
    Khalti: 'Khalti',
    Stripe: 'Stripe',
    Esewa: 'Esewa',
};

export function getStatusString(statusID) {
    switch (statusID) {
        case 0:
            return "APPROVED";
        case 1:
            return "PENDING";
        case 2:
            return "BLOCKED";
        case 3:
            return "EMPTY";
        default:
            return "NONE";
    }
}

export function getGroupTypeString(typeID) {
    switch (typeID) {
        case 0:
            return "PRIVATE";
        case 1:
            return "PUBLIC";
        default:
            return "NONE";
    }
}

export function getGroupTypeStringInSentence(typeID) {
    switch (typeID) {
        case 0:
            return "Private";
        case 1:
            return "Public";
        default:
            return "None";
    }
}

export function getEventTypeStringInSentence(typeID) {
    switch (typeID) {
        case 0:
            return "Open";
        case 1:
            return "Closed";
        case 2:
            return "Canceled";
        case 3:
            return "Postponed";
        default:
            return "None";
    }
}

export function getRoleString(roleID) {
    switch (roleID) {
        case 0:
            return "DEFAULT";
        case 1:
            return "PARTICIPANT";
            // return "MODERATOR";
        case 2:
            return "ADMIN";
        case 3:
            return "MEMBER";
        default:
            return "NONE";
    }
}

export function getUserResponseString(userStatusID) {
    switch (userStatusID) {
        case 0:
            return "NOT_GOING";
        case 1:
            return "INTERESTED";
        case 2:
            return "GOING";
        case 3:
            return "DECLINED";
        default:
            return "NONE";
    }
}

export function getUserTicketStatus(ticketSts) {
    switch (ticketSts) {
        case 0:
            return "UNUSED";
        case 1:
            return "USED";
        case 2:
            return "INVALID";
        default:
            return "NONE";
    }
}

export function getCountryCurrencySymbol(countryCode) {
    switch (countryCode) {
        case 'AUD':
            return 'A$';
        case 'USD':
            return '$';
        case 'NPR':
            return 'रू ';
        case 'INR':
            return '₹ ';
        default:
            return '';
    }
}

export const currencies = [
    {
        value: 'NPR',
        label: 'Nepalese Rupee',
    },
    {
        value: 'AUD',
        label: 'Australian Dollar',
    },
    {
        value: 'USD',
        label: 'US Dollar',
    },
    // {
    //     value: 'AED',
    //     label: 'UAE dirham',
    // },
    // {
    //     value: 'CNY',
    //     label: 'Chinese Yuan Renminbi',
    // },
    // {
    //     value: 'INR',
    //     label: 'Indian rupee',
    // },
    // {
    //     value: 'JPY',
    //     label: 'Japanese yen',
    // },
    // {
    //     value: 'QAR',
    //     label: 'Qatari riyal',
    // },
    // {
    //     value: 'EUR',
    //     label: 'European euro',
    // },
];

export default usedString;