const GroupCategories = {
    "Arts": ["Design", "Fashion & Beauty", "Food", "Literature", "Performing Arts", "Spoken Word", "Visual Arts"],
    "Business": ["Business News", "Careers", "Investing", "Management & Marketing", "Shopping"],
    "Comedy": [],
    "Education": ["Educational Technology", "Higher Education", "K-12", "Language Courses", "Training"],
    "Games & Hobbies": ["Automotive", "Aviation", "Hobbies", "Other Games", "Video Games"],
    "Government & Organizations": ["Local", "National", "Non-Profit", "Regional"],
    "Health": ["Alternative Health", "Fitness & Nutrition", "Self-Help", "Sexuality", "Kids & Family"],
    "Music": ["Alternative", "Blues", "Country", "Easy Listening", "Electronic:", "Folk", "Freeform", "Hip-Hop & Rap", "Inspirational", "Jazz", "Latin", "Metal", "New Age",
        "Oldies", "Pop", "R&B & Urban", "Reggae", "Rock", "Seasonal & Holiday", "Soundtracks", "World"],
    "News & Politics": ["Conservative (Right), Liberal (Left)"],
    "Religion & Spirituality": ["Buddhism", "Christianity", "Hinduism", "Islam", "Judaism", "Other", "Spirituality"],
    "Science & Medicine": ["Medicine", "Natural Sciences", "Social Sciences"],
    "Society & Culture": ["Gay & Lesbian", "History", "Personal Journals", "Philosophy", "Places & Travel"],
    "Sports & Recreation": ["Amateur", "College & High School", "Outdoor", "Professional"],
    "TV & Film": [],
    "Technology": ["Gadgets", "Podcasting", "Software How-To", "Tech News"]
};
export default GroupCategories;