import ContentLoader from "react-content-loader";
import React from "react";

export const EventsLoader = () => (
    <ContentLoader
        speed={3}
        height={100}
        primaryColor="#dad2d2"
        secondaryColor="#ecebeb"
    >
        <rect x="10" y="28" rx="10" ry="20" width="0" height="0"/>
        <rect x="10" y="152" rx="0" ry="0" width="0" height="0"/>
        <rect x="10" y="8" rx="5" ry="5" width="80" height="72"/>
        <rect x="10" y="91" rx="0" ry="0" width="79" height="4"/>
        <rect x="110" y="0" rx="0" ry="0" width="0" height="0"/>
        <rect x="110" y="8" rx="5" ry="5" width="80" height="72"/>
        <rect x="110" y="91" rx="0" ry="0" width="79" height="4"/>
        <rect x="210" y="0" rx="0" ry="0" width="0" height="0"/>
        <rect x="210" y="8" rx="5" ry="5" width="80" height="72"/>
        <rect x="210" y="91" rx="0" ry="0" width="79" height="4"/>
        <rect x="310" y="0" rx="0" ry="0" width="0" height="0"/>
        <rect x="310" y="8" rx="5" ry="5" width="80" height="72"/>
        <rect x="310" y="91" rx="0" ry="0" width="79" height="4"/>
    </ContentLoader>
);

export const CustomLoader = () => (
    <ContentLoader
        speed={3}
        height={100}
        primaryColor="#dad2d2"
        secondaryColor="#ecebeb"
    >
        <rect x="10" y="28" rx="10" ry="20" width="0" height="0"/>
        <rect x="10" y="152" rx="0" ry="0" width="0" height="0"/>
        <rect x="10" y="8" rx="5" ry="5" width="80" height="72"/>
        <rect x="10" y="91" rx="0" ry="0" width="79" height="4"/>
        <rect x="110" y="0" rx="0" ry="0" width="0" height="0"/>
        <rect x="110" y="8" rx="5" ry="5" width="80" height="72"/>
        <rect x="110" y="91" rx="0" ry="0" width="79" height="4"/>
        <rect x="210" y="0" rx="0" ry="0" width="0" height="0"/>
        <rect x="210" y="8" rx="5" ry="5" width="80" height="72"/>
        <rect x="210" y="91" rx="0" ry="0" width="79" height="4"/>
        <rect x="310" y="0" rx="0" ry="0" width="0" height="0"/>
        <rect x="310" y="8" rx="5" ry="5" width="80" height="72"/>
        <rect x="310" y="91" rx="0" ry="0" width="79" height="4"/>
    </ContentLoader>
);
export const GroupDetailLoader = () => (
    <ContentLoader
        height={305}
        width={400}
        speed={3}
        primaryColor="#dad2d2"
        secondaryColor="#ecebeb"
    >
        <rect x="67" y="251" rx="4" ry="4" width="261" height="6"/>
        <rect x="130" y="230" rx="4" ry="4" width="136" height="5"/>
        <rect x="21" y="19" rx="5" ry="5" width="364" height="182"/>
    </ContentLoader>
);

export const PostLoader = () => (
    <ContentLoader
        height={200}
        speed={3}
        primaryColor="#dad2d2"
        secondaryColor="#ecebeb"
    >
        {/*<rect x="65" y="15" rx="4" ry="4" width="70" height="5"/>*/}
        {/*<rect x="65" y="29" rx="3" ry="3" width="136" height="5"/>*/}
        {/*<circle cx="34" cy="26" r="16"/>*/}
        {/*<rect x="143" y="16" rx="0" ry="0" width="10" height="2"/>*/}
        {/*<rect x="225" y="32" rx="0" ry="0" width="0" height="0"/>*/}
        {/*<circle cx="75" cy="53" r="7"/>*/}
        {/*<circle cx="100" cy="53" r="7"/>*/}
        <rect x="65" y="15" rx="4" ry="4" width="130" height="5" />
        <rect x="65" y="29" rx="3" ry="3" width="70" height="5" />
        <rect x="65" y="50" rx="3" ry="3" width="300" height="6" />
        <rect x="65" y="65" rx="3" ry="3" width="300" height="6" />
        <rect x="65" y="80" rx="3" ry="3" width="300" height="6" />
        <circle cx="30" cy="30" r="15" />
    </ContentLoader>
);

export const HomeLoader = () => (
    <ContentLoader
        height={100}
        speed={3}
        primaryColor="#dad2d2"
        secondaryColor="#ecebeb"
    >
        <rect x="26" y="18" rx="0" ry="0" width="26" height="23"/>
        <rect x="64" y="26" rx="0" ry="0" width="52" height="7"/>
        <circle cx="41" cy="72" r="5"/>
        <rect x="66" y="69" rx="0" ry="0" width="51" height="3"/>
        <rect x="137" y="9" rx="0" ry="0" width="2" height="116"/>
        <rect x="204" y="109" rx="0" ry="0" width="0" height="0"/>
        <rect x="150" y="11" rx="0" ry="0" width="25" height="4"/>
        <circle cx="220" cy="8" r="0"/>
        <circle cx="224" cy="13" r="5"/>
        <circle cx="242" cy="13" r="5"/>
        <rect x="263" y="8" rx="0" ry="0" width="2" height="115"/>
        <rect x="278" y="15" rx="0" ry="0" width="0" height="0"/>
        <circle cx="281" cy="22" r="4"/>
        <rect x="297" y="20" rx="0" ry="0" width="18" height="4"/>
        <rect x="287" y="43" rx="0" ry="0" width="0" height="0"/>
        <rect x="149" y="36" rx="0" ry="0" width="18" height="11"/>
        <rect x="155" y="50" rx="0" ry="0" width="10" height="4"/>
        <rect x="157" y="52" rx="0" ry="0" width="0" height="0"/>
    </ContentLoader>
);
export const UserLoader = () => (
    <ContentLoader
        height={100}
        speed={3}
        primaryColor="#dad2d2"
        secondaryColor="#ecebeb"
    >
        <circle cx="20" cy="20" r="15"/>
        <rect x="45" y="15" rx="5" ry="5" width="100" height="10"/>
        <circle cx="20" cy="60" r="15"/>
        <rect x="45" y="55" rx="5" ry="5" width="100" height="10"/>
    </ContentLoader>
);