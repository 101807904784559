import React, {Component} from 'react';
import {Link} from 'react-router-dom';
// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
// Material helpers
import {Typography, withStyles} from '@material-ui/core';
// Component styles
import styles from './styles';
import {GetGroupRequest, GetUserGroupsRequest} from "../../../../generated/frontend-community-group-service_pb";
import {getAllGroups, getGroupDetails} from "../../../../grpcRequests/groups-request";
import {eventAnalyst} from "../../../../configurations/config";
import {isAdmin, isLogin} from "../../../../configurations/global-config";
import {removeLastSpace} from "../../../../utils/converter/common";
import usedString, {
    getGroupTypeStringInSentence,
    getRoleString,
    getStatusString
} from "../../../../utils/mapper/enumToStringMapper";

// Material icons

class SidebarRight extends Component {
    state = {
        isLoading: true,
        myGroupLists: [],
        cursor: '',
        errMsg: null,
        groupID: this.props.groupId,
        categoriesList: [],
        eventID: this.props.eventId,
        userRole: '',
        userRoleSts: '',
        cType: '',
    };

    componentDidMount() {
        if (isLogin()) {
            if (this.props.groupId) {
                this.getUserRole(this.props.groupId);
            }
            this.fetchUserGroups();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (isLogin() && nextProps.groupId !== this.props.groupId) {
            this.setState({
                groupID: nextProps.groupId,
                categoriesList: []
            });
            this.getUserRole(nextProps.groupId);
        }
    }

    getUserRole = (groupId) => {
        let self = this;
        const req = new GetGroupRequest();
        req.setGroupId(groupId);
        getGroupDetails(req, 3).then(function (response) {
            const group = response.getGroup();
            const cType = getGroupTypeStringInSentence(group.getGrouptype());
            const userRole = getRoleString(group.getRole());
            const userRoleSts = getStatusString(group.getStatus());
            const categoriesList = response.getCategoryList();
            self.setState({
                cType, userRole, userRoleSts, categoriesList
            })
        }).catch(function (error) {
        })
    };

    fetchUserGroups = () => {
        let self = this;
        self.setState({
            isLoading: true
        });
        const req = new GetUserGroupsRequest();
        req.setCursor();
        req.setPagesize(5);
        getAllGroups(req, 3).then(function (response) {
            const myGroupLists = response.getGroupInfosList();
            const cursor = response.getNextpagetoken();
            const isLoading = false;
            self.setState({
                cursor,
                isLoading,
                myGroupLists
            });
        }).catch(function (error) {
            const isLoading = false;
            const errMsg = error.message;
            self.setState({
                isLoading,
                errMsg
            });
        })
    };

    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);
        const {myGroupLists, categoriesList, groupID, cType, userRole, cursor, userRoleSts} = this.state;
        const rootGroupsURl = '/c/';
        return (
            <div className={rootClassName}>
                {/*<div className={classes.search}>*/}
                {/*    <div className={classes.searchIcon}>*/}
                {/*        <SearchIcon/>*/}
                {/*    </div>*/}
                {/*    <InputBase*/}
                {/*        classes={{*/}
                {/*            root: classes.inputRoot,*/}
                {/*            input: classes.inputInput,*/}
                {/*        }}*/}
                {/*        inputProps={{'aria-label': 'Search Here'}}*/}
                {/*        placeholder="Search Here…"*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<br/>*/}
                {/*<div>{userRole}||{isAdmin()}||{groupID}||{cType}</div>*/}
                <div>
                    {groupID && (userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT || (cType === usedString.Public) || (cType === usedString.Private && userRoleSts === usedString.APPROVED && userRole === usedString.MEMBER) || isAdmin()) ? (
                        <div>
                            <Typography
                                className={classes.title}
                                variant="h6"
                                style={{fontWeight: 800}}
                            >
                                Community Channels
                            </Typography>
                            <Link
                                to={`${rootGroupsURl}${groupID + '/channel/_feed'}`}>
                                <div
                                    className={classes.product}
                                >
                                    <div>
                                        <Typography
                                            className={classes.productTitle}
                                            style={window.location.pathname.includes(encodeURIComponent('_feed')) ? {
                                                fontSize: '1em',
                                                fontWeight: 600
                                            } : {fontSize: '1em'}}
                                            color={window.location.pathname.includes(encodeURIComponent('_feed')) ? "primary" : ""}
                                            variant="h6"
                                        >
                                            #Feeds
                                        </Typography>
                                    </div>
                                </div>
                            </Link>
                            {(categoriesList.length || isAdmin()) && categoriesList.map(category => (
                                !category.getCategoryName().includes('post@') ? (
                                    <Link
                                        to={`${rootGroupsURl}${groupID + '/channel/' + removeLastSpace(category.getCategoryName())}`}>
                                        <div
                                            className={classes.product}
                                        >
                                            <div>
                                                <Typography
                                                    className={classes.productTitle}
                                                    style={window.location.pathname.includes(encodeURIComponent(removeLastSpace(category.getCategoryName()))) ? {
                                                        fontSize: '1em',
                                                        fontWeight: 600
                                                    } : {fontSize: '1em'}}
                                                    color={window.location.pathname.includes(encodeURIComponent(removeLastSpace(category.getCategoryName()))) ? "primary" : ""}
                                                    variant="h6"
                                                >
                                                    #{category.getCategoryName() === '_admin' ? 'Admin' : category.getCategoryName()}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Link>
                                ) : null
                            ))}
                            <br/>
                            <br/>
                            <hr/>
                        </div>):null}
                </div>
                {myGroupLists.length > 0 ? (
                    <div>
                        <Typography
                            className={classes.title}
                            variant="h6"
                            style={{fontWeight: 800}}
                        >
                            My Communities
                        </Typography>
                        {myGroupLists.map(group => (
                            <Link to={`${rootGroupsURl}${group.getGroupId()}/`} key={group.getGroupId()}
                                  onClick={() => eventAnalyst('sidebar-right', group.getGroupName() + '_clicked', group.getGroupName() + ' Clicked', group.getGroupName() + '-clicked')}>
                                <div
                                    className={classes.product}
                                >
                                    <div className={classes.productImageWrapper}>
                                        <img
                                            alt={group.getGroupName()}
                                            className={classes.productImage}
                                            src={group.getImage() ? group.getImage() : '/images/defaultCover.png'}
                                            title={group.getGroupName()}
                                        />
                                    </div>
                                    <div className={classes.productDetails}>
                                        <Typography
                                            className={classes.productTitle}
                                            variant="subtitle1"
                                            color={window.location.pathname.includes(group.getGroupId()) ? "primary" : ""}
                                        >
                                            {group.getGroupName()}
                                        </Typography>
                                    </div>
                                </div>
                            </Link>
                        ))}
                        {cursor ? (
                            <div
                                className={classes.viewAll}
                            ><Link to={'/communities'} className={classes.viewText}>View All ></Link></div>
                        ) : null}
                    </div>) : (<div></div>)}
            </div>
        );
    }
}

SidebarRight.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SidebarRight);
