/**
 * @fileoverview gRPC-Web generated client stub for com.hamropatro.sociallayer.io
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');


var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
const proto = {};
proto.com = {};
proto.com.hamropatro = {};
proto.com.hamropatro.sociallayer = {};
proto.com.hamropatro.sociallayer.io = require('./frontend-community-members-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.MembersServiceClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.MembersServicePromiseClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse>}
 */
const methodDescriptor_MembersService_GetAdminAndModerators = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.MembersService/GetAdminAndModerators',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest,
    proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse>}
 */
const methodInfo_MembersService_GetAdminAndModerators = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.MembersServiceClient.prototype.getAdminAndModerators =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/GetAdminAndModerators',
            request,
            metadata || {},
            methodDescriptor_MembersService_GetAdminAndModerators,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.MembersServicePromiseClient.prototype.getAdminAndModerators =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/GetAdminAndModerators',
            request,
            metadata || {},
            methodDescriptor_MembersService_GetAdminAndModerators);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetMemberListRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetMemberListResponse>}
 */
const methodDescriptor_MembersService_GetGroupMembers = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.MembersService/GetGroupMembers',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetMemberListRequest,
    proto.com.hamropatro.sociallayer.io.GetMemberListResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetMemberListRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetMemberListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetMemberListRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetMemberListResponse>}
 */
const methodInfo_MembersService_GetGroupMembers = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetMemberListResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetMemberListRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetMemberListResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetMemberListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetMemberListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetMemberListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.MembersServiceClient.prototype.getGroupMembers =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/GetGroupMembers',
            request,
            metadata || {},
            methodDescriptor_MembersService_GetGroupMembers,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetMemberListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetMemberListResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.MembersServicePromiseClient.prototype.getGroupMembers =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/GetGroupMembers',
            request,
            metadata || {},
            methodDescriptor_MembersService_GetGroupMembers);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse>}
 */
const methodDescriptor_MembersService_GetPendingMembers = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.MembersService/GetPendingMembers',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest,
    proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse>}
 */
const methodInfo_MembersService_GetPendingMembers = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.MembersServiceClient.prototype.getPendingMembers =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/GetPendingMembers',
            request,
            metadata || {},
            methodDescriptor_MembersService_GetPendingMembers,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.MembersServicePromiseClient.prototype.getPendingMembers =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/GetPendingMembers',
            request,
            metadata || {},
            methodDescriptor_MembersService_GetPendingMembers);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse>}
 */
const methodDescriptor_MembersService_GetBlockedMembers = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.MembersService/GetBlockedMembers',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest,
    proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse>}
 */
const methodInfo_MembersService_GetBlockedMembers = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.MembersServiceClient.prototype.getBlockedMembers =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/GetBlockedMembers',
            request,
            metadata || {},
            methodDescriptor_MembersService_GetBlockedMembers,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.MembersServicePromiseClient.prototype.getBlockedMembers =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/GetBlockedMembers',
            request,
            metadata || {},
            methodDescriptor_MembersService_GetBlockedMembers);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse>}
 */
const methodDescriptor_MembersService_UpdateFollowerStatus = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.MembersService/UpdateFollowerStatus',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest,
    proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse>}
 */
const methodInfo_MembersService_UpdateFollowerStatus = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.MembersServiceClient.prototype.updateFollowerStatus =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/UpdateFollowerStatus',
            request,
            metadata || {},
            methodDescriptor_MembersService_UpdateFollowerStatus,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.MembersServicePromiseClient.prototype.updateFollowerStatus =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/UpdateFollowerStatus',
            request,
            metadata || {},
            methodDescriptor_MembersService_UpdateFollowerStatus);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.AddMemberRequest,
 *   !proto.com.hamropatro.sociallayer.io.AddMemberResponse>}
 */
const methodDescriptor_MembersService_AddFollower = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.MembersService/AddFollower',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.AddMemberRequest,
    proto.com.hamropatro.sociallayer.io.AddMemberResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.AddMemberRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.AddMemberResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.AddMemberRequest,
 *   !proto.com.hamropatro.sociallayer.io.AddMemberResponse>}
 */
const methodInfo_MembersService_AddFollower = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.AddMemberResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.AddMemberRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.AddMemberResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AddMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.AddMemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.AddMemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.MembersServiceClient.prototype.addFollower =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/AddFollower',
            request,
            metadata || {},
            methodDescriptor_MembersService_AddFollower,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AddMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.AddMemberResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.MembersServicePromiseClient.prototype.addFollower =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/AddFollower',
            request,
            metadata || {},
            methodDescriptor_MembersService_AddFollower);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.AddFolloweeRequest,
 *   !proto.com.hamropatro.sociallayer.io.AddFolloweeResponse>}
 */
const methodDescriptor_MembersService_Follow = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.MembersService/Follow',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.AddFolloweeRequest,
    proto.com.hamropatro.sociallayer.io.AddFolloweeResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.AddFolloweeRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.AddFolloweeRequest,
 *   !proto.com.hamropatro.sociallayer.io.AddFolloweeResponse>}
 */
const methodInfo_MembersService_Follow = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.AddFolloweeResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.AddFolloweeRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AddFolloweeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.AddFolloweeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.AddFolloweeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.MembersServiceClient.prototype.follow =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/Follow',
            request,
            metadata || {},
            methodDescriptor_MembersService_Follow,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AddFolloweeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.AddFolloweeResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.MembersServicePromiseClient.prototype.follow =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/Follow',
            request,
            metadata || {},
            methodDescriptor_MembersService_Follow);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.DirectJoinRequest,
 *   !proto.com.hamropatro.sociallayer.io.DirectJoinResponse>}
 */
const methodDescriptor_MembersService_DirecJoin = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.MembersService/DirecJoin',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.DirectJoinRequest,
    proto.com.hamropatro.sociallayer.io.DirectJoinResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.DirectJoinRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.DirectJoinResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.DirectJoinRequest,
 *   !proto.com.hamropatro.sociallayer.io.DirectJoinResponse>}
 */
const methodInfo_MembersService_DirecJoin = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.DirectJoinResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.DirectJoinRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.DirectJoinResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.DirectJoinRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.DirectJoinResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.DirectJoinResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.MembersServiceClient.prototype.direcJoin =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/DirecJoin',
            request,
            metadata || {},
            methodDescriptor_MembersService_DirecJoin,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.DirectJoinRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.DirectJoinResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.MembersServicePromiseClient.prototype.direcJoin =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/DirecJoin',
            request,
            metadata || {},
            methodDescriptor_MembersService_DirecJoin);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse>}
 */
const methodDescriptor_MembersService_GetMemberRole = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.MembersService/GetMemberRole',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest,
    proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse>}
 */
const methodInfo_MembersService_GetMemberRole = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.MembersServiceClient.prototype.getMemberRole =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/GetMemberRole',
            request,
            metadata || {},
            methodDescriptor_MembersService_GetMemberRole,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.MembersServicePromiseClient.prototype.getMemberRole =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/GetMemberRole',
            request,
            metadata || {},
            methodDescriptor_MembersService_GetMemberRole);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest,
 *   !proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse>}
 */
const methodDescriptor_MembersService_RemoveFollowing = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.MembersService/RemoveFollowing',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest,
    proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest,
 *   !proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse>}
 */
const methodInfo_MembersService_RemoveFollowing = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.MembersServiceClient.prototype.removeFollowing =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/RemoveFollowing',
            request,
            metadata || {},
            methodDescriptor_MembersService_RemoveFollowing,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.MembersServicePromiseClient.prototype.removeFollowing =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/RemoveFollowing',
            request,
            metadata || {},
            methodDescriptor_MembersService_RemoveFollowing);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.RemoveMemberRequest,
 *   !proto.com.hamropatro.sociallayer.io.RemoveMemberResponse>}
 */
const methodDescriptor_MembersService_RemoveFollower = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.MembersService/RemoveFollower',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.RemoveMemberRequest,
    proto.com.hamropatro.sociallayer.io.RemoveMemberResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.RemoveMemberRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.RemoveMemberRequest,
 *   !proto.com.hamropatro.sociallayer.io.RemoveMemberResponse>}
 */
const methodInfo_MembersService_RemoveFollower = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.RemoveMemberResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.RemoveMemberRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.RemoveMemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.RemoveMemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.MembersServiceClient.prototype.removeFollower =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/RemoveFollower',
            request,
            metadata || {},
            methodDescriptor_MembersService_RemoveFollower,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.RemoveMemberResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.MembersServicePromiseClient.prototype.removeFollower =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/RemoveFollower',
            request,
            metadata || {},
            methodDescriptor_MembersService_RemoveFollower);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.BlockMemberRequest,
 *   !proto.com.hamropatro.sociallayer.io.BlockMemberResponse>}
 */
const methodDescriptor_MembersService_BlockMember = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.MembersService/BlockMember',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.BlockMemberRequest,
    proto.com.hamropatro.sociallayer.io.BlockMemberResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.BlockMemberRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.BlockMemberResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.BlockMemberRequest,
 *   !proto.com.hamropatro.sociallayer.io.BlockMemberResponse>}
 */
const methodInfo_MembersService_BlockMember = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.BlockMemberResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.BlockMemberRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.BlockMemberResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.BlockMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.BlockMemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.BlockMemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.MembersServiceClient.prototype.blockMember =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/BlockMember',
            request,
            metadata || {},
            methodDescriptor_MembersService_BlockMember,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.BlockMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.BlockMemberResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.MembersServicePromiseClient.prototype.blockMember =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.MembersService/BlockMember',
            request,
            metadata || {},
            methodDescriptor_MembersService_BlockMember);
    };


module.exports = proto.com.hamropatro.sociallayer.io;

