import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Material components
import {Divider, Typography, withStyles} from '@material-ui/core';

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(2)
    },
    company: {
        fontSize: '15px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5)
    }
});

class Footer extends Component {
    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <div className={rootClassName}>
                <Divider/>
                <Typography
                    className={classes.company}
                    variant="h6"
                    style={{fontWeight: 600}}
                >
                    &copy; Hamro Patro. {new Date().getFullYear()}
                </Typography>
                <Typography variant="caption">
                    All Rights Reserved | <a href="#"> Privacy </a> | <a href="#"> Terms of Service </a>
                </Typography>
            </div>
        );
    }
}

Footer.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);