function postCreatedDaysNumber(postCreated) {
    let dateDifference = postCreated - new Date().getTime();
    let totalDays=  Math.abs(dateDifference/(1000 * 3600 * 24));
    return totalDays;
}

function postCreatedInteractionPercentage(totalMember,interactionValue) {
    let interactionPer = (interactionValue/totalMember)*100;
    return interactionPer;
}

export function getPostClass(createdDate,totalCommunityMember,likeCount,commentCount) {  //(timestamp,number,number)
    return '';
    if(likeCount===0.01) return 'postAvgAtt';
    else if(postCreatedDaysNumber(createdDate) <= 15 && postCreatedDaysNumber(createdDate) >= 1) {
        let likePer = postCreatedInteractionPercentage(totalCommunityMember, likeCount);
        let commentPer = postCreatedInteractionPercentage(totalCommunityMember, commentCount);
        let createdDay = postCreatedDaysNumber(createdDate);
        let postValue = (likePer + commentPer) / createdDay;
        if (postValue >= 10) return 'postHigAtt';
        else if (postValue >= 0 && postValue <= 1) return 'postLowAtt';
        else return 'postAvgAtt';
    } else return 'postAvgAtt';
}

export function categoryRegex(value) {
    let regexVal = value.replace(/[&^`~!#$*@+(){}'"?/.<>|,_;:=\[\]% ]/g, "").replace(/-{2,}/g, '-');
    return regexVal.substr(0, 50)
}

export function removeLastSpace(word) {
    if(word)
        return word.replace(/\s+$/, '');
}

export function fixedFloat(number, digits) {
    var pow = Math.pow(10, digits);
    return Math.floor(number * pow) / pow;
}

export function addCommas(num) {
    var parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export function removeSpace(word) {
    return word.toLowerCase().replace(/\s/g, '-')
}

export function removeHyphen(word) {
    let ans = word.toLowerCase().replace(/-/g, ' ');
    return titleCase(ans);
}

export function ignoreCase(word) {
    let lowerWord = word.toLowerCase();
    if (lowerWord === 'the' || lowerWord === 'of' || lowerWord === 'and')
        return lowerWord;
    else return word;
}

export function titleCase(string) {
    var sentence = string.toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i++) {
        sentence[i] = (sentence[i][0]).toUpperCase() + sentence[i].slice(1);
        sentence[i] = ignoreCase(sentence[i]);
    }
    return sentence.join(" ");
}

export function validateFields(fieldValue) {
    let totalTicket;
    if (!fieldValue) {
        return {
            'err': true,
            'msg': 'Not a valid number'
        }
    } else if (typeof fieldValue !== 'number') {
        let result = (fieldValue - Math.floor(fieldValue)) !== 0;
        if (result) {
            return {
                'err': true,
                'msg': 'Should not contain decimal value'
            }
        } else if (Math.sign(fieldValue) !== 1) {
            return {
                'err': true,
                'msg': 'Should not be negative'
            }
        } else {
            totalTicket = parseInt(fieldValue);
            return {
                'err': false,
                'msg': '',
                'newValue': totalTicket
            }
        }
    } else {
        totalTicket = fieldValue;
        return {
            'err': false,
            'msg': '',
            'newValue': totalTicket
        }
    }
};