export default theme => ({
    product: {
        paddingBottom: theme.spacing(),
        paddingTop: theme.spacing(),
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            '& $productTitle': {
                color: theme.palette.primary.main
            },
            '& $productImage': {
                opacity: 0.8
            },
        }
    },
    userImageWrapper: {
        borderRadius: '50%',
        overflow: 'hidden',
        height: '50px',
        width: '50px',
        border: '1px solid #e5e5e5'
    },
    productImage: {
        width: '100%',
        height: 'auto'
    },
    productDetails: {
        marginLeft: theme.spacing(2),
        flexGrow: 1
    },
    productTitle: {},
    eventLocation: {
        padding: '5px 0',
    },
    detailTabs: {
        boxShadow: 'unset',
        alignItems: 'center'
    }
});