export function shortenString(string, maxLength) {
    if (string && string.length >= maxLength) {
        var trimmedString = string.substr(0, maxLength);
        // trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
        trimmedString = trimmedString.concat("...");
        return trimmedString;
    } else
        return string;
}

export function memberCountValue(member) {
    if (member) {
        if (member >= 1 && member < 1000) return member;
        else if (member >= 1000 && member < 1000000) {
            let number = roundOff((member / 1000), false);
            return number + 'K';
        } else if (member >= 1000000 && member < 10000000) {
            let mNumber = roundOff(member / 1000000, false);
            return mNumber + 'M';
        } else return member;
    } else return member;
}

export function roundOff(number, roundOff) {
    if (roundOff)
        return number.toFixed(1);
    else return number.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]
}

export function getUsrNotificationCount(allCount) {
    let pendingList;
    if (allCount >= 10) pendingList = '9+';
    else if (allCount > 0) pendingList = allCount;
    else pendingList = 0;
    return pendingList;
}