/**
 * @fileoverview gRPC-Web generated client stub for com.hamropatro.sociallayer.io
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');


var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
const proto = {};
proto.com = {};
proto.com.hamropatro = {};
proto.com.hamropatro.sociallayer = {};
proto.com.hamropatro.sociallayer.io = require('./frontend-user-activity-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.UserActivityServiceClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.UserActivityServicePromiseClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetUserActivityRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetUserActivityResponse>}
 */
const methodDescriptor_UserActivityService_GetUserLikeActivity = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.UserActivityService/GetUserLikeActivity',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetUserActivityRequest,
    proto.com.hamropatro.sociallayer.io.GetUserActivityResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetUserActivityRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetUserActivityResponse>}
 */
const methodInfo_UserActivityService_GetUserLikeActivity = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetUserActivityResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetUserActivityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetUserActivityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.UserActivityServiceClient.prototype.getUserLikeActivity =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.UserActivityService/GetUserLikeActivity',
            request,
            metadata || {},
            methodDescriptor_UserActivityService_GetUserLikeActivity,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetUserActivityResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.UserActivityServicePromiseClient.prototype.getUserLikeActivity =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.UserActivityService/GetUserLikeActivity',
            request,
            metadata || {},
            methodDescriptor_UserActivityService_GetUserLikeActivity);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetUserActivityRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetUserActivityResponse>}
 */
const methodDescriptor_UserActivityService_GetUserCommentActivity = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.UserActivityService/GetUserCommentActivity',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetUserActivityRequest,
    proto.com.hamropatro.sociallayer.io.GetUserActivityResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetUserActivityRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetUserActivityResponse>}
 */
const methodInfo_UserActivityService_GetUserCommentActivity = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetUserActivityResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetUserActivityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetUserActivityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.UserActivityServiceClient.prototype.getUserCommentActivity =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.UserActivityService/GetUserCommentActivity',
            request,
            metadata || {},
            methodDescriptor_UserActivityService_GetUserCommentActivity,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetUserActivityResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.UserActivityServicePromiseClient.prototype.getUserCommentActivity =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.UserActivityService/GetUserCommentActivity',
            request,
            metadata || {},
            methodDescriptor_UserActivityService_GetUserCommentActivity);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse>}
 */
const methodDescriptor_UserActivityService_GetNotificationFeed = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.UserActivityService/GetNotificationFeed',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest,
    proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse>}
 */
const methodInfo_UserActivityService_GetNotificationFeed = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.UserActivityServiceClient.prototype.getNotificationFeed =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.UserActivityService/GetNotificationFeed',
            request,
            metadata || {},
            methodDescriptor_UserActivityService_GetNotificationFeed,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.UserActivityServicePromiseClient.prototype.getNotificationFeed =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.UserActivityService/GetNotificationFeed',
            request,
            metadata || {},
            methodDescriptor_UserActivityService_GetNotificationFeed);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.NotificationCountRequest,
 *   !proto.com.hamropatro.sociallayer.io.NotificationCountResponse>}
 */
const methodDescriptor_UserActivityService_GetNotificationCount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.UserActivityService/GetNotificationCount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.NotificationCountRequest,
    proto.com.hamropatro.sociallayer.io.NotificationCountResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.NotificationCountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.NotificationCountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.NotificationCountRequest,
 *   !proto.com.hamropatro.sociallayer.io.NotificationCountResponse>}
 */
const methodInfo_UserActivityService_GetNotificationCount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.NotificationCountResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.NotificationCountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.NotificationCountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.NotificationCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.NotificationCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.NotificationCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.UserActivityServiceClient.prototype.getNotificationCount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.UserActivityService/GetNotificationCount',
            request,
            metadata || {},
            methodDescriptor_UserActivityService_GetNotificationCount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.NotificationCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.NotificationCountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.UserActivityServicePromiseClient.prototype.getNotificationCount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.UserActivityService/GetNotificationCount',
            request,
            metadata || {},
            methodDescriptor_UserActivityService_GetNotificationCount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse>}
 */
const methodDescriptor_UserActivityService_UpdateSeenStatus = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.UserActivityService/UpdateSeenStatus',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest,
    proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse>}
 */
const methodInfo_UserActivityService_UpdateSeenStatus = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.UserActivityServiceClient.prototype.updateSeenStatus =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.UserActivityService/UpdateSeenStatus',
            request,
            metadata || {},
            methodDescriptor_UserActivityService_UpdateSeenStatus,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.UserActivityServicePromiseClient.prototype.updateSeenStatus =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.UserActivityService/UpdateSeenStatus',
            request,
            metadata || {},
            methodDescriptor_UserActivityService_UpdateSeenStatus);
    };


module.exports = proto.com.hamropatro.sociallayer.io;

