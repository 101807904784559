/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$account$common$types_pb = require('./frontend-account-common-types_pb.js');
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.AccountByUser', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.AccountUser', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.BusinessAccount', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.InvitedUser', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.Role', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.displayName = 'proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccount: (f = msg.getBusinessAccount()) && proto.com.hamropatro.everestbackend.account.BusinessAccount.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest;
    return proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.everestbackend.account.BusinessAccount;
                reader.readMessage(value, proto.com.hamropatro.everestbackend.account.BusinessAccount.deserializeBinaryFromReader);
                msg.setBusinessAccount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccount();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            proto.com.hamropatro.everestbackend.account.BusinessAccount.serializeBinaryToWriter
        );
    }
};


/**
 * optional BusinessAccount business_account = 1;
 * @return {?proto.com.hamropatro.everestbackend.account.BusinessAccount}
 */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.prototype.getBusinessAccount = function () {
    return /** @type{?proto.com.hamropatro.everestbackend.account.BusinessAccount} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.everestbackend.account.BusinessAccount, 1));
};


/** @param {?proto.com.hamropatro.everestbackend.account.BusinessAccount|undefined} value */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.prototype.setBusinessAccount = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.prototype.clearBusinessAccount = function () {
    this.setBusinessAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest.prototype.hasBusinessAccount = function () {
    return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.displayName = 'proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            appid: jspb.Message.getFieldWithDefault(msg, 1, ""),
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest;
    return proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppid();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string appId = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.prototype.getAppid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.prototype.setAppid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.displayName = 'proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse;
    return proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
};


/**
 * optional string business_account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.displayName = 'proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccount: (f = msg.getBusinessAccount()) && proto.com.hamropatro.everestbackend.account.BusinessAccount.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest;
    return proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.everestbackend.account.BusinessAccount;
                reader.readMessage(value, proto.com.hamropatro.everestbackend.account.BusinessAccount.deserializeBinaryFromReader);
                msg.setBusinessAccount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccount();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            proto.com.hamropatro.everestbackend.account.BusinessAccount.serializeBinaryToWriter
        );
    }
};


/**
 * optional BusinessAccount business_account = 1;
 * @return {?proto.com.hamropatro.everestbackend.account.BusinessAccount}
 */
proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.prototype.getBusinessAccount = function () {
    return /** @type{?proto.com.hamropatro.everestbackend.account.BusinessAccount} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.everestbackend.account.BusinessAccount, 1));
};


/** @param {?proto.com.hamropatro.everestbackend.account.BusinessAccount|undefined} value */
proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.prototype.setBusinessAccount = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.prototype.clearBusinessAccount = function () {
    this.setBusinessAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest.prototype.hasBusinessAccount = function () {
    return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse.displayName = 'proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse;
    return proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest.displayName = 'proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest;
    return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
};


/**
 * optional string business_account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse.displayName = 'proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse;
    return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest.displayName = 'proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest;
    return proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
};


/**
 * optional string business_account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.displayName = 'proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.repeatedFields_ = [2];


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccount: (f = msg.getBusinessAccount()) && proto.com.hamropatro.everestbackend.account.BusinessAccount.toObject(includeInstance, f),
            membersList: jspb.Message.toObjectList(msg.getMembersList(),
                proto.com.hamropatro.everestbackend.account.AccountUser.toObject, includeInstance)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse;
    return proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.everestbackend.account.BusinessAccount;
                reader.readMessage(value, proto.com.hamropatro.everestbackend.account.BusinessAccount.deserializeBinaryFromReader);
                msg.setBusinessAccount(value);
                break;
            case 2:
                var value = new proto.com.hamropatro.everestbackend.account.AccountUser;
                reader.readMessage(value, proto.com.hamropatro.everestbackend.account.AccountUser.deserializeBinaryFromReader);
                msg.addMembers(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccount();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            proto.com.hamropatro.everestbackend.account.BusinessAccount.serializeBinaryToWriter
        );
    }
    f = message.getMembersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            2,
            f,
            proto.com.hamropatro.everestbackend.account.AccountUser.serializeBinaryToWriter
        );
    }
};


/**
 * optional BusinessAccount business_account = 1;
 * @return {?proto.com.hamropatro.everestbackend.account.BusinessAccount}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.prototype.getBusinessAccount = function () {
    return /** @type{?proto.com.hamropatro.everestbackend.account.BusinessAccount} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.everestbackend.account.BusinessAccount, 1));
};


/** @param {?proto.com.hamropatro.everestbackend.account.BusinessAccount|undefined} value */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.prototype.setBusinessAccount = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.prototype.clearBusinessAccount = function () {
    this.setBusinessAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.prototype.hasBusinessAccount = function () {
    return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AccountUser members = 2;
 * @return {!Array<!proto.com.hamropatro.everestbackend.account.AccountUser>}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.prototype.getMembersList = function () {
    return /** @type{!Array<!proto.com.hamropatro.everestbackend.account.AccountUser>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.everestbackend.account.AccountUser, 2));
};


/** @param {!Array<!proto.com.hamropatro.everestbackend.account.AccountUser>} value */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.prototype.setMembersList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.hamropatro.everestbackend.account.AccountUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.everestbackend.account.AccountUser}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.prototype.addMembers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.hamropatro.everestbackend.account.AccountUser, opt_index);
};


proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.prototype.clearMembersList = function () {
    this.setMembersList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.displayName = 'proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            currentBusinessAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest;
    return proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setCurrentBusinessAccountId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCurrentBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string current_business_account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.prototype.getCurrentBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.prototype.setCurrentBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.displayName = 'proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.repeatedFields_ = [2];


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccount: (f = msg.getBusinessAccount()) && proto.com.hamropatro.everestbackend.account.BusinessAccount.toObject(includeInstance, f),
            membersList: jspb.Message.toObjectList(msg.getMembersList(),
                proto.com.hamropatro.everestbackend.account.AccountUser.toObject, includeInstance)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse;
    return proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.everestbackend.account.BusinessAccount;
                reader.readMessage(value, proto.com.hamropatro.everestbackend.account.BusinessAccount.deserializeBinaryFromReader);
                msg.setBusinessAccount(value);
                break;
            case 2:
                var value = new proto.com.hamropatro.everestbackend.account.AccountUser;
                reader.readMessage(value, proto.com.hamropatro.everestbackend.account.AccountUser.deserializeBinaryFromReader);
                msg.addMembers(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccount();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            proto.com.hamropatro.everestbackend.account.BusinessAccount.serializeBinaryToWriter
        );
    }
    f = message.getMembersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            2,
            f,
            proto.com.hamropatro.everestbackend.account.AccountUser.serializeBinaryToWriter
        );
    }
};


/**
 * optional BusinessAccount business_account = 1;
 * @return {?proto.com.hamropatro.everestbackend.account.BusinessAccount}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.prototype.getBusinessAccount = function () {
    return /** @type{?proto.com.hamropatro.everestbackend.account.BusinessAccount} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.everestbackend.account.BusinessAccount, 1));
};


/** @param {?proto.com.hamropatro.everestbackend.account.BusinessAccount|undefined} value */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.prototype.setBusinessAccount = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.prototype.clearBusinessAccount = function () {
    this.setBusinessAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.prototype.hasBusinessAccount = function () {
    return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AccountUser members = 2;
 * @return {!Array<!proto.com.hamropatro.everestbackend.account.AccountUser>}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.prototype.getMembersList = function () {
    return /** @type{!Array<!proto.com.hamropatro.everestbackend.account.AccountUser>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.everestbackend.account.AccountUser, 2));
};


/** @param {!Array<!proto.com.hamropatro.everestbackend.account.AccountUser>} value */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.prototype.setMembersList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.hamropatro.everestbackend.account.AccountUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.everestbackend.account.AccountUser}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.prototype.addMembers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.hamropatro.everestbackend.account.AccountUser, opt_index);
};


proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.prototype.clearMembersList = function () {
    this.setMembersList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.displayName = 'proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
            pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest;
    return proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setPageSize(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeUint32(
            1,
            f
        );
    }
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional uint32 page_size = 1;
 * @return {number}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest.prototype.setPageToken = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.displayName = 'proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccountsList: jspb.Message.toObjectList(msg.getBusinessAccountsList(),
                proto.com.hamropatro.everestbackend.account.AccountByUser.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse;
    return proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.everestbackend.account.AccountByUser;
                reader.readMessage(value, proto.com.hamropatro.everestbackend.account.AccountByUser.deserializeBinaryFromReader);
                msg.addBusinessAccounts(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccountsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.everestbackend.account.AccountByUser.serializeBinaryToWriter
        );
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * repeated AccountByUser business_accounts = 1;
 * @return {!Array<!proto.com.hamropatro.everestbackend.account.AccountByUser>}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.prototype.getBusinessAccountsList = function () {
    return /** @type{!Array<!proto.com.hamropatro.everestbackend.account.AccountByUser>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.everestbackend.account.AccountByUser, 1));
};


/** @param {!Array<!proto.com.hamropatro.everestbackend.account.AccountByUser>} value */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.prototype.setBusinessAccountsList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.everestbackend.account.AccountByUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.everestbackend.account.AccountByUser}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.prototype.addBusinessAccounts = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.everestbackend.account.AccountByUser, opt_index);
};


proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.prototype.clearBusinessAccountsList = function () {
    this.setBusinessAccountsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.prototype.setNextPageToken = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.displayName = 'proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            redirectlink: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest}
 */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest;
    return proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest}
 */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setRedirectlink(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getRedirectlink();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string business_account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string redirectLink = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.prototype.getRedirectlink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest.prototype.setRedirectlink = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.displayName = 'proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            link: jspb.Message.getFieldWithDefault(msg, 1, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse}
 */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse;
    return proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse}
 */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLink(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLink();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
};


/**
 * optional string link = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.prototype.getLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.prototype.setLink = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.displayName = 'proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.repeatedFields_ = [2];


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            emailList: jspb.Message.getRepeatedField(msg, 2),
            role: jspb.Message.getFieldWithDefault(msg, 3, 0),
            redirectlink: jspb.Message.getFieldWithDefault(msg, 4, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest;
    return proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.addEmail(value);
                break;
            case 3:
                var value = /** @type {!proto.com.hamropatro.everestbackend.account.Role} */ (reader.readEnum());
                msg.setRole(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setRedirectlink(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getEmailList();
    if (f.length > 0) {
        writer.writeRepeatedString(
            2,
            f
        );
    }
    f = message.getRole();
    if (f !== 0.0) {
        writer.writeEnum(
            3,
            f
        );
    }
    f = message.getRedirectlink();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
};


/**
 * optional string business_account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string email = 2;
 * @return {!Array<string>}
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.prototype.getEmailList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.prototype.setEmailList = function (value) {
    jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.prototype.addEmail = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.prototype.clearEmailList = function () {
    this.setEmailList([]);
};


/**
 * optional Role role = 3;
 * @return {!proto.com.hamropatro.everestbackend.account.Role}
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.prototype.getRole = function () {
    return /** @type {!proto.com.hamropatro.everestbackend.account.Role} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.com.hamropatro.everestbackend.account.Role} value */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.prototype.setRole = function (value) {
    jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string redirectLink = 4;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.prototype.getRedirectlink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest.prototype.setRedirectlink = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse.displayName = 'proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse;
    return proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.displayName = 'proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            secretKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
            redirectlink: jspb.Message.getFieldWithDefault(msg, 3, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest;
    return proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSecretKey(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setRedirectlink(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSecretKey();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getRedirectlink();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
};


/**
 * optional string secret_key = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.prototype.getSecretKey = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.prototype.setSecretKey = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string redirectLink = 3;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.prototype.getRedirectlink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest.prototype.setRedirectlink = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse.displayName = 'proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse;
    return proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.displayName = 'proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
            pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest;
    return proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setPageSize(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeUint32(
            2,
            f
        );
    }
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
};


/**
 * optional string business_account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 page_size = 2;
 * @return {number}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest.prototype.setPageToken = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.displayName = 'proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            membersList: jspb.Message.toObjectList(msg.getMembersList(),
                proto.com.hamropatro.everestbackend.account.AccountUser.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse;
    return proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.everestbackend.account.AccountUser;
                reader.readMessage(value, proto.com.hamropatro.everestbackend.account.AccountUser.deserializeBinaryFromReader);
                msg.addMembers(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMembersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.everestbackend.account.AccountUser.serializeBinaryToWriter
        );
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * repeated AccountUser members = 1;
 * @return {!Array<!proto.com.hamropatro.everestbackend.account.AccountUser>}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.prototype.getMembersList = function () {
    return /** @type{!Array<!proto.com.hamropatro.everestbackend.account.AccountUser>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.everestbackend.account.AccountUser, 1));
};


/** @param {!Array<!proto.com.hamropatro.everestbackend.account.AccountUser>} value */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.prototype.setMembersList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.everestbackend.account.AccountUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.everestbackend.account.AccountUser}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.prototype.addMembers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.everestbackend.account.AccountUser, opt_index);
};


proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.prototype.clearMembersList = function () {
    this.setMembersList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.prototype.setNextPageToken = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.displayName = 'proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            role: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest}
 */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest;
    return proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest}
 */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 4:
                var value = /** @type {!proto.com.hamropatro.everestbackend.account.Role} */ (reader.readEnum());
                msg.setRole(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getRole();
    if (f !== 0.0) {
        writer.writeEnum(
            4,
            f
        );
    }
};


/**
 * optional string business_account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.prototype.setUserId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Role role = 4;
 * @return {!proto.com.hamropatro.everestbackend.account.Role}
 */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.prototype.getRole = function () {
    return /** @type {!proto.com.hamropatro.everestbackend.account.Role} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.com.hamropatro.everestbackend.account.Role} value */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest.prototype.setRole = function (value) {
    jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse.displayName = 'proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse}
 */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse;
    return proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse}
 */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.displayName = 'proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest;
    return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
};


/**
 * optional string business_account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest.prototype.setUserId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse.displayName = 'proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse;
    return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.displayName = 'proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            email: jspb.Message.getFieldWithDefault(msg, 3, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest;
    return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmail(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getEmail();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
};


/**
 * optional string business_account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.prototype.getEmail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest.prototype.setEmail = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse.displayName = 'proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse;
    return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.everestbackend.account.BusinessAccount.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.BusinessAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.BusinessAccount.displayName = 'proto.com.hamropatro.everestbackend.account.BusinessAccount';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.repeatedFields_ = [13];


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.BusinessAccount.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccount} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.BusinessAccount.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            name: jspb.Message.getFieldWithDefault(msg, 3, ""),
            about: jspb.Message.getFieldWithDefault(msg, 4, ""),
            url: jspb.Message.getFieldWithDefault(msg, 5, ""),
            coverImage: jspb.Message.getFieldWithDefault(msg, 6, ""),
            logo: jspb.Message.getFieldWithDefault(msg, 7, ""),
            country: jspb.Message.getFieldWithDefault(msg, 8, ""),
            location: (f = msg.getLocation()) && frontend$account$common$types_pb.Location.toObject(includeInstance, f),
            category: jspb.Message.getFieldWithDefault(msg, 10, ""),
            subcategory: jspb.Message.getFieldWithDefault(msg, 11, ""),
            address: jspb.Message.getFieldWithDefault(msg, 12, ""),
            domainsList: jspb.Message.getRepeatedField(msg, 13),
            verified: jspb.Message.getFieldWithDefault(msg, 14, false),
            suspended: jspb.Message.getFieldWithDefault(msg, 15, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessAccount}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.BusinessAccount;
    return proto.com.hamropatro.everestbackend.account.BusinessAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessAccount}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setAbout(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setUrl(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setCoverImage(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setLogo(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setCountry(value);
                break;
            case 9:
                var value = new frontend$account$common$types_pb.Location;
                reader.readMessage(value, frontend$account$common$types_pb.Location.deserializeBinaryFromReader);
                msg.setLocation(value);
                break;
            case 10:
                var value = /** @type {string} */ (reader.readString());
                msg.setCategory(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());
                msg.setSubcategory(value);
                break;
            case 12:
                var value = /** @type {string} */ (reader.readString());
                msg.setAddress(value);
                break;
            case 13:
                var value = /** @type {string} */ (reader.readString());
                msg.addDomains(value);
                break;
            case 14:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setVerified(value);
                break;
            case 15:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSuspended(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.BusinessAccount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getAbout();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getUrl();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
    f = message.getCoverImage();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
        );
    }
    f = message.getLogo();
    if (f.length > 0) {
        writer.writeString(
            7,
            f
        );
    }
    f = message.getCountry();
    if (f.length > 0) {
        writer.writeString(
            8,
            f
        );
    }
    f = message.getLocation();
    if (f != null) {
        writer.writeMessage(
            9,
            f,
            frontend$account$common$types_pb.Location.serializeBinaryToWriter
        );
    }
    f = message.getCategory();
    if (f.length > 0) {
        writer.writeString(
            10,
            f
        );
    }
    f = message.getSubcategory();
    if (f.length > 0) {
        writer.writeString(
            11,
            f
        );
    }
    f = message.getAddress();
    if (f.length > 0) {
        writer.writeString(
            12,
            f
        );
    }
    f = message.getDomainsList();
    if (f.length > 0) {
        writer.writeRepeatedString(
            13,
            f
        );
    }
    f = message.getVerified();
    if (f) {
        writer.writeBool(
            14,
            f
        );
    }
    f = message.getSuspended();
    if (f) {
        writer.writeBool(
            15,
            f
        );
    }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setName = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string about = 4;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getAbout = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setAbout = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string url = 5;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setUrl = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cover_image = 6;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getCoverImage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setCoverImage = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string logo = 7;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getLogo = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setLogo = function (value) {
    jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string country = 8;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getCountry = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setCountry = function (value) {
    jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Location location = 9;
 * @return {?proto.com.hamropatro.everestbackend.account.Location}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getLocation = function () {
    return /** @type{?proto.com.hamropatro.everestbackend.account.Location} */ (
        jspb.Message.getWrapperField(this, frontend$account$common$types_pb.Location, 9));
};


/** @param {?proto.com.hamropatro.everestbackend.account.Location|undefined} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setLocation = function (value) {
    jspb.Message.setWrapperField(this, 9, value);
};


proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.clearLocation = function () {
    this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.hasLocation = function () {
    return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string category = 10;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getCategory = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setCategory = function (value) {
    jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string subcategory = 11;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getSubcategory = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setSubcategory = function (value) {
    jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string address = 12;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getAddress = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setAddress = function (value) {
    jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated string domains = 13;
 * @return {!Array<string>}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getDomainsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/** @param {!Array<string>} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setDomainsList = function (value) {
    jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.addDomains = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.clearDomainsList = function () {
    this.setDomainsList([]);
};


/**
 * optional bool verified = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getVerified = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setVerified = function (value) {
    jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool suspended = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.getSuspended = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.com.hamropatro.everestbackend.account.BusinessAccount.prototype.setSuspended = function (value) {
    jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.AccountByUser = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.AccountByUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.AccountByUser.displayName = 'proto.com.hamropatro.everestbackend.account.AccountByUser';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.AccountByUser.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.AccountByUser} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.AccountByUser.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            accountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            name: jspb.Message.getFieldWithDefault(msg, 4, ""),
            logo: jspb.Message.getFieldWithDefault(msg, 5, ""),
            role: jspb.Message.getFieldWithDefault(msg, 6, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.AccountByUser}
 */
proto.com.hamropatro.everestbackend.account.AccountByUser.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.AccountByUser;
    return proto.com.hamropatro.everestbackend.account.AccountByUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.AccountByUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.AccountByUser}
 */
proto.com.hamropatro.everestbackend.account.AccountByUser.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setLogo(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setRole(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.AccountByUser.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.AccountByUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.AccountByUser.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getLogo();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
    f = message.getRole();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
        );
    }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.setUserId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.setName = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string logo = 5;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.getLogo = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.setLogo = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string role = 6;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.getRole = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AccountByUser.prototype.setRole = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.AccountUser = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.AccountUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.AccountUser.displayName = 'proto.com.hamropatro.everestbackend.account.AccountUser';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.AccountUser.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.AccountUser.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.AccountUser} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.AccountUser.toObject = function (includeInstance, msg) {
        var f, obj = {
            appid: jspb.Message.getFieldWithDefault(msg, 1, ""),
            accountid: jspb.Message.getFieldWithDefault(msg, 2, ""),
            userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
            username: jspb.Message.getFieldWithDefault(msg, 4, ""),
            profileImage: jspb.Message.getFieldWithDefault(msg, 5, ""),
            email: jspb.Message.getFieldWithDefault(msg, 6, ""),
            role: jspb.Message.getFieldWithDefault(msg, 7, 0),
            status: jspb.Message.getFieldWithDefault(msg, 8, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.AccountUser}
 */
proto.com.hamropatro.everestbackend.account.AccountUser.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.AccountUser;
    return proto.com.hamropatro.everestbackend.account.AccountUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.AccountUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.AccountUser}
 */
proto.com.hamropatro.everestbackend.account.AccountUser.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountid(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserid(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setUsername(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setProfileImage(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmail(value);
                break;
            case 7:
                var value = /** @type {!proto.com.hamropatro.everestbackend.account.Role} */ (reader.readEnum());
                msg.setRole(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.AccountUser.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.AccountUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.AccountUser.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppid();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getAccountid();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getUserid();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getUsername();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getProfileImage();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
    f = message.getEmail();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
        );
    }
    f = message.getRole();
    if (f !== 0.0) {
        writer.writeEnum(
            7,
            f
        );
    }
    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(
            8,
            f
        );
    }
};


/**
 * optional string appId = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.getAppid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.setAppid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string accountId = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.getAccountid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.setAccountid = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string userId = 3;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.getUserid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.setUserid = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.getUsername = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.setUsername = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string profile_image = 5;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.getProfileImage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.setProfileImage = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.getEmail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.setEmail = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Role role = 7;
 * @return {!proto.com.hamropatro.everestbackend.account.Role}
 */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.getRole = function () {
    return /** @type {!proto.com.hamropatro.everestbackend.account.Role} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {!proto.com.hamropatro.everestbackend.account.Role} value */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.setRole = function (value) {
    jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.AccountUser.prototype.setStatus = function (value) {
    jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.displayName = 'proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest;
    return proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setDomain(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDomain();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.prototype.getDomain = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.prototype.setDomain = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.displayName = 'proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse;
    return proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setDomain(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDomain();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.prototype.getDomain = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.prototype.setDomain = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.displayName = 'proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            domain: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest;
    return proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setDomain(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getDomain();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string domain = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.prototype.getDomain = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest.prototype.setDomain = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.displayName = 'proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse;
    return proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setDomain(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDomain();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.prototype.getDomain = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.prototype.setDomain = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest.displayName = 'proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            domain: jspb.Message.getFieldWithDefault(msg, 1, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest;
    return proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setDomain(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDomain();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest.prototype.getDomain = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest.prototype.setDomain = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse.displayName = 'proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse;
    return proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.displayName = 'proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            link: jspb.Message.getFieldWithDefault(msg, 3, ""),
            status: jspb.Message.getFieldWithDefault(msg, 4, ""),
            userInfo: (f = msg.getUserInfo()) && proto.com.hamropatro.everestbackend.account.InvitedUser.toObject(includeInstance, f),
            lastupdated: jspb.Message.getFieldWithDefault(msg, 6, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink;
    return proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLink(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setStatus(value);
                break;
            case 5:
                var value = new proto.com.hamropatro.everestbackend.account.InvitedUser;
                reader.readMessage(value, proto.com.hamropatro.everestbackend.account.InvitedUser.deserializeBinaryFromReader);
                msg.setUserInfo(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLastupdated(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getLink();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getUserInfo();
    if (f != null) {
        writer.writeMessage(
            5,
            f,
            proto.com.hamropatro.everestbackend.account.InvitedUser.serializeBinaryToWriter
        );
    }
    f = message.getLastupdated();
    if (f !== 0) {
        writer.writeInt64(
            6,
            f
        );
    }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string link = 3;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.getLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.setLink = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.setStatus = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional InvitedUser user_info = 5;
 * @return {?proto.com.hamropatro.everestbackend.account.InvitedUser}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.getUserInfo = function () {
    return /** @type{?proto.com.hamropatro.everestbackend.account.InvitedUser} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.everestbackend.account.InvitedUser, 5));
};


/** @param {?proto.com.hamropatro.everestbackend.account.InvitedUser|undefined} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.setUserInfo = function (value) {
    jspb.Message.setWrapperField(this, 5, value);
};


proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.clearUserInfo = function () {
    this.setUserInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.hasUserInfo = function () {
    return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 lastUpdated = 6;
 * @return {number}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.getLastupdated = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.prototype.setLastupdated = function (value) {
    jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.displayName = 'proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            link: jspb.Message.getFieldWithDefault(msg, 3, ""),
            email: jspb.Message.getFieldWithDefault(msg, 4, ""),
            role: jspb.Message.getFieldWithDefault(msg, 5, ""),
            status: jspb.Message.getFieldWithDefault(msg, 6, ""),
            userInfo: (f = msg.getUserInfo()) && proto.com.hamropatro.everestbackend.account.InvitedUser.toObject(includeInstance, f),
            lastupdated: jspb.Message.getFieldWithDefault(msg, 8, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation;
    return proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLink(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmail(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setRole(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setStatus(value);
                break;
            case 7:
                var value = new proto.com.hamropatro.everestbackend.account.InvitedUser;
                reader.readMessage(value, proto.com.hamropatro.everestbackend.account.InvitedUser.deserializeBinaryFromReader);
                msg.setUserInfo(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLastupdated(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getLink();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getEmail();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getRole();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
        );
    }
    f = message.getUserInfo();
    if (f != null) {
        writer.writeMessage(
            7,
            f,
            proto.com.hamropatro.everestbackend.account.InvitedUser.serializeBinaryToWriter
        );
    }
    f = message.getLastupdated();
    if (f !== 0) {
        writer.writeInt64(
            8,
            f
        );
    }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string link = 3;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.getLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.setLink = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.getEmail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.setEmail = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string role = 5;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.getRole = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.setRole = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.setStatus = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional InvitedUser user_info = 7;
 * @return {?proto.com.hamropatro.everestbackend.account.InvitedUser}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.getUserInfo = function () {
    return /** @type{?proto.com.hamropatro.everestbackend.account.InvitedUser} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.everestbackend.account.InvitedUser, 7));
};


/** @param {?proto.com.hamropatro.everestbackend.account.InvitedUser|undefined} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.setUserInfo = function (value) {
    jspb.Message.setWrapperField(this, 7, value);
};


proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.clearUserInfo = function () {
    this.setUserInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.hasUserInfo = function () {
    return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 lastUpdated = 8;
 * @return {number}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.getLastupdated = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.prototype.setLastupdated = function (value) {
    jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest.displayName = 'proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            accountId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest;
    return proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.displayName = 'proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessaccountinvitationlinksList: jspb.Message.toObjectList(msg.getBusinessaccountinvitationlinksList(),
                proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.toObject, includeInstance)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse;
    return proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink;
                reader.readMessage(value, proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.deserializeBinaryFromReader);
                msg.addBusinessaccountinvitationlinks(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessaccountinvitationlinksList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink.serializeBinaryToWriter
        );
    }
};


/**
 * repeated BusinessAccountInvitationLink businessAccountInvitationLinks = 1;
 * @return {!Array<!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink>}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.prototype.getBusinessaccountinvitationlinksList = function () {
    return /** @type{!Array<!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink, 1));
};


/** @param {!Array<!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink>} value */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.prototype.setBusinessaccountinvitationlinksList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.prototype.addBusinessaccountinvitationlinks = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.everestbackend.account.BusinessAccountInvitationLink, opt_index);
};


proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.prototype.clearBusinessaccountinvitationlinksList = function () {
    this.setBusinessaccountinvitationlinksList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.displayName = 'proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            link: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest;
    return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLink(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getLink();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.prototype.getLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest.prototype.setLink = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse.displayName = 'proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse;
    return proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest.displayName = 'proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            accountId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest;
    return proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.displayName = 'proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessaccountinvitationsList: jspb.Message.toObjectList(msg.getBusinessaccountinvitationsList(),
                proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.toObject, includeInstance)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse;
    return proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation;
                reader.readMessage(value, proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.deserializeBinaryFromReader);
                msg.addBusinessaccountinvitations(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessaccountinvitationsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation.serializeBinaryToWriter
        );
    }
};


/**
 * repeated BusinessAccountInvitation businessAccountInvitations = 1;
 * @return {!Array<!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation>}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.prototype.getBusinessaccountinvitationsList = function () {
    return /** @type{!Array<!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation, 1));
};


/** @param {!Array<!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation>} value */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.prototype.setBusinessaccountinvitationsList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation}
 */
proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.prototype.addBusinessaccountinvitations = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.everestbackend.account.BusinessAccountInvitation, opt_index);
};


proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.prototype.clearBusinessaccountinvitationsList = function () {
    this.setBusinessaccountinvitationsList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.InvitedUser = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.InvitedUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.InvitedUser.displayName = 'proto.com.hamropatro.everestbackend.account.InvitedUser';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.InvitedUser.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.InvitedUser} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.InvitedUser.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            username: jspb.Message.getFieldWithDefault(msg, 2, ""),
            displayname: jspb.Message.getFieldWithDefault(msg, 3, ""),
            coverPhotoUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
            photoUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
            email: jspb.Message.getFieldWithDefault(msg, 6, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.InvitedUser}
 */
proto.com.hamropatro.everestbackend.account.InvitedUser.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.InvitedUser;
    return proto.com.hamropatro.everestbackend.account.InvitedUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.InvitedUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.InvitedUser}
 */
proto.com.hamropatro.everestbackend.account.InvitedUser.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setUsername(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setDisplayname(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setCoverPhotoUrl(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setPhotoUrl(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmail(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.InvitedUser.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.InvitedUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.InvitedUser.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getUsername();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getDisplayname();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getCoverPhotoUrl();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getPhotoUrl();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
    f = message.getEmail();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
        );
    }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.setUserId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.getUsername = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.setUsername = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string displayName = 3;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.getDisplayname = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.setDisplayname = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cover_photo_url = 4;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.getCoverPhotoUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.setCoverPhotoUrl = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string photo_url = 5;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.getPhotoUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.setPhotoUrl = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.getEmail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.InvitedUser.prototype.setEmail = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest.displayName = 'proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest;
    return proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
};


/**
 * optional string business_account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest.displayName = 'proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest;
    return proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest}
 */
proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
};


/**
 * optional string business_account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.displayName = 'proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse;
    return proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.displayName = 'proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse;
    return proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse}
 */
proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest.displayName = 'proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessid: jspb.Message.getFieldWithDefault(msg, 1, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest;
    return proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessid(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessid();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
};


/**
 * optional string businessId = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest.prototype.getBusinessid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest.prototype.setBusinessid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.displayName = 'proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.repeatedFields_ = [2];


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            domainsList: jspb.Message.getRepeatedField(msg, 2)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse;
    return proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.addDomains(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getDomainsList();
    if (f.length > 0) {
        writer.writeRepeatedString(
            2,
            f
        );
    }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string domains = 2;
 * @return {!Array<string>}
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.prototype.getDomainsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.prototype.setDomainsList = function (value) {
    jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.prototype.addDomains = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.prototype.clearDomainsList = function () {
    this.setDomainsList([]);
};


/**
 * @enum {number}
 */
proto.com.hamropatro.everestbackend.account.Role = {
    DEFAULT: 0,
    PARTICIPANT: 1,
    ADMIN: 2
};

goog.object.extend(exports, proto.com.hamropatro.everestbackend.account);
