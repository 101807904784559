import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles, withWidth} from "@material-ui/core";
import styles from "./styles";
import {Dashboard as DashboardLayout} from 'layouts';
import {GetPostDetailRequest, GetPostRequest, ReactionDetail} from "../../../generated/frontend-post-service_pb";
import {getPostById, getSinglePostDetails} from "../../../grpcRequests/posts-request";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {get_date_obj, timeEvent, timeRemaining} from "../../../utils/converter/dateConverter";
import {Reaction, ReactionType} from "../../../generated/frontend-social-common-types_pb";
import IconButton from "@material-ui/core/IconButton";
import {Message as MessageIcon, ThumbUp, ThumbUpAltOutlined as ThumbOutline} from "@material-ui/icons";
import {GroupDetailLoader} from "../../../components/Skeleton/EventsSket";
import PostLikedUsers from "../../../components/Dialogs/PostLikedUsers";
import {ReactOnContentRequest} from "../../../generated/frontend-reaction-service_pb";
import {likeUnlikePost} from "../../../grpcRequests/reaction-request";
import GetUserComments from "../../../components/CommentBox/GetUserComments";
import {GetGroupRequest} from "../../../generated/frontend-community-group-service_pb";
import {getGroupDetails} from "../../../grpcRequests/groups-request";
import usedString, {
    getGroupTypeStringInSentence,
    getRoleString,
    getStatusString
} from "../../../utils/mapper/enumToStringMapper";
import SnackBar from "../../../components/SnackBar/SnackBar";
import {eventAnalyst} from "../../../configurations/config";
import {checkIfLogin, isAdmin, isLogin, userInfo, userWhoCanPost} from "../../../configurations/global-config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Images from "../../../components/MultipleImages";
import UserPostMenus from "../../../components/UserPostMenus";
import classNames from "classnames";
import {getPostClass} from "../../../utils/converter/common";
import LoginRequiredDialog from "../../../components/Dialogs/LoginRequiredDialog";
import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton} from "react-share";
import {metaDataSet} from "../../../utils/MetaData";
import compose from "recompose/compose";
import Comments from "../component/Comments";
import {shortenString} from "../../../utils/converter/shortenString";
import RenderText from "../component/RenderText";


class PostDetails extends Component {
    state = {
        mainGroupID: this.props.match.params.groupId,
        postId: this.props.match.params.postId,
        postDetails: null,
        likedBy: '',
        userLiked: '',
        likeCount: '',
        allReactions: '',
        disableLike: '',
        userComments: [],
        postedImages: [],
        resizedImages: [],
        isLoadingPost: true,
        openShowUsers: false,
        userRole: '',
        userRoleStatus: '',
        cType: '',
        groupMembers: '',
        title: '',

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null,

        isCommented: false,
        commentedId: null,
        isViewed: false,
        viewedId: null,

        openLoginDialog:false,
        openJoinGroupDialog:false

    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.fetchPostDetails(this.props.match.params.groupId, this.props.match.params.postId);
        this.fetchPostReactions(this.props.match.params.postId, true);
        this.getUserRole(this.props.match.params.groupId);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            mainGroupID: nextProps.match.params.groupId,
            postId: nextProps.match.params.postId,
            userComments:[],
        });
        if (nextProps.match.params.postId !== this.props.match.params.postId) {
            this.fetchPostDetails(nextProps.match.params.groupId,nextProps.match.params.postId);
            this.fetchPostReactions(nextProps.match.params.postId, true);
            this.getUserRole(nextProps.match.params.groupId);
        }
    }

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    getUserResponseFromUserPost = (actionName, isActionCompleted,key) => {
        this.setState({snackOpen: false});
        let self = this;
        if (isActionCompleted && actionName === 'delete') {
            self.snackbar(true, 'Post deleted Successfully', 'success', 5000);
            window.location.href = "/c/" + self.state.mainGroupID;
        }
        else if (isActionCompleted && actionName === 'edited') {
            self.snackbar(true, 'Post edited Successfully', 'success', 5000);
            self.fetchPostDetails(self.state.mainGroupID, key);
        }
        else {
            self.snackbar(true, 'Error while deleting post', 'error', 5000);
        }
    };

    getStatusFromChild = (action, status, ID) => {
        if (action === 'view') {
            this.setState({userComments: [],isViewed: status, viewedId: ID});
        }else if (action === 'delete') {
            let userReactions= new ReactionDetail();
            userReactions.setTotalComments((this.state.allReactions.getTotalComments())-1);
            this.setState({
                allReactions:userReactions
            });
        } else {
            this.setState({userComments: [], isCommented: status, commentedId: ID, isViewed: status});
            if (this.state.isCommented) {
                this.fetchPostReactions(this.state.commentedId, false);
            }
        }
    };

    getUserRole = (groupId) => {
        let self = this;
        const req = new GetGroupRequest();
        req.setGroupId(groupId);
        getGroupDetails(req, 3).then(function (response) {
            const group = response.getGroup();
            const title = group.getName();
            const cType = getGroupTypeStringInSentence(group.getGrouptype());
            const groupMembers = group.getFollowerscount();
            const userRole = getRoleString(group.getRole());
            const userRoleStatus = getStatusString(group.getStatus());
            self.setState({
                cType, userRole, title, groupMembers, userRoleStatus
            })
        }).catch(function (error) {

        })
    };

    fetchPostDetails = (groupId,postID) => {
        let self = this;
        self.setState({
            isLoadingPost: true
        });
        const req = new GetPostRequest();
        req.setGroupId(groupId);
        req.setPostId(postID);
        getPostById(req, 3).then(function (response) {
            eventAnalyst('community_post_detail', 'community_post_detail_fetch', groupId + '-' + postID + '-community post detail fetch success', 'community-post-detail-fetch-success');
            const postDetails = response.getPost();
            let postedImages = [];
            postDetails.getContent().getDataMap().forEach(function (value, key) {
                if (key.includes('IMAGE_URL_') || key.includes('IMAGE') || key.includes('IMAGE_')) {
                    postedImages.push(value);
                }
            });
            let resizedImages = [];
            postDetails.getContent().getMetadataMap().forEach(function (value, key) {
                if (key.includes('RESIZED_IMAGE')) {
                    resizedImages.push(value);
                }
            });
            metaDataSet(postDetails.getPostOwner().getAccountName() + "'s Post", postDetails.getContent().getDataMap().get("TEXT"), window.location.href, resizedImages[0] ? resizedImages[0] : '');

            const isLoadingPost = false;
            self.setState({
                postDetails,
                isLoadingPost,
                postedImages,
                resizedImages
            });
        }).catch(function (error) {
            metaDataSet(self.state.title ? self.state.title + "'s Post" : 'Community Post', '', window.location.href, '');
            eventAnalyst('community_post_detail', 'community_post_detail_fetch', groupId + '-' + postID + '-community post detail fetch fail', 'community-post-detail-fetch-fail');
            self.snackbar(true, 'Error fetching post details', 'error', 5000);
            const isLoadingPost = false;
            const postDetails = '';
            self.setState({
                isLoadingPost,
                postDetails
            });
        })
    };

    fetchPostReactions = (postID, fetchComment) => {
        let self = this;
        const req = new GetPostDetailRequest();

        const reactionObj = new Reaction();
        reactionObj.setPostUri(postID);
        reactionObj.setReactionType(ReactionType.LIKE);

        req.setReaction(reactionObj);
        req.setPageSize(3);
        req.setPageToken();

        getSinglePostDetails(req, 3).then(function (response) {
            const reactions = response.getReactionDetail();
            const doUserLike = response.getLiked();
            const likedUser = response.getListofreactedusersList();
            let userCommentsList;
            if (fetchComment)
                userCommentsList = response.getCommentDetailList();
            else
                userCommentsList = [];
            self.setState({
                allReactions: reactions,
                userLiked: doUserLike,
                likeCount: 0,
                likedBy: likedUser,
                userComments: userCommentsList

            });
        }).catch(function (error) {

        })
    };


    likePost = (postID) => {
        if (!isLogin()) {
            this.openLoginDialog()
        }
        else if(!this.canInteractInGroup()){
            this.openJoinDialog()
        }
        else {
            let self = this;
            this.setState({
                disableLike: true
            });
            const req = new ReactOnContentRequest();
            const reactionObj = new Reaction();
            reactionObj.setPostUri(postID);
            if (this.state.userLiked) {
                self.setState({
                    userLiked: false,
                    likeCount: -1,
                });
                reactionObj.setReactionType(ReactionType.UNLIKE);
            } else {
                self.setState({
                    userLiked: true,
                    likeCount: +1,
                });
                reactionObj.setReactionType(ReactionType.LIKE);
            }
            req.setReaction(reactionObj);
            likeUnlikePost(req).then(function (response) {
                self.setState({
                    disableLike: false
                });
                if (self.state.userComments.length <= 3 && self.state.isViewed)
                    self.fetchPostReactions(postID, false);
                else
                    self.fetchPostReactions(postID, true);
            }).catch(function (error) {
                self.setState({
                    disableLike: false
                })
            })
        }
    };


    getResFrmChild = (status) => {
        this.setState({openShowUsers: false});
    };

    openLikedByDialogs = (postId) => {
        this.setState({openShowUsers: true, sendPostId: postId});
    };

    getLoginDialogRes = (clickedButton, key) => {
        this.setState({openLoginDialog: false});
        if (clickedButton === "login") {
            checkIfLogin(window.location.pathname);
        }
    };

    canInteractInGroup= () => {
        let userRole = this.state.userRole;
        let groupRoleStatus = this.state.userRoleStatus;
        return userWhoCanPost(userRole, groupRoleStatus);
    };

    getJoinGroupDialogRes = (clickedButton, key) => {
        this.setState({openJoinGroupDialog: false});
        if (clickedButton === "view community") {
            window.location.pathname = '/c/' + this.state.mainGroupID;
        }
    };


    openLoginDialog = () => {
        this.setState({openLoginDialog: true});
    };

    openJoinDialog = () => {
        this.setState({openJoinGroupDialog: true});
    };

    render() {
        const {classes, width} = this.props;
        const {cType, groupMembers, title, mainGroupID, postDetails, allReactions, likeCount, likedBy, userLiked, isLoadingPost, sendPostId, openShowUsers, userRole, userRoleStatus, userComments, postId, isViewed, postedImages, resizedImages, disableLike, openLoginDialog, openJoinGroupDialog} = this.state;
        const shareUrl = window.location.href;
        const isMobile = ['xs', 'sm', 'md'].includes(width);
        const shareTitle = '';
        const rootGroupsURl = '/c/' + mainGroupID;
        return (
            <DashboardLayout title={`${title ? title + "'s Post" : "Community Post"}`} onBack={`/c/${mainGroupID}`}
                             linkTo={`/c/${mainGroupID}`} groupId={mainGroupID}>
                <div className={classes.root}>
                    {!isLoadingPost ? (
                        <div>
                            {postDetails ? (
                                <div>
                                    <div>
                                        <ListItem alignItems="flex-start" className={classes.commentWrapper}
                                                  style={isMobile ? {
                                                      display: "inline-block", paddingTop: '1px',
                                                      paddingBottom: '1px'
                                                  } : {
                                                      display: "flex", paddingTop: '1px',
                                                      paddingBottom: '1px'
                                                  }}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    alt={postDetails.getPostOwner().getAccountName()}
                                                    style={isMobile ? {display: 'none'} : {
                                                        backgroundImage: `url('${postDetails.getPostOwner().getAccountImage()}')`,
                                                        backgroundRepeat: 'round'
                                                    }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                className={classNames((postDetails.getContentid() && (postDetails.getPostOwner().getAccountId() === userInfo().user_id || isAdmin() || userRole === usedString.ADMIN)) ? getPostClass(get_date_obj(postDetails.getContentid()).getTime(), groupMembers, allReactions ? allReactions.getLikes() : 0.01, allReactions ? allReactions.getTotalComments() : 0) : '', classes.listCover)}
                                                primary={
                                                    <React.Fragment>
                                                        <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                                            <Avatar
                                                                alt={postDetails.getPostOwner().getAccountName()}
                                                                style={isMobile ? {
                                                                    backgroundImage: `url('${postDetails.getPostOwner().getAccountImage()}')`,
                                                                    backgroundRepeat: 'round',
                                                                    width: '25px',
                                                                    height: '25px',
                                                                    marginRight: '8px'
                                                                } : {display: 'none'}}
                                                            />
                                                            <Typography
                                                                component="div"
                                                                variant="body1"
                                                                style={{fontWeight: 600, lineHeight: 1.2}}
                                                                className={classes.inline}
                                                                color="textPrimary"
                                                            >{postDetails.getPostOwner().getAccountName()}
                                                                <div className={classes.timeUUID}><span className={classes.postTime}
                                                                                                        title={get_date_obj(postDetails.getContentid()).toLocaleString()}>{timeRemaining(get_date_obj(postDetails.getContentid()))}</span> <span title={timeEvent(postDetails.getLastUpdated())} className={classes.postTime}>{postDetails.getEdited() ? '(edited)':null}</span>
                                                                </div>
                                                            </Typography>

                                                        </div>
                                                        {/*    menu Here*/}
                                                        <div style={{display: "inline-flex", float: 'right'}}>
                                                            {cType === 'Public' ? (
                                                                <div style={{display: "inline-flex"}}>
                                                                    <FacebookShareButton
                                                                        url={shareUrl}
                                                                        quote={shareTitle + postDetails.getContent().getDataMap().get("TEXT")}
                                                                        className="socialShareIcons">
                                                                        <FacebookIcon
                                                                            size={25}
                                                                            round/>
                                                                    </FacebookShareButton>
                                                                    <TwitterShareButton
                                                                        url={shareUrl}
                                                                        title={shareTitle + postDetails.getContent().getDataMap().get("TEXT")}
                                                                        className="socialShareIcons">
                                                                        <TwitterIcon
                                                                            size={25}
                                                                            round/>
                                                                    </TwitterShareButton>
                                                                </div>) : null}
                                                            {postDetails.getPostOwner().getAccountId() === userInfo().user_id || isAdmin() || userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT ? (
                                                                <UserPostMenus
                                                                    postText={postDetails.getContent().getDataMap().get("TEXT")}
                                                                    ownerID={postDetails.getPostOwner().getAccountId()}
                                                                    sendUserResponse={this.getUserResponseFromUserPost}
                                                                    postID={postDetails.getContentid()}
                                                                    groupID={mainGroupID}
                                                                    userRole={userRole}
                                                                    userPostData={postDetails}
                                                                    groupCategory={postDetails.getCategory()}
                                                                />) : (<span>&nbsp;</span>)}
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        {postDetails.getContent().getDataMap().get("TEXT") ? (
                                                            <Typography
                                                                component="div"
                                                                className={classes.textPost}
                                                                color="textPrimary"
                                                                style={{
                                                                    // fontWeight: 600,
                                                                    whiteSpace: 'pre-line'
                                                                }}
                                                            >
                                                                <div
                                                                    className={classes.postedText}>
                                                                    <RenderText
                                                                        text={postDetails.getContent().getDataMap().get("TEXT")}/>
                                                                </div>
                                                            </Typography>
                                                        ) : (<div></div>)}
                                                        {/*multipleImages*/}
                                                        {postedImages.length ? (
                                                            <div
                                                                className={classes.msgImageWrapper}>
                                                                <Images images={postedImages}
                                                                        resizedImages={resizedImages.length ? resizedImages : postedImages}/>
                                                            </div>
                                                        ) : (<div></div>)}
                                                        {/*Post Reaction and Comment*/}
                                                        <div>
                                                            <div className={classes.userAction}>
                                                                <div>
                                                                    <IconButton aria-label="Like"
                                                                                onClick={disableLike ? '' : () => this.likePost(postDetails.getContentid())}>
                                                                        {userLiked ?
                                                                            <ThumbUp color="secondary"/> :
                                                                            <ThumbOutline/>}
                                                                    </IconButton>
                                                                    <span> {allReactions && allReactions.getLikes() > 0 ? allReactions.getLikes() + (likeCount) : ''} </span>
                                                                    <IconButton
                                                                        disabled
                                                                        style={{color: '#0000008a'}}
                                                                        aria-label="Message">
                                                                        <MessageIcon/>
                                                                    </IconButton>
                                                                    <span> {allReactions && allReactions.getTotalComments() > 0 ? allReactions.getTotalComments() : ''} </span>
                                                                </div>
                                                            </div>
                                                            <div className={classes.likedUser}
                                                                 onClick={() => this.openLikedByDialogs(postDetails.getContentid())}>
                                                                {likedBy ? (
                                                                    <ul className={classes.likedUnOrder}>
                                                                        {likedBy.map(liked => (
                                                                            <li className={classes.likedList}>
                                                                                <Avatar
                                                                                    alt={liked.getAccountName()}
                                                                                    key={liked.getAccountId()}
                                                                                    title={liked.getAccountName()}
                                                                                    style={{
                                                                                        backgroundImage: `url('${liked.getAccountImage() ? liked.getAccountImage().getImageUrl() : ''}')`,
                                                                                        backgroundRepeat: 'round'
                                                                                    }}
                                                                                    // src={liked.getAccountImage() ? liked.getAccountImage().getImageUrl() : ''}
                                                                                    className={classes.likedUserImg}/>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                ) : (<div></div>)}
                                                                {((allReactions && allReactions.getLikes() > 0 ? allReactions.getLikes() + (likeCount) : 0) - (likedBy ? likedBy.length : 0)) > 0 ? (
                                                                    <Avatar className={classes.likedUserImg}
                                                                            style={{
                                                                                margin: '1px',
                                                                                marginLeft: '-7px'
                                                                            }}>
                                                                        <span
                                                                            className={classes.morePeople}
                                                                            title="more likes">
                                                                            +{(allReactions && allReactions.getLikes() > 0 ? allReactions.getLikes() + (likeCount) : 0) - (likedBy ? likedBy.length : 0)}
                                                                        </span>
                                                                    </Avatar>
                                                                ) : (<div></div>)}
                                                            </div>
                                                        </div>
                                                        {/*All User Comments*/}
                                                        <div className={classes.clearBoth}>
                                                            {userComments || isViewed ? (
                                                                <GetUserComments
                                                                    groupName={title}
                                                                    groupId={mainGroupID}
                                                                    commentPostId={postId}
                                                                    userRole={userRole}
                                                                    userRoleStatus={userRoleStatus}
                                                                    totalComments={allReactions && allReactions.getTotalComments() > 0 ? allReactions.getTotalComments() : ''}
                                                                    postTitle={postDetails.getContent().getDataMap().get("TEXT") ? shortenString(postDetails.getContent().getDataMap().get("TEXT"), 25) : ''}
                                                                    viewComments={(userComments && userComments.length > 0)}
                                                                    sendResponse={this.getStatusFromChild}/>
                                                            ) : (<div></div>)}

                                                        </div>
                                                        <div>
                                                            {userComments ? (
                                                                <div>
                                                                    {userComments.length > 0 ? (
                                                                        <Comments groupId={mainGroupID}
                                                                                  groupName={title}
                                                                                  userComments={userComments}
                                                                                  isMobile={isMobile}
                                                                                  rootGroupsURl={rootGroupsURl}
                                                                                  postTitle={postDetails.getContent().getDataMap().get("TEXT") ? shortenString(postDetails.getContent().getDataMap().get("TEXT"), 25) : ''}
                                                                                  userRole={userRole}
                                                                                  userRoleStatus={userRoleStatus}
                                                                                  postId={postId}
                                                                                  sendResponse={this.getStatusFromChild}/>
                                                                    ) : (<div></div>)}
                                                                </div>
                                                            ) : (<div style={{textAlign: 'center'}}><CircularProgress
                                                                size={20}/></div>)}
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </div>
                                </div>
                            ) : (<div></div>)}
                        </div>
                    ) : (<div><GroupDetailLoader/></div>)}
                    <div>
                        {openShowUsers ? (
                            <PostLikedUsers sendCloseReport={this.getResFrmChild} postId={sendPostId}/>) : (
                            <div></div>)}
                    </div>
                    {/*Snack BAr Show*/}
                    <div>{this.state.snackOpen ? (
                        <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                                  snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                    ) : (<span></span>)}
                    </div>
                    {/*Snack BAr Show*/}

                    <div>
                        {openLoginDialog ? (
                            <LoginRequiredDialog
                                openDialog={openLoginDialog} dialogHeader=""
                                postId="124"
                                dialogContent={"You are not logged in. Please login to continue and using more feature of the community."}
                                button1="cancel"
                                button2="login"
                                sendUserResponse={this.getLoginDialogRes}
                            />
                        ) : (<div></div>)}
                    </div>
                    <div>
                        {openJoinGroupDialog ? (
                            <LoginRequiredDialog
                                openDialog={openJoinGroupDialog} dialogHeader=""
                                postId="124"
                                dialogContent={"You are not associated with '"+title+"'. Please join the community to continue and start posting to the community."}
                                button1="cancel"
                                button2="view community"
                                sendUserResponse={this.getJoinGroupDialogRes}
                            />
                        ) : (<div></div>)}
                    </div>
                </div>
            </DashboardLayout>
        )
    }
}

PostDetails.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withWidth())(PostDetails);