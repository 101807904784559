import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles, withWidth} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import styles from "./styles";
import compose from "recompose/compose";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import {userInfo} from "../../configurations/global-config";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {CommentOnContentRequest} from "../../generated/frontend-comment-service_pb";
import {CommentMessage, MessageType} from "../../generated/frontend-social-common-types_pb";
import {createCommentOnPost} from "../../grpcRequests/comments-request";


const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeEditBox} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class EditCommentBox extends Component {
    state = {
        open: this.props.openDialog,
        dialogHeader: this.props.dialogHeader,
        dialogContent: this.props.dialogContent,
        postKey: this.props.postId,
        commentPostId: this.props.commentPostId,
        multiline:true,
        userCommentText:this.props.userCommentText,
        disable:false,
        creatingComment:false
    };

    sendResponse = (actionType, actionStatus, editedKey) => {
        this.props.sendUserResponse(actionType, actionStatus, editedKey);
    };

    postCommentChange = (event) => {
        this.setState({
            userCommentText: event.target.value
        });
    };

    handleKeyPress = (event) => {
        if (!event.shiftKey && event.key === 'Enter') {
            this.setState({
                multiline: false,
            });
            if (this.state.userCommentText && this.state.userCommentText.trim().length !== 0) {
                this.submitComment();
            }
        }

    };

    submitComment = () => {
        let self = this;
        self.setState({disable: true, snackOpen: false,creatingComment:true});
        const req = new CommentOnContentRequest();
        const commentObj = new CommentMessage();
        commentObj.setText(this.state.userCommentText);

        req.setPostUri(this.props.postId);
        req.setCommentId(this.props.commentId);
        req.setComment(commentObj);
        req.setCommentType(MessageType.TEXT);
        //req.setBusinessAccountInfo(accountInfo); // only used when the user is logged in to business account
        createCommentOnPost(req).then(function (response) {
            self.handleClose();
            self.sendResponse('edited', true, response.getCommentId());
        }).catch(function (error) {
            self.setState({
                disable: false, creatingComment: false, multiline: true
            });
            self.sendResponse('edited', false, self.props.commentId);
        })
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };



    render() {
        const {open, multiline,disable,creatingComment,userCommentText} = this.state;
        const {width, classes,commentId,dialogHeader,postId} = this.props;
        const isMobile = ['xs', 'sm'].includes(width);
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    fullScreen={isMobile}
                    fullWidth
                    maxWidth={'sm'}
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        {dialogHeader}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>
                                <Paper className={classes.editChatBox}>
                                    <IconButton
                                        size="small"
                                        className={classes.floatLeft}
                                        aria-label="Menu">
                                        <Avatar
                                            alt={userInfo().displayName}
                                            style={{
                                                backgroundImage: `url('${userInfo().photo_url}')`,
                                                backgroundRepeat: 'round',
                                            }}
                                            className={classes.chatBoxAvatar}/>
                                    </IconButton>
                                    <InputBase
                                        className={classes.postContent}
                                        placeholder="Comment on this post"
                                        multiline={multiline}
                                        rowsMin={5}
                                        inputProps={{'aria-label': 'Add Comment'}}
                                        value={userCommentText}
                                        onChange={this.postCommentChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                </Paper>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.buttonWrapper}>
                            <Button variant="contained" color="secondary" onClick={()=>this.submitComment()}
                                    style={{minWidth:'200px'}}
                                    className={classes.postButton} disabled={!userCommentText || disable || userCommentText.trim().length === 0}>
                                {creatingComment ? (<span>Saving Changes&nbsp; &nbsp;<CircularProgress
                                    size={12}/> </span>) : 'Save Changes'}
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

EditCommentBox.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles),
    withWidth()
)(EditCommentBox);