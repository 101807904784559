export default theme => ({
    root: {
        padding: theme.spacing(2),
        minHeight: '85vh'
    },
    item: {
        height: '100%',
    },
    avatar: {
        margin: 5,
        width: 33,
        height: 33,
        border: '1px solid #e5e5e5'
    },
    nameWrapper: {
        padding: '10px 5px !important',
    },
    groupName: {
        maxHeight: '77px',
        overflow: 'hidden'
    },
    upComingEventsWrapper: {
        padding: '7px 5px',
    },
    goingEvent: {
        float: 'right',
        marginTop: '-5px'
    },
    name: {
        fontSize: '15px',
        fontWeight: 600,
        color: theme.palette.text.primary,
        clear: 'both',
        lineHeight: 1.2,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    count: {
        fontSize: '11px',
        color: '#5a5858',
        clear: 'both',
        padding: '2px 0'
    },
    eventsDesc: {
        fontSize: '15px',
        height: '68px',
        overflow: 'hidden',
        fontWeight: 400,
        color: theme.palette.text.primary,
        padding: '5px 15px 10px',
        textAlign: 'center'
    },
    avatarWrapper: {
        padding: '10px',
        paddingTop: '5px',
        height: '60px',
    },
    card: {
        background: '#f4f6f7',
        boxShadow: 'none',
        width: '150px'
    },
    titleTopBar: {
        marginTop: '25px',
        marginBottom: '15px'
    },
    boxItems: {
        marginTop: '20px',
        marginBottom: '25px'
    },
    floatRight: {
        float: 'right'
    },
    floatLeft: {
        float: 'left'
    },
    buttonView: {
        fontSize: '16px',
        boxShadow: 'none',
        color: '#000000',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    homeCardWrapper: {
        display: 'inline-flex',
        overflow: 'auto',
        width: '100%',
        margin: '5px 0'
    },
    groupCard: {
        margin: '10px'
    },
    singleCard: {
        boxShadow: 'none',
        width: '165px',
        height: '100%',
    },
    singleCardEvent: {
        boxShadow: 'none',
        width: '210px',
        height: '100%',
    },
    noMemberWrapper: {
        margin: '10px 0',
        padding: '10px',
        borderRadius: '5px',
        background: '#66aef1d4',
        color: '#253d49',
        fontSize: '16px'
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
        borderRadius: '10px'
    },
    mediaEvent: {
        height: 0,
        paddingTop: '56.25%',
        borderRadius: '10px'
    },
    actionWrapper: {
        height: '100%'
    },
    flexWrapper: {
        display: 'inline-flex',
    },
    morePeople: {
        fontSize: '13px',
        fontWeight: '600',
    },
    topBarWrapper: {
        fontSize: '11px',
        color: '#5a5858',
        padding: '0 5px'
    },
    createdBy: {
        float: 'left',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    daysRemain: {
        float: 'right', padding: '5px'
    },
    simpleUnderline: {
        paddingTop: '5px',
        clear: 'both',
        borderBottom: '1px solid #e5e5e5'
    },
    //    seemore
    seeMoreWrapper: {
        marginTop: '35px', fontSize: '13px', textAlign: 'center', margin: '10px'
    },
    iconWrapper: {
        padding: '10px', background: '#f4f6f7', borderRadius: '50%'
    },
    textSeeMore: {
        paddingTop: '10px', fontWeight: 600
    },
    iconTextWrapper: {
        padding: '15px',
        '&:hover': {
            background: '#f4f6f7',
            cursor: 'pointer',
            borderRadius: '8px'
        },
    },
});