import {BusinessGroupsServiceServer as srv} from "../grpcClients/service-clients-factory";
import {MetaHeaders} from "../configurations/config";
import {retryCase} from "../utils/Common";

export function fetchBusinessGroupDetails(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getBusinessAccountProfileAsUser(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            fetchBusinessGroupDetails(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function createBusinessGroup(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.createBusinessAccount(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function updateBusinessGroupDetails(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.updateBusinessAccount(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function inviteUserToBusinessGroup(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.inviteUsersToBusinessAccount(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function acceptDirectJoinAsRole(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.acceptInvitationToBusinessAccount(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function deleteGroup(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.deleteBusinessAccount(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function generateDirectLink(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.generateBusinessAccountShareLink(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function deleteMemberFromGroup(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.deleteBusinessAccountMember(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function changeUserGroupRole(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.changeBusinessAccountMemberRole(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}
