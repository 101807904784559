/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
goog.object.extend(proto, frontend$social$common$types_pb);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.AddCategoryRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.AddCategoryResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.Category', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetCategoriesRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetCategoriesResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetGroupRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetGroupResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.Group', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GroupInfo', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GroupType', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.Member', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GroupInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GroupInfo.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GroupInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GroupInfo.displayName = 'proto.com.hamropatro.sociallayer.io.GroupInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.Group = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.Group.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.Group, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.Group.displayName = 'proto.com.hamropatro.sociallayer.io.Group';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.Category = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.Category, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.Category.displayName = 'proto.com.hamropatro.sociallayer.io.Category';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.Member = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.Member, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.Member.displayName = 'proto.com.hamropatro.sociallayer.io.Member';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetGroupRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetGroupRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetGroupResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetGroupResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetGroupResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest.displayName = 'proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.displayName = 'proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest.displayName = 'proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.displayName = 'proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest.displayName = 'proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.displayName = 'proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetCategoriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetCategoriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetCategoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetCategoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.AddCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.AddCategoryRequest.displayName = 'proto.com.hamropatro.sociallayer.io.AddCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.AddCategoryResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.AddCategoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.AddCategoryResponse.displayName = 'proto.com.hamropatro.sociallayer.io.AddCategoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.displayName = 'proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.displayName = 'proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      groupNotificationEnable: jspb.Message.getFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest;
  return proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setGroupNotificationEnable(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getGroupNotificationEnable();
  if (f) {
    writer.writeBool(
        2,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool group_notification_enable = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.prototype.getGroupNotificationEnable = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest.prototype.setGroupNotificationEnable = function (value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      success: jspb.Message.getFieldWithDefault(msg, 1, false),
      updatedStatus: jspb.Message.getFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse;
  return proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSuccess(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setUpdatedStatus(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
        1,
        f
    );
  }
  f = message.getUpdatedStatus();
  if (f) {
    writer.writeBool(
        2,
        f
    );
  }
};


/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.prototype.getSuccess = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.prototype.setSuccess = function (value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool updated_status = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.prototype.getUpdatedStatus = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.prototype.setUpdatedStatus = function (value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      maxGroupPushCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      maxContentPushCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest;
  return proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMaxGroupPushCount(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMaxContentPushCount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getMaxGroupPushCount();
  if (f !== 0) {
    writer.writeInt32(
        2,
        f
    );
  }
  f = message.getMaxContentPushCount();
  if (f !== 0) {
    writer.writeInt32(
        3,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 max_group_push_count = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.prototype.getMaxGroupPushCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.prototype.setMaxGroupPushCount = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 max_content_push_count = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.prototype.getMaxContentPushCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest.prototype.setMaxContentPushCount = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      success: jspb.Message.getFieldWithDefault(msg, 1, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse;
  return proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSuccess(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
        1,
        f
    );
  }
};


/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.prototype.getSuccess = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.prototype.setSuccess = function (value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      pagesize: jspb.Message.getFieldWithDefault(msg, 3, 0),
      cursor: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest;
  return proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPagesize(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCursor(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt32(
        3,
        f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
};


/**
 * optional int32 pageSize = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.prototype.getPagesize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.prototype.setPagesize = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string cursor = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.prototype.getCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest.prototype.setCursor = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupInfosList: jspb.Message.toObjectList(msg.getGroupInfosList(),
          proto.com.hamropatro.sociallayer.io.GroupInfo.toObject, includeInstance),
      nextpagetoken: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse;
  return proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.com.hamropatro.sociallayer.io.GroupInfo;
        reader.readMessage(value, proto.com.hamropatro.sociallayer.io.GroupInfo.deserializeBinaryFromReader);
        msg.addGroupInfos(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextpagetoken(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.com.hamropatro.sociallayer.io.GroupInfo.serializeBinaryToWriter
    );
  }
  f = message.getNextpagetoken();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * repeated GroupInfo group_infos = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.GroupInfo>}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.prototype.getGroupInfosList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.GroupInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.GroupInfo, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.GroupInfo>} value */
proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.prototype.setGroupInfosList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GroupInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.GroupInfo}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.prototype.addGroupInfos = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.GroupInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.prototype.clearGroupInfosList = function () {
  this.setGroupInfosList([]);
};


/**
 * optional string nextPageToken = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.prototype.getNextpagetoken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.prototype.setNextpagetoken = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GroupInfo.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GroupInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GroupInfo.toObject = function (includeInstance, msg) {
    var f, obj = {
      appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      groupName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      groupDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
      groupType: jspb.Message.getFieldWithDefault(msg, 5, 0),
      image: jspb.Message.getFieldWithDefault(msg, 6, ""),
      followerCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
      role: jspb.Message.getFieldWithDefault(msg, 8, 0),
      membersList: jspb.Message.toObjectList(msg.getMembersList(),
          proto.com.hamropatro.sociallayer.io.Member.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GroupInfo}
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GroupInfo;
  return proto.com.hamropatro.sociallayer.io.GroupInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GroupInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GroupInfo}
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAppId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupName(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupDescription(value);
        break;
      case 5:
        var value = /** @type {!proto.com.hamropatro.sociallayer.io.GroupType} */ (reader.readEnum());
        msg.setGroupType(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setImage(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setFollowerCount(value);
        break;
      case 8:
        var value = /** @type {!proto.com.hamropatro.sociallayer.io.MemberRole} */ (reader.readEnum());
        msg.setRole(value);
        break;
      case 9:
        var value = new proto.com.hamropatro.sociallayer.io.Member;
        reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Member.deserializeBinaryFromReader);
        msg.addMembers(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GroupInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GroupInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getGroupDescription();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getGroupType();
  if (f !== 0.0) {
    writer.writeEnum(
        5,
        f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
        6,
        f
    );
  }
  f = message.getFollowerCount();
  if (f !== 0) {
    writer.writeInt64(
        7,
        f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
        8,
        f
    );
  }
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        9,
        f,
        proto.com.hamropatro.sociallayer.io.Member.serializeBinaryToWriter
    );
  }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.getAppId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.setAppId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_name = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.getGroupName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.setGroupName = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string group_description = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.getGroupDescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.setGroupDescription = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional GroupType group_type = 5;
 * @return {!proto.com.hamropatro.sociallayer.io.GroupType}
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.getGroupType = function () {
  return /** @type {!proto.com.hamropatro.sociallayer.io.GroupType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.GroupType} value */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.setGroupType = function (value) {
  jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string image = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.getImage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.setImage = function (value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 follower_count = 7;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.getFollowerCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.setFollowerCount = function (value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional MemberRole role = 8;
 * @return {!proto.com.hamropatro.sociallayer.io.MemberRole}
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.getRole = function () {
  return /** @type {!proto.com.hamropatro.sociallayer.io.MemberRole} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.MemberRole} value */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.setRole = function (value) {
  jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated Member members = 9;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.Member>}
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.getMembersList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.Member>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.Member, 9));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.Member>} value */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.setMembersList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.Member=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.Member}
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.addMembers = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.com.hamropatro.sociallayer.io.Member, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GroupInfo.prototype.clearMembersList = function () {
  this.setMembersList([]);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.Group.repeatedFields_ = [8, 17, 23];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.Group.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.Group.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.Group} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.Group.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      desc: jspb.Message.getFieldWithDefault(msg, 3, ""),
      grouptype: jspb.Message.getFieldWithDefault(msg, 4, 0),
      followerscount: jspb.Message.getFieldWithDefault(msg, 5, 0),
      created: jspb.Message.getFieldWithDefault(msg, 6, 0),
      address: jspb.Message.getFieldWithDefault(msg, 7, ""),
      domainurlList: jspb.Message.getRepeatedField(msg, 8),
      coverimage: jspb.Message.getFieldWithDefault(msg, 9, ""),
      logo: jspb.Message.getFieldWithDefault(msg, 10, ""),
      country: jspb.Message.getFieldWithDefault(msg, 11, ""),
      status: jspb.Message.getFieldWithDefault(msg, 12, 0),
      location: (f = msg.getLocation()) && frontend$social$common$types_pb.LocationInfo.toObject(includeInstance, f),
      category: jspb.Message.getFieldWithDefault(msg, 14, ""),
      subcategory: jspb.Message.getFieldWithDefault(msg, 15, ""),
      role: jspb.Message.getFieldWithDefault(msg, 16, 0),
      membersList: jspb.Message.toObjectList(msg.getMembersList(),
          proto.com.hamropatro.sociallayer.io.Member.toObject, includeInstance),
      isverified: jspb.Message.getFieldWithDefault(msg, 18, false),
      issuspended: jspb.Message.getFieldWithDefault(msg, 19, false),
      accountType: jspb.Message.getFieldWithDefault(msg, 20, ""),
      directJoinLink: jspb.Message.getFieldWithDefault(msg, 21, ""),
      ticketallowed: jspb.Message.getFieldWithDefault(msg, 22, false),
      transactionCurrencyList: jspb.Message.getRepeatedField(msg, 23),
      maxGroupPushCount: jspb.Message.getFieldWithDefault(msg, 24, 0),
      maxContentPushCount: jspb.Message.getFieldWithDefault(msg, 25, 0),
      notificationallowed: jspb.Message.getFieldWithDefault(msg, 26, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.Group}
 */
proto.com.hamropatro.sociallayer.io.Group.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.Group;
  return proto.com.hamropatro.sociallayer.io.Group.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.Group} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.Group}
 */
proto.com.hamropatro.sociallayer.io.Group.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDesc(value);
        break;
      case 4:
        var value = /** @type {!proto.com.hamropatro.sociallayer.io.GroupType} */ (reader.readEnum());
        msg.setGrouptype(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setFollowerscount(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setCreated(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setAddress(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.addDomainurl(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setCoverimage(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setLogo(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountry(value);
        break;
      case 12:
        var value = /** @type {!proto.com.hamropatro.sociallayer.io.Status} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 13:
        var value = new frontend$social$common$types_pb.LocationInfo;
        reader.readMessage(value, frontend$social$common$types_pb.LocationInfo.deserializeBinaryFromReader);
        msg.setLocation(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.setCategory(value);
        break;
      case 15:
        var value = /** @type {string} */ (reader.readString());
        msg.setSubcategory(value);
        break;
      case 16:
        var value = /** @type {!proto.com.hamropatro.sociallayer.io.MemberRole} */ (reader.readEnum());
        msg.setRole(value);
        break;
      case 17:
        var value = new proto.com.hamropatro.sociallayer.io.Member;
        reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Member.deserializeBinaryFromReader);
        msg.addMembers(value);
        break;
      case 18:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsverified(value);
        break;
      case 19:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIssuspended(value);
        break;
      case 20:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountType(value);
        break;
      case 21:
        var value = /** @type {string} */ (reader.readString());
        msg.setDirectJoinLink(value);
        break;
      case 22:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setTicketallowed(value);
        break;
      case 23:
        var value = /** @type {string} */ (reader.readString());
        msg.addTransactionCurrency(value);
        break;
      case 24:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMaxGroupPushCount(value);
        break;
      case 25:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMaxContentPushCount(value);
        break;
      case 26:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setNotificationallowed(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.Group.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.Group} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.Group.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupid();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getDesc();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getGrouptype();
  if (f !== 0.0) {
    writer.writeEnum(
        4,
        f
    );
  }
  f = message.getFollowerscount();
  if (f !== 0) {
    writer.writeInt64(
        5,
        f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
        6,
        f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
        7,
        f
    );
  }
  f = message.getDomainurlList();
  if (f.length > 0) {
    writer.writeRepeatedString(
        8,
        f
    );
  }
  f = message.getCoverimage();
  if (f.length > 0) {
    writer.writeString(
        9,
        f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
        10,
        f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
        11,
        f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
        12,
        f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
        13,
        f,
        frontend$social$common$types_pb.LocationInfo.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
        14,
        f
    );
  }
  f = message.getSubcategory();
  if (f.length > 0) {
    writer.writeString(
        15,
        f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
        16,
        f
    );
  }
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        17,
        f,
        proto.com.hamropatro.sociallayer.io.Member.serializeBinaryToWriter
    );
  }
  f = message.getIsverified();
  if (f) {
    writer.writeBool(
        18,
        f
    );
  }
  f = message.getIssuspended();
  if (f) {
    writer.writeBool(
        19,
        f
    );
  }
  f = message.getAccountType();
  if (f.length > 0) {
    writer.writeString(
        20,
        f
    );
  }
  f = message.getDirectJoinLink();
  if (f.length > 0) {
    writer.writeString(
        21,
        f
    );
  }
  f = message.getTicketallowed();
  if (f) {
    writer.writeBool(
        22,
        f
    );
  }
  f = message.getTransactionCurrencyList();
  if (f.length > 0) {
    writer.writeRepeatedString(
        23,
        f
    );
  }
  f = message.getMaxGroupPushCount();
  if (f !== 0) {
    writer.writeInt32(
        24,
        f
    );
  }
  f = message.getMaxContentPushCount();
  if (f !== 0) {
    writer.writeInt32(
        25,
        f
    );
  }
  f = message.getNotificationallowed();
  if (f) {
    writer.writeBool(
        26,
        f
    );
  }
};


/**
 * optional string groupId = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getGroupid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setGroupid = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setName = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string desc = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getDesc = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setDesc = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional GroupType groupType = 4;
 * @return {!proto.com.hamropatro.sociallayer.io.GroupType}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getGrouptype = function () {
  return /** @type {!proto.com.hamropatro.sociallayer.io.GroupType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.GroupType} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setGrouptype = function (value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 followersCount = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getFollowerscount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setFollowerscount = function (value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 created = 6;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getCreated = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setCreated = function (value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string address = 7;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getAddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setAddress = function (value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string domainUrl = 8;
 * @return {!Array<string>}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getDomainurlList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/** @param {!Array<string>} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setDomainurlList = function (value) {
  jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.addDomainurl = function (value, opt_index) {
  jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.clearDomainurlList = function () {
  this.setDomainurlList([]);
};


/**
 * optional string coverImage = 9;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getCoverimage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setCoverimage = function (value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string logo = 10;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getLogo = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setLogo = function (value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string country = 11;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getCountry = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setCountry = function (value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional Status status = 12;
 * @return {!proto.com.hamropatro.sociallayer.io.Status}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getStatus = function () {
  return /** @type {!proto.com.hamropatro.sociallayer.io.Status} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.Status} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setStatus = function (value) {
  jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional LocationInfo location = 13;
 * @return {?proto.com.hamropatro.sociallayer.io.LocationInfo}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getLocation = function () {
  return /** @type{?proto.com.hamropatro.sociallayer.io.LocationInfo} */ (
      jspb.Message.getWrapperField(this, frontend$social$common$types_pb.LocationInfo, 13));
};


/** @param {?proto.com.hamropatro.sociallayer.io.LocationInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setLocation = function (value) {
  jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.clearLocation = function () {
  this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.hasLocation = function () {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string category = 14;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getCategory = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setCategory = function (value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string subcategory = 15;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getSubcategory = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setSubcategory = function (value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional MemberRole role = 16;
 * @return {!proto.com.hamropatro.sociallayer.io.MemberRole}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getRole = function () {
  return /** @type {!proto.com.hamropatro.sociallayer.io.MemberRole} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.MemberRole} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setRole = function (value) {
  jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * repeated Member members = 17;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.Member>}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getMembersList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.Member>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.Member, 17));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.Member>} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setMembersList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.Member=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.Member}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.addMembers = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.com.hamropatro.sociallayer.io.Member, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.clearMembersList = function () {
  this.setMembersList([]);
};


/**
 * optional bool isVerified = 18;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getIsverified = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 18, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setIsverified = function (value) {
  jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool isSuspended = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getIssuspended = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setIssuspended = function (value) {
  jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional string account_type = 20;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getAccountType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setAccountType = function (value) {
  jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string direct_join_link = 21;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getDirectJoinLink = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setDirectJoinLink = function (value) {
  jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional bool ticketAllowed = 22;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getTicketallowed = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 22, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setTicketallowed = function (value) {
  jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * repeated string transaction_currency = 23;
 * @return {!Array<string>}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getTransactionCurrencyList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 23));
};


/** @param {!Array<string>} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setTransactionCurrencyList = function (value) {
  jspb.Message.setField(this, 23, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.addTransactionCurrency = function (value, opt_index) {
  jspb.Message.addToRepeatedField(this, 23, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.clearTransactionCurrencyList = function () {
  this.setTransactionCurrencyList([]);
};


/**
 * optional int32 max_group_push_count = 24;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getMaxGroupPushCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setMaxGroupPushCount = function (value) {
  jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 max_content_push_count = 25;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getMaxContentPushCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setMaxContentPushCount = function (value) {
  jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional bool notificationAllowed = 26;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Group.prototype.getNotificationallowed = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 26, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.Group.prototype.setNotificationallowed = function (value) {
  jspb.Message.setProto3BooleanField(this, 26, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.Category.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.Category.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.Category} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.Category.toObject = function (includeInstance, msg) {
    var f, obj = {
      appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      categoryName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      categoryDesc: jspb.Message.getFieldWithDefault(msg, 4, ""),
      categoryImage: jspb.Message.getFieldWithDefault(msg, 5, ""),
      createAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
      lastUpdated: jspb.Message.getFieldWithDefault(msg, 7, 0),
      accessToWrite: jspb.Message.getFieldWithDefault(msg, 8, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.Category}
 */
proto.com.hamropatro.sociallayer.io.Category.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.Category;
  return proto.com.hamropatro.sociallayer.io.Category.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.Category} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.Category}
 */
proto.com.hamropatro.sociallayer.io.Category.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAppId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCategoryName(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCategoryDesc(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCategoryImage(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setCreateAt(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setLastUpdated(value);
        break;
      case 8:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAccessToWrite(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.Category.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.Category.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.Category} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.Category.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getCategoryName();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getCategoryDesc();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getCategoryImage();
  if (f.length > 0) {
    writer.writeString(
        5,
        f
    );
  }
  f = message.getCreateAt();
  if (f !== 0) {
    writer.writeInt64(
        6,
        f
    );
  }
  f = message.getLastUpdated();
  if (f !== 0) {
    writer.writeInt64(
        7,
        f
    );
  }
  f = message.getAccessToWrite();
  if (f) {
    writer.writeBool(
        8,
        f
    );
  }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Category.prototype.getAppId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Category.prototype.setAppId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Category.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Category.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_name = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Category.prototype.getCategoryName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Category.prototype.setCategoryName = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string category_desc = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Category.prototype.getCategoryDesc = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Category.prototype.setCategoryDesc = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string category_image = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Category.prototype.getCategoryImage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Category.prototype.setCategoryImage = function (value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 create_at = 6;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Category.prototype.getCreateAt = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Category.prototype.setCreateAt = function (value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 last_updated = 7;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Category.prototype.getLastUpdated = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Category.prototype.setLastUpdated = function (value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool access_to_write = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Category.prototype.getAccessToWrite = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.Category.prototype.setAccessToWrite = function (value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.Member.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.Member.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.Member} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.Member.toObject = function (includeInstance, msg) {
    var f, obj = {
      userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      imageurl: jspb.Message.getFieldWithDefault(msg, 3, ""),
      role: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.Member}
 */
proto.com.hamropatro.sociallayer.io.Member.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.Member;
  return proto.com.hamropatro.sociallayer.io.Member.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.Member} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.Member}
 */
proto.com.hamropatro.sociallayer.io.Member.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setImageurl(value);
        break;
      case 4:
        var value = /** @type {!proto.com.hamropatro.sociallayer.io.MemberRole} */ (reader.readEnum());
        msg.setRole(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.Member.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.Member.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.Member} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.Member.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
        4,
        f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Member.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Member.prototype.setUserid = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Member.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Member.prototype.setName = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string imageUrl = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Member.prototype.getImageurl = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Member.prototype.setImageurl = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MemberRole role = 4;
 * @return {!proto.com.hamropatro.sociallayer.io.MemberRole}
 */
proto.com.hamropatro.sociallayer.io.Member.prototype.getRole = function () {
  return /** @type {!proto.com.hamropatro.sociallayer.io.MemberRole} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.MemberRole} value */
proto.com.hamropatro.sociallayer.io.Member.prototype.setRole = function (value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetGroupRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetGroupRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetGroupRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetGroupRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupRequest}
 */
proto.com.hamropatro.sociallayer.io.GetGroupRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetGroupRequest;
  return proto.com.hamropatro.sociallayer.io.GetGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupRequest}
 */
proto.com.hamropatro.sociallayer.io.GetGroupRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetGroupRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetGroupResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetGroupResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetGroupResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetGroupResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetGroupResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      group: (f = msg.getGroup()) && proto.com.hamropatro.sociallayer.io.Group.toObject(includeInstance, f),
      categoryList: jspb.Message.toObjectList(msg.getCategoryList(),
          proto.com.hamropatro.sociallayer.io.Category.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupResponse}
 */
proto.com.hamropatro.sociallayer.io.GetGroupResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetGroupResponse;
  return proto.com.hamropatro.sociallayer.io.GetGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupResponse}
 */
proto.com.hamropatro.sociallayer.io.GetGroupResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.com.hamropatro.sociallayer.io.Group;
        reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Group.deserializeBinaryFromReader);
        msg.setGroup(value);
        break;
      case 2:
        var value = new proto.com.hamropatro.sociallayer.io.Category;
        reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Category.deserializeBinaryFromReader);
        msg.addCategory(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetGroupResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        proto.com.hamropatro.sociallayer.io.Group.serializeBinaryToWriter
    );
  }
  f = message.getCategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        2,
        f,
        proto.com.hamropatro.sociallayer.io.Category.serializeBinaryToWriter
    );
  }
};


/**
 * optional Group group = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.Group}
 */
proto.com.hamropatro.sociallayer.io.GetGroupResponse.prototype.getGroup = function () {
  return /** @type{?proto.com.hamropatro.sociallayer.io.Group} */ (
      jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.Group, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Group|undefined} value */
proto.com.hamropatro.sociallayer.io.GetGroupResponse.prototype.setGroup = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetGroupResponse.prototype.clearGroup = function () {
  this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetGroupResponse.prototype.hasGroup = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Category category = 2;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.Category>}
 */
proto.com.hamropatro.sociallayer.io.GetGroupResponse.prototype.getCategoryList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.Category>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.Category, 2));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.Category>} value */
proto.com.hamropatro.sociallayer.io.GetGroupResponse.prototype.setCategoryList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.Category}
 */
proto.com.hamropatro.sociallayer.io.GetGroupResponse.prototype.addCategory = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.hamropatro.sociallayer.io.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetGroupResponse.prototype.clearCategoryList = function () {
  this.setCategoryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      groupType: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest;
  return proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {!proto.com.hamropatro.sociallayer.io.GroupType} */ (reader.readEnum());
        msg.setGroupType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getGroupType();
  if (f !== 0.0) {
    writer.writeEnum(
        2,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GroupType group_type = 2;
 * @return {!proto.com.hamropatro.sociallayer.io.GroupType}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.prototype.getGroupType = function () {
  return /** @type {!proto.com.hamropatro.sociallayer.io.GroupType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.GroupType} value */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest.prototype.setGroupType = function (value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
      cursor: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest;
  return proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCursor(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        1,
        f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.prototype.getCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest.prototype.setCursor = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest}
 */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest;
  return proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest}
 */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      directJoinLink: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse}
 */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse;
  return proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse}
 */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setDirectJoinLink(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDirectJoinLink();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * optional string direct_join_link = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.prototype.getDirectJoinLink = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.prototype.setDirectJoinLink = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest}
 */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest;
  return proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest}
 */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      linkString: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse}
 */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse;
  return proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse}
 */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setLinkString(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLinkString();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
};


/**
 * optional string link_string = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.prototype.getLinkString = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.prototype.setLinkString = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupInfosList: jspb.Message.toObjectList(msg.getGroupInfosList(),
          proto.com.hamropatro.sociallayer.io.GroupInfo.toObject, includeInstance),
      nextpagetoken: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse;
  return proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.com.hamropatro.sociallayer.io.GroupInfo;
        reader.readMessage(value, proto.com.hamropatro.sociallayer.io.GroupInfo.deserializeBinaryFromReader);
        msg.addGroupInfos(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextpagetoken(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.com.hamropatro.sociallayer.io.GroupInfo.serializeBinaryToWriter
    );
  }
  f = message.getNextpagetoken();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * repeated GroupInfo group_infos = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.GroupInfo>}
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.prototype.getGroupInfosList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.GroupInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.GroupInfo, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.GroupInfo>} value */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.prototype.setGroupInfosList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GroupInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.GroupInfo}
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.prototype.addGroupInfos = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.GroupInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.prototype.clearGroupInfosList = function () {
  this.setGroupInfosList([]);
};


/**
 * optional string nextPageToken = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.prototype.getNextpagetoken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.prototype.setNextpagetoken = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      updatedType: jspb.Message.getFieldWithDefault(msg, 2, 0),
      updated: jspb.Message.getFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse;
  return proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {!proto.com.hamropatro.sociallayer.io.GroupType} */ (reader.readEnum());
        msg.setUpdatedType(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setUpdated(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getUpdatedType();
  if (f !== 0.0) {
    writer.writeEnum(
        2,
        f
    );
  }
  f = message.getUpdated();
  if (f) {
    writer.writeBool(
        3,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GroupType updated_type = 2;
 * @return {!proto.com.hamropatro.sociallayer.io.GroupType}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.prototype.getUpdatedType = function () {
  return /** @type {!proto.com.hamropatro.sociallayer.io.GroupType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.GroupType} value */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.prototype.setUpdatedType = function (value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool updated = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.prototype.getUpdated = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.prototype.setUpdated = function (value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest}
 */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest;
  return proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest}
 */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      isSuccess: jspb.Message.getFieldWithDefault(msg, 1, false),
      groupId: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse}
 */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse;
  return proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse}
 */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsSuccess(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
        1,
        f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * optional bool is_success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.prototype.getIsSuccess = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.prototype.setIsSuccess = function (value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetCategoriesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
      nextPageCursor: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetCategoriesRequest}
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetCategoriesRequest;
  return proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetCategoriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetCategoriesRequest}
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextPageCursor(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetCategoriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        2,
        f
    );
  }
  f = message.getNextPageCursor();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string next_page_cursor = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.prototype.getNextPageCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetCategoriesRequest.prototype.setNextPageCursor = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetCategoriesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      categoryList: jspb.Message.toObjectList(msg.getCategoryList(),
          proto.com.hamropatro.sociallayer.io.Category.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetCategoriesResponse}
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetCategoriesResponse;
  return proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetCategoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetCategoriesResponse}
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.com.hamropatro.sociallayer.io.Category;
        reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Category.deserializeBinaryFromReader);
        msg.addCategory(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetCategoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCategoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.com.hamropatro.sociallayer.io.Category.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Category category = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.Category>}
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.prototype.getCategoryList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.Category>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.Category, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.Category>} value */
proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.prototype.setCategoryList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.Category}
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.prototype.addCategory = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.prototype.clearCategoryList = function () {
  this.setCategoryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.AddCategoryRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.AddCategoryRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.AddCategoryRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.AddCategoryRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      categoryName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      categoryDesc: jspb.Message.getFieldWithDefault(msg, 4, ""),
      categoryImage: jspb.Message.getFieldWithDefault(msg, 5, ""),
      generalWriteAccess: jspb.Message.getFieldWithDefault(msg, 6, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.AddCategoryRequest}
 */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.AddCategoryRequest;
  return proto.com.hamropatro.sociallayer.io.AddCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.AddCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.AddCategoryRequest}
 */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCategoryName(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCategoryDesc(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCategoryImage(value);
        break;
      case 6:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setGeneralWriteAccess(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.AddCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.AddCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getCategoryName();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getCategoryDesc();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getCategoryImage();
  if (f.length > 0) {
    writer.writeString(
        5,
        f
    );
  }
  f = message.getGeneralWriteAccess();
  if (f) {
    writer.writeBool(
        6,
        f
    );
  }
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_name = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.prototype.getCategoryName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.prototype.setCategoryName = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string category_desc = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.prototype.getCategoryDesc = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.prototype.setCategoryDesc = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string category_image = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.prototype.getCategoryImage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.prototype.setCategoryImage = function (value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool general_write_access = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.prototype.getGeneralWriteAccess = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.AddCategoryRequest.prototype.setGeneralWriteAccess = function (value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.AddCategoryResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.AddCategoryResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.AddCategoryResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.AddCategoryResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      created: jspb.Message.getFieldWithDefault(msg, 1, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.AddCategoryResponse}
 */
proto.com.hamropatro.sociallayer.io.AddCategoryResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.AddCategoryResponse;
  return proto.com.hamropatro.sociallayer.io.AddCategoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.AddCategoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.AddCategoryResponse}
 */
proto.com.hamropatro.sociallayer.io.AddCategoryResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setCreated(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.AddCategoryResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.AddCategoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.AddCategoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.AddCategoryResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCreated();
  if (f) {
    writer.writeBool(
        1,
        f
    );
  }
};


/**
 * optional bool created = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.AddCategoryResponse.prototype.getCreated = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.AddCategoryResponse.prototype.setCreated = function (value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      categoryName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      accessLevel: jspb.Message.getFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest;
  return proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCategoryName(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAccessLevel(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getCategoryName();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getAccessLevel();
  if (f) {
    writer.writeBool(
        3,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_name = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.prototype.getCategoryName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.prototype.setCategoryName = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool access_level = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.prototype.getAccessLevel = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest.prototype.setAccessLevel = function (value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      categoryName: jspb.Message.getFieldWithDefault(msg, 1, ""),
      updatedWriteAccess: jspb.Message.getFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse;
  return proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCategoryName(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setUpdatedWriteAccess(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCategoryName();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getUpdatedWriteAccess();
  if (f) {
    writer.writeBool(
        2,
        f
    );
  }
};


/**
 * optional string category_name = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.prototype.getCategoryName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.prototype.setCategoryName = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool updated_write_access = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.prototype.getUpdatedWriteAccess = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.prototype.setUpdatedWriteAccess = function (value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      cursor: jspb.Message.getFieldWithDefault(msg, 1, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest}
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest;
  return proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest}
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCursor(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        2,
        f
    );
  }
};


/**
 * optional string cursor = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.prototype.getCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.prototype.setCursor = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupinfoList: jspb.Message.toObjectList(msg.getGroupinfoList(),
          proto.com.hamropatro.sociallayer.io.GroupInfo.toObject, includeInstance),
      nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse}
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse;
  return proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse}
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.com.hamropatro.sociallayer.io.GroupInfo;
        reader.readMessage(value, proto.com.hamropatro.sociallayer.io.GroupInfo.deserializeBinaryFromReader);
        msg.addGroupinfo(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextPageToken(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupinfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.com.hamropatro.sociallayer.io.GroupInfo.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * repeated GroupInfo groupInfo = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.GroupInfo>}
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.prototype.getGroupinfoList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.GroupInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.GroupInfo, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.GroupInfo>} value */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.prototype.setGroupinfoList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GroupInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.GroupInfo}
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.prototype.addGroupinfo = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.GroupInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.prototype.clearGroupinfoList = function () {
  this.setGroupinfoList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.prototype.getNextPageToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.prototype.setNextPageToken = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      enableTicketing: jspb.Message.getFieldWithDefault(msg, 2, false),
      supportedCurrencyList: jspb.Message.getRepeatedField(msg, 3)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest}
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest;
  return proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest}
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setEnableTicketing(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.addSupportedCurrency(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getEnableTicketing();
  if (f) {
    writer.writeBool(
        2,
        f
    );
  }
  f = message.getSupportedCurrencyList();
  if (f.length > 0) {
    writer.writeRepeatedString(
        3,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool enable_ticketing = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.prototype.getEnableTicketing = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.prototype.setEnableTicketing = function (value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated string supported_currency = 3;
 * @return {!Array<string>}
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.prototype.getSupportedCurrencyList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<string>} value */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.prototype.setSupportedCurrencyList = function (value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.prototype.addSupportedCurrency = function (value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest.prototype.clearSupportedCurrencyList = function () {
  this.setSupportedCurrencyList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      updatedTicketingStatus: jspb.Message.getFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse}
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse;
  return proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse}
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setUpdatedTicketingStatus(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getUpdatedTicketingStatus();
  if (f) {
    writer.writeBool(
        2,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool updated_ticketing_status = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.prototype.getUpdatedTicketingStatus = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.prototype.setUpdatedTicketingStatus = function (value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      location: (f = msg.getLocation()) && frontend$social$common$types_pb.LocationInfo.toObject(includeInstance, f),
      algorithm: jspb.Message.getFieldWithDefault(msg, 2, ""),
      cursor: jspb.Message.getFieldWithDefault(msg, 3, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest;
  return proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new frontend$social$common$types_pb.LocationInfo;
        reader.readMessage(value, frontend$social$common$types_pb.LocationInfo.deserializeBinaryFromReader);
        msg.setLocation(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAlgorithm(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCursor(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        frontend$social$common$types_pb.LocationInfo.serializeBinaryToWriter
    );
  }
  f = message.getAlgorithm();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        4,
        f
    );
  }
};


/**
 * optional LocationInfo location = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.LocationInfo}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.prototype.getLocation = function () {
  return /** @type{?proto.com.hamropatro.sociallayer.io.LocationInfo} */ (
      jspb.Message.getWrapperField(this, frontend$social$common$types_pb.LocationInfo, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.LocationInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.prototype.setLocation = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.prototype.clearLocation = function () {
  this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.prototype.hasLocation = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string algorithm = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.prototype.getAlgorithm = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.prototype.setAlgorithm = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cursor = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.prototype.getCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.prototype.setCursor = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
          proto.com.hamropatro.sociallayer.io.GroupInfo.toObject, includeInstance),
      nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse;
  return proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.com.hamropatro.sociallayer.io.GroupInfo;
        reader.readMessage(value, proto.com.hamropatro.sociallayer.io.GroupInfo.deserializeBinaryFromReader);
        msg.addGroups(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextPageCursor(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.com.hamropatro.sociallayer.io.GroupInfo.serializeBinaryToWriter
    );
  }
  f = message.getNextPageCursor();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * repeated GroupInfo groups = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.GroupInfo>}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.prototype.getGroupsList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.GroupInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.GroupInfo, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.GroupInfo>} value */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.prototype.setGroupsList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GroupInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.GroupInfo}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.prototype.addGroups = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.GroupInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.prototype.clearGroupsList = function () {
  this.setGroupsList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.prototype.getNextPageCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.prototype.setNextPageCursor = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.GroupType = {
  PRIVATE: 0,
  PUBLIC: 1
};

goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
