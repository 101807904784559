import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {Grid, Typography, withStyles, withWidth} from '@material-ui/core';
import classNames from 'classnames';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
import compose from 'recompose/compose';
// Component styles
import styles from '../styles';
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StyleIcon from '@material-ui/icons/Style';
import StarIcon from '@material-ui/icons/Star';
import Icon from '@material-ui/core/Icon';
import Fab from "@material-ui/core/Fab";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {checkIfLogin, isAdmin, isLogin, removeUserData} from "../../../configurations/global-config";
import {
    deleteEvent,
    getEventDetails,
    updateEventDetails,
    updateUserResponseToEvent
} from "../../../grpcRequests/events-request";
import {
    Event,
    GetEventRequest,
    GoingStatus,
    RemoveEventRequest,
    UpdateEventRequest,
    UpdateGoingStatusRequest
} from "../../../generated/frontend-community-event-service_pb";
import usedString, {
    getCountryCurrencySymbol,
    getEventTypeStringInSentence,
    getGroupTypeStringInSentence,
    getRoleString,
    getStatusString,
    getUserResponseString
} from "../../../utils/mapper/enumToStringMapper";
import {GetGroupRequest} from "../../../generated/frontend-community-group-service_pb";
import {getGroupDetails} from "../../../grpcRequests/groups-request";
import {Link} from "react-router-dom";
import SnackBar from "../../../components/SnackBar/SnackBar";
import UserConfirmationDialog from "../../../components/UserConfirmationDialog";
import {GroupDetailLoader} from "../../../components/Skeleton/EventsSket";
import EventsUsers from "../../../components/Dialogs/EventsUsers";
import {formatDate, timeEvent} from "../../../utils/converter/dateConverter";
import GetUserComments from "../../../components/CommentBox/GetUserComments";
import {eventAnalyst, pageViewAnalyst} from "../../../configurations/config";
import BuyTickets from "../../../components/Tickets/BuyTickets";
import {
    GetTicketsForEventRequest,
    UserTicketsForTicketTypeRequest
} from "../../../generated/frontend-community-ticket-service_pb";
import {GetEverestCounterRequest, IncrementCountRequest} from "../../../generated/frontend-everestdb-service_pb";
import {getEventsTickets, getUserTicketListByTypeId} from "../../../grpcRequests/tickets-request";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import {shortenString} from "../../../utils/converter/shortenString";
import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton} from "react-share";
import LoginRequiredDialog from "../../../components/Dialogs/LoginRequiredDialog";
import {metaDataSet, setPageTitle} from "../../../utils/MetaData";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import PhoneIcon from '@material-ui/icons/Phone';
import PeopleIcon from '@material-ui/icons/People';
import MailIcon from '@material-ui/icons/Mail';
import SAG from "../../QuickPages/SAG";
import {getPageCount, incrementCount} from "../../../grpcRequests/everestDB-request";
import GetPostedContent from "../../../components/PostBox/GetPostedContent";
import {addCommas, titleCase} from "../../../utils/converter/common";
import {EventJSON} from "../../../utils/JSON";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import NotificationConfirmation from "../../../components/NotificationDialog/NotificationConfirmation";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import RenderText from "../../Groups/component/RenderText";


function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}

const imagesList = ["https://sgp1.digitaloceanspaces.com/everestdb/hamropatro/Mandala/event/91a272f0-f47e-11e9-8647-0242ac120012", "https://sgp1.digitaloceanspaces.com/everestdb/hamropatro/Mandala/communityLogo/47306010-e204-11e9-86ac-0242ac120012", "https://sgp1.digitaloceanspaces.com/everestdb/hamropatro/Mandala/event/91a272f0-f47e-11e9-8647-0242ac120012", "https://sgp1.digitaloceanspaces.com/everestdb/hamropatro/Mandala/communityLogo/47306010-e204-11e9-86ac-0242ac120012", "https://sgp1.digitaloceanspaces.com/everestdb/hamropatro/Mandala/event/91a272f0-f47e-11e9-8647-0242ac120012", "https://sgp1.digitaloceanspaces.com/everestdb/hamropatro/Mandala/communityLogo/47306010-e204-11e9-86ac-0242ac120012"];

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class EventsDetails extends Component {
    state = {
        tabValue: 0,
        userCommentText: [],
        groupId: this.props.match.params.groupId,
        eventId: this.props.match.params.eventId,
        ticketId: this.props.match.params.ticketId,
        fieldValues: this.props.match.params.fieldValues,
        isLoadingEvent: true,
        eventDetails: null,
        attenders: null,
        userStatus: null,
        userGroupStatus: null,
        errEventFetch: null,
        userRole: '',
        groupType: '',
        eType: 'Open',
        changeEventType: false,
        changingEventType: false,
        goingCount: null,
        interestedCount: null,
        openUserGoing: false,
        userEventText: null,
        title: '',

        eventTickets: [],
        isBought: false,
        userTickets: [],
        loadingTickets: false,
        //delete
        openConfirmation: false,
        openLoginDialog: false,
        openNotificationDialog: false,

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null,

        checked: [1],
        errSts: '',
        anchorEl: null,
        pageViewCount: '',
        showMore: false,
        changeBackground: false,
        hideTicket: false,
        canSendNotification: false,
        maxEventNotification: '',
        viewMap: false
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        pageViewAnalyst('Event Details', this.props.location.pathname);
        // checkIfLogin(this.props.location.pathname);
        this.increasePageCount(this.props.match.params.eventId);
        this.getUserRole(this.state.groupId, this.state.eventId);
        let refId = window.location.search ? (new URLSearchParams(window.location.search).get('refId')) : '';
        if (refId && this.state.fieldValues) removeUserData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.match.params.fieldValues && nextProps.match.params.fieldValues !== this.props.match.params.fieldValues){
            removeUserData();
            this.setState({
                fieldValues:nextProps.match.params.fieldValues
            });
        }else {
            this.setState({
                fieldValues: ''
            });
        }
        if (nextProps.match.params.eventId !== this.props.match.params.eventId) {
            this.increasePageCount(nextProps.match.params.eventId);
            this.setState({
                groupId: nextProps.match.params.groupId,
                eventId: nextProps.match.params.eventId,
            });
            this.getUserRole(nextProps.match.params.groupId, nextProps.match.params.eventId);
        }
    }

    increasePageCount = (eventId) => {
        const req = new IncrementCountRequest();
        req.setGroup('counters/community/events');
        req.setKey(eventId);
        req.setCounttoincr(1);
        incrementCount(req).then(function (response) {
        }).catch(function (error) {
        })
    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };
    getUserResponseFromChild = (clickedButton, key) => {
        this.setState({openConfirmation: false});
        if (clickedButton === "YES") {
            this.deleteEvent(key);
        }
    };

    geNotificationRes = () => {
        this.setState({openNotificationDialog: false});
    };

    getLoginDialogRes = (clickedButton, key) => {
        this.setState({openLoginDialog: false});
        if (clickedButton === "login") {
            checkIfLogin(this.props.location.pathname);
        } else {
            sessionStorage.removeItem('userStatus');
        }
    };

    getCommentStatusFromChild = (commentStatus, commentedID) => {
        this.setState({isCommented: commentStatus, commentedId: commentedID});
    };

    openConfirmation = () => {
        this.setState({openConfirmation: true});
    };

    openNotificationDialog = () => {
        this.setState({openNotificationDialog: true});
    };

    openLoginDialog = () => {
        this.setState({openLoginDialog: true});
    };

    getEventCounter = (eventId) => {
        let self = this;
        const req = new GetEverestCounterRequest();
        req.setGroup('counters/community/events');
        req.setKey(eventId);
        getPageCount(req).then(function (response) {
            self.setState({pageViewCount: response.getCounter().getCount()});
        }).catch(function (error) {
        })
    };

    getResFrmChild = (status) => {
        this.setState({openUserGoing: false});
    };

    getUserRole = (grpId, evtId) => {
        let self = this;
        const req = new GetGroupRequest();
        req.setGroupId(grpId);
        getGroupDetails(req, 3).then(function (response) {
            const group = response.getGroup();
            const title = group.getName();
            const groupType = getGroupTypeStringInSentence(group.getGrouptype());
            const userRole = getRoleString(group.getRole());
            const userGroupStatus = getStatusString(group.getStatus());
            const canSendNotification = group.getNotificationallowed();
            const maxEventNotification = group.getMaxGroupPushCount();

            if (isAdmin() || getRoleString(group.getRole()) === usedString.ADMIN || getRoleString(group.getRole()) === usedString.MODERATOR) {
                self.getEventCounter(evtId);
            }

            self.setState({
                userRole, title, groupType, userGroupStatus, canSendNotification, maxEventNotification
            });
            self.fetchEventDetails(grpId, evtId);
        }).catch(function (error) {
            self.snackbar(true, error.message, 'error', 5000);
            self.setState({
                isLoadingEvent: false
            });
        })
    };

    fetchEventDetails = (groupID, eventID) => {
        let self = this;
        const thisState = this.state;
        const req = new GetEventRequest();
        req.setGroupId(groupID);
        req.setEventId(eventID);
        getEventDetails(req, 3).then(function (response) {
            eventAnalyst('community_event_detail', 'community_event_detail_fetch', groupID + '-' + eventID + '-community event detail fetch success', 'community-event-detail-fetch-success');
            const eventDetails = response.getEvent();
            const eventTickets = response.getTicketTypesList();
            setPageTitle(eventDetails.getTitle());
            //set og-tag
            metaDataSet(eventDetails.getTitle(), eventDetails.getEventDesc(), window.location.origin + '/c/' + self.props.match.params.groupId + '/events/' + self.props.match.params.eventId, eventDetails.getEventCoverImage());
            //og-tag ends
            //create Event Json
            EventJSON(eventDetails, eventTickets);

            let userStatus;
            if (isLogin()) {
                let userStsData = sessionStorage.getItem('userStatus');
                if (userStsData) {
                    let eventID = userStsData.split(':')[1];
                    let userSts = userStsData.split(':')[0];
                    userStatus = userSts;
                    if (eventID === self.props.match.params.eventId) self.userResponseToEvent(userSts, response.getEvent())
                } else userStatus = getUserResponseString(response.getStatus());
            } else userStatus = getUserResponseString(response.getStatus());
            const attenders = response.getRecentAttendersList();
            const goingCount = response.getGoingCount();
            const interestedCount = response.getInterestedCount();
            const isLoadingEvent = false;
            const eType = getEventTypeStringInSentence(eventDetails.getEventStatus());

            const userBoughtTickets = response.getUserTicketsList();
            let userTicketMap = new Map();
            let allTicketId = [];
            userBoughtTickets.forEach(function (ticket, index) {
                let newTicket = [];
                let previousTickets = [];
                if (allTicketId.includes(ticket.getTicketTypeId())) {
                    if (userTicketMap.get(ticket.getTicketTypeId())) {
                        previousTickets = userTicketMap.get(ticket.getTicketTypeId());
                        previousTickets.push(ticket);
                        userTicketMap.set(ticket.getTicketTypeId(), previousTickets);
                    } else {
                        newTicket.push(ticket);
                        userTicketMap.set(ticket.getTicketTypeId(), newTicket);
                    }
                } else {
                    allTicketId.push(ticket.getTicketTypeId());
                    newTicket.push(ticket);
                    userTicketMap.set(ticket.getTicketTypeId(), newTicket);
                }
            });
            const isBought = response.getTicketAlreadyBought();
            self.setState({
                userStatus,
                eventDetails,
                attenders,
                isLoadingEvent,
                goingCount,
                interestedCount,
                eventTickets,
                isBought,
                eType,
                userTickets: userTicketMap,
                errSts: ''
            });
        }).catch(function (error) {
            eventAnalyst('community_event_detail', 'community_event_detail_fetch', groupID + '-' + eventID + '-community event detail fetch fail', 'community-event-detail-fetch-fail');
            const errEventFetch = error.message;
            const isLoadingEvent = false;
            metaDataSet(self.state.title ? self.state.title + "'s Event" : 'Community Event', '', window.location.origin + '/c/' + self.props.match.params.groupId + '/events/' + self.props.match.params.eventId, '');
            self.setState({
                isLoadingEvent,
                errEventFetch,
                errSts: error.code
            });
        })
    };

    closeResponse = (purchaseStatus, closeStatus) => {
        this.setState({snackOpen: false, ticketId:''});
        let self = this;
        if (purchaseStatus) {
            self.snackbar(true, 'Ticket Purchased', 'success', 5000);
        }
        this.fetchEventTickets();
        if (isLogin())
            this.fetchUserTickets();
    };

    fetchEventTickets = () => {
        this.setState({
            loadingTickets: true
        });
        let self = this;
        const req = new GetTicketsForEventRequest();
        req.setEventId(this.state.eventId);
        req.setCursor('');
        req.setPageSize(50);
        getEventsTickets(req).then(function (response) {
            self.setState({
                eventTickets: response.getTicketList(),
                loadingTickets: false
            });
        }).catch(function (error) {
        })
    };

    fetchUserTickets = () => {
        let typeIds = [];
        this.state.eventTickets.forEach(function (ticket, index) {
            typeIds.push(ticket.getTicketTypeId());
        });
        let self = this;
        const req = new UserTicketsForTicketTypeRequest();
        req.setTicketTypeIdList(typeIds);
        req.setCursor('');
        req.setPageSize(100);
        getUserTicketListByTypeId(req, 3).then(function (response) {
            let userTicketMap = new Map();
            let allTicketId = [];
            response.getUserTicketList().forEach(function (ticket, index) {
                let newTicket = [];
                let previousTickets = [];
                if (allTicketId.includes(ticket.getTicketTypeId())) {
                    if (userTicketMap.get(ticket.getTicketTypeId())) {
                        previousTickets = userTicketMap.get(ticket.getTicketTypeId());
                        previousTickets.push(ticket);
                        userTicketMap.set(ticket.getTicketTypeId(), previousTickets);
                    } else {
                        newTicket.push(ticket);
                        userTicketMap.set(ticket.getTicketTypeId(), newTicket);
                    }
                } else {
                    allTicketId.push(ticket.getTicketTypeId());
                    newTicket.push(ticket);
                    userTicketMap.set(ticket.getTicketTypeId(), newTicket);
                }
            });

            self.setState({
                userTickets: userTicketMap,
            });
        }).catch(function (error) {

        })
    };

    userResponseToEvent = (status, event) => {
        if (!isLogin()) {
            sessionStorage.setItem('userStatus', status + ':' + event.getEventId());
            this.openLoginDialog()
        } else {
            sessionStorage.removeItem('userStatus');
            eventAnalyst('community_event_action', status, status + '-users', 'event-users');
            let self = this;
            this.setState({
                userStatus: status
            });
            let userSts;
            if (status === usedString.GOING) {
                userSts = GoingStatus.GOING
            } else if (status === usedString.INTERESTED) {
                userSts = GoingStatus.INTERESTED
            } else if (status === usedString.DECLINED) {
                userSts = GoingStatus.DECLINED
            }
            const req = new UpdateGoingStatusRequest();
            req.setGroupId(this.state.groupId);
            req.setEventId(event.getEventId());
            req.setYear(event.getYear());
            req.setMonth(event.getMonth());
            req.setDay(event.getDay());
            req.setStatus(userSts);
            updateUserResponseToEvent(req).then(function (response) {
                self.fetchEventDetails(self.state.groupId, event.getEventId());
            }).catch(function (error) {
            })
        }
    };

    handleTabChange = (event, value) => {
        this.setState({tabValue: value});
    };

    handleEventTypeChange = name => event => {
        this.setState({changeEventType: true});
        this.setState({
            [name]: event.target.value,
        });
    };

    getETypeChangeResponse = (clickedButton, key) => {
        this.setState({snackOpen: false, changeEventType: false});
        if (clickedButton === "Yes") {
            this.setState({changingGroupType: true});
            let self = this;
            const req = new UpdateEventRequest();
            const event = self.state.eventDetails;
            let eventSts;
            if (this.state.tempType === usedString.Open) {
                eventSts = Event.EventStatus.OPEN;
            } else if (this.state.tempType === usedString.Closed) {
                eventSts = Event.EventStatus.CLOSED;
            } else if (this.state.tempType === usedString.Canceled) {
                eventSts = Event.EventStatus.CANCELED;
            } else if (this.state.tempType === usedString.Postponed) {
                eventSts = Event.EventStatus.POSTPONED;
            } else eventSts = Event.EventStatus.OPEN;
            event.setEventStatus(eventSts);
            event.setEventId(self.state.eventId);
            req.setEvent(event);
            // return;
            updateEventDetails(req).then(function (response) {
                self.setState({
                    eType: self.state.tempType,
                    changeEventType: false
                });
                self.snackbar(true, 'Event is now ' + self.state.eType, 'success', 5000);
            }).catch(function (error) {
                self.setState({
                    changeEventType: false
                });
                self.snackbar(true, 'Sorry. Could not change event type', 'error', 5000);
            })
        }
    };

    deleteEvent = (eventId) => {
        this.setState({snackOpen: false});
        let self = this;
        const req = new RemoveEventRequest();
        req.setGroupId(this.state.groupId);
        req.setEventId(eventId);
        deleteEvent(req).then(function (response) {
            eventAnalyst('community_event_detail', 'community_event_delete', self.state.groupId + '-' + self.state.eventId + '-community event delete success', 'community-event-delete-fail');
            self.snackbar(true, 'Event is Deleted', 'success', 1000);
            setTimeout(function () {
                window.location.href = '/c/' + self.state.groupId;
            }, 3000);
        }).catch(function (error) {
            eventAnalyst('community_event_detail', 'community_event_delete', self.state.groupId + '-' + self.state.eventId + '-community event delete fail', 'community-event-delete-fail');
            self.snackbar(true, 'Error Deleting Event', 'error', 5000)
        })
    };

    openUserLists = (eventDetails) => {
        var eventGoingText;
        if (eventDetails.getStartFrom() <= new Date().getTime() && eventDetails.getEnds() >= new Date().getTime()) {
            eventGoingText = 'Attending';
        } else if (eventDetails.getEnds() <= new Date().getTime()) {
            eventGoingText = 'Went'
        } else {
            eventGoingText = 'Going'
        }
        this.setState({openUserGoing: true, userEventText: eventGoingText})
    };

    scrollToMyRef = () => {
        this.setState({
            changeBackground: true
        });
        setTimeout(() => {
            this.setState({
                changeBackground: false
            });
        }, 2000);
        window.scrollTo(0, this.scrollToCat.offsetTop)
    };

    closeButton = (eventId) => {
        this.setState({
            hideTicket: true
        })
    };

    render() {
        const {classes, width} = this.props;
        const {groupType, pageViewCount, anchorEl, title, tabValue, openUserGoing, isLoadingEvent, eventDetails, errSts, userStatus, userGroupStatus, userRole, groupId, openConfirmation, eventId, goingCount, interestedCount, userEventText, attenders, eventTickets, isBought, userTickets, loadingTickets, openLoginDialog, showMore, ticketId, fieldValues, eType, changeEventType, tempType, openNotificationDialog, changeBackground, hideTicket, canSendNotification, maxEventNotification, viewMap} = this.state;
        const rootGroupsURl = '/c/' + groupId;
        const isMobile = ['xs'].includes(width);
        const isMobileLess = ['xs', 'sm', 'lg'].includes(width);
        const isMobileMoreLess = ['xs', 'sm', 'md'].includes(width);
        const shareUrl = window.location.href;
        const shareTitle = 'Join the event - ';
        const open = Boolean(anchorEl);
        return (
            <DashboardLayout title={`${title ? title + "'s Event" : "Event"}`} onBack={`/c/${groupId}`} setTitle={false}
                             linkTo={`/c/${groupId}`}>
                <div className={classes.minHeight}>
                    {!isLoadingEvent ? (
                        <div>
                            {userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT || userRole === usedString.MEMBER || userRole === usedString.DEFAULT ? (
                                <div>
                                    {eventDetails ? (
                                        <div>
                                            <div>
                                                <div className={classes.eventsCard}>
                                                    <Card className={classes.cardDetails}>
                                                        {getEventTypeStringInSentence(eventDetails.getEventStatus()) === usedString.Open || getEventTypeStringInSentence(eventDetails.getEventStatus()) === usedString.Postponed ?
                                                            eventTickets && eventTickets.length && eventDetails.getTicketAvailable() && eventDetails.getEnds() >= new Date().getTime() ? (
                                                                <div className={classes.buyTicketsDetails}
                                                                     onClick={() => this.scrollToMyRef()}>
                                                                    Get Tickets
                                                                </div>
                                                            ) : null :
                                                            <div className={classes.eventStsDetails}>
                                                                {getEventTypeStringInSentence(eventDetails.getEventStatus())}
                                                            </div>
                                                        }
                                                        <CardMedia
                                                            className={classes.mediaEventDetails}
                                                            image={eventDetails.getEventCoverImage() ? eventDetails.getEventCoverImage() : '/images/eventDefault.png'}
                                                            title={eventDetails.getTitle()}
                                                        />
                                                        <CardContent>
                                                            {canSendNotification && userRole === usedString.ADMIN ? (
                                                                <div className={classes.notifyWrapper}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="default"
                                                                        className={classes.fab} size="small"
                                                                        onClick={this.openNotificationDialog}
                                                                        endIcon={
                                                                            <Icon>notifications_none</Icon>}
                                                                    >
                                                                        Notify
                                                                    </Button>
                                                                </div>
                                                            ) : null}
                                                            {userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT ? (
                                                                <div className={classes.actionWrapper}>
                                                                    <Link
                                                                        to={`${rootGroupsURl}/events/${eventDetails.getEventId()}/edit`}>
                                                                        <Fab color="secondary" aria-label="edit"
                                                                             className={classes.fab} size="small">
                                                                            <Icon>edit_icon</Icon>
                                                                        </Fab>
                                                                    </Link>
                                                                    {userRole === usedString.ADMIN ? (
                                                                        <div>
                                                                            <Fab color="primary" aria-label="delete"
                                                                                 className={classes.fab} size="small"
                                                                                 onClick={this.openConfirmation}>
                                                                                <Icon>delete_icon</Icon>
                                                                            </Fab>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            ) : (<span></span>)}
                                                            <div className={classes.clearBoth}>
                                                                <div className={classes.interested}>
                                                                    <div style={{display: "inline-flex"}}>
                                                                        <FacebookShareButton
                                                                            url={shareUrl}
                                                                            quote={shareTitle}
                                                                            className="socialShareIcons">
                                                                            <FacebookIcon
                                                                                size={32}
                                                                                round/>
                                                                        </FacebookShareButton>
                                                                        <TwitterShareButton
                                                                            url={shareUrl}
                                                                            title={shareTitle + eventDetails.getTitle()}
                                                                            className="socialShareIcons">
                                                                            <TwitterIcon
                                                                                size={32}
                                                                                round/>
                                                                        </TwitterShareButton>
                                                                    </div>
                                                                </div>
                                                                {openConfirmation ? (
                                                                    <UserConfirmationDialog
                                                                        openDialog={openConfirmation}
                                                                        dialogHeader="Delete Event"
                                                                        postId={eventDetails.getEventId()}
                                                                        dialogContent={"Are you sure want to delete event ?"}
                                                                        button1="NO" button2="YES"
                                                                        toDeleteContent={<div
                                                                            className={classes.sharableLinkWrapper}>
                                                                                <span
                                                                                    className={classes.sharableText}> {eventDetails.getTitle()}</span>
                                                                        </div>}
                                                                        sendUserResponse={this.getUserResponseFromChild}
                                                                    />
                                                                ) : (<div></div>)}

                                                            </div>
                                                            <Typography
                                                                variant="h5"
                                                                style={{fontWeight: 600}}
                                                            >{eventDetails.getTitle()}
                                                            </Typography>
                                                            {eType === usedString.Closed || eType === usedString.Canceled ? (
                                                                <div className={classes.eventChangeSts}>
                                                                    Event has been {eType}.
                                                                </div>
                                                            ) : null}
                                                            <div>
                                                                {(isAdmin() || userRole === usedString.ADMIN) && pageViewCount > 0 ? (
                                                                    <Typography variant="body3" component="span"
                                                                                color="secondary">
                                                                        Page View
                                                                        : {addCommas(pageViewCount)} times
                                                                    </Typography>
                                                                ) : null}
                                                                <Typography
                                                                    variant="h5"
                                                                    className={classes.eventName}
                                                                >
                                                                    <span>{formatDate(eventDetails.getStartFrom(), 'MMMM dd, yyyy p')}</span> - <span>{formatDate(eventDetails.getEnds(), 'MMMM dd, yyyy p')}</span>
                                                                </Typography>
                                                                {(eType === usedString.Open || eType === usedString.Postponed) ? (
                                                                    <div>
                                                                        <Typography
                                                                            variant="body2" className={classes.eventSts}
                                                                        >{eventDetails.getStartFrom() <= new Date().getTime() && eventDetails.getEnds() >= new Date().getTime() ? (
                                                                            <div>
                                                                                Started {timeEvent(new Date(eventDetails.getStartFrom()))}
                                                                            </div>
                                                                        ) : <div>
                                                                            {eventDetails.getEnds() <= new Date().getTime() ? (
                                                                                    <div>
                                                                                        Ended {timeEvent(new Date(eventDetails.getEnds()))}
                                                                                    </div>
                                                                                ) :
                                                                                <div> Starting {timeEvent(new Date(eventDetails.getStartFrom()))}</div>}
                                                                        </div>}
                                                                        </Typography>
                                                                    </div>
                                                                ) : null}
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    className={classes.eventLocation}
                                                                > {eventDetails.getIsPrivate() ? ' Private' : ' Public' + ' Event'}
                                                                    {eventDetails.getLocationString() ? (
                                                                        <span> | at {eventDetails.getLocationString()}</span>) : null}
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                ><span
                                                                    title={`Associated with ${title} - (${titleCase(userGroupStatus)}-${titleCase(userRole)})`}>Hosted By </span>
                                                                    <Link className={classes.hostedBy}
                                                                          title={`Associated with ${title}`}
                                                                          to={`${rootGroupsURl}`}>{eventDetails.getMetaDataMap().get('event_hosted_by') ? eventDetails.getMetaDataMap().get('event_hosted_by') : title}</Link>
                                                                </Typography>
                                                                <br/>
                                                                <div>
                                                                    <Typography
                                                                        component="div"
                                                                        variant="body1"
                                                                        style={{fontWeight: 600}}
                                                                        className={classes.eventMember}
                                                                        onClick={() => this.openUserLists(eventDetails)}
                                                                    >
                                                                        {eventDetails.getStartFrom() <= new Date().getTime() && eventDetails.getEnds() >= new Date().getTime() ? (
                                                                            <div>
                                                                                <span>{goingCount > 0 ? goingCount + ' Attending' : ''} {(goingCount > 0 && interestedCount > 0) ? ' ,' : ''} {interestedCount > 0 ? interestedCount + ' Interested' : ''}</span>
                                                                            </div>
                                                                        ) : <div>
                                                                            {eventDetails.getEnds() <= new Date().getTime() ? (
                                                                                    <div>
                                                                                        <span>{goingCount > 0 ? goingCount + ' Went' : ''} {(goingCount > 0 && interestedCount > 0) ? ' ,' : ''} {interestedCount > 0 ? interestedCount + ' Interested' : ''}</span>
                                                                                    </div>
                                                                                ) :
                                                                                <div>
                                                                                    <span>{goingCount > 0 ? goingCount + ' Going' : ''} {(goingCount > 0 && interestedCount > 0) ? ' ,' : ''} {interestedCount > 0 ? interestedCount + ' Interested' : ''}</span>
                                                                                </div>}
                                                                        </div>}
                                                                    </Typography>
                                                                    <div className={classes.likedUser}
                                                                         onClick={() => this.openUserLists(eventDetails)}>
                                                                        {attenders ? (
                                                                            <ul className={classes.likedUnOrder}>
                                                                                {attenders.map(attender => (
                                                                                    <li className={classes.likedList}>
                                                                                        <Avatar
                                                                                            alt={attender.getAttenders().getAccountName()}
                                                                                            key={attender.getAttenders().getAccountId()}
                                                                                            title={attender.getAttenders().getAccountName()}
                                                                                            style={{
                                                                                                backgroundImage: `url('${attender.getAttenders().getAccountImage()}')`,
                                                                                                backgroundRepeat: 'round'
                                                                                            }}
                                                                                            className={classes.likedUserImg}/>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        ) : (<div></div>)}
                                                                        {(goingCount + interestedCount) - attenders.length > 0 ? (
                                                                            <Avatar className={classes.likedUserImg}
                                                                                    style={{
                                                                                        margin: '1px',
                                                                                        marginLeft: '-7px'
                                                                                    }}>
                                                                <span
                                                                    className={classes.moreLikePeople}
                                                                    title="Attenders">+{(goingCount + interestedCount) - attenders.length}
                                                                </span>
                                                                            </Avatar>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                {(eType === usedString.Open || eType === usedString.Postponed) && eventDetails.getEnds() >= new Date().getTime() ? (
                                                                    <Grid container spacing={3}
                                                                          className={isMobileLess ? classes.textCenter : ''}>
                                                                        <Grid item xs={12} sm={12}>
                                                                            {userStatus === usedString.INTERESTED ? (
                                                                                <Button size="small"
                                                                                        variant="outlined"
                                                                                        onClick={() => this.userResponseToEvent(usedString.INTERESTED, eventDetails)}
                                                                                        className={classNames(classes.interestedButton, isMobileLess ? classes.fitContent : '')}>
                                                                                    <StarIcon
                                                                                        style={{color: '#ffe100'}}/> &nbsp;
                                                                                    Interested
                                                                                </Button>) : (
                                                                                <Button size="small"
                                                                                        variant="outlined"
                                                                                        onClick={() => this.userResponseToEvent(usedString.INTERESTED, eventDetails)}
                                                                                        className={classNames(classes.beforeInterested, isMobileLess ? classes.fitContent : '')}>
                                                                                    <StarBorderIcon
                                                                                        style={{color: '#ffe100'}}/> &nbsp;
                                                                                    Interested
                                                                                </Button>
                                                                            )}
                                                                            <Button
                                                                                variant="outlined"
                                                                                size="small"
                                                                                aria-label="Add"
                                                                                className={userStatus === usedString.GOING ? classNames(classes.invitationAccepted, isMobileLess ? classes.fitContent : '') : classNames(classes.beforeGoing, isMobileLess ? classes.fitContent : '')}
                                                                                onClick={() => this.userResponseToEvent(usedString.GOING, eventDetails)}
                                                                            >
                                                                                GOING
                                                                            </Button>
                                                                            <Button
                                                                                variant="outlined"
                                                                                size="small"
                                                                                aria-label="Add"
                                                                                className={userStatus === usedString.DECLINED ? classNames(classes.invitationRejected, isMobileLess ? classes.fitContent : '') : classNames(classes.beforeMayBe, isMobileLess ? classes.fitContent : '')}
                                                                                onClick={() => this.userResponseToEvent(usedString.DECLINED, eventDetails)}
                                                                            >
                                                                                May be
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>) : null}
                                                                {/*<br/>*/}
                                                                {/*{(eType === usedString.Closed || eType === usedString.Canceled) ? (*/}
                                                                {/*    <div*/}
                                                                {/*        className={classes.eventStsCard}>Event {eType}</div>*/}
                                                                {/*) : null}*/}
                                                            </div>
                                                        </CardContent>
                                                    </Card>
                                                    <div>
                                                        {eventTickets && eventTickets.length > 0 ? (
                                                            <div>
                                                                <div ref={(ref) => this.scrollToCat = ref}></div>
                                                                <div style={{margin: '10px'}}>
                                                                    {!loadingTickets ? (
                                                                        <div className={classes.eventTicketLists}>
                                                                            <Grid
                                                                                container
                                                                                spacing={3}
                                                                            >
                                                                                <Grid item xs={12} md={12} sm={12}
                                                                                      lg={12}
                                                                                      style={{padding: 0}}>
                                                                                    <div>
                                                                                        <div
                                                                                            className={classes.floatLeft}>
                                                                                            <Typography
                                                                                                variant="h6"
                                                                                                style={{
                                                                                                    fontWeight: 900,
                                                                                                    padding: '20px 10px'
                                                                                                }}
                                                                                            > Available Tickets
                                                                                            </Typography>
                                                                                        </div>
                                                                                        {userRole === usedString.ADMIN || isAdmin() ? (
                                                                                            <div
                                                                                                className={classes.floatRight}
                                                                                                style={{padding: '20px 10px'}}>
                                                                                                <Link
                                                                                                    to={`${rootGroupsURl}/events/${eventDetails.getEventId()}/manage/dashboard`}>
                                                                                                    <Button
                                                                                                        color="secondary">
                                                                                                        Dashboard
                                                                                                    </Button>
                                                                                                </Link>
                                                                                            </div>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                            {eventTickets.map((ticket, index) => (
                                                                                <Grid
                                                                                    container
                                                                                    spacing={isMobile ? 2 : 3}
                                                                                    style={changeBackground ? {background: 'linear-gradient(180deg, rgb(250, 225, 2), transparent)'} : {}}
                                                                                    className={isMobile ? classes.ticketListWrapperMobile : classes.ticketListWrapper}
                                                                                >
                                                                                    <Grid item xs={12} md={4} sm={4}
                                                                                          lg={4}
                                                                                          className={classes.ticketItems}>
                                                                                        <Typography component="div">
                                                                                    <span
                                                                                        className={classes.availableTicketDesc}>{index + 1}.</span>&nbsp;&nbsp;
                                                                                            <span
                                                                                                className={classes.ticketNameList}>{ticket.getTitle()}</span>
                                                                                        </Typography>
                                                                                        {userTickets && userTickets.get(ticket.getTicketTypeId()) ? (
                                                                                            <span>
                                                                                        <Avatar alt='ticket Name'
                                                                                                title="Tickets"
                                                                                                style={{
                                                                                                    backgroundImage: `url('/images/icons/tickets.png')`,
                                                                                                    backgroundRepeat: 'round',
                                                                                                    backgroundColor: 'unset',
                                                                                                    margin: 'auto',
                                                                                                    top: '5px',
                                                                                                    width: '20px',
                                                                                                    height: '20px',
                                                                                                    display: 'inline-flex'
                                                                                                }}/>
                                                                                    <Link
                                                                                        to={`/tickets/${ticket.getTicketSourceId()}/${ticket.getTicketTypeId()}/ct/${userTickets.get(ticket.getTicketTypeId()).length}`}
                                                                                        style={{paddingLeft: '5px'}}>
                                                                                        <Typography component="span"
                                                                                                    className={classes.userTicketCount}>
                                                                                            You bought {userTickets.get(ticket.getTicketTypeId()).length} tickets
                                                                                        </Typography>
                                                                                    </Link>
                                                                                    </span>
                                                                                        ) : null}
                                                                                    </Grid>
                                                                                    <Grid item xs={12} md={4} sm={4}
                                                                                          lg={4}
                                                                                          className={classes.ticketItems}>
                                                                                        <Typography component="div"
                                                                                                    className={classes.availableTicketDesc}>
                                                                                            {shortenString(ticket.getDescription(), 24)}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} md={2} sm={2}
                                                                                          lg={2}
                                                                                          className={classes.ticketItems}>
                                                                                        {ticket.getTicketType() === usedString.FREE_TICKET ? (
                                                                                            <Typography component="div"
                                                                                                        className={classes.ticketAmount}>
                                                                                                FREE TICKET
                                                                                            </Typography>
                                                                                        ) : (
                                                                                            <Typography component="div"
                                                                                                        className={classes.ticketAmount}>
                                                                                                {ticket.getPricingDetail().getCurrency()} {getCountryCurrencySymbol(ticket.getPricingDetail().getCurrency())}{ticket.getPricingDetail().getPrice()}
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Grid>
                                                                                    {(eType === usedString.Canceled || eType === usedString.Closed) ? (
                                                                                        <Grid item xs={12} md={2} sm={2}
                                                                                              lg={2}
                                                                                              className={classes.ticketItems}>
                                                                                            <Button
                                                                                                variant="contained"
                                                                                                color="secondary"
                                                                                                style={{
                                                                                                    fontSize: '10px',
                                                                                                    fontWeight: 600
                                                                                                }}
                                                                                                fullWidth
                                                                                                disabled>
                                                                                                {eType}
                                                                                            </Button>
                                                                                        </Grid>
                                                                                    ) : (
                                                                                        <Grid item xs={12} md={2} sm={2}
                                                                                              lg={2}
                                                                                              className={classes.ticketItems}>
                                                                                            {eventDetails.getEnds() <= new Date().getTime() ? (
                                                                                                    <Button
                                                                                                        variant="contained"
                                                                                                        color="secondary"
                                                                                                        style={{
                                                                                                            fontSize: '10px',
                                                                                                            fontWeight: 600
                                                                                                        }}
                                                                                                        fullWidth
                                                                                                        disabled>
                                                                                                        Ended
                                                                                                    </Button>) :
                                                                                                ticket.getSoldOut().toString() === 'true' ? (
                                                                                                    <Button
                                                                                                        variant="contained"
                                                                                                        color="secondary"
                                                                                                        style={{
                                                                                                            fontSize: '10px',
                                                                                                            fontWeight: 600
                                                                                                        }}
                                                                                                        fullWidth
                                                                                                        disabled>
                                                                                                        Sold Out
                                                                                                    </Button>
                                                                                                ) : (
                                                                                                    <BuyTickets
                                                                                                        ticketSoldOut={ticket.getSoldOut().toString()}
                                                                                                        ticketId={ticket.getTicketTypeId()}
                                                                                                        ticketDesc={ticket.getDescription()}
                                                                                                        isFreeTicket={ticket.getTicketType() === usedString.FREE_TICKET}
                                                                                                        ticketCurrency={ticket.getTicketType() === usedString.FREE_TICKET ? '' : ticket.getPricingDetail().getCurrency()}
                                                                                                        ticketName={ticket.getTitle()}
                                                                                                        ticketExp={ticket.getExpiration()}
                                                                                                        ticketPrice={ticket.getPricingDetail().getPrice()}
                                                                                                        eventId={eventDetails.getEventId()}
                                                                                                        maxTicket={ticket.getMaxAvailableLimit()}
                                                                                                        available={ticket.getAvailable()}
                                                                                                        maxPurchase={ticket.getMaxPurchaseCount()}
                                                                                                        eventName={eventDetails.getTitle()}
                                                                                                        groupID={groupId}
                                                                                                        fieldDatas={ticketId === ticket.getTicketTypeId() ? fieldValues : null}
                                                                                                        openTicket={(eType === usedString.Closed || eType === usedString.Canceled) ? false : ticketId === ticket.getTicketTypeId()}
                                                                                                        eventImage={eventDetails.getEventCoverImage() ? eventDetails.getEventCoverImage() : ''}
                                                                                                        sendCloseResponse={this.closeResponse}/>
                                                                                                )}
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            ))}
                                                                        </div>
                                                                    ) : (<div><CircularProgress
                                                                        size={15}/> Tickets Loading.. </div>)}
                                                                </div>
                                                            </div>
                                                        ) : (<span></span>)}
                                                    </div>
                                                </div>
                                                <div>
                                                    <AppBar position="static" color="" style={{boxShadow: 'unset'}}>
                                                        <Tabs
                                                            value={tabValue}
                                                            onChange={this.handleTabChange}
                                                            indicatorColor="primary"
                                                            textColor="primary"
                                                            variant="scrollable"
                                                            scrollButtons="auto"
                                                            // style={{display: 'unset', textAlign: "center"}}
                                                            style={{width: '100%', padding: '16px 0'}}
                                                        >
                                                            <Tab value={0} label="Description"/>
                                                            <Tab value={1} label="Location"/>
                                                            {/*<Tab label="Host"/>*/}
                                                            <Tab value={2} label="Contact"/>
                                                            {userRole === usedString.ADMIN ? (
                                                                <Tab value={3} label="Setting"/>
                                                            ) : null}
                                                        </Tabs>
                                                        {tabValue === 0 &&
                                                        <TabContainer style={{padding: 0}}>
                                                            <div>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body1"
                                                                    style={{whiteSpace: 'pre-line'}}
                                                                    display="inline"
                                                                >
                                                                    <RenderText text={eventDetails.getEventDesc()}/>
                                                                    {/*{showMore ? eventDetails.getEventDesc() : shortenString(eventDetails.getEventDesc(), 150)}*/}
                                                                    {/*{eventDetails.getEventDesc() && eventDetails.getEventDesc().length >= 150 && !showMore ? (*/}
                                                                    {/*    <span onClick={this.setShowMore} style={{*/}
                                                                    {/*        textDecoration: "underline",*/}
                                                                    {/*        cursor: "pointer",*/}
                                                                    {/*        fontSize: '14px',*/}
                                                                    {/*        color: '#3377e0'*/}
                                                                    {/*    }}>Show More</span>) : null}*/}
                                                                </Typography>
                                                            </div>
                                                        </TabContainer>}
                                                        {tabValue === 1 &&
                                                        <TabContainer>
                                                            <div className={classes.aboutUsWrapper}>
                                                                <Typography
                                                                    className={classes.mainText}
                                                                    variant="h5"
                                                                > Location
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                >{eventDetails.getLocationString() ? `at ` + eventDetails.getLocationString() : ''}
                                                                    <span
                                                                        onClick={() => this.setState({viewMap: true})}
                                                                        className={classes.hostedBy}
                                                                    >View Map
                                                                </span>
                                                                </Typography>
                                                                {viewMap ? (
                                                                    <div>
                                                                        {(eventDetails.getLocation().getLat() && eventDetails.getLocation().getLon()) ? (
                                                                            <div>
                                                                                <a href={`https://www.google.com/maps/search/?api=1&query=${eventDetails.getLocation().getLat()},${eventDetails.getLocation().getLon()}`}
                                                                                   target="_blank">
                                                                                    <img className={classes.mapImage}
                                                                                         src={`https://maps.googleapis.com/maps/api/staticmap?center=${eventDetails.getLocation().getLat()},${eventDetails.getLocation().getLon()}&size=2048x250&scale=4&zoom=15&key=AIzaSyBbWutco8YMhBuGTTexubtCHs34ZEFl490&markers=color:blue%7Clabel:%7C${eventDetails.getLocation().getLat()},${eventDetails.getLocation().getLon()}`}
                                                                                         alt={eventDetails.getLocationString()}/>
                                                                                </a>
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </TabContainer>}
                                                        {tabValue === 3 &&
                                                        <TabContainer>
                                                            <div className={classes.aboutUsWrapper}>
                                                                <Typography
                                                                    className={classes.mainText}
                                                                    variant="h6"
                                                                > Event Setting
                                                                </Typography>
                                                                <Typography
                                                                    component="div"
                                                                    color="textPrimary"
                                                                > Event is currently {eType}.
                                                                </Typography>
                                                            </div>
                                                            <FormControl component="fieldset"
                                                                         className={classes.formControl}>
                                                                <RadioGroup
                                                                    className={classes.group}
                                                                    value={eType}
                                                                    onChange={this.handleEventTypeChange('tempType')}
                                                                    row
                                                                >
                                                                    <FormControlLabel value="Open"
                                                                                      control={<Radio/>}
                                                                                      label="Open Event"/>
                                                                    <FormControlLabel value="Closed"
                                                                                      control={<Radio/>}
                                                                                      label="Close Event"/>
                                                                    <FormControlLabel value="Canceled"
                                                                                      control={<Radio/>}
                                                                                      label="Cancel Event"/>
                                                                    <FormControlLabel value="Postponed"
                                                                                      control={<Radio/>}
                                                                                      label="Postpone Event"/>
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </TabContainer>}
                                                        {tabValue === 2 &&
                                                        <TabContainer>
                                                            {eventDetails.getOrganizerPhone() && eventDetails.getOrganizerEmail() ? (
                                                                <div>
                                                                    <div style={{
                                                                        fontWeight: 900,
                                                                        borderBottom: '1px solid #e5e5e5'
                                                                    }}>
                                                                        <Grid
                                                                            container
                                                                            spacing={1}
                                                                        >
                                                                            {/*<Grid item xs={12} sm={12}>*/}
                                                                            {/*    <Link  to={`${rootGroupsURl}`} className={classes.hostedBy}>*/}
                                                                            {/*        <Typography*/}
                                                                            {/*            component="span"*/}
                                                                            {/*            variant="h6"*/}
                                                                            {/*            style={{fontWeight: 600, color: '#0077e0', paddingLeft:'20px'}}*/}
                                                                            {/*            className={classes.inline}*/}
                                                                            {/*            color="textPrimary"*/}
                                                                            {/*        >{eventDetails.getOrganizerName()}*/}
                                                                            {/*        </Typography>*/}
                                                                            {/*    </Link>*/}
                                                                            {/*</Grid>*/}
                                                                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                                                                <List>
                                                                                    <ListItem style={{paddingTop: 0}}>
                                                                                        <ListItemAvatar>
                                                                                            <Avatar>
                                                                                                <PhoneIcon/>
                                                                                            </Avatar>
                                                                                        </ListItemAvatar>
                                                                                        <ListItemText primary={
                                                                                            <Typography
                                                                                                component="span"
                                                                                                variant="body1"
                                                                                                style={{fontWeight: 600}}
                                                                                                className={classes.inline}
                                                                                                color="textPrimary"
                                                                                            >{eventDetails.getOrganizerName()}
                                                                                            </Typography>
                                                                                        } secondary={<div style={{
                                                                                            fontWeight: 600,
                                                                                            lineHeight: 1.3
                                                                                        }} title="Phone Number">
                                                                                            <a href={`tel:${eventDetails.getOrganizerPhone()}`}
                                                                                               className={classes.contactHover}>
                                                                                            <span
                                                                                                style={{color: '#777272'}}>{eventDetails.getOrganizerPhone()}</span></a>
                                                                                        </div>
                                                                                        }/>
                                                                                    </ListItem>
                                                                                </List>
                                                                            </Grid>
                                                                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                                                                <List>
                                                                                    <ListItem style={{paddingTop: 0}}>
                                                                                        <ListItemAvatar>
                                                                                            <Avatar>
                                                                                                <MailIcon/>
                                                                                            </Avatar>
                                                                                        </ListItemAvatar>
                                                                                        <ListItemText primary={
                                                                                            <Typography
                                                                                                component="span"
                                                                                                variant="body1"
                                                                                                style={{
                                                                                                    fontWeight: 600,
                                                                                                    color: '#777272'
                                                                                                }}
                                                                                                className={classes.inline}
                                                                                                color="textPrimary"
                                                                                            >Email
                                                                                            </Typography>
                                                                                        } secondary={<a
                                                                                            href={`mailto:${eventDetails.getOrganizerEmail()}`}
                                                                                            className={classes.contactHover}
                                                                                            title="Email">{eventDetails.getOrganizerEmail()}</a>}/>
                                                                                    </ListItem>
                                                                                </List>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                            {eventDetails.getOrganizerList().length > 0 ? (
                                                                <div>
                                                                    <Typography
                                                                        variant="h6"
                                                                        style={{
                                                                            fontWeight: 600,
                                                                            padding: '15px 15px 0'
                                                                        }}
                                                                    > Supporting Contacts
                                                                    </Typography>
                                                                    <div>
                                                                        <Grid
                                                                            container
                                                                            spacing={1}
                                                                        >
                                                                            {eventDetails.getOrganizerList().map((organizer, index) => (
                                                                                <Grid item xs={12} sm={6}>
                                                                                    <List>
                                                                                        <ListItem
                                                                                            style={{paddingTop: 0}}>
                                                                                            <ListItemAvatar>
                                                                                                <Avatar>
                                                                                                    <PeopleIcon/>
                                                                                                </Avatar>
                                                                                            </ListItemAvatar>
                                                                                            <ListItemText primary={
                                                                                                <Typography
                                                                                                    component="span"
                                                                                                    variant="body1"
                                                                                                    style={{fontWeight: 600}}
                                                                                                    className={classes.inline}
                                                                                                    color="textPrimary"
                                                                                                >{organizer.getName()}
                                                                                                </Typography>
                                                                                            } secondary={
                                                                                                <div style={{
                                                                                                    fontWeight: 600,
                                                                                                    lineHeight: 1.7
                                                                                                }}>
                                                                                                    P: <a
                                                                                                    href={`tel:${organizer.getPhone()}`}
                                                                                                    className={classes.contactHover}
                                                                                                    title="Phone Number">
                                                                                                <span
                                                                                                    style={{color: '#777272'}}>{organizer.getPhone()}</span></a>
                                                                                                    <br/>
                                                                                                    E: <a
                                                                                                    href={`mailto:${organizer.getEmail()}`}
                                                                                                    className={classes.contactHover}
                                                                                                    title="Email"> <span
                                                                                                    style={{color: '#777272'}}>{organizer.getEmail()}</span></a>
                                                                                                </div>
                                                                                            }/>
                                                                                        </ListItem>
                                                                                    </List>
                                                                                </Grid>
                                                                            ))}
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </TabContainer>}
                                                    </AppBar>
                                                </div>
                                            </div>
                                            <div className={classes.pageDivider}></div>
                                            {eventDetails.getEventId() === 'b75c43d0-1522-11ea-a1d4-0242ac120036' ? (
                                                <div>
                                                    <Typography
                                                        variant="h6"
                                                        style={{fontWeight: 900}}
                                                        className={classes.discussion}
                                                    > Medal Tally
                                                    </Typography>
                                                    <div className={classes.discussion}>
                                                        <SAG/>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {eventDetails.getMetaDataMap().get('event_video_url') ? (
                                                <div>
                                                    <Typography
                                                        variant="h6"
                                                        style={{fontWeight: 900, padding: '15px 15px 0'}}
                                                    > Video
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle1"
                                                        style={{fontWeight: 600, padding: '15px 15px 0'}}
                                                    > {eventDetails.getMetaDataMap().get('event_video_title')}
                                                    </Typography>
                                                    <div style={{fontWeight: 900, padding: '15px 15px 0'}}>
                                                        <div className={classNames('videoWrapper')}>
                                                            <iframe width="560" height="349"
                                                                    src={`https://www.youtube.com/embed/${eventDetails.getMetaDataMap().get('event_video_url')}`}
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen></iframe>
                                                        </div>
                                                        {/*<Grid*/}
                                                        {/*    container*/}
                                                        {/*    spacing={3}*/}
                                                        {/*>*/}
                                                        {/*    <Grid item sm={12} style={{margin: 'auto'}}>*/}
                                                        {/*        */}
                                                        {/*    </Grid>*/}
                                                        {/*</Grid>*/}
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className={classes.pageDivider}></div>
                                            {eventDetails.getSponsorList().length > 0 ? (
                                                <div>
                                                    <Typography
                                                        variant="h6"
                                                        style={{fontWeight: 900, padding: '15px 15px 0'}}
                                                    > Partners
                                                    </Typography>
                                                    <div style={{overflow: "auto"}}>
                                                        <div style={{
                                                            fontWeight: 900,
                                                            padding: '10px',
                                                            display: 'inline-flex'
                                                        }}>
                                                            {eventDetails.getSponsorList().map((sponsor, index) => (
                                                                <div key={sponsor.getName()}
                                                                     className={classes.sponsorImg}>
                                                                    <a href={sponsor.getLink()} target="_blank">
                                                                        <img
                                                                            src={sponsor.getImageUrl() ? sponsor.getImageUrl() : '/images/defaultLogo.png'}
                                                                            style={{
                                                                                height: '100%',
                                                                                objectFit: 'cover'
                                                                            }}/>
                                                                    </a>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className={classes.pageDivider}></div>
                                            <Typography
                                                variant="h6"
                                                style={{fontWeight: 900}}
                                                className={classes.discussion}
                                            > Discussions
                                            </Typography>
                                            {eventDetails.getMetaDataMap().get('event_post_enable') === 'true' ? (
                                                <div className={classes.eventPost}>
                                                    <GetPostedContent groupName={title} groupId={groupId}
                                                                      groupMembers=''
                                                                      groupType={groupType}
                                                                      userRoleStatus={userGroupStatus}
                                                        // userRoleStatus={eventDetails.getIsPrivate() ? userGroupStatus : usedString.APPROVED}
                                                                      channelName={`post@${eventDetails.getEventId()}`}
                                                        // userRole={eventDetails.getIsPrivate() ? userRole : usedString.MEMBER}
                                                                      userRole={userRole}
                                                    />
                                                </div>
                                            ) : (
                                                <div className={classes.eventComment}>
                                                    <GetUserComments commentPostId={eventDetails.getEventId()}
                                                                     groupName={title}
                                                                     postTitle={title ? shortenString(title + "'s Event Post", 25) : ''}
                                                                     groupId={groupId}
                                                                     type='event'
                                                                     totalComments={3}
                                                                     userRole={eventDetails.getIsPrivate() ? userRole : usedString.MEMBER}
                                                                     userRoleStatus={eventDetails.getIsPrivate() ? userGroupStatus : usedString.APPROVED}
                                                                     viewComments={true}
                                                                     sendResponse={this.getCommentStatusFromChild}/>
                                                </div>
                                            )}
                                            {!hideTicket && eventDetails.getEnds() > new Date().getTime() && ((eType === usedString.Open || eType === usedString.Postponed) && eventTickets && eventTickets.length > 0) ? (
                                                <div
                                                    className={isMobileLess ? classes.stickyNotesMobileWrapper : classes.stickyNotesWrapper}>
                                                    <div className={classes.stickyNotes}>
                                                        <div>
                                                            <div className={classes.floatLeft}/>
                                                            <IconButton aria-label="close" className={classes.closeIcon}
                                                                        onClick={() => this.closeButton(eventDetails.getEventId())}>
                                                                <CloseIcon/>
                                                            </IconButton>
                                                        </div>
                                                        <div className={classes.clearBoth}>
                                                            <div className={classes.textCenter}>
                                                                <Button size="small"
                                                                        variant="outlined"
                                                                        onClick={() => this.scrollToMyRef()}
                                                                        className={classes.ticketButton}>
                                                                    <StyleIcon
                                                                        style={{color: '#ffe100'}}/> &nbsp;
                                                                    Get Tickets Now
                                                                </Button>
                                                            </div>
                                                            <Typography component="div"
                                                                        className={classes.stickyDesc}>
                                                                Choose, Continue and get your tickets
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : <div>
                                        {errSts === 7 ? (
                                            <div className={classes.eventsCard}>
                                                <br/>
                                                <Typography component="div" className={classes.noMemberWrapper}
                                                >This is a private event hosted by {title}. If you want to know more
                                                    about
                                                    this community please visit the community <Link
                                                        to={`${rootGroupsURl}`}
                                                        style={{textDecoration: 'underline'}}>here</Link>.
                                                </Typography>
                                            </div>) : null}
                                    </div>}
                                </div>) : null}
                        </div>) : (<div><GroupDetailLoader/></div>)}
                </div>
                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
                {/*Snack BAr Show*/}
                <div>{openUserGoing ? (
                    <EventsUsers open={openUserGoing} groupID={groupId} eventID={eventId} goingCount={goingCount}
                                 eventText={userEventText}
                                 interestedCount={interestedCount} sendCloseReport={this.getResFrmChild}/>) : (
                    <div></div>)}
                </div>
                <div>
                    {openLoginDialog ? (
                        <LoginRequiredDialog
                            openDialog={openLoginDialog} dialogHeader=""
                            postId="124"
                            dialogContent={"You are not logged in. Please login to continue and  start using more feature of the community."}
                            button1="cancel"
                            button2="login"
                            sendUserResponse={this.getLoginDialogRes}
                        />
                    ) : (<div></div>)}
                </div>
                {changeEventType ? (
                    <UserConfirmationDialog
                        openDialog={changeEventType}
                        dialogHeader="Change Event Type"
                        postId={''}
                        dialogContent={"Are you sure want to change '" + eventDetails.getTitle() + "' type to " + tempType + "?"}
                        button1="No" button2="Yes"
                        sendUserResponse={this.getETypeChangeResponse}
                    />
                ) : (<div></div>)}

                {openNotificationDialog ? (
                    <NotificationConfirmation
                        openDialog={openNotificationDialog}
                        dialogHeader="Notifiy"
                        postId={groupId}
                        eventId={eventId}
                        maxEventSendCount={maxEventNotification}
                        sendUserResponse={this.geNotificationRes}
                    />
                ) : (<div></div>)}
            </DashboardLayout>
        );
    }
}

EventsDetails.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withWidth())(EventsDetails);
