import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {Typography, withStyles, withWidth} from '@material-ui/core';
// Shared layouts
// Component styles
import styles from './styles';
import {checkIfLogin, isAdmin, isLogin, userInfo, userWhoCanPost} from "../../configurations/global-config";
import compose from "recompose/compose";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import InputBase from "@material-ui/core/InputBase";
import {Add as AddIcon, AddPhotoAlternate as AddImage, CloseOutlined as CloseIcon} from "@material-ui/icons";
import Uploader from "../ImageUpload/Uploader";
import {uploadImageToDigitalOcean} from "../ImageUpload/upload";
import {AddPostRequest, Content, Post} from "../../generated/frontend-post-service_pb";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import LoginRequiredDialog from "../Dialogs/LoginRequiredDialog";
import usedString from "../../utils/mapper/enumToStringMapper";
import {createPost} from "../../grpcRequests/posts-request";
import Chip from '@material-ui/core/Chip';
import {removeLastSpace} from "../../utils/converter/common";
import SnackBar from "../SnackBar/SnackBar";

const defaultPageSize =15;

class NewPostBox extends Component {
    state={
        //Images from childUploader
        uploadImage:false,
        imageFile: [],
        uploadingURL: [],
        imageCount: [1],
        nextImageCount: 2,

        createPost: false,
        disable: false,
        creatingPost: false,
        multiline: true,
        channelName: '',

        openLoginDialog: false,
        openJoinGroupDialog: false,
        showFields: false,

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null,
    };

    changeUploadSts = () => {
        if (!isLogin()) {
            this.openLoginDialog()
        }
        else if(!this.canInteractInGroup()){
            this.openJoinDialog()
        }
        else {
            let currentUploadSts = this.state.uploadImage;
            if(!currentUploadSts)
                this.setState({uploadImage: true,showFields: true});
        }
    };

    postTextChange = (event) => {
        this.setState({postingText: event.target.value});
    };

    handleCatClick = (channelName) => {
        if(channelName !== '_admin' || (isAdmin() || this.props.userRole===usedString.ADMIN))
            this.setState({
                channelName:channelName
            })
    };

    handleKeyPress = (event) => {
        if (!isLogin()) {
            this.openLoginDialog()
        }
        else if(!this.canInteractInGroup()){
            this.openJoinDialog()
        }
    };

    setFieldSts = (status) =>{
        this.setState({showFields:status})
    };

    addImage = () => {
        var joined = this.state.imageCount.concat(this.state.nextImageCount++);
        this.setState({imageCount: joined})
    };

    remove = (index) => {
        var array = [...this.state.imageCount];
        if (index > -1) {
            array.splice(index, 1);
            this.setState({imageCount: array});
        }
    };


    getImageUploadingURL = (url, file, index) => {
        if (url && file) {
            this.setState({
                imageFile: {...this.state.imageFile, [index]: file},
                uploadingURL: {...this.state.uploadingURL, [index]: url}
            })
        }
    };


    uploadToDigital(imageFile) {
        let uploadedImages = [];
        let self = this;
        var length = 0;
        var itemsProcessed = 0;
        for (var key in imageFile) {
            if (imageFile.hasOwnProperty(key)) {
                ++length;
            }
        }
        return new Promise(function (resolve, reject) {
            Object.keys(imageFile).forEach((file, index) => {
                uploadImageToDigitalOcean(self.state.imageFile[index], self.state.uploadingURL[index]).then(function (response) {
                    uploadedImages.push(response.image);
                    itemsProcessed++;
                    if (itemsProcessed === length) {
                        resolve(uploadedImages)
                    }
                }).catch(function (error) {

                })
            });
        });
    }

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    };

    createPost = () => {
        if (!isLogin()) {
            this.openLoginDialog()
        }
        else if(!this.canInteractInGroup()){
            this.openJoinDialog()
        }
        else {
            this.setState({disable: true, creatingPost: true});
            let self = this;
            if (!this.isEmpty(this.state.imageFile) && !this.isEmpty(this.state.uploadingURL) && this.state.imageCount.length >= 1) {
                this.uploadToDigital(this.state.imageFile).then(function (response) {
                    self.postNewPost(response);
                })
            } else this.postNewPost('');
        }
    };

    getLoginDialogRes = (clickedButton, key) => {
        this.setState({openLoginDialog: false});
        if (clickedButton === "login") {
            checkIfLogin(window.location.pathname);
        }
    };

    canInteractInGroup= () =>{
        let groupRoleStatus = this.props.userRoleStatus;
        return userWhoCanPost(this.props.userRole, groupRoleStatus);
    };

    getJoinGroupDialogRes = (clickedButton, key) => {
        this.setState({openJoinGroupDialog: false});
        if (clickedButton === "view community") {
            // console.log('clicked join');
            window.location.pathname = '/c/' + this.props.groupId
        }
    };

    openLoginDialog = () => {
        this.setState({openLoginDialog: true});
    };

    openJoinDialog = () => {
        this.setState({openJoinGroupDialog: true});
    };

    sendResponse = (type,status, channelName, message) => {
        this.props.sendCreatedToChannelResponse(type,status, channelName, message);
    };

    postNewPost = (postingImage) => {
        this.setState({snackOpen: false});
        let self = this;
        if (this.state.postingText !== '') {
            var post = this.state.postingText;
            var hashTags = "";
            const req = new AddPostRequest();
            const postRew = new Post();
            const content = new Content();

            const metadata = content.getMetadataMap();
            metadata.set("HASH_TAGS", hashTags);

            const contentData = content.getDataMap();
            if (postingImage !== '' && postingImage) {
                postingImage.forEach(function (singleImage, index) {
                    contentData.set("IMAGE" + '_URL_' + new Date().getTime() + index + 1, singleImage);
                });
                contentData.set("TEXT", post);
            } else contentData.set("TEXT", post);
            let toPostToChannel= this.state.channelName;
            postRew.setGroupId(this.props.groupId);
            postRew.setCategory(toPostToChannel);

            if (postingImage !== '' && postingImage)
                postRew.setPostType(Post.PostType.IMAGE_TEXT);
            else postRew.setPostType(Post.PostType.TEXT);
            postRew.setContent(content);
            req.setPost(postRew);
            req.setBusinessId(this.state.groupId);
            createPost(req).then(function (response) {
                if (response.getAdded()) {
                    const postingText = '';
                    const postingImage = '';
                    const imageFile = '';
                    const uploadingURL = '';
                    const disable = false;
                    const multiline = true;
                    const creatingPost = false;
                    const uploadImage = false;
                    const channelName = '';
                    const imageCount = [1];
                    self.setState({
                        creatingPost, channelName, imageCount, uploadImage,
                        postingText, postingImage, imageFile, uploadingURL, disable, multiline
                    });
                    self.sendResponse('created', true, toPostToChannel, '');
                } else throw ('Err');
            }).catch(function (error) {
                self.setState({
                    disable: false, creatingPost: false, multiline: true
                });
                self.snackbar(true, 'Something Went Wrong', 'error', 5000);
            })
        }
    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };


    render() {
        const {classes, categoriesList, userRole, groupId, groupName, userRoleStatus} = this.props;
        const {imageCount, multiline, postingText, creatingPost, disable, openJoinGroupDialog, openLoginDialog, uploadImage, channelName, showFields} = this.state;
        return (
            <div>
                {isLogin() && (categoriesList.length > 1 && (userRoleStatus === usedString.APPROVED && userRole !== usedString.DEFAULT) || isAdmin() || (userRoleStatus === usedString.APPROVED && userRole === usedString.ADMIN)) ? (
                    <div className={classes.postBoxRoot}>
                        <div className={classes.postBoxWrapper}>
                            <Typography className={classes.createNewPostWrapper} variant="body2">
                                <div><b>CREATE NEW POST</b></div>
                            </Typography>
                            <Paper className={classes.searchBoxBroad}>
                                <IconButton className={classes.userProfileButton} aria-label="Menu">
                                    <Avatar alt={userInfo().displayName}
                                            style={{
                                                backgroundImage: `url('${userInfo().photo_url}')`,
                                                backgroundRepeat: 'round',
                                                backgroundColor: '#e5e5e5'
                                            }}
                                            className={classes.chatBoxAvatar}/>
                                </IconButton>
                                <InputBase
                                    className={classes.postContent}
                                    placeholder="Your post to the channel"
                                    multiline={multiline}
                                    rows={showFields ? 4 : 3}
                                    rowsMin={5}
                                    inputProps={{'aria-label': 'Enter to post content'}}
                                    value={postingText}
                                    onKeyPress={this.handleKeyPress.bind(this)}
                                    onFocus={() => this.setFieldSts(true)}
                                    // onBlur={()=>this.setFieldSts(false)}
                                    onChange={this.postTextChange}
                                />
                                <div className={classes.postBoxIconWrapper}>
                                    <IconButton className={classes.floatRight} aria-label="Image"
                                                title="Post with images"
                                                size="small"
                                                color={uploadImage ? 'secondary' : ''}
                                                onClick={this.changeUploadSts}>
                                        <AddImage/>
                                    </IconButton>
                                </div>
                            </Paper>
                            <div className={classes.clearBoth}></div>
                            {showFields || postingText || (uploadImage && imageCount) ? (
                                <div>
                                    <br/>
                                    <div>
                                        {uploadImage ? (
                                            <div>
                                                <Typography className={classes.userAction}>
                                                    <div><b>Upload Image</b></div>
                                                </Typography>
                                                <div style={{display: 'flex', clear: 'both', overflow: 'auto'}}>
                                                    {imageCount.map((group, index) => (
                                                        <div className={classes.clearBoth} style={{margin: '0px 5px'}}>
                                                            {index === imageCount.length - 1 ? (
                                                                <IconButton className={classes.floatRight}
                                                                            aria-label="Send"
                                                                            title="remove image"
                                                                            size="small"
                                                                            color={"primary"}
                                                                            style={{
                                                                                marginBottom: '-19px',
                                                                                zIndex: 1,
                                                                                background: '#b71c1c',
                                                                                color: '#ffffff',
                                                                                padding: '0px'
                                                                            }}
                                                                            onClick={() => this.remove(index)}>
                                                                    <CloseIcon/>
                                                                </IconButton>
                                                            ) : (<span></span>)}
                                                            <Uploader type={"community"} paddingTop={'70%'} width={'150px'}
                                                                      index={index}
                                                                      sendImageURLObtainStatus={this.getImageUploadingURL}/>
                                                        </div>
                                                    ))}
                                                    <div>
                                                        <div style={{
                                                            width: '110px',
                                                            height: '100px',
                                                            cursor: 'pointer',
                                                            border: '2px dotted #e5e5e5',
                                                            textAlign: 'center',
                                                            margin: '11px'
                                                        }} onClick={this.addImage}>
                                                            <div style={{lineHeight: '6'}}>
                                                                <IconButton aria-label="Send"
                                                                            title="Add image"
                                                                            size="small"
                                                                            color={"primary"}>
                                                                    <AddIcon/>
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ):null}
                                        <div>
                                            <Typography className={classes.channelWrapper}>
                                                <div><b>Choose Category</b></div>
                                            </Typography>
                                            <div>
                                                {categoriesList.map(category =>(
                                                    isAdmin() || userRole === usedString.ADMIN || ((userRole === usedString.DEFAULT || userRole === usedString.MEMBER || userRole === usedString.PARTICIPANT)  && (category.getCategoryName() !== '_admin') && !category.getCategoryName().includes('post@')) ? (
                                                        <Chip size="small" avatar={<Avatar src={category.getCategoryImage() ? category.getCategoryImage() :'/'}>{category.getCategoryImage() ? category.getCategoryImage() : category.getCategoryName().charAt(0)==='_' ? category.getCategoryName().charAt(1).toUpperCase() : category.getCategoryName().charAt(0).toUpperCase() }</Avatar>} label={category.getCategoryName() === '_admin' ? 'Admin' : removeLastSpace(category.getCategoryName())}
                                                              onClick={()=>this.handleCatClick(removeLastSpace(category.getCategoryName()))} className={classes.channelChip}
                                                              style={removeLastSpace(category.getCategoryName()) === channelName ? {
                                                                  background: '#0077e0',
                                                                  fontWeight: 600,
                                                                  fontSize: '14px',
                                                                  color: '#ffffff'
                                                              } : {
                                                                  background: '#e5eaea',
                                                                  fontSize: '14px',
                                                                  color: '#525252'
                                                              }}
                                                              color={removeLastSpace(category.getCategoryName())===channelName ? "secondary" : ""} variant={channelName==='first' ? null : "outlined"}/>
                                                    ):null))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.buttonWrapper}>
                                        <Button
                                            disabled={creatingPost || !postingText || disable || postingText.trim().length === 0 || !channelName}
                                            onClick={() => this.createPost()}
                                            variant="contained" color="secondary" className={classes.postButton}>
                                            {creatingPost ? (<span>Posting&nbsp; &nbsp;<CircularProgress
                                                size={12}/> </span>) : 'Post'}
                                        </Button>
                                    </div>
                                </div>
                            ):null}
                            <div>
                                {creatingPost ? (
                                    <div><LinearProgress/></div>) : null}
                            </div>
                            <div>
                                {openLoginDialog ? (
                                    <LoginRequiredDialog
                                        openDialog={openLoginDialog} dialogHeader=""
                                        postId="124"
                                        dialogContent={"You are not logged in. Please login to continue and start posting to the community."}
                                        button1="cancel"
                                        button2="login"
                                        sendUserResponse={this.getLoginDialogRes}
                                    />
                                ) : null}
                            </div>
                            <div>
                                {openJoinGroupDialog ? (
                                    <LoginRequiredDialog
                                        openDialog={openJoinGroupDialog} dialogHeader=""
                                        postId="124"
                                        dialogContent={"You are not associated with '" + groupName + "'. Please join the community to continue and start posting to the community."}
                                        button1="cancel"
                                        button2="view community"
                                        sendUserResponse={this.getJoinGroupDialogRes}
                                    />
                                ) : (<div></div>)}
                            </div>
                            {/*Snack BAr Show*/}
                            <div>{this.state.snackOpen ? (
                                <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                                          snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                            ) : (<span></span>)}
                            </div>
                        </div>
                    </div>
                ):null}
            </div>
        );
    }
}

NewPostBox.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles),
    withWidth()
)(NewPostBox);
