export default theme => ({
    root: {
        padding: theme.spacing(2),
        minHeight: '85vh'
    },
    simpleUnderline: {
        paddingTop: '5px',
        clear: 'both',
        borderBottom: '1px solid #e5e5e5'
    },
    floatRight: {
        float: 'right'
    },
    floatLeft: {
        float: 'left'
    },
    buttonView: {
        fontSize: '16px',
        boxShadow: 'none',
        color: '#000000',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    name: {
        fontSize: '17px',
        fontWeight: 600,
        color: theme.palette.text.primary,
        clear: 'both',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    count: {
        fontSize: '11px',
        color: '#5a5858',
        clear: 'both',
        padding: '2px 0'
    },
    groupName: {
        maxHeight: '77px',
        overflow: 'hidden'
    },
    singleCardEvent: {
        boxShadow: 'none',
        minWidth: '210px',
        height: '100%',
    },
    mediaEvent: {
        height: 0,
        paddingTop: '56.25%',
        borderRadius: '5px'
    },
    upComingEventsWrapper: {
        padding: '10px 5px',
    },
    singleCard: {
        boxShadow: 'none',
        minWidth: '170px',
        height: '100%',
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
        borderRadius: '10px'
    },
    nameWrapper: {
        padding: '10px 5px',
    },
    buyTicketsInfo: {
        margin: '10px 0px 0px',
        float: 'right',
        background: '#0a62c5',
        padding: '2px 10px',
        borderRadius: '5px 0px 0px 5px',
        fontSize: '12px',
        width: 'fit-content',
        color: '#ffffff'
    },
    eventStsInfo: {
        margin: '10px 0px 0px',
        float: 'right',
        background: '#f67718',
        padding: '2px 10px',
        borderRadius: '5px 0px 0px 5px',
        fontSize: '12px',
        width: 'fit-content',
        color: '#ffffff'
    }
});
