import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles, withWidth} from "@material-ui/core";
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import palette from "../theme/palette";
import compose from "recompose/compose";

class PleaseWait extends Component {
    state = {};

    render() {
        const {msg,stopLoad,width} = this.props;
        let isMobile = ['xs', 'sm', 'md'].includes(width);
        return (
            <div>
                <div>
                    <div style={isMobile ? {
                        background: palette.background.default,
                        color:palette.text.secondary,
                        height: '100%', position: 'absolute',width: '100%'
                    }: {
                        background: palette.background.default,
                        color:palette.text.secondary,
                        height: '100%', width: '40%', position: 'absolute'}}>
                        <div style={{top:'50%', width: '100%', position: 'absolute', alignItems: 'center'}}>
                            {!stopLoad ? (<div style={{textAlign: 'center'}}><CircularProgress size={20}/></div>):null}
                            <div style={{fontSize: '18px',textAlign: 'center'}}>{msg}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PleaseWait.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles),
    withWidth()
)(PleaseWait);