/**
 * @fileoverview gRPC-Web generated client stub for com.hamropatro.sociallayer.io
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');


var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js')
const proto = {};
proto.com = {};
proto.com.hamropatro = {};
proto.com.hamropatro.sociallayer = {};
proto.com.hamropatro.sociallayer.io = require('./frontend-admin-notification-push-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.AdminManualPushServiceClient =
    function (hostname, credentials, options) {
      if (!options) options = {};
      options['format'] = 'text';

      /**
       * @private @const {!grpc.web.GrpcWebClientBase} The client
       */
      this.client_ = new grpc.web.GrpcWebClientBase(options);

      /**
       * @private @const {string} The hostname
       */
      this.hostname_ = hostname;

    };


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.AdminManualPushServicePromiseClient =
    function (hostname, credentials, options) {
      if (!options) options = {};
      options['format'] = 'text';

      /**
       * @private @const {!grpc.web.GrpcWebClientBase} The client
       */
      this.client_ = new grpc.web.GrpcWebClientBase(options);

      /**
       * @private @const {string} The hostname
       */
      this.hostname_ = hostname;

    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.EventNotificationRequest,
 *   !proto.com.hamropatro.sociallayer.io.EventNotificationResponse>}
 */
const methodDescriptor_AdminManualPushService_SendEventNotification = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.AdminManualPushService/SendEventNotification',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.EventNotificationRequest,
    proto.com.hamropatro.sociallayer.io.EventNotificationResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.EventNotificationRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.EventNotificationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.EventNotificationRequest,
 *   !proto.com.hamropatro.sociallayer.io.EventNotificationResponse>}
 */
const methodInfo_AdminManualPushService_SendEventNotification = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.EventNotificationResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.EventNotificationRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.EventNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.EventNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.EventNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.EventNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.AdminManualPushServiceClient.prototype.sendEventNotification =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.AdminManualPushService/SendEventNotification',
          request,
          metadata || {},
          methodDescriptor_AdminManualPushService_SendEventNotification,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.EventNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.EventNotificationResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.AdminManualPushServicePromiseClient.prototype.sendEventNotification =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.AdminManualPushService/SendEventNotification',
          request,
          metadata || {},
          methodDescriptor_AdminManualPushService_SendEventNotification);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.PushLogRequest,
 *   !proto.com.hamropatro.sociallayer.io.PushLogResponse>}
 */
const methodDescriptor_AdminManualPushService_GetPushLog = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.AdminManualPushService/GetPushLog',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.PushLogRequest,
    proto.com.hamropatro.sociallayer.io.PushLogResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.PushLogRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.PushLogResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.PushLogRequest,
 *   !proto.com.hamropatro.sociallayer.io.PushLogResponse>}
 */
const methodInfo_AdminManualPushService_GetPushLog = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.PushLogResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.PushLogRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.PushLogResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.PushLogRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.PushLogResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.PushLogResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.AdminManualPushServiceClient.prototype.getPushLog =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.AdminManualPushService/GetPushLog',
          request,
          metadata || {},
          methodDescriptor_AdminManualPushService_GetPushLog,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.PushLogRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.PushLogResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.AdminManualPushServicePromiseClient.prototype.getPushLog =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.AdminManualPushService/GetPushLog',
          request,
          metadata || {},
          methodDescriptor_AdminManualPushService_GetPushLog);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.PushLogForContentRequest,
 *   !proto.com.hamropatro.sociallayer.io.PushLogResponse>}
 */
const methodDescriptor_AdminManualPushService_GetPushLogForContent = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.AdminManualPushService/GetPushLogForContent',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.PushLogForContentRequest,
    proto.com.hamropatro.sociallayer.io.PushLogResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.PushLogForContentRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.PushLogResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.PushLogForContentRequest,
 *   !proto.com.hamropatro.sociallayer.io.PushLogResponse>}
 */
const methodInfo_AdminManualPushService_GetPushLogForContent = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.PushLogResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.PushLogForContentRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.PushLogResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.PushLogForContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.PushLogResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.PushLogResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.AdminManualPushServiceClient.prototype.getPushLogForContent =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.AdminManualPushService/GetPushLogForContent',
          request,
          metadata || {},
          methodDescriptor_AdminManualPushService_GetPushLogForContent,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.PushLogForContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.PushLogResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.AdminManualPushServicePromiseClient.prototype.getPushLogForContent =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.AdminManualPushService/GetPushLogForContent',
          request,
          metadata || {},
          methodDescriptor_AdminManualPushService_GetPushLogForContent);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.PushCountRequest,
 *   !proto.com.hamropatro.sociallayer.io.PushCountResponse>}
 */
const methodDescriptor_AdminManualPushService_GetPushCount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.AdminManualPushService/GetPushCount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.PushCountRequest,
    proto.com.hamropatro.sociallayer.io.PushCountResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.PushCountRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.PushCountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.PushCountRequest,
 *   !proto.com.hamropatro.sociallayer.io.PushCountResponse>}
 */
const methodInfo_AdminManualPushService_GetPushCount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.PushCountResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.PushCountRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.PushCountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.PushCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.PushCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.PushCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.AdminManualPushServiceClient.prototype.getPushCount =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.AdminManualPushService/GetPushCount',
          request,
          metadata || {},
          methodDescriptor_AdminManualPushService_GetPushCount,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.PushCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.PushCountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.AdminManualPushServicePromiseClient.prototype.getPushCount =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.AdminManualPushService/GetPushCount',
          request,
          metadata || {},
          methodDescriptor_AdminManualPushService_GetPushCount);
    };


module.exports = proto.com.hamropatro.sociallayer.io;

