/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.PayeeInfo', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.TicketModel', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.TicketPaymentModel', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.TicketPricing', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.TicketStatus', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.TransactionDetail', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UserTicket', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.TicketModel = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.TicketModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.TicketModel.displayName = 'proto.com.hamropatro.sociallayer.io.TicketModel';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.TicketModel.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.TicketModel.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.TicketModel} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.TicketModel.toObject = function (includeInstance, msg) {
    var f, obj = {
      appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      ticketSourceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      ticketTypeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
      title: jspb.Message.getFieldWithDefault(msg, 4, ""),
      description: jspb.Message.getFieldWithDefault(msg, 5, ""),
      issuedTimestamp: jspb.Message.getFieldWithDefault(msg, 6, 0),
      expiration: jspb.Message.getFieldWithDefault(msg, 7, 0),
      pricingDetail: (f = msg.getPricingDetail()) && proto.com.hamropatro.sociallayer.io.TicketPricing.toObject(includeInstance, f),
      ticketType: jspb.Message.getFieldWithDefault(msg, 9, ""),
      maxAvailableLimit: jspb.Message.getFieldWithDefault(msg, 10, 0),
      ownerBusinessId: jspb.Message.getFieldWithDefault(msg, 11, ""),
      metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
      soldOut: jspb.Message.getFieldWithDefault(msg, 13, false),
      available: jspb.Message.getFieldWithDefault(msg, 14, 0),
      sold: jspb.Message.getFieldWithDefault(msg, 15, 0),
      maxPurchaseCount: jspb.Message.getFieldWithDefault(msg, 16, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketModel}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.TicketModel;
  return proto.com.hamropatro.sociallayer.io.TicketModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketModel}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAppId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketSourceId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketTypeId(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setTitle(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setIssuedTimestamp(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setExpiration(value);
        break;
      case 8:
        var value = new proto.com.hamropatro.sociallayer.io.TicketPricing;
        reader.readMessage(value, proto.com.hamropatro.sociallayer.io.TicketPricing.deserializeBinaryFromReader);
        msg.setPricingDetail(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketType(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setMaxAvailableLimit(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setOwnerBusinessId(value);
        break;
      case 12:
        var value = msg.getMetadataMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
        });
        break;
      case 13:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSoldOut(value);
        break;
      case 14:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setAvailable(value);
        break;
      case 15:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setSold(value);
        break;
      case 16:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setMaxPurchaseCount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.TicketModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.TicketModel.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getTicketSourceId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getTicketTypeId();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
        5,
        f
    );
  }
  f = message.getIssuedTimestamp();
  if (f !== 0) {
    writer.writeInt64(
        6,
        f
    );
  }
  f = message.getExpiration();
  if (f !== 0) {
    writer.writeInt64(
        7,
        f
    );
  }
  f = message.getPricingDetail();
  if (f != null) {
    writer.writeMessage(
        8,
        f,
        proto.com.hamropatro.sociallayer.io.TicketPricing.serializeBinaryToWriter
    );
  }
  f = message.getTicketType();
  if (f.length > 0) {
    writer.writeString(
        9,
        f
    );
  }
  f = message.getMaxAvailableLimit();
  if (f !== 0) {
    writer.writeInt64(
        10,
        f
    );
  }
  f = message.getOwnerBusinessId();
  if (f.length > 0) {
    writer.writeString(
        11,
        f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(12, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSoldOut();
  if (f) {
    writer.writeBool(
        13,
        f
    );
  }
  f = message.getAvailable();
  if (f !== 0) {
    writer.writeInt64(
        14,
        f
    );
  }
  f = message.getSold();
  if (f !== 0) {
    writer.writeInt64(
        15,
        f
    );
  }
  f = message.getMaxPurchaseCount();
  if (f !== 0) {
    writer.writeInt64(
        16,
        f
    );
  }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getAppId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setAppId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ticket_source_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getTicketSourceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setTicketSourceId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ticket_type_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getTicketTypeId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setTicketTypeId = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getTitle = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setTitle = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getDescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setDescription = function (value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 issued_timestamp = 6;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getIssuedTimestamp = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setIssuedTimestamp = function (value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 expiration = 7;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getExpiration = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setExpiration = function (value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional TicketPricing pricing_detail = 8;
 * @return {?proto.com.hamropatro.sociallayer.io.TicketPricing}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getPricingDetail = function () {
  return /** @type{?proto.com.hamropatro.sociallayer.io.TicketPricing} */ (
      jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.TicketPricing, 8));
};


/** @param {?proto.com.hamropatro.sociallayer.io.TicketPricing|undefined} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setPricingDetail = function (value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.com.hamropatro.sociallayer.io.TicketModel.prototype.clearPricingDetail = function () {
  this.setPricingDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.hasPricingDetail = function () {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string ticket_type = 9;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getTicketType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setTicketType = function (value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 max_available_limit = 10;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getMaxAvailableLimit = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setMaxAvailableLimit = function (value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string owner_business_id = 11;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getOwnerBusinessId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setOwnerBusinessId = function (value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * map<string, string> metadata = 12;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getMetadataMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 12, opt_noLazyCreate,
          null));
};


proto.com.hamropatro.sociallayer.io.TicketModel.prototype.clearMetadataMap = function () {
  this.getMetadataMap().clear();
};


/**
 * optional bool sold_out = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getSoldOut = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setSoldOut = function (value) {
  jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int64 available = 14;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getAvailable = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setAvailable = function (value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 sold = 15;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getSold = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setSold = function (value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 max_purchase_count = 16;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.getMaxPurchaseCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketModel.prototype.setMaxPurchaseCount = function (value) {
  jspb.Message.setProto3IntField(this, 16, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.TicketPricing = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.TicketPricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.TicketPricing.displayName = 'proto.com.hamropatro.sociallayer.io.TicketPricing';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.TicketPricing.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.TicketPricing.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.TicketPricing} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.TicketPricing.toObject = function (includeInstance, msg) {
    var f, obj = {
      currency: jspb.Message.getFieldWithDefault(msg, 1, ""),
      price: +jspb.Message.getFieldWithDefault(msg, 3, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketPricing}
 */
proto.com.hamropatro.sociallayer.io.TicketPricing.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.TicketPricing;
  return proto.com.hamropatro.sociallayer.io.TicketPricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketPricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketPricing}
 */
proto.com.hamropatro.sociallayer.io.TicketPricing.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCurrency(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setPrice(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.TicketPricing.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.TicketPricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketPricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.TicketPricing.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
        3,
        f
    );
  }
};


/**
 * optional string currency = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketPricing.prototype.getCurrency = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketPricing.prototype.setCurrency = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double price = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketPricing.prototype.getPrice = function () {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketPricing.prototype.setPrice = function (value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UserTicket = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UserTicket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.UserTicket.displayName = 'proto.com.hamropatro.sociallayer.io.UserTicket';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UserTicket.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UserTicket.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UserTicket} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UserTicket.toObject = function (includeInstance, msg) {
    var f, obj = {
      userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      ticketTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      ticketId: jspb.Message.getFieldWithDefault(msg, 3, ""),
      purchasedTimestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
      ticketName: jspb.Message.getFieldWithDefault(msg, 5, ""),
      groupId: jspb.Message.getFieldWithDefault(msg, 6, ""),
      eventId: jspb.Message.getFieldWithDefault(msg, 7, ""),
      amount: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
      status: jspb.Message.getFieldWithDefault(msg, 9, 0),
      currency: jspb.Message.getFieldWithDefault(msg, 10, ""),
      eventMetadataMap: (f = msg.getEventMetadataMap()) ? f.toObject(includeInstance, undefined) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicket}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UserTicket;
  return proto.com.hamropatro.sociallayer.io.UserTicket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicket}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketTypeId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketId(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setPurchasedTimestamp(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketName(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventId(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setAmount(value);
        break;
      case 9:
        var value = /** @type {!proto.com.hamropatro.sociallayer.io.TicketStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setCurrency(value);
        break;
      case 11:
        var value = msg.getEventMetadataMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UserTicket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UserTicket.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getTicketTypeId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getTicketId();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getPurchasedTimestamp();
  if (f !== 0) {
    writer.writeInt64(
        4,
        f
    );
  }
  f = message.getTicketName();
  if (f.length > 0) {
    writer.writeString(
        5,
        f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        6,
        f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
        7,
        f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
        8,
        f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
        9,
        f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
        10,
        f
    );
  }
  f = message.getEventMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(11, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.getUserId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.setUserId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ticket_type_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.getTicketTypeId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.setTicketTypeId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ticket_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.getTicketId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.setTicketId = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 purchased_timestamp = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.getPurchasedTimestamp = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.setPurchasedTimestamp = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string ticket_name = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.getTicketName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.setTicketName = function (value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string group_id = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string event_id = 7;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.getEventId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.setEventId = function (value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double amount = 8;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.getAmount = function () {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.setAmount = function (value) {
  jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional TicketStatus status = 9;
 * @return {!proto.com.hamropatro.sociallayer.io.TicketStatus}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.getStatus = function () {
  return /** @type {!proto.com.hamropatro.sociallayer.io.TicketStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.TicketStatus} value */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.setStatus = function (value) {
  jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string currency = 10;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.getCurrency = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.setCurrency = function (value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * map<string, string> event_metadata = 11;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.UserTicket.prototype.getEventMetadataMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 11, opt_noLazyCreate,
          null));
};


proto.com.hamropatro.sociallayer.io.UserTicket.prototype.clearEventMetadataMap = function () {
  this.getEventMetadataMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.TicketPaymentModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.TicketPaymentModel.displayName = 'proto.com.hamropatro.sociallayer.io.TicketPaymentModel';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.TicketPaymentModel.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.TicketPaymentModel} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.TicketPaymentModel.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketSourceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      ticketTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      ticketid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      userId: jspb.Message.getFieldWithDefault(msg, 4, ""),
      paymentTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
      amount: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
      buyerInfo: (f = msg.getBuyerInfo()) && proto.com.hamropatro.sociallayer.io.PayeeInfo.toObject(includeInstance, f),
      paymentCurrency: jspb.Message.getFieldWithDefault(msg, 8, ""),
      ticketName: jspb.Message.getFieldWithDefault(msg, 9, ""),
      status: jspb.Message.getFieldWithDefault(msg, 10, ""),
      metaDataMap: (f = msg.getMetaDataMap()) ? f.toObject(includeInstance, undefined) : [],
      groupId: jspb.Message.getFieldWithDefault(msg, 12, ""),
      checkedInBy: (f = msg.getCheckedInBy()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketPaymentModel}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.TicketPaymentModel;
  return proto.com.hamropatro.sociallayer.io.TicketPaymentModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketPaymentModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketPaymentModel}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketSourceId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketTypeId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserId(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setPaymentTime(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setAmount(value);
        break;
      case 7:
        var value = new proto.com.hamropatro.sociallayer.io.PayeeInfo;
        reader.readMessage(value, proto.com.hamropatro.sociallayer.io.PayeeInfo.deserializeBinaryFromReader);
        msg.setBuyerInfo(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setPaymentCurrency(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketName(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setStatus(value);
        break;
      case 11:
        var value = msg.getMetaDataMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
        });
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 13:
        var value = new frontend$social$common$types_pb.AccountInfo;
        reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
        msg.setCheckedInBy(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.TicketPaymentModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketPaymentModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketSourceId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getTicketTypeId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getTicketid();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getPaymentTime();
  if (f !== 0) {
    writer.writeInt64(
        5,
        f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
        6,
        f
    );
  }
  f = message.getBuyerInfo();
  if (f != null) {
    writer.writeMessage(
        7,
        f,
        proto.com.hamropatro.sociallayer.io.PayeeInfo.serializeBinaryToWriter
    );
  }
  f = message.getPaymentCurrency();
  if (f.length > 0) {
    writer.writeString(
        8,
        f
    );
  }
  f = message.getTicketName();
  if (f.length > 0) {
    writer.writeString(
        9,
        f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
        10,
        f
    );
  }
  f = message.getMetaDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(11, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        12,
        f
    );
  }
  f = message.getCheckedInBy();
  if (f != null) {
    writer.writeMessage(
        13,
        f,
        frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ticket_source_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.getTicketSourceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.setTicketSourceId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ticket_type_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.getTicketTypeId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.setTicketTypeId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ticketId = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.getTicketid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.setTicketid = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.getUserId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.setUserId = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 payment_time = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.getPaymentTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.setPaymentTime = function (value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double amount = 6;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.getAmount = function () {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.setAmount = function (value) {
  jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional PayeeInfo buyer_info = 7;
 * @return {?proto.com.hamropatro.sociallayer.io.PayeeInfo}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.getBuyerInfo = function () {
  return /** @type{?proto.com.hamropatro.sociallayer.io.PayeeInfo} */ (
      jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.PayeeInfo, 7));
};


/** @param {?proto.com.hamropatro.sociallayer.io.PayeeInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.setBuyerInfo = function (value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.clearBuyerInfo = function () {
  this.setBuyerInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.hasBuyerInfo = function () {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string payment_currency = 8;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.getPaymentCurrency = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.setPaymentCurrency = function (value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string ticket_name = 9;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.getTicketName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.setTicketName = function (value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string status = 10;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.getStatus = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.setStatus = function (value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * map<string, string> meta_data = 11;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.getMetaDataMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 11, opt_noLazyCreate,
          null));
};


proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.clearMetaDataMap = function () {
  this.getMetaDataMap().clear();
};


/**
 * optional string group_id = 12;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional AccountInfo checked_in_by = 13;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.getCheckedInBy = function () {
  return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
      jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 13));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.setCheckedInBy = function (value) {
  jspb.Message.setWrapperField(this, 13, value);
};


proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.clearCheckedInBy = function () {
  this.setCheckedInBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.TicketPaymentModel.prototype.hasCheckedInBy = function () {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.TransactionDetail.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.TransactionDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.TransactionDetail.displayName = 'proto.com.hamropatro.sociallayer.io.TransactionDetail';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.TransactionDetail.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.TransactionDetail} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.TransactionDetail.toObject = function (includeInstance, msg) {
    var f, obj = {
      appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      transactionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      transactionTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
      ticketsourceId: jspb.Message.getFieldWithDefault(msg, 4, ""),
      quantity: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
      tickettypeId: jspb.Message.getFieldWithDefault(msg, 6, ""),
      totalCharge: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
      userId: jspb.Message.getFieldWithDefault(msg, 8, ""),
      ticketIdList: jspb.Message.getRepeatedField(msg, 9)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.TransactionDetail}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.TransactionDetail;
  return proto.com.hamropatro.sociallayer.io.TransactionDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.TransactionDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.TransactionDetail}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAppId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTransactionId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTransactionTime(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketsourceId(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setQuantity(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setTickettypeId(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setTotalCharge(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserId(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.addTicketId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.TransactionDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.TransactionDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getTransactionTime();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getTicketsourceId();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
        5,
        f
    );
  }
  f = message.getTickettypeId();
  if (f.length > 0) {
    writer.writeString(
        6,
        f
    );
  }
  f = message.getTotalCharge();
  if (f !== 0.0) {
    writer.writeDouble(
        7,
        f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
        8,
        f
    );
  }
  f = message.getTicketIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
        9,
        f
    );
  }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.getAppId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.setAppId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transaction_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.getTransactionId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.setTransactionId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string transaction_time = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.getTransactionTime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.setTransactionTime = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ticketSource_id = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.getTicketsourceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.setTicketsourceId = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double quantity = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.getQuantity = function () {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.setQuantity = function (value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string ticketType_id = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.getTickettypeId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.setTickettypeId = function (value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double total_charge = 7;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.getTotalCharge = function () {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.setTotalCharge = function (value) {
  jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string user_id = 8;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.getUserId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.setUserId = function (value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string ticket_id = 9;
 * @return {!Array<string>}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.getTicketIdList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/** @param {!Array<string>} value */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.setTicketIdList = function (value) {
  jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.addTicketId = function (value, opt_index) {
  jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


proto.com.hamropatro.sociallayer.io.TransactionDetail.prototype.clearTicketIdList = function () {
  this.setTicketIdList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.PayeeInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.PayeeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.PayeeInfo.displayName = 'proto.com.hamropatro.sociallayer.io.PayeeInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.PayeeInfo.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.PayeeInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.PayeeInfo.toObject = function (includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      email: jspb.Message.getFieldWithDefault(msg, 2, ""),
      country: jspb.Message.getFieldWithDefault(msg, 3, ""),
      city: jspb.Message.getFieldWithDefault(msg, 4, ""),
      address: jspb.Message.getFieldWithDefault(msg, 5, ""),
      phone: jspb.Message.getFieldWithDefault(msg, 6, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.PayeeInfo}
 */
proto.com.hamropatro.sociallayer.io.PayeeInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.PayeeInfo;
  return proto.com.hamropatro.sociallayer.io.PayeeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.PayeeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.PayeeInfo}
 */
proto.com.hamropatro.sociallayer.io.PayeeInfo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmail(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountry(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCity(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setAddress(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setPhone(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.PayeeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.PayeeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.PayeeInfo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
        5,
        f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
        6,
        f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.setName = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.getEmail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.setEmail = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.getCountry = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.setCountry = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.getCity = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.setCity = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.getAddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.setAddress = function (value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.getPhone = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PayeeInfo.prototype.setPhone = function (value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.TicketStatus = {
  UNUSED: 0,
  USED: 1,
  INVALID: 2
};

goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
