import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";
import styles from "./styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {Link} from "react-router-dom";
import {timeEvent, timeRemaining} from "../../../utils/converter/dateConverter";
import {isAdmin, userInfo} from "../../../configurations/global-config";
import usedString from "../../../utils/mapper/enumToStringMapper";
import UserCommentMenus from "../../../components/UserCommentMenus";
import UserReply from "../../../components/ReplyBox/UserReply";
import {GetCommentRequest} from "../../../generated/frontend-comment-service_pb";
import {getCommentDetails} from "../../../grpcRequests/comments-request";
import RenderText from "./RenderText";
import SnackBar from "../../../components/SnackBar/SnackBar";

class Comments extends Component {
    state = {
        userComments: this.props.userComments,

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null,
    };

    sendAction = (action, postID) => {
        this.props.sendResponse(action, true, postID);
    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    getUserResponseFromUserComment = (actionName, isActionCompleted, postId, key, indexVal) => {
        this.setState({snackOpen: false});
        let self = this;
        if (isActionCompleted && actionName === 'delete') {
            self.snackbar(true, 'Comment deleted Successfully', 'success', 5000);
            self.sendAction('delete', postId);
            const userComments = this.state.userComments.filter(i => i.getComment().getCommentId() !== key);
            this.setState({userComments});
        }
        else if (isActionCompleted && actionName === 'edited') {

            const userComments = this.state.userComments.filter(i => i.getComment().getCommentId() !== key);
            this.setState({userComments});


            //    now add new item to the list

            const req = new GetCommentRequest();
            req.setPostUri(postId);
            req.setCommentId(key);
            getCommentDetails(req, 3).then(function (response) {
                let currentComment = response.getCommentdetail();
                let userPost=self.state.userComments;
                // userPost.push(currentPosts);
                userPost.splice(indexVal, 0, currentComment);
                self.setState({
                    userComments: userPost
                });
            }).catch(function (error) {

            })

        }
        else {
            this.snackbar(true, 'Something went wrong', 'error', 5000);
        }
    };

    render() {
        const {userComments} = this.state;
        const {classes, isMobile, rootGroupsURl, userRole, postId, postTitle, groupName, groupId, userRoleStatus} = this.props;
        return (
            <div>
                <div>
                    {userComments.map((comments,index) => (
                        <ListItem alignItems="flex-start"
                                  className={classes.commentWrapper}
                                  style={isMobile ? {
                                      display: "inline-block",
                                      paddingTop: '1px',
                                      paddingBottom: '1px'
                                  } : {
                                      display: "flex",
                                      paddingTop: '1px',
                                      paddingBottom: '1px'
                                  }}>
                            <ListItemAvatar>
                                <Avatar
                                    alt={comments.getComment().getAccountInfo().getAccountName()}
                                    className={classes.chatBoxAvatar}
                                    style={
                                        isMobile ?
                                            {
                                                display: 'none'
                                            } : {
                                                backgroundImage: `url('${comments.getComment().getAccountInfo().getAccountImage() ? comments.getComment().getAccountInfo().getAccountImage().getImageUrl() : ''}')`,
                                                backgroundRepeat: 'round',
                                                margin: 'auto',
                                                marginTop: '5px'
                                            }}/>
                            </ListItemAvatar>
                            <ListItemText
                                className={classes.listCoverComment}
                                primary={
                                    <React.Fragment>
                                        <div style={{
                                            display: 'inline-flex',
                                            alignItems: 'center'
                                        }}>
                                            <Avatar
                                                alt={comments.getComment().getAccountInfo().getAccountName()}
                                                style={
                                                    isMobile ?
                                                        {
                                                            backgroundImage: `url('${comments.getComment().getAccountInfo().getAccountImage() ? comments.getComment().getAccountInfo().getAccountImage().getImageUrl() : ''}')`,
                                                            backgroundRepeat: 'round',
                                                            width: '25px',
                                                            height: '25px',
                                                            marginRight: '8px'
                                                        } : {display: 'none'}}/>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                                style={{
                                                    fontWeight: 600,
                                                    lineHeight: 1.2
                                                }}
                                            >
                                                {comments.getComment().getAccountInfo().getAccountName()}
                                                <Link
                                                    to={`${rootGroupsURl}/p/${comments.getComment().getPostUri()}/${comments.getComment().getCommentId()}/?title=${postTitle}`}>
                                                    <div className={classes.timeUUID}>
                                                        <span title={new Date(comments.getComment().getCommentTimestamp()).toLocaleString()}>{timeRemaining(new Date(comments.getComment().getCommentTimestamp()))}</span> <span title={timeEvent(comments.getComment().getCommentTimestamp())} className={classes.postTime}>{comments.getComment().getEdited() ? '(edited)':null}</span>
                                                    </div>
                                                </Link>
                                            </Typography>
                                        </div>
                                        {comments.getComment().getAccountInfo().getAccountId() === userInfo().user_id || isAdmin() || userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT ? (
                                            <UserCommentMenus
                                                commentID={comments.getComment().getCommentId()}
                                                postID={postId}
                                                commentText={comments.getComment().getComment().getText()}
                                                ownerID={comments.getComment().getAccountInfo().getAccountId()}
                                                userRole={userRole}
                                                userRoleStatus={userRoleStatus}
                                                indexValue={index}
                                                sendUserResponse={this.getUserResponseFromUserComment}
                                            />) : (
                                            <span>&nbsp;</span>)}
                                    </React.Fragment>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            component="div"
                                            className={classes.commentPost}
                                            color="textPrimary"
                                            variant={"body2"}
                                            style={{
                                                // fontWeight: 600,
                                                whiteSpace: 'pre-line'
                                            }}
                                        >
                                            <RenderText text={comments.getComment().getComment().getText()}/>
                                        </Typography>
                                        <div>
                                            <UserReply
                                                groupName={groupName}
                                                groupId={groupId}
                                                userRole={userRole}
                                                userRoleStatus={userRoleStatus}
                                                commentId={comments.getComment().getCommentId()}
                                                commentPostId={comments.getComment().getPostUri()}
                                                commentLikes={comments.getComment().getLikes()}
                                                commentReplies={comments.getComment().getReplies()}
                                                userLikeSts={comments.getLiked()}/>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    ))}
                </div>
                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
            </div>
        )
    }
}

Comments.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Comments);

