import React, {Component} from "react";
import PropTypes from "prop-types";
import {Grid, withStyles} from "@material-ui/core";
import styles from "./styles";
import {GetGroupPostByCategoryRequest} from "../../generated/frontend-post-service_pb";
import {getPostsByCategory} from "../../grpcRequests/posts-request";
import {PostLoader} from "../../components/Skeleton/EventsSket";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Dashboard as DashboardLayout} from 'layouts';
import {isAdmin} from "../../configurations/global-config";
import {Link} from "react-router-dom";


const defaultPageSize = 50;
const postedImages = [];

class QuickViewImages extends Component {
    state = {
        isLoadingAdminPost: true,
        adminPosts: [],
        cursor: '',
        previousCursor: '',
        loadingMore: false,
        errorGettingPosts: null,
        groupId: this.props.match.params.groupId,
        catName: this.props.match.params.catName,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if (isAdmin())
            this.fetchByPostCategory('', '', defaultPageSize, [], '');
        else this.setState({
            isLoadingAdminPost: false
        })
    }

    componentWillMount() {
        document.addEventListener("scroll", e => {
            this.handleScroll(e);
        });
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", e => {
            this.handleScroll(e);
        });
    }

    handleScroll = () => {
        if (this.state.loadingMore) return;
        let lastLi = document.querySelector("#postImageItem");
        if (lastLi) {
            let lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
            let pageOffset = window.pageYOffset + window.innerHeight;
            if ((pageOffset > lastLiOffset - 1000) && this.state.cursor && (this.state.previousCursor !== this.state.cursor)) {
                this.loadMore(this.props.match.params.catName, this.state.cursor, 15, this.state.adminPosts, this.state.cursor)
            }
        }
    };

    loadMore = (category, cursor, pageSize, previousPosts, prevCursor) => {
        this.setState({loadingMore: true});
        if (previousPosts && cursor) {
            this.fetchByPostCategory(category, cursor, pageSize, previousPosts, prevCursor)
        }
    };

    fetchByPostCategory = (category, cursor, pageSize, previousPosts, prevCursor) => {
        let self = this;
        if (previousPosts.length < 0) {
            self.setState({
                adminPosts: previousPosts,
                isLoadingAdminPost: true,
            });
        }
        const req = new GetGroupPostByCategoryRequest();
        req.setGroupId(this.props.match.params.groupId);
        req.setCategory(this.props.match.params.catName);
        req.setCursor(cursor);
        req.setPageSize(pageSize);
        getPostsByCategory(req, 4).then(function (response) {
            const cursorNew = response.getNextPageToken();
            let posts = previousPosts;
            for (let i = 0; i < response.getPostList().length; i++) {
                let newPosts = response.getPostList()[i];

                let imageList = [];

                newPosts.getContent().getMetadataMap().forEach(function (value, key) {
                    if (key.includes('RESIZED_IMAGE')) {
                        imageList.push(value);
                        postedImages[newPosts.getContentid()] = imageList;
                    }
                });
                if (postedImages[newPosts.getContentid()])
                    posts.push(newPosts);
            }
            const isLoadingAdminPost = false;
            self.setState({
                cursor: cursorNew,
                previousCursor: prevCursor,
                adminPosts: posts,
                isLoadingAdminPost, loadingMore: false,
            });
        }).catch(function (error) {
            const errorGettingPosts = error.message;
            const isLoadingAdminPost = false;
            self.setState({
                isLoadingAdminPost,
                errorGettingPosts,
                loadingMore: false
            });
        })
    };

    render() {
        const {classes} = this.props;
        const groupId = this.props.match.params.groupId;
        const {adminPosts, cursor, errorGettingPosts, isLoadingAdminPost, loadingMore, previousCursor, catName} = this.state;


        return (
            <DashboardLayout title={'Quick View'} onBack={groupId ? `/c/${groupId}` : ''}
                             linkTo={groupId ? `/c/${groupId}` : ''}>
                <div className={classes.root}>
                    {!isLoadingAdminPost ? (
                        <div id="postImageItem">
                            <div>Showing all images from {catName}.</div>
                            <br/>
                            {adminPosts.length > 0 ? (
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    {adminPosts
                                        .map(post => (
                                            <Grid item xs={6} md={3} sm={3} lg={3} xl={3}
                                                  key={post.getContentid()}>
                                                <Link
                                                    to={`/c/${groupId}/p/${post.getContentid()}`}>
                                                    <div style={{
                                                        height: '100px',
                                                        width: '150px',
                                                        overflow: 'hidden',
                                                        margin: "auto"
                                                    }}>{postedImages[post.getContentid()].length > 1 ? (
                                                        postedImages[post.getContentid()] ? postedImages[post.getContentid()].map((singleImg, index) => (
                                                            <img src={singleImg}
                                                                 style={{width: 100 / (postedImages[post.getContentid()].length) + '%'}}/>
                                                        )) : null
                                                    ) : <img src={postedImages[post.getContentid()]}
                                                             style={{width: '100%'}}/>}
                                                    </div>
                                                </Link>
                                            </Grid>
                                        ))}
                                    <br/>
                                    {loadingMore ? (
                                        <div style={{textAlign: 'center'}}><CircularProgress size={16}/> <span style={{
                                            fontSize: '17px',
                                            fontWeight: '600',
                                            margin: '5px'
                                        }}>More images...</span></div>) : (<span></span>)}
                                </Grid>
                            ) : (<div>No images found.</div>)}
                        </div>
                    ) : (<div>{PostLoader()}</div>)}
                </div>
            </DashboardLayout>
        )
    }
}

QuickViewImages.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(QuickViewImages);

