import * as dateFns from 'date-fns/';

export function getMonthFrom(date) {
    return dateFns.getMonth(date);
}

export function getYearFrom(date) {
    return dateFns.getYear(date);
}

export function getDateFrom(date) {
    return dateFns.getDate(date);
}

export function formatDate(date, format) {
    return dateFns.format(date, format);
}

export function timeRemaining(date) {
    return dateFns.formatDistanceToNow(date, {includeSeconds: true});
}

export function timeEvent(date) {
    return dateFns.formatDistanceToNow(date, {includeSeconds: true, addSuffix: true});
}

function get_time_int(uuid_str) {
    var uuid_arr = uuid_str.split('-'),
        time_str = [
            uuid_arr[2].substring(1),
            uuid_arr[1],
            uuid_arr[0]
        ].join('');
    return parseInt(time_str, 16);
}

export function get_date_obj(uuid_str) {
    var int_time = get_time_int(uuid_str) - 122192928000000000,
        int_millisec = Math.floor(int_time / 10000);
    return new Date(int_millisec);
}