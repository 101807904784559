/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.displayName = 'proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            contentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
            type: jspb.Message.getFieldWithDefault(msg, 2, 0),
            pagesize: jspb.Message.getFieldWithDefault(msg, 3, 0),
            cursor: jspb.Message.getFieldWithDefault(msg, 4, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest;
    return proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setContentid(value);
                break;
            case 2:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (reader.readEnum());
                msg.setType(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setPagesize(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getContentid();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getType();
    if (f !== 0.0) {
        writer.writeEnum(
            2,
            f
        );
    }
    f = message.getPagesize();
    if (f !== 0) {
        writer.writeUint32(
            3,
            f
        );
    }
    f = message.getCursor();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
};


/**
 * optional string contentId = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.prototype.getContentid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.prototype.setContentid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ReactionType type = 2;
 * @return {!proto.com.hamropatro.sociallayer.io.ReactionType}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.prototype.getType = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.ReactionType} value */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.prototype.setType = function (value) {
    jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 pagesize = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.prototype.getPagesize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.prototype.setPagesize = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string cursor = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.displayName = 'proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            contentuserreactioninfoList: jspb.Message.toObjectList(msg.getContentuserreactioninfoList(),
                proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            contentId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse;
    return proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.deserializeBinaryFromReader);
                msg.addContentuserreactioninfo(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setContentId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getContentuserreactioninfoList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.serializeBinaryToWriter
        );
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getContentId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
};


/**
 * repeated ContentUserReactionInfo contentUserReactionInfo = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo>}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.prototype.getContentuserreactioninfoList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo>} value */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.prototype.setContentuserreactioninfoList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.prototype.addContentuserreactioninfo = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo, opt_index);
};


proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.prototype.clearContentuserreactioninfoList = function () {
    this.setContentuserreactioninfoList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.prototype.setNextPageToken = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.prototype.getContentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.prototype.setContentId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.displayName = 'proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.toObject = function (includeInstance, msg) {
        var f, obj = {
            appid: jspb.Message.getFieldWithDefault(msg, 1, ""),
            accountid: jspb.Message.getFieldWithDefault(msg, 3, ""),
            reactiontype: jspb.Message.getFieldWithDefault(msg, 4, 0),
            time: jspb.Message.getFieldWithDefault(msg, 5, 0),
            reactionuserinfo: (f = msg.getReactionuserinfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
            summary: jspb.Message.getFieldWithDefault(msg, 7, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo;
    return proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppid(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountid(value);
                break;
            case 4:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (reader.readEnum());
                msg.setReactiontype(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTime(value);
                break;
            case 6:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setReactionuserinfo(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setSummary(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppid();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getAccountid();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getReactiontype();
    if (f !== 0.0) {
        writer.writeEnum(
            4,
            f
        );
    }
    f = message.getTime();
    if (f !== 0) {
        writer.writeUint64(
            5,
            f
        );
    }
    f = message.getReactionuserinfo();
    if (f != null) {
        writer.writeMessage(
            6,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getSummary();
    if (f.length > 0) {
        writer.writeString(
            7,
            f
        );
    }
};


/**
 * optional string appId = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.getAppid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.setAppid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string accountId = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.getAccountid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.setAccountid = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ReactionType reactionType = 4;
 * @return {!proto.com.hamropatro.sociallayer.io.ReactionType}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.getReactiontype = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.ReactionType} value */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.setReactiontype = function (value) {
    jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional uint64 time = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.getTime = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.setTime = function (value) {
    jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional AccountInfo reactionUserInfo = 6;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.getReactionuserinfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 6));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.setReactionuserinfo = function (value) {
    jspb.Message.setWrapperField(this, 6, value);
};


proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.clearReactionuserinfo = function () {
    this.setReactionuserinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.hasReactionuserinfo = function () {
    return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string summary = 7;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.getSummary = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ContentUserReactionInfo.prototype.setSummary = function (value) {
    jspb.Message.setProto3StringField(this, 7, value);
};


goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
