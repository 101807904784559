import {CommunityServiceServer as srv} from "../grpcClients/service-clients-factory";
import {MetaHeaders} from "../configurations/config";
import {retryCase} from "../utils/Common";

export function getAllGroups(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getUserGroups(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getAllGroups(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getAllPendingGroups(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getPendingGroups(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getAllPendingGroups(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getAllSuggestedGroups(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getSuggestedGroup(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getAllSuggestedGroups(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getGroupDetails(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getGroup(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getGroupDetails(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getSharableLink(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getInvitationLink(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getDirectGeneratedLink(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.generateDirectJoinLink(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function removeDirectLink(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.removeDirectJoinLink(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function createNewCategory(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.addCategory(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getAllCategory(userReq,retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getCategories(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getAllCategory(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function updateGroupType(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.updateGroupType(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function updateCommunityTicketing(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.updateTickingPrivilege(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getNearByCommunities(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getNearbyGroups(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getNearByCommunities(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function changeNotificationStsForGroup(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.updateGroupNotificationPrivilege(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function updateGroupNotificationCount(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.updateGroupMaxPushCounts(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}