export default theme => ({
    root: {
        backgroundColor: theme.palette.background.sideBar,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingLeft: '50px',
        paddingRight: '50px',
        borderRight: 'unset'
    },
    search: {
        marginTop: '15px',
        marginBottom: '15px',
        position: 'relative',
        borderRadius: '20px',
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
        },
        marginLeft: 0,
        width: '100%',
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },
    title: {
        marginTop: '25px',
        marginBottom: '15px'
    },
    product: {
        width: 'fit-content',
        paddingBottom: theme.spacing(),
        paddingTop: theme.spacing(),
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            '& $productTitle': {
                color: theme.palette.primary.main
            },
            '& $productImage': {
                opacity: 0.8
            },
        }
    },
    productImageWrapper: {
        borderRadius: '5px',
        overflow: 'hidden',
        height: '50px',
        width: '50px',
        border: '1px solid #e5e5e5'
    },
    productImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    productDetails: {
        marginLeft: theme.spacing(2),
        flexGrow: 1
    },
    productTitle: {},
    productTimestamp: {
        marginTop: theme.spacing(),
        color: theme.palette.text.secondary
    },
    viewAll: {
        padding: '10px 0',
        textAlign: 'right',
        color: theme.palette.primary.main,
    },
    viewText: {
        '&:hover': {
            textDecoration: 'underline',
        },
    }
});
