/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetUserActivityRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetUserActivityResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.NotificationCount', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.NotificationCountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.NotificationCountResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UserActivity', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UserNotificationFeed', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
            pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest;
    return proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setPageSize(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 3:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeUint32(
            1,
            f
        );
    }
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            3,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
};


/**
 * optional uint32 page_size = 1;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.prototype.setPageToken = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AccountInfo business_account_info = 3;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 3));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 3, value);
};


proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedRequest.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            accountType: jspb.Message.getFieldWithDefault(msg, 3, ""),
            notificationfeedList: jspb.Message.toObjectList(msg.getNotificationfeedList(),
                proto.com.hamropatro.sociallayer.io.UserNotificationFeed.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 5, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse;
    return proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountType(value);
                break;
            case 4:
                var value = new proto.com.hamropatro.sociallayer.io.UserNotificationFeed;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.UserNotificationFeed.deserializeBinaryFromReader);
                msg.addNotificationfeed(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getAccountType();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getNotificationfeedList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            4,
            f,
            proto.com.hamropatro.sociallayer.io.UserNotificationFeed.serializeBinaryToWriter
        );
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_type = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.getAccountType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.setAccountType = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated UserNotificationFeed notificationFeed = 4;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.UserNotificationFeed>}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.getNotificationfeedList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.UserNotificationFeed>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.UserNotificationFeed, 4));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.UserNotificationFeed>} value */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.setNotificationfeedList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UserNotificationFeed=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.UserNotificationFeed}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.addNotificationfeed = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.hamropatro.sociallayer.io.UserNotificationFeed, opt_index);
};


proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.clearNotificationfeedList = function () {
    this.setNotificationfeedList([]);
};


/**
 * optional string next_page_token = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetNotificationFeedResponse.prototype.setNextPageToken = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UserNotificationFeed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.UserNotificationFeed.displayName = 'proto.com.hamropatro.sociallayer.io.UserNotificationFeed';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.UserNotificationFeed.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.UserNotificationFeed} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.UserNotificationFeed.toObject = function (includeInstance, msg) {
        var f, obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, ""),
            title: jspb.Message.getFieldWithDefault(msg, 2, ""),
            description: jspb.Message.getFieldWithDefault(msg, 3, ""),
            timestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
            actorimageurl: jspb.Message.getFieldWithDefault(msg, 5, ""),
            image: jspb.Message.getFieldWithDefault(msg, 6, ""),
            deeplink: jspb.Message.getFieldWithDefault(msg, 7, ""),
            metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
            topic: jspb.Message.getFieldWithDefault(msg, 9, ""),
            summary: jspb.Message.getFieldWithDefault(msg, 10, ""),
            seen: jspb.Message.getFieldWithDefault(msg, 11, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UserNotificationFeed}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.UserNotificationFeed;
    return proto.com.hamropatro.sociallayer.io.UserNotificationFeed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UserNotificationFeed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UserNotificationFeed}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setTitle(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setDescription(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTimestamp(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setActorimageurl(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setImage(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setDeeplink(value);
                break;
            case 8:
                var value = msg.getMetadataMap();
                reader.readMessage(value, function (message, reader) {
                    jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
                });
                break;
            case 9:
                var value = /** @type {string} */ (reader.readString());
                msg.setTopic(value);
                break;
            case 10:
                var value = /** @type {string} */ (reader.readString());
                msg.setSummary(value);
                break;
            case 11:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSeen(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.UserNotificationFeed.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UserNotificationFeed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getTitle();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getDescription();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getTimestamp();
    if (f !== 0) {
        writer.writeUint64(
            4,
            f
        );
    }
    f = message.getActorimageurl();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
    f = message.getImage();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
        );
    }
    f = message.getDeeplink();
    if (f.length > 0) {
        writer.writeString(
            7,
            f
        );
    }
    f = message.getMetadataMap(true);
    if (f && f.getLength() > 0) {
        f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
    }
    f = message.getTopic();
    if (f.length > 0) {
        writer.writeString(
            9,
            f
        );
    }
    f = message.getSummary();
    if (f.length > 0) {
        writer.writeString(
            10,
            f
        );
    }
    f = message.getSeen();
    if (f) {
        writer.writeBool(
            11,
            f
        );
    }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.getId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.setId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.getTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.setTitle = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.getDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.setDescription = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 timestamp = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.getTimestamp = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.setTimestamp = function (value) {
    jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string actorImageUrl = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.getActorimageurl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.setActorimageurl = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string image = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.getImage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.setImage = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string deeplink = 7;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.getDeeplink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.setDeeplink = function (value) {
    jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * map<string, string> metadata = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.getMetadataMap = function (opt_noLazyCreate) {
    return /** @type {!jspb.Map<string,string>} */ (
        jspb.Message.getMapField(this, 8, opt_noLazyCreate,
            null));
};


proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.clearMetadataMap = function () {
    this.getMetadataMap().clear();
};


/**
 * optional string topic = 9;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.getTopic = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.setTopic = function (value) {
    jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string summary = 10;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.getSummary = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.setSummary = function (value) {
    jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool seen = 11;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.getSeen = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 11, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UserNotificationFeed.prototype.setSeen = function (value) {
    jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.NotificationCountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.NotificationCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.NotificationCountRequest.displayName = 'proto.com.hamropatro.sociallayer.io.NotificationCountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.NotificationCountRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.NotificationCountRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.NotificationCountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.NotificationCountRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            identifierId: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.NotificationCountRequest}
 */
proto.com.hamropatro.sociallayer.io.NotificationCountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.NotificationCountRequest;
    return proto.com.hamropatro.sociallayer.io.NotificationCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.NotificationCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.NotificationCountRequest}
 */
proto.com.hamropatro.sociallayer.io.NotificationCountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setIdentifierId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.NotificationCountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.NotificationCountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.NotificationCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.NotificationCountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIdentifierId();
    if (f !== 0) {
        writer.writeUint64(
            1,
            f
        );
    }
};


/**
 * optional uint64 identifier_id = 1;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.NotificationCountRequest.prototype.getIdentifierId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.NotificationCountRequest.prototype.setIdentifierId = function (value) {
    jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.NotificationCountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.NotificationCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.NotificationCountResponse.displayName = 'proto.com.hamropatro.sociallayer.io.NotificationCountResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.NotificationCountResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.NotificationCountResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.NotificationCountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.NotificationCountResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            count: (f = msg.getCount()) && proto.com.hamropatro.sociallayer.io.NotificationCount.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.NotificationCountResponse}
 */
proto.com.hamropatro.sociallayer.io.NotificationCountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.NotificationCountResponse;
    return proto.com.hamropatro.sociallayer.io.NotificationCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.NotificationCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.NotificationCountResponse}
 */
proto.com.hamropatro.sociallayer.io.NotificationCountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.NotificationCount;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.NotificationCount.deserializeBinaryFromReader);
                msg.setCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.NotificationCountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.NotificationCountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.NotificationCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.NotificationCountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCount();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.NotificationCount.serializeBinaryToWriter
        );
    }
};


/**
 * optional NotificationCount count = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.NotificationCount}
 */
proto.com.hamropatro.sociallayer.io.NotificationCountResponse.prototype.getCount = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.NotificationCount} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.NotificationCount, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.NotificationCount|undefined} value */
proto.com.hamropatro.sociallayer.io.NotificationCountResponse.prototype.setCount = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.sociallayer.io.NotificationCountResponse.prototype.clearCount = function () {
    this.setCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.NotificationCountResponse.prototype.hasCount = function () {
    return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.NotificationCount = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.NotificationCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.NotificationCount.displayName = 'proto.com.hamropatro.sociallayer.io.NotificationCount';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.NotificationCount.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.NotificationCount.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.NotificationCount} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.NotificationCount.toObject = function (includeInstance, msg) {
        var f, obj = {
            value: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.NotificationCount}
 */
proto.com.hamropatro.sociallayer.io.NotificationCount.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.NotificationCount;
    return proto.com.hamropatro.sociallayer.io.NotificationCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.NotificationCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.NotificationCount}
 */
proto.com.hamropatro.sociallayer.io.NotificationCount.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setValue(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.NotificationCount.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.NotificationCount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.NotificationCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.NotificationCount.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getValue();
    if (f !== 0) {
        writer.writeInt64(
            1,
            f
        );
    }
};


/**
 * optional int64 value = 1;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.NotificationCount.prototype.getValue = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.NotificationCount.prototype.setValue = function (value) {
    jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            activitytimeList: jspb.Message.getRepeatedField(msg, 1)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest;
    return proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
                msg.setActivitytimeList(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getActivitytimeList();
    if (f.length > 0) {
        writer.writePackedInt64(
            1,
            f
        );
    }
};


/**
 * repeated int64 activityTime = 1;
 * @return {!Array<number>}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.prototype.getActivitytimeList = function () {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.prototype.setActivitytimeList = function (value) {
    jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.prototype.addActivitytime = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusRequest.prototype.clearActivitytimeList = function () {
    this.setActivitytimeList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            success: jspb.Message.getFieldWithDefault(msg, 1, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse;
    return proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSuccess(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSuccess();
    if (f) {
        writer.writeBool(
            1,
            f
        );
    }
};


/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UpdateNotificationSeenStatusResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetUserActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetUserActivityRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
            pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserActivityRequest}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetUserActivityRequest;
    return proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserActivityRequest}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setPageSize(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 3:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeUint32(
            1,
            f
        );
    }
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            3,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
};


/**
 * optional uint32 page_size = 1;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.prototype.setPageToken = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AccountInfo business_account_info = 3;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 3));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 3, value);
};


proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityRequest.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetUserActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetUserActivityResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            accountType: jspb.Message.getFieldWithDefault(msg, 3, ""),
            useractivitiesList: jspb.Message.toObjectList(msg.getUseractivitiesList(),
                proto.com.hamropatro.sociallayer.io.UserActivity.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 5, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserActivityResponse}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetUserActivityResponse;
    return proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserActivityResponse}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountType(value);
                break;
            case 4:
                var value = new proto.com.hamropatro.sociallayer.io.UserActivity;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.UserActivity.deserializeBinaryFromReader);
                msg.addUseractivities(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getAccountType();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getUseractivitiesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            4,
            f,
            proto.com.hamropatro.sociallayer.io.UserActivity.serializeBinaryToWriter
        );
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_type = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.getAccountType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.setAccountType = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated UserActivity userActivities = 4;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.UserActivity>}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.getUseractivitiesList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.UserActivity>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.UserActivity, 4));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.UserActivity>} value */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.setUseractivitiesList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UserActivity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.UserActivity}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.addUseractivities = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.hamropatro.sociallayer.io.UserActivity, opt_index);
};


proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.clearUseractivitiesList = function () {
    this.setUseractivitiesList([]);
};


/**
 * optional string next_page_token = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUserActivityResponse.prototype.setNextPageToken = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UserActivity = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UserActivity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.UserActivity.displayName = 'proto.com.hamropatro.sociallayer.io.UserActivity';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.UserActivity.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.UserActivity.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.UserActivity} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.UserActivity.toObject = function (includeInstance, msg) {
        var f, obj = {
            id: jspb.Message.getFieldWithDefault(msg, 1, ""),
            title: jspb.Message.getFieldWithDefault(msg, 2, ""),
            description: jspb.Message.getFieldWithDefault(msg, 3, ""),
            timestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
            usericon: jspb.Message.getFieldWithDefault(msg, 5, ""),
            image: jspb.Message.getFieldWithDefault(msg, 6, ""),
            deeplink: jspb.Message.getFieldWithDefault(msg, 7, ""),
            metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
            topic: jspb.Message.getFieldWithDefault(msg, 9, ""),
            summary: jspb.Message.getFieldWithDefault(msg, 10, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UserActivity}
 */
proto.com.hamropatro.sociallayer.io.UserActivity.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.UserActivity;
    return proto.com.hamropatro.sociallayer.io.UserActivity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UserActivity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UserActivity}
 */
proto.com.hamropatro.sociallayer.io.UserActivity.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setTitle(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setDescription(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTimestamp(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setUsericon(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setImage(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setDeeplink(value);
                break;
            case 8:
                var value = msg.getMetadataMap();
                reader.readMessage(value, function (message, reader) {
                    jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
                });
                break;
            case 9:
                var value = /** @type {string} */ (reader.readString());
                msg.setTopic(value);
                break;
            case 10:
                var value = /** @type {string} */ (reader.readString());
                msg.setSummary(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.UserActivity.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UserActivity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UserActivity.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getTitle();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getDescription();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getTimestamp();
    if (f !== 0) {
        writer.writeUint64(
            4,
            f
        );
    }
    f = message.getUsericon();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
    f = message.getImage();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
        );
    }
    f = message.getDeeplink();
    if (f.length > 0) {
        writer.writeString(
            7,
            f
        );
    }
    f = message.getMetadataMap(true);
    if (f && f.getLength() > 0) {
        f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
    }
    f = message.getTopic();
    if (f.length > 0) {
        writer.writeString(
            9,
            f
        );
    }
    f = message.getSummary();
    if (f.length > 0) {
        writer.writeString(
            10,
            f
        );
    }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.getId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.setId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.getTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.setTitle = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.getDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.setDescription = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 timestamp = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.getTimestamp = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.setTimestamp = function (value) {
    jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string userIcon = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.getUsericon = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.setUsericon = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string image = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.getImage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.setImage = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string deeplink = 7;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.getDeeplink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.setDeeplink = function (value) {
    jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * map<string, string> metadata = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.getMetadataMap = function (opt_noLazyCreate) {
    return /** @type {!jspb.Map<string,string>} */ (
        jspb.Message.getMapField(this, 8, opt_noLazyCreate,
            null));
};


proto.com.hamropatro.sociallayer.io.UserActivity.prototype.clearMetadataMap = function () {
    this.getMetadataMap().clear();
};


/**
 * optional string topic = 9;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.getTopic = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.setTopic = function (value) {
    jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string summary = 10;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.getSummary = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserActivity.prototype.setSummary = function (value) {
    jspb.Message.setProto3StringField(this, 10, value);
};


goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
