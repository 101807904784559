/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.CommentOnContentRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.CommentOnContentResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.DeleteCommentRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.Status', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.DeleteCommentResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetCommentRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetCommentResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ListCommentsRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ListCommentsResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.SpamCommentRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.SpamCommentResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.CommentOnContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.displayName = 'proto.com.hamropatro.sociallayer.io.CommentOnContentRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.CommentOnContentRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            accountInfo: (f = msg.getAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
            comment: (f = msg.getComment()) && frontend$social$common$types_pb.CommentMessage.toObject(includeInstance, f),
            commentType: jspb.Message.getFieldWithDefault(msg, 5, 0),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.CommentOnContentRequest}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.CommentOnContentRequest;
    return proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.CommentOnContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.CommentOnContentRequest}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 3:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setAccountInfo(value);
                break;
            case 4:
                var value = new frontend$social$common$types_pb.CommentMessage;
                reader.readMessage(value, frontend$social$common$types_pb.CommentMessage.deserializeBinaryFromReader);
                msg.setComment(value);
                break;
            case 5:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.MessageType} */ (reader.readEnum());
                msg.setCommentType(value);
                break;
            case 6:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.CommentOnContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getAccountInfo();
    if (f != null) {
        writer.writeMessage(
            3,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getComment();
    if (f != null) {
        writer.writeMessage(
            4,
            f,
            frontend$social$common$types_pb.CommentMessage.serializeBinaryToWriter
        );
    }
    f = message.getCommentType();
    if (f !== 0.0) {
        writer.writeEnum(
            5,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            6,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
};


/**
 * optional string post_uri = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AccountInfo account_info = 3;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.getAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 3));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.setAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 3, value);
};


proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.clearAccountInfo = function () {
    this.setAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.hasAccountInfo = function () {
    return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CommentMessage comment = 4;
 * @return {?proto.com.hamropatro.sociallayer.io.CommentMessage}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.getComment = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.CommentMessage} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.CommentMessage, 4));
};


/** @param {?proto.com.hamropatro.sociallayer.io.CommentMessage|undefined} value */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.setComment = function (value) {
    jspb.Message.setWrapperField(this, 4, value);
};


proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.clearComment = function () {
    this.setComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.hasComment = function () {
    return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MessageType comment_type = 5;
 * @return {!proto.com.hamropatro.sociallayer.io.MessageType}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.getCommentType = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.MessageType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.MessageType} value */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.setCommentType = function (value) {
    jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional AccountInfo business_account_info = 6;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 6));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 6, value);
};


proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentRequest.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 6) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.CommentOnContentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.displayName = 'proto.com.hamropatro.sociallayer.io.CommentOnContentResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.CommentOnContentResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            postUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            accountInfo: (f = msg.getAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
            postOwnerBusinessId: jspb.Message.getFieldWithDefault(msg, 6, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.CommentOnContentResponse}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.CommentOnContentResponse;
    return proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.CommentOnContentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.CommentOnContentResponse}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 4:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setAccountInfo(value);
                break;
            case 5:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostOwnerBusinessId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.CommentOnContentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getAccountInfo();
    if (f != null) {
        writer.writeMessage(
            4,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            5,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getPostOwnerBusinessId();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
        );
    }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string post_uri = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AccountInfo account_info = 4;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.getAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 4));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.setAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 4, value);
};


proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.clearAccountInfo = function () {
    this.setAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.hasAccountInfo = function () {
    return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AccountInfo business_account_info = 5;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 5));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 5, value);
};


proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string post_owner_business_id = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.getPostOwnerBusinessId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.prototype.setPostOwnerBusinessId = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.displayName = 'proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            accountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            reactionType: jspb.Message.getFieldWithDefault(msg, 4, 0),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest;
    return proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 4:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (reader.readEnum());
                msg.setReactionType(value);
                break;
            case 5:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getReactionType();
    if (f !== 0.0) {
        writer.writeEnum(
            4,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            5,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
};


/**
 * optional string post_uri = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ReactionType reaction_type = 4;
 * @return {!proto.com.hamropatro.sociallayer.io.ReactionType}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.getReactionType = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.ReactionType} value */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.setReactionType = function (value) {
    jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional AccountInfo business_account_info = 5;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 5));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 5, value);
};


proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 5) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse.displayName = 'proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse;
    return proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.DeleteCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.displayName = 'proto.com.hamropatro.sociallayer.io.DeleteCommentRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            status: jspb.Message.getFieldWithDefault(msg, 3, 0),
            accountId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.DeleteCommentRequest}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.DeleteCommentRequest;
    return proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.DeleteCommentRequest}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 3:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.Status} */ (reader.readEnum());
                msg.setStatus(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 5:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getStatus();
    if (f !== 0.0) {
        writer.writeEnum(
            3,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            5,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
};


/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.Status = {
    APPROVED: 0,
    ACTIVE: 1,
    BLOCKED: 2,
    PENDING: 3
};

/**
 * optional string post_uri = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Status status = 3;
 * @return {!proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.Status}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.getStatus = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.Status} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.setStatus = function (value) {
    jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string account_id = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional AccountInfo business_account_info = 5;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 5));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 5, value);
};


proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentRequest.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 5) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.DeleteCommentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.displayName = 'proto.com.hamropatro.sociallayer.io.DeleteCommentResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            postUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            postOwnerBusinessId: jspb.Message.getFieldWithDefault(msg, 4, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.DeleteCommentResponse}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.DeleteCommentResponse;
    return proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.DeleteCommentResponse}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostOwnerBusinessId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getPostOwnerBusinessId();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string post_uri = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string post_owner_business_id = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.prototype.getPostOwnerBusinessId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.prototype.setPostOwnerBusinessId = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ListCommentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ListCommentsRequest.displayName = 'proto.com.hamropatro.sociallayer.io.ListCommentsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ListCommentsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ListCommentsRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ListCommentsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ListCommentsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
            pageToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ListCommentsRequest}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ListCommentsRequest;
    return proto.com.hamropatro.sociallayer.io.ListCommentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ListCommentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ListCommentsRequest}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setPageSize(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 4:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ListCommentsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ListCommentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeUint32(
            2,
            f
        );
    }
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            4,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
};


/**
 * optional string post_uri = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 page_size = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest.prototype.setPageToken = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AccountInfo business_account_info = 4;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 4));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 4, value);
};


proto.com.hamropatro.sociallayer.io.ListCommentsRequest.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsRequest.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 4) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.ListCommentsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ListCommentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ListCommentsResponse.displayName = 'proto.com.hamropatro.sociallayer.io.ListCommentsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ListCommentsResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ListCommentsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ListCommentsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            postUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
            commentsList: jspb.Message.toObjectList(msg.getCommentsList(),
                frontend$social$common$types_pb.UserComment.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
            postOwnerBusinessId: jspb.Message.getFieldWithDefault(msg, 6, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ListCommentsResponse}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ListCommentsResponse;
    return proto.com.hamropatro.sociallayer.io.ListCommentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ListCommentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ListCommentsResponse}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 3:
                var value = new frontend$social$common$types_pb.UserComment;
                reader.readMessage(value, frontend$social$common$types_pb.UserComment.deserializeBinaryFromReader);
                msg.addComments(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 5:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostOwnerBusinessId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ListCommentsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ListCommentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getCommentsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            3,
            f,
            frontend$social$common$types_pb.UserComment.serializeBinaryToWriter
        );
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            5,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getPostOwnerBusinessId();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
        );
    }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string post_uri = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated UserComment comments = 3;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.UserComment>}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.getCommentsList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.UserComment>} */ (
        jspb.Message.getRepeatedWrapperField(this, frontend$social$common$types_pb.UserComment, 3));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.UserComment>} value */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.setCommentsList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UserComment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.UserComment}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.addComments = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.hamropatro.sociallayer.io.UserComment, opt_index);
};


proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.clearCommentsList = function () {
    this.setCommentsList([]);
};


/**
 * optional string next_page_token = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.setNextPageToken = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional AccountInfo business_account_info = 5;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 5));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 5, value);
};


proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string post_owner_business_id = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.getPostOwnerBusinessId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentsResponse.prototype.setPostOwnerBusinessId = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.SpamCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.SpamCommentRequest.displayName = 'proto.com.hamropatro.sociallayer.io.SpamCommentRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.SpamCommentRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.SpamCommentRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.SpamCommentRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.SpamCommentRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            ownerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            accountId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            accountType: jspb.Message.getFieldWithDefault(msg, 5, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.SpamCommentRequest}
 */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.SpamCommentRequest;
    return proto.com.hamropatro.sociallayer.io.SpamCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.SpamCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.SpamCommentRequest}
 */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setOwnerId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.SpamCommentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.SpamCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getOwnerId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getAccountType();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
};


/**
 * optional string post_uri = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string owner_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.prototype.getOwnerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.prototype.setOwnerId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_id = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_type = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.prototype.getAccountType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.SpamCommentRequest.prototype.setAccountType = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.SpamCommentResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.SpamCommentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.SpamCommentResponse.displayName = 'proto.com.hamropatro.sociallayer.io.SpamCommentResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.SpamCommentResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.SpamCommentResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.SpamCommentResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.SpamCommentResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.SpamCommentResponse}
 */
proto.com.hamropatro.sociallayer.io.SpamCommentResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.SpamCommentResponse;
    return proto.com.hamropatro.sociallayer.io.SpamCommentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.SpamCommentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.SpamCommentResponse}
 */
proto.com.hamropatro.sociallayer.io.SpamCommentResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.SpamCommentResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.SpamCommentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.SpamCommentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.SpamCommentResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetCommentRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetCommentRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetCommentRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetCommentRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetCommentRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetCommentRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetCommentRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetCommentRequest}
 */
proto.com.hamropatro.sociallayer.io.GetCommentRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetCommentRequest;
    return proto.com.hamropatro.sociallayer.io.GetCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetCommentRequest}
 */
proto.com.hamropatro.sociallayer.io.GetCommentRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 3:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetCommentRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetCommentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetCommentRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            3,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
};


/**
 * optional string post_uri = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetCommentRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetCommentRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetCommentRequest.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetCommentRequest.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AccountInfo business_account_info = 3;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.GetCommentRequest.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 3));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.GetCommentRequest.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 3, value);
};


proto.com.hamropatro.sociallayer.io.GetCommentRequest.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.GetCommentRequest.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetCommentResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetCommentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetCommentResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetCommentResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetCommentResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetCommentResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetCommentResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetCommentResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            commentdetail: (f = msg.getCommentdetail()) && frontend$social$common$types_pb.CommentDetail.toObject(includeInstance, f),
            postownerbusinessid: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetCommentResponse}
 */
proto.com.hamropatro.sociallayer.io.GetCommentResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetCommentResponse;
    return proto.com.hamropatro.sociallayer.io.GetCommentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetCommentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetCommentResponse}
 */
proto.com.hamropatro.sociallayer.io.GetCommentResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new frontend$social$common$types_pb.CommentDetail;
                reader.readMessage(value, frontend$social$common$types_pb.CommentDetail.deserializeBinaryFromReader);
                msg.setCommentdetail(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostownerbusinessid(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetCommentResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetCommentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetCommentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetCommentResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCommentdetail();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            frontend$social$common$types_pb.CommentDetail.serializeBinaryToWriter
        );
    }
    f = message.getPostownerbusinessid();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional CommentDetail commentDetail = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.CommentDetail}
 */
proto.com.hamropatro.sociallayer.io.GetCommentResponse.prototype.getCommentdetail = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.CommentDetail} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.CommentDetail, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.CommentDetail|undefined} value */
proto.com.hamropatro.sociallayer.io.GetCommentResponse.prototype.setCommentdetail = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.sociallayer.io.GetCommentResponse.prototype.clearCommentdetail = function () {
    this.setCommentdetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.GetCommentResponse.prototype.hasCommentdetail = function () {
    return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string postOwnerBusinessId = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetCommentResponse.prototype.getPostownerbusinessid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetCommentResponse.prototype.setPostownerbusinessid = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
