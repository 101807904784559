import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {withStyles} from '@material-ui/core';
// Shared layouts
// Component styles
import styles from './styles';
import {generateLink} from "../../../utils/converter/linkGenerator";
import {shortenString} from "../../../utils/converter/shortenString";

class RenderText extends Component {
    state = {
        showMore: false
    };

    setShowMore = () => {
        this.setState({
            showMore: true
        })
    };

    render() {
        const {text} = this.props;
        const {showMore} = this.state;
        return (
            <span>
                <span
                    dangerouslySetInnerHTML={showMore ? generateLink(text) : generateLink(shortenString(text, 250))}
                >
                </span>
                {text && text.length >= 250 && !showMore ? (
                    <span onClick={() => this.setShowMore()} style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: '14px',
                        color: '#3377e0'
                    }}>Show More</span>
                ) : null}
            </span>
        );
    }
}

RenderText.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RenderText);
