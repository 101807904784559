export default theme => ({
    root: {
        padding: theme.spacing(1),
        minHeight: '85vh'
    },
    detailTabs: {
        boxShadow: 'unset',
        alignItems: 'center'
    },
    product: {
        paddingBottom: theme.spacing(),
        paddingTop: theme.spacing(),
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            '& $productTitle': {
                color: theme.palette.primary.main
            },
            '& $productImage': {
                opacity: 0.8
            },
        }
    },
    userImageWrapper: {
        borderRadius: '50%',
        overflow: 'hidden',
        height: '50px',
        width: '50px',
        border: '1px solid #e5e5e5'
    },
    productImage: {
        width: '100%',
        height: 'auto'
    },
    productDetails: {
        marginLeft: theme.spacing(2),
        flexGrow: 1
    },
    productTitle: {
        fontSize: '1em'
    },
    productTimestamp: {
        marginTop: theme.spacing(),
        color: theme.palette.text.secondary
    },
    detailHeader: {
        textAlign: 'center',
        padding: '0 10px 10px'
    },
    inviteMember: {
        width: 'fit-content !important',
        marginTop: '20px',
        marginBottom: '20px',
        padding: '7px 30px !important',
        boxShadow: 'none',
        color: '#ee493f',
        background: '#f4f6f7',
        '&:hover': {
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    chip: {
        margin: '2px 5px'
    },
    noMemberWrapper: {
        padding: '10px',
        borderRadius: '5px',
        background: '#66aef1d4',
        color: '#253d49',
        fontSize: '16px'
    },
    displayFlex: {
        display: 'flex'
    }
});
