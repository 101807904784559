import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, withStyles} from "@material-ui/core";
import styles from "../../Home/styles";
import Typography from "@material-ui/core/Typography";
import {eventAnalyst} from "../../../configurations/config";
import {GetSuggestedGroupRequest} from "../../../generated/frontend-community-group-service_pb";
import {getAllSuggestedGroups} from "../../../grpcRequests/groups-request";
import {EventsLoader} from "../../../components/Skeleton/EventsSket";
import GroupsCard from "./GroupsCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {ArrowRightAltOutlined as CalendarIcon} from "@material-ui/icons";

const defaultPageSize = 15;

class SuggestedCommunity extends Component {
    state = {
        history: this.props.history,
        isLoadingSuggested: false,
        mySuggestedLists: [],
        errSuggestedGroupsMsg: null,
        title: 'Featured Communities',
        suggestCommCursor: '',
        suggestCommPreviousCursor: '',
        loadingMoreSuggested: false,
    };

    componentDidMount() {
        this.fetSuggestedGroups('', defaultPageSize, [], '');
    }

    goToGroups() {
        eventAnalyst('home_view_link_redirect', this.state.title + '_view_events', this.state.title + ' click success', this.state.title + '-click-success');
        const {history} = this.state.history;
        history.push('/explore/community');
    };

    loadMore = (cursorValue, pageSize, previousData, prevCursor) => {
        this.setState({loadingMoreSuggested: true});
        this.fetSuggestedGroups(cursorValue, pageSize, previousData, prevCursor)
    };

    fetSuggestedGroups = (cursorValue, pageSize, previousData, prevCursor) => {
        let self = this;
        self.setState({
            isLoadingSuggested: !prevCursor
        });
        const req = new GetSuggestedGroupRequest();
        req.setCursor(cursorValue);
        req.setPageSize(pageSize);
        getAllSuggestedGroups(req, 3).then(function (response) {
            eventAnalyst('home', 'fetch_suggested_community', 'Suggested community fetch success', 'suggested-community-fetch-success');
            // let mySuggestedLists = response.getGroupinfoList();
            let mySuggestedLists = previousData;
            for (let i = 0; i < response.getGroupinfoList().length; i++) {
                let newData = response.getGroupinfoList()[i];
                mySuggestedLists.push(newData);
            }
            const isLoadingSuggested = false;
            const loadingMoreSuggested = false;
            const suggestCommCursor = response.getNextPageToken();
            self.setState({
                isLoadingSuggested,
                mySuggestedLists,
                suggestCommCursor,
                suggestCommPreviousCursor: prevCursor,
                loadingMoreSuggested
            });
        }).catch(function (error) {

            eventAnalyst('home', 'fetch_suggested_community', 'Suggested community fetch fail', 'suggested-community-fetch-fail');
            const isLoadingSuggested = false;
            const errSuggestedGroupsMsg = '';
            const loadingMoreSuggested = false;
            self.setState({
                isLoadingSuggested,
                errSuggestedGroupsMsg,
                loadingMoreSuggested
            });
            // self.snackbar(true, 'Got Err while fetching pending', 'error', 5000)
        })
    };

    render() {
        const {classes} = this.props;
        const {errSuggestedGroupsMsg, mySuggestedLists, isLoadingSuggested, title, suggestCommCursor, loadingMoreSuggested, suggestCommPreviousCursor} = this.state;

        return (
            <div>
                {!isLoadingSuggested ? (
                    <div>
                        {mySuggestedLists.length > 0 ? (
                            <div className={classes.boxItems}>
                                <div>
                                    <div>
                                        <div className={classes.floatLeft}>
                                            <Typography
                                                variant="h6"
                                                style={{fontWeight: 900}}
                                            >
                                                <span>{title}</span>
                                            </Typography>
                                        </div>
                                        <div className={classes.floatRight}>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                onClick={() => this.goToGroups()}
                                                className={classes.buttonView}
                                            >
                                                Explore
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={classes.simpleUnderline}></div>
                                </div>
                                <div className={classes.homeCardWrapper}>
                                    <GroupsCard groupList={mySuggestedLists} title={title}/>
                                    {suggestCommCursor && suggestCommPreviousCursor !== suggestCommCursor ? (
                                        <div className={classes.seeMoreWrapper}>
                                            <div
                                                onClick={() => this.loadMore(suggestCommCursor, defaultPageSize, mySuggestedLists, suggestCommCursor)}
                                                className={classes.iconTextWrapper}>
                                                <div className={classes.iconWrapper}>
                                                    <IconButton aria-label="See More"
                                                                title="See More"
                                                                size="small"
                                                                color={"primary"}>
                                                        <CalendarIcon style={{fontSize: '38px'}}/>
                                                    </IconButton>
                                                </div>
                                                <div className={classes.textSeeMore}>
                                                    {loadingMoreSuggested ? (
                                                        <CircularProgress size={20}/>) : 'See More'}
                                                </div>
                                            </div>
                                        </div>

                                    ) : (<span></span>)}
                                </div>
                            </div>
                        ) : (
                            <div>
                                {errSuggestedGroupsMsg ? (
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid item>
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                {errSuggestedGroupsMsg}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        )}
                    </div>
                ) : (<EventsLoader/>)}
            </div>
        )
    }
}


SuggestedCommunity.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SuggestedCommunity);