/**
 * @fileoverview gRPC-Web generated client stub for com.hamropatro.sociallayer.io
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');


var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
const proto = {};
proto.com = {};
proto.com.hamropatro = {};
proto.com.hamropatro.sociallayer = {};
proto.com.hamropatro.sociallayer.io = require('./frontend-reaction-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.ReactionServiceClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.ReactionServicePromiseClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.ViewContentRequest,
 *   !proto.com.hamropatro.sociallayer.io.ViewContentResponse>}
 */
const methodDescriptor_ReactionService_ViewContent = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.ReactionService/ViewContent',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.ViewContentRequest,
    proto.com.hamropatro.sociallayer.io.ViewContentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ViewContentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ViewContentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.ViewContentRequest,
 *   !proto.com.hamropatro.sociallayer.io.ViewContentResponse>}
 */
const methodInfo_ReactionService_ViewContent = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.ViewContentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ViewContentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ViewContentResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ViewContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.ViewContentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.ViewContentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.ReactionServiceClient.prototype.viewContent =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReactionService/ViewContent',
            request,
            metadata || {},
            methodDescriptor_ReactionService_ViewContent,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ViewContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.ViewContentResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.ReactionServicePromiseClient.prototype.viewContent =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReactionService/ViewContent',
            request,
            metadata || {},
            methodDescriptor_ReactionService_ViewContent);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.ReactOnContentRequest,
 *   !proto.com.hamropatro.sociallayer.io.ReactOnContentResponse>}
 */
const methodDescriptor_ReactionService_ReactOnContent = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.ReactionService/ReactOnContent',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.ReactOnContentRequest,
    proto.com.hamropatro.sociallayer.io.ReactOnContentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ReactOnContentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ReactOnContentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.ReactOnContentRequest,
 *   !proto.com.hamropatro.sociallayer.io.ReactOnContentResponse>}
 */
const methodInfo_ReactionService_ReactOnContent = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.ReactOnContentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ReactOnContentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ReactOnContentResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.ReactOnContentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.ReactOnContentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.ReactionServiceClient.prototype.reactOnContent =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReactionService/ReactOnContent',
            request,
            metadata || {},
            methodDescriptor_ReactionService_ReactOnContent,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.ReactOnContentResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.ReactionServicePromiseClient.prototype.reactOnContent =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReactionService/ReactOnContent',
            request,
            metadata || {},
            methodDescriptor_ReactionService_ReactOnContent);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetReactionCountRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetReactionCountResponse>}
 */
const methodDescriptor_ReactionService_GetReactionCount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.ReactionService/GetReactionCount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetReactionCountRequest,
    proto.com.hamropatro.sociallayer.io.GetReactionCountResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetReactionCountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetReactionCountRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetReactionCountResponse>}
 */
const methodInfo_ReactionService_GetReactionCount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetReactionCountResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetReactionCountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetReactionCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetReactionCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetReactionCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.ReactionServiceClient.prototype.getReactionCount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReactionService/GetReactionCount',
            request,
            metadata || {},
            methodDescriptor_ReactionService_GetReactionCount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetReactionCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetReactionCountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.ReactionServicePromiseClient.prototype.getReactionCount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReactionService/GetReactionCount',
            request,
            metadata || {},
            methodDescriptor_ReactionService_GetReactionCount);
    };


module.exports = proto.com.hamropatro.sociallayer.io;

