export default theme => ({
    root: {
        padding: theme.spacing(2),
    },
    minHeight: {
        minHeight: '85vh'
    },
    aboutUsWrapper: {
        padding: theme.spacing(2)
    },
    groupDetailImageWrapper: {
        paddingTop: '56.25%',
        position: 'relative',
        width: '100%',
        overflow: 'hidden'
    },
    groupImage: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        objectFit: 'cover'
    },

    joinButton: {
        width: 'fit-content !important',
        marginTop: '20px',
        marginBottom: '20px',
        padding: '7px 30px !important',
        boxShadow: 'none',
        color: '#ffffff',
        background: '#89c348',
        '&:hover': {
            color: '#ffffff',
            background: '#89c348',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    groupInviteBtn: {
        width: '40% !important',
        margin: 'auto',
        padding: '7px 30px !important',
        boxShadow: 'none',
        color: '#000000',
        background: '#ffffff',
        '&:hover': {
            color: '#ffffff',
            background: '#89c348',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    inviteButton: {
        width: 'fit-content !important',
        margin: '20px 5px',
        padding: '7px 30px !important',
        boxShadow: 'none',
        color: '#d23c3c',
        fontSize: '12px',
        background: '#f4f6f7',
        '&:hover': {
            background: '#f4f6f7',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    calendarIcon: {
        width: 'fit-content !important',
        margin: '20px 5px',
        padding: '7px 30px !important',
        fontSize: '12px',
        boxShadow: 'none',
        color: '#ffffff',
        background: '#3b5998',
        '&:hover': {
            background: '#3b5998',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    pendingButton: {
        width: '40% !important',
        marginTop: '20px',
        marginBottom: '20px',
        padding: '7px 30px !important',
        boxShadow: 'none',
        color: '#ffffff',
        background: '#bcbcbc',
        '&:hover': {
            cursor: 'unset',
            background: '#bcbcbc',
            boxShadow: 'none'
        },
    },
    floatRight: {
        float: 'right'
    },
    floatLeft: {
        float: 'left'
    },
    clearBoth: {
        clear: 'both'
    },
    detailHeader: {
        textAlign: 'center',
        padding: '15px 10px'
    },
    groupName: {
        fontWeight: 600,
    },
    groupTypeMember: {
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    followerCount: {
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    mainText: {
        paddingBottom: '10px'
    },
    listItemIcon: {},
    mapImage: {
        width: '100%',
        margin: '10px 0'
    },
    listCover: {
        background: '#f4f6f7',
        padding: '10px 15px 15px',
        borderRadius: '5px'
    },

    textPublic: {
        color: '#757575',
        fontSize: '16px',
        lineHeight: 1.4
    },

    commentClass: {
        margin: '5px',
        padding: '5px',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    groupInfoWrapper: {
        borderTop: '1px solid #e5e5e5',
        marginTop: '20px',
        marginBottom: '20px',
        padding: '17px 0px',
        borderBottom: '1px solid #e5e5e5'
    },
    titleTopBar: {
        marginTop: '25px',
        marginBottom: '15px'
    },
    boxItems: {
        marginTop: '15px',
        marginBottom: '30px'
    },
    buttonView: {
        fontSize: '16px',
        boxShadow: 'none',
        color: '#000000',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    avatarWrapper: {
        paddingTop: '5px',
    },
    avatar: {
        margin: 5,
        height: 50,
        width: 50,
        border: '1px solid #e5e5e5'
    },
    chatBoxAvatar: {
        height: 30,
        width: 30,
    },
    detailTabs: {
        boxShadow: 'unset',
        alignItems: 'center',
        paddingBottom: '10px'
    },
    enableEditing: {
        fontSize: '14px', textDecoration: 'underline'
    },
    memberTab: {
        color: '#000000',
        opacity: 1
    },
    memberButton: {
        padding: 'auto 15px'
    },
    searchBox: {
        background: '#f4f6f7',
        margin: 'auto 5px',
        borderRadius: '50px',
        boxShadow: 'none'
    },
    chatBox: {
        background: '#ffffff',
        margin: 'auto 5px',
        borderRadius: '50px',
        boxShadow: 'none'
    },
    postContent: {
        width: '73%',
        padding: '10px'
    },
    CatContent: {
        width: '100%',
        padding: '10px'
    },
    pageDivider: {
        width: '100%',
        padding: '5px',
        background: '#f4f6f7'
    },
    comentBox: {
        padding: '20px 10px',
    },
    card: {
        background: '#f4f6f7',
        boxShadow: 'none',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    eventsCard: {
        padding: '5px 24px 15px'
    },
    latestEvent: {
        padding: '20px 10px'
    },
    eventTime: {
        color: '#b71c1c',
        padding: '5px 0',
        float: 'left'
    },
    interested: {
        color: '#b71c1c',
        float: 'right',
        display: 'inline-flex'
    },
    eventName: {
        clear: 'both',
        fontWeight: '400',
        fontSize: '20px',
        padding: '5px 0'
    },
    eventLocation: {
        padding: '5px 0',
    },
    actionBar: {
        margin: '5px 10px 15px'
    },
    buttonWrapper: {
        marginBottom: '10px'
    },
    addEventWrapper: {
        padding: '15px 24px'
    },
    displayFlex: {
        display: 'inline-flex'
    },
    notifyWrapper: {
        float: 'left',
        marginTop: '-50px',
        marginLeft: '5px',
    },
    actionWrapper: {
        float: 'right',
        marginTop: '-60px'
    },
    fabIcon: {
        margin: '5px'
    },
    tabs: {
        textTransform: 'none'
    },
    tabLabel: {
        textTransform: 'none'
    },
    singleTab: {
        marginLeft:'16px',
        padding: '7px 15px',
        minWidth: '120px',
        borderRadius: '20px',
        fontSize: '16px',
        color: '#223136',
        background: '#f4f6f7',
    },
    createCat: {
        padding: '7px 15px',
        minWidth: '110px',
        margin: '0 15px',
        borderRadius: '10%',
        fontSize: '13px',
        color: '#223136',
        background: '#bbddef',
    },
    headersWrapper: {
        padding: '10px 20px'
    },
    simpleUnderline: {
        paddingTop: '5px',
        clear: 'both',
        borderBottom: '1px solid #e5e5e5'
    },
    searchBoxBroad: {
        background: '#f4f6f7',
        margin: 'auto 5px',
        borderRadius: '10px',
        boxShadow: 'none',
        display: 'flex',
    },
    selectedButton: {
        backgroundColor: '#0077e0',
        color: '#ffffff !important',
        fontWeight: '600'
    },
    badge: {
        marginRight: '15px'
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(2),
    },
    channelLink: {
        color: '#0094ff',
        fontSize: '12px',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    }
});
