/**
 * @fileoverview gRPC-Web generated client stub for com.hamropatro.sociallayer.io
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');


var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');

var frontend$ticket$common$types_pb = require('./frontend-ticket-common-types_pb.js');
const proto = {};
proto.com = {};
proto.com.hamropatro = {};
proto.com.hamropatro.sociallayer = {};
proto.com.hamropatro.sociallayer.io = require('./frontend-community-ticket-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient =
    function (hostname, credentials, options) {
      if (!options) options = {};
      options['format'] = 'text';

      /**
       * @private @const {!grpc.web.GrpcWebClientBase} The client
       */
      this.client_ = new grpc.web.GrpcWebClientBase(options);

      /**
       * @private @const {string} The hostname
       */
      this.hostname_ = hostname;

      /**
       * @private @const {?Object} The credentials to be used to connect
       *    to the server
       */
      this.credentials_ = credentials;

      /**
       * @private @const {?Object} Options for the client
       */
      this.options_ = options;
    };


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient =
    function (hostname, credentials, options) {
      if (!options) options = {};
      options['format'] = 'text';

      /**
       * @private @const {!grpc.web.GrpcWebClientBase} The client
       */
      this.client_ = new grpc.web.GrpcWebClientBase(options);

      /**
       * @private @const {string} The hostname
       */
      this.hostname_ = hostname;

      /**
       * @private @const {?Object} The credentials to be used to connect
       *    to the server
       */
      this.credentials_ = credentials;

      /**
       * @private @const {?Object} Options for the client
       */
      this.options_ = options;
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.AddTicketRequest,
 *   !proto.com.hamropatro.sociallayer.io.AddTicketResponse>}
 */
const methodDescriptor_CommunityTicketService_AddTicket = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/AddTicket',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.AddTicketRequest,
    proto.com.hamropatro.sociallayer.io.AddTicketResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.AddTicketRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.AddTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.AddTicketRequest,
 *   !proto.com.hamropatro.sociallayer.io.AddTicketResponse>}
 */
const methodInfo_CommunityTicketService_AddTicket = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.AddTicketResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.AddTicketRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.AddTicketResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AddTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.AddTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.AddTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.addTicket =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/AddTicket',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_AddTicket,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AddTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.AddTicketResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.addTicket =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/AddTicket',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_AddTicket);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.UpdateTicketRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateTicketResponse>}
 */
const methodDescriptor_CommunityTicketService_UpdateTicket = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/UpdateTicket',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.UpdateTicketRequest,
    proto.com.hamropatro.sociallayer.io.UpdateTicketResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.UpdateTicketRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.UpdateTicketRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateTicketResponse>}
 */
const methodInfo_CommunityTicketService_UpdateTicket = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.UpdateTicketResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.UpdateTicketRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.UpdateTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.UpdateTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.updateTicket =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/UpdateTicket',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_UpdateTicket,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.UpdateTicketResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.updateTicket =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/UpdateTicket',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_UpdateTicket);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.RemoveTicketRequest,
 *   !proto.com.hamropatro.sociallayer.io.RemoveTicketResponse>}
 */
const methodDescriptor_CommunityTicketService_RemoveTicket = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/RemoveTicket',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.RemoveTicketRequest,
    proto.com.hamropatro.sociallayer.io.RemoveTicketResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.RemoveTicketRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.RemoveTicketRequest,
 *   !proto.com.hamropatro.sociallayer.io.RemoveTicketResponse>}
 */
const methodInfo_CommunityTicketService_RemoveTicket = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.RemoveTicketResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.RemoveTicketRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.RemoveTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.RemoveTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.removeTicket =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/RemoveTicket',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_RemoveTicket,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.RemoveTicketResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.removeTicket =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/RemoveTicket',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_RemoveTicket);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetTicketRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetTicketResponse>}
 */
const methodDescriptor_CommunityTicketService_GetTicket = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicket',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetTicketRequest,
    proto.com.hamropatro.sociallayer.io.GetTicketResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetTicketRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetTicketRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetTicketResponse>}
 */
const methodInfo_CommunityTicketService_GetTicket = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetTicketResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetTicketRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetTicketResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.getTicket =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicket',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetTicket,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetTicketResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.getTicket =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicket',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetTicket);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse>}
 */
const methodDescriptor_CommunityTicketService_GetTicketsForEvent = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicketsForEvent',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest,
    proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse>}
 */
const methodInfo_CommunityTicketService_GetTicketsForEvent = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.getTicketsForEvent =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicketsForEvent',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetTicketsForEvent,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.getTicketsForEvent =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicketsForEvent',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetTicketsForEvent);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetUserTicketRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetUserTicketResponse>}
 */
const methodDescriptor_CommunityTicketService_GetUserTickets = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/GetUserTickets',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetUserTicketRequest,
    proto.com.hamropatro.sociallayer.io.GetUserTicketResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetUserTicketRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetUserTicketRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetUserTicketResponse>}
 */
const methodInfo_CommunityTicketService_GetUserTickets = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetUserTicketResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetUserTicketRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetUserTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetUserTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.getUserTickets =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetUserTickets',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetUserTickets,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetUserTicketResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.getUserTickets =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetUserTickets',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetUserTickets);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest,
 *   !proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse>}
 */
const methodDescriptor_CommunityTicketService_GetUserTicketsForType = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/GetUserTicketsForType',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest,
    proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest,
 *   !proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse>}
 */
const methodInfo_CommunityTicketService_GetUserTicketsForType = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.getUserTicketsForType =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetUserTicketsForType',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetUserTicketsForType,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.getUserTicketsForType =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetUserTicketsForType',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetUserTicketsForType);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest,
 *   !proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse>}
 */
const methodDescriptor_CommunityTicketService_GetUserTicketsForTransactionTime = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/GetUserTicketsForTransactionTime',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest,
    proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest,
 *   !proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse>}
 */
const methodInfo_CommunityTicketService_GetUserTicketsForTransactionTime = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.getUserTicketsForTransactionTime =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetUserTicketsForTransactionTime',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetUserTicketsForTransactionTime,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.getUserTicketsForTransactionTime =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetUserTicketsForTransactionTime',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetUserTicketsForTransactionTime);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest,
 *   !proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse>}
 */
const methodDescriptor_CommunityTicketService_UpdateTicketStatus = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/UpdateTicketStatus',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest,
    proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest,
 *   !proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse>}
 */
const methodInfo_CommunityTicketService_UpdateTicketStatus = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.updateTicketStatus =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/UpdateTicketStatus',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_UpdateTicketStatus,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.updateTicketStatus =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/UpdateTicketStatus',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_UpdateTicketStatus);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse>}
 */
const methodDescriptor_CommunityTicketService_GetTicketPaymentsForEvent = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicketPaymentsForEvent',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest,
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse>}
 */
const methodInfo_CommunityTicketService_GetTicketPaymentsForEvent = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.getTicketPaymentsForEvent =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicketPaymentsForEvent',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetTicketPaymentsForEvent,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.getTicketPaymentsForEvent =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicketPaymentsForEvent',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetTicketPaymentsForEvent);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse>}
 */
const methodDescriptor_CommunityTicketService_GetTicketPaymentsForTicketType = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicketPaymentsForTicketType',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest,
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse>}
 */
const methodInfo_CommunityTicketService_GetTicketPaymentsForTicketType = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.getTicketPaymentsForTicketType =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicketPaymentsForTicketType',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetTicketPaymentsForTicketType,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.getTicketPaymentsForTicketType =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicketPaymentsForTicketType',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetTicketPaymentsForTicketType);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse>}
 */
const methodDescriptor_CommunityTicketService_GetTicketPayment = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicketPayment',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest,
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse>}
 */
const methodInfo_CommunityTicketService_GetTicketPayment = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.getTicketPayment =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicketPayment',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetTicketPayment,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.getTicketPayment =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTicketPayment',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetTicketPayment);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.TransactionDetailRequest,
 *   !proto.com.hamropatro.sociallayer.io.TransactionDetailResponse>}
 */
const methodDescriptor_CommunityTicketService_GetTransactionDetail = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTransactionDetail',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.TransactionDetailRequest,
    proto.com.hamropatro.sociallayer.io.TransactionDetailResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.TransactionDetailRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.TransactionDetailRequest,
 *   !proto.com.hamropatro.sociallayer.io.TransactionDetailResponse>}
 */
const methodInfo_CommunityTicketService_GetTransactionDetail = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.TransactionDetailResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.TransactionDetailRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.TransactionDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.TransactionDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.TransactionDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.getTransactionDetail =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTransactionDetail',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetTransactionDetail,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.TransactionDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.TransactionDetailResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.getTransactionDetail =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetTransactionDetail',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetTransactionDetail);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse>}
 */
const methodDescriptor_CommunityTicketService_GetGroupSalesReport = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/GetGroupSalesReport',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest,
    proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse>}
 */
const methodInfo_CommunityTicketService_GetGroupSalesReport = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.getGroupSalesReport =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetGroupSalesReport',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetGroupSalesReport,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.getGroupSalesReport =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetGroupSalesReport',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetGroupSalesReport);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse>}
 */
const methodDescriptor_CommunityTicketService_GetEventSalesReport = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityTicketService/GetEventSalesReport',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest,
    proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse>}
 */
const methodInfo_CommunityTicketService_GetEventSalesReport = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServiceClient.prototype.getEventSalesReport =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetEventSalesReport',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetEventSalesReport,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketServicePromiseClient.prototype.getEventSalesReport =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityTicketService/GetEventSalesReport',
          request,
          metadata || {},
          methodDescriptor_CommunityTicketService_GetEventSalesReport);
    };


module.exports = proto.com.hamropatro.sociallayer.io;

