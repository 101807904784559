import React, {Component, Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';
// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
// Material components
import {Badge, IconButton, Popover, Toolbar, Typography, withStyles} from '@material-ui/core';
// Material icons
import {
    ArrowBack as ArrowBackIcon,
    Close as CloseIcon,
    Input as InputIcon,
    Menu as MenuIcon,
    Notifications as NotificationIcon
} from '@material-ui/icons';
// Shared services
// Custom components
import {NotificationList} from './components';
// Component styles
import styles from './styles';
import Avatar from "@material-ui/core/Avatar";
import {isAdmin, isLogin, userInfo} from "../../../../configurations/global-config";
import menu from "../../../../data/menu";
import {
    GetNotificationFeedRequest,
    NotificationCountRequest
} from "../../../../generated/frontend-user-activity-service_pb";
import {getUserNotificationCount, getUserNotifications} from "../../../../grpcRequests/user-activity-request";
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {eventAnalyst, removeLocalStorage} from "../../../../configurations/config";
import UserConfirmationDialog from "../../../../components/UserConfirmationDialog";
import {getUsrNotificationCount} from "../../../../utils/converter/shortenString";
import Button from '@material-ui/core/Button';
import {CreateEverestObjectRequest, EverestObject, Value} from "../../../../generated/frontend-everestdb-service_pb";
import {writeToEverestDB} from "../../../../grpcRequests/everestDB-request";


class Topbar extends Component {

    signal = true;

    state = {
        loadingNotification: true,
        notifications: [],
        notificationsCount: 0,
        notificationsEl: null,
        userOptionsEl: null,
        openConfirmation: false,
        lastPostedTime:new Date().getTime()
    };


    handleBack = (backTo) => {
        const {history} = this.props;
        if(history.length > 2 && window.location.pathname.includes('/edit')) {
            history.goBack(-1);
        }
        else if (history.length > 2) {
            history.goBack();
        } else if (backTo)
            history.push(backTo);
    };

    componentDidMount() {
        this.signal = true;
        // this.writeLastFetchTimeToEDB();

        if (isLogin())
            this.getNotificationCount();
    }

    componentWillUnmount() {
        this.signal = false;
    }

    writeLastFetchTimeToEDB=(postID)=>{
        const req = new CreateEverestObjectRequest();

        let newEvObj = new EverestObject();

        let keyVal=new Value();
        keyVal.setStringValue('last_fetched');

        let valueVal =new Value();
        valueVal.setIntegerValue(postID);

        let propertiesMap = newEvObj.getPropertiesMap();

        propertiesMap.set('key', keyVal);
        propertiesMap.set('last_fetched', valueVal);

        newEvObj.setKey('last_fetched');

        req.setParent('buckets/users/'+userInfo().user_id+'/latest_notification_feed');
        req.setEverestObjectKey('last_fetched');
        req.setEverestObject(newEvObj);
        writeToEverestDB(req).then(function (response) {
        }).catch(function (error) {
        })
    };

    getNotificationCount = () => {
        let self = this;
        const req = new NotificationCountRequest();
        req.setIdentifierId(0);
        getUserNotificationCount(req, 3).then(function (response) {
            if (response) {
                self.setState({
                    notificationsCount: response.getCount().getValue()
                });
            }
        }).catch(function (error) {

        })
    };

    getNotifications = () => {
        let self = this;
        const req = new GetNotificationFeedRequest();
        req.setPageToken();
        req.setPageSize();
        getUserNotifications(req, 3).then(function (response) {
            self.setState({
                loadingNotification: false
            });
            if (response) {
                const notifications = response.getNotificationfeedList();
                if (notifications && notifications.length > 0) {
                    // localStorage.setItem(globalConfig.appId + "_LastPostTime", notifications[0].getTimestamp());
                    self.setState({
                        notifications
                    });
                    self.writeLastFetchTimeToEDB(notifications[0].getTimestamp());
                }
            }
        }).catch(function (error) {
            self.setState({
                loadingNotification: false

            });
        })
    };

    handleSignOut = () => {
        let self= this;
        removeLocalStorage().then(function () {
            const {history} = self.props;
            history.push('/login');
        }).catch(function () {
        });
    };

    handleShowNotifications = event => {
        this.setState({
            notificationsEl: event.currentTarget,
            notificationsCount: 0
        });
        this.getNotifications();
    };

    handleCloseNotifications = () => {
        this.setState({
            notificationsEl: null,
            notificationsCount: 0
        });
    };

    goToLink = (notificationItem) => {
        let redirectLink = notificationItem.getMetadataMap().get("parent_deeplink").split('hamropatro://communities/')[1].split('/events/')[1] ? notificationItem.getMetadataMap().get("parent_deeplink").split('hamropatro://communities/')[1].split('/events/')[1].split('?')[0] ? '/' + notificationItem.getDeeplink().split('hamropatro://communities/')[1].split('?')[0] + `?eventId=${notificationItem.getMetadataMap().get("parent_deeplink").split('hamropatro://communities/')[1].split('/events/')[1].split('?')[0]}` : `/${notificationItem.getDeeplink().split('hamropatro://communities/')[1].split('?')[0]}` : `/${notificationItem.getDeeplink().split('hamropatro://communities/')[1].split('?')[0]}`
        const {history} = this.props;
        history.push(redirectLink);
        this.handleCloseNotifications();
    };

    handleShowUserOptions = event => {
        this.setState({
            userOptionsEl: event.currentTarget,
        });
    };

    handleCloseUserOptions = () => {
        this.setState({
            userOptionsEl: null,
        });
    };

    openConfirmation = () => {
        eventAnalyst('logout', 'logout_click', 'logout click', 'logout-click-success');
        this.setState({openConfirmation: true});
    };

    goToMyTickets = () => {
        const {history} = this.props;
        history.push('/tickets');
    };

    getUserResponseFromChild = (clickedButton, key) => {
        this.setState({openConfirmation: false});
        if (clickedButton === "Logout") {
            this.handleSignOut();
        }
    };

    render() {
        const {
            classes,
            className,
            title,
            isMobile,
            isSidebarOpen,
            onToggleSidebar,
            onBack, linkTo
        } = this.props;

        const {notifications, notificationsCount, notificationsEl, loadingNotification, userOptionsEl, openConfirmation} = this.state;
        const rootClassName = classNames(classes.root, className);
        const showNotifications = Boolean(notificationsEl);
        const showUserOptions = Boolean(userOptionsEl);
        let iconValue;
        const {history} = this.props;
        let self = this;
        var match = false;
        menu.forEach(function (menuName) {
            if (self.props.match.path === menuName.to) {
                match = true
            }
        });
        if ((onBack) && !match) {
            iconValue = <IconButton size="small" style={{paddingLeft: '5px'}} onClick={() => this.handleBack(onBack)}>
                <ArrowBackIcon/> </IconButton>
        } else iconValue = <div></div>;

        return (
            <Fragment>
                <div className={rootClassName}>
                    <Toolbar className={classes.toolbar}>
                        <div className={classes.flex}>
                            {/*{iconValue}*/}
                            {isMobile ? (
                                <IconButton
                                    className={classes.menuButton}
                                    onClick={onToggleSidebar}
                                    variant="text"
                                    size="small"
                                >
                                    {isSidebarOpen ? <CloseIcon/> : <MenuIcon/>}
                                </IconButton>
                            ) : (<span></span>)}
                            <IconButton
                                className={classes.menuButton}
                                variant="text"
                                size="small"
                            >
                                {iconValue}
                            </IconButton>
                        </div>
                        {linkTo && linkTo !== '' && typeof linkTo !== 'undefined' ? (
                            <Link to={linkTo}>
                                <Typography
                                    className={classes.titleTopBar}
                                    variant="h6"
                                    style={{fontWeight: 600}}
                                >
                                    {title}
                                </Typography>
                            </Link>
                        ) : (
                            <Typography
                                className={classes.title}
                                variant="h6"
                                style={{fontWeight: 600}}
                            >
                                {title}
                            </Typography>
                        )}
                        <div className={classes.notificationsButton}>
                        </div>
                        {
                            userInfo().displayName ? (
                                <div style={{display: 'inline-flex'}}>
                                    {/*{localStorage.getItem(globalConfig.appId + "_LastPostTime") ? (*/}
                                    <Badge className={classes.firstNotification}
                                           badgeContent={getUsrNotificationCount(notificationsCount)}
                                           color="primary" style={{fontSize: '12px'}}
                                           title="Notifications">
                                        <IconButton
                                            className={classes.signOutButton}
                                            title="Notifications"
                                            onClick={this.handleShowNotifications}
                                        >
                                            <NotificationIcon/>
                                        </IconButton>
                                    </Badge>

                                    {/*<Badge*/}
                                    {/*    color="primary"*/}
                                    {/*    variant="dot"*/}
                                    {/*    className={classes.firstNotification}*/}
                                    {/*>*/}
                                    {/*    <IconButton*/}
                                    {/*        size="medium"*/}
                                    {/*        className={classes.signOutButton}*/}
                                    {/*        title="Notifications"*/}
                                    {/*        onClick={this.handleShowNotifications}*/}
                                    {/*    >*/}
                                    {/*        <NotificationIcon/>*/}
                                    {/*    </IconButton>*/}
                                    {/*</Badge>*/}

                                    <IconButton
                                        size="small"
                                        title="User Profile"
                                        className={classes.signOutButton}
                                        onClick={this.handleShowUserOptions}
                                    >
                                        <Avatar alt={userInfo().displayName}
                                                style={{
                                                    backgroundImage: `url('${userInfo().photo_url}')`,
                                                    backgroundRepeat: 'round'
                                                }}
                                                className={classes.loggedInUserAvatar}/>
                                    </IconButton>
                                </div>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={() => window.location.href = "/login?redirectURL=" + window.location.pathname}
                                    className={classes.button}
                                >
                                    <b> LOG IN </b>
                                </Button>
                            )
                        }
                    </Toolbar>
                </div>
                <Popover
                    anchorEl={notificationsEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    onClose={this.handleCloseNotifications}
                    open={showNotifications}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <NotificationList
                        loadingNotification={loadingNotification}
                        notifications={notifications}
                        onSelect={this.handleCloseNotifications}
                        onGoto={this.goToLink}
                    />
                </Popover>
                <Popover
                    anchorEl={userOptionsEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    onClose={this.handleCloseUserOptions}
                    open={showUserOptions}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Paper className={classes.paperRoot}>
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItem>
                                <ListItemIcon>
                                    <Avatar alt='User Profile'
                                            style={{
                                                backgroundImage: `url(${userInfo().photo_url})`,
                                                backgroundRepeat: 'round',
                                                backgroundColor: '#e5e5e5',
                                                width: '25px',
                                                height: '25px',
                                            }}/>
                                </ListItemIcon>
                                <ListItemText title={`${isAdmin() ? 'Login as Super-Admin' : ''}`}
                                              primary={userInfo().displayName}
                                              secondary={`${userInfo().email ? userInfo().email : ''}${isAdmin() ? ' (God)' : ''}`}/>
                            </ListItem>
                            <ListItem button onClick={this.goToMyTickets}>
                                <ListItemIcon>
                                    <Avatar alt='ticket Name'
                                            style={{
                                                backgroundImage: `url('/images/icons/tickets.png')`,
                                                backgroundRepeat: 'round',
                                                backgroundColor: 'unset',
                                                width: '25px',
                                                height: '25px',
                                            }}/>
                                </ListItemIcon>
                                <ListItemText primary="My Tickets"/>
                            </ListItem>
                            <ListItem button onClick={this.openConfirmation}>
                                <ListItemIcon>
                                    <InputIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Logout"/>
                            </ListItem>
                        </List>
                    </Paper>
                </Popover>
                {openConfirmation ? (
                    <UserConfirmationDialog
                        openDialog={openConfirmation}
                        dialogHeader="Logout"
                        dialogContent={"Are you sure want to logout ?"}
                        button1="Cancel" button2="Logout"
                        sendUserResponse={this.getUserResponseFromChild}
                    />
                ) : (<div></div>)}
            </Fragment>
        );
    }
}

Topbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isSidebarOpen: PropTypes.bool,
    onToggleSidebar: PropTypes.func,
    title: PropTypes.string,
    linkTo: PropTypes.string,
};

export default compose(
    withRouter,
    withStyles(styles)
)(Topbar);
