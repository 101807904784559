export default theme => ({
    imageBox: {
        margin: '10px 0'
    },
    uploadText: {
        margin: '5px 0'
    },
    imageWrapperBefore: {
        background: '#e5e5e5',
        textAlign: 'center',
        width: '100%',
        lineHeight: 9,
        height: '100px',
        overflow: 'hidden',
        borderRadius: '5px',
        cursor: 'pointer',
        border: '1px dotted #e5e5e59e',
        position: 'relative',
    },
    imageWrapper: {
        overflow: 'hidden',
        borderRadius: '5px',
        cursor: 'pointer',
        position: 'relative',
    },
    imageToUpload: {
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        margin: 'auto',
    }
});