/**
 * @fileoverview gRPC-Web generated client stub for com.hamropatro.sociallayer.io
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');


var frontend$ticket$common$types_pb = require('./frontend-ticket-common-types_pb.js')
const proto = {};
proto.com = {};
proto.com.hamropatro = {};
proto.com.hamropatro.sociallayer = {};
proto.com.hamropatro.sociallayer.io = require('./frontend-community-ticket-payment-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketPaymentServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketPaymentServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.PaymentRequest,
 *   !proto.com.hamropatro.sociallayer.io.PaymentResponse>}
 */
const methodDescriptor_CommunityTicketPaymentService_MakePayment = new grpc.web.MethodDescriptor(
  '/com.hamropatro.sociallayer.io.CommunityTicketPaymentService/MakePayment',
  grpc.web.MethodType.UNARY,
  proto.com.hamropatro.sociallayer.io.PaymentRequest,
  proto.com.hamropatro.sociallayer.io.PaymentResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.PaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.PaymentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.PaymentRequest,
 *   !proto.com.hamropatro.sociallayer.io.PaymentResponse>}
 */
const methodInfo_CommunityTicketPaymentService_MakePayment = new grpc.web.AbstractClientBase.MethodInfo(
  proto.com.hamropatro.sociallayer.io.PaymentResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.PaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.PaymentResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.PaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.PaymentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.PaymentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketPaymentServiceClient.prototype.makePayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.CommunityTicketPaymentService/MakePayment',
      request,
      metadata || {},
      methodDescriptor_CommunityTicketPaymentService_MakePayment,
      callback);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.PaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.PaymentResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityTicketPaymentServicePromiseClient.prototype.makePayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.CommunityTicketPaymentService/MakePayment',
      request,
      metadata || {},
      methodDescriptor_CommunityTicketPaymentService_MakePayment);
};


module.exports = proto.com.hamropatro.sociallayer.io;

