import React, {Component} from "react";
import PropTypes from "prop-types";
import {Grid, Typography, withStyles, withWidth} from "@material-ui/core";
import styles from "./styles";
import compose from "recompose/compose";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import axios from 'axios';
import CountryDetails from "./CountryDetails";

const ApiURL = 'https://sag.dallas-hamrostack.com/participate-stat';

class SAG extends Component {
    state = {
        loadingTable: true,
        tableData: [],
        errMessage: '',
        openCountryDetails: false
    };

    componentDidMount() {
        let self = this;
        axios.get(ApiURL).then(function (response) {
            const data = response.data.countries;
            const loadingTable = false;
            self.setState({
                tableData: data, loadingTable
            });

            //set popUpForSag
            let searchURL = window.location.search ? (new URLSearchParams(window.location.search).get('country')) : '';
            if (searchURL)
                self.openCountryDetails('', searchURL.toUpperCase());

        }).catch(function (error) {
            const loadingTable = false;
            self.setState({
                loadingTable, errMessage: error.message
            })
        });
    }

    openCountryDetails = (id, name) => {
        this.setState({
            openCountryDetails: true,
            countryId: id,
            countryName: name,
        })
    };
    closeResponse = (closeStatus) => {
        this.setState({openCountryDetails: false});
    };

    render() {
        const {classes, width} = this.props;
        const isMobile = ['xs'].includes(width);
        const {loadingTable, tableData, errMessage, openCountryDetails, countryId, countryName} = this.state;
        return (
            <div>
                {!loadingTable ? (
                    <div>
                        {tableData.length > 0 ? (
                            <div>
                                <div>
                                    <Grid
                                        container
                                        spacing={isMobile ? 2 : 3}
                                        style={{
                                            background: '#e9f2fb',
                                            padding: '15px 0px',
                                            minHeight: '40px',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <Grid item xs={4} md={4} sm={4} lg={4}>
                                            <Typography component="div"
                                                        className={isMobile ? classes.tableHeadTextMobile : classes.tableHeadText}>
                                                &nbsp;&nbsp;Country
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} md={2} sm={2} lg={2}>
                                            <Typography component="div"
                                                        className={isMobile ? classes.tableHeadTextMobile : classes.tableHeadText}
                                                        style={{textAlign: 'center'}}>
                                                Gold
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} md={2} sm={2} lg={2}>
                                            <Typography component="div"
                                                        className={isMobile ? classes.tableHeadTextMobile : classes.tableHeadText}
                                                        style={{textAlign: 'center'}}>
                                                Silver
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} md={2} sm={2} lg={2}>
                                            <Typography component="div"
                                                        className={isMobile ? classes.tableHeadTextMobile : classes.tableHeadText}
                                                        style={{textAlign: 'center'}}>
                                                Bronze
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} md={2} sm={2} lg={2}>
                                            <Typography component="div" style={{textAlign: 'center'}}
                                                        className={isMobile ? classes.tableHeadTextMobile : classes.tableHeadText}>
                                                Total
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <div>
                                        {tableData.map((table, index) => (
                                            <Grid
                                                container
                                                spacing={isMobile ? 2 : 3}
                                                className={isMobile ? classes.tableDataWrapperMobile : classes.tableDataWrapper}
                                                onClick={() => this.openCountryDetails(index, table.name)}
                                            >
                                                <Grid item xs={4} md={4} sm={4} lg={4}>
                                                    <Typography component="div" className={classes.countryWrapper}>
                                                <span className={classes.tableNumber}>
                                                    <Avatar alt='Country Flag'
                                                            title="Country Flag"
                                                            className={classes.countryFlag}
                                                            style={{
                                                                backgroundImage: `url('${table.flagUrl}')`,
                                                            }}/>
                                                </span>&nbsp;&nbsp;&nbsp;
                                                        {!isMobile ? (
                                                            <span
                                                                className={classes.countryName}>{table.name}</span>) : null}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} md={2} sm={2} lg={2}
                                                      className={classes.tableItems}>
                                                    <Typography component="div"
                                                                className={classes.tableNumber}>
                                                        {table.gold}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} md={2} sm={2} lg={2}
                                                      className={classes.tableItems}>
                                                    <Typography component="div"
                                                                className={classes.tableNumber}>
                                                        {table.silver}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} md={2} sm={2} lg={2}
                                                      className={classes.tableItems}>
                                                    <Typography component="div"
                                                                className={classes.tableNumber}>
                                                        {table.bronze}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={2} md={2} sm={2} lg={2}
                                                      className={classes.tableItems}>
                                                    <Typography component="div"
                                                                className={classes.tableNumber}>
                                                        {table.total}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (<div><span>{errMessage}</span></div>)}
                    </div>
                ) : (
                    <div className={classes.loadingTable}>
                        <div><CircularProgress size={20}/></div>
                        <Typography component="div" variant="body2">
                            Please Wait
                        </Typography>
                    </div>
                )}
                {/*    countryDetails*/}
                {openCountryDetails ? (
                    <CountryDetails open={openCountryDetails} countryName={countryName} tableData={tableData[countryId]}
                                    sendCloseResponse={this.closeResponse}/>
                ) : null}
            </div>
        )
    }
}

SAG.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withWidth())(SAG);

