/**
 * @fileoverview gRPC-Web generated client stub for com.hamropatro.sociallayer.io
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');


var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
const proto = {};
proto.com = {};
proto.com.hamropatro = {};
proto.com.hamropatro.sociallayer = {};
proto.com.hamropatro.sociallayer.io = require('./frontend-reply-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.ReplyServiceClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.ReplyServicePromiseClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest,
 *   !proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse>}
 */
const methodDescriptor_ReplyService_ReplyOnComment = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.ReplyService/ReplyOnComment',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest,
    proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest,
 *   !proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse>}
 */
const methodInfo_ReplyService_ReplyOnComment = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.ReplyServiceClient.prototype.replyOnComment =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReplyService/ReplyOnComment',
            request,
            metadata || {},
            methodDescriptor_ReplyService_ReplyOnComment,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.ReplyServicePromiseClient.prototype.replyOnComment =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReplyService/ReplyOnComment',
            request,
            metadata || {},
            methodDescriptor_ReplyService_ReplyOnComment);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetReplyRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetReplyResponse>}
 */
const methodDescriptor_ReplyService_GetReplyDetail = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.ReplyService/GetReplyDetail',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetReplyRequest,
    proto.com.hamropatro.sociallayer.io.GetReplyResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetReplyRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetReplyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetReplyRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetReplyResponse>}
 */
const methodInfo_ReplyService_GetReplyDetail = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetReplyResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetReplyRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetReplyResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetReplyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetReplyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetReplyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.ReplyServiceClient.prototype.getReplyDetail =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReplyService/GetReplyDetail',
            request,
            metadata || {},
            methodDescriptor_ReplyService_GetReplyDetail,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetReplyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetReplyResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.ReplyServicePromiseClient.prototype.getReplyDetail =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReplyService/GetReplyDetail',
            request,
            metadata || {},
            methodDescriptor_ReplyService_GetReplyDetail);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest,
 *   !proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse>}
 */
const methodDescriptor_ReplyService_ReactOnCommentReply = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.ReplyService/ReactOnCommentReply',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest,
    proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest,
 *   !proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse>}
 */
const methodInfo_ReplyService_ReactOnCommentReply = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.ReplyServiceClient.prototype.reactOnCommentReply =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReplyService/ReactOnCommentReply',
            request,
            metadata || {},
            methodDescriptor_ReplyService_ReactOnCommentReply,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.ReplyServicePromiseClient.prototype.reactOnCommentReply =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReplyService/ReactOnCommentReply',
            request,
            metadata || {},
            methodDescriptor_ReplyService_ReactOnCommentReply);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest,
 *   !proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse>}
 */
const methodDescriptor_ReplyService_DeleteCommentReply = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.ReplyService/DeleteCommentReply',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest,
    proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest,
 *   !proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse>}
 */
const methodInfo_ReplyService_DeleteCommentReply = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.ReplyServiceClient.prototype.deleteCommentReply =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReplyService/DeleteCommentReply',
            request,
            metadata || {},
            methodDescriptor_ReplyService_DeleteCommentReply,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.ReplyServicePromiseClient.prototype.deleteCommentReply =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReplyService/DeleteCommentReply',
            request,
            metadata || {},
            methodDescriptor_ReplyService_DeleteCommentReply);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest,
 *   !proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse>}
 */
const methodDescriptor_ReplyService_ListCommentReplies = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.ReplyService/ListCommentReplies',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest,
    proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest,
 *   !proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse>}
 */
const methodInfo_ReplyService_ListCommentReplies = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.ReplyServiceClient.prototype.listCommentReplies =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReplyService/ListCommentReplies',
            request,
            metadata || {},
            methodDescriptor_ReplyService_ListCommentReplies,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.ReplyServicePromiseClient.prototype.listCommentReplies =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReplyService/ListCommentReplies',
            request,
            metadata || {},
            methodDescriptor_ReplyService_ListCommentReplies);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.SpamReplyRequest,
 *   !proto.com.hamropatro.sociallayer.io.SpamReplyResponse>}
 */
const methodDescriptor_ReplyService_SpamReply = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.ReplyService/SpamReply',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.SpamReplyRequest,
    proto.com.hamropatro.sociallayer.io.SpamReplyResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.SpamReplyRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.SpamReplyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.SpamReplyRequest,
 *   !proto.com.hamropatro.sociallayer.io.SpamReplyResponse>}
 */
const methodInfo_ReplyService_SpamReply = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.SpamReplyResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.SpamReplyRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.SpamReplyResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.SpamReplyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.SpamReplyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.SpamReplyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.ReplyServiceClient.prototype.spamReply =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReplyService/SpamReply',
            request,
            metadata || {},
            methodDescriptor_ReplyService_SpamReply,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.SpamReplyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.SpamReplyResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.ReplyServicePromiseClient.prototype.spamReply =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ReplyService/SpamReply',
            request,
            metadata || {},
            methodDescriptor_ReplyService_SpamReply);
    };


module.exports = proto.com.hamropatro.sociallayer.io;

