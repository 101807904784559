var nepaliYearList = {
    "year": {
        '1978': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '1979': [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '1980': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '1981': [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
        '1982': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '1983': [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '1984': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '1985': [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
        '1986': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '1987': [0, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '1988': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '1989': [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '1990': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '1991': [0, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '1992': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
        '1993': [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '1994': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '1995': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
        '1996': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
        '1997': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '1998': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '1999': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2000': [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365],
        '2001': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2002': [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '2003': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2004': [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365],
        '2005': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2006': [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '2007': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2008': [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31, 365],
        '2009': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2010': [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '2011': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2012': [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
        '2013': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2014': [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '2015': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2016': [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
        '2017': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2018': [0, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '2019': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
        '2020': [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2021': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2022': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
        '2023': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
        '2024': [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2025': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2026': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2027': [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365],
        '2028': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2029': [0, 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30, 365],
        '2030': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2031': [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365],
        '2032': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2033': [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '2034': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2035': [0, 30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31, 365],
        '2036': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2037': [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '2038': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2039': [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
        '2040': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2041': [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '2042': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2043': [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
        '2044': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2045': [0, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '2046': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2047': [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2048': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2049': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
        '2050': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
        '2051': [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2052': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2053': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
        '2054': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
        '2055': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2056': [0, 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30, 365],
        '2057': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2058': [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 365],
        '2059': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2060': [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '2061': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2062': [0, 30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31, 365],
        '2063': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2064': [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '2065': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2066': [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31, 365],
        '2067': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2068': [0, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '2069': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2070': [0, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
        '2071': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2072': [0, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
        '2073': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
        '2074': [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2075': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2076': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
        '2077': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
        '2078': [0, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2079': [0, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
        '2080': [0, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
        '2081': [0, 31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30, 366],
        '2082': [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365],
        '2083': [0, 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30, 365],
        '2084': [0, 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30, 365],
        '2085': [0, 31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30, 366],
        '2086': [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365],
        '2087': [0, 31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30, 366],
        '2088': [0, 30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30, 365],
        '2089': [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365],
        '2090': [0, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365],
        '2091': [0, 31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30, 366],
        '2092': [0, 31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30, 366]
    },
    "month": ["", "वैशाख", "जेठ", "असार", "साउन", "भदौ", "असोज", "कार्तिक", "मंसिर", "पुष", "माघ", "फागुन", "चैत"],
    'nepMonthEng': ['', 'Baisakh', 'Jestha', 'Ashadh', 'Shrawan', 'Bhadra', 'Ashwin', 'Kartik', 'Mangsir', 'Paush', 'Mangh', 'Falgun', 'Chaitra'],
    "30": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    "31": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    "32": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
    "28": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28],
    "29": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
    "nepDay": ['आइतवार', 'सोमवार', 'मंगलवार', 'बुधवार', 'बिहिवार', 'शुक्रवार', 'शनिवार'],
    "engMonthsName": ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
};
export default nepaliYearList;
