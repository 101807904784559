export default theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.border}`,
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        height: '64px',
        zIndex: theme.zIndex.appBar
    },
    toolbar: {
        minHeight: 'auto',
        width: '100%',
        padding: '0 10px'
    },
    title: {
        marginLeft: theme.spacing(),
        fontSize: '15px'
    },
    titleTopBar: {
        marginLeft: theme.spacing(),
        fontSize: '15px',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    menuButton: {
        marginLeft: '-4px',
        padding: '2px'
    },
    arrowBack: {
        padding: '2px'
    },
    notificationsButton: {
        marginLeft: 'auto'
    },
    badge: {
        // marginTop: '5px',
        // marginRight: '5px'
    },
    firstNotification: {
        marginRight: '15px'
    },
    signOutButton: {
        padding: '0px'
        // marginLeft: theme.spacing()
    },
    loggedInUserAvatar: {
        height: 25,
        width: 25,
    },
    flex: {
        display: 'flex'
    },
    paperRoot: {
        width: '300px',
        maxWidth: '100%',
        padding: '5px 0',
        maxHeight: '350px',
        overflow: 'auto'
    }

});
