export default theme => ({
    buyTicketsInfo: {
        margin: '10px 0px 0px',
        float: 'right',
        background: '#0a62c5',
        padding: '2px 10px',
        borderRadius: '5px 0px 0px 5px',
        fontSize: '12px',
        width: 'fit-content',
        color: '#ffffff'
    },
    freeTicketsInfo: {
        margin: '10px 0px 0px',
        float: 'right',
        background: '#099c17',
        padding: '2px 10px',
        borderRadius: '5px 0px 0px 5px',
        fontSize: '12px',
        width: 'fit-content',
        color: '#ffffff'
    },
    eventStsInfo: {
        margin: '10px 0px 0px',
        float: 'right',
        background: '#f67718',
        padding: '2px 10px',
        borderRadius: '5px 0px 0px 5px',
        fontSize: '12px',
        width: 'fit-content',
        color: '#ffffff'
    },
    publicIcon: {
        float: 'right',
        padding: '1px 0',
    },
    privateIcon: {
        float: 'right',
        padding: '1px 0'
    }
});
