import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styles
import styles from './styles';
import {checkIfLogin} from "../../configurations/global-config";
import {pageViewAnalyst} from "../../configurations/config";

class Settings extends Component {
    componentDidMount() {
        pageViewAnalyst('Setting', this.props.location.pathname);
        checkIfLogin(this.props.location.pathname);
    }

    render() {
        const {classes} = this.props;

        return (
            <DashboardLayout title="Settings">
                <div className={classes.root}>

                </div>
            </DashboardLayout>
        );
    }
}

Settings.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Settings);
