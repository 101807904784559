import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";
import styles from "./styles";
import UserConfirmationDialog from "../../../components/UserConfirmationDialog";
import {GetGroupRequest} from "../../../generated/frontend-community-group-service_pb";
import {getGroupDetails} from "../../../grpcRequests/groups-request";
import usedString, {
    getGroupTypeStringInSentence,
    getRoleString,
    getStatusString
} from "../../../utils/mapper/enumToStringMapper";
import {checkIfLogin, forceTokenRefresh, isLogin} from "../../../configurations/global-config";
import globalConfig from "../../../configurations/configVariables";
import {DirectJoinRequest} from "../../../generated/frontend-community-members-service_grpc_web_pb";
import {directJoinGroup} from "../../../grpcRequests/members-request";
import {eventAnalyst, pageViewAnalyst} from "../../../configurations/config";
import PleaseWait from "../../../components/PleaseWait";
import About from "../GroupDetails/components/About";
import {Dashboard as DashboardLayout} from 'layouts';
import {memberCountValue} from "../../../utils/converter/shortenString";
import {metaDataSet} from "../../../utils/MetaData";

class Index extends Component {
    state = {
        openConfirmation: false,
        group: null,
        fetchedGroup: false,
        fetchingMsg: null,
        userRole: null,
        userStatus: null,
        isAlreadyMember: false
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        pageViewAnalyst('Direct Join', this.props.location.pathname);
        this.fetchGroupDetails();
    }

    fetchGroupDetails() {
        let self = this;
        const req = new GetGroupRequest();
        req.setGroupId(this.props.match.params.groupId);
        this.setState({fetchingMsg: 'Please Wait..'});
        getGroupDetails(req, 3).then(function (response) {
            const group = response.getGroup();
            metaDataSet(group.getName(), group.getDesc(), window.location.origin + '/_admin/c/' + self.props.match.params.groupId + '/join', group.getCoverimage());
            const userRole = group.getRole();
            const userStatus = group.getStatus();
            const fetchedGroup = true;
            self.setState({
                fetchedGroup,
                group,
                userStatus,
                userRole,
            });
            if (getRoleString(userRole) === usedString.DEFAULT && getStatusString(userStatus) === usedString.EMPTY)
                if (isLogin() && sessionStorage.getItem('directJoin')) {
                    self.directJoin();
                } else {
                    sessionStorage.removeItem('directJoin');
                    self.openConfirmation();
                }
            else {
                sessionStorage.removeItem('directJoin');
                self.setState({isAlreadyMember: true});
                self.goToGroupDirect(self.props.match.params.groupId);
            }
        }).catch(function (error) {
            self.setState({fetchedGroup: false, fetchingMsg: 'Something went wrong'});
        })
    }

    openConfirmation = () => {
        this.setState({openConfirmation: true});
    };

    getUserResponseFromChild = (clickedButton, key) => {
        if (clickedButton === 'Accept') {
            if (!isLogin()) {
                sessionStorage.setItem('directJoin', 'accept' + ':' + this.props.match.params.groupId);
                checkIfLogin(this.props.location.pathname);
            }
            this.directJoin();
        } else window.location.href = "/home" + forceTokenRefresh();
    };

    directJoin = () => {
        let self = this;
        // const link = window.location.href + '?appId='+globalConfig.appId;
        const link = 'https://we.hamropatro.com/_admin/c/' + this.props.match.params.groupId + '/join?appId=' + globalConfig.appId;
        const req = new DirectJoinRequest();
        req.setLink(link);
        req.setGroupId(this.props.match.params.groupId);
        directJoinGroup(req).then(function (response) {
            if (response.getSuccess()) {
                eventAnalyst('directJoin_link', self.props.match.params.groupId + '_direct_link_accept', self.props.match.params.groupId + ' link join success', self.state.groupId + '-link-join-success');
                self.goToGroup(self.props.match.params.groupId)
            }
        }).catch(function (error) {
            eventAnalyst('directJoin_link', self.props.match.params.groupId + '_direct_link_accept', self.props.match.params.groupId + ' link join fail', self.state.groupId + '-link-join-fail');
        })
    };

    goToGroup = (groupId) => {
        window.location.href = '/c/' + groupId + forceTokenRefresh();
    };

    goToGroupDirect = (groupId) => {
        const {history} = this.props;
        history.push('/c/' + groupId + forceTokenRefresh());
    };

    render() {
        const {classes} = this.props;
        const {openConfirmation, fetchedGroup, isAlreadyMember, group, fetchingMsg} = this.state;
        return (
            <div>
                {fetchedGroup ? (
                    <div>
                        {isAlreadyMember ? (
                            <PleaseWait msg="Already a member. Redirecting.... "/>
                        ) : <div>
                            {group ? (
                                <div>
                                    <div>
                                        {openConfirmation ? (
                                            <UserConfirmationDialog
                                                openDialog={openConfirmation} dialogHeader="Community Join Invitation"
                                                postId="124"
                                                dialogContent={"You are invited to join the Community"} button1="Reject"
                                                button2="Accept"
                                                toDeleteContent={<div className={classes.sharableLinkWrapper}>
                                                <span
                                                    className={classes.sharableText}> {group.getName()}</span>
                                                </div>}
                                                sendUserResponse={this.getUserResponseFromChild}
                                            />
                                        ) : (<div></div>)}
                                    </div>
                                    <DashboardLayout title='Community Join' onBack="/home" linkTo='/home'>
                                        <div>
                                            <div style={{background: 'aliceblue', opacity: 0.5}}>
                                                <div style={{
                                                    paddingTop: '56.25%',
                                                    position: 'relative',
                                                    width: '100%',
                                                    overflow: 'hidden'
                                                }}>
                                                    <img
                                                        alt="Community_Image"
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            position: 'absolute',
                                                            top: '0',
                                                            left: '0',
                                                            bottom: '0',
                                                            right: '0',
                                                            objectFit: 'cover'
                                                        }}
                                                        src={group.getCoverimage() ? group.getCoverimage() : '/images/defaultCover.png'}
                                                    />
                                                </div>
                                                <br/>
                                                <div style={{textAlign: 'center', padding: '15px 10px'}}>
                                                    <Typography
                                                        className={classes.groupName}
                                                        variant="h5"
                                                        style={{fontWeight: 600}}
                                                    >
                                                        {group.getName()}<br/>
                                                    </Typography>
                                                    <Typography
                                                        className={classes.groupTypeMember}
                                                        variant="subtitle1"
                                                    >
                                                        <span>{getGroupTypeStringInSentence(group.getGrouptype())} Community </span>| <span
                                                        title='Community members'
                                                        className={classes.followerCount}>{memberCountValue(group.getFollowerscount())} Members</span>
                                                    </Typography>
                                                </div>
                                                <About group={group}
                                                       groupType={getGroupTypeStringInSentence(group.getGrouptype())}
                                                       history={this.props}/>
                                            </div>
                                        </div>
                                    </DashboardLayout>
                                </div>
                            ) : (<div>Something went wrong</div>)}
                        </div>}
                    </div>
                ) : (
                    <div>
                        <PleaseWait msg="Getting ready.."/>
                    </div>
                )}
            </div>
        )
    }
}

Index.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Index);