import React, {Component} from 'react';
import {Link, NavLink, Router} from 'react-router-dom';
// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
// Material components
import {List, ListItem, ListItemIcon, ListItemText, Typography, withStyles} from '@material-ui/core';
// Material icons
import {
    AccountBox as AccountBoxIcon,
    CalendarToday as CalendarIcon,
    EventNote as EventIcon,
    Explore as ExploreIcon,
    Home as HomeIcon,
    Message as MessageIcon,
    Notifications as NotificationIcon,
    People as PeopleIcon,
    Public as PublicIcon,
    Settings as SettingsIcon
} from '@material-ui/icons';
// Component styles
import styles from './styles';
import menu from '../../../../data/menu';
import {eventAnalyst} from "../../../../configurations/config";
import Avatar from "@material-ui/core/Avatar";

const icons = {
    home: {
        icon: <HomeIcon/>
    },
    message: {
        icon: <MessageIcon/>
    },
    notification: {
        icon: <NotificationIcon/>
    },
    people: {
        icon: <PeopleIcon/>
    },
    public: {
        icon: <PublicIcon/>
    },
    setting: {
        icon: <SettingsIcon/>
    },
    event: {
        icon: <EventIcon/>
    },
    account: {
        icon: <AccountBoxIcon/>
    },
    calendar: {
        icon: <CalendarIcon/>
    },
    explore: {
        icon: <ExploreIcon/>
    }
};

class Sidebar extends Component {
    state = {
        menuItems: [],
        themeColor:localStorage.getItem('mode') ? localStorage.getItem('mode') : 'light'
    };

    getMenuItems() {
        const menuItems = menu;
        this.setState({
            menuItems
        });
    }

    componentDidMount() {
        this.getMenuItems();
    }

    toggleTheme = () => {
        if (this.state.themeColor === 'light') {
            localStorage.setItem('mode','dark');
            window.location.reload();
        } else {
            localStorage.setItem('mode','light');
            window.location.reload();
        }
    };

    render() {
        const {classes, className, isMobile} = this.props;
        const {menuItems,themeColor} = this.state;
        const rootClassName = classNames(classes.root, className);

        return (
            <nav className={rootClassName}>
                <Link to="/" onClick={() => eventAnalyst('sidebar', 'logo_clicked', 'Logo Clicked', 'logo-clicked')}>
                    <div
                        className={classes.product}
                    >
                        <div className={classes.productImageWrapper}>
                            <img
                                alt="hamro community"
                                className={classes.productImage}
                                src="/images/logos/hamropatro.png"
                            />
                        </div>
                        {isMobile ? (
                            <span></span>
                        ) : (
                            <div className={classes.productDetails}>
                                <Typography
                                    className={classes.productTitle}
                                    variant="h6"
                                    style={{fontWeight: 800}}
                                >
                                    Community
                                </Typography>
                            </div>
                        )}
                    </div>
                </Link>
                <List
                    component="div"
                    disablePadding
                    className={classes.menuWrapper}
                >
                    {menuItems.length > 0 ? (
                        <div>
                            {menuItems.map(menu => (
                                <ListItem
                                    style={{fontWeight: 600}}
                                    activeClassName={classes.activeListItem}
                                    className={classes.listItem}
                                    onClick={() => eventAnalyst('sidebar', menu.name + '_clicked', menu.name + ' Clicked', menu.name + '-clicked')}
                                    component={NavLink}
                                    to={menu.to}
                                    key={menu.id}
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        {menu.icon === 'ticket' ? (
                                            <Avatar alt={menu.name}
                                                    style={{
                                                        backgroundImage: `url('/images/icons/tickets.png')`,
                                                        backgroundRepeat: 'round',
                                                        backgroundColor: 'unset',
                                                        width: '25px',
                                                        height: '25px',
                                                    }}/>
                                        ) : icons[menu.icon].icon}
                                    </ListItemIcon>
                                    {isMobile ? (
                                        <span></span>
                                    ) : (<ListItemText
                                        classes={{primary: classes.listItemText}}
                                        primary={menu.name}
                                    />)}
                                </ListItem>
                            ))}
                            {/*<ListItemText*/}
                            {/*    classes={{primary: classes.listItemText}}*/}
                            {/*    primary=*/}
                            {/*        {*/}
                            {/*            <React.Fragment>*/}
                            {/*                <button onClick={this.toggleTheme}>Apply {themeColor === 'dark' ? 'light' : 'dark'} theme</button>*/}
                            {/*            </React.Fragment>*/}
                            {/*        }*/}
                            {/*/>*/}
                        </div>) : (
                        <div></div>
                    )}
                </List>
            </nav>
        );
    }
}

Sidebar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Sidebar);
