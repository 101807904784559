import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styles
import styles from './styles';
import Calendar from "../../components/Calendar/Calendar.jsx";
import {checkIfLogin} from "../../configurations/global-config";
import {pageViewAnalyst} from "../../configurations/config";

class CalendarPage extends Component {
    state = {
        groupId: '',
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        pageViewAnalyst('Calendar', this.props.location.pathname);
        checkIfLogin(this.props.location.pathname);
        this.props.match.params.groupId ? this.setState({groupId: this.props.match.params.groupId}) : this.setState({groupId: null})
    }

    render() {
        const {classes} = this.props;
        const {groupId} = this.state;

        return (
            <DashboardLayout title={'Calender'} onBack={groupId ? `/c/${groupId}` : ''}
                             linkTo={groupId ? `/c/${groupId}` : ''}>
                <div className={classes.root}>
                    <Calendar groupID={groupId}/>
                </div>
            </DashboardLayout>
        );
    }
}

CalendarPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CalendarPage);
