import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {timeEvent} from "../../utils/converter/dateConverter";
import {isAdmin} from "../../configurations/global-config";

const styles = theme => ({
    root: {
        width: '100%',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        border: '1px solid #a2a2a220',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: '#615f5f',
    },
    summary: {
        backgroundColor: 'rgba(0,0,0,.03)',
        borderBottom: '1px solid rgba(0,0,0,.125)',
        marginBottom: -1,
        minHeight: 35,
        '&$expanded': {
            minHeight: 35,
        },
    },
    expanssionPanel: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }

});

class ExpanssionList extends React.Component {
    state = {
        expanded: null,
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    render() {
        const {classes, notifList} = this.props;
        const {expanded} = this.state;

        return (
            <div className={classes.root}>
                {notifList.map((notif, index) => (
                    <ExpansionPanel expanded={expanded === 'panel' + index}
                                    onChange={this.handleChange('panel' + index)}
                                    className={classes.expanssionPanel}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} className={classes.summary}>
                            <Typography className={classes.heading}>#{index + 1} By {notif.getUser().getAccountName()}
                                <span><small> - {timeEvent(new Date(notif.getSendTime()))}</small></span>
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                {isAdmin() ? (
                                    <div><small>{new Date(notif.getSendTime()).toLocaleString()} -
                                        ({notif.getRole()})</small></div>
                                ) : <div><small>{new Date(notif.getSendTime()).toLocaleString()}</small></div>}
                                {notif.getMessage()}
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
            </div>
        );
    }
}

ExpanssionList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExpanssionList);
