import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, withStyles} from "@material-ui/core";
import styles from "../../Home/styles";
import Typography from "@material-ui/core/Typography";
import {eventAnalyst} from "../../../configurations/config";
import {GetPendingGroupsRequest} from "../../../generated/frontend-community-group-service_pb";
import {getAllPendingGroups} from "../../../grpcRequests/groups-request";
import {EventsLoader} from "../../../components/Skeleton/EventsSket";
import GroupsCard from "./GroupsCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {ArrowRightAltOutlined as CalendarIcon} from "@material-ui/icons";

const defaultPageSize = 15;

class PendingCommunity extends Component {
    state = {
        history: this.props.history,
        isLoadingPending: false,
        pendingGroupLists: [],
        errPenGroupsMsg: null,
        title: 'Pending Communities',
        pendingCommCursor: '',
        pendingCommPreviousCursor: '',
        loadingMorePending: false,
    };

    componentDidMount() {
        this.fetPendingGroups('', defaultPageSize, [], '');
    }

    loadMore = (cursorValue, pageSize, previousPending, prevCursor) => {
        this.setState({loadingMorePending: true});
        this.fetPendingGroups(cursorValue, pageSize, previousPending, prevCursor)
    };

    goToGroups() {
        eventAnalyst('home_view_link_redirect', this.state.title + '_view_events', this.state.title + ' click success', this.state.title + '-click-success');
        const {history} = this.state.history;
        history.push('/communities');
    };

    fetPendingGroups = (cursorValue, pageSize, previousPending, prevCursor) => {
        let self = this;
        self.setState({
            isLoadingPending: !prevCursor
        });
        const req = new GetPendingGroupsRequest();
        req.setCursor(cursorValue);
        req.setPageSize(pageSize);
        getAllPendingGroups(req, 3).then(function (response) {
            eventAnalyst('home', 'fetch_pending_community', 'Pending community fetch Success', 'pending-community-fetch-success');
            let pendingGroupLists = previousPending;
            for (let i = 0; i < response.getGroupInfosList().length; i++) {
                let newMember = response.getGroupInfosList()[i];
                pendingGroupLists.push(newMember);
            }
            const isLoadingPending = false;
            const loadingMorePending = false;
            const pendingCommCursor = response.getNextpagetoken();
            self.setState({
                isLoadingPending,
                pendingGroupLists,
                pendingCommCursor,
                pendingCommPreviousCursor: prevCursor,
                loadingMorePending
            });
        }).catch(function (error) {
            eventAnalyst('home', 'fetch_pending_community', 'Pending community fetch fail', 'pending-community-fetch-fail');
            const isLoadingPending = false;
            const errPenGroupsMsg = '';
            const loadingMorePending = false;
            self.setState({
                isLoadingPending,
                errPenGroupsMsg,
                loadingMorePending
            });
        })
    };

    render() {
        const {classes} = this.props;
        const {errPenGroupsMsg, isLoadingPending, pendingGroupLists, title, loadingMorePending, pendingCommCursor, pendingCommPreviousCursor} = this.state;

        return (
            <div>
                {!isLoadingPending ? (
                    <div>
                        {pendingGroupLists.length > 0 ? (
                            <div className={classes.boxItems}>
                                <div>
                                    <div>
                                        <div className={classes.floatLeft}>
                                            <Typography
                                                variant="h6"
                                                style={{fontWeight: 900}}
                                            >
                                                <span>{title}</span>
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={classes.simpleUnderline}></div>
                                </div>
                                <div className={classes.homeCardWrapper}>
                                    <GroupsCard groupList={pendingGroupLists} title={title}/>
                                    {pendingCommCursor && pendingCommPreviousCursor !== pendingCommCursor ? (
                                        <div className={classes.seeMoreWrapper}>
                                            <div
                                                onClick={() => this.loadMore(pendingCommCursor, defaultPageSize, pendingGroupLists, pendingCommCursor)}
                                                className={classes.iconTextWrapper}>
                                                <div className={classes.iconWrapper}>
                                                    <IconButton aria-label="See More"
                                                                title="See More"
                                                                size="small"
                                                                color={"primary"}>
                                                        <CalendarIcon style={{fontSize: '38px'}}/>
                                                    </IconButton>
                                                </div>
                                                <div className={classes.textSeeMore}>
                                                    {loadingMorePending ? (<CircularProgress size={20}/>) : 'See More'}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (<span></span>)}
                                </div>
                            </div>
                        ) : (
                            <div>
                                {errPenGroupsMsg ? (
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid item>
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                {errPenGroupsMsg}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        )}
                    </div>
                ) : (<EventsLoader/>)}
            </div>
        )
    }
}


PendingCommunity.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PendingCommunity);