import {CommentServiceServer as srv} from "../grpcClients/service-clients-factory";
import {MetaHeaders} from "../configurations/config";
import {retryCase} from "../utils/Common";

export function createCommentOnPost(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.commentOnContent(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getPostComments(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.listComments(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getPostComments(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function deletePostComment(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.deleteComment(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function reactOnCommentedPost(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.reactOnComment(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getCommentDetails(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getComment(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getCommentDetails(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}