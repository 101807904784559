/**
 * @fileoverview gRPC-Web generated client stub for com.hamropatro.sociallayer.io
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');


var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js')

var frontend$ticket$common$types_pb = require('./frontend-ticket-common-types_pb.js')
const proto = {};
proto.com = {};
proto.com.hamropatro = {};
proto.com.hamropatro.sociallayer = {};
proto.com.hamropatro.sociallayer.io = require('./frontend-community-event-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

    };


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.CreateEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.CreateEventResponse>}
 */
const methodDescriptor_CommunityEventService_CreateEvent = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/CreateEvent',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.CreateEventRequest,
    proto.com.hamropatro.sociallayer.io.CreateEventResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.CreateEventRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.CreateEventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.CreateEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.CreateEventResponse>}
 */
const methodInfo_CommunityEventService_CreateEvent = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.CreateEventResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.CreateEventRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.CreateEventResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.CreateEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.CreateEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.CreateEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.createEvent =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/CreateEvent',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_CreateEvent,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.CreateEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.CreateEventResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.createEvent =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/CreateEvent',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_CreateEvent);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.UpdateEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateEventResponse>}
 */
const methodDescriptor_CommunityEventService_UpdateEvent = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/UpdateEvent',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.UpdateEventRequest,
    proto.com.hamropatro.sociallayer.io.UpdateEventResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateEventRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateEventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.UpdateEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateEventResponse>}
 */
const methodInfo_CommunityEventService_UpdateEvent = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.UpdateEventResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateEventRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateEventResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.UpdateEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.UpdateEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.updateEvent =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/UpdateEvent',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_UpdateEvent,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.UpdateEventResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.updateEvent =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/UpdateEvent',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_UpdateEvent);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetEventResponse>}
 */
const methodDescriptor_CommunityEventService_GetEvent = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/GetEvent',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetEventRequest,
    proto.com.hamropatro.sociallayer.io.GetEventResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetEventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetEventResponse>}
 */
const methodInfo_CommunityEventService_GetEvent = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetEventResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetEventResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.getEvent =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetEvent',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetEvent,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetEventResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.getEvent =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetEvent',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetEvent);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse>}
 */
const methodDescriptor_CommunityEventService_GetEventsOfDay = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/GetEventsOfDay',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest,
    proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse>}
 */
const methodInfo_CommunityEventService_GetEventsOfDay = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.getEventsOfDay =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetEventsOfDay',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetEventsOfDay,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.getEventsOfDay =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetEventsOfDay',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetEventsOfDay);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse>}
 */
const methodDescriptor_CommunityEventService_GetEventByCategory = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/GetEventByCategory',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest,
    proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse>}
 */
const methodInfo_CommunityEventService_GetEventByCategory = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.getEventByCategory =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetEventByCategory',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetEventByCategory,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.getEventByCategory =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetEventByCategory',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetEventByCategory);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse>}
 */
const methodDescriptor_CommunityEventService_GetUsersGroupEvents = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/GetUsersGroupEvents',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest,
    proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse>}
 */
const methodInfo_CommunityEventService_GetUsersGroupEvents = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.getUsersGroupEvents =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetUsersGroupEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetUsersGroupEvents,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.getUsersGroupEvents =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetUsersGroupEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetUsersGroupEvents);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse>}
 */
const methodDescriptor_CommunityEventService_GetGroupEvents = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/GetGroupEvents',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest,
    proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse>}
 */
const methodInfo_CommunityEventService_GetGroupEvents = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.getGroupEvents =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetGroupEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetGroupEvents,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.getGroupEvents =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetGroupEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetGroupEvents);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse>}
 */
const methodDescriptor_CommunityEventService_UpdateGoingStatus = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/UpdateGoingStatus',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest,
    proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse>}
 */
const methodInfo_CommunityEventService_UpdateGoingStatus = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.updateGoingStatus =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/UpdateGoingStatus',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_UpdateGoingStatus,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.updateGoingStatus =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/UpdateGoingStatus',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_UpdateGoingStatus);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.RemoveEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.RemoveEventResponse>}
 */
const methodDescriptor_CommunityEventService_RemoveEvent = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/RemoveEvent',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.RemoveEventRequest,
    proto.com.hamropatro.sociallayer.io.RemoveEventResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.RemoveEventRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.RemoveEventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.RemoveEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.RemoveEventResponse>}
 */
const methodInfo_CommunityEventService_RemoveEvent = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.RemoveEventResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.RemoveEventRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.RemoveEventResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.RemoveEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.RemoveEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.removeEvent =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/RemoveEvent',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_RemoveEvent,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.RemoveEventResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.removeEvent =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/RemoveEvent',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_RemoveEvent);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse>}
 */
const methodDescriptor_CommunityEventService_GetEventAttenders = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/GetEventAttenders',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest,
    proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse>}
 */
const methodInfo_CommunityEventService_GetEventAttenders = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.getEventAttenders =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetEventAttenders',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetEventAttenders,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.getEventAttenders =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetEventAttenders',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetEventAttenders);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent,
 *   !proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse>}
 */
const methodDescriptor_CommunityEventService_GetUserAttendingEvents = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/GetUserAttendingEvents',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent,
    proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent,
 *   !proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse>}
 */
const methodInfo_CommunityEventService_GetUserAttendingEvents = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.getUserAttendingEvents =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetUserAttendingEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetUserAttendingEvents,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.getUserAttendingEvents =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetUserAttendingEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetUserAttendingEvents);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse>}
 */
const methodDescriptor_CommunityEventService_GetUpcomingEvents = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/GetUpcomingEvents',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest,
    proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse>}
 */
const methodInfo_CommunityEventService_GetUpcomingEvents = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.getUpcomingEvents =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetUpcomingEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetUpcomingEvents,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.getUpcomingEvents =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetUpcomingEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetUpcomingEvents);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse>}
 */
const methodDescriptor_CommunityEventService_GetPreviousEvents = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/GetPreviousEvents',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest,
    proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse>}
 */
const methodInfo_CommunityEventService_GetPreviousEvents = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.getPreviousEvents =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetPreviousEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetPreviousEvents,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.getPreviousEvents =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetPreviousEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetPreviousEvents);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse>}
 */
const methodDescriptor_CommunityEventService_GetNearbyEvents = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/GetNearbyEvents',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest,
    proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse>}
 */
const methodInfo_CommunityEventService_GetNearbyEvents = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.getNearbyEvents =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetNearbyEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetNearbyEvents,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.getNearbyEvents =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/GetNearbyEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_GetNearbyEvents);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.ExploreEventsRequest,
 *   !proto.com.hamropatro.sociallayer.io.ExploreEventsResponse>}
 */
const methodDescriptor_CommunityEventService_ExploreEvents = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityEventService/ExploreEvents',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.ExploreEventsRequest,
    proto.com.hamropatro.sociallayer.io.ExploreEventsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.ExploreEventsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.ExploreEventsRequest,
 *   !proto.com.hamropatro.sociallayer.io.ExploreEventsResponse>}
 */
const methodInfo_CommunityEventService_ExploreEvents = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.ExploreEventsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.ExploreEventsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ExploreEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.ExploreEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.ExploreEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServiceClient.prototype.exploreEvents =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/ExploreEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_ExploreEvents,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ExploreEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.ExploreEventsResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityEventServicePromiseClient.prototype.exploreEvents =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommunityEventService/ExploreEvents',
            request,
            metadata || {},
            methodDescriptor_CommunityEventService_ExploreEvents);
    };


module.exports = proto.com.hamropatro.sociallayer.io;

