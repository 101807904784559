export function communityJSON(community) {
    var SCHEMA =
        {
            "@context": "http://www.schema.org",
            "@type": "Organization",
            "name": community.getName(),
            "url": window.location.href,
            "sameAs": [
                community.getDomainurlList().length > 0 ? community.getDomainurlList()[community.getDomainurlList().length - 1] : window.location.href
            ],
            "logo": community.getLogo(),
            "image": community.getCoverimage(),
            "description": community.getDesc(),
            "address": {
                "@type": "PostalAddress",
                "streetAddress": community.getAddress(),
                "addressCountry": community.getCountry()
            },
            "location": {
                "@type": "Place",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": community.getAddress(),
                    "addressCountry": community.getCountry(),
                    "geo": {
                        "@type": "GeoCoordinates",
                        "latitude": community.getLocation().getLat(),
                        "longitude": community.getLocation().getLon()
                    }
                },
                "hasMap": "https://www.google.com/maps/search/?api=1&query=" + community.getLocation().getLat() + "," + community.getLocation().getLon()
            },
        };
    const script = document.createElement("script");
    script.id = "jsonSchemaID";
    script.type = "application/ld+json";
    script.async = true;

    script.textContent = JSON.stringify(SCHEMA);

    document.body.appendChild(script);
}


export function removeElement() {
    var list = document.getElementById("jsonSchemaID");
    if (list && list.childNodes) {
        list.removeChild(list.childNodes[0]);
    }
}

export function EventJSON(eventDetails, ticketList) {
    let allTickets = [];
    let allTicketList = [];
    if (ticketList.length > 0) {
        allTickets.push(
            {
                "@type": "AggregateOffer",
                "availability": "InStock",
                "availabilityStarts": '',
                "availabilityEnds": '',
                "highPrice": parseFloat(eventDetails.getMetaDataMap().get('max_price')),
                "lowPrice": parseFloat(eventDetails.getMetaDataMap().get('min_price')),
                "priceCurrency": ticketList[0].getPricingDetail().getCurrency(),
                "url": window.location.href,
                "price": parseFloat(eventDetails.getMetaDataMap().get('min_price')),
                "validFrom": new Date (eventDetails.getStartFrom()).getTime()
            }
        );
        ticketList.forEach(function (ticket) {
            let avlType;
            if (new Date().getTime() > new Date(ticket.getExpiration()).getTime()) {
                avlType = "Discontinued";
            } else if (ticket.getSoldOut()) {
                avlType = "SoldOut";
            } else avlType = "InStock";

            allTicketList.push(
                {
                    "@type": "Offer",
                    "availability": avlType,
                    "availabilityStarts": new Date(ticket.getIssuedTimestamp()).toISOString(),
                    "availabilityEnds": new Date(ticket.getExpiration()).toISOString(),
                    "highPrice": ticket.getPricingDetail().getPrice(),
                    "lowPrice": ticket.getPricingDetail().getPrice(),
                    "priceCurrency": ticket.getPricingDetail().getCurrency(),
                    "url": window.location.href,
                    "price": ticket.getPricingDetail().getPrice(),
                    "validFrom": new Date(ticket.getIssuedTimestamp()).toISOString()
                }
            )
        });
        allTickets.push.apply(allTickets, allTicketList);
    }

    let sponsorsList = [];
    if (eventDetails.getSponsorList().length > 0) {
        eventDetails.getSponsorList().forEach(function (sponsor) {
            sponsorsList.push(
                {
                    "@type": "Organization",
                    "name": sponsor.getName(),
                    "email": sponsor.getEmail(),
                    "logo": sponsor.getImageUrl(),
                    "url": sponsor.getLink()
                }
            )
        })
    }

    //organizarion contact info
    let orgList = [
        {
            "@type": "Organization",
            "name": eventDetails.getMetaDataMap().get('event_hosted_by'),
            "url": window.location.origin + '/c/' + eventDetails.getGroupId()
        },
        {
            "@type": "Person",
            "name": eventDetails.getOrganizerName(),
            "email": eventDetails.getOrganizerEmail(),
            "telephone": eventDetails.getOrganizerPhone()
        }];
    let organizersList = [];

    if (eventDetails.getOrganizerList().length > 0) {
        eventDetails.getOrganizerList().forEach(function (organizer) {
            organizersList.push(
                {
                    "@type": "Person",
                    "name": organizer.getName(),
                    "email": organizer.getEmail(),
                    "telephone": organizer.getPhone()
                }
            )
        })
    }
    orgList.push.apply(orgList, organizersList);

    let SCHEMA =
        {
            "@context": "http://www.schema.org",
            "@type": "Event",
            "name": eventDetails.getTitle(),
            "url": window.location.href,
            "description": eventDetails.getEventDesc(),
            "startDate": new Date(eventDetails.getStartFrom()).toISOString(),
            "endDate": new Date(eventDetails.getEnds()).toISOString(),
            "image": eventDetails.getEventCoverImage() ? eventDetails.getEventCoverImage() : '/images/eventDefault.png',
            "eventStatus": "EventScheduled", //EventCancelled, EventPostponed, EventRescheduled, EventScheduled
            "location": {
                "@type": "Place",
                "latitude": eventDetails.getLocation().getLat(),
                "longitude": eventDetails.getLocation().getLon(),
                "address": {
                    '@type': 'PostalAddress',
                    'name': eventDetails.getLocationString()
                },
                "name": eventDetails.getLocationString(),
                "hasMap": "https://www.google.com/maps/search/?api=1&query=" + eventDetails.getLocation().getLat() + "," + eventDetails.getLocation().getLon()
            },
            "organizer": orgList,
            "sponsor": sponsorsList,
            "offers": allTickets ? allTickets : [],
            "performer": []
        };

    const script = document.createElement("script");
    script.id = "jsonSchemaID";
    script.type = "application/ld+json";
    script.async = true;

    script.textContent = JSON.stringify(SCHEMA);

    document.body.appendChild(script);
}
