import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, withStyles} from "@material-ui/core";
import styles from "../../Home/styles";
import Typography from "@material-ui/core/Typography";
import {eventAnalyst} from "../../../configurations/config";
import {EventsLoader} from "../../../components/Skeleton/EventsSket";
import {getUserGroupsEvents} from "../../../grpcRequests/events-request";
import {GetUserGroupEventsRequest} from "../../../generated/frontend-community-event-service_pb";
import UpComingEventCard from "./UpComingEventCard";
import IconButton from "@material-ui/core/IconButton";
import {ArrowRightAltOutlined as CalendarIcon} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";

const defaultPageSize = 15;

class UpcomingEvents extends Component {
    state = {
        history: this.props.history,
        isLoadingEventList: false,
        myEventLists: [],
        errUpComingEventsList: null,
        title: 'Upcoming Events',
        upComEventsCursor: '',
        upComEventsPreviousCursor: '',
        loadingMoreUpComEvents: false,
    };

    componentDidMount() {
        this.fetchUpcomingEvents('', defaultPageSize, [], '');
    }

    loadMore = (cursorValue, pageSize, previousPending, prevCursor) => {
        this.setState({loadingMoreUpComEvents: true});
        this.fetchUpcomingEvents(cursorValue, pageSize, previousPending, prevCursor)
    };

    fetchUpcomingEvents = (cursorValue, pageSize, previousData, prevCursor) => {
        let self = this;
        self.setState({
            isLoadingEventList: !prevCursor
        });
        const req = new GetUserGroupEventsRequest();
        req.setYear(new Date().getFullYear());
        req.setCursor(cursorValue);
        req.setPageSize(pageSize);
        getUserGroupsEvents(req, 3).then(function (response) {
            eventAnalyst('home', 'fetch_upcoming_events', 'Upcoming event fetch success', 'upcoming-event-fetch-success');
            let myEventLists = previousData;
            for (let i = 0; i < response.getEventDetailList().length; i++) {
                let newData = response.getEventDetailList()[i];
                myEventLists.push(newData);
            }
            const isLoadingEventList = false;
            const loadingMoreUpComEvents = false;
            const upComEventsCursor = response.getNextPageCursor();
            self.setState({
                myEventLists,
                isLoadingEventList,
                loadingMoreUpComEvents,
                upComEventsCursor,
                upComEventsPreviousCursor: prevCursor
            });
        }).catch(function (error) {
            eventAnalyst('home', 'fetch_upcoming_events', 'Upcoming event fetch fail', 'upcoming-event-fetch-fail');
            const isLoadingEventList = false;
            const loadingMoreUpComEvents = false;
            self.setState({
                isLoadingEventList,
                loadingMoreUpComEvents
            });
        })
    };

    goToEvents() {
        eventAnalyst('home_view_link_redirect', 'view_upcoming_events', 'View Upcoming event click success', 'view-upcoming-event-click-success');
        const {history} = this.state.history;
        history.push('/events');
    };

    render() {
        const {classes} = this.props;
        const {isLoadingEventList, myEventLists, errUpComingEventsList, title, upComEventsCursor, loadingMoreUpComEvents, upComEventsPreviousCursor} = this.state;
        return (
            <div>
                {!isLoadingEventList ? (
                    <div>
                        {myEventLists.length > 0 ? (
                            <div className={classes.boxItems}>
                                <div>
                                    <div>
                                        <div className={classes.floatLeft}>
                                            <Typography
                                                variant="h6"
                                                style={{fontWeight: 900}}
                                            >
                                                <span>{title}</span>
                                            </Typography>
                                        </div>
                                        <div className={classes.floatRight}>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                onClick={() => this.goToEvents()}
                                                className={classes.buttonView}
                                            >
                                                View All
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={classes.simpleUnderline}></div>
                                </div>
                                <div className={classes.homeCardWrapper}>
                                    <UpComingEventCard eventList={myEventLists} title={title}/>
                                    {upComEventsCursor && upComEventsPreviousCursor !== upComEventsCursor ? (
                                        <div className={classes.seeMoreWrapper}>
                                            <div
                                                onClick={() => this.loadMore(upComEventsCursor, defaultPageSize, myEventLists, upComEventsCursor)}
                                                className={classes.iconTextWrapper}>
                                                <div className={classes.iconWrapper}>
                                                    <IconButton aria-label="See More"
                                                                title="See More"
                                                                size="small"
                                                                color={"primary"}>
                                                        <CalendarIcon style={{fontSize: '38px'}}/>
                                                    </IconButton>
                                                </div>
                                                <div className={classes.textSeeMore}>
                                                    {loadingMoreUpComEvents ? (
                                                        <CircularProgress size={20}/>) : 'See More'}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (<span></span>)}
                                </div>
                            </div>
                        ) : (
                            <div>
                                {errUpComingEventsList ? (
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid item>
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                {errUpComingEventsList}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        )}
                    </div>
                ) : (<EventsLoader/>)}
            </div>
        )
    }
}


UpcomingEvents.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UpcomingEvents);