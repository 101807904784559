import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./styles";
import {PaymentMethod, PaymentRequest} from "../../generated/frontend-community-ticket-payment-service_pb";
import {PayeeInfo} from "../../generated/frontend-ticket-common-types_pb";
import {makeTicketPayment} from "../../grpcRequests/tickets-request";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import globalConfig from "../../configurations/configVariables";

let uniqueRequestId;

class KhaltiCheckOut extends Component {

    state = {
        paying: false,
        payingMsg: '',
        paymentConfirm: false

    };

    componentDidMount() {
        uniqueRequestId = '';
    }

    checkoutConfirm = () => {
        this.setState({paying: true, payingMsg: ''});
        this.paymentRequests(this.props.khaltiTok, this.props.khaltiTok, PaymentMethod.KHALTI)
    };

    paymentRequests = (token, uniqueIdentifer, paymentType) => {
        let self = this;
        let payeeInfo = new PayeeInfo();
        payeeInfo.setName(self.props.buyerName);
        payeeInfo.setEmail(self.props.buyerEmail);
        payeeInfo.setAddress(self.props.buyerAddress);
        payeeInfo.setPhone(self.props.buyerPhone);
        const req = new PaymentRequest();
        req.setSourceId(self.props.passEventId); //event ID
        req.setTicketTypeId(self.props.passTicketId); //tickcet iD
        req.setPaymentmethod(paymentType);
        req.setPaymentToken(token); //token id
        req.setPurchaseQuantity(self.props.noOfTicket);
        req.setUniqueIdentifier(uniqueIdentifer);
        req.setPayeeInfo(payeeInfo);
        req.setAppId(globalConfig.appId);
        const metadata = req.getMetaDataMap();
        metadata.set("buyer_desc", (self.props.buyersDesc));
        self.paymentAPI(req, 3);
    };

    paymentAPI = (req, retry) => {
        let self = this;
        makeTicketPayment(req).then(function (response) {
            self.setState({paying: false});
            if (response.getPaymentSuccessful() && response.getPaymentConfirmationId() && response.getPaymentConfirmationId() !== '') {
                self.setState({payingMsg: 'Payment success'});
                self.sendCheckoutResponse(true, response.getPaymentConfirmationId(), response.getTicketTypeId(), response.getTicketIdList().length, response.getChargedAmount(), response.getPaymentTimestamp());
            } else {
                self.setState({payingMsg: 'Payment failed'});
                self.sendCheckoutResponse(false, true, '', '', '', '');
            }
        }).catch(function (error) {
            if (error.code === 14) {
                if (retry === 1) {
                    self.setState({paying: false, payingMsg: 'Payment failed'});
                } else {
                    setTimeout(() => {
                        self.paymentAPI(req, retry - 1)
                    }, 10);
                }
            } else self.setState({paying: false, payingMsg: error.message});
        })
    };

    handleChange = (change) => {
        this.setState({
            payingMsg: ''
        })
    };

    sendCheckoutResponse = (checkoutStatus, confirmationId, ticketTypeId, totalTicket, totalAmount, timeStamp) => {
        this.props.sendCloseCheckout(checkoutStatus, confirmationId, ticketTypeId, totalTicket, totalAmount, timeStamp)
    };

    render() {
        const {classes, paymentType, khaltiTok} = this.props;
        const {paying, payingMsg} = this.state;
        return (

            <div className="checkout">

                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            Please complete the transaction by clicking the button below.
                        </Grid>
                    </Grid>
                </div>

                <br/>
                {payingMsg ? (<div style={{
                    padding: '10px 15px',
                    margin: '10px 0',
                    background: "#ffbd21",
                    borderRadius: '5px'
                }}>{payingMsg}&nbsp;</div>) : (<div>&nbsp;</div>)}
                <Button variant="contained" color="secondary" onClick={this.checkoutConfirm}
                        className={classes.buttonSubmit} disabled={paying}>
                    {paying ? (<span>Submitting Payment&nbsp; &nbsp;<CircularProgress
                        size={12}/> </span>) : 'Complete Payment'}
                </Button>
            </div>
        );
    }
}

KhaltiCheckOut.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(KhaltiCheckOut)