import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from "@material-ui/core/Divider";

import {Typography, withStyles} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import styles from './styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FileCopyOutlined as CopyFile} from "@material-ui/icons";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import {
    GenerateBusinessAccountShareLinkRequest,
    InviteUsersToBusinessAccountRequest,
    Role
} from "../generated/frontend-business-account-service_pb";
import {generateDirectLink, inviteUserToBusinessGroup} from "../grpcRequests/business-groups-request";
import usedString, {emailFormat} from "../utils/mapper/enumToStringMapper";
import {eventAnalyst} from "../configurations/config";


class AddAdmin extends Component {
    state = {
        openDialog: false,
        generatingLink: false,
        shareLink: '',
        copiedDirectLink: false,
        email: '',
        members: [],
        groupId: this.props.groupId,
        role: 'PARTICIPANT',
        sendingInvitees: false
    };

    componentDidMount() {
        this.setState({members: []});
    }

    openInviteDialog = () => {
        this.setState({openDialog: true, members: []});
    };

    closeInviteDialog = () => {
        this.setState({openDialog: false, members: []});
    };

    changeType = (event) => {
        const linkEnableType = event.target.checked;
        if (linkEnableType) {
            this.generateLink();
        } else {
            this.setState({shareLink: ''});
        }
    };

    generateLink = () => {
        let self = this;
        self.setState({
            shareLink: null,
            copiedDirectLink: false,
            generatingLink: true
        });
        const req = new GenerateBusinessAccountShareLinkRequest();
        req.setBusinessAccountId(this.state.groupId);
        req.setRedirectlink(window.location.origin + "/_admin/c/" + this.props.groupId + "/type/join");
        generateDirectLink(req).then(function (response) {
            eventAnalyst('directAdmin_link', self.state.groupId + '_direct_admin_create', self.state.groupId + ' direct admin create success', self.state.groupId + '-direct-admin-create-success');
            const shareLink = response.getLink();
            self.setState({
                shareLink, generatingLink: false
            });
        }).catch(function (error) {
            eventAnalyst('directAdmin_link', self.state.groupId + '_direct_admin_create', self.state.groupId + ' direct admin create fail', self.state.groupId + '-direct-admin-create-fail');
            self.setState({
                generatingLink: false
            });
        })
    };

    handleChange = (event) => {
        this.setState({value: event.target.value});
    };

    handleChangeField = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    onEnterPressed = (e) => {
        if (e.key === 'Enter' && this.state.email && emailFormat.test(this.state.email)) {
            this.addMemberToList();
        }
    };

    addMemberToList = () => {
        this.state.members.push(this.state.email);
        this.setState({members: this.state.members, email: ''});
    };

    removeMember = (index, mem) => {
        this.state.members.splice(index, 1);
        this.setState({members: this.state.members});
    };

    sendInvitation = () => {
        this.setState({sendingInvitees: true});
        let self = this;
        let sts;
        if (this.state.role === usedString.ADMIN)
            sts = Role.ADMIN;
        else if (this.state.role === usedString.PARTICIPANT)
            sts = Role.PARTICIPANT;
        else sts = Role.DEFAULT;

        const req = new InviteUsersToBusinessAccountRequest();
        req.setBusinessAccountId(this.props.groupId);
        req.setRole(sts);
        req.setEmailList(this.state.members);
        req.setRedirectlink(window.location.origin + "/_admin/c/" + this.props.groupId + "/type/join");
        inviteUserToBusinessGroup(req).then(function (response) {
            eventAnalyst('community_admin', self.state.groupId + '_community_admin_create', self.state.groupId + ' community admin create success', self.state.groupId + '-community-admin-create-success');
            self.setState({sendingInvitees: false});
            self.closeInviteDialog();
        }).catch(function (error) {
            eventAnalyst('community_admin', self.state.groupId + '_community_admin_create', self.state.groupId + ' community admin create fail', self.state.groupId + '-community-admin-create-fail');
            self.setState({sendingInvitees: false});
        })
    };

    render() {
        const {fullScreen, classes} = this.props;
        const {shareLink, generatingLink, copiedDirectLink, role, email, members, sendingInvitees} = this.state;
        return (
            <div>
                <Fab
                    variant="extended"
                    size="small"
                    aria-label="Add"
                    className={classes.inviteButton}
                    onClick={this.openInviteDialog}
                >
                    Add Admin <AddIcon/>
                </Fab>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.openDialog}
                    onClose={this.closeInviteDialog}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"ADD ADMIN | MODERATORS"}</DialogTitle>
                    <Divider/>
                    <Typography
                        style={{padding: '5px 15px', fontSize: '12px', background: "#0e83e8", color: '#ffffff'}}>
                        <i>INFO: Only Community Admin can invite admin and moderator. Following feature may not
                            work.</i>
                    </Typography>
                    <Divider/>
                    <DialogContent>
                        <div className={classes.directHead}>Invite through Link</div>
                        <div className={classes.formSwitch}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch size="small" checked={shareLink} color="primary"
                                                     onChange={this.changeType}/>}
                                    label="Generate Link"
                                />
                            </FormGroup>
                        </div>
                        <Typography
                            className={classes.directText}
                            variant="subtitle1"
                        > <span> You can add join members as Moderator by sharing the generated link.</span>
                        </Typography>
                        {generatingLink ? (
                            <div><CircularProgress className={classes.progress} size={22}/></div>) : (<div></div>)}
                        {shareLink ? (
                            <div>
                                <div className={classes.sharableLinkWrapper}>
                                    <span className={classes.sharableText}> {shareLink}</span>
                                    <CopyToClipboard text={shareLink}
                                                     onCopy={() => this.setState({
                                                         copiedDirectLink: true,
                                                         copied: false
                                                     })}>
                                    <span title="copy invitation link" className={classes.rightIcon}><CopyFile
                                        color="primary"
                                        onClick={() => eventAnalyst('direct_admin_link', this.state.groupId + '_direct_admin_link_copy', this.state.groupId + ' direct admin link copy', this.state.groupId + '-direct-admin-link-copy')}/></span>
                                    </CopyToClipboard>
                                </div>
                                {copiedDirectLink ? (
                                    <DialogContentText className={classes.contentInvite}>
                                        Invitation Link is now copied to clipboard.
                                    </DialogContentText>
                                ) : (<DialogContentText
                                    className={classes.contentInvite}>&nbsp;</DialogContentText>)}
                            </div>) : (<div className={classes.directText}>&nbsp;</div>)}

                        <Divider/>
                        <br/>
                        <div>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <div className={classes.directHeadText}>Select Community Role</div>
                                <RadioGroup
                                    className={classes.group}
                                    value={role}
                                    onChange={this.handleChangeField('role')}
                                    row
                                >
                                    <FormControlLabel value="ADMIN" control={<Radio/>} label="Admin"/>
                                    <FormControlLabel value="PARTICIPANT" control={<Radio/>} label="Moderator"/>
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <br/>
                        <div>
                            <div className={classes.directHeadText}>Invite through Email</div>
                            <DialogContentText>
                                You can add users as Admin/Moderator of the community through email as well.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={10}>
                                    <div>
                                        <TextField
                                            name="email"
                                            variant="outlined"
                                            id="email"
                                            label="Email"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            value={email}
                                            type="email"
                                            onChange={this.handleChangeField('email')}
                                            onKeyPress={this.onEnterPressed}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <div>
                                        <Button variant="outlined" color="secondary"
                                                disabled={!email || !emailFormat.test(email)}
                                                onClick={() => this.addMemberToList()}>Add</Button>
                                    </div>
                                </Grid>
                            </Grid>
                            <div>
                                {members.length ?
                                    members.map(
                                        (mem, index) =>
                                            <Chip
                                                style={{margin: 5}}
                                                label={mem}
                                                color="primary"
                                                variant="outline"
                                                onDelete={() => this.removeMember(index, mem)}
                                                deleteIcon={<span><i className="material-icons">clear</i></span>}
                                            />) : null}
                            </div>

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div>{sendingInvitees ? (<CircularProgress size={20}/>) : (<div></div>)}</div>
                        <Button color="primary" className="cancel" onClick={this.closeInviteDialog}>
                            Close
                        </Button>
                        <Button variant="outlined" onClick={this.sendInvitation}
                                disabled={members.length > 0 ? false : true} color="primary" className="submit">
                            Invite
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AddAdmin.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AddAdmin);

