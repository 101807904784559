/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.io.ArrayValue', null, global);
goog.exportSymbol('proto.io.BatchCreateEverestObjectResponse', null, global);
goog.exportSymbol('proto.io.BatchCrudRequest', null, global);
goog.exportSymbol('proto.io.BatchCrudResponse', null, global);
goog.exportSymbol('proto.io.BatchDeleteEverestObjectResponse', null, global);
goog.exportSymbol('proto.io.BatchUpdateEverestObjectResponse', null, global);
goog.exportSymbol('proto.io.BatchWriteRequest', null, global);
goog.exportSymbol('proto.io.BatchWriteResponse', null, global);
goog.exportSymbol('proto.io.ChangeEvent', null, global);
goog.exportSymbol('proto.io.Counter', null, global);
goog.exportSymbol('proto.io.CreateEverestObjectRequest', null, global);
goog.exportSymbol('proto.io.CreateEverestObjectResponse', null, global);
goog.exportSymbol('proto.io.DecrementAndIncrementRequest', null, global);
goog.exportSymbol('proto.io.DecrementAndIncrementResponse', null, global);
goog.exportSymbol('proto.io.DecrementCounterRequest', null, global);
goog.exportSymbol('proto.io.DecrementCounterResponse', null, global);
goog.exportSymbol('proto.io.DeleteEverestObjectRequest', null, global);
goog.exportSymbol('proto.io.DeleteEverestObjectResponse', null, global);
goog.exportSymbol('proto.io.DeletionEvent', null, global);
goog.exportSymbol('proto.io.Document', null, global);
goog.exportSymbol('proto.io.DocumentMask', null, global);
goog.exportSymbol('proto.io.EverestObject', null, global);
goog.exportSymbol('proto.io.GetEverestCounterListRequest', null, global);
goog.exportSymbol('proto.io.GetEverestCounterListResponse', null, global);
goog.exportSymbol('proto.io.GetEverestCounterRequest', null, global);
goog.exportSymbol('proto.io.GetEverestCounterResponse', null, global);
goog.exportSymbol('proto.io.GetEverestObjectRequest', null, global);
goog.exportSymbol('proto.io.GetEverestUniqueCounterListResponse', null, global);
goog.exportSymbol('proto.io.GetEverestUniqueCounterRequest', null, global);
goog.exportSymbol('proto.io.GetEverestUniqueCounterResponse', null, global);
goog.exportSymbol('proto.io.IncrementCountRequest', null, global);
goog.exportSymbol('proto.io.IncrementCountResponse', null, global);
goog.exportSymbol('proto.io.IncrementUniqueCountRequest', null, global);
goog.exportSymbol('proto.io.InsertionEvent', null, global);
goog.exportSymbol('proto.io.LatLng', null, global);
goog.exportSymbol('proto.io.ListEverestObjectsRequest', null, global);
goog.exportSymbol('proto.io.ListEverestObjectsResponse', null, global);
goog.exportSymbol('proto.io.MapValue', null, global);
goog.exportSymbol('proto.io.NullValue', null, global);
goog.exportSymbol('proto.io.Precondition', null, global);
goog.exportSymbol('proto.io.SubscribeRequest', null, global);
goog.exportSymbol('proto.io.SubscribeRequest.Type', null, global);
goog.exportSymbol('proto.io.SubscriptionEvent', null, global);
goog.exportSymbol('proto.io.SubscriptionEvent.Type', null, global);
goog.exportSymbol('proto.io.SyncEverestObjectsRequest', null, global);
goog.exportSymbol('proto.io.SyncEverestObjectsResponse', null, global);
goog.exportSymbol('proto.io.Timestamp', null, global);
goog.exportSymbol('proto.io.UniqueCounter', null, global);
goog.exportSymbol('proto.io.UpdateConflict', null, global);
goog.exportSymbol('proto.io.UpdateEvent', null, global);
goog.exportSymbol('proto.io.UpdateEverestObjectRequest', null, global);
goog.exportSymbol('proto.io.UpdateEverestObjectResponse', null, global);
goog.exportSymbol('proto.io.UpdateSuccess', null, global);
goog.exportSymbol('proto.io.Value', null, global);
goog.exportSymbol('proto.io.Write', null, global);
goog.exportSymbol('proto.io.WriteRequest', null, global);
goog.exportSymbol('proto.io.WriteResponse', null, global);
goog.exportSymbol('proto.io.WriteResult', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.ListEverestObjectsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.ListEverestObjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.ListEverestObjectsRequest.displayName = 'proto.io.ListEverestObjectsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.ListEverestObjectsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.ListEverestObjectsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.ListEverestObjectsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.ListEverestObjectsRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
      pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.ListEverestObjectsRequest}
 */
proto.io.ListEverestObjectsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.ListEverestObjectsRequest;
  return proto.io.ListEverestObjectsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.ListEverestObjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.ListEverestObjectsRequest}
 */
proto.io.ListEverestObjectsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setParent(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setPageToken(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.ListEverestObjectsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.ListEverestObjectsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.ListEverestObjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.ListEverestObjectsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        2,
        f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.io.ListEverestObjectsRequest.prototype.getParent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.ListEverestObjectsRequest.prototype.setParent = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.io.ListEverestObjectsRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.io.ListEverestObjectsRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.io.ListEverestObjectsRequest.prototype.getPageToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.io.ListEverestObjectsRequest.prototype.setPageToken = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.ListEverestObjectsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.io.ListEverestObjectsResponse.repeatedFields_, null);
};
goog.inherits(proto.io.ListEverestObjectsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.ListEverestObjectsResponse.displayName = 'proto.io.ListEverestObjectsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.io.ListEverestObjectsResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.ListEverestObjectsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.ListEverestObjectsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.ListEverestObjectsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.ListEverestObjectsResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      everestobjectsList: jspb.Message.toObjectList(msg.getEverestobjectsList(),
          proto.io.EverestObject.toObject, includeInstance),
      nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
      maxusn: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.ListEverestObjectsResponse}
 */
proto.io.ListEverestObjectsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.ListEverestObjectsResponse;
  return proto.io.ListEverestObjectsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.ListEverestObjectsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.ListEverestObjectsResponse}
 */
proto.io.ListEverestObjectsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.EverestObject;
        reader.readMessage(value, proto.io.EverestObject.deserializeBinaryFromReader);
        msg.addEverestobjects(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextPageToken(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setMaxusn(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.ListEverestObjectsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.ListEverestObjectsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.ListEverestObjectsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.ListEverestObjectsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEverestobjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.io.EverestObject.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getMaxusn();
  if (f !== 0) {
    writer.writeInt64(
        3,
        f
    );
  }
};


/**
 * repeated EverestObject everestObjects = 1;
 * @return {!Array<!proto.io.EverestObject>}
 */
proto.io.ListEverestObjectsResponse.prototype.getEverestobjectsList = function () {
  return /** @type{!Array<!proto.io.EverestObject>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.EverestObject, 1));
};


/** @param {!Array<!proto.io.EverestObject>} value */
proto.io.ListEverestObjectsResponse.prototype.setEverestobjectsList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.io.EverestObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.EverestObject}
 */
proto.io.ListEverestObjectsResponse.prototype.addEverestobjects = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.io.EverestObject, opt_index);
};


proto.io.ListEverestObjectsResponse.prototype.clearEverestobjectsList = function () {
  this.setEverestobjectsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.io.ListEverestObjectsResponse.prototype.getNextPageToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.ListEverestObjectsResponse.prototype.setNextPageToken = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 maxUSN = 3;
 * @return {number}
 */
proto.io.ListEverestObjectsResponse.prototype.getMaxusn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.io.ListEverestObjectsResponse.prototype.setMaxusn = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.GetEverestObjectRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.GetEverestObjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.GetEverestObjectRequest.displayName = 'proto.io.GetEverestObjectRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.GetEverestObjectRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.GetEverestObjectRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.GetEverestObjectRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.GetEverestObjectRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.GetEverestObjectRequest}
 */
proto.io.GetEverestObjectRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.GetEverestObjectRequest;
  return proto.io.GetEverestObjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.GetEverestObjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.GetEverestObjectRequest}
 */
proto.io.GetEverestObjectRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.GetEverestObjectRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.GetEverestObjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.GetEverestObjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.GetEverestObjectRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.io.GetEverestObjectRequest.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.GetEverestObjectRequest.prototype.setName = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.CreateEverestObjectRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.CreateEverestObjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.CreateEverestObjectRequest.displayName = 'proto.io.CreateEverestObjectRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.CreateEverestObjectRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.CreateEverestObjectRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.CreateEverestObjectRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.CreateEverestObjectRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
      everestObjectKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
      everestObject: (f = msg.getEverestObject()) && proto.io.EverestObject.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.CreateEverestObjectRequest}
 */
proto.io.CreateEverestObjectRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.CreateEverestObjectRequest;
  return proto.io.CreateEverestObjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.CreateEverestObjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.CreateEverestObjectRequest}
 */
proto.io.CreateEverestObjectRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setParent(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEverestObjectKey(value);
        break;
      case 3:
        var value = new proto.io.EverestObject;
        reader.readMessage(value, proto.io.EverestObject.deserializeBinaryFromReader);
        msg.setEverestObject(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.CreateEverestObjectRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.CreateEverestObjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.CreateEverestObjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.CreateEverestObjectRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getEverestObjectKey();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getEverestObject();
  if (f != null) {
    writer.writeMessage(
        3,
        f,
        proto.io.EverestObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.io.CreateEverestObjectRequest.prototype.getParent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.CreateEverestObjectRequest.prototype.setParent = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string everest_object_key = 2;
 * @return {string}
 */
proto.io.CreateEverestObjectRequest.prototype.getEverestObjectKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.CreateEverestObjectRequest.prototype.setEverestObjectKey = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional EverestObject everest_object = 3;
 * @return {?proto.io.EverestObject}
 */
proto.io.CreateEverestObjectRequest.prototype.getEverestObject = function () {
  return /** @type{?proto.io.EverestObject} */ (
      jspb.Message.getWrapperField(this, proto.io.EverestObject, 3));
};


/** @param {?proto.io.EverestObject|undefined} value */
proto.io.CreateEverestObjectRequest.prototype.setEverestObject = function (value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.io.CreateEverestObjectRequest.prototype.clearEverestObject = function () {
  this.setEverestObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.CreateEverestObjectRequest.prototype.hasEverestObject = function () {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.CreateEverestObjectResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.CreateEverestObjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.CreateEverestObjectResponse.displayName = 'proto.io.CreateEverestObjectResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.CreateEverestObjectResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.CreateEverestObjectResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.CreateEverestObjectResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.CreateEverestObjectResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      key: jspb.Message.getFieldWithDefault(msg, 1, ""),
      created: jspb.Message.getFieldWithDefault(msg, 2, 0),
      usn: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.CreateEverestObjectResponse}
 */
proto.io.CreateEverestObjectResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.CreateEverestObjectResponse;
  return proto.io.CreateEverestObjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.CreateEverestObjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.CreateEverestObjectResponse}
 */
proto.io.CreateEverestObjectResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setCreated(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setUsn(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.CreateEverestObjectResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.CreateEverestObjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.CreateEverestObjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.CreateEverestObjectResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
        2,
        f
    );
  }
  f = message.getUsn();
  if (f !== 0) {
    writer.writeInt64(
        3,
        f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.io.CreateEverestObjectResponse.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.CreateEverestObjectResponse.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created = 2;
 * @return {number}
 */
proto.io.CreateEverestObjectResponse.prototype.getCreated = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.io.CreateEverestObjectResponse.prototype.setCreated = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 USN = 3;
 * @return {number}
 */
proto.io.CreateEverestObjectResponse.prototype.getUsn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.io.CreateEverestObjectResponse.prototype.setUsn = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.UpdateEverestObjectRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.UpdateEverestObjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.UpdateEverestObjectRequest.displayName = 'proto.io.UpdateEverestObjectRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.UpdateEverestObjectRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.UpdateEverestObjectRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.UpdateEverestObjectRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.UpdateEverestObjectRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
      key: jspb.Message.getFieldWithDefault(msg, 2, ""),
      usn: jspb.Message.getFieldWithDefault(msg, 3, 0),
      modifiedpropertiesMap: (f = msg.getModifiedpropertiesMap()) ? f.toObject(includeInstance, proto.io.Value.toObject) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.UpdateEverestObjectRequest}
 */
proto.io.UpdateEverestObjectRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.UpdateEverestObjectRequest;
  return proto.io.UpdateEverestObjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.UpdateEverestObjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.UpdateEverestObjectRequest}
 */
proto.io.UpdateEverestObjectRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setParent(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setUsn(value);
        break;
      case 4:
        var value = msg.getModifiedpropertiesMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.io.Value.deserializeBinaryFromReader, "");
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.UpdateEverestObjectRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.UpdateEverestObjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.UpdateEverestObjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.UpdateEverestObjectRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getUsn();
  if (f !== 0) {
    writer.writeInt64(
        3,
        f
    );
  }
  f = message.getModifiedpropertiesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.io.Value.serializeBinaryToWriter);
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.io.UpdateEverestObjectRequest.prototype.getParent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.UpdateEverestObjectRequest.prototype.setParent = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.io.UpdateEverestObjectRequest.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.UpdateEverestObjectRequest.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 USN = 3;
 * @return {number}
 */
proto.io.UpdateEverestObjectRequest.prototype.getUsn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.io.UpdateEverestObjectRequest.prototype.setUsn = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * map<string, Value> modifiedProperties = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.io.Value>}
 */
proto.io.UpdateEverestObjectRequest.prototype.getModifiedpropertiesMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.io.Value>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
          proto.io.Value));
};


proto.io.UpdateEverestObjectRequest.prototype.clearModifiedpropertiesMap = function () {
  this.getModifiedpropertiesMap().clear();
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.UpdateEverestObjectResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.UpdateEverestObjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.UpdateEverestObjectResponse.displayName = 'proto.io.UpdateEverestObjectResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.UpdateEverestObjectResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.UpdateEverestObjectResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.UpdateEverestObjectResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.UpdateEverestObjectResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      success: (f = msg.getSuccess()) && proto.io.UpdateSuccess.toObject(includeInstance, f),
      conflict: (f = msg.getConflict()) && proto.io.UpdateConflict.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.UpdateEverestObjectResponse}
 */
proto.io.UpdateEverestObjectResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.UpdateEverestObjectResponse;
  return proto.io.UpdateEverestObjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.UpdateEverestObjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.UpdateEverestObjectResponse}
 */
proto.io.UpdateEverestObjectResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.UpdateSuccess;
        reader.readMessage(value, proto.io.UpdateSuccess.deserializeBinaryFromReader);
        msg.setSuccess(value);
        break;
      case 2:
        var value = new proto.io.UpdateConflict;
        reader.readMessage(value, proto.io.UpdateConflict.deserializeBinaryFromReader);
        msg.setConflict(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.UpdateEverestObjectResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.UpdateEverestObjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.UpdateEverestObjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.UpdateEverestObjectResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        proto.io.UpdateSuccess.serializeBinaryToWriter
    );
  }
  f = message.getConflict();
  if (f != null) {
    writer.writeMessage(
        2,
        f,
        proto.io.UpdateConflict.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateSuccess success = 1;
 * @return {?proto.io.UpdateSuccess}
 */
proto.io.UpdateEverestObjectResponse.prototype.getSuccess = function () {
  return /** @type{?proto.io.UpdateSuccess} */ (
      jspb.Message.getWrapperField(this, proto.io.UpdateSuccess, 1));
};


/** @param {?proto.io.UpdateSuccess|undefined} value */
proto.io.UpdateEverestObjectResponse.prototype.setSuccess = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.io.UpdateEverestObjectResponse.prototype.clearSuccess = function () {
  this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.UpdateEverestObjectResponse.prototype.hasSuccess = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UpdateConflict conflict = 2;
 * @return {?proto.io.UpdateConflict}
 */
proto.io.UpdateEverestObjectResponse.prototype.getConflict = function () {
  return /** @type{?proto.io.UpdateConflict} */ (
      jspb.Message.getWrapperField(this, proto.io.UpdateConflict, 2));
};


/** @param {?proto.io.UpdateConflict|undefined} value */
proto.io.UpdateEverestObjectResponse.prototype.setConflict = function (value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.io.UpdateEverestObjectResponse.prototype.clearConflict = function () {
  this.setConflict(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.UpdateEverestObjectResponse.prototype.hasConflict = function () {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.UpdateSuccess = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.UpdateSuccess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.UpdateSuccess.displayName = 'proto.io.UpdateSuccess';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.UpdateSuccess.prototype.toObject = function (opt_includeInstance) {
    return proto.io.UpdateSuccess.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.UpdateSuccess} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.UpdateSuccess.toObject = function (includeInstance, msg) {
    var f, obj = {
      usn: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.UpdateSuccess}
 */
proto.io.UpdateSuccess.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.UpdateSuccess;
  return proto.io.UpdateSuccess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.UpdateSuccess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.UpdateSuccess}
 */
proto.io.UpdateSuccess.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setUsn(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.UpdateSuccess.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.UpdateSuccess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.UpdateSuccess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.UpdateSuccess.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUsn();
  if (f !== 0) {
    writer.writeInt64(
        1,
        f
    );
  }
};


/**
 * optional int64 USN = 1;
 * @return {number}
 */
proto.io.UpdateSuccess.prototype.getUsn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.io.UpdateSuccess.prototype.setUsn = function (value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.UpdateConflict = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.UpdateConflict, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.UpdateConflict.displayName = 'proto.io.UpdateConflict';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.UpdateConflict.prototype.toObject = function (opt_includeInstance) {
    return proto.io.UpdateConflict.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.UpdateConflict} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.UpdateConflict.toObject = function (includeInstance, msg) {
    var f, obj = {
      everestObject: (f = msg.getEverestObject()) && proto.io.EverestObject.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.UpdateConflict}
 */
proto.io.UpdateConflict.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.UpdateConflict;
  return proto.io.UpdateConflict.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.UpdateConflict} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.UpdateConflict}
 */
proto.io.UpdateConflict.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.EverestObject;
        reader.readMessage(value, proto.io.EverestObject.deserializeBinaryFromReader);
        msg.setEverestObject(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.UpdateConflict.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.UpdateConflict.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.UpdateConflict} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.UpdateConflict.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEverestObject();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        proto.io.EverestObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional EverestObject everest_object = 1;
 * @return {?proto.io.EverestObject}
 */
proto.io.UpdateConflict.prototype.getEverestObject = function () {
  return /** @type{?proto.io.EverestObject} */ (
      jspb.Message.getWrapperField(this, proto.io.EverestObject, 1));
};


/** @param {?proto.io.EverestObject|undefined} value */
proto.io.UpdateConflict.prototype.setEverestObject = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.io.UpdateConflict.prototype.clearEverestObject = function () {
  this.setEverestObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.UpdateConflict.prototype.hasEverestObject = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.DeleteEverestObjectRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.DeleteEverestObjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.DeleteEverestObjectRequest.displayName = 'proto.io.DeleteEverestObjectRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.DeleteEverestObjectRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.DeleteEverestObjectRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.DeleteEverestObjectRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.DeleteEverestObjectRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.DeleteEverestObjectRequest}
 */
proto.io.DeleteEverestObjectRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.DeleteEverestObjectRequest;
  return proto.io.DeleteEverestObjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.DeleteEverestObjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.DeleteEverestObjectRequest}
 */
proto.io.DeleteEverestObjectRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.DeleteEverestObjectRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.DeleteEverestObjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.DeleteEverestObjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.DeleteEverestObjectRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.io.DeleteEverestObjectRequest.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.DeleteEverestObjectRequest.prototype.setName = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.DeleteEverestObjectResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.DeleteEverestObjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.DeleteEverestObjectResponse.displayName = 'proto.io.DeleteEverestObjectResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.DeleteEverestObjectResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.DeleteEverestObjectResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.DeleteEverestObjectResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.DeleteEverestObjectResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      usn: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.DeleteEverestObjectResponse}
 */
proto.io.DeleteEverestObjectResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.DeleteEverestObjectResponse;
  return proto.io.DeleteEverestObjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.DeleteEverestObjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.DeleteEverestObjectResponse}
 */
proto.io.DeleteEverestObjectResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setUsn(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.DeleteEverestObjectResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.DeleteEverestObjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.DeleteEverestObjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.DeleteEverestObjectResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUsn();
  if (f !== 0) {
    writer.writeInt64(
        1,
        f
    );
  }
};


/**
 * optional int64 USN = 1;
 * @return {number}
 */
proto.io.DeleteEverestObjectResponse.prototype.getUsn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.io.DeleteEverestObjectResponse.prototype.setUsn = function (value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.BatchWriteRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.io.BatchWriteRequest.repeatedFields_, null);
};
goog.inherits(proto.io.BatchWriteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.BatchWriteRequest.displayName = 'proto.io.BatchWriteRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.io.BatchWriteRequest.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.BatchWriteRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.BatchWriteRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.BatchWriteRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.BatchWriteRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      batchList: jspb.Message.toObjectList(msg.getBatchList(),
          proto.io.WriteRequest.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.BatchWriteRequest}
 */
proto.io.BatchWriteRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.BatchWriteRequest;
  return proto.io.BatchWriteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.BatchWriteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.BatchWriteRequest}
 */
proto.io.BatchWriteRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.WriteRequest;
        reader.readMessage(value, proto.io.WriteRequest.deserializeBinaryFromReader);
        msg.addBatch(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.BatchWriteRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.BatchWriteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.BatchWriteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.BatchWriteRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getBatchList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.io.WriteRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WriteRequest batch = 1;
 * @return {!Array<!proto.io.WriteRequest>}
 */
proto.io.BatchWriteRequest.prototype.getBatchList = function () {
  return /** @type{!Array<!proto.io.WriteRequest>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.WriteRequest, 1));
};


/** @param {!Array<!proto.io.WriteRequest>} value */
proto.io.BatchWriteRequest.prototype.setBatchList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.io.WriteRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.WriteRequest}
 */
proto.io.BatchWriteRequest.prototype.addBatch = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.io.WriteRequest, opt_index);
};


proto.io.BatchWriteRequest.prototype.clearBatchList = function () {
  this.setBatchList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.BatchWriteResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.io.BatchWriteResponse.repeatedFields_, null);
};
goog.inherits(proto.io.BatchWriteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.BatchWriteResponse.displayName = 'proto.io.BatchWriteResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.io.BatchWriteResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.BatchWriteResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.BatchWriteResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.BatchWriteResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.BatchWriteResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      writeresultsList: jspb.Message.toObjectList(msg.getWriteresultsList(),
          proto.io.WriteResult.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.BatchWriteResponse}
 */
proto.io.BatchWriteResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.BatchWriteResponse;
  return proto.io.BatchWriteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.BatchWriteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.BatchWriteResponse}
 */
proto.io.BatchWriteResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.WriteResult;
        reader.readMessage(value, proto.io.WriteResult.deserializeBinaryFromReader);
        msg.addWriteresults(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.BatchWriteResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.BatchWriteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.BatchWriteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.BatchWriteResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getWriteresultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.io.WriteResult.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WriteResult writeResults = 1;
 * @return {!Array<!proto.io.WriteResult>}
 */
proto.io.BatchWriteResponse.prototype.getWriteresultsList = function () {
  return /** @type{!Array<!proto.io.WriteResult>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.WriteResult, 1));
};


/** @param {!Array<!proto.io.WriteResult>} value */
proto.io.BatchWriteResponse.prototype.setWriteresultsList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.io.WriteResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.WriteResult}
 */
proto.io.BatchWriteResponse.prototype.addWriteresults = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.io.WriteResult, opt_index);
};


proto.io.BatchWriteResponse.prototype.clearWriteresultsList = function () {
  this.setWriteresultsList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.WriteRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.WriteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.WriteRequest.displayName = 'proto.io.WriteRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.WriteRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.WriteRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.WriteRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.WriteRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
      write: (f = msg.getWrite()) && proto.io.Write.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.WriteRequest}
 */
proto.io.WriteRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.WriteRequest;
  return proto.io.WriteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.WriteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.WriteRequest}
 */
proto.io.WriteRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setParent(value);
        break;
      case 2:
        var value = new proto.io.Write;
        reader.readMessage(value, proto.io.Write.deserializeBinaryFromReader);
        msg.setWrite(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.WriteRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.WriteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.WriteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.WriteRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getWrite();
  if (f != null) {
    writer.writeMessage(
        2,
        f,
        proto.io.Write.serializeBinaryToWriter
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.io.WriteRequest.prototype.getParent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.WriteRequest.prototype.setParent = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Write write = 2;
 * @return {?proto.io.Write}
 */
proto.io.WriteRequest.prototype.getWrite = function () {
  return /** @type{?proto.io.Write} */ (
      jspb.Message.getWrapperField(this, proto.io.Write, 2));
};


/** @param {?proto.io.Write|undefined} value */
proto.io.WriteRequest.prototype.setWrite = function (value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.io.WriteRequest.prototype.clearWrite = function () {
  this.setWrite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.WriteRequest.prototype.hasWrite = function () {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.WriteResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.WriteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.WriteResponse.displayName = 'proto.io.WriteResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.WriteResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.WriteResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.WriteResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.WriteResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      writeresult: (f = msg.getWriteresult()) && proto.io.WriteResult.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.WriteResponse}
 */
proto.io.WriteResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.WriteResponse;
  return proto.io.WriteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.WriteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.WriteResponse}
 */
proto.io.WriteResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.WriteResult;
        reader.readMessage(value, proto.io.WriteResult.deserializeBinaryFromReader);
        msg.setWriteresult(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.WriteResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.WriteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.WriteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.WriteResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getWriteresult();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        proto.io.WriteResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional WriteResult writeResult = 1;
 * @return {?proto.io.WriteResult}
 */
proto.io.WriteResponse.prototype.getWriteresult = function () {
  return /** @type{?proto.io.WriteResult} */ (
      jspb.Message.getWrapperField(this, proto.io.WriteResult, 1));
};


/** @param {?proto.io.WriteResult|undefined} value */
proto.io.WriteResponse.prototype.setWriteresult = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.io.WriteResponse.prototype.clearWriteresult = function () {
  this.setWriteresult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.WriteResponse.prototype.hasWriteresult = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.SyncEverestObjectsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.SyncEverestObjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.SyncEverestObjectsRequest.displayName = 'proto.io.SyncEverestObjectsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.SyncEverestObjectsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.SyncEverestObjectsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.SyncEverestObjectsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.SyncEverestObjectsRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
      pageToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
      maxusn: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.SyncEverestObjectsRequest}
 */
proto.io.SyncEverestObjectsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.SyncEverestObjectsRequest;
  return proto.io.SyncEverestObjectsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.SyncEverestObjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.SyncEverestObjectsRequest}
 */
proto.io.SyncEverestObjectsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setParent(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setPageToken(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setMaxusn(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.SyncEverestObjectsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.SyncEverestObjectsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.SyncEverestObjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.SyncEverestObjectsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        2,
        f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getMaxusn();
  if (f !== 0) {
    writer.writeInt64(
        4,
        f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.io.SyncEverestObjectsRequest.prototype.getParent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.SyncEverestObjectsRequest.prototype.setParent = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.io.SyncEverestObjectsRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.io.SyncEverestObjectsRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.io.SyncEverestObjectsRequest.prototype.getPageToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.io.SyncEverestObjectsRequest.prototype.setPageToken = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 maxUSN = 4;
 * @return {number}
 */
proto.io.SyncEverestObjectsRequest.prototype.getMaxusn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.io.SyncEverestObjectsRequest.prototype.setMaxusn = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.SyncEverestObjectsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.SyncEverestObjectsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.SyncEverestObjectsResponse.displayName = 'proto.io.SyncEverestObjectsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.SyncEverestObjectsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.SyncEverestObjectsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.SyncEverestObjectsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.SyncEverestObjectsResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
      maxusn: jspb.Message.getFieldWithDefault(msg, 2, 0),
      changeevent: (f = msg.getChangeevent()) && proto.io.ChangeEvent.toObject(includeInstance, f),
      nextPageToken: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.SyncEverestObjectsResponse}
 */
proto.io.SyncEverestObjectsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.SyncEverestObjectsResponse;
  return proto.io.SyncEverestObjectsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.SyncEverestObjectsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.SyncEverestObjectsResponse}
 */
proto.io.SyncEverestObjectsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setParent(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setMaxusn(value);
        break;
      case 3:
        var value = new proto.io.ChangeEvent;
        reader.readMessage(value, proto.io.ChangeEvent.deserializeBinaryFromReader);
        msg.setChangeevent(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextPageToken(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.SyncEverestObjectsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.SyncEverestObjectsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.SyncEverestObjectsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.SyncEverestObjectsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getMaxusn();
  if (f !== 0) {
    writer.writeInt64(
        2,
        f
    );
  }
  f = message.getChangeevent();
  if (f != null) {
    writer.writeMessage(
        3,
        f,
        proto.io.ChangeEvent.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.io.SyncEverestObjectsResponse.prototype.getParent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.SyncEverestObjectsResponse.prototype.setParent = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 maxUSN = 2;
 * @return {number}
 */
proto.io.SyncEverestObjectsResponse.prototype.getMaxusn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.io.SyncEverestObjectsResponse.prototype.setMaxusn = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ChangeEvent changeEvent = 3;
 * @return {?proto.io.ChangeEvent}
 */
proto.io.SyncEverestObjectsResponse.prototype.getChangeevent = function () {
  return /** @type{?proto.io.ChangeEvent} */ (
      jspb.Message.getWrapperField(this, proto.io.ChangeEvent, 3));
};


/** @param {?proto.io.ChangeEvent|undefined} value */
proto.io.SyncEverestObjectsResponse.prototype.setChangeevent = function (value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.io.SyncEverestObjectsResponse.prototype.clearChangeevent = function () {
  this.setChangeevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.SyncEverestObjectsResponse.prototype.hasChangeevent = function () {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string next_page_token = 4;
 * @return {string}
 */
proto.io.SyncEverestObjectsResponse.prototype.getNextPageToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.io.SyncEverestObjectsResponse.prototype.setNextPageToken = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.ChangeEvent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.io.ChangeEvent.repeatedFields_, null);
};
goog.inherits(proto.io.ChangeEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.ChangeEvent.displayName = 'proto.io.ChangeEvent';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.io.ChangeEvent.repeatedFields_ = [1, 2, 3];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.ChangeEvent.prototype.toObject = function (opt_includeInstance) {
    return proto.io.ChangeEvent.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.ChangeEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.ChangeEvent.toObject = function (includeInstance, msg) {
    var f, obj = {
      insertionsList: jspb.Message.toObjectList(msg.getInsertionsList(),
          proto.io.InsertionEvent.toObject, includeInstance),
      deletionsList: jspb.Message.toObjectList(msg.getDeletionsList(),
          proto.io.DeletionEvent.toObject, includeInstance),
      updatesList: jspb.Message.toObjectList(msg.getUpdatesList(),
          proto.io.UpdateEvent.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.ChangeEvent}
 */
proto.io.ChangeEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.ChangeEvent;
  return proto.io.ChangeEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.ChangeEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.ChangeEvent}
 */
proto.io.ChangeEvent.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.InsertionEvent;
        reader.readMessage(value, proto.io.InsertionEvent.deserializeBinaryFromReader);
        msg.addInsertions(value);
        break;
      case 2:
        var value = new proto.io.DeletionEvent;
        reader.readMessage(value, proto.io.DeletionEvent.deserializeBinaryFromReader);
        msg.addDeletions(value);
        break;
      case 3:
        var value = new proto.io.UpdateEvent;
        reader.readMessage(value, proto.io.UpdateEvent.deserializeBinaryFromReader);
        msg.addUpdates(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.ChangeEvent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.ChangeEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.ChangeEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.ChangeEvent.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getInsertionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.io.InsertionEvent.serializeBinaryToWriter
    );
  }
  f = message.getDeletionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        2,
        f,
        proto.io.DeletionEvent.serializeBinaryToWriter
    );
  }
  f = message.getUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        3,
        f,
        proto.io.UpdateEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InsertionEvent insertions = 1;
 * @return {!Array<!proto.io.InsertionEvent>}
 */
proto.io.ChangeEvent.prototype.getInsertionsList = function () {
  return /** @type{!Array<!proto.io.InsertionEvent>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.InsertionEvent, 1));
};


/** @param {!Array<!proto.io.InsertionEvent>} value */
proto.io.ChangeEvent.prototype.setInsertionsList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.io.InsertionEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.InsertionEvent}
 */
proto.io.ChangeEvent.prototype.addInsertions = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.io.InsertionEvent, opt_index);
};


proto.io.ChangeEvent.prototype.clearInsertionsList = function () {
  this.setInsertionsList([]);
};


/**
 * repeated DeletionEvent deletions = 2;
 * @return {!Array<!proto.io.DeletionEvent>}
 */
proto.io.ChangeEvent.prototype.getDeletionsList = function () {
  return /** @type{!Array<!proto.io.DeletionEvent>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.DeletionEvent, 2));
};


/** @param {!Array<!proto.io.DeletionEvent>} value */
proto.io.ChangeEvent.prototype.setDeletionsList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.io.DeletionEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.DeletionEvent}
 */
proto.io.ChangeEvent.prototype.addDeletions = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.io.DeletionEvent, opt_index);
};


proto.io.ChangeEvent.prototype.clearDeletionsList = function () {
  this.setDeletionsList([]);
};


/**
 * repeated UpdateEvent updates = 3;
 * @return {!Array<!proto.io.UpdateEvent>}
 */
proto.io.ChangeEvent.prototype.getUpdatesList = function () {
  return /** @type{!Array<!proto.io.UpdateEvent>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.UpdateEvent, 3));
};


/** @param {!Array<!proto.io.UpdateEvent>} value */
proto.io.ChangeEvent.prototype.setUpdatesList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.io.UpdateEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.UpdateEvent}
 */
proto.io.ChangeEvent.prototype.addUpdates = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.io.UpdateEvent, opt_index);
};


proto.io.ChangeEvent.prototype.clearUpdatesList = function () {
  this.setUpdatesList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.InsertionEvent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.InsertionEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.InsertionEvent.displayName = 'proto.io.InsertionEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.InsertionEvent.prototype.toObject = function (opt_includeInstance) {
    return proto.io.InsertionEvent.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.InsertionEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.InsertionEvent.toObject = function (includeInstance, msg) {
    var f, obj = {
      everestObject: (f = msg.getEverestObject()) && proto.io.EverestObject.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.InsertionEvent}
 */
proto.io.InsertionEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.InsertionEvent;
  return proto.io.InsertionEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.InsertionEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.InsertionEvent}
 */
proto.io.InsertionEvent.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.EverestObject;
        reader.readMessage(value, proto.io.EverestObject.deserializeBinaryFromReader);
        msg.setEverestObject(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.InsertionEvent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.InsertionEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.InsertionEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.InsertionEvent.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEverestObject();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        proto.io.EverestObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional EverestObject everest_object = 1;
 * @return {?proto.io.EverestObject}
 */
proto.io.InsertionEvent.prototype.getEverestObject = function () {
  return /** @type{?proto.io.EverestObject} */ (
      jspb.Message.getWrapperField(this, proto.io.EverestObject, 1));
};


/** @param {?proto.io.EverestObject|undefined} value */
proto.io.InsertionEvent.prototype.setEverestObject = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.io.InsertionEvent.prototype.clearEverestObject = function () {
  this.setEverestObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.InsertionEvent.prototype.hasEverestObject = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.DeletionEvent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.DeletionEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.DeletionEvent.displayName = 'proto.io.DeletionEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.DeletionEvent.prototype.toObject = function (opt_includeInstance) {
    return proto.io.DeletionEvent.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.DeletionEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.DeletionEvent.toObject = function (includeInstance, msg) {
    var f, obj = {
      key: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.DeletionEvent}
 */
proto.io.DeletionEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.DeletionEvent;
  return proto.io.DeletionEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.DeletionEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.DeletionEvent}
 */
proto.io.DeletionEvent.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.DeletionEvent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.DeletionEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.DeletionEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.DeletionEvent.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.io.DeletionEvent.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.DeletionEvent.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.UpdateEvent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.UpdateEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.UpdateEvent.displayName = 'proto.io.UpdateEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.UpdateEvent.prototype.toObject = function (opt_includeInstance) {
    return proto.io.UpdateEvent.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.UpdateEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.UpdateEvent.toObject = function (includeInstance, msg) {
    var f, obj = {
      everestObject: (f = msg.getEverestObject()) && proto.io.EverestObject.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.UpdateEvent}
 */
proto.io.UpdateEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.UpdateEvent;
  return proto.io.UpdateEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.UpdateEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.UpdateEvent}
 */
proto.io.UpdateEvent.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.EverestObject;
        reader.readMessage(value, proto.io.EverestObject.deserializeBinaryFromReader);
        msg.setEverestObject(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.UpdateEvent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.UpdateEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.UpdateEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.UpdateEvent.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEverestObject();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        proto.io.EverestObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional EverestObject everest_object = 1;
 * @return {?proto.io.EverestObject}
 */
proto.io.UpdateEvent.prototype.getEverestObject = function () {
  return /** @type{?proto.io.EverestObject} */ (
      jspb.Message.getWrapperField(this, proto.io.EverestObject, 1));
};


/** @param {?proto.io.EverestObject|undefined} value */
proto.io.UpdateEvent.prototype.setEverestObject = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.io.UpdateEvent.prototype.clearEverestObject = function () {
  this.setEverestObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.UpdateEvent.prototype.hasEverestObject = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.SubscribeRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.SubscribeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.SubscribeRequest.displayName = 'proto.io.SubscribeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.SubscribeRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.SubscribeRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.SubscribeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.SubscribeRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      bucket: jspb.Message.getFieldWithDefault(msg, 1, ""),
      type: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.SubscribeRequest}
 */
proto.io.SubscribeRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.SubscribeRequest;
  return proto.io.SubscribeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.SubscribeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.SubscribeRequest}
 */
proto.io.SubscribeRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setBucket(value);
        break;
      case 2:
        var value = /** @type {!proto.io.SubscribeRequest.Type} */ (reader.readEnum());
        msg.setType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.SubscribeRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.SubscribeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.SubscribeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.SubscribeRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getBucket();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
        2,
        f
    );
  }
};


/**
 * @enum {number}
 */
proto.io.SubscribeRequest.Type = {
  SUBSCRIBE: 0,
  UNSUBSCRIBE: 1,
  CLOSE: 2
};

/**
 * optional string bucket = 1;
 * @return {string}
 */
proto.io.SubscribeRequest.prototype.getBucket = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.SubscribeRequest.prototype.setBucket = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.io.SubscribeRequest.Type}
 */
proto.io.SubscribeRequest.prototype.getType = function () {
  return /** @type {!proto.io.SubscribeRequest.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.io.SubscribeRequest.Type} value */
proto.io.SubscribeRequest.prototype.setType = function (value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.SubscriptionEvent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.io.SubscriptionEvent.repeatedFields_, null);
};
goog.inherits(proto.io.SubscriptionEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.SubscriptionEvent.displayName = 'proto.io.SubscriptionEvent';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.io.SubscriptionEvent.repeatedFields_ = [3];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.SubscriptionEvent.prototype.toObject = function (opt_includeInstance) {
    return proto.io.SubscriptionEvent.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.SubscriptionEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.SubscriptionEvent.toObject = function (includeInstance, msg) {
    var f, obj = {
      bucket: jspb.Message.getFieldWithDefault(msg, 1, ""),
      type: jspb.Message.getFieldWithDefault(msg, 2, 0),
      everestobjectkeysList: jspb.Message.getRepeatedField(msg, 3)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.SubscriptionEvent}
 */
proto.io.SubscriptionEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.SubscriptionEvent;
  return proto.io.SubscriptionEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.SubscriptionEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.SubscriptionEvent}
 */
proto.io.SubscriptionEvent.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setBucket(value);
        break;
      case 2:
        var value = /** @type {!proto.io.SubscriptionEvent.Type} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.addEverestobjectkeys(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.SubscriptionEvent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.SubscriptionEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.SubscriptionEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.SubscriptionEvent.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getBucket();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
        2,
        f
    );
  }
  f = message.getEverestobjectkeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
        3,
        f
    );
  }
};


/**
 * @enum {number}
 */
proto.io.SubscriptionEvent.Type = {
  NEW_DATA_AVAILABLE: 0,
  INSERT: 1,
  UPDATE: 2,
  DELETE: 3
};

/**
 * optional string bucket = 1;
 * @return {string}
 */
proto.io.SubscriptionEvent.prototype.getBucket = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.SubscriptionEvent.prototype.setBucket = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.io.SubscriptionEvent.Type}
 */
proto.io.SubscriptionEvent.prototype.getType = function () {
  return /** @type {!proto.io.SubscriptionEvent.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.io.SubscriptionEvent.Type} value */
proto.io.SubscriptionEvent.prototype.setType = function (value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated string everestObjectKeys = 3;
 * @return {!Array<string>}
 */
proto.io.SubscriptionEvent.prototype.getEverestobjectkeysList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<string>} value */
proto.io.SubscriptionEvent.prototype.setEverestobjectkeysList = function (value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.io.SubscriptionEvent.prototype.addEverestobjectkeys = function (value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


proto.io.SubscriptionEvent.prototype.clearEverestobjectkeysList = function () {
  this.setEverestobjectkeysList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.BatchCrudRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.io.BatchCrudRequest.repeatedFields_, null);
};
goog.inherits(proto.io.BatchCrudRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.BatchCrudRequest.displayName = 'proto.io.BatchCrudRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.io.BatchCrudRequest.repeatedFields_ = [1, 2, 3];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.BatchCrudRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.BatchCrudRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.BatchCrudRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.BatchCrudRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      createrequestsList: jspb.Message.toObjectList(msg.getCreaterequestsList(),
          proto.io.CreateEverestObjectRequest.toObject, includeInstance),
      updaterequestsList: jspb.Message.toObjectList(msg.getUpdaterequestsList(),
          proto.io.UpdateEverestObjectRequest.toObject, includeInstance),
      deleterequestsList: jspb.Message.toObjectList(msg.getDeleterequestsList(),
          proto.io.DeleteEverestObjectRequest.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.BatchCrudRequest}
 */
proto.io.BatchCrudRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.BatchCrudRequest;
  return proto.io.BatchCrudRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.BatchCrudRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.BatchCrudRequest}
 */
proto.io.BatchCrudRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.CreateEverestObjectRequest;
        reader.readMessage(value, proto.io.CreateEverestObjectRequest.deserializeBinaryFromReader);
        msg.addCreaterequests(value);
        break;
      case 2:
        var value = new proto.io.UpdateEverestObjectRequest;
        reader.readMessage(value, proto.io.UpdateEverestObjectRequest.deserializeBinaryFromReader);
        msg.addUpdaterequests(value);
        break;
      case 3:
        var value = new proto.io.DeleteEverestObjectRequest;
        reader.readMessage(value, proto.io.DeleteEverestObjectRequest.deserializeBinaryFromReader);
        msg.addDeleterequests(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.BatchCrudRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.BatchCrudRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.BatchCrudRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.BatchCrudRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCreaterequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.io.CreateEverestObjectRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdaterequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        2,
        f,
        proto.io.UpdateEverestObjectRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeleterequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        3,
        f,
        proto.io.DeleteEverestObjectRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CreateEverestObjectRequest createRequests = 1;
 * @return {!Array<!proto.io.CreateEverestObjectRequest>}
 */
proto.io.BatchCrudRequest.prototype.getCreaterequestsList = function () {
  return /** @type{!Array<!proto.io.CreateEverestObjectRequest>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.CreateEverestObjectRequest, 1));
};


/** @param {!Array<!proto.io.CreateEverestObjectRequest>} value */
proto.io.BatchCrudRequest.prototype.setCreaterequestsList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.io.CreateEverestObjectRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.CreateEverestObjectRequest}
 */
proto.io.BatchCrudRequest.prototype.addCreaterequests = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.io.CreateEverestObjectRequest, opt_index);
};


proto.io.BatchCrudRequest.prototype.clearCreaterequestsList = function () {
  this.setCreaterequestsList([]);
};


/**
 * repeated UpdateEverestObjectRequest updateRequests = 2;
 * @return {!Array<!proto.io.UpdateEverestObjectRequest>}
 */
proto.io.BatchCrudRequest.prototype.getUpdaterequestsList = function () {
  return /** @type{!Array<!proto.io.UpdateEverestObjectRequest>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.UpdateEverestObjectRequest, 2));
};


/** @param {!Array<!proto.io.UpdateEverestObjectRequest>} value */
proto.io.BatchCrudRequest.prototype.setUpdaterequestsList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.io.UpdateEverestObjectRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.UpdateEverestObjectRequest}
 */
proto.io.BatchCrudRequest.prototype.addUpdaterequests = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.io.UpdateEverestObjectRequest, opt_index);
};


proto.io.BatchCrudRequest.prototype.clearUpdaterequestsList = function () {
  this.setUpdaterequestsList([]);
};


/**
 * repeated DeleteEverestObjectRequest deleteRequests = 3;
 * @return {!Array<!proto.io.DeleteEverestObjectRequest>}
 */
proto.io.BatchCrudRequest.prototype.getDeleterequestsList = function () {
  return /** @type{!Array<!proto.io.DeleteEverestObjectRequest>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.DeleteEverestObjectRequest, 3));
};


/** @param {!Array<!proto.io.DeleteEverestObjectRequest>} value */
proto.io.BatchCrudRequest.prototype.setDeleterequestsList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.io.DeleteEverestObjectRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.DeleteEverestObjectRequest}
 */
proto.io.BatchCrudRequest.prototype.addDeleterequests = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.io.DeleteEverestObjectRequest, opt_index);
};


proto.io.BatchCrudRequest.prototype.clearDeleterequestsList = function () {
  this.setDeleterequestsList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.BatchCrudResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.io.BatchCrudResponse.repeatedFields_, null);
};
goog.inherits(proto.io.BatchCrudResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.BatchCrudResponse.displayName = 'proto.io.BatchCrudResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.io.BatchCrudResponse.repeatedFields_ = [1, 2, 3];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.BatchCrudResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.BatchCrudResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.BatchCrudResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.BatchCrudResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      createresponsesList: jspb.Message.toObjectList(msg.getCreateresponsesList(),
          proto.io.BatchCreateEverestObjectResponse.toObject, includeInstance),
      updateresponsesList: jspb.Message.toObjectList(msg.getUpdateresponsesList(),
          proto.io.BatchUpdateEverestObjectResponse.toObject, includeInstance),
      deleteresponsesList: jspb.Message.toObjectList(msg.getDeleteresponsesList(),
          proto.io.BatchDeleteEverestObjectResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.BatchCrudResponse}
 */
proto.io.BatchCrudResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.BatchCrudResponse;
  return proto.io.BatchCrudResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.BatchCrudResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.BatchCrudResponse}
 */
proto.io.BatchCrudResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.BatchCreateEverestObjectResponse;
        reader.readMessage(value, proto.io.BatchCreateEverestObjectResponse.deserializeBinaryFromReader);
        msg.addCreateresponses(value);
        break;
      case 2:
        var value = new proto.io.BatchUpdateEverestObjectResponse;
        reader.readMessage(value, proto.io.BatchUpdateEverestObjectResponse.deserializeBinaryFromReader);
        msg.addUpdateresponses(value);
        break;
      case 3:
        var value = new proto.io.BatchDeleteEverestObjectResponse;
        reader.readMessage(value, proto.io.BatchDeleteEverestObjectResponse.deserializeBinaryFromReader);
        msg.addDeleteresponses(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.BatchCrudResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.BatchCrudResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.BatchCrudResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.BatchCrudResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCreateresponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.io.BatchCreateEverestObjectResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdateresponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        2,
        f,
        proto.io.BatchUpdateEverestObjectResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeleteresponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        3,
        f,
        proto.io.BatchDeleteEverestObjectResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BatchCreateEverestObjectResponse createResponses = 1;
 * @return {!Array<!proto.io.BatchCreateEverestObjectResponse>}
 */
proto.io.BatchCrudResponse.prototype.getCreateresponsesList = function () {
  return /** @type{!Array<!proto.io.BatchCreateEverestObjectResponse>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.BatchCreateEverestObjectResponse, 1));
};


/** @param {!Array<!proto.io.BatchCreateEverestObjectResponse>} value */
proto.io.BatchCrudResponse.prototype.setCreateresponsesList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.io.BatchCreateEverestObjectResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.BatchCreateEverestObjectResponse}
 */
proto.io.BatchCrudResponse.prototype.addCreateresponses = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.io.BatchCreateEverestObjectResponse, opt_index);
};


proto.io.BatchCrudResponse.prototype.clearCreateresponsesList = function () {
  this.setCreateresponsesList([]);
};


/**
 * repeated BatchUpdateEverestObjectResponse updateResponses = 2;
 * @return {!Array<!proto.io.BatchUpdateEverestObjectResponse>}
 */
proto.io.BatchCrudResponse.prototype.getUpdateresponsesList = function () {
  return /** @type{!Array<!proto.io.BatchUpdateEverestObjectResponse>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.BatchUpdateEverestObjectResponse, 2));
};


/** @param {!Array<!proto.io.BatchUpdateEverestObjectResponse>} value */
proto.io.BatchCrudResponse.prototype.setUpdateresponsesList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.io.BatchUpdateEverestObjectResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.BatchUpdateEverestObjectResponse}
 */
proto.io.BatchCrudResponse.prototype.addUpdateresponses = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.io.BatchUpdateEverestObjectResponse, opt_index);
};


proto.io.BatchCrudResponse.prototype.clearUpdateresponsesList = function () {
  this.setUpdateresponsesList([]);
};


/**
 * repeated BatchDeleteEverestObjectResponse deleteResponses = 3;
 * @return {!Array<!proto.io.BatchDeleteEverestObjectResponse>}
 */
proto.io.BatchCrudResponse.prototype.getDeleteresponsesList = function () {
  return /** @type{!Array<!proto.io.BatchDeleteEverestObjectResponse>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.BatchDeleteEverestObjectResponse, 3));
};


/** @param {!Array<!proto.io.BatchDeleteEverestObjectResponse>} value */
proto.io.BatchCrudResponse.prototype.setDeleteresponsesList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.io.BatchDeleteEverestObjectResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.BatchDeleteEverestObjectResponse}
 */
proto.io.BatchCrudResponse.prototype.addDeleteresponses = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.io.BatchDeleteEverestObjectResponse, opt_index);
};


proto.io.BatchCrudResponse.prototype.clearDeleteresponsesList = function () {
  this.setDeleteresponsesList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.BatchCreateEverestObjectResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.BatchCreateEverestObjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.BatchCreateEverestObjectResponse.displayName = 'proto.io.BatchCreateEverestObjectResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.BatchCreateEverestObjectResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.BatchCreateEverestObjectResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.BatchCreateEverestObjectResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.BatchCreateEverestObjectResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
      key: jspb.Message.getFieldWithDefault(msg, 2, ""),
      created: jspb.Message.getFieldWithDefault(msg, 3, 0),
      usn: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.BatchCreateEverestObjectResponse}
 */
proto.io.BatchCreateEverestObjectResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.BatchCreateEverestObjectResponse;
  return proto.io.BatchCreateEverestObjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.BatchCreateEverestObjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.BatchCreateEverestObjectResponse}
 */
proto.io.BatchCreateEverestObjectResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setParent(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setCreated(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setUsn(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.BatchCreateEverestObjectResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.BatchCreateEverestObjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.BatchCreateEverestObjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.BatchCreateEverestObjectResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
        3,
        f
    );
  }
  f = message.getUsn();
  if (f !== 0) {
    writer.writeInt64(
        4,
        f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.io.BatchCreateEverestObjectResponse.prototype.getParent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.BatchCreateEverestObjectResponse.prototype.setParent = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.io.BatchCreateEverestObjectResponse.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.BatchCreateEverestObjectResponse.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created = 3;
 * @return {number}
 */
proto.io.BatchCreateEverestObjectResponse.prototype.getCreated = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.io.BatchCreateEverestObjectResponse.prototype.setCreated = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 USN = 4;
 * @return {number}
 */
proto.io.BatchCreateEverestObjectResponse.prototype.getUsn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.io.BatchCreateEverestObjectResponse.prototype.setUsn = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.BatchUpdateEverestObjectResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.BatchUpdateEverestObjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.BatchUpdateEverestObjectResponse.displayName = 'proto.io.BatchUpdateEverestObjectResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.BatchUpdateEverestObjectResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.BatchUpdateEverestObjectResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.BatchUpdateEverestObjectResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.BatchUpdateEverestObjectResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
      key: jspb.Message.getFieldWithDefault(msg, 2, ""),
      success: (f = msg.getSuccess()) && proto.io.UpdateSuccess.toObject(includeInstance, f),
      conflict: (f = msg.getConflict()) && proto.io.UpdateConflict.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.BatchUpdateEverestObjectResponse}
 */
proto.io.BatchUpdateEverestObjectResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.BatchUpdateEverestObjectResponse;
  return proto.io.BatchUpdateEverestObjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.BatchUpdateEverestObjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.BatchUpdateEverestObjectResponse}
 */
proto.io.BatchUpdateEverestObjectResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setParent(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 3:
        var value = new proto.io.UpdateSuccess;
        reader.readMessage(value, proto.io.UpdateSuccess.deserializeBinaryFromReader);
        msg.setSuccess(value);
        break;
      case 4:
        var value = new proto.io.UpdateConflict;
        reader.readMessage(value, proto.io.UpdateConflict.deserializeBinaryFromReader);
        msg.setConflict(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.BatchUpdateEverestObjectResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.BatchUpdateEverestObjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.BatchUpdateEverestObjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.BatchUpdateEverestObjectResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
        3,
        f,
        proto.io.UpdateSuccess.serializeBinaryToWriter
    );
  }
  f = message.getConflict();
  if (f != null) {
    writer.writeMessage(
        4,
        f,
        proto.io.UpdateConflict.serializeBinaryToWriter
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.io.BatchUpdateEverestObjectResponse.prototype.getParent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.BatchUpdateEverestObjectResponse.prototype.setParent = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.io.BatchUpdateEverestObjectResponse.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.BatchUpdateEverestObjectResponse.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UpdateSuccess success = 3;
 * @return {?proto.io.UpdateSuccess}
 */
proto.io.BatchUpdateEverestObjectResponse.prototype.getSuccess = function () {
  return /** @type{?proto.io.UpdateSuccess} */ (
      jspb.Message.getWrapperField(this, proto.io.UpdateSuccess, 3));
};


/** @param {?proto.io.UpdateSuccess|undefined} value */
proto.io.BatchUpdateEverestObjectResponse.prototype.setSuccess = function (value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.io.BatchUpdateEverestObjectResponse.prototype.clearSuccess = function () {
  this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.BatchUpdateEverestObjectResponse.prototype.hasSuccess = function () {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UpdateConflict conflict = 4;
 * @return {?proto.io.UpdateConflict}
 */
proto.io.BatchUpdateEverestObjectResponse.prototype.getConflict = function () {
  return /** @type{?proto.io.UpdateConflict} */ (
      jspb.Message.getWrapperField(this, proto.io.UpdateConflict, 4));
};


/** @param {?proto.io.UpdateConflict|undefined} value */
proto.io.BatchUpdateEverestObjectResponse.prototype.setConflict = function (value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.io.BatchUpdateEverestObjectResponse.prototype.clearConflict = function () {
  this.setConflict(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.BatchUpdateEverestObjectResponse.prototype.hasConflict = function () {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.BatchDeleteEverestObjectResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.BatchDeleteEverestObjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.BatchDeleteEverestObjectResponse.displayName = 'proto.io.BatchDeleteEverestObjectResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.BatchDeleteEverestObjectResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.BatchDeleteEverestObjectResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.BatchDeleteEverestObjectResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.BatchDeleteEverestObjectResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
      key: jspb.Message.getFieldWithDefault(msg, 2, ""),
      usn: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.BatchDeleteEverestObjectResponse}
 */
proto.io.BatchDeleteEverestObjectResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.BatchDeleteEverestObjectResponse;
  return proto.io.BatchDeleteEverestObjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.BatchDeleteEverestObjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.BatchDeleteEverestObjectResponse}
 */
proto.io.BatchDeleteEverestObjectResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setParent(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setUsn(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.BatchDeleteEverestObjectResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.BatchDeleteEverestObjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.BatchDeleteEverestObjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.BatchDeleteEverestObjectResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getUsn();
  if (f !== 0) {
    writer.writeInt64(
        3,
        f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.io.BatchDeleteEverestObjectResponse.prototype.getParent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.BatchDeleteEverestObjectResponse.prototype.setParent = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.io.BatchDeleteEverestObjectResponse.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.BatchDeleteEverestObjectResponse.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 USN = 3;
 * @return {number}
 */
proto.io.BatchDeleteEverestObjectResponse.prototype.getUsn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.io.BatchDeleteEverestObjectResponse.prototype.setUsn = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.EverestObject = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.EverestObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.EverestObject.displayName = 'proto.io.EverestObject';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.EverestObject.prototype.toObject = function (opt_includeInstance) {
    return proto.io.EverestObject.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.EverestObject} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.EverestObject.toObject = function (includeInstance, msg) {
    var f, obj = {
      key: jspb.Message.getFieldWithDefault(msg, 1, ""),
      created: jspb.Message.getFieldWithDefault(msg, 2, 0),
      usn: jspb.Message.getFieldWithDefault(msg, 3, 0),
      propertiesMap: (f = msg.getPropertiesMap()) ? f.toObject(includeInstance, proto.io.Value.toObject) : [],
      deleted: jspb.Message.getFieldWithDefault(msg, 5, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.EverestObject}
 */
proto.io.EverestObject.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.EverestObject;
  return proto.io.EverestObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.EverestObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.EverestObject}
 */
proto.io.EverestObject.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setCreated(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setUsn(value);
        break;
      case 4:
        var value = msg.getPropertiesMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.io.Value.deserializeBinaryFromReader, "");
        });
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDeleted(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.EverestObject.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.EverestObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.EverestObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.EverestObject.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
        2,
        f
    );
  }
  f = message.getUsn();
  if (f !== 0) {
    writer.writeInt64(
        3,
        f
    );
  }
  f = message.getPropertiesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.io.Value.serializeBinaryToWriter);
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
        5,
        f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.io.EverestObject.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.EverestObject.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created = 2;
 * @return {number}
 */
proto.io.EverestObject.prototype.getCreated = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.io.EverestObject.prototype.setCreated = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 USN = 3;
 * @return {number}
 */
proto.io.EverestObject.prototype.getUsn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.io.EverestObject.prototype.setUsn = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * map<string, Value> properties = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.io.Value>}
 */
proto.io.EverestObject.prototype.getPropertiesMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.io.Value>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
          proto.io.Value));
};


proto.io.EverestObject.prototype.clearPropertiesMap = function () {
  this.getPropertiesMap().clear();
};


/**
 * optional bool deleted = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.io.EverestObject.prototype.getDeleted = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.io.EverestObject.prototype.setDeleted = function (value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.GetEverestCounterRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.GetEverestCounterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.GetEverestCounterRequest.displayName = 'proto.io.GetEverestCounterRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.GetEverestCounterRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.GetEverestCounterRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.GetEverestCounterRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.GetEverestCounterRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      group: jspb.Message.getFieldWithDefault(msg, 2, ""),
      key: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.GetEverestCounterRequest}
 */
proto.io.GetEverestCounterRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.GetEverestCounterRequest;
  return proto.io.GetEverestCounterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.GetEverestCounterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.GetEverestCounterRequest}
 */
proto.io.GetEverestCounterRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroup(value);
        break;
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.GetEverestCounterRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.GetEverestCounterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.GetEverestCounterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.GetEverestCounterRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
};


/**
 * optional string group = 2;
 * @return {string}
 */
proto.io.GetEverestCounterRequest.prototype.getGroup = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.GetEverestCounterRequest.prototype.setGroup = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.io.GetEverestCounterRequest.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.GetEverestCounterRequest.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.GetEverestUniqueCounterRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.GetEverestUniqueCounterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.GetEverestUniqueCounterRequest.displayName = 'proto.io.GetEverestUniqueCounterRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.GetEverestUniqueCounterRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.GetEverestUniqueCounterRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.GetEverestUniqueCounterRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.GetEverestUniqueCounterRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      group: jspb.Message.getFieldWithDefault(msg, 2, ""),
      key: jspb.Message.getFieldWithDefault(msg, 1, ""),
      uniquekey: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.GetEverestUniqueCounterRequest}
 */
proto.io.GetEverestUniqueCounterRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.GetEverestUniqueCounterRequest;
  return proto.io.GetEverestUniqueCounterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.GetEverestUniqueCounterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.GetEverestUniqueCounterRequest}
 */
proto.io.GetEverestUniqueCounterRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroup(value);
        break;
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setUniquekey(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.GetEverestUniqueCounterRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.GetEverestUniqueCounterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.GetEverestUniqueCounterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.GetEverestUniqueCounterRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getUniquekey();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
};


/**
 * optional string group = 2;
 * @return {string}
 */
proto.io.GetEverestUniqueCounterRequest.prototype.getGroup = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.GetEverestUniqueCounterRequest.prototype.setGroup = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.io.GetEverestUniqueCounterRequest.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.GetEverestUniqueCounterRequest.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uniqueKey = 3;
 * @return {string}
 */
proto.io.GetEverestUniqueCounterRequest.prototype.getUniquekey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.io.GetEverestUniqueCounterRequest.prototype.setUniquekey = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.GetEverestCounterResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.GetEverestCounterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.GetEverestCounterResponse.displayName = 'proto.io.GetEverestCounterResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.GetEverestCounterResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.GetEverestCounterResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.GetEverestCounterResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.GetEverestCounterResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      counter: (f = msg.getCounter()) && proto.io.Counter.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.GetEverestCounterResponse}
 */
proto.io.GetEverestCounterResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.GetEverestCounterResponse;
  return proto.io.GetEverestCounterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.GetEverestCounterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.GetEverestCounterResponse}
 */
proto.io.GetEverestCounterResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.Counter;
        reader.readMessage(value, proto.io.Counter.deserializeBinaryFromReader);
        msg.setCounter(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.GetEverestCounterResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.GetEverestCounterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.GetEverestCounterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.GetEverestCounterResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCounter();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        proto.io.Counter.serializeBinaryToWriter
    );
  }
};


/**
 * optional Counter counter = 1;
 * @return {?proto.io.Counter}
 */
proto.io.GetEverestCounterResponse.prototype.getCounter = function () {
  return /** @type{?proto.io.Counter} */ (
      jspb.Message.getWrapperField(this, proto.io.Counter, 1));
};


/** @param {?proto.io.Counter|undefined} value */
proto.io.GetEverestCounterResponse.prototype.setCounter = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.io.GetEverestCounterResponse.prototype.clearCounter = function () {
  this.setCounter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.GetEverestCounterResponse.prototype.hasCounter = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.GetEverestUniqueCounterResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.GetEverestUniqueCounterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.GetEverestUniqueCounterResponse.displayName = 'proto.io.GetEverestUniqueCounterResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.GetEverestUniqueCounterResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.GetEverestUniqueCounterResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.GetEverestUniqueCounterResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.GetEverestUniqueCounterResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      uniquecounter: (f = msg.getUniquecounter()) && proto.io.UniqueCounter.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.GetEverestUniqueCounterResponse}
 */
proto.io.GetEverestUniqueCounterResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.GetEverestUniqueCounterResponse;
  return proto.io.GetEverestUniqueCounterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.GetEverestUniqueCounterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.GetEverestUniqueCounterResponse}
 */
proto.io.GetEverestUniqueCounterResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.UniqueCounter;
        reader.readMessage(value, proto.io.UniqueCounter.deserializeBinaryFromReader);
        msg.setUniquecounter(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.GetEverestUniqueCounterResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.GetEverestUniqueCounterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.GetEverestUniqueCounterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.GetEverestUniqueCounterResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUniquecounter();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        proto.io.UniqueCounter.serializeBinaryToWriter
    );
  }
};


/**
 * optional UniqueCounter uniqueCounter = 1;
 * @return {?proto.io.UniqueCounter}
 */
proto.io.GetEverestUniqueCounterResponse.prototype.getUniquecounter = function () {
  return /** @type{?proto.io.UniqueCounter} */ (
      jspb.Message.getWrapperField(this, proto.io.UniqueCounter, 1));
};


/** @param {?proto.io.UniqueCounter|undefined} value */
proto.io.GetEverestUniqueCounterResponse.prototype.setUniquecounter = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.io.GetEverestUniqueCounterResponse.prototype.clearUniquecounter = function () {
  this.setUniquecounter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.GetEverestUniqueCounterResponse.prototype.hasUniquecounter = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.GetEverestCounterListRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.GetEverestCounterListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.GetEverestCounterListRequest.displayName = 'proto.io.GetEverestCounterListRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.GetEverestCounterListRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.GetEverestCounterListRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.GetEverestCounterListRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.GetEverestCounterListRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      group: jspb.Message.getFieldWithDefault(msg, 1, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
      pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.GetEverestCounterListRequest}
 */
proto.io.GetEverestCounterListRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.GetEverestCounterListRequest;
  return proto.io.GetEverestCounterListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.GetEverestCounterListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.GetEverestCounterListRequest}
 */
proto.io.GetEverestCounterListRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroup(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setPageToken(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.GetEverestCounterListRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.GetEverestCounterListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.GetEverestCounterListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.GetEverestCounterListRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        2,
        f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
};


/**
 * optional string group = 1;
 * @return {string}
 */
proto.io.GetEverestCounterListRequest.prototype.getGroup = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.GetEverestCounterListRequest.prototype.setGroup = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.io.GetEverestCounterListRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.io.GetEverestCounterListRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.io.GetEverestCounterListRequest.prototype.getPageToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.io.GetEverestCounterListRequest.prototype.setPageToken = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.GetEverestCounterListResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.io.GetEverestCounterListResponse.repeatedFields_, null);
};
goog.inherits(proto.io.GetEverestCounterListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.GetEverestCounterListResponse.displayName = 'proto.io.GetEverestCounterListResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.io.GetEverestCounterListResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.GetEverestCounterListResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.GetEverestCounterListResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.GetEverestCounterListResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.GetEverestCounterListResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      counterList: jspb.Message.toObjectList(msg.getCounterList(),
          proto.io.Counter.toObject, includeInstance),
      nextpagetoken: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.GetEverestCounterListResponse}
 */
proto.io.GetEverestCounterListResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.GetEverestCounterListResponse;
  return proto.io.GetEverestCounterListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.GetEverestCounterListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.GetEverestCounterListResponse}
 */
proto.io.GetEverestCounterListResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.Counter;
        reader.readMessage(value, proto.io.Counter.deserializeBinaryFromReader);
        msg.addCounter(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextpagetoken(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.GetEverestCounterListResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.GetEverestCounterListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.GetEverestCounterListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.GetEverestCounterListResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCounterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.io.Counter.serializeBinaryToWriter
    );
  }
  f = message.getNextpagetoken();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * repeated Counter counter = 1;
 * @return {!Array<!proto.io.Counter>}
 */
proto.io.GetEverestCounterListResponse.prototype.getCounterList = function () {
  return /** @type{!Array<!proto.io.Counter>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.Counter, 1));
};


/** @param {!Array<!proto.io.Counter>} value */
proto.io.GetEverestCounterListResponse.prototype.setCounterList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.io.Counter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.Counter}
 */
proto.io.GetEverestCounterListResponse.prototype.addCounter = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.io.Counter, opt_index);
};


proto.io.GetEverestCounterListResponse.prototype.clearCounterList = function () {
  this.setCounterList([]);
};


/**
 * optional string nextPageToken = 2;
 * @return {string}
 */
proto.io.GetEverestCounterListResponse.prototype.getNextpagetoken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.GetEverestCounterListResponse.prototype.setNextpagetoken = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.GetEverestUniqueCounterListResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.io.GetEverestUniqueCounterListResponse.repeatedFields_, null);
};
goog.inherits(proto.io.GetEverestUniqueCounterListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.GetEverestUniqueCounterListResponse.displayName = 'proto.io.GetEverestUniqueCounterListResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.io.GetEverestUniqueCounterListResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.GetEverestUniqueCounterListResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.GetEverestUniqueCounterListResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.GetEverestUniqueCounterListResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.GetEverestUniqueCounterListResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      uniquecounterList: jspb.Message.toObjectList(msg.getUniquecounterList(),
          proto.io.UniqueCounter.toObject, includeInstance),
      nextpagetoken: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.GetEverestUniqueCounterListResponse}
 */
proto.io.GetEverestUniqueCounterListResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.GetEverestUniqueCounterListResponse;
  return proto.io.GetEverestUniqueCounterListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.GetEverestUniqueCounterListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.GetEverestUniqueCounterListResponse}
 */
proto.io.GetEverestUniqueCounterListResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.UniqueCounter;
        reader.readMessage(value, proto.io.UniqueCounter.deserializeBinaryFromReader);
        msg.addUniquecounter(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextpagetoken(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.GetEverestUniqueCounterListResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.GetEverestUniqueCounterListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.GetEverestUniqueCounterListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.GetEverestUniqueCounterListResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUniquecounterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.io.UniqueCounter.serializeBinaryToWriter
    );
  }
  f = message.getNextpagetoken();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * repeated UniqueCounter uniqueCounter = 1;
 * @return {!Array<!proto.io.UniqueCounter>}
 */
proto.io.GetEverestUniqueCounterListResponse.prototype.getUniquecounterList = function () {
  return /** @type{!Array<!proto.io.UniqueCounter>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.UniqueCounter, 1));
};


/** @param {!Array<!proto.io.UniqueCounter>} value */
proto.io.GetEverestUniqueCounterListResponse.prototype.setUniquecounterList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.io.UniqueCounter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.UniqueCounter}
 */
proto.io.GetEverestUniqueCounterListResponse.prototype.addUniquecounter = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.io.UniqueCounter, opt_index);
};


proto.io.GetEverestUniqueCounterListResponse.prototype.clearUniquecounterList = function () {
  this.setUniquecounterList([]);
};


/**
 * optional string nextPageToken = 2;
 * @return {string}
 */
proto.io.GetEverestUniqueCounterListResponse.prototype.getNextpagetoken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.GetEverestUniqueCounterListResponse.prototype.setNextpagetoken = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.IncrementCountRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.IncrementCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.IncrementCountRequest.displayName = 'proto.io.IncrementCountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.IncrementCountRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.IncrementCountRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.IncrementCountRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.IncrementCountRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      group: jspb.Message.getFieldWithDefault(msg, 2, ""),
      key: jspb.Message.getFieldWithDefault(msg, 1, ""),
      counttoincr: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.IncrementCountRequest}
 */
proto.io.IncrementCountRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.IncrementCountRequest;
  return proto.io.IncrementCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.IncrementCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.IncrementCountRequest}
 */
proto.io.IncrementCountRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroup(value);
        break;
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setCounttoincr(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.IncrementCountRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.IncrementCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.IncrementCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.IncrementCountRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getCounttoincr();
  if (f !== 0) {
    writer.writeInt64(
        3,
        f
    );
  }
};


/**
 * optional string group = 2;
 * @return {string}
 */
proto.io.IncrementCountRequest.prototype.getGroup = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.IncrementCountRequest.prototype.setGroup = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.io.IncrementCountRequest.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.IncrementCountRequest.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 countToIncr = 3;
 * @return {number}
 */
proto.io.IncrementCountRequest.prototype.getCounttoincr = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.io.IncrementCountRequest.prototype.setCounttoincr = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.IncrementUniqueCountRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.IncrementUniqueCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.IncrementUniqueCountRequest.displayName = 'proto.io.IncrementUniqueCountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.IncrementUniqueCountRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.IncrementUniqueCountRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.IncrementUniqueCountRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.IncrementUniqueCountRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      group: jspb.Message.getFieldWithDefault(msg, 2, ""),
      key: jspb.Message.getFieldWithDefault(msg, 1, ""),
      uniquekey: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.IncrementUniqueCountRequest}
 */
proto.io.IncrementUniqueCountRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.IncrementUniqueCountRequest;
  return proto.io.IncrementUniqueCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.IncrementUniqueCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.IncrementUniqueCountRequest}
 */
proto.io.IncrementUniqueCountRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroup(value);
        break;
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setUniquekey(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.IncrementUniqueCountRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.IncrementUniqueCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.IncrementUniqueCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.IncrementUniqueCountRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getUniquekey();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
};


/**
 * optional string group = 2;
 * @return {string}
 */
proto.io.IncrementUniqueCountRequest.prototype.getGroup = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.IncrementUniqueCountRequest.prototype.setGroup = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.io.IncrementUniqueCountRequest.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.IncrementUniqueCountRequest.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uniqueKey = 3;
 * @return {string}
 */
proto.io.IncrementUniqueCountRequest.prototype.getUniquekey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.io.IncrementUniqueCountRequest.prototype.setUniquekey = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.IncrementCountResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.IncrementCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.IncrementCountResponse.displayName = 'proto.io.IncrementCountResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.IncrementCountResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.IncrementCountResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.IncrementCountResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.IncrementCountResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      applied: jspb.Message.getFieldWithDefault(msg, 1, false),
      newvalue: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.IncrementCountResponse}
 */
proto.io.IncrementCountResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.IncrementCountResponse;
  return proto.io.IncrementCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.IncrementCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.IncrementCountResponse}
 */
proto.io.IncrementCountResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setApplied(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setNewvalue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.IncrementCountResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.IncrementCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.IncrementCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.IncrementCountResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getApplied();
  if (f) {
    writer.writeBool(
        1,
        f
    );
  }
  f = message.getNewvalue();
  if (f !== 0) {
    writer.writeInt64(
        2,
        f
    );
  }
};


/**
 * optional bool applied = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.io.IncrementCountResponse.prototype.getApplied = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.io.IncrementCountResponse.prototype.setApplied = function (value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 newValue = 2;
 * @return {number}
 */
proto.io.IncrementCountResponse.prototype.getNewvalue = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.io.IncrementCountResponse.prototype.setNewvalue = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.DecrementCounterRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.DecrementCounterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.DecrementCounterRequest.displayName = 'proto.io.DecrementCounterRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.DecrementCounterRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.DecrementCounterRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.DecrementCounterRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.DecrementCounterRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      group: jspb.Message.getFieldWithDefault(msg, 2, ""),
      key: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.DecrementCounterRequest}
 */
proto.io.DecrementCounterRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.DecrementCounterRequest;
  return proto.io.DecrementCounterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.DecrementCounterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.DecrementCounterRequest}
 */
proto.io.DecrementCounterRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroup(value);
        break;
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.DecrementCounterRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.DecrementCounterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.DecrementCounterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.DecrementCounterRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
};


/**
 * optional string group = 2;
 * @return {string}
 */
proto.io.DecrementCounterRequest.prototype.getGroup = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.DecrementCounterRequest.prototype.setGroup = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.io.DecrementCounterRequest.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.DecrementCounterRequest.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.DecrementCounterResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.DecrementCounterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.DecrementCounterResponse.displayName = 'proto.io.DecrementCounterResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.DecrementCounterResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.DecrementCounterResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.DecrementCounterResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.DecrementCounterResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      applied: jspb.Message.getFieldWithDefault(msg, 1, false),
      newvalue: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.DecrementCounterResponse}
 */
proto.io.DecrementCounterResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.DecrementCounterResponse;
  return proto.io.DecrementCounterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.DecrementCounterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.DecrementCounterResponse}
 */
proto.io.DecrementCounterResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setApplied(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setNewvalue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.DecrementCounterResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.DecrementCounterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.DecrementCounterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.DecrementCounterResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getApplied();
  if (f) {
    writer.writeBool(
        1,
        f
    );
  }
  f = message.getNewvalue();
  if (f !== 0) {
    writer.writeInt64(
        2,
        f
    );
  }
};


/**
 * optional bool applied = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.io.DecrementCounterResponse.prototype.getApplied = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.io.DecrementCounterResponse.prototype.setApplied = function (value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 newValue = 2;
 * @return {number}
 */
proto.io.DecrementCounterResponse.prototype.getNewvalue = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.io.DecrementCounterResponse.prototype.setNewvalue = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.Counter = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.Counter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.Counter.displayName = 'proto.io.Counter';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.Counter.prototype.toObject = function (opt_includeInstance) {
    return proto.io.Counter.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.Counter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.Counter.toObject = function (includeInstance, msg) {
    var f, obj = {
      group: jspb.Message.getFieldWithDefault(msg, 2, ""),
      key: jspb.Message.getFieldWithDefault(msg, 3, ""),
      count: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.Counter}
 */
proto.io.Counter.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.Counter;
  return proto.io.Counter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.Counter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.Counter}
 */
proto.io.Counter.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroup(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setCount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.Counter.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.Counter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.Counter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.Counter.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
        4,
        f
    );
  }
};


/**
 * optional string group = 2;
 * @return {string}
 */
proto.io.Counter.prototype.getGroup = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.Counter.prototype.setGroup = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 3;
 * @return {string}
 */
proto.io.Counter.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.io.Counter.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 count = 4;
 * @return {number}
 */
proto.io.Counter.prototype.getCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.io.Counter.prototype.setCount = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.UniqueCounter = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.UniqueCounter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.UniqueCounter.displayName = 'proto.io.UniqueCounter';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.UniqueCounter.prototype.toObject = function (opt_includeInstance) {
    return proto.io.UniqueCounter.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.UniqueCounter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.UniqueCounter.toObject = function (includeInstance, msg) {
    var f, obj = {
      group: jspb.Message.getFieldWithDefault(msg, 2, ""),
      key: jspb.Message.getFieldWithDefault(msg, 3, ""),
      count: jspb.Message.getFieldWithDefault(msg, 4, 0),
      reacted: jspb.Message.getFieldWithDefault(msg, 5, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.UniqueCounter}
 */
proto.io.UniqueCounter.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.UniqueCounter;
  return proto.io.UniqueCounter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.UniqueCounter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.UniqueCounter}
 */
proto.io.UniqueCounter.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroup(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setCount(value);
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setReacted(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.UniqueCounter.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.UniqueCounter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.UniqueCounter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.UniqueCounter.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
        4,
        f
    );
  }
  f = message.getReacted();
  if (f) {
    writer.writeBool(
        5,
        f
    );
  }
};


/**
 * optional string group = 2;
 * @return {string}
 */
proto.io.UniqueCounter.prototype.getGroup = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.UniqueCounter.prototype.setGroup = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 3;
 * @return {string}
 */
proto.io.UniqueCounter.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.io.UniqueCounter.prototype.setKey = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 count = 4;
 * @return {number}
 */
proto.io.UniqueCounter.prototype.getCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.io.UniqueCounter.prototype.setCount = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool reacted = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.io.UniqueCounter.prototype.getReacted = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.io.UniqueCounter.prototype.setReacted = function (value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.DecrementAndIncrementRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.DecrementAndIncrementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.DecrementAndIncrementRequest.displayName = 'proto.io.DecrementAndIncrementRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.DecrementAndIncrementRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.io.DecrementAndIncrementRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.DecrementAndIncrementRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.DecrementAndIncrementRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      decrrequest: (f = msg.getDecrrequest()) && proto.io.DecrementCounterRequest.toObject(includeInstance, f),
      incrrequest: (f = msg.getIncrrequest()) && proto.io.IncrementUniqueCountRequest.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.DecrementAndIncrementRequest}
 */
proto.io.DecrementAndIncrementRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.DecrementAndIncrementRequest;
  return proto.io.DecrementAndIncrementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.DecrementAndIncrementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.DecrementAndIncrementRequest}
 */
proto.io.DecrementAndIncrementRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.DecrementCounterRequest;
        reader.readMessage(value, proto.io.DecrementCounterRequest.deserializeBinaryFromReader);
        msg.setDecrrequest(value);
        break;
      case 2:
        var value = new proto.io.IncrementUniqueCountRequest;
        reader.readMessage(value, proto.io.IncrementUniqueCountRequest.deserializeBinaryFromReader);
        msg.setIncrrequest(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.DecrementAndIncrementRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.DecrementAndIncrementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.DecrementAndIncrementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.DecrementAndIncrementRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDecrrequest();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        proto.io.DecrementCounterRequest.serializeBinaryToWriter
    );
  }
  f = message.getIncrrequest();
  if (f != null) {
    writer.writeMessage(
        2,
        f,
        proto.io.IncrementUniqueCountRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional DecrementCounterRequest decrRequest = 1;
 * @return {?proto.io.DecrementCounterRequest}
 */
proto.io.DecrementAndIncrementRequest.prototype.getDecrrequest = function () {
  return /** @type{?proto.io.DecrementCounterRequest} */ (
      jspb.Message.getWrapperField(this, proto.io.DecrementCounterRequest, 1));
};


/** @param {?proto.io.DecrementCounterRequest|undefined} value */
proto.io.DecrementAndIncrementRequest.prototype.setDecrrequest = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.io.DecrementAndIncrementRequest.prototype.clearDecrrequest = function () {
  this.setDecrrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.DecrementAndIncrementRequest.prototype.hasDecrrequest = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional IncrementUniqueCountRequest incrRequest = 2;
 * @return {?proto.io.IncrementUniqueCountRequest}
 */
proto.io.DecrementAndIncrementRequest.prototype.getIncrrequest = function () {
  return /** @type{?proto.io.IncrementUniqueCountRequest} */ (
      jspb.Message.getWrapperField(this, proto.io.IncrementUniqueCountRequest, 2));
};


/** @param {?proto.io.IncrementUniqueCountRequest|undefined} value */
proto.io.DecrementAndIncrementRequest.prototype.setIncrrequest = function (value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.io.DecrementAndIncrementRequest.prototype.clearIncrrequest = function () {
  this.setIncrrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.DecrementAndIncrementRequest.prototype.hasIncrrequest = function () {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.DecrementAndIncrementResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.DecrementAndIncrementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.DecrementAndIncrementResponse.displayName = 'proto.io.DecrementAndIncrementResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.DecrementAndIncrementResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.io.DecrementAndIncrementResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.DecrementAndIncrementResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.DecrementAndIncrementResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      decrcounterkey: jspb.Message.getFieldWithDefault(msg, 1, ""),
      decrcountervalue: jspb.Message.getFieldWithDefault(msg, 2, 0),
      incrcounterkey: jspb.Message.getFieldWithDefault(msg, 3, ""),
      incrcountervalue: jspb.Message.getFieldWithDefault(msg, 4, 0),
      success: jspb.Message.getFieldWithDefault(msg, 5, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.DecrementAndIncrementResponse}
 */
proto.io.DecrementAndIncrementResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.DecrementAndIncrementResponse;
  return proto.io.DecrementAndIncrementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.DecrementAndIncrementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.DecrementAndIncrementResponse}
 */
proto.io.DecrementAndIncrementResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setDecrcounterkey(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setDecrcountervalue(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setIncrcounterkey(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setIncrcountervalue(value);
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSuccess(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.DecrementAndIncrementResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.DecrementAndIncrementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.DecrementAndIncrementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.DecrementAndIncrementResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDecrcounterkey();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getDecrcountervalue();
  if (f !== 0) {
    writer.writeInt64(
        2,
        f
    );
  }
  f = message.getIncrcounterkey();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getIncrcountervalue();
  if (f !== 0) {
    writer.writeInt64(
        4,
        f
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
        5,
        f
    );
  }
};


/**
 * optional string decrCounterKey = 1;
 * @return {string}
 */
proto.io.DecrementAndIncrementResponse.prototype.getDecrcounterkey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.io.DecrementAndIncrementResponse.prototype.setDecrcounterkey = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 decrCounterValue = 2;
 * @return {number}
 */
proto.io.DecrementAndIncrementResponse.prototype.getDecrcountervalue = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.io.DecrementAndIncrementResponse.prototype.setDecrcountervalue = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string incrCounterKey = 3;
 * @return {string}
 */
proto.io.DecrementAndIncrementResponse.prototype.getIncrcounterkey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.io.DecrementAndIncrementResponse.prototype.setIncrcounterkey = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 incrCounterValue = 4;
 * @return {number}
 */
proto.io.DecrementAndIncrementResponse.prototype.getIncrcountervalue = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.io.DecrementAndIncrementResponse.prototype.setIncrcountervalue = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool success = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.io.DecrementAndIncrementResponse.prototype.getSuccess = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.io.DecrementAndIncrementResponse.prototype.setSuccess = function (value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.DocumentMask = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.io.DocumentMask.repeatedFields_, null);
};
goog.inherits(proto.io.DocumentMask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.DocumentMask.displayName = 'proto.io.DocumentMask';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.io.DocumentMask.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.DocumentMask.prototype.toObject = function (opt_includeInstance) {
    return proto.io.DocumentMask.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.DocumentMask} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.DocumentMask.toObject = function (includeInstance, msg) {
    var f, obj = {
      fieldPathsList: jspb.Message.getRepeatedField(msg, 1)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.DocumentMask}
 */
proto.io.DocumentMask.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.DocumentMask;
  return proto.io.DocumentMask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.DocumentMask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.DocumentMask}
 */
proto.io.DocumentMask.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addFieldPaths(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.DocumentMask.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.DocumentMask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.DocumentMask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.DocumentMask.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldPathsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
        1,
        f
    );
  }
};


/**
 * repeated string field_paths = 1;
 * @return {!Array<string>}
 */
proto.io.DocumentMask.prototype.getFieldPathsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.io.DocumentMask.prototype.setFieldPathsList = function (value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.io.DocumentMask.prototype.addFieldPaths = function (value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.io.DocumentMask.prototype.clearFieldPathsList = function () {
  this.setFieldPathsList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.Precondition = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.io.Precondition.oneofGroups_);
};
goog.inherits(proto.io.Precondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.Precondition.displayName = 'proto.io.Precondition';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.io.Precondition.oneofGroups_ = [[1, 2]];

/**
 * @enum {number}
 */
proto.io.Precondition.ConditionTypeCase = {
  CONDITION_TYPE_NOT_SET: 0,
  EXISTS: 1,
  UPDATE_TIME: 2
};

/**
 * @return {proto.io.Precondition.ConditionTypeCase}
 */
proto.io.Precondition.prototype.getConditionTypeCase = function () {
  return /** @type {proto.io.Precondition.ConditionTypeCase} */(jspb.Message.computeOneofCase(this, proto.io.Precondition.oneofGroups_[0]));
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.Precondition.prototype.toObject = function (opt_includeInstance) {
    return proto.io.Precondition.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.Precondition} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.Precondition.toObject = function (includeInstance, msg) {
    var f, obj = {
      exists: jspb.Message.getFieldWithDefault(msg, 1, false),
      updateTime: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.Precondition}
 */
proto.io.Precondition.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.Precondition;
  return proto.io.Precondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.Precondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.Precondition}
 */
proto.io.Precondition.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setExists(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setUpdateTime(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.Precondition.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.Precondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.Precondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.Precondition.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
        1,
        f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
        2,
        f
    );
  }
};


/**
 * optional bool exists = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.io.Precondition.prototype.getExists = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.io.Precondition.prototype.setExists = function (value) {
  jspb.Message.setOneofField(this, 1, proto.io.Precondition.oneofGroups_[0], value);
};


proto.io.Precondition.prototype.clearExists = function () {
  jspb.Message.setOneofField(this, 1, proto.io.Precondition.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Precondition.prototype.hasExists = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 update_time = 2;
 * @return {number}
 */
proto.io.Precondition.prototype.getUpdateTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.io.Precondition.prototype.setUpdateTime = function (value) {
  jspb.Message.setOneofField(this, 2, proto.io.Precondition.oneofGroups_[0], value);
};


proto.io.Precondition.prototype.clearUpdateTime = function () {
  jspb.Message.setOneofField(this, 2, proto.io.Precondition.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Precondition.prototype.hasUpdateTime = function () {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.Write = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.io.Write.oneofGroups_);
};
goog.inherits(proto.io.Write, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.Write.displayName = 'proto.io.Write';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.io.Write.oneofGroups_ = [[1, 2]];

/**
 * @enum {number}
 */
proto.io.Write.OperationCase = {
  OPERATION_NOT_SET: 0,
  UPDATE: 1,
  DELETE: 2
};

/**
 * @return {proto.io.Write.OperationCase}
 */
proto.io.Write.prototype.getOperationCase = function () {
  return /** @type {proto.io.Write.OperationCase} */(jspb.Message.computeOneofCase(this, proto.io.Write.oneofGroups_[0]));
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.Write.prototype.toObject = function (opt_includeInstance) {
    return proto.io.Write.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.Write} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.Write.toObject = function (includeInstance, msg) {
    var f, obj = {
      update: (f = msg.getUpdate()) && proto.io.EverestObject.toObject(includeInstance, f),
      pb_delete: jspb.Message.getFieldWithDefault(msg, 2, ""),
      updateMask: (f = msg.getUpdateMask()) && proto.io.DocumentMask.toObject(includeInstance, f),
      currentDocument: (f = msg.getCurrentDocument()) && proto.io.Precondition.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.Write}
 */
proto.io.Write.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.Write;
  return proto.io.Write.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.Write} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.Write}
 */
proto.io.Write.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.EverestObject;
        reader.readMessage(value, proto.io.EverestObject.deserializeBinaryFromReader);
        msg.setUpdate(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setDelete(value);
        break;
      case 3:
        var value = new proto.io.DocumentMask;
        reader.readMessage(value, proto.io.DocumentMask.deserializeBinaryFromReader);
        msg.setUpdateMask(value);
        break;
      case 4:
        var value = new proto.io.Precondition;
        reader.readMessage(value, proto.io.Precondition.deserializeBinaryFromReader);
        msg.setCurrentDocument(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.Write.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.Write.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.Write} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.Write.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUpdate();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        proto.io.EverestObject.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
        3,
        f,
        proto.io.DocumentMask.serializeBinaryToWriter
    );
  }
  f = message.getCurrentDocument();
  if (f != null) {
    writer.writeMessage(
        4,
        f,
        proto.io.Precondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional EverestObject update = 1;
 * @return {?proto.io.EverestObject}
 */
proto.io.Write.prototype.getUpdate = function () {
  return /** @type{?proto.io.EverestObject} */ (
      jspb.Message.getWrapperField(this, proto.io.EverestObject, 1));
};


/** @param {?proto.io.EverestObject|undefined} value */
proto.io.Write.prototype.setUpdate = function (value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.io.Write.oneofGroups_[0], value);
};


proto.io.Write.prototype.clearUpdate = function () {
  this.setUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Write.prototype.hasUpdate = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string delete = 2;
 * @return {string}
 */
proto.io.Write.prototype.getDelete = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.io.Write.prototype.setDelete = function (value) {
  jspb.Message.setOneofField(this, 2, proto.io.Write.oneofGroups_[0], value);
};


proto.io.Write.prototype.clearDelete = function () {
  jspb.Message.setOneofField(this, 2, proto.io.Write.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Write.prototype.hasDelete = function () {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DocumentMask update_mask = 3;
 * @return {?proto.io.DocumentMask}
 */
proto.io.Write.prototype.getUpdateMask = function () {
  return /** @type{?proto.io.DocumentMask} */ (
      jspb.Message.getWrapperField(this, proto.io.DocumentMask, 3));
};


/** @param {?proto.io.DocumentMask|undefined} value */
proto.io.Write.prototype.setUpdateMask = function (value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.io.Write.prototype.clearUpdateMask = function () {
  this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Write.prototype.hasUpdateMask = function () {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Precondition current_document = 4;
 * @return {?proto.io.Precondition}
 */
proto.io.Write.prototype.getCurrentDocument = function () {
  return /** @type{?proto.io.Precondition} */ (
      jspb.Message.getWrapperField(this, proto.io.Precondition, 4));
};


/** @param {?proto.io.Precondition|undefined} value */
proto.io.Write.prototype.setCurrentDocument = function (value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.io.Write.prototype.clearCurrentDocument = function () {
  this.setCurrentDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Write.prototype.hasCurrentDocument = function () {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.WriteResult = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.WriteResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.WriteResult.displayName = 'proto.io.WriteResult';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.WriteResult.prototype.toObject = function (opt_includeInstance) {
    return proto.io.WriteResult.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.WriteResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.WriteResult.toObject = function (includeInstance, msg) {
    var f, obj = {
      updateTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
      usn: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.WriteResult}
 */
proto.io.WriteResult.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.WriteResult;
  return proto.io.WriteResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.WriteResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.WriteResult}
 */
proto.io.WriteResult.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setUpdateTime(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setUsn(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.WriteResult.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.WriteResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.WriteResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.WriteResult.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUpdateTime();
  if (f !== 0) {
    writer.writeInt64(
        1,
        f
    );
  }
  f = message.getUsn();
  if (f !== 0) {
    writer.writeInt64(
        2,
        f
    );
  }
};


/**
 * optional int64 update_time = 1;
 * @return {number}
 */
proto.io.WriteResult.prototype.getUpdateTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.io.WriteResult.prototype.setUpdateTime = function (value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 usn = 2;
 * @return {number}
 */
proto.io.WriteResult.prototype.getUsn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.io.WriteResult.prototype.setUsn = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.Document = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.Document.displayName = 'proto.io.Document';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.Document.prototype.toObject = function (opt_includeInstance) {
    return proto.io.Document.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.Document} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.Document.toObject = function (includeInstance, msg) {
    var f, obj = {
      fieldsMap: (f = msg.getFieldsMap()) ? f.toObject(includeInstance, proto.io.Value.toObject) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.Document}
 */
proto.io.Document.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.Document;
  return proto.io.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.Document}
 */
proto.io.Document.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = msg.getFieldsMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.io.Value.deserializeBinaryFromReader, "");
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.Document.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.Document.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.io.Value.serializeBinaryToWriter);
  }
};


/**
 * map<string, Value> fields = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.io.Value>}
 */
proto.io.Document.prototype.getFieldsMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.io.Value>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
          proto.io.Value));
};


proto.io.Document.prototype.clearFieldsMap = function () {
  this.getFieldsMap().clear();
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.Value = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.io.Value.oneofGroups_);
};
goog.inherits(proto.io.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.Value.displayName = 'proto.io.Value';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.io.Value.oneofGroups_ = [[11, 1, 2, 3, 10, 17, 18, 5, 8, 9, 6]];

/**
 * @enum {number}
 */
proto.io.Value.ValueTypeCase = {
  VALUE_TYPE_NOT_SET: 0,
  NULL_VALUE: 11,
  BOOLEAN_VALUE: 1,
  INTEGER_VALUE: 2,
  DOUBLE_VALUE: 3,
  TIMESTAMP_VALUE: 10,
  STRING_VALUE: 17,
  BYTES_VALUE: 18,
  REFERENCE_VALUE: 5,
  GEO_POINT_VALUE: 8,
  ARRAY_VALUE: 9,
  MAP_VALUE: 6
};

/**
 * @return {proto.io.Value.ValueTypeCase}
 */
proto.io.Value.prototype.getValueTypeCase = function () {
  return /** @type {proto.io.Value.ValueTypeCase} */(jspb.Message.computeOneofCase(this, proto.io.Value.oneofGroups_[0]));
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.Value.prototype.toObject = function (opt_includeInstance) {
    return proto.io.Value.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.Value} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.Value.toObject = function (includeInstance, msg) {
    var f, obj = {
      nullValue: jspb.Message.getFieldWithDefault(msg, 11, 0),
      booleanValue: jspb.Message.getFieldWithDefault(msg, 1, false),
      integerValue: jspb.Message.getFieldWithDefault(msg, 2, 0),
      doubleValue: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
      timestampValue: (f = msg.getTimestampValue()) && proto.io.Timestamp.toObject(includeInstance, f),
      stringValue: jspb.Message.getFieldWithDefault(msg, 17, ""),
      bytesValue: msg.getBytesValue_asB64(),
      referenceValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
      geoPointValue: (f = msg.getGeoPointValue()) && proto.io.LatLng.toObject(includeInstance, f),
      arrayValue: (f = msg.getArrayValue()) && proto.io.ArrayValue.toObject(includeInstance, f),
      mapValue: (f = msg.getMapValue()) && proto.io.MapValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.Value}
 */
proto.io.Value.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.Value;
  return proto.io.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.Value}
 */
proto.io.Value.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 11:
        var value = /** @type {!proto.io.NullValue} */ (reader.readEnum());
        msg.setNullValue(value);
        break;
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setBooleanValue(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setIntegerValue(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setDoubleValue(value);
        break;
      case 10:
        var value = new proto.io.Timestamp;
        reader.readMessage(value, proto.io.Timestamp.deserializeBinaryFromReader);
        msg.setTimestampValue(value);
        break;
      case 17:
        var value = /** @type {string} */ (reader.readString());
        msg.setStringValue(value);
        break;
      case 18:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setBytesValue(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setReferenceValue(value);
        break;
      case 8:
        var value = new proto.io.LatLng;
        reader.readMessage(value, proto.io.LatLng.deserializeBinaryFromReader);
        msg.setGeoPointValue(value);
        break;
      case 9:
        var value = new proto.io.ArrayValue;
        reader.readMessage(value, proto.io.ArrayValue.deserializeBinaryFromReader);
        msg.setArrayValue(value);
        break;
      case 6:
        var value = new proto.io.MapValue;
        reader.readMessage(value, proto.io.MapValue.deserializeBinaryFromReader);
        msg.setMapValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.Value.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.Value.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {!proto.io.NullValue} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeEnum(
        11,
        f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
        1,
        f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
        2,
        f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
        3,
        f
    );
  }
  f = message.getTimestampValue();
  if (f != null) {
    writer.writeMessage(
        10,
        f,
        proto.io.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
        17,
        f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeBytes(
        18,
        f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
        5,
        f
    );
  }
  f = message.getGeoPointValue();
  if (f != null) {
    writer.writeMessage(
        8,
        f,
        proto.io.LatLng.serializeBinaryToWriter
    );
  }
  f = message.getArrayValue();
  if (f != null) {
    writer.writeMessage(
        9,
        f,
        proto.io.ArrayValue.serializeBinaryToWriter
    );
  }
  f = message.getMapValue();
  if (f != null) {
    writer.writeMessage(
        6,
        f,
        proto.io.MapValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional NullValue null_value = 11;
 * @return {!proto.io.NullValue}
 */
proto.io.Value.prototype.getNullValue = function () {
  return /** @type {!proto.io.NullValue} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {!proto.io.NullValue} value */
proto.io.Value.prototype.setNullValue = function (value) {
  jspb.Message.setOneofField(this, 11, proto.io.Value.oneofGroups_[0], value);
};


proto.io.Value.prototype.clearNullValue = function () {
  jspb.Message.setOneofField(this, 11, proto.io.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Value.prototype.hasNullValue = function () {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool boolean_value = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.io.Value.prototype.getBooleanValue = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.io.Value.prototype.setBooleanValue = function (value) {
  jspb.Message.setOneofField(this, 1, proto.io.Value.oneofGroups_[0], value);
};


proto.io.Value.prototype.clearBooleanValue = function () {
  jspb.Message.setOneofField(this, 1, proto.io.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Value.prototype.hasBooleanValue = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 integer_value = 2;
 * @return {number}
 */
proto.io.Value.prototype.getIntegerValue = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.io.Value.prototype.setIntegerValue = function (value) {
  jspb.Message.setOneofField(this, 2, proto.io.Value.oneofGroups_[0], value);
};


proto.io.Value.prototype.clearIntegerValue = function () {
  jspb.Message.setOneofField(this, 2, proto.io.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Value.prototype.hasIntegerValue = function () {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double double_value = 3;
 * @return {number}
 */
proto.io.Value.prototype.getDoubleValue = function () {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.io.Value.prototype.setDoubleValue = function (value) {
  jspb.Message.setOneofField(this, 3, proto.io.Value.oneofGroups_[0], value);
};


proto.io.Value.prototype.clearDoubleValue = function () {
  jspb.Message.setOneofField(this, 3, proto.io.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Value.prototype.hasDoubleValue = function () {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Timestamp timestamp_value = 10;
 * @return {?proto.io.Timestamp}
 */
proto.io.Value.prototype.getTimestampValue = function () {
  return /** @type{?proto.io.Timestamp} */ (
      jspb.Message.getWrapperField(this, proto.io.Timestamp, 10));
};


/** @param {?proto.io.Timestamp|undefined} value */
proto.io.Value.prototype.setTimestampValue = function (value) {
  jspb.Message.setOneofWrapperField(this, 10, proto.io.Value.oneofGroups_[0], value);
};


proto.io.Value.prototype.clearTimestampValue = function () {
  this.setTimestampValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Value.prototype.hasTimestampValue = function () {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string string_value = 17;
 * @return {string}
 */
proto.io.Value.prototype.getStringValue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.io.Value.prototype.setStringValue = function (value) {
  jspb.Message.setOneofField(this, 17, proto.io.Value.oneofGroups_[0], value);
};


proto.io.Value.prototype.clearStringValue = function () {
  jspb.Message.setOneofField(this, 17, proto.io.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Value.prototype.hasStringValue = function () {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional bytes bytes_value = 18;
 * @return {string}
 */
proto.io.Value.prototype.getBytesValue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * optional bytes bytes_value = 18;
 * This is a type-conversion wrapper around `getBytesValue()`
 * @return {string}
 */
proto.io.Value.prototype.getBytesValue_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBytesValue()));
};


/**
 * optional bytes bytes_value = 18;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBytesValue()`
 * @return {!Uint8Array}
 */
proto.io.Value.prototype.getBytesValue_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBytesValue()));
};


/** @param {!(string|Uint8Array)} value */
proto.io.Value.prototype.setBytesValue = function (value) {
  jspb.Message.setOneofField(this, 18, proto.io.Value.oneofGroups_[0], value);
};


proto.io.Value.prototype.clearBytesValue = function () {
  jspb.Message.setOneofField(this, 18, proto.io.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Value.prototype.hasBytesValue = function () {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string reference_value = 5;
 * @return {string}
 */
proto.io.Value.prototype.getReferenceValue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.io.Value.prototype.setReferenceValue = function (value) {
  jspb.Message.setOneofField(this, 5, proto.io.Value.oneofGroups_[0], value);
};


proto.io.Value.prototype.clearReferenceValue = function () {
  jspb.Message.setOneofField(this, 5, proto.io.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Value.prototype.hasReferenceValue = function () {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional LatLng geo_point_value = 8;
 * @return {?proto.io.LatLng}
 */
proto.io.Value.prototype.getGeoPointValue = function () {
  return /** @type{?proto.io.LatLng} */ (
      jspb.Message.getWrapperField(this, proto.io.LatLng, 8));
};


/** @param {?proto.io.LatLng|undefined} value */
proto.io.Value.prototype.setGeoPointValue = function (value) {
  jspb.Message.setOneofWrapperField(this, 8, proto.io.Value.oneofGroups_[0], value);
};


proto.io.Value.prototype.clearGeoPointValue = function () {
  this.setGeoPointValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Value.prototype.hasGeoPointValue = function () {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ArrayValue array_value = 9;
 * @return {?proto.io.ArrayValue}
 */
proto.io.Value.prototype.getArrayValue = function () {
  return /** @type{?proto.io.ArrayValue} */ (
      jspb.Message.getWrapperField(this, proto.io.ArrayValue, 9));
};


/** @param {?proto.io.ArrayValue|undefined} value */
proto.io.Value.prototype.setArrayValue = function (value) {
  jspb.Message.setOneofWrapperField(this, 9, proto.io.Value.oneofGroups_[0], value);
};


proto.io.Value.prototype.clearArrayValue = function () {
  this.setArrayValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Value.prototype.hasArrayValue = function () {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional MapValue map_value = 6;
 * @return {?proto.io.MapValue}
 */
proto.io.Value.prototype.getMapValue = function () {
  return /** @type{?proto.io.MapValue} */ (
      jspb.Message.getWrapperField(this, proto.io.MapValue, 6));
};


/** @param {?proto.io.MapValue|undefined} value */
proto.io.Value.prototype.setMapValue = function (value) {
  jspb.Message.setOneofWrapperField(this, 6, proto.io.Value.oneofGroups_[0], value);
};


proto.io.Value.prototype.clearMapValue = function () {
  this.setMapValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.io.Value.prototype.hasMapValue = function () {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.ArrayValue = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.io.ArrayValue.repeatedFields_, null);
};
goog.inherits(proto.io.ArrayValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.ArrayValue.displayName = 'proto.io.ArrayValue';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.io.ArrayValue.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.ArrayValue.prototype.toObject = function (opt_includeInstance) {
    return proto.io.ArrayValue.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.ArrayValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.ArrayValue.toObject = function (includeInstance, msg) {
    var f, obj = {
      valuesList: jspb.Message.toObjectList(msg.getValuesList(),
          proto.io.Value.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.ArrayValue}
 */
proto.io.ArrayValue.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.ArrayValue;
  return proto.io.ArrayValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.ArrayValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.ArrayValue}
 */
proto.io.ArrayValue.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.io.Value;
        reader.readMessage(value, proto.io.Value.deserializeBinaryFromReader);
        msg.addValues(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.ArrayValue.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.ArrayValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.ArrayValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.ArrayValue.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.io.Value.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Value values = 1;
 * @return {!Array<!proto.io.Value>}
 */
proto.io.ArrayValue.prototype.getValuesList = function () {
  return /** @type{!Array<!proto.io.Value>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.io.Value, 1));
};


/** @param {!Array<!proto.io.Value>} value */
proto.io.ArrayValue.prototype.setValuesList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.io.Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.io.Value}
 */
proto.io.ArrayValue.prototype.addValues = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.io.Value, opt_index);
};


proto.io.ArrayValue.prototype.clearValuesList = function () {
  this.setValuesList([]);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.MapValue = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.MapValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.MapValue.displayName = 'proto.io.MapValue';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.MapValue.prototype.toObject = function (opt_includeInstance) {
    return proto.io.MapValue.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.MapValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.MapValue.toObject = function (includeInstance, msg) {
    var f, obj = {
      fieldsMap: (f = msg.getFieldsMap()) ? f.toObject(includeInstance, proto.io.Value.toObject) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.MapValue}
 */
proto.io.MapValue.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.MapValue;
  return proto.io.MapValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.MapValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.MapValue}
 */
proto.io.MapValue.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = msg.getFieldsMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.io.Value.deserializeBinaryFromReader, "");
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.MapValue.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.MapValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.MapValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.MapValue.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.io.Value.serializeBinaryToWriter);
  }
};


/**
 * map<string, Value> fields = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.io.Value>}
 */
proto.io.MapValue.prototype.getFieldsMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.io.Value>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
          proto.io.Value));
};


proto.io.MapValue.prototype.clearFieldsMap = function () {
  this.getFieldsMap().clear();
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.Timestamp = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.Timestamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.Timestamp.displayName = 'proto.io.Timestamp';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.Timestamp.prototype.toObject = function (opt_includeInstance) {
    return proto.io.Timestamp.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.Timestamp} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.Timestamp.toObject = function (includeInstance, msg) {
    var f, obj = {
      seconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
      nanos: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.Timestamp}
 */
proto.io.Timestamp.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.Timestamp;
  return proto.io.Timestamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.Timestamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.Timestamp}
 */
proto.io.Timestamp.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setSeconds(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setNanos(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.Timestamp.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.Timestamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.Timestamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.Timestamp.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSeconds();
  if (f !== 0) {
    writer.writeInt64(
        1,
        f
    );
  }
  f = message.getNanos();
  if (f !== 0) {
    writer.writeInt32(
        2,
        f
    );
  }
};


/**
 * optional int64 seconds = 1;
 * @return {number}
 */
proto.io.Timestamp.prototype.getSeconds = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.io.Timestamp.prototype.setSeconds = function (value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 nanos = 2;
 * @return {number}
 */
proto.io.Timestamp.prototype.getNanos = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.io.Timestamp.prototype.setNanos = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.io.LatLng = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.io.LatLng, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.io.LatLng.displayName = 'proto.io.LatLng';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.io.LatLng.prototype.toObject = function (opt_includeInstance) {
    return proto.io.LatLng.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.io.LatLng} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.io.LatLng.toObject = function (includeInstance, msg) {
    var f, obj = {
      latitude: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
      longitude: +jspb.Message.getFieldWithDefault(msg, 2, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.io.LatLng}
 */
proto.io.LatLng.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.io.LatLng;
  return proto.io.LatLng.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.io.LatLng} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.io.LatLng}
 */
proto.io.LatLng.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setLatitude(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setLongitude(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.io.LatLng.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.io.LatLng.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.io.LatLng} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.io.LatLng.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
        1,
        f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
        2,
        f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.io.LatLng.prototype.getLatitude = function () {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.io.LatLng.prototype.setLatitude = function (value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.io.LatLng.prototype.getLongitude = function () {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.io.LatLng.prototype.setLongitude = function (value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.io.NullValue = {
  NULL_VALUE: 0
};

goog.object.extend(exports, proto.io);
