import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import styles from "../styles";
import Autosuggest from "react-autosuggest";
import countries from "../../data/countries";

const theme = {
    container: {
        position: 'relative'
    },
    input: {
        width: '100%',
        padding: '18.5px 14px',
        fontFamily: 'Helvetica, sans-serif',
        fontWeight: 300,
        fontSize: 16,
        border: '1px solid #aaa',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    inputFocused: {
        outline: 'none'
    },
    inputOpen: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    },
    suggestionsContainer: {
        display: 'none'
    },
    suggestionsContainerOpen: {
        display: 'block',
        position: 'absolute',
        top: 51,
        width: '100%',
        border: '1px solid #aaa',
        backgroundColor: '#fff',
        fontFamily: 'Helvetica, sans-serif',
        fontWeight: 300,
        fontSize: 16,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        zIndex: 2
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    suggestion: {
        cursor: 'pointer',
        padding: '10px 20px'
    },
    suggestionHighlighted: {
        backgroundColor: '#ddd'
    }
};


function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());
    const regex = new RegExp('^' + escapedValue, 'i');
    const names = countries
        .map(section => {
            return {
                title: section.title,
                countries: section.countries.filter(language =>
                    regex.test(language.name)
                )
            };
        })
        .filter(section => section.countries.length > 0);
    return names;
}

function getSuggestionValue(suggestion) {
    return suggestion.name;
}

function renderSuggestion(suggestion) {
    return (
        <span className="react-autosuggest__suggestions-container">{suggestion.name}</span>
    );
}

function renderSectionTitle(section) {
    return (<strong></strong>
    );
}

function getSectionSuggestions(section) {

    return section.countries;
}

let highlightFirstSuggestion = false;

function setHighlightFirstSuggestion(value) {
    highlightFirstSuggestion = value;
}

class UTC_Suggestions extends Component {
    state = {
        value: this.props.country,
        suggestions: []
    };

    onChange = (event, {newValue, method}) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = (event, {suggestion}) => {
        this.sendOffset(suggestion.offset, suggestion.name)
    };

    sendOffset = (offsetValueByName, name) => {
        this.props.offset(offsetValueByName, name);
    };

    render() {
        const {classes} = this.props;
        const {value, suggestions} = this.state;
        const inputProps = {
            placeholder: "Type Here....",
            value,
            onChange: this.onChange
        };
        return (
            <div>
                <Autosuggest
                    theme={theme}
                    multiSection={true}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    renderSectionTitle={renderSectionTitle}
                    getSectionSuggestions={getSectionSuggestions}
                    highlightFirstSuggestion={highlightFirstSuggestion}
                    inputProps={inputProps}/>
            </div>
        )
    }
}

UTC_Suggestions.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UTC_Suggestions);