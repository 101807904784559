import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";

import styles from "./styles";
import {checkIfLogin, forceTokenRefresh, isAdmin} from "../../../configurations/global-config";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Uploader from "../../../components/ImageUpload/Uploader";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dashboard from "../../../layouts/Dashboard";
import {
    createBusinessGroup,
    fetchBusinessGroupDetails,
    updateBusinessGroupDetails
} from "../../../grpcRequests/business-groups-request";
import {
    BusinessAccount,
    CreateBusinessAccountRequest,
    GetBusinessAccountProfileAsUserRequest,
    UpdateBusinessAccountRequest
} from "../../../generated/frontend-business-account-service_pb";
import {Location} from "../../../generated/frontend-account-common-types_pb";
import GroupCategories from "../../../data/groupsCategories";
import {uploadImageToDigitalOcean} from "../../../components/ImageUpload/upload";
import globalConfig from "../../../configurations/configVariables";
import SnackBar from "../../../components/SnackBar/SnackBar";
import PleaseWait from "../../../components/PleaseWait";
import usedString, {getRoleString} from "../../../utils/mapper/enumToStringMapper";
import {GetGroupRequest} from "../../../generated/frontend-community-group-service_pb";
import {getGroupDetails} from "../../../grpcRequests/groups-request";
import {pageViewAnalyst} from "../../../configurations/config";

const ranges = [
    {
        value: '_admin',
        label: 'Admin',
        address: ''
    },
];

class CreateGroups extends Component {

    state = {
        //starts here
        coverImage: '',
        groupLogo: '',
        groupName: '',
        groupUrl: '',
        groupSubCategory: '',
        groupCategory: '',
        country: '',
        address: '',
        latitude: 0, longitude: 0,
        groupDesc: '',
        verified: false,
        suspended: false,
        userRole: '',

        creatingGroup: false,

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null,

        //Edit Event
        isEditing: false,
        toEdit: this.props.match.params.groupId,
        type: this.props.match.params.type,
        groupDetails: [], isFetchingGroups: false,

        //Images from childUploader
        imageFile: null,
        uploadingURL: null,
        coverImageFile: null,
        coverUploadingURL: null,

    };

    componentDidMount() {
        window.scrollTo(0, 0);
        checkIfLogin(this.props.location.pathname);
        if (isAdmin()) {
            this.setState({
                userRole: usedString.ADMIN
            })
        }
        this.isEditing();
    }

    getImageUploadingURL = (url, file) => {
        this.setState({imageFile: file, uploadingURL: url, groupLogo: url.split('?')[0]})
    };

    getCoverImageUploadingURL = (url, file) => {
        this.setState({coverImageFile: file, coverUploadingURL: url, coverImage: url.split('?')[0]})
    };

    isEditing = () => {
        if (this.state.type === 'edit') {
            pageViewAnalyst('Edit Community', this.props.location.pathname);
            this.getUserRole();
            this.setState({isEditing: true, isFetchingGroups: true, groupDetails: null});
            this.fetchGroupDetails();
        } else {
            pageViewAnalyst('Add New Community', this.props.location.pathname);
        }
    };

    getUserRole = () => {
        let self = this;
        const req = new GetGroupRequest();
        req.setGroupId(this.props.match.params.groupId);
        getGroupDetails(req, 3).then(function (response) {
            const group = response.getGroup();
            const userRole = getRoleString(group.getRole());
            self.setState({
                userRole
            });
        }).catch(function (error) {
        })
    };

    fetchGroupDetails = () => {
        let self = this;
        const req = new GetBusinessAccountProfileAsUserRequest();
        req.setBusinessAccountId(this.props.match.params.groupId);
        fetchBusinessGroupDetails(req, 4).then(function (response) {
            const groupDetails = response.getBusinessAccount();
            self.setState({
                groupDetails, isFetchingGroups: false
            });
            self.setFields();
        }).catch(function (error) {
            self.setState({
                isFetchingGroups: false
            });
            self.snackbar(true, 'Err while fetching community details', 'error', 5000);
        })
    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleAddressChange = address => {
        this.setState({address});
    };

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => this.setState({latitude: latLng.lat, longitude: latLng.lng, address: address}))
            .catch(error => console.error('Error', error));
    };

    createGroup = () => {
        this.setState({snackOpen: false, creatingGroup: true});
        let self = this;
        if (this.state.imageFile && this.state.uploadingURL) {
            uploadImageToDigitalOcean(this.state.imageFile, this.state.uploadingURL).then(function (response) {
                self.setState({groupLogo: response.image});
                self.uploadCover(response.image)
            }).catch(function (error) {
                self.snackbar(true, 'Err while uploading logo', 'success', 5000);
                self.uploadCover(self.state.groupLogo)
            })
        } else this.uploadCover(this.state.groupLogo)
    };
    uploadCover = (logo) => {
        let self = this;
        if (this.state.coverImageFile && this.state.coverUploadingURL) {
            uploadImageToDigitalOcean(this.state.coverImageFile, this.state.coverUploadingURL).then(function (response) {
                self.setState({coverImage: response.image});
                self.postGroup(response.image, logo)
            }).catch(function (error) {
                self.snackbar(true, 'Err while uploading coverImage', 'success', 5000);
                self.postGroup(self.state.coverImage, logo)
            })
        } else this.postGroup(this.state.coverImage, logo)
    };
    postGroup = (coverImage, logo) => {
        let self = this;
        let state = this.state;

        const location = new Location();
        location.setLat(state.latitude);
        location.setLon(state.longitude);

        const businessInf = new BusinessAccount();

        businessInf.setName(state.groupName);
        businessInf.setAppId(globalConfig.appId);
        businessInf.setAbout(state.groupDesc);
        businessInf.setUrl(state.groupUrl);
        businessInf.setCoverImage(coverImage ? coverImage : '');
        businessInf.setLogo(logo ? logo : '');
        businessInf.setCountry(state.country);
        businessInf.setLocation(location);
        businessInf.setCategory(state.groupCategory);
        businessInf.setSubcategory(state.groupSubCategory);
        businessInf.setAddress(state.address);
        businessInf.setVerified(state.verified);
        businessInf.setSuspended(state.suspended);

        if (state.isEditing) {
            const req = new UpdateBusinessAccountRequest();
            businessInf.setAccountId(this.props.match.params.groupId);
            req.setBusinessAccount(businessInf);
            updateBusinessGroupDetails(req).then(function (response) {
                self.snackbar(true, 'Community Updated', 'success', 5000);
                setTimeout(function () {
                    window.location.href = '/c/' + self.props.match.params.groupId + forceTokenRefresh();
                    ;
                }, 2000);
            }).catch(function (error) {
                self.setState({
                    creatingGroup: false
                });
                self.snackbar(true, 'Error while updating', 'error', 5000);
            })
        } else {
            const req = new CreateBusinessAccountRequest();
            req.setBusinessAccount(businessInf);
            createBusinessGroup(req).then(function (response) {
                self.snackbar(true, 'New Community Created', 'success', 5000);
                setTimeout(function () {
                    window.location.href = '/c/' + response.getBusinessAccountId() + forceTokenRefresh();
                    ;
                }, 2000);
            }).catch(function (error) {
                self.setState({
                    creatingGroup: false
                });
                self.snackbar(true, 'Error while creating', 'error', 5000);
            })
        }
    };

    setFields = () => {
        const groupDetails = this.state.groupDetails;
        this.setState({
            coverImage: groupDetails.getCoverImage() ? groupDetails.getCoverImage() : '',
            groupLogo: groupDetails.getLogo() ? groupDetails.getLogo() : '',
            groupName: groupDetails.getName(),
            groupUrl: groupDetails.getUrl(),
            groupCategory: groupDetails.getCategory(),
            groupSubCategory: groupDetails.getSubcategory(),
            country: groupDetails.getCountry(),
            address: groupDetails.getAddress(),
            latitude: groupDetails.getLocation().getLat(), longitude: groupDetails.getLocation().getLon(),
            groupDesc: groupDetails.getAbout(),
            verified: groupDetails.getVerified(),
            suspend: groupDetails.getSuspended(),
        })
    };

    reset() {
        this.setState({
            coverImage: '',
            groupLogo: '',
            groupName: '',
            groupUrl: '',
            groupSubCategory: '',
            groupCategory: '',
            country: '',
            address: '',
            latitude: 0, longitude: 0,
            groupDesc: '',
        })
    }

    render() {
        const {classes} = this.props;
        const {toEdit, userRole, groupDetails, groupName, groupCategory, groupSubCategory, groupDesc, groupLogo, address, coverImage, country, groupUrl, isEditing, creatingGroup, isFetchingGroups} = this.state;
        return (
            <Dashboard title={isEditing ? "Edit Community" : "Create Community"}
                       onBack={toEdit ? `/c/${toEdit}` : '/home'} linkTo={toEdit ? `/c/${toEdit}` : '/home'}>
                <div className={classes.root}>
                    {!isFetchingGroups ? (
                        <div>
                            {userRole === usedString.ADMIN ? (
                                <div>
                                    {groupDetails ? (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12}>
                                                <div className={classes.formWrapper}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography variant="body1" className={classes.eventTitle}>
                                                                Please fill all the required fields.
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <div style={{background: '#f4f6f7'}}>
                                                                <Uploader type={"communityLogo"}
                                                                          imageURL={coverImage ? coverImage : '/images/defaultCover.png'}
                                                                          paddingTop={'30%'}
                                                                          width={'auto'}
                                                                          index={"0"}
                                                                          sendImageURLObtainStatus={this.getCoverImageUploadingURL}/>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="body2">
                                                                <b>Community Logo</b>
                                                            </Typography>
                                                            <Uploader type={"communityLogo"}
                                                                      imageURL={groupLogo ? groupLogo : '/images/defaultLogo.png'}
                                                                      paddingTop={'40%'}
                                                                      width={'130px'}
                                                                      index={"0"}
                                                                      sendImageURLObtainStatus={this.getImageUploadingURL}/>
                                                        </Grid>
                                                        <Grid item xs={12} sm={9}>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Typography variant="body2">
                                                                        <b>Community Name</b> <span
                                                                        className={classes.required}>*</span>
                                                                    </Typography>
                                                                    <TextField
                                                                        required
                                                                        id="groupName"
                                                                        name="groupName"
                                                                        fullWidth
                                                                        value={groupName}
                                                                        className={classes.textField}
                                                                        onChange={this.handleChange('groupName')}
                                                                        autoComplete="Community Name"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Typography variant="body2">
                                                                        <b>URL</b> <span
                                                                        className={classes.required}>*</span>
                                                                    </Typography>
                                                                    <TextField
                                                                        required
                                                                        id="url"
                                                                        name="url"
                                                                        fullWidth
                                                                        value={groupUrl}
                                                                        helperText="Hint: www.hamropatro.com"
                                                                        onChange={this.handleChange('groupUrl')}
                                                                        autoComplete="URL"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Typography variant="body2">
                                                                        <b>Community Category</b> <span
                                                                        className={classes.required}>*</span>
                                                                    </Typography>
                                                                    <Select
                                                                        fullWidth
                                                                        value={groupCategory}
                                                                        onChange={this.handleChange("groupCategory")}
                                                                    >
                                                                        {Object.keys(GroupCategories).length ?
                                                                            Object.keys(GroupCategories).map(
                                                                                (option, index) =>
                                                                                    <MenuItem key={index}
                                                                                              value={option}>
                                                                                        {option}
                                                                                    </MenuItem>
                                                                            ) : null}</Select>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Typography variant="body2">
                                                                        <b>Community Sub-Category</b> <span
                                                                        className={classes.required}>*</span>
                                                                    </Typography>
                                                                    <Select
                                                                        fullWidth
                                                                        disable={!groupCategory}
                                                                        value={groupSubCategory}
                                                                        onChange={this.handleChange("groupSubCategory")}
                                                                    >
                                                                        {GroupCategories[groupCategory] === undefined ? null :
                                                                            GroupCategories[groupCategory].length ?
                                                                                GroupCategories[groupCategory].map(
                                                                                    (type) =>
                                                                                        <MenuItem value={type}>
                                                                                            {type}
                                                                                        </MenuItem>
                                                                                ) : null}</Select>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography variant="h6" className={classes.eventTitle}>
                                                                Additional Info
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography variant="body2">
                                                                <b>Country</b> <span
                                                                className={classes.required}>*</span>
                                                            </Typography>
                                                            <TextField
                                                                required
                                                                id="country"
                                                                name="country"
                                                                fullWidth
                                                                value={country}
                                                                onChange={this.handleChange('country')}
                                                                autoComplete="Country"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography variant="body2">
                                                                <b>Address</b>
                                                            </Typography>
                                                            <PlacesAutocomplete
                                                                value={address}
                                                                onChange={this.handleAddressChange}
                                                                onSelect={this.handleSelect}
                                                            >
                                                                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                                                    <div>
                                                                        <TextField
                                                                            fullWidth
                                                                            {...getInputProps({
                                                                                placeholder: 'Search Places ...',
                                                                                className: 'location-search-input',
                                                                            })}
                                                                        />
                                                                        <span
                                                                            className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                                            {suggestions.map(suggestion => {
                                                                                const className = suggestion.active
                                                                                    ? 'suggestion-item--active'
                                                                                    : 'suggestion-item';
                                                                                // inline style for demonstration purpose
                                                                                const style = suggestion.active
                                                                                    ? {
                                                                                        padding: '10px 5px',
                                                                                        backgroundColor: '#fafafa',
                                                                                        cursor: 'pointer'
                                                                                    }
                                                                                    : {
                                                                                        padding: '10px 5px',
                                                                                        backgroundColor: '#ffffff',
                                                                                        cursor: 'pointer'
                                                                                    };
                                                                                return (
                                                                                    <div
                                                                                        {...getSuggestionItemProps(suggestion, {
                                                                                            className,
                                                                                            style,
                                                                                        })}
                                                                                    >
                                                                                        <span>{suggestion.description}</span>
                                                                                    </div>
                                                                                );
                                                                            })}
                                            </span>
                                                                    </div>
                                                                )}
                                                            </PlacesAutocomplete>
                                                        </Grid>
                                                        {/*<Grid item xs={12} sm={3}>*/}
                                                        {/*    <Typography variant="body2">*/}
                                                        {/*        <b>Latitude</b>*/}
                                                        {/*    </Typography>*/}
                                                        {/*    <TextField*/}
                                                        {/*        id="Latitude"*/}
                                                        {/*        name="Latitude"*/}
                                                        {/*        type="number"*/}
                                                        {/*        value={latitude}*/}
                                                        {/*        onChange={this.handleChange('latitude')}*/}
                                                        {/*        fullWidth={true}*/}
                                                        {/*    />*/}
                                                        {/*</Grid>*/}
                                                        {/*<Grid item xs={12} sm={3}>*/}
                                                        {/*    <Typography variant="body2">*/}
                                                        {/*        <b>Longitude</b>*/}
                                                        {/*    </Typography>*/}
                                                        {/*    <TextField*/}
                                                        {/*        id="Longitude"*/}
                                                        {/*        type="number"*/}
                                                        {/*        name="Longitude"*/}
                                                        {/*        value={longitude}*/}
                                                        {/*        onChange={this.handleChange('longitude')}*/}
                                                        {/*        fullWidth={true}*/}
                                                        {/*    />*/}
                                                        {/*</Grid>*/}
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography variant="body2">
                                                                <b>About Community</b>
                                                            </Typography>
                                                            <TextField
                                                                multiline
                                                                fullWidth
                                                                rows="8"
                                                                rowsMax="25"
                                                                value={groupDesc}
                                                                onChange={this.handleChange('groupDesc')}
                                                                className={classes.textField}
                                                                margin="normal"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <div className={classes.floatRight}>
                                                                <span style={{padding: '0 20px'}}>
                                                                    {creatingGroup ? (
                                                                        <span> Please Wait...<CircularProgress
                                                                            className={classes.progress}
                                                                            size={15}/></span>) : (
                                                                        <span></span>)}
                                                                </span>
                                                                <Button variant="outlined" color="primary"
                                                                        className={classes.button}
                                                                        disabled={!groupName || !groupUrl || !country || creatingGroup}
                                                                        onClick={() => this.createGroup()}
                                                                >
                                                                    {isEditing ? 'Update Community' : 'Create Community'}
                                                                </Button>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    ) : (<div>Something went wrong</div>)}
                                </div>) : (<div></div>)}
                        </div>
                    ) : (<div>
                        <PleaseWait msg="fetching Community info... "/>
                    </div>)}
                </div>
                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
            </Dashboard>
        )
    }
}

CreateGroups.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CreateGroups);