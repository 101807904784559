export default [
    {
        id: '1',
        name: 'Home',
        icon: 'home',
        to: '/home'
    },
    // {
    //     id: '2',
    //     name: 'Notifications',
    //     icon: 'notification',
    //     to: '/notifications'
    // },
    {
        id: '2',
        name: 'Communities',
        icon: 'people',
        to: '/communities'
    },
    {
        id: '3',
        name: 'Events',
        icon: 'event',
        to: '/events'
    },
    {
        id: '4',
        name: 'My Tickets',
        icon: 'ticket',
        to: '/tickets'
    },
    {
        id: '5',
        name: 'Explore',
        icon: 'explore',
        to: '/explore'
    },
    // {
    //     id: '5',
    //     name: 'Channels',
    //     icon: 'public',
    //     to: '/channels'
    // },
    // {
    //     id: '6',
    //     name: 'Message',
    //     icon: 'message',
    //     to: '/messages'
    // },
    // {
    //     id: '8',
    //     name: 'Setting',
    //     icon: 'setting',
    //     to: '/settings'
    // },
    // {
    //     id: '4',
    //     name: 'Calendar',
    //     icon: 'calendar',
    //     to: '/calendar'
    // },
    // {
    //     id: '5',
    //     name: 'Profile',
    //     icon: 'account',
    //     to: '/profile'
    // },
];
