import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from "./styles";

class UserConfirmationDialog extends Component {
    state = {
        open: this.props.openDialog,
        dialogHeader: this.props.dialogHeader,
        dialogContent: this.props.dialogContent,
        dialogContent2: this.props.toDeleteContent,
        postKey: this.props.postId,
        button1: this.props.button1,
        button2: this.props.button2,

    };

    sendResponse = (clickedButton) => {
        this.props.sendUserResponse(clickedButton, this.state.postKey);
    };

    handleClose = (buttonValue) => {
        this.sendResponse(buttonValue);
        this.setState({open: false});
    };

    render() {
        const {open, dialogContent, dialogContent2, dialogHeader, button1, button2} = this.state;
        return (
            <div>
                <Dialog
                    fullWidth
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="alert-dialog-title">{dialogHeader}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>{dialogContent}</div>
                            <br/>
                            {dialogContent2}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => this.handleClose(button1)}>
                            {button1}
                        </Button>
                        <Button onClick={() => this.handleClose(button2)} color="primary" autoFocus>
                            {button2}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

UserConfirmationDialog.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserConfirmationDialog);