import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {Badge, OutlinedInput, Typography, withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styles
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import styles from './styles';
import {
    GetCategoriesRequest,
    GetGroupRequest,
    GroupType,
    TicketingPrivilegeRequest,
    UpdateGroupPushCountsRequest,
    UpdateGroupTypeRequest,
    UpdateNotificationPrivilegeRequest
} from "../../../generated/frontend-community-group-service_pb";
import {
    changeNotificationStsForGroup,
    getAllCategory,
    getGroupDetails,
    updateCommunityTicketing,
    updateGroupNotificationCount,
    updateGroupType
} from "../../../grpcRequests/groups-request";
import {AddFolloweeRequest} from "../../../generated/frontend-community-members-service_pb";
import {createJoinGroup, getAllPendingLists} from "../../../grpcRequests/members-request";
import InviteDialog from "../../../components/inviteDialogs";
import EventCard from "../../Events/components/EventCard";
import About from "./components/About";
import {checkIfLogin, forceTokenRefresh, isAdmin, isLogin} from "../../../configurations/global-config";
import usedString, {
    currencies,
    getGroupTypeStringInSentence,
    getRoleString,
    getStatusString
} from "../../../utils/mapper/enumToStringMapper";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {GroupDetailLoader} from "../../../components/Skeleton/EventsSket";
import Icon from "@material-ui/core/Icon";
import UserConfirmationDialog from "../../../components/UserConfirmationDialog";
import {DeleteBusinessAccountRequest} from "../../../generated/frontend-business-account-service_pb";
import {deleteGroup} from "../../../grpcRequests/business-groups-request";
import SnackBar from "../../../components/SnackBar/SnackBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import {eventAnalyst, pageViewAnalyst} from "../../../configurations/config";
import PreviousEvents from "../../Events/components/PreviousEvents";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import UserConfirmationWithText from "../../../components/ConfirmationBox/UserConfirmationWithText";
import {GetPendingMembersRequest} from "../../../generated/frontend-community-members-service_grpc_web_pb";
import {memberCountValue} from "../../../utils/converter/shortenString";
import LoginRequiredDialog from "../../../components/Dialogs/LoginRequiredDialog";
import {metaDataSet} from "../../../utils/MetaData";
import {GetEverestCounterRequest, IncrementCountRequest} from "../../../generated/frontend-everestdb-service_pb";
import {getPageCount, incrementCount} from "../../../grpcRequests/everestDB-request";
import {communityJSON} from "../../../utils/JSON";
import {addCommas, titleCase, validateFields} from "../../../utils/converter/common";
import ChannelsName from "./components/Channels";
import NewPostBox from "../../../components/PostBox/NewPostBox";
import TextField from "@material-ui/core/TextField";
import NotificationConfirmation from "../../../components/NotificationDialog/NotificationConfirmation";

class TabContainer extends Component {
    render() {
        return (
            <Typography component="div" style={{padding: 8 * 3}}>
                {this.props.children}
            </Typography>
        );
    }
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class GroupDetails extends Component {
    state = {
        isLoading: true,
        group: null,
        errMsg: null,
        value: 0,
        category: this.props.match.params.catId ? this.props.match.params.catId : '',
        isLoadingAdminPost: true,
        adminPosts: [],
        postingText: '',
        mainGroupID: this.props.match.params.groupId,
        categoriesList: [],

        createCat: false,
        catName: '',
        catDesc: '',
        catImage: '',
        creatingCat: false,
        disableCatBtn: false,

        title: 'Community Details',
        tempType: '',
        cType: 'Private',
        enableTicket: false,
        currencyType: 'USD',
        changeGroupType: false,
        changingGroupType: false,
        savingCurrencySts: false,


        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null,

        //category
        deleteCategory: false,
        openCat: null,
        pendingCount: '',

        //Login
        openLoginDialog: false,
        anchorEl: null,
        pageViewCount: '',
        shouldRefreshChannels: false,
        communityNotificationCount: 1,
        eventNotificationCount: 1,
        isNotificationAllowed: false,
        changingNotifSts: false,
        savingNotifCount: false,
        openNotificationDialog: false
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        pageViewAnalyst('Community Details', this.props.location.pathname);
        // checkIfLogin(this.props.location.pathname);

        this.increasePageCount(this.props.match.params.groupId);
        this.fetchUserGroups(this.props.match.params.groupId);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.match.params.catId !== this.props.match.params.catId) {
            // this.scrollToMyRef();
            this.setState({
                category: nextProps.match.params.catId ? nextProps.match.params.catId : ''
            });
        }
        if (nextProps.match.params.groupId !== this.props.match.params.groupId) {
            this.increasePageCount(nextProps.match.params.groupId);
            this.setState({
                mainGroupID: nextProps.match.params.groupId
            });
            // checkIfLogin(this.props.location.pathname);
            this.fetchUserGroups(nextProps.match.params.groupId);
        }
    }

    getUserResponseFromChild = (clickedButton, key) => {
        this.setState({openConfirmation: false});
        if (clickedButton === "Confirm") {
            this.deleteGroup(key);
        }
    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    fetchUserGroups = (groupId) => {
        let self = this;
        self.setState({
            isLoading: true
        });
        const req = new GetGroupRequest();
        req.setGroupId(groupId);
        getGroupDetails(req, 3).then(function (response) {
            eventAnalyst('community_detail', 'community_detail_fetch', 'community detail fetch success', 'community-detail-fetch-success');
            const group = response.getGroup();
            communityJSON(group);
            const title = group.getName();
            //set og-tag
            metaDataSet(group.getName(), group.getDesc(), window.location.origin + '/c/' + self.props.match.params.groupId, group.getCoverimage());
            //og-tag ends

            const cType = getGroupTypeStringInSentence(group.getGrouptype());
            const enableTicket = group.getTicketallowed();
            const eventNotificationCount = group.getMaxContentPushCount();
            const communityNotificationCount = group.getMaxGroupPushCount();
            const isNotificationAllowed = group.getNotificationallowed();
            const currencyType = group.getTransactionCurrencyList()[0];
            const categoriesList = response.getCategoryList();
            const isLoading = false;
            self.setState({
                isLoading,
                categoriesList,
                group,
                cType,
                enableTicket,
                currencyType,
                title,
                eventNotificationCount,
                communityNotificationCount,
                isNotificationAllowed,
            });
            if (isAdmin() || getRoleString(group.getRole()) === usedString.ADMIN || getRoleString(group.getRole()) === usedString.MODERATOR) {
                self.fetchPendingUsers();
                self.getGroupCounter(groupId);
            }
        }).catch(function (error) {
            eventAnalyst('community_detail', 'community_detail_fetch', 'community detail fetch fail', 'community-detail-fetch-fail');
            const errMsg = error.message;
            const isLoading = false;
            self.setState({
                isLoading,
                errMsg
            });
        })
    };

    openConfirmation = () => {
        eventAnalyst('delete_click', 'group_delete_click', this.state.mainGroupID + ' delete click', 'group-delete-click-success');
        this.setState({openConfirmation: true});
    };

    openNotificationDialog = () => {
        this.setState({openNotificationDialog: true});
    };

    geNotificationRes = () => {
        this.setState({openNotificationDialog: false});
    };

    handleTabChange = (event, value) => {
        this.setState({value});
    };

    openLoginDialog = () => {
        this.setState({openLoginDialog: true});
    };

    getLoginDialogRes = (clickedButton, key) => {
        this.setState({openLoginDialog: false});
        if (clickedButton === "login") {
            checkIfLogin(this.props.location.pathname);
        }
    };

    joinGroup = () => {
        if (!isLogin()) {
            this.openLoginDialog()
        } else {
            let self = this;
            const req = new AddFolloweeRequest();
            req.setFolloweeId(this.state.mainGroupID);
            req.setFolloweeType("BUSINESS");
            createJoinGroup(req).then(function (response) {
                self.fetchUserGroups(self.props.match.params.groupId);
            }).catch(function (error) {
            })
        }
    };

    goToMembers = (groupId) => {
        const {history} = this.props;
        history.push('/c/' + groupId + '/members');
    };

    deleteGroup = (groupId) => {
        this.setState({snackOpen: false});
        let self = this;
        const req = new DeleteBusinessAccountRequest();
        req.setBusinessAccountId(groupId);
        deleteGroup(req).then(function (response) {
            eventAnalyst('delete', groupId + '_delete', groupId + ' delete success', groupId + '-delete-success');
            self.snackbar(true, 'Community is Deleted', 'success', 1000);
            setTimeout(function () {
                window.location.href = '/home' + forceTokenRefresh();
            }, 3000);
        }).catch(function (error) {
            eventAnalyst('delete', groupId + '_delete', groupId + ' delete fail', groupId + '-delete-fail');
            self.snackbar(true, 'Error Deleting Community', 'error', 5000)

        })
    };

    fetchCategory = () => {
        let self = this;
        const req = new GetCategoriesRequest();
        req.setGroupId(this.state.mainGroupID);
        req.setPageSize(25);
        req.setNextPageCursor();
        getAllCategory(req,3).then(function (response) {
            const categoriesList = response.getCategoryList();
            self.setState({
                categoriesList
            });
        }).catch(function (error) {
        })
    };

    handleTypeChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleCommunityTypeChange = name => event => {
        this.setState({changeGroupType: true});
        this.setState({
            [name]: event.target.value,
        });
    };

    channelResponse = (responseType, status, msg) => {
        if(responseType==='created'){
            if(status) {
                this.snackbar(true, 'Category Created', 'success', 5000);
                this.fetchCategory();
            }else{
                this.snackbar(true, msg, 'error', 5000);
            }
        }
    };
    getChannelResponse = (type,status, channelName, message) => {
        this.setState({
            shouldRefreshChannels:false,
            isLoading:true,
        });
        this.setState({snackOpen: false});
        let self= this;
        if(type==='created'){
            if(status) {
                self.snackbar(true, 'Post Created', 'success', 5000);
                const isLoading = false;
                self.setState({
                    isLoading,
                    shouldRefreshChannels:true,
                    category:channelName
                });
            }else{
                const isLoading = false;
                self.setState({
                    isLoading,
                    shouldRefreshChannels:false
                });
                self.snackbar(true, 'Something Went Wrong', 'error', 5000);
            }
        }
    };

    getCTypeChangeResponse = (clickedButton, key) => {
        this.setState({snackOpen: false, changeGroupType: false});
        if (clickedButton === "Yes") {
            this.setState({changingGroupType: true});
            let self = this;
            const req = new UpdateGroupTypeRequest();
            req.setGroupId(this.state.mainGroupID);
            let groupType;
            if (this.state.tempType === usedString.Public) {
                groupType = GroupType.PUBLIC;
            } else groupType = GroupType.PRIVATE;
            req.setGroupType(groupType);
            updateGroupType(req).then(function (response) {
                self.setState({
                    cType: self.state.tempType,
                    changingGroupType: false
                });
                self.snackbar(true, 'Community is now ' + self.state.cType, 'success', 5000);
            }).catch(function (error) {
                self.setState({
                    changingGroupType: false
                });
                self.snackbar(true, 'Sorry. Could not change community type', 'error', 5000);
            })
        }
    };

    saveCurrencySts = () => {
        this.setState({snackOpen: false, savingCurrencySts: true});
        let self = this;
        const req = new TicketingPrivilegeRequest();
        req.setGroupId(this.state.mainGroupID);
        req.setEnableTicketing(this.state.enableTicket);
        let cur = [this.state.currencyType];
        req.setSupportedCurrencyList(cur);
        updateCommunityTicketing(req).then(function (response) {
            self.setState({
                enableTicket: response.getUpdatedTicketingStatus(),
                savingCurrencySts: false
            });
            self.state.enableTicket ?
                self.snackbar(true, 'Ticketing is allowed for the community', 'success', 5000) :
                self.snackbar(true, 'Ticketing is disabled for the community', 'info', 5000);
        }).catch(function (error) {
            self.setState({
                savingCurrencySts: false
            });
            self.snackbar(true, 'Sorry. Could not save changes', 'error', 5000);
        })
    };
    saveNotificationCount = () => {
        this.setState({snackOpen: false, savingNotifCount: true});
        let self = this;
        const req = new UpdateGroupPushCountsRequest();

        req.setGroupId(this.state.mainGroupID);
        req.setMaxGroupPushCount(parseInt(this.state.communityNotificationCount));
        req.setMaxContentPushCount(parseInt(this.state.eventNotificationCount));
        updateGroupNotificationCount(req).then(function (response) {
            if (response.getSuccess()) {
                self.setState({
                    savingNotifCount: false
                });
                self.snackbar(true, 'Notification count is changed for the community', 'success', 5000);
            } else
                self.snackbar(true, 'Sorry. Could not save changes', 'error', 5000);
        }).catch(function (error) {
            self.setState({
                savingNotifCount: false
            });
            self.snackbar(true, 'Sorry. Could not save changes', 'error', 5000);
        })
    };

    changeNotificationType = (isNotificationAllowed) => {
        // this.setState({isNotificationAllowed: !isNotificationAllowed});
        this.setState({
            changingNotifSts: true,
            snackOpen: false
        });
        let self = this;
        let userSelectValue = !isNotificationAllowed //user se;ect value is always opposite to state value of isNotificationAllowed;
        const req = new UpdateNotificationPrivilegeRequest();
        req.setGroupId(this.state.mainGroupID);
        req.setGroupNotificationEnable(userSelectValue);
        changeNotificationStsForGroup(req).then(function (response) {
            self.setState({
                changingNotifSts: false
            });

            self.setState({
                isNotificationAllowed: userSelectValue
            });
            if (userSelectValue)
                self.snackbar(true, 'Notification is now allowed for this community', 'success', 5000);
            else
                self.snackbar(true, 'Notification is now disabled for this community', 'success', 5000);
        }).catch(function (error) {
            self.snackbar(true, 'Cannot allow notification for this group', 'error', 5000);
            self.setState({
                changingNotifSts: false
            });
        })
    };

    fetchPendingUsers = () => {
        let self = this;
        const req = new GetPendingMembersRequest();
        req.setAccountId(this.props.match.params.groupId);
        req.setCursor('');
        req.setPageSize(25);
        getAllPendingLists(req).then(function (response) {
            const allCount = response.getPendingUsersList().length;
            let pendingLists;
            if (allCount >= 9) pendingLists = '9+';
            else if (allCount > 0) pendingLists = allCount;
            else pendingLists = '';
            const pendingCount = pendingLists;
            self.setState({
                pendingCount
            });
        }).catch(function (error) {
        })
    };

    increasePageCount = (groupId) => {
        const req = new IncrementCountRequest();
        req.setGroup('counters/community/group');
        req.setKey(groupId);
        req.setCounttoincr(1);
        incrementCount(req).then(function (response) {
        }).catch(function (error) {
        })
    };

    getGroupCounter = (groupId) => {
        let self = this;
        const req = new GetEverestCounterRequest();
        req.setGroup('counters/community/group');
        req.setKey(groupId);
        getPageCount(req).then(function (response) {
            self.setState({pageViewCount: response.getCounter().getCount()});
        }).catch(function (error) {
        })
    };

    render() {
        const {classes} = this.props;
        const {anchorEl, pageViewCount, title, value, group, isLoading, mainGroupID, openConfirmation, category, createCat, catName, catDesc, catImage, creatingCat, disableCatBtn, categoriesList, cType, enableTicket, currencyType, changeGroupType, tempType, savingCurrencySts, changingGroupType, pendingCount, openLoginDialog, shouldRefreshChannels, communityNotificationCount, eventNotificationCount, isNotificationAllowed, changingNotifSts, savingNotifCount, openNotificationDialog} = this.state;
        const rootGroupsURl = '/c/';
        const open = Boolean(anchorEl);
        return (
            <DashboardLayout title={title} onBack="/home" linkTo='' groupId={mainGroupID}>
                <div className={classes.minHeight}>
                    {!isLoading  || shouldRefreshChannels ? (
                        <div>
                            {group ? (
                                <div>
                                    <div className={classes.groupDetailImageWrapper}>
                                        <img
                                            alt="Community_Image"
                                            className={classes.groupImage}
                                            src={group.getCoverimage() ? group.getCoverimage() : '/images/defaultCover.png'}
                                        />
                                        {/*<Images images={[group.getCoverimage() ? group.getCoverimage() : 'https://everestdb.sgp1.digitaloceanspaces.com/testImages/8496d110-5bb7-11e9-addd-8a5c241502e4']} resizedImages={[group.getCoverimage() ? group.getCoverimage() : 'https://everestdb.sgp1.digitaloceanspaces.com/testImages/8496d110-5bb7-11e9-addd-8a5c241502e4']}/>*/}
                                        {/*<div className={classes.notifyWrapper}>*/}
                                        {/*    <Button*/}
                                        {/*        variant="contained"*/}
                                        {/*        color="default"*/}
                                        {/*        className={classes.fab} size="small"*/}
                                        {/*        onClick={this.openNotificationDialog}*/}
                                        {/*        endIcon={*/}
                                        {/*            <Icon>notifications_none</Icon>}*/}
                                        {/*    >*/}
                                        {/*        Notify*/}
                                        {/*    </Button>*/}
                                        {/*</div>*/}
                                        {getRoleString(group.getRole()) === usedString.ADMIN ? (
                                            <div className={classes.actionWrapper}>
                                                <Link
                                                    to={`/c/${mainGroupID}/edit/details`}
                                                    onClick={() => eventAnalyst(group.getName() + '_edit_click', group.getName() + '_edit_click', group.getName() + ' edit click', group.getName() + '-edit-click-success')}>
                                                    <Fab color="secondary" aria-label="edit"
                                                         className={classes.fabIcon} size="small">
                                                        <Icon>edit_icon</Icon>
                                                    </Fab>
                                                </Link>
                                                {isAdmin() ? (
                                                    <Fab color="primary" aria-label="delete"
                                                         className={classes.fabIcon} size="small"
                                                         onClick={this.openConfirmation}>
                                                        <Icon>delete_icon</Icon>
                                                    </Fab>
                                                ) : (<div></div>)}
                                            </div>) : (<div></div>)}
                                    </div>
                                    {openConfirmation ? (
                                        <UserConfirmationWithText
                                            openDialog={openConfirmation}
                                            dialogHeader="Delete Community"
                                            postId={mainGroupID}
                                            dialogContent={"Are you sure want to delete " + group.getName() + " ?"}
                                            button1="Cancel" button2="Confirm"
                                            toDeleteContent={<div
                                                className={classes.sharableLinkWrapper}>
                                                <div className={classes.sharableText}> You will lose all the content
                                                    associated with this community including posts, comments, events,
                                                    likes
                                                    and
                                                    community members. This action is irreversible.
                                                </div>
                                            </div>}
                                            sendUserResponse={this.getUserResponseFromChild}
                                            confirmationText='i will take the blame'
                                        />
                                    ) : (<div></div>)}
                                    <div>
                                        <div className={classes.detailHeader}>
                                            <Typography
                                                className={classes.groupName}
                                                variant="h5"
                                                style={{fontWeight: 600}}
                                            >
                                                {group.getName()}&nbsp;
                                            </Typography>
                                            {(isAdmin() || getRoleString(group.getRole()) === usedString.ADMIN) && pageViewCount > 0 ? (
                                                <Typography variant="body3" component="span" color="secondary">
                                                    Page View : {addCommas(pageViewCount)} times
                                                </Typography>
                                            ) : null}
                                            <Typography
                                                className={classes.groupTypeMember}
                                                variant="subtitle1"
                                            >
                                                {(getRoleString(group.getRole()) !== usedString.DEFAULT && getRoleString(group.getRole()) !== usedString.NONE) ? (
                                                    <span
                                                        title={`You are a ${titleCase(getStatusString(group.getStatus())) + ' ' + titleCase(getRoleString(group.getRole()))} of ${group.getName()} community`}>{titleCase(getRoleString(group.getRole()) === usedString.PARTICIPANT ? usedString.MODERATOR : getRoleString(group.getRole()))} | </span>) : null}<span>{cType} Community </span>| <span
                                                title='Community members'
                                                onClick={getRoleString(group.getRole()) === usedString.DEFAULT || getStatusString(group.getStatus()) !== usedString.APPROVED ? '' : () => this.goToMembers(group.getGroupid())}
                                                className={classes.followerCount}>{memberCountValue(group.getFollowerscount())} Members</span>
                                            </Typography>

                                            {((getRoleString(group.getRole()) === usedString.DEFAULT) && getStatusString(group.getStatus()) === usedString.EMPTY) ? (
                                                    <Fab
                                                        variant="extended"
                                                        size="small"
                                                        aria-label="Add"
                                                        className={classes.joinButton}
                                                        onClick={() => this.joinGroup()}
                                                    >
                                                        Join Community <AddIcon/>
                                                    </Fab>
                                                ) :
                                                (
                                                    <div>
                                                        {((getRoleString(group.getRole()) === usedString.DEFAULT || getRoleString(group.getRole()) === usedString.MEMBER) && getStatusString(group.getStatus()) === usedString.PENDING) ? (
                                                                <Fab
                                                                    variant="extended"
                                                                    size="small"
                                                                    aria-label="Add"
                                                                    className={classes.pendingButton}
                                                                >
                                                                    Pending
                                                                </Fab>
                                                            ) :
                                                            (
                                                                <div className={classes.displayFlex}>
                                                                    {(getRoleString(group.getRole()) === usedString.ADMIN || getRoleString(group.getRole()) === usedString.PARTICIPANT || getRoleString(group.getRole()) === usedString.MEMBER) ? (
                                                                        <InviteDialog
                                                                            groupId={mainGroupID}
                                                                            userRole={getRoleString(group.getRole())}
                                                                            shareLink={group.getDirectJoinLink() ? group.getDirectJoinLink() : ''}/>
                                                                    ) : (<div></div>)}
                                                                    <Link to={`${rootGroupsURl}${mainGroupID}/calendar`}
                                                                          onClick={() => eventAnalyst('community_calendar', 'community_calendar_clicked', group.getName() + '-calendar', group.getName() + '-calendar-click-success')}>
                                                                        <Fab
                                                                            variant="extended"
                                                                            size="small"
                                                                            aria-label="Add"
                                                                            className={classes.calendarIcon}
                                                                        >
                                                                            Calendar &nbsp;
                                                                        </Fab>
                                                                    </Link>

                                                                </div>

                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {(getRoleString(group.getRole()) === usedString.DEFAULT || getRoleString(group.getRole()) === usedString.MEMBER) && (getStatusString(group.getStatus()) === usedString.PENDING || getStatusString(group.getStatus()) === usedString.EMPTY) ? (
                                                <div>
                                                    <NewPostBox userRole={getRoleString(group.getRole())}
                                                                userRoleStatus={getStatusString(group.getStatus())}
                                                                categoriesList={categoriesList}
                                                                sendCreatedToChannelResponse={this.getChannelResponse}
                                                                groupId={mainGroupID} groupName={group.getName()}/>
                                                    <About group={group} groupType={cType} history={this.props}/>
                                                    <EventCard role={group.getRole()} groupID={mainGroupID}/>
                                                    {cType!==usedString.Private ? (
                                                        <ChannelsName categoriesList={categoriesList} category={category} group={group} mainGroupID={mainGroupID} cType={cType} sendChannelResponse={this.channelResponse}/>
                                                    ):null}
                                                </div>
                                            ) :
                                            (
                                                <div>
                                                    <div>
                                                        <NewPostBox userRole={getRoleString(group.getRole())}
                                                                    userRoleStatus={getStatusString(group.getStatus())}
                                                                    categoriesList={categoriesList}
                                                                    sendCreatedToChannelResponse={this.getChannelResponse}
                                                                    groupId={mainGroupID} groupName={group.getName()}/>
                                                        <AppBar position="static" color=""
                                                                className={classes.detailTabs}>
                                                            <Tabs
                                                                value={value}
                                                                onChange={this.handleTabChange}
                                                                indicatorColor="primary"
                                                                textColor="primary"
                                                                style={{width: '100%', padding: '16px 0'}}
                                                                variant="scrollable"
                                                                scrollButtons="auto"
                                                            >
                                                                <Tab label="Home" value={0}/>
                                                                <Tab label="Events" value={1}
                                                                     onClick={() => eventAnalyst('community_detail', 'community_event_click', group.getName() + '-event-clicked', '-about-clicked')}/>
                                                                <Tab label={
                                                                    ((getRoleString(group.getRole()) === usedString.ADMIN || getRoleString(group.getRole()) === usedString.PARTICIPANT) && pendingCount) ?
                                                                        (<Badge className={classes.badge}
                                                                                color="primary"
                                                                                badgeContent={pendingCount}><span>Members</span></Badge>) : 'MEMBERS'}
                                                                     onClick={() => this.goToMembers(group.getGroupid())}/>
                                                                {getRoleString(group.getRole()) === usedString.ADMIN ? (
                                                                    <Tab label="Setting" value={3}/>
                                                                ) : null}
                                                                <Tab label="About" value={4}
                                                                     onClick={() => eventAnalyst('community_detail', 'community_about_click', group.getName() + '-about-clicked', '-about_view-clicked')}/>
                                                            </Tabs>
                                                        </AppBar>
                                                        {value === 0 && <div></div>}
                                                        {value === 1 && <div>
                                                            <div className={classes.addEventWrapper}>
                                                                {getRoleString(group.getRole()) === usedString.ADMIN ? (
                                                                    <div>
                                                                        <div className={classes.floatRight}>
                                                                            <div className={classes.buttonWrapper}>
                                                                                <Link
                                                                                    to={`${rootGroupsURl}${mainGroupID}/addEvent`}>
                                                                                    <Button variant="outlined"
                                                                                            color="primary"
                                                                                            className={classes.button}>
                                                                                        Add Event
                                                                                    </Button>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <br/>
                                                                    </div>
                                                                ) : (<div></div>)}
                                                            </div>
                                                            <EventCard role={group.getRole()} groupID={mainGroupID}/>
                                                            <PreviousEvents role={group.getRole()}
                                                                            groupID={mainGroupID}/>
                                                        </div>}
                                                        {value === 3 && getRoleString(group.getRole()) === usedString.ADMIN &&
                                                        <div className={classes.headersWrapper}>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <Typography
                                                                            variant="h6"
                                                                            style={{fontWeight: 600}}
                                                                        >
                                                                            Community Type {changingGroupType ? (<span
                                                                            style={{
                                                                                color: '#5726ca',
                                                                                fontSize: '13px'
                                                                            }}>Changing Community&nbsp; &nbsp;
                                                                            <CircularProgress
                                                                                size={12}/> </span>) : null}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <Typography
                                                                        component="div"
                                                                        color="textPrimary"
                                                                    > Community can be either be private or public.
                                                                    </Typography>
                                                                    <Typography
                                                                        component="div"
                                                                        className={classes.textPublic}
                                                                        color="textPrimary"
                                                                    > <b>Private community </b> is referred to as a
                                                                        closed
                                                                        community where only members can view the
                                                                        contents
                                                                        of the community.
                                                                    </Typography>
                                                                    <Typography
                                                                        component="div"
                                                                        className={classes.textPublic}
                                                                        color="textPrimary"
                                                                    > <b>Public community</b> is referred to as an open
                                                                        community where anyone can view the contents of
                                                                        the
                                                                        community.
                                                                    </Typography>

                                                                </div>
                                                                <FormControl component="fieldset"
                                                                             className={classes.formControl}>
                                                                    <RadioGroup
                                                                        className={classes.group}
                                                                        value={cType}
                                                                        onChange={this.handleCommunityTypeChange('tempType')}
                                                                        row
                                                                    >
                                                                        <FormControlLabel value="Private"
                                                                                          control={<Radio/>}
                                                                                          label="Private"/>
                                                                        <FormControlLabel value="Public"
                                                                                          control={<Radio/>}
                                                                                          label="Public"/>
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </div>
                                                            {isAdmin() ? (
                                                                <div>
                                                                    <div className={classes.simpleUnderline}></div>
                                                                    <br/>
                                                                    <div style={{
                                                                        background: 'aliceblue',
                                                                        padding: '10px 15px'
                                                                    }}>
                                                                        <div>
                                                                            <div>
                                                                                <div>
                                                                                    <Typography
                                                                                        variant="h6"
                                                                                        style={{fontWeight: 600}}
                                                                                    >
                                                                                        Tickets
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <Typography
                                                                                component="div"
                                                                                color="textPrimary"
                                                                            > Super Admin can enable ticketing for
                                                                                events
                                                                                organized
                                                                                by the community.
                                                                            </Typography>
                                                                            <FormControlLabel
                                                                                control={<Checkbox color="secondary"
                                                                                                   name="allowTicket"
                                                                                                   checked={enableTicket}
                                                                                                   onChange={() => this.setState({enableTicket: !enableTicket})}/>}
                                                                                label="Allow Ticketing"
                                                                            />
                                                                            <br/>
                                                                            {enableTicket ? (
                                                                                <div>
                                                                                    <Typography>
                                                                                        <b>Currency Type</b>
                                                                                    </Typography>
                                                                                    <Select
                                                                                        value={currencyType}
                                                                                        onChange={this.handleTypeChange("currencyType")}
                                                                                        input={
                                                                                            <OutlinedInput
                                                                                                name="currencyType"
                                                                                                id="currency-type"
                                                                                            />
                                                                                        }
                                                                                    >
                                                                                        {currencies.map(option => (
                                                                                            <MenuItem key={option.value}
                                                                                                      value={option.value}>
                                                                                                {option.label}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </div>
                                                                            ) : (<div></div>)}
                                                                            <br/>
                                                                            <Button color="secondary"
                                                                                    className={classes.button}
                                                                                    disabled={savingCurrencySts}
                                                                                    onClick={this.saveCurrencySts}>
                                                                                {savingCurrencySts ? (
                                                                                    <span>Saving&nbsp; &nbsp;
                                                                                        <CircularProgress
                                                                                            size={12}/> </span>) : 'Save'}
                                                                            </Button>
                                                                            <br/>
                                                                        </div>
                                                                    </div>
                                                                    <br/>
                                                                    <div className={classes.simpleUnderline}></div>
                                                                    <br/>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <div>
                                                                                    <Typography
                                                                                        variant="h6"
                                                                                        style={{fontWeight: 600}}
                                                                                    >
                                                                                        Notification {changingNotifSts ? (
                                                                                        <span
                                                                                            style={{
                                                                                                color: '#5726ca',
                                                                                                fontSize: '13px'
                                                                                            }}>Updating Notification Status&nbsp; &nbsp;
                                                                                            <CircularProgress
                                                                                                size={12}/> </span>) : null}
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <Typography
                                                                                component="div"
                                                                                color="textPrimary"
                                                                                className={classes.textPublic}
                                                                            > Super Admin can modify total no of
                                                                                notification to be sent by a community
                                                                                for regarding events and community
                                                                                information.
                                                                            </Typography>
                                                                            <FormControlLabel
                                                                                control={<Checkbox color="secondary"
                                                                                                   name="allowNotification"
                                                                                                   checked={isNotificationAllowed}
                                                                                                   onChange={() => this.changeNotificationType(isNotificationAllowed)}/>}
                                                                                label="Allow Notification"
                                                                            />
                                                                            <br/>
                                                                            {isNotificationAllowed ? (
                                                                                <div>
                                                                                    {/*<Typography*/}
                                                                                    {/*    component="div"*/}
                                                                                    {/*    className={classes.textPublic}*/}
                                                                                    {/*    color="textPrimary"*/}
                                                                                    {/*>   <b>For Community</b> <br/>*/}
                                                                                    {/*    <div style={{alignItems:'center', display:'inline-flex'}}>*/}
                                                                                    {/*        Total number of Notification:*/}
                                                                                    {/*        <TextField*/}
                                                                                    {/*            required*/}
                                                                                    {/*            variant="outlined"*/}
                                                                                    {/*            name="communityNotificationCount"*/}
                                                                                    {/*            value={communityNotificationCount}*/}
                                                                                    {/*            onChange={this.handleTypeChange('communityNotificationCount')}*/}
                                                                                    {/*            type="number"*/}
                                                                                    {/*            style={{width:'100px', padding:'0 10px'}}*/}
                                                                                    {/*            min="0"*/}
                                                                                    {/*        /> Times*/}
                                                                                    {/*    </div>*/}
                                                                                    {/*</Typography>*/}
                                                                                    <Typography
                                                                                        component="div"
                                                                                        className={classes.textPublic}
                                                                                        color="textPrimary"
                                                                                    > <b>For Events</b> <br/>
                                                                                        <div style={{
                                                                                            alignItems: 'center',
                                                                                            display: 'inline-flex'
                                                                                        }}>
                                                                                            Total number of
                                                                                            Notification:
                                                                                            <TextField
                                                                                                required
                                                                                                variant="outlined"
                                                                                                name="eventNotificationCount"
                                                                                                value={eventNotificationCount}
                                                                                                onChange={this.handleTypeChange('eventNotificationCount')}
                                                                                                type="number"
                                                                                                style={{
                                                                                                    width: '100px',
                                                                                                    padding: '0 10px'
                                                                                                }}
                                                                                                min="0"
                                                                                            /> Times
                                                                                        </div>
                                                                                    </Typography>
                                                                                    <Button color="secondary"
                                                                                            variant={"contained"}
                                                                                            className={classes.floatRight}
                                                                                            disabled={savingNotifCount || validateFields(communityNotificationCount).err || validateFields(eventNotificationCount).err}
                                                                                            onClick={this.saveNotificationCount}>
                                                                                        {savingNotifCount ? (
                                                                                            <span>Saving&nbsp; &nbsp;
                                                                                                <CircularProgress
                                                                                                    size={12}/> </span>) : 'Save'}
                                                                                    </Button>
                                                                                </div>
                                                                            ) : null}
                                                                            <br/>
                                                                            <div
                                                                                className={classes.simpleUnderline}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (<div></div>)}
                                                        </div>}
                                                        {value === 4 && <div>
                                                            <About group={group} groupType={cType}
                                                                   history={this.props}/>
                                                        </div>}
                                                    </div>
                                                    {value === 0 ? (
                                                        <div>
                                                            <EventCard role={group.getRole()} groupID={mainGroupID}/>
                                                            <ChannelsName categoriesList={categoriesList} category={category} group={group} mainGroupID={mainGroupID} cType={cType} sendChannelResponse={this.channelResponse}/>
                                                        </div>
                                                    ) : (<div></div>)}
                                                </div>
                                            )
                                        }
                                    </div>
                                    {changeGroupType ? (
                                        <UserConfirmationDialog
                                            openDialog={changeGroupType}
                                            dialogHeader="Change Community Type"
                                            postId={mainGroupID}
                                            dialogContent={"Are you sure want to change " + group.getName() + " type to " + tempType + "?"}
                                            button1="No" button2="Yes"
                                            sendUserResponse={this.getCTypeChangeResponse}
                                        />
                                    ) : (<div></div>)}
                                </div>
                            ) : (<div className={classes.root}>Something went wrong</div>)}
                        </div>
                    ) : (
                        <div>
                            <GroupDetailLoader/>
                        </div>
                    )}
                </div>

                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
                <div>
                    {openLoginDialog ? (
                        <LoginRequiredDialog
                            openDialog={openLoginDialog} dialogHeader=""
                            postId="124"
                            dialogContent={"You are not logged in. Please login to continue and  start using more feature of the community."}
                            button1="cancel"
                            button2="login"
                            sendUserResponse={this.getLoginDialogRes}
                        />
                    ) : (<div></div>)}
                </div>
                {openNotificationDialog ? (
                    <NotificationConfirmation
                        openDialog={openNotificationDialog}
                        dialogHeader="Notifiy"
                        postId={mainGroupID}
                        eventId=''
                        sendUserResponse={this.geNotificationRes}
                    />
                ) : (<div></div>)}
            </DashboardLayout>
        );
    }
}

GroupDetails.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GroupDetails);
