/**
 * @fileoverview gRPC-Web generated client stub for com.hamropatro.sociallayer.io
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');


var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
const proto = {};
proto.com = {};
proto.com.hamropatro = {};
proto.com.hamropatro.sociallayer = {};
proto.com.hamropatro.sociallayer.io = require('./frontend-user-reaction-to-content-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.ContentToUserReactionInfoServiceClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.ContentToUserReactionInfoServicePromiseClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest,
 *   !proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse>}
 */
const methodDescriptor_ContentToUserReactionInfoService_GetContentUserReactionInfo = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.ContentToUserReactionInfoService/GetContentUserReactionInfo',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest,
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest,
 *   !proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse>}
 */
const methodInfo_ContentToUserReactionInfoService_GetContentUserReactionInfo = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.ContentToUserReactionInfoServiceClient.prototype.getContentUserReactionInfo =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ContentToUserReactionInfoService/GetContentUserReactionInfo',
            request,
            metadata || {},
            methodDescriptor_ContentToUserReactionInfoService_GetContentUserReactionInfo,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.ContentUserReactionInfoResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.ContentToUserReactionInfoServicePromiseClient.prototype.getContentUserReactionInfo =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.ContentToUserReactionInfoService/GetContentUserReactionInfo',
            request,
            metadata || {},
            methodDescriptor_ContentToUserReactionInfoService_GetContentUserReactionInfo);
    };


module.exports = proto.com.hamropatro.sociallayer.io;

