import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

class SnackBar extends Component {
    queue = [];
    state = {
        open: true,
        message: this.props.snackMSG,
        color: this.props.snackCOLOR,
        timeOut: this.props.snackTIMEOUT,
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        messageInfo: {},
    };

    componentDidMount() {
        this.handleClick(this.props.snackMSG);
    }

    closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open: false});
    };

    handleClick(message) {
        this.queue.push({
            message,
            key: new Date().getTime(),
        });
        if (this.state.open) {
            // immediately begin dismissing current message
            // to start showing new one
            this.setState({open: false});
        } else {
            this.processQueue();
        }
    };

    handleExited = () => {
        this.processQueue();
    };

    processQueue = () => {
        if (this.queue.length > 0) {
            this.setState({
                messageInfo: this.queue.shift(),
                open: true,
            });
        }
    };


    render() {
        const {classes} = this.props;
        const {open, timeOut, message, verticalAlign, horizontalAlign, color} = this.state;
        const Icon = variantIcon[color];

        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: verticalAlign,
                        horizontal: horizontalAlign,
                    }}
                    open={open}
                    autoHideDuration={timeOut}
                    onClose={this.closeSnackbar}
                    onExited={this.handleExited}
                >
                    <SnackbarContent
                        className={classes[color]}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}><Icon
                                className={classes.iconVarient}/>{message}</span>
                        }
                        action={[
                            <IconButton
                                size="small"
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.closeSnackbar}
                            >
                                <CloseIcon/>
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </div>
        )
    }
}

SnackBar.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SnackBar);