import usedString from "../mapper/enumToStringMapper";

export function createLink(text, userRole) {
    if (userWhoCanUseLink(userRole)) {
        let urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a href="https://l.hamropatro.com/' + url + '" target="_blank" class="linkGenerated" rel=”nofollow”>' + url + '</a>';
        })
    } else return text;
}

export function userWhoCanUseLink(userRole) {
    return userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT || userRole === usedString.MODERATOR;
    ;
}

export function returnLink(text) {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    // let urlRegex = /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi;
    // let urlRegex = /(\w[a-z][.][a-z])\w+/g;
    return text.replace(urlRegex, function (url) {
        return '<a href="https://l.hamropatro.com/' + url + '" target="_blank" class="linkGenerated">' + url + '</a>';
    })
}

function removeTags(str) {
    return str.replace(/(<)/ig, '&lt;');
}

export function generateLink(text) {
    let renderedLink = returnLink(removeTags(text));
    return {__html: renderedLink};
}