/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.com.hamropatro.sociallayer.io.AccountInfo', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.CommentDetail', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.CommentMessage', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ContentType', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.LocationInfo', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.MemberRole', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.MessageType', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ProfileImage', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.Reaction', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ReactionType', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ReplyMessage', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.Status', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UserComment', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UserComment.Status', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UserReply', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UserReply.Status', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ProfileImage = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ProfileImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ProfileImage.displayName = 'proto.com.hamropatro.sociallayer.io.ProfileImage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ProfileImage.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ProfileImage.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ProfileImage} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ProfileImage.toObject = function (includeInstance, msg) {
        var f, obj = {
            imageUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
            width: jspb.Message.getFieldWithDefault(msg, 2, 0),
            height: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ProfileImage}
 */
proto.com.hamropatro.sociallayer.io.ProfileImage.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ProfileImage;
    return proto.com.hamropatro.sociallayer.io.ProfileImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ProfileImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ProfileImage}
 */
proto.com.hamropatro.sociallayer.io.ProfileImage.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setImageUrl(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setWidth(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setHeight(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ProfileImage.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ProfileImage.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ProfileImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ProfileImage.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getImageUrl();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getWidth();
    if (f !== 0) {
        writer.writeUint32(
            2,
            f
        );
    }
    f = message.getHeight();
    if (f !== 0) {
        writer.writeUint32(
            3,
            f
        );
    }
};


/**
 * optional string image_url = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ProfileImage.prototype.getImageUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ProfileImage.prototype.setImageUrl = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 width = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ProfileImage.prototype.getWidth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ProfileImage.prototype.setWidth = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 height = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ProfileImage.prototype.getHeight = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ProfileImage.prototype.setHeight = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.CommentMessage = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.CommentMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.CommentMessage.displayName = 'proto.com.hamropatro.sociallayer.io.CommentMessage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.CommentMessage.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.CommentMessage.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.CommentMessage} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.CommentMessage.toObject = function (includeInstance, msg) {
        var f, obj = {
            text: jspb.Message.getFieldWithDefault(msg, 1, ""),
            metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : []
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.CommentMessage}
 */
proto.com.hamropatro.sociallayer.io.CommentMessage.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.CommentMessage;
    return proto.com.hamropatro.sociallayer.io.CommentMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.CommentMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.CommentMessage}
 */
proto.com.hamropatro.sociallayer.io.CommentMessage.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setText(value);
                break;
            case 2:
                var value = msg.getMetadataMap();
                reader.readMessage(value, function (message, reader) {
                    jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
                });
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.CommentMessage.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.CommentMessage.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.CommentMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.CommentMessage.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getText();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getMetadataMap(true);
    if (f && f.getLength() > 0) {
        f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
    }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.CommentMessage.prototype.getText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.CommentMessage.prototype.setText = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> metadata = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.CommentMessage.prototype.getMetadataMap = function (opt_noLazyCreate) {
    return /** @type {!jspb.Map<string,string>} */ (
        jspb.Message.getMapField(this, 2, opt_noLazyCreate,
            null));
};


proto.com.hamropatro.sociallayer.io.CommentMessage.prototype.clearMetadataMap = function () {
    this.getMetadataMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ReplyMessage = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ReplyMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ReplyMessage.displayName = 'proto.com.hamropatro.sociallayer.io.ReplyMessage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ReplyMessage.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ReplyMessage.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ReplyMessage} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ReplyMessage.toObject = function (includeInstance, msg) {
        var f, obj = {
            text: jspb.Message.getFieldWithDefault(msg, 1, ""),
            metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : []
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ReplyMessage}
 */
proto.com.hamropatro.sociallayer.io.ReplyMessage.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ReplyMessage;
    return proto.com.hamropatro.sociallayer.io.ReplyMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ReplyMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ReplyMessage}
 */
proto.com.hamropatro.sociallayer.io.ReplyMessage.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setText(value);
                break;
            case 2:
                var value = msg.getMetadataMap();
                reader.readMessage(value, function (message, reader) {
                    jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
                });
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ReplyMessage.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ReplyMessage.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ReplyMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ReplyMessage.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getText();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getMetadataMap(true);
    if (f && f.getLength() > 0) {
        f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
    }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReplyMessage.prototype.getText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReplyMessage.prototype.setText = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> metadata = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.ReplyMessage.prototype.getMetadataMap = function (opt_noLazyCreate) {
    return /** @type {!jspb.Map<string,string>} */ (
        jspb.Message.getMapField(this, 2, opt_noLazyCreate,
            null));
};


proto.com.hamropatro.sociallayer.io.ReplyMessage.prototype.clearMetadataMap = function () {
    this.getMetadataMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.AccountInfo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.AccountInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.AccountInfo.displayName = 'proto.com.hamropatro.sociallayer.io.AccountInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.AccountInfo.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.AccountInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.AccountInfo.toObject = function (includeInstance, msg) {
        var f, obj = {
            accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            accountName: jspb.Message.getFieldWithDefault(msg, 2, ""),
            accountType: jspb.Message.getFieldWithDefault(msg, 3, ""),
            accountImage: (f = msg.getAccountImage()) && proto.com.hamropatro.sociallayer.io.ProfileImage.toObject(includeInstance, f),
            isVerified: jspb.Message.getFieldWithDefault(msg, 6, false),
            isSuspended: jspb.Message.getFieldWithDefault(msg, 7, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.AccountInfo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.AccountInfo;
    return proto.com.hamropatro.sociallayer.io.AccountInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.AccountInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.AccountInfo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountName(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountType(value);
                break;
            case 5:
                var value = new proto.com.hamropatro.sociallayer.io.ProfileImage;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.ProfileImage.deserializeBinaryFromReader);
                msg.setAccountImage(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsVerified(value);
                break;
            case 7:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsSuspended(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.AccountInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.AccountInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.AccountInfo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getAccountName();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getAccountType();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getAccountImage();
    if (f != null) {
        writer.writeMessage(
            5,
            f,
            proto.com.hamropatro.sociallayer.io.ProfileImage.serializeBinaryToWriter
        );
    }
    f = message.getIsVerified();
    if (f) {
        writer.writeBool(
            6,
            f
        );
    }
    f = message.getIsSuspended();
    if (f) {
        writer.writeBool(
            7,
            f
        );
    }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_name = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.getAccountName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.setAccountName = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_type = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.getAccountType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.setAccountType = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ProfileImage account_image = 5;
 * @return {?proto.com.hamropatro.sociallayer.io.ProfileImage}
 */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.getAccountImage = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.ProfileImage} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.ProfileImage, 5));
};


/** @param {?proto.com.hamropatro.sociallayer.io.ProfileImage|undefined} value */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.setAccountImage = function (value) {
    jspb.Message.setWrapperField(this, 5, value);
};


proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.clearAccountImage = function () {
    this.setAccountImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.hasAccountImage = function () {
    return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool is_verified = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.getIsVerified = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.setIsVerified = function (value) {
    jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_suspended = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.getIsSuspended = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.AccountInfo.prototype.setIsSuspended = function (value) {
    jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UserComment = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UserComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.UserComment.displayName = 'proto.com.hamropatro.sociallayer.io.UserComment';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.UserComment.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.UserComment.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.UserComment} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.UserComment.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            postUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            status: jspb.Message.getFieldWithDefault(msg, 4, 0),
            comment: (f = msg.getComment()) && proto.com.hamropatro.sociallayer.io.CommentMessage.toObject(includeInstance, f),
            commentType: jspb.Message.getFieldWithDefault(msg, 6, 0),
            accountInfo: (f = msg.getAccountInfo()) && proto.com.hamropatro.sociallayer.io.AccountInfo.toObject(includeInstance, f),
            commentTimestamp: jspb.Message.getFieldWithDefault(msg, 8, 0),
            likes: jspb.Message.getFieldWithDefault(msg, 9, 0),
            dislikes: jspb.Message.getFieldWithDefault(msg, 10, 0),
            replies: jspb.Message.getFieldWithDefault(msg, 11, 0),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && proto.com.hamropatro.sociallayer.io.AccountInfo.toObject(includeInstance, f),
            spams: jspb.Message.getFieldWithDefault(msg, 13, 0),
            edited: jspb.Message.getFieldWithDefault(msg, 14, false),
            liked: jspb.Message.getFieldWithDefault(msg, 15, false),
            disliked: jspb.Message.getFieldWithDefault(msg, 16, false),
            spammed: jspb.Message.getFieldWithDefault(msg, 17, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UserComment}
 */
proto.com.hamropatro.sociallayer.io.UserComment.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.UserComment;
    return proto.com.hamropatro.sociallayer.io.UserComment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UserComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UserComment}
 */
proto.com.hamropatro.sociallayer.io.UserComment.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 4:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.UserComment.Status} */ (reader.readEnum());
                msg.setStatus(value);
                break;
            case 5:
                var value = new proto.com.hamropatro.sociallayer.io.CommentMessage;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.CommentMessage.deserializeBinaryFromReader);
                msg.setComment(value);
                break;
            case 6:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.MessageType} */ (reader.readEnum());
                msg.setCommentType(value);
                break;
            case 7:
                var value = new proto.com.hamropatro.sociallayer.io.AccountInfo;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.AccountInfo.deserializeBinaryFromReader);
                msg.setAccountInfo(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setCommentTimestamp(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLikes(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setDislikes(value);
                break;
            case 11:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setReplies(value);
                break;
            case 12:
                var value = new proto.com.hamropatro.sociallayer.io.AccountInfo;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            case 13:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setSpams(value);
                break;
            case 14:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setEdited(value);
                break;
            case 15:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setLiked(value);
                break;
            case 16:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setDisliked(value);
                break;
            case 17:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSpammed(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.UserComment.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UserComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UserComment.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getStatus();
    if (f !== 0.0) {
        writer.writeEnum(
            4,
            f
        );
    }
    f = message.getComment();
    if (f != null) {
        writer.writeMessage(
            5,
            f,
            proto.com.hamropatro.sociallayer.io.CommentMessage.serializeBinaryToWriter
        );
    }
    f = message.getCommentType();
    if (f !== 0.0) {
        writer.writeEnum(
            6,
            f
        );
    }
    f = message.getAccountInfo();
    if (f != null) {
        writer.writeMessage(
            7,
            f,
            proto.com.hamropatro.sociallayer.io.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getCommentTimestamp();
    if (f !== 0) {
        writer.writeUint64(
            8,
            f
        );
    }
    f = message.getLikes();
    if (f !== 0) {
        writer.writeUint64(
            9,
            f
        );
    }
    f = message.getDislikes();
    if (f !== 0) {
        writer.writeUint64(
            10,
            f
        );
    }
    f = message.getReplies();
    if (f !== 0) {
        writer.writeUint64(
            11,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            12,
            f,
            proto.com.hamropatro.sociallayer.io.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getSpams();
    if (f !== 0) {
        writer.writeUint64(
            13,
            f
        );
    }
    f = message.getEdited();
    if (f) {
        writer.writeBool(
            14,
            f
        );
    }
    f = message.getLiked();
    if (f) {
        writer.writeBool(
            15,
            f
        );
    }
    f = message.getDisliked();
    if (f) {
        writer.writeBool(
            16,
            f
        );
    }
    f = message.getSpammed();
    if (f) {
        writer.writeBool(
            17,
            f
        );
    }
};


/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.UserComment.Status = {
    APPROVED: 0,
    ACTIVE: 1,
    BLOCKED: 2,
    PENDING: 3
};

/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string post_uri = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.com.hamropatro.sociallayer.io.UserComment.Status}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getStatus = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.UserComment.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.UserComment.Status} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setStatus = function (value) {
    jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional CommentMessage comment = 5;
 * @return {?proto.com.hamropatro.sociallayer.io.CommentMessage}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getComment = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.CommentMessage} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.CommentMessage, 5));
};


/** @param {?proto.com.hamropatro.sociallayer.io.CommentMessage|undefined} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setComment = function (value) {
    jspb.Message.setWrapperField(this, 5, value);
};


proto.com.hamropatro.sociallayer.io.UserComment.prototype.clearComment = function () {
    this.setComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.hasComment = function () {
    return jspb.Message.getField(this, 5) != null;
};


/**
 * optional MessageType comment_type = 6;
 * @return {!proto.com.hamropatro.sociallayer.io.MessageType}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getCommentType = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.MessageType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.MessageType} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setCommentType = function (value) {
    jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional AccountInfo account_info = 7;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.AccountInfo, 7));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 7, value);
};


proto.com.hamropatro.sociallayer.io.UserComment.prototype.clearAccountInfo = function () {
    this.setAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.hasAccountInfo = function () {
    return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint64 comment_timestamp = 8;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getCommentTimestamp = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setCommentTimestamp = function (value) {
    jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 likes = 9;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getLikes = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setLikes = function (value) {
    jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 dislikes = 10;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getDislikes = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setDislikes = function (value) {
    jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint64 replies = 11;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getReplies = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setReplies = function (value) {
    jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional AccountInfo business_account_info = 12;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.AccountInfo, 12));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 12, value);
};


proto.com.hamropatro.sociallayer.io.UserComment.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 12) != null;
};


/**
 * optional uint64 spams = 13;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getSpams = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setSpams = function (value) {
    jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool edited = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getEdited = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setEdited = function (value) {
    jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool liked = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getLiked = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setLiked = function (value) {
    jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool disliked = 16;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getDisliked = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 16, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setDisliked = function (value) {
    jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool spammed = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.getSpammed = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UserComment.prototype.setSpammed = function (value) {
    jspb.Message.setProto3BooleanField(this, 17, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UserReply = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UserReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.UserReply.displayName = 'proto.com.hamropatro.sociallayer.io.UserReply';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.UserReply.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.UserReply.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.UserReply} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.UserReply.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            postUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            replyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            status: jspb.Message.getFieldWithDefault(msg, 5, 0),
            reply: (f = msg.getReply()) && proto.com.hamropatro.sociallayer.io.ReplyMessage.toObject(includeInstance, f),
            commentType: jspb.Message.getFieldWithDefault(msg, 7, 0),
            accountInfo: (f = msg.getAccountInfo()) && proto.com.hamropatro.sociallayer.io.AccountInfo.toObject(includeInstance, f),
            replyTimestamp: jspb.Message.getFieldWithDefault(msg, 9, 0),
            likes: jspb.Message.getFieldWithDefault(msg, 10, 0),
            dislikes: jspb.Message.getFieldWithDefault(msg, 11, 0),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && proto.com.hamropatro.sociallayer.io.AccountInfo.toObject(includeInstance, f),
            liked: jspb.Message.getFieldWithDefault(msg, 13, false),
            disliked: jspb.Message.getFieldWithDefault(msg, 14, false),
            spammed: jspb.Message.getFieldWithDefault(msg, 15, false),
            spams: jspb.Message.getFieldWithDefault(msg, 16, 0),
            edited: jspb.Message.getFieldWithDefault(msg, 17, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UserReply}
 */
proto.com.hamropatro.sociallayer.io.UserReply.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.UserReply;
    return proto.com.hamropatro.sociallayer.io.UserReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UserReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UserReply}
 */
proto.com.hamropatro.sociallayer.io.UserReply.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setReplyId(value);
                break;
            case 5:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.UserReply.Status} */ (reader.readEnum());
                msg.setStatus(value);
                break;
            case 6:
                var value = new proto.com.hamropatro.sociallayer.io.ReplyMessage;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.ReplyMessage.deserializeBinaryFromReader);
                msg.setReply(value);
                break;
            case 7:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.MessageType} */ (reader.readEnum());
                msg.setCommentType(value);
                break;
            case 8:
                var value = new proto.com.hamropatro.sociallayer.io.AccountInfo;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.AccountInfo.deserializeBinaryFromReader);
                msg.setAccountInfo(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setReplyTimestamp(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLikes(value);
                break;
            case 11:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setDislikes(value);
                break;
            case 12:
                var value = new proto.com.hamropatro.sociallayer.io.AccountInfo;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            case 13:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setLiked(value);
                break;
            case 14:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setDisliked(value);
                break;
            case 15:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSpammed(value);
                break;
            case 16:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setSpams(value);
                break;
            case 17:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setEdited(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.UserReply.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UserReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UserReply.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getReplyId();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getStatus();
    if (f !== 0.0) {
        writer.writeEnum(
            5,
            f
        );
    }
    f = message.getReply();
    if (f != null) {
        writer.writeMessage(
            6,
            f,
            proto.com.hamropatro.sociallayer.io.ReplyMessage.serializeBinaryToWriter
        );
    }
    f = message.getCommentType();
    if (f !== 0.0) {
        writer.writeEnum(
            7,
            f
        );
    }
    f = message.getAccountInfo();
    if (f != null) {
        writer.writeMessage(
            8,
            f,
            proto.com.hamropatro.sociallayer.io.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getReplyTimestamp();
    if (f !== 0) {
        writer.writeUint64(
            9,
            f
        );
    }
    f = message.getLikes();
    if (f !== 0) {
        writer.writeUint64(
            10,
            f
        );
    }
    f = message.getDislikes();
    if (f !== 0) {
        writer.writeUint64(
            11,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            12,
            f,
            proto.com.hamropatro.sociallayer.io.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getLiked();
    if (f) {
        writer.writeBool(
            13,
            f
        );
    }
    f = message.getDisliked();
    if (f) {
        writer.writeBool(
            14,
            f
        );
    }
    f = message.getSpammed();
    if (f) {
        writer.writeBool(
            15,
            f
        );
    }
    f = message.getSpams();
    if (f !== 0) {
        writer.writeUint64(
            16,
            f
        );
    }
    f = message.getEdited();
    if (f) {
        writer.writeBool(
            17,
            f
        );
    }
};


/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.UserReply.Status = {
    APPROVED: 0,
    ACTIVE: 1,
    BLOCKED: 2,
    PENDING: 3
};

/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string post_uri = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string reply_id = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getReplyId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setReplyId = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Status status = 5;
 * @return {!proto.com.hamropatro.sociallayer.io.UserReply.Status}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getStatus = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.UserReply.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.UserReply.Status} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setStatus = function (value) {
    jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional ReplyMessage reply = 6;
 * @return {?proto.com.hamropatro.sociallayer.io.ReplyMessage}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getReply = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.ReplyMessage} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.ReplyMessage, 6));
};


/** @param {?proto.com.hamropatro.sociallayer.io.ReplyMessage|undefined} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setReply = function (value) {
    jspb.Message.setWrapperField(this, 6, value);
};


proto.com.hamropatro.sociallayer.io.UserReply.prototype.clearReply = function () {
    this.setReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.hasReply = function () {
    return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MessageType comment_type = 7;
 * @return {!proto.com.hamropatro.sociallayer.io.MessageType}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getCommentType = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.MessageType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.MessageType} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setCommentType = function (value) {
    jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional AccountInfo account_info = 8;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.AccountInfo, 8));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 8, value);
};


proto.com.hamropatro.sociallayer.io.UserReply.prototype.clearAccountInfo = function () {
    this.setAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.hasAccountInfo = function () {
    return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint64 reply_timestamp = 9;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getReplyTimestamp = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setReplyTimestamp = function (value) {
    jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 likes = 10;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getLikes = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setLikes = function (value) {
    jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint64 dislikes = 11;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getDislikes = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setDislikes = function (value) {
    jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional AccountInfo business_account_info = 12;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.AccountInfo, 12));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 12, value);
};


proto.com.hamropatro.sociallayer.io.UserReply.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool liked = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getLiked = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setLiked = function (value) {
    jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool disliked = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getDisliked = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setDisliked = function (value) {
    jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool spammed = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getSpammed = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setSpammed = function (value) {
    jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional uint64 spams = 16;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getSpams = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setSpams = function (value) {
    jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional bool edited = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.getEdited = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UserReply.prototype.setEdited = function (value) {
    jspb.Message.setProto3BooleanField(this, 17, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.Reaction = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.Reaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.Reaction.displayName = 'proto.com.hamropatro.sociallayer.io.Reaction';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.Reaction.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.Reaction.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.Reaction} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.Reaction.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
            accountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            reactionType: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.Reaction}
 */
proto.com.hamropatro.sociallayer.io.Reaction.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.Reaction;
    return proto.com.hamropatro.sociallayer.io.Reaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.Reaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.Reaction}
 */
proto.com.hamropatro.sociallayer.io.Reaction.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 4:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (reader.readEnum());
                msg.setReactionType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.Reaction.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.Reaction.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.Reaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.Reaction.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getReactionType();
    if (f !== 0.0) {
        writer.writeEnum(
            4,
            f
        );
    }
};


/**
 * optional string post_uri = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Reaction.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Reaction.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Reaction.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Reaction.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ReactionType reaction_type = 4;
 * @return {!proto.com.hamropatro.sociallayer.io.ReactionType}
 */
proto.com.hamropatro.sociallayer.io.Reaction.prototype.getReactionType = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.ReactionType} value */
proto.com.hamropatro.sociallayer.io.Reaction.prototype.setReactionType = function (value) {
    jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.CommentDetail = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.CommentDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.CommentDetail.displayName = 'proto.com.hamropatro.sociallayer.io.CommentDetail';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.CommentDetail.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.CommentDetail} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.CommentDetail.toObject = function (includeInstance, msg) {
        var f, obj = {
            comment: (f = msg.getComment()) && proto.com.hamropatro.sociallayer.io.UserComment.toObject(includeInstance, f),
            likes: jspb.Message.getFieldWithDefault(msg, 2, 0),
            dislikes: jspb.Message.getFieldWithDefault(msg, 3, 0),
            liked: jspb.Message.getFieldWithDefault(msg, 4, false),
            disliked: jspb.Message.getFieldWithDefault(msg, 5, false),
            spammed: jspb.Message.getFieldWithDefault(msg, 6, false),
            spams: jspb.Message.getFieldWithDefault(msg, 7, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.CommentDetail}
 */
proto.com.hamropatro.sociallayer.io.CommentDetail.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.CommentDetail;
    return proto.com.hamropatro.sociallayer.io.CommentDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.CommentDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.CommentDetail}
 */
proto.com.hamropatro.sociallayer.io.CommentDetail.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.UserComment;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.UserComment.deserializeBinaryFromReader);
                msg.setComment(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLikes(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setDislikes(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setLiked(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setDisliked(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSpammed(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setSpams(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.CommentDetail.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.CommentDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.CommentDetail.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getComment();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.UserComment.serializeBinaryToWriter
        );
    }
    f = message.getLikes();
    if (f !== 0) {
        writer.writeUint64(
            2,
            f
        );
    }
    f = message.getDislikes();
    if (f !== 0) {
        writer.writeUint64(
            3,
            f
        );
    }
    f = message.getLiked();
    if (f) {
        writer.writeBool(
            4,
            f
        );
    }
    f = message.getDisliked();
    if (f) {
        writer.writeBool(
            5,
            f
        );
    }
    f = message.getSpammed();
    if (f) {
        writer.writeBool(
            6,
            f
        );
    }
    f = message.getSpams();
    if (f !== 0) {
        writer.writeUint64(
            7,
            f
        );
    }
};


/**
 * optional UserComment comment = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.UserComment}
 */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.getComment = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.UserComment} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.UserComment, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.UserComment|undefined} value */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.setComment = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.clearComment = function () {
    this.setComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.hasComment = function () {
    return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 likes = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.getLikes = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.setLikes = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 dislikes = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.getDislikes = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.setDislikes = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool liked = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.getLiked = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.setLiked = function (value) {
    jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool disliked = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.getDisliked = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.setDisliked = function (value) {
    jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool spammed = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.getSpammed = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.setSpammed = function (value) {
    jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional uint64 spams = 7;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.getSpams = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.CommentDetail.prototype.setSpams = function (value) {
    jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.LocationInfo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.LocationInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.LocationInfo.displayName = 'proto.com.hamropatro.sociallayer.io.LocationInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.LocationInfo.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.LocationInfo.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.LocationInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.LocationInfo.toObject = function (includeInstance, msg) {
        var f, obj = {
            lat: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
            lon: +jspb.Message.getFieldWithDefault(msg, 2, 0.0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.LocationInfo}
 */
proto.com.hamropatro.sociallayer.io.LocationInfo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.LocationInfo;
    return proto.com.hamropatro.sociallayer.io.LocationInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.LocationInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.LocationInfo}
 */
proto.com.hamropatro.sociallayer.io.LocationInfo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setLat(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setLon(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.LocationInfo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.LocationInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.LocationInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.LocationInfo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLat();
    if (f !== 0.0) {
        writer.writeDouble(
            1,
            f
        );
    }
    f = message.getLon();
    if (f !== 0.0) {
        writer.writeDouble(
            2,
            f
        );
    }
};


/**
 * optional double lat = 1;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.LocationInfo.prototype.getLat = function () {
    return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.LocationInfo.prototype.setLat = function (value) {
    jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double lon = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.LocationInfo.prototype.getLon = function () {
    return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.LocationInfo.prototype.setLon = function (value) {
    jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.ReactionType = {
    NONE: 0,
    LIKE: 1,
    DISLIKE: 2,
    COMMENT: 3,
    UNLIKE: 4,
    UNDISLIKE: 5,
    VIEW: 6,
    SPAM: 7,
    REPLY: 8
};

/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.MessageType = {
    TEXT: 0,
    IMAGE: 1,
    AUDIO: 2,
    VIDEO: 3
};

/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.ContentType = {
    POST_TYPE: 0,
    COMMENT_TYPE: 1,
    REPLY_TYPE: 2
};

/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.MemberRole = {
    DEFAULT: 0,
    PARTICIPANT: 1,
    ADMIN: 2,
    MEMBER: 3
};

/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.Status = {
    APPROVED: 0,
    PENDING: 1,
    BLOCKED: 2,
    EMPTY: 3
};

goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
