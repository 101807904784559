import React, {Component} from "react";
import PropTypes from "prop-types";
import {Grid, Typography, withStyles} from "@material-ui/core";
import {Link} from "react-router-dom";
import styles from "./styles";
import CircleIcon from '@material-ui/icons/CheckCircle';
import usedString, {getCountryCurrencySymbol} from "../../utils/mapper/enumToStringMapper";
import {formatDate} from "../../utils/converter/dateConverter";
import {b64Encode} from "../../utils/converter/base64";

var QRCode = require('qrcode.react');

class PaymentDetails extends Component {
    render() {
        const {ticketInfo, classes} = this.props;
        return (
            <Grid
                container
                spacing={3}
            >
                <Grid item xs={12} sm={12} style={{padding: '0 10px'}}>
                    <Typography component="div" style={{
                        fontSize: '15px',
                        color: '#7b798d',
                        padding: '10px 16px',
                        fontWeight: 900,
                        borderBottom: '1px solid #e5e5e5'
                    }}>
                        Ticket Details
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <div style={{padding: '0 16px'}}>
                        <Typography component="div" style={{fontSize: '17px', fontWeight: 600, lineHeight: '1.7'}}>
                            <Link className={classes.hover}
                                  to={`/c/${ticketInfo.getGroupId()}/events/${ticketInfo.getTicketSourceId()}`}>
                                {ticketInfo.getMetaDataMap().get('event_name')}
                            </Link>
                        </Typography>
                        <Typography component="div"
                                    style={{fontSize: '14px', color: '#7b798d'}}>
                            {ticketInfo.getMetaDataMap().get('event_location')}
                        </Typography>
                        <Typography component="div"
                                    style={{fontSize: '13px', color: '#7b798d'}}>
                            {formatDate(Number(ticketInfo.getMetaDataMap().get('event_startTime')), 'MMMM dd, yyyy p')}
                        </Typography>
                        {ticketInfo.getAmount() === 0 ? null : (
                            <Typography component="div" style={{fontSize: '12px', color: '#7b798d'}}>
                                Payment Mode : Credit Card
                            </Typography>)}
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div style={{padding: '0 16px', textAlign: 'center'}}>
                        <QRCode
                            id="123456"
                            value={b64Encode(ticketInfo.getTicketSourceId() + ':' + ticketInfo.getTicketTypeId() + ':' + ticketInfo.getTicketid() + ':' + ticketInfo.getUserId())}
                            size={130}
                            level={"L"}
                            renderAs={'svg'}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} style={{padding: '0 0 10px'}}>
                    <div style={{padding: '0 16px', textAlign: 'center'}}>
                        <Typography component="div"
                                    style={{fontSize: '13px', color: '#7b798d'}}>
                            Ticket Summary
                        </Typography>
                        <Typography component="span" style={{fontSize: '17px', fontWeight: 600, lineHeight: '1.1'}}>
                            Ticket No. #{ticketInfo.getTicketid()}&nbsp;
                            {ticketInfo.getStatus() === usedString.USED ? (
                                <span title="This ticket has been used"><CircleIcon fontSize='small'
                                                                                    style={{color: '#458c48'}}/></span>
                            ) : null}
                        </Typography>
                        <Typography component="div"
                                    style={{fontSize: '13px', color: '#7b798d'}}>
                            {formatDate(ticketInfo.getPaymentTime(), 'MMMM dd, yyyy p')}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div style={{padding: '0 16px'}}>
                        <Typography component="div"
                                    style={{fontSize: '13px', color: '#7b798d'}}>
                            Ticket Name
                        </Typography>
                        <Typography component="div" style={{fontSize: '15px', fontWeight: 600}}>
                            {ticketInfo.getTicketName()}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div style={{padding: '0 16px'}}>
                        <Typography component="div"
                                    style={{fontSize: '13px', color: '#7b798d'}}>
                            Quantity
                        </Typography>
                        <Typography component="div" style={{fontSize: '15px', fontWeight: 600}}>
                            1
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div style={{padding: '0 16px'}}>
                        <Typography component="div"
                                    style={{fontSize: '13px', color: '#7b798d'}}>
                            Amount
                        </Typography>
                        <Typography component="div" style={{fontSize: '15px', fontWeight: 600}}>
                            {ticketInfo.getAmount() === 0 ? usedString.FREE : (
                                <span>{ticketInfo.getPaymentCurrency()} {getCountryCurrencySymbol(ticketInfo.getPaymentCurrency())}{ticketInfo.getAmount()}</span>)}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div style={{padding: '0 16px'}}>
                        <Typography component="div"
                                    style={{fontSize: '13px', color: '#7b798d'}}>
                            Proceed By
                        </Typography>
                        <Typography component="div" style={{fontSize: '15px', fontWeight: 600}}>
                            {ticketInfo.getBuyerInfo().getName()}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div style={{padding: '0 16px'}}>
                        <Typography component="div"
                                    style={{fontSize: '13px', color: '#7b798d'}}>
                            Email Address
                        </Typography>
                        <Typography component="div" style={{fontSize: '15px', fontWeight: 600}}>
                            {ticketInfo.getBuyerInfo().getEmail() ? ticketInfo.getBuyerInfo().getEmail() : '-'}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div style={{padding: '0 16px'}}>
                        <Typography component="div"
                                    style={{fontSize: '13px', color: '#7b798d'}}>
                            Phone Number
                        </Typography>
                        <Typography component="div" style={{fontSize: '15px', fontWeight: 600}}>
                            {ticketInfo.getBuyerInfo().getPhone() ? ticketInfo.getBuyerInfo().getPhone() : '-'}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

PaymentDetails.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PaymentDetails);

