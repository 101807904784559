import {EventServiceServer as srv} from "../grpcClients/service-clients-factory";
import {MetaHeaders} from "../configurations/config";
import {retryCase} from "../utils/Common";

export function getEventDetails(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getEvent(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getEventDetails(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getGroupsEvents(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getGroupEvents(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getGroupsEvents(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getUpComingEvents(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getUpcomingEvents(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getUpComingEvents(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getPreviousEvents(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getPreviousEvents(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getPreviousEvents(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getUserGroupsEvents(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getUsersGroupEvents(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getUserGroupsEvents(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function addNewEvent(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.createEvent(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function updateUserResponseToEvent(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.updateGoingStatus(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function updateEventDetails(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.updateEvent(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function deleteEvent(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.removeEvent(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function eventAttendersList(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getEventAttenders(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getNearByEvent(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getNearbyEvents(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            getNearByEvent(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function exploreEvent(userReq, retryTimes) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.exploreEvents(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    if (retryTimes === 1 || retryCase(err.code)) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            exploreEvent(userReq, retryTimes - 1).then(function (response) {
                                resolve(response);
                            }).catch(function (error) {
                                reject(error);
                            });
                        }, 2000); //retry after 2 sec
                    }
                } else {
                    resolve(res);
                }
            })
        })
    });
}
