export default theme => ({
    root: {
        padding: theme.spacing(1),
        minHeight: '85vh'
    },

    loadingTable: {
        minHeight: '70px',
        textAlign: 'center',
    },
    countryFlag: {
        backgroundRepeat: 'round',
        backgroundColor: 'unset',
        margin: 'auto',
        top: '0',
        width: '30px',
        height: '20px',
        borderRadius: '0',
        display: 'inline-flex'
    },
    tableDataWrapper: {
        borderTop: '1px solid #ffffff',
        padding: '10px',
        background: 'aliceblue',
        '&:hover': {
            background: '#efefef', cursor: 'pointer'
        }
    },
    tableDataWrapperMobile: {
        borderTop: '6px solid #ffffff',
        padding: '8px !important',
        background: 'aliceblue',
        '&:hover': {
            background: '#efefef', cursor: 'pointer'
        }
    },
    tableHeadText: {
        fontSize: '17px', fontWeight: 600
    },
    tableHeadTextMobile: {
        fontSize: '15px', fontWeight: 600, width: 'fit-content', margin: 'auto'
    },
    tableItems: {
        padding: '10px 5px'
    },
    countryName: {
        fontSize: '15px', fontWeight: 600,
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    tableNumber: {
        fontSize: '14px', fontWeight: 600, textAlign: 'center'
    },
    countryWrapper: {
        display: 'inline-flex',
    },

});
