import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
// Views
import Home from './pages/Home';
import Notifications from './pages/Notifications';
import Groups from './pages/Groups';
import GroupDetails from './pages/Groups/GroupDetails';
import GroupMembers from './pages/Groups/Members';
import GroupsEvents from './pages/Events/GroupEvents';
import EventsDetails from './pages/Events/EventsDetails';
import AddEvent from './pages/Events/AddEvent';
import Events from './pages/Events';
import Channels from './pages/Channels';
import Messages from './pages/Messages';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import NotFound from './pages/NotFound';
import Login from './pages/Login'
import Calendar from './pages/Calendar'
import Explore from './pages/Explore'
import DirectJoin from "./pages/Groups/DirectJoin";
import CreateGroups from "./pages/Groups/Admin/CreateGroups";
import DirectAdmin from "./pages/Groups/DirectJoin/DirectAdmin";
import PostDetails from "./pages/Groups/PostDetails";
import CommentDetails from "./pages/Groups/CommentDetails";
import Tickets from "./pages/Tickets";
import TicketPaymentDetails from "./pages/Tickets/TicketPaymentDetails";
import TicketPaymentsByTicketTypeId from "./pages/Tickets/TicketPaymentLists";
import TicketPaymentsByTicketTimeStamp from "./pages/Tickets/TicketPaymentByTime";
import EventsDashboard from "./pages/Events/EventDashboard/";
import TicketPaymentsByPaymentId from "./pages/Tickets/TicketPaymentByPaymentId";
import QuickViewImages from "./pages/QuickPages/QuickViewImages";
import ExploreEvents from "./pages/Explore/ExploreEvents";
import ExploreCommunity from "./pages/Explore/ExploreCommunity";


export default class Routes extends Component {
    render() {
        return (

            <div>
                <Switch>
                    <Redirect
                        exact
                        from="/"
                        to="/home"
                    />
                    <Route
                        component={Home}
                        exact
                        path="/home"
                    />
                    <Route
                        component={Notifications}
                        exact
                        path="/notifications"
                    />
                    <Route
                        component={Groups}
                        exact
                        path="/communities"
                    />
                    <Route
                        component={Calendar}
                        exact
                        path="/calendar"
                    />
                    <Route
                        component={ExploreEvents}
                        exact
                        path="/explore/events"
                    />
                    <Route
                        component={ExploreEvents}
                        exact
                        path="/explore/events/:countryName"
                    />
                    <Route
                        component={ExploreCommunity}
                        exact
                        path="/explore/community"
                    />
                    <Route
                        component={ExploreCommunity}
                        exact
                        path="/explore/community/:countryName"
                    />
                    <Route
                        component={Explore}
                        exact
                        path="/explore"
                    />
                    <Route
                        component={Explore}
                        exact
                        path="/explore/:countryName"
                    />
                    {/*<Route*/}
                    {/*    component={CreateGroups}*/}
                    {/*    exact*/}
                    {/*    path="/community/create/new"*/}
                    {/*/>*/}
                    <Route
                        component={CreateGroups}
                        exact
                        path="/c/create/new"
                    />
                    {/*<Route*/}
                    {/*    component={CreateGroups}*/}
                    {/*    exact*/}
                    {/*    path="/community/:groupId/:type/details"*/}
                    {/*/>*/}
                    <Route
                        component={CreateGroups}
                        exact
                        path="/c/:groupId/:type/details"
                    />
                    <Route
                        component={GroupDetails}
                        exact
                        path="/community/:groupId"
                    />
                    <Route
                        component={GroupDetails}
                        exact
                        path="/c/:groupId"
                    />
                    <Route
                        component={GroupDetails}
                        exact
                        path="/c/:groupId/channel/:catId"
                    />
                    <Route
                        component={PostDetails}
                        exact
                        path="/community/:groupId/p/:postId"
                    />
                    <Route
                        component={PostDetails}
                        exact
                        path="/community/:groupId/post/:postId"
                    />
                    <Route
                        component={PostDetails}
                        exact
                        path="/c/:groupId/p/:postId"
                    />
                    <Route
                        component={CommentDetails}
                        exact
                        path="/c/:groupId/p/:postId/:commentId"
                    />
                    <Route
                        component={CommentDetails}
                        exact
                        path="/community/:groupId/p/:postId/:commentId"
                    />
                    <Route
                        component={CommentDetails}
                        exact
                        path="/community/:groupId/p/:postId/:commentId/:replyId"
                    />
                    <Route
                        component={CommentDetails}
                        exact
                        path="/c/:groupId/p/:postId/:commentId/:replyId"
                    />
                    {/*<Route*/}
                    {/*    component={Calendar}*/}
                    {/*    exact*/}
                    {/*    path="/community/:groupId/calendar"*/}
                    {/*/>*/}
                    <Route
                        component={Calendar}
                        exact
                        path="/c/:groupId/calendar"
                    />
                    {/*<Route*/}
                    {/*    component={GroupMembers}*/}
                    {/*    exact*/}
                    {/*    path="/community/:groupId/members"*/}
                    {/*/>*/}
                    <Route
                        component={GroupMembers}
                        exact
                        path="/c/:groupId/members"
                    />
                    {/*<Route*/}
                    {/*    component={GroupsEvents}*/}
                    {/*    exact*/}
                    {/*    path="/community/:groupId/events"*/}
                    {/*/>*/}
                    <Route
                        component={GroupsEvents}
                        exact
                        path="/c/:groupId/events"
                    />
                    <Route
                        component={EventsDetails}
                        exact
                        path="/community/:groupId/events/:eventId"
                    />
                    <Route
                        component={CommentDetails}
                        exact
                        path="/community/:groupId/events/:eventId/:commentId"
                    />
                    <Route
                        component={CommentDetails}
                        exact
                        path="/community/:groupId/events/:eventId/:commentId/:replyId"
                    />
                    {/*<Route*/}
                    {/*    component={EventsDetails}*/}
                    {/*    exact*/}
                    {/*    path="/community/:groupId/event/:eventId"*/}
                    {/*/>*/}
                    <Route
                        component={EventsDetails}
                        exact
                        path="/c/:groupId/events/:eventId"
                    />
                    <Route
                        component={EventsDetails}
                        exact
                        path="/c/:groupId/events/:eventId/ticket/:ticketId/data/:fieldValues"
                    />
                    <Route
                        component={EventsDetails}
                        exact
                        path="/c/:groupId/events/:eventId/ticket/:ticketId"
                    />
                    <Route
                        component={EventsDashboard}
                        exact
                        path="/c/:groupId/events/:eventId/manage/dashboard"
                    />
                    {/*<Route*/}
                    {/*    component={AddEvent}*/}
                    {/*    exact*/}
                    {/*    path="/community/:groupId/events/:eventId/:type"*/}
                    {/*/>*/}
                    <Route
                        component={AddEvent}
                        exact
                        path="/c/:groupId/events/:eventId/:type"
                    />
                    {/*<Route*/}
                    {/*    component={AddEvent}*/}
                    {/*    exact*/}
                    {/*    path="/community/:groupId/addEvent"*/}
                    {/*/>*/}
                    <Route
                        component={AddEvent}
                        exact
                        path="/c/:groupId/addEvent"
                    />
                    <Route
                        component={DirectJoin}
                        exact
                        path="/_admin/community/:groupId/:type"
                    />
                    <Route
                        component={DirectJoin}
                        exact
                        path="/_admin/c/:groupId/:type"
                    />
                    <Route
                        component={DirectAdmin}
                        exact
                        path="/_admin/community/:groupId/type/:type/:token"
                    />
                    <Route
                        component={DirectAdmin}
                        exact
                        path="/_admin/c/:groupId/type/:type/:token"
                    />
                    <Route
                        component={Channels}
                        exact
                        path="/channels"
                    />
                    <Route
                        component={Tickets}
                        exact
                        path="/tickets"
                    />
                    <Route
                        component={TicketPaymentDetails}
                        exact
                        path="/tickets/:ticketSourceId/:ticketTypeId/:ticketId"
                    />
                    <Route
                        component={TicketPaymentsByTicketTypeId}
                        exact
                        path="/tickets/:ticketSourceId/:ticketTypeId/ct/:ticketCount"
                    />
                    <Route
                        component={TicketPaymentsByTicketTimeStamp}
                        exact
                        path="/tickets/:ticketSourceId/:ticketTypeId/ts/:purchaseTime"
                    />
                    <Route
                        component={TicketPaymentsByPaymentId}
                        exact
                        path="/tickets/:ticketSourceId/:ticketTypeId/pid/:paymentID"
                    />
                    <Route
                        component={Events}
                        exact
                        path="/events"
                    />
                    <Route
                        component={Messages}
                        exact
                        path="/messages"
                    />
                    <Route
                        component={Profile}
                        exact
                        path="/profile"
                    />
                    <Route
                        component={Settings}
                        exact
                        path="/settings"
                    />
                    <Route
                        component={NotFound}
                        exact
                        path="/not-found"
                    />
                    <Route
                        component={Login}
                        exact
                        path="/login"
                    />
                    <Route
                        component={QuickViewImages}
                        exact
                        path="/quickView/:groupId/:catName"
                    />
                    <Redirect to="/not-found"/>
                </Switch>
            </div>
        )
    }
}
