import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
// Material components
import {Grid, Typography, withStyles} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {pageViewAnalyst} from "../../configurations/config";

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        marginTop: '150px',
        textAlign: 'center'
    },
    image: {
        display: 'inline-block',
        marginTop: '50px',
        maxWidth: '100%',
        width: '554px'
    }
});

class NotFound extends Component {
    componentDidMount() {
        pageViewAnalyst('Not Found', this.props.location.pathname);
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Grid
                    container
                    justify="center"
                    spacing={4}
                >
                    <Grid
                        item
                        lg={6}
                        xs={12}
                    >
                        <div className={classes.content}>
                            <Typography variant="h4">
                                404: The page you are looking for isn’t here
                            </Typography>
                            <br/>
                            <br/>
                            <Link
                                to={`/home`}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    className={classes.button}
                                >
                                    Go Home
                                </Button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

NotFound.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NotFound);
