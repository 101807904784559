import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {Grid, Typography, withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styles
import styles from './styles';
import Paper from '@material-ui/core/Paper';
import {isLogin} from "../../configurations/global-config";
import {pageViewAnalyst} from "../../configurations/config";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import {GetUserTicketRequest} from "../../generated/frontend-community-ticket-service_pb";
import {getUserBoughtTickets} from "../../grpcRequests/tickets-request";
import PleaseWait from "../../components/PleaseWait";
import CircularProgress from "@material-ui/core/CircularProgress";
import {timeEvent} from "../../utils/converter/dateConverter";
import {Link} from "react-router-dom";
import usedString, {getCountryCurrencySymbol, getUserTicketStatus} from "../../utils/mapper/enumToStringMapper";
import CircleIcon from "@material-ui/icons/CheckCircle";

const defaultPageSize = 50;

class Tickets extends Component {

    state = {
        userTickets: [],
        isLoadingUserTickets: false,
        userTicketsCursor: '',
        userTicketsPreviousCursor: '',
        userTicketsLoadingMore: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        pageViewAnalyst('Tickets', this.props.location.pathname);
        // checkIfLogin(this.props.location.pathname);
        if (isLogin())
            this.fetchUserTickets("", defaultPageSize, [], "");
    }

    fetchUserTickets = (cursorValue, pageSize, previousTickets, prevCursor) => {
        let self = this;
        this.setState({
            isLoadingUserTickets: true
        });
        const req = new GetUserTicketRequest();
        req.setCursor(cursorValue);
        req.setPageSize(pageSize);
        getUserBoughtTickets(req, 3).then(function (response) {
            const userTicketsLoadingMore = false;
            const userTicketsPreviousCursor = prevCursor;
            const userTicketsCursor = response.getNextPageCursor();
            let userTickets = previousTickets;

            for (let i = 0; i < response.getTicketsList().length; i++) {
                let newTicket = response.getTicketsList()[i];
                userTickets.push(newTicket);
            }
            const isLoadingUserTickets = false;
            self.setState({
                userTickets, isLoadingUserTickets, userTicketsCursor, userTicketsLoadingMore, userTicketsPreviousCursor
            });
        }).catch(function (error) {
            const isLoadingUserTickets = false;
            self.setState({
                isLoadingUserTickets, userTicketsLoadingMore: false
            });
        })
    };

    loadMoreMember = (cursorValue, pageSize, previousTickets, prevCursor) => {
        this.setState({userTicketsLoadingMore: true});
        this.fetchUserTickets(cursorValue, pageSize, previousTickets, prevCursor)
    };

    goToTicketDetail = (sourceId, ticketTypeId, ticketId) => {
        const {history} = this.props;
        history.push('/tickets/' + sourceId + '/' + ticketTypeId + '/' + ticketId);
    };

    render() {
        const {classes} = this.props;
        const {userTickets, isLoadingUserTickets, userTicketsCursor, userTicketsLoadingMore, userTicketsPreviousCursor} = this.state;
        return (
            <DashboardLayout title="Tickets" onBack='/home'>
                <div className={classes.root}>
                    {!isLoadingUserTickets ? (
                        <div>
                            {userTickets.length > 0 ? (
                                <div>
                                    {userTickets.map(boughtTicket => (
                                        <div style={{margin: '25px 0'}}>
                                            <Typography
                                                variant="subtitle1"
                                                style={{fontWeight: 600, color: '#686868'}}
                                            >{timeEvent(new Date(boughtTicket.getPurchasedTimestamp()))}
                                            </Typography>
                                            <Paper className={classes.paperRoot}>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                >
                                                    <Grid item xs={2} md={1} sm={1} lg={1}
                                                          style={{borderRight: '1px solid #e5e5e5'}}>
                                                        <Avatar alt='ticket Name'
                                                                style={{
                                                                    backgroundImage: `url('/images/icons/tickets.png')`,
                                                                    backgroundRepeat: 'round',
                                                                    backgroundColor: 'unset',
                                                                    margin: 'auto',
                                                                    top: '5px',
                                                                    width: '30px',
                                                                    height: '30px',
                                                                }}/>
                                                    </Grid>
                                                    <Grid item xs={10} md={11} sm={11} lg={11}
                                                          style={{paddingLeft: '16px'}}>
                                                        <Typography
                                                            variant="div"
                                                            style={{fontWeight: 600, display: 'inline-flex'}}
                                                        >
                                                            {boughtTicket.getAmount() === 0 ? (
                                                                <span>{usedString.FREE} {boughtTicket.getTicketName()}</span>) : (
                                                                <span>{boughtTicket.getTicketName()} at {boughtTicket.getCurrency()} {getCountryCurrencySymbol(boughtTicket.getCurrency())}{boughtTicket.getAmount()}</span>)}
                                                            &nbsp;{getUserTicketStatus(boughtTicket.getStatus()) === usedString.USED ? (
                                                            <span title="This ticket has been used"> <CircleIcon
                                                                fontSize='small' style={{color: '#458c48'}}/></span>
                                                        ) : null}
                                                        </Typography>
                                                        <Typography component="div" style={{
                                                            fontSize: '15px',
                                                            fontWeight: 600,
                                                            lineHeight: '1.7',
                                                            color: '#686868'
                                                        }}>
                                                            <Link className={classes.hover}
                                                                  to={`/c/${boughtTicket.getGroupId()}/events/${boughtTicket.getEventId()}`}>
                                                                {boughtTicket.getEventMetadataMap().get('event_name')}
                                                            </Link>
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.timeUUID}
                                                        >{new Date(boughtTicket.getPurchasedTimestamp()).toLocaleString()}
                                                        </Typography>
                                                        <br/>
                                                        <Button variant="contained" color="secondary" size="small"
                                                                onClick={() => this.goToTicketDetail(boughtTicket.getEventId(), boughtTicket.getTicketTypeId(), boughtTicket.getTicketId())}>
                                                            View
                                                        </Button>&nbsp;&nbsp;&nbsp;
                                                        {/*<Button color="secondary" size="small">*/}
                                                        {/*    download*/}
                                                        {/*</Button>*/}
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </div>
                                    ))}
                                </div>) : (
                                <div>
                                    <div className={classes.noMemberWrapper}>You don’t have any ticket right now. When
                                        you buy tickets from events, it will be shown here.
                                    </div>
                                </div>)}
                            {userTicketsCursor && userTicketsPreviousCursor !== userTicketsCursor ? (
                                <div>
                                    <Button variant="outlined" size="large" fullWidth
                                            style={{margin: '15px 0'}}
                                            onClick={() => this.loadMoreMember(userTicketsCursor, defaultPageSize, userTickets, userTicketsCursor)}>
                                        Load More
                                    </Button>
                                    {userTicketsLoadingMore ? (<CircularProgress size={20}/>) : (
                                        <span></span>)}
                                </div>
                            ) : (<span></span>)}
                        </div>
                    ) : <PleaseWait msg={"Loading your tickets... "}/>}
                </div>
            </DashboardLayout>
        );
    }
}

Tickets.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Tickets);
