import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styles
import styles from './styles';
import {checkIfLogin} from "../../configurations/global-config";
import {pageViewAnalyst} from "../../configurations/config";
import {UserTicketsForTransactionRequest} from "../../generated/frontend-community-ticket-service_pb";
import PleaseWait from "../../components/PleaseWait";
import {getUserTicketsForTime} from "../../grpcRequests/tickets-request";
import PaymentDetailsLists from "../../components/Tickets/PaymentDetailsLists";

var QRCode = require('qrcode.react');

class TicketPaymentByTime extends Component {

    state = {
        sourceId: this.props.match.params.ticketSourceId,
        ticketTypeId: this.props.match.params.ticketTypeId,
        purchaseTime: this.props.match.params.purchaseTime,
        loading: true,
        gotTicketInfo: false,
        ticketInfo: [],
        buyerInfo: '',
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        pageViewAnalyst('Ticket Details By Time', this.props.location.pathname);
        checkIfLogin(this.props.location.pathname);
        this.getTicketInformation(this.props.match.params.ticketSourceId, this.props.match.params.ticketTypeId, this.props.match.params.purchaseTime);
    }

    getTicketInformation = (sourceId, typeId, time) => {
        let self = this;
        let typeIds = [];
        typeIds.push(typeId);
        const req = new UserTicketsForTransactionRequest();
        req.setTicketTypeIdList(typeIds);
        req.setCursor('');
        req.setPageSize(100);
        req.setPurchaseTime(Number(time));
        getUserTicketsForTime(req, 3).then(function (response) {
            const ticketInfo = response.getUserTicketList();
            const buyerInfo = response.getBuyerInfoMap();
            const gotTicketInfo = true;
            const loading = false;
            self.setState({
                buyerInfo, ticketInfo, gotTicketInfo, loading
            });
        }).catch(function (error) {
            const loading = false;
            self.setState({
                loading
            });
        });
    };

    render() {
        const {classes} = this.props;
        const {ticketInfo, gotTicketInfo, loading, buyerInfo} = this.state;

        return (
            <DashboardLayout title="Ticket Payment Details" onBack='/tickets' linkTo='/tickets'>
                <div className={classes.root}>
                    {!loading ? (
                            <div>
                                {gotTicketInfo ? (
                                    <PaymentDetailsLists ticketInfo={ticketInfo} buyerInfo={buyerInfo}/>
                                ) : 'Sorry we Could not get ticket details now.'}
                            </div>
                        ) :
                        (<PleaseWait msg="Loading ticket details... "/>)}
                </div>
            </DashboardLayout>
        );
    }
}

TicketPaymentByTime.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TicketPaymentByTime);
