import globalConfig from "../configurations/configVariables";

import {CommunityServiceClient} from "../generated/frontend-community-group-service_grpc_web_pb";
import {MembersServiceClient} from "../generated/frontend-community-members-service_grpc_web_pb";
import {PostServiceClient} from "../generated/frontend-post-service_grpc_web_pb";
import {ReplyServiceClient} from "../generated/frontend-reply-service_grpc_web_pb";
import {CommentServiceClient} from "../generated/frontend-comment-service_grpc_web_pb";
import {ReactionServiceClient} from "../generated/frontend-reaction-service_grpc_web_pb";
import {CommunityEventServiceClient} from "../generated/frontend-community-event-service_grpc_web_pb";
import {ContentToUserReactionInfoServiceClient} from "../generated/frontend-user-reaction-to-content-service_grpc_web_pb";
import {BusinessAccountServiceClient} from "../generated/frontend-business-account-service_grpc_web_pb";
import {UserActivityServiceClient} from "../generated/frontend-user-activity-service_grpc_web_pb";
import {CommunityTicketServiceClient} from "../generated/frontend-community-ticket-service_grpc_web_pb";
import {CommunityTicketPaymentServiceClient} from "../generated/frontend-community-ticket-payment-service_grpc_web_pb";
import {EverestDbServiceClient} from "../generated/frontend-everestdb-service_grpc_web_pb";
import {AdminManualPushServiceClient} from "../generated/frontend-admin-notification-push-service_grpc_web_pb";

const communityServiceClientServer = new CommunityServiceClient(globalConfig.clientUrl);
const communityMemberServiceClientServer = new MembersServiceClient(globalConfig.clientUrl);
const postServiceClientServer = new PostServiceClient(globalConfig.clientUrl);
const replyServiceClientServer = new ReplyServiceClient(globalConfig.clientUrl);
const commentServiceClientServer = new CommentServiceClient(globalConfig.clientUrl);
const reactionServiceClientServer = new ReactionServiceClient(globalConfig.clientUrl);
const eventServiceClientServer = new CommunityEventServiceClient(globalConfig.clientUrl);
const contentToUserReactionInfoServiceClientServer = new ContentToUserReactionInfoServiceClient(globalConfig.clientUrl);
const businessGroupsServiceClientServer = new BusinessAccountServiceClient(globalConfig.clientUrl);
const userActivityServiceClientServer = new UserActivityServiceClient(globalConfig.clientUrl);
const communityTicketServiceClientServer = new CommunityTicketServiceClient(globalConfig.clientUrl);
const communityTicketPaymentServiceClientServer = new CommunityTicketPaymentServiceClient(globalConfig.clientUrl);
const everestDBServiceClientServer = new EverestDbServiceClient(globalConfig.clientUrl);
const adminManualPushClientServer = new AdminManualPushServiceClient(globalConfig.clientUrl);


export {communityServiceClientServer as CommunityServiceServer};
export {communityMemberServiceClientServer as CommunityMemberServiceServer};
export {postServiceClientServer as PostServiceServer};
export {replyServiceClientServer as ReplyServiceServer};
export {commentServiceClientServer as CommentServiceServer};
export {reactionServiceClientServer as ReactionServiceServer};
export {eventServiceClientServer as EventServiceServer};
export {contentToUserReactionInfoServiceClientServer as UserContentReactionServiceServer};
export {businessGroupsServiceClientServer as BusinessGroupsServiceServer};
export {userActivityServiceClientServer as UserActivityServiceServer};
export {communityTicketServiceClientServer as CommunityTicketServiceServer};
export {communityTicketPaymentServiceClientServer as CommunityTicketPaymentServiceServer};
export {everestDBServiceClientServer as EverestDBServiceServer};
export {adminManualPushClientServer as AdminManualPushServiceServer};