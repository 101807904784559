export default theme => ({
    root: {
        padding: theme.spacing(1),
        minHeight: '85vh'
    },
    textPost: {
        marginTop: '5px',
        color: '#000000',
        fontSize: '17px',
        lineHeight: 1.4,
    },
    timeUUID: {
        color: '#757575',
        fontSize: '12px',
    },
    postedText: {
        margin: '10px 0 0',
        lineHeight: 1.4,
        fontSize: '16px',
        color: '#292828'
    },
    msgImageWrapper: {
        overflow: 'hidden',
        width: 'auto',
        marginTop: '10px'
    },
    msgImage: {
        width: '100%',
        height: 'auto'
    },
    userAction: {
        margin: '5px 0',
        float: 'left',
    },
    chatBox: {
        background: '#ffffff',
        margin: 'auto 5px',
        borderRadius: '50px',
        boxShadow: 'none'
    },
    chatBoxAvatar: {
        height: 30,
        width: 30,
    },
    commentWrapper: {
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    commentClass: {
        color: '#0077e0',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    listCover: {
        background: '#f4f6f7',
        padding: '10px 10px 15px',
        borderRadius: '5px'
    },
    listCoverComment: {
        background: '#f4f6f7',
        padding: '2px 5px',
        borderRadius: '5px'
    },
    commentPost: {
        color: '#000000',
        fontSize: '15px',
        lineHeight: 1.5,
        marginTop: '5px'
    },
    iconWrapper: {
        padding: '10px 5px',
        width: '120px'
    },
    postDivider: {
        padding: '1px 0',
        background: '#f4f6f7',
        margin: '0 -10px'
    },
    likedUser: {
        float: 'right',
        margin: '5px 0',
        padding: '12px 5px',
        display: 'flex'
    },
    likedUnOrder: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
    },
    likedList: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        margin: '0 2px',
        border: '1px solid #ffffff',
        display: 'inline-block',
        position: 'relative',
        '&:nth-child(n+2)': {
            marginLeft: '-7px'
        }
    },
    likedUserImg: {
        cursor: 'pointer',
        width: '20px',
        height: '20px',
    },
    morePeople: {
        fontSize: '8px',
    },
    clearBoth: {
        clear: 'both'
    }
});
