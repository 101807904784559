import React, {Component} from "react";
import PropTypes from "prop-types";
import {Grid, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {formatDate} from "../../utils/converter/dateConverter";
import styles from "./styles";
import CircleIcon from '@material-ui/icons/CheckCircle';
import usedString, {getCountryCurrencySymbol, getUserTicketStatus} from "../../utils/mapper/enumToStringMapper";
import {b64Encode} from "../../utils/converter/base64";

var QRCode = require('qrcode.react');

class PaymentDetailsLists extends Component {
    render() {
        const {ticketInfo, buyerInfo, classes} = this.props;
        return (
            <div>
                {ticketInfo.map((ticket, index) => (
                    <div>
                        <div style={{
                            margin: '15px 0',
                            padding: '15px 0',
                            boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
                            borderRadius: '5px'
                        }}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid item xs={12} sm={12} style={{padding: '0 10px'}}>
                                    <Typography component="div" style={{
                                        fontSize: '15px',
                                        color: '#7b798d',
                                        padding: '10px 16px',
                                        fontWeight: 900,
                                        borderBottom: '1px solid #e5e5e5'
                                    }}>
                                        {index + 1}. Ticket Details
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <div style={{padding: '0 16px'}}>
                                        <Typography component="div"
                                                    style={{fontSize: '17px', fontWeight: 600, lineHeight: '1.7'}}>
                                            <Link className={classes.hover}
                                                  to={`/c/${ticket.getGroupId()}/events/${ticket.getEventId()}`}>
                                                {ticket.getEventMetadataMap().get('event_name')}
                                            </Link>
                                        </Typography>
                                        <Typography component="div"
                                                    style={{fontSize: '14px', color: '#7b798d'}}>
                                            {ticket.getEventMetadataMap().get('event_location')}
                                        </Typography>
                                        <Typography component="div"
                                                    style={{fontSize: '13px', color: '#7b798d'}}>
                                            {formatDate(Number(ticket.getEventMetadataMap().get('event_startTime')), 'MMMM dd, yyyy p')}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div style={{padding: '0 16px', textAlign: 'center'}}>
                                        <QRCode
                                            id="123456"
                                            value={b64Encode(ticket.getEventId() + ':' + ticket.getTicketTypeId() + ':' + ticket.getTicketId() + ':' + ticket.getUserId())}
                                            size={130}
                                            level={"L"}
                                            renderAs={'svg'}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} style={{padding: '0 0 10px'}}>
                                    <div style={{padding: '0 16px', textAlign: 'center'}}>
                                        <Typography component="div"
                                                    style={{fontSize: '13px', color: '#7b798d'}}>
                                            Ticket Summary
                                        </Typography>
                                        <Typography component="div"
                                                    style={{fontSize: '17px', fontWeight: 600, lineHeight: '1.1'}}>
                                            Ticket No. #{ticket.getTicketId()}&nbsp;
                                            {getUserTicketStatus(ticket.getStatus()) === usedString.USED ? (
                                                <span title="This ticket has been used"> <CircleIcon fontSize='small'
                                                                                                     style={{color: '#458c48'}}/></span>
                                            ) : null}
                                        </Typography>
                                        <Typography component="div"
                                                    style={{fontSize: '13px', color: '#7b798d'}}>
                                            {formatDate(ticket.getPurchasedTimestamp(), 'MMMM dd, yyyy p')}
                                        </Typography>
                                        {ticket.getAmount() === 0 ? null : (
                                            <Typography component="div" style={{fontSize: '12px', color: '#7b798d'}}>
                                                Payment Mode : Credit Card
                                            </Typography>)}
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div style={{padding: '0 16px'}}>
                                        <Typography component="div"
                                                    style={{fontSize: '13px', color: '#7b798d'}}>
                                            Ticket Name
                                        </Typography>
                                        <Typography component="div"
                                                    style={{fontSize: '15px', fontWeight: 600}}>
                                            {ticket.getTicketName()}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <div style={{padding: '0 16px'}}>
                                        <Typography component="div"
                                                    style={{fontSize: '13px', color: '#7b798d'}}>
                                            Quantity
                                        </Typography>
                                        <Typography component="div"
                                                    style={{fontSize: '15px', fontWeight: 600}}>
                                            1
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <div style={{padding: '0 16px'}}>
                                        <Typography component="div"
                                                    style={{fontSize: '13px', color: '#7b798d'}}>
                                            Amount
                                        </Typography>
                                        <Typography component="div"
                                                    style={{fontSize: '15px', fontWeight: 600}}>
                                            {ticket.getAmount() === 0 ? usedString.FREE : (
                                                <span>{ticket.getCurrency()} {getCountryCurrencySymbol(ticket.getCurrency())}{ticket.getAmount()}</span>)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <div style={{padding: '0 16px'}}>
                                        <Typography component="div"
                                                    style={{fontSize: '13px', color: '#7b798d'}}>
                                            Proceed By
                                        </Typography>
                                        <Typography component="div"
                                                    style={{fontSize: '15px', fontWeight: 600}}>
                                            {buyerInfo.get('name')}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <div style={{padding: '0 16px'}}>
                                        <Typography component="div"
                                                    style={{fontSize: '13px', color: '#7b798d'}}>
                                            Email Address
                                        </Typography>
                                        <Typography component="div"
                                                    style={{fontSize: '15px', fontWeight: 600}}>
                                            {buyerInfo.get('email') ? buyerInfo.get('email') : '-'}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div style={{padding: '0 16px'}}>
                                        <Typography component="div"
                                                    style={{fontSize: '13px', color: '#7b798d'}}>
                                            Phone Number
                                        </Typography>
                                        <Typography component="div"
                                                    style={{fontSize: '15px', fontWeight: 600}}>
                                            {buyerInfo.get('phone') ? buyerInfo.get('phone') : '-'}
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <br/>
                        <hr color="#e5e5e5"/>
                        <br/>
                    </div>
                ))}
            </div>
        )
    }
}

PaymentDetailsLists.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PaymentDetailsLists);

