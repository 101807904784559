import React, {Component} from "react";
import PropTypes from "prop-types";
import {Grid, ListItemIcon, Typography, withStyles} from "@material-ui/core";
import styles from "../styles";
import ListItem from "@material-ui/core/ListItem";
import {EventNote as HistoryIcon, Lock as LockIcon, Public as PublicIcon} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import usedString, {getRoleString} from "../../../../utils/mapper/enumToStringMapper";
import {formatDate} from "../../../../utils/converter/dateConverter";
import RenderText from "../../component/RenderText";

class About extends Component {
    state = {
        group: this.props.group,
        history: this.props.history
    };
    goToMembers = (groupId) => {
        const {history} = this.state.history;
        history.push('/c/' + groupId + '/members');
    };

    render() {
        const {classes, groupType} = this.props;
        const {group} = this.state;
        return (
            <div className={classes.aboutUsWrapper}>
                <div>
                    <Typography
                        className={classes.mainText}
                        variant="h6"
                        style={{fontWeight: 600}}
                    > About
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        style={{whiteSpace: 'pre-line'}}
                    >
                        <RenderText text={group.getDesc()}/>
                    </Typography>
                </div>
                <div className={classes.groupInfoWrapper}>
                    <Grid
                        container
                        spacing={0}
                    >
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                            <ListItem alignItems="flex-start">
                                {groupType === 'Private' ? (
                                        <ListItemIcon className={classes.publicListItemIcon}>
                                            <LockIcon/>
                                        </ListItemIcon>
                                    ) :
                                    <ListItemIcon className={classes.publicListItemIcon}>
                                        <PublicIcon/>
                                    </ListItemIcon>
                                }
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="h6"
                                                style={{fontWeight: 600}}
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {groupType} Community
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                className={classes.textPublic}
                                                color="textPrimary"
                                            >
                                                {groupType === 'Public' ? 'Open community, anyone can view the contents in this community.' : 'Closed community, only community member can view the contents in this community.'}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                            <ListItem alignItems="flex-start">
                                <ListItemIcon className={classes.publicListItemIcon}>
                                    <HistoryIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="h6"
                                                style={{fontWeight: 600}}
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                History
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.textPublic}
                                                color="textPrimary"
                                            >
                                                Created On
                                            </Typography>
                                            <p>{formatDate(group.getCreated(), 'MMMM dd, yyyy p')}</p>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Typography
                        className={classes.mainText}
                        variant="h6"
                        style={{fontWeight: 600}}
                    > Location
                    </Typography>
                    <Typography
                        variant="subtitle1"
                    > {group.getAddress()} {group.getCountry()}
                    </Typography>
                    {/*{(group.getLocation().getLat() && group.getLocation().getLon()) ? (*/}
                    {/*    <div>*/}
                    {/*        <a href={`https://www.google.com/maps/search/?api=1&query=${group.getLocation().getLat()},${group.getLocation().getLon()}`}*/}
                    {/*           target="_blank">*/}
                    {/*            <img className={classes.mapImage}*/}
                    {/*                 src={`https://maps.googleapis.com/maps/api/staticmap?center=${group.getLocation().getLat()},${group.getLocation().getLon()}&size=2048x250&scale=4&zoom=15&key=AIzaSyBbWutco8YMhBuGTTexubtCHs34ZEFl490&markers=color:blue%7Clabel:%7C${group.getLocation().getLat()},${group.getLocation().getLon()}`}*/}
                    {/*                 alt=""/>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*) : null}*/}
                </div>
                <div className={classes.titleTopBar}>
                    {(getRoleString(group.getRole()) === usedString.ADMIN || getRoleString(group.getRole()) === usedString.PARTICIPANT) ? (
                        <div>
                            <div className={classes.floatLeft}>
                                <Typography
                                    variant="h6"
                                    style={{fontWeight: 600}}
                                >
                                    Admin / Moderators
                                </Typography>
                            </div>
                            <div className={classes.floatRight}>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    onClick={() => this.goToMembers(group.getGroupid())}
                                    className={classes.buttonView}
                                >
                                    View All
                                </Typography>
                            </div>

                            <Grid container className={classes.avatarWrapper}>
                                {group.getMembersList().map(member => (
                                    <Avatar alt={member.getName()}
                                            style={{
                                                backgroundImage: `url('${member.getImageurl()}')`,
                                                backgroundRepeat: 'round'
                                            }}
                                            title={member.getName()}
                                            className={classes.avatar}/>
                                ))}
                            </Grid>
                        </div>) : (<div></div>)}
                </div>
            </div>
        )
    }
}

About.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(About);