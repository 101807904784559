/**
 * @fileoverview gRPC-Web generated client stub for com.hamropatro.sociallayer.io
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');


var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
const proto = {};
proto.com = {};
proto.com.hamropatro = {};
proto.com.hamropatro.sociallayer = {};
proto.com.hamropatro.sociallayer.io = require('./frontend-comment-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.CommentServiceClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.CommentServicePromiseClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.CommentOnContentRequest,
 *   !proto.com.hamropatro.sociallayer.io.CommentOnContentResponse>}
 */
const methodDescriptor_CommentService_CommentOnContent = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommentService/CommentOnContent',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.CommentOnContentRequest,
    proto.com.hamropatro.sociallayer.io.CommentOnContentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.CommentOnContentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.CommentOnContentRequest,
 *   !proto.com.hamropatro.sociallayer.io.CommentOnContentResponse>}
 */
const methodInfo_CommentService_CommentOnContent = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.CommentOnContentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.CommentOnContentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.CommentOnContentResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.CommentOnContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.CommentOnContentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.CommentOnContentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommentServiceClient.prototype.commentOnContent =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommentService/CommentOnContent',
            request,
            metadata || {},
            methodDescriptor_CommentService_CommentOnContent,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.CommentOnContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.CommentOnContentResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommentServicePromiseClient.prototype.commentOnContent =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommentService/CommentOnContent',
            request,
            metadata || {},
            methodDescriptor_CommentService_CommentOnContent);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest,
 *   !proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse>}
 */
const methodDescriptor_CommentService_ReactOnComment = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommentService/ReactOnComment',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest,
    proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest,
 *   !proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse>}
 */
const methodInfo_CommentService_ReactOnComment = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommentServiceClient.prototype.reactOnComment =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommentService/ReactOnComment',
            request,
            metadata || {},
            methodDescriptor_CommentService_ReactOnComment,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.ReactOnCommentResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommentServicePromiseClient.prototype.reactOnComment =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommentService/ReactOnComment',
            request,
            metadata || {},
            methodDescriptor_CommentService_ReactOnComment);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.DeleteCommentRequest,
 *   !proto.com.hamropatro.sociallayer.io.DeleteCommentResponse>}
 */
const methodDescriptor_CommentService_DeleteComment = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommentService/DeleteComment',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.DeleteCommentRequest,
    proto.com.hamropatro.sociallayer.io.DeleteCommentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.DeleteCommentRequest,
 *   !proto.com.hamropatro.sociallayer.io.DeleteCommentResponse>}
 */
const methodInfo_CommentService_DeleteComment = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.DeleteCommentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.DeleteCommentResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.DeleteCommentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.DeleteCommentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommentServiceClient.prototype.deleteComment =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommentService/DeleteComment',
            request,
            metadata || {},
            methodDescriptor_CommentService_DeleteComment,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.DeleteCommentResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommentServicePromiseClient.prototype.deleteComment =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommentService/DeleteComment',
            request,
            metadata || {},
            methodDescriptor_CommentService_DeleteComment);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.ListCommentsRequest,
 *   !proto.com.hamropatro.sociallayer.io.ListCommentsResponse>}
 */
const methodDescriptor_CommentService_ListComments = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommentService/ListComments',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.ListCommentsRequest,
    proto.com.hamropatro.sociallayer.io.ListCommentsResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ListCommentsRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ListCommentsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.ListCommentsRequest,
 *   !proto.com.hamropatro.sociallayer.io.ListCommentsResponse>}
 */
const methodInfo_CommentService_ListComments = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.ListCommentsResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.ListCommentsRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.ListCommentsResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ListCommentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.ListCommentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.ListCommentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommentServiceClient.prototype.listComments =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommentService/ListComments',
            request,
            metadata || {},
            methodDescriptor_CommentService_ListComments,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ListCommentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.ListCommentsResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommentServicePromiseClient.prototype.listComments =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommentService/ListComments',
            request,
            metadata || {},
            methodDescriptor_CommentService_ListComments);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.SpamCommentRequest,
 *   !proto.com.hamropatro.sociallayer.io.SpamCommentResponse>}
 */
const methodDescriptor_CommentService_SpamComment = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommentService/SpamComment',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.SpamCommentRequest,
    proto.com.hamropatro.sociallayer.io.SpamCommentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.SpamCommentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.SpamCommentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.SpamCommentRequest,
 *   !proto.com.hamropatro.sociallayer.io.SpamCommentResponse>}
 */
const methodInfo_CommentService_SpamComment = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.SpamCommentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.SpamCommentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.SpamCommentResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.SpamCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.SpamCommentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.SpamCommentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommentServiceClient.prototype.spamComment =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommentService/SpamComment',
            request,
            metadata || {},
            methodDescriptor_CommentService_SpamComment,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.SpamCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.SpamCommentResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommentServicePromiseClient.prototype.spamComment =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommentService/SpamComment',
            request,
            metadata || {},
            methodDescriptor_CommentService_SpamComment);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetCommentRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetCommentResponse>}
 */
const methodDescriptor_CommentService_GetComment = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommentService/GetComment',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetCommentRequest,
    proto.com.hamropatro.sociallayer.io.GetCommentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetCommentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetCommentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetCommentRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetCommentResponse>}
 */
const methodInfo_CommentService_GetComment = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetCommentResponse,
    /** @param {!proto.com.hamropatro.sociallayer.io.GetCommentRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetCommentResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetCommentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetCommentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommentServiceClient.prototype.getComment =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommentService/GetComment',
            request,
            metadata || {},
            methodDescriptor_CommentService_GetComment,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetCommentResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommentServicePromiseClient.prototype.getComment =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.sociallayer.io.CommentService/GetComment',
            request,
            metadata || {},
            methodDescriptor_CommentService_GetComment);
    };


module.exports = proto.com.hamropatro.sociallayer.io;

