export default theme => ({
    root: {
        backgroundColor: theme.palette.background.sideBar,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingLeft: '45%',
        paddingRight: '5%',
        borderRight: 'unset'
    },
    logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '63px',
        flexShrink: 0,
        marginBottom: '10px'
    },
    logoLink: {
        fontSize: 0
    },
    logoImage: {
        cursor: 'pointer'
    },
    logoDivider: {
        marginBottom: theme.spacing(2)
    },
    'MuiDrawer-paperAnchorDockedLeft': {
        borderRight: 'unset'
    },
    profile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    avatar: {
        width: '100px',
        height: '100px'
    },
    nameText: {
        marginTop: theme.spacing(4)
    },
    bioText: {},
    profileDivider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    listSubheader: {
        color: theme.palette.text.secondary
    },
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            // backgroundColor: theme.palette.primary.light,
            // borderLeft: `4px solid ${theme.palette.primary.main}`,
            borderRadius: '4px',
            '& $listItemText': {
                color: theme.palette.primary.main
            },
            '& $listItemIcon': {
                color: theme.palette.primary.main,
                // marginLeft: '-4px'
            }
        },
        '& + &': {
            marginTop: theme.spacing()
        }
    },
    activeListItem: {
        // borderLeft: `4px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
        // backgroundColor: theme.palette.primary.light,
        '& $listItemText': {
            color: theme.palette.primary.main
        },
        '& $listItemIcon': {
            color: theme.palette.primary.main,
            // marginLeft: '-4px'
        }
    },
    listItemIcon: {
        marginRight: 0
    },
    listItemText: {
        paddingLeft: '6px',
        fontSize: '19px',
        fontWeight: 600,
        color: theme.palette.text.main
    },
    listDivider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    product: {
        paddingBottom: theme.spacing(),
        paddingTop: theme.spacing(),
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '10px',
        paddingRight: '16px',
        paddingLeft: '16px',
    },
    productImageWrapper: {
        overflow: 'hidden',
        height: '40px',
        width: '40px'
    },
    productImage: {
        width: '100%',
        height: 'auto'
    },
    productDetails: {
        marginLeft: theme.spacing(2),
        flexGrow: 1
    },
    productTitle: {
        fontWeight: 600,
    },
    menuWrapper: {
        marginTop: '25px',
        marginBottom: '15px'
    }
});
