import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/styles';
import {Dashboard as DashboardLayout} from 'layouts';
import {Avatar, Card, CardContent, Grid, Typography} from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/Money';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import RemoveRedEye from '@material-ui/icons/RemoveRedEyeOutlined';
import CalendarToday from '@material-ui/icons/CalendarToday';
import WatchLater from '@material-ui/icons/WatchLater';
import MyLocation from '@material-ui/icons/MyLocation';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Doughnut} from 'react-chartjs-2';
import {GetEventSalesReportRequest} from "../../../generated/frontend-community-ticket-service_pb";
import {getEventsDashboardResults} from "../../../grpcRequests/tickets-request";
import {formatDate} from "../../../utils/converter/dateConverter";
import CheckInDetails from "./checkInDetails";
import {checkIfLogin} from "../../../configurations/global-config";
import {GetEverestCounterRequest} from "../../../generated/frontend-everestdb-service_pb";
import {getPageCount} from "../../../grpcRequests/everestDB-request";
import {GetGroupRequest} from "../../../generated/frontend-community-group-service_pb";
import {getGroupDetails} from "../../../grpcRequests/groups-request";
import usedString, {getCountryCurrencySymbol, getRoleString} from "../../../utils/mapper/enumToStringMapper";
import PleaseWait from "../../../components/PleaseWait";
import styles from "./styles";
import {shortenString} from "../../../utils/converter/shortenString";
import {Link} from "react-router-dom";

const useStyles = makeStyles(styles);

const EventDashboard = props => {
    const width = useMediaQuery('(min-width:400px)');
    const theme = useTheme();
    const [date, setOpen] = React.useState('');
    const [time, setTime] = React.useState('');
    const [loadedStat, setLoadedStat] = React.useState(false);
    const [getData, setGetData] = React.useState(false);
    const [address, setaddress] = React.useState('');
    const [errInfo, setErrInfo] = React.useState('');
    const [eventStats, setEventStats] = useState('');
    const [data, setData] = useState('');
    const [ticketTypeList, setTicketTypeList] = useState('');
    const [totalTicketSold, setTicketSold] = useState('0');
    const [groupId, setgroupId] = React.useState(props.match.params.groupId);
    const [eventId, seteventId] = React.useState(props.match.params.eventId);
    const [totalTicketsAvailable, setTicketAvailable] = useState('');
    const [pageViewCount, setPageViewCount] = useState('');
    const [title, setTitle] = React.useState('');
    const [eventTitle, setEventTitle] = React.useState('');
    const [role, setRole] = React.useState('');
    const {className, ...rest} = props;
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
        checkIfLogin('/login');
        getUserRole();
        // getTicketInfoByTicketId();
    }, [20]);

    function getEventCounter() {
        const req = new GetEverestCounterRequest();
        req.setGroup('counters/community/events');
        req.setKey(eventId);
        getPageCount(req).then(function (response) {
            setPageViewCount(response.getCounter().getCount())
        }).catch(function (error) {
        })
    }

    function getUserRole() {
        const req = new GetGroupRequest();
        req.setGroupId(groupId);
        getGroupDetails(req, 3).then(function (response) {
            const group = response.getGroup();
            const title = group.getName();
            const userRole = getRoleString(group.getRole());
            setTitle(title);
            setRole(userRole);
            // fetch only after getting ROle
            getEventCounter();
            fetchEventDashboard();
        }).catch(function (error) {
        })
    }

    function fetchEventDashboard() {
        const req = new GetEventSalesReportRequest();
        req.setGroupId(groupId);
        req.setEventId(eventId);
        getEventsDashboardResults(req, 3).then(function (response) {
            setEventStats(response);
            setEventTitle(response.getEventName());
            setTicketTypeList(response.getTypeOverviewList());
            setLoadedStat(true);
            setGetData(true);
            var totalTicketsSold = 0;
            var ticketsAvailable = 0;
            response.getTypeOverviewList().forEach(function (ticket) {
                ticketsAvailable = parseInt(ticketsAvailable) + parseInt(ticket.getTicketAvailable());
                totalTicketsSold = parseInt(totalTicketsSold) + parseInt(ticket.getTicketSold());
                setTicketAvailable(ticketsAvailable);
                setTicketSold(totalTicketsSold);
            })
        }).catch(function (error) {
            setErrInfo(error.message);
            setLoadedStat(true);
        })

    }
    return (
        <DashboardLayout title={`${eventTitle ? shortenString(eventTitle, 25) + "'s Dashboard" : "Event Dashboard"}`}
                         onBack={`/c/${groupId}/events/${eventId}`} linkTo={`/c/${groupId}/events/${eventId}`}>
            <div className={classes.root}>
                {loadedStat === true ? (
                    <div>
                        {getData && role === usedString.ADMIN ? (
                                <div>
                                    <div className={classes.eventWrapper}>
                                        <Link className={classes.hover}
                                              to={`/c/${groupId}/events/${eventId}`}>
                                            <Typography
                                                variant="h6"
                                                component="span"
                                                style={{fontWeight: 600, padding: '5px 0'}}
                                            >{eventStats.getEventName()}</Typography>
                                        </Link>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            className={classes.eventDate}
                                        >
                                        <span style={{display: "flex"}}>
                                            <CalendarToday
                                                className={classes.dashboardIcon}/> {formatDate(eventStats.getEventStartingTime(), 'MMMM dd, yyyy')}
                                            &nbsp;&nbsp;<WatchLater
                                            className={classes.dashboardIcon}/> {formatDate(eventStats.getEventStartingTime(), 'hh:mm a ')}
                                        </span>
                                        </Typography>
                                        {eventStats.getVenue() ? (
                                            <Typography
                                                variant="h6"
                                                component="div"
                                                className={classes.eventDate}
                                            >
                                            <span style={{display: "flex"}}>
                                                <MyLocation className={classes.dashboardIcon}/>
                                                <span>{eventStats.getVenue()}</span>
                                            </span>
                                            </Typography>
                                        ) : null}
                                    </div>
                                    <br/>
                                    <Typography
                                        variant="h6"
                                        style={{fontWeight: 900, borderBottom: '1px solid #e5e5e5'}}
                                    >Event Statistics</Typography>
                                    <br/>
                                    <div>
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid item xs={12} md={4} sm={4} lg={6} xl={4}>
                                                <Card className={classes.cards}
                                                      {...rest}

                                                >
                                                    <CardContent className={classes.cardWrapper}>
                                                        <Grid
                                                            container
                                                            justify="space-between"
                                                        >
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.title}
                                                                    color="textSecondary"
                                                                    gutterBottom

                                                                >
                                                                    PAGE VIEW <br/>
                                                                </Typography>
                                                                <Typography className={classes.amount}
                                                                            variant="h6">{pageViewCount} Times<br/></Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Avatar style={{width: 25, height: 25}}
                                                                        className={classes.money}
                                                                        title="Total Page View">
                                                                    <RemoveRedEye style={{width: 15, height: 15}}
                                                                                  className={classes.icon}/>
                                                                </Avatar>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={4} sm={4} lg={6} xl={4}>
                                                <Card className={classes.cards}
                                                      {...rest}

                                                >
                                                    <CardContent className={classes.cardWrapper}>
                                                        <Grid
                                                            container
                                                            justify="space-between"
                                                        >
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.title}
                                                                    color="textSecondary"
                                                                    gutterBottom

                                                                >
                                                                    TOTAL REVENUE <br/>
                                                                </Typography>
                                                                <Typography className={classes.amount}
                                                                            variant="h6">{eventStats.getCurrency()} {getCountryCurrencySymbol(eventStats.getCurrency())}{eventStats.getGrossSalesAmount()}<br/></Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Avatar style={{width: 25, height: 25}}
                                                                        className={classes.money}
                                                                        title='Total Gross Sale'>
                                                                    <MonetizationOn style={{width: 15, height: 15}}
                                                                                    className={classes.icon}/>
                                                                </Avatar>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={4} sm={4} lg={6} xl={4}>
                                                <Card className={classes.cards}
                                                      {...rest}
                                                >
                                                    <CardContent className={classes.cardWrapper}>
                                                        <Grid
                                                            container
                                                            justify="space-between"
                                                        >
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.title}
                                                                    color="textSecondary"
                                                                    gutterBottom
                                                                >
                                                                    TICKETS SOLD</Typography>

                                                                <Typography variant="h6"
                                                                            className={classes.amount}>{totalTicketSold}</Typography>

                                                            </Grid>
                                                            <Grid item>
                                                                <Avatar style={{width: 25, height: 25}}
                                                                        className={classes.totalSold}
                                                                        title='Total Ticket Sold'>
                                                                    <MoneyIcon style={{width: 15, height: 15}}
                                                                               className={classes.icon}/>
                                                                </Avatar>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={4} sm={4} lg={6} xl={4}>
                                                <Card className={classes.cards}
                                                      {...rest}
                                                >
                                                    <CardContent className={classes.cardWrapper}>
                                                        <Grid
                                                            container
                                                            justify="space-between"
                                                        >
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.title}
                                                                    color="textSecondary"
                                                                    gutterBottom
                                                                >
                                                                    REMAINING TICKETS <br/>
                                                                </Typography>
                                                                <Typography className={classes.amount}
                                                                            variant="h6">{totalTicketsAvailable}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Avatar style={{
                                                                    backgroundImage: `url('/images/icons/tickets.png')`,
                                                                    backgroundRepeat: 'round',
                                                                    backgroundColor: 'unset',
                                                                    margin: 'auto',
                                                                    width: '25px',
                                                                    height: '25px',
                                                                }} className={classes.avatar} title='Remaining Tickets'>

                                                                </Avatar>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <br/>
                                    <Typography
                                        variant="h6"
                                        style={{fontWeight: 900, borderBottom: '1px solid #e5e5e5'}}
                                    >Chart View</Typography>
                                    {ticketTypeList ? (
                                        <div>

                                            <div style={{display: "block", float: 'center'}}>
                                                <Grid
                                                    container
                                                    spacing={3}
                                                    style={{textAlign: 'cemter', margin: 'auto'}}
                                                >
                                                    {ticketTypeList.map((singleTicket, index) => (
                                                        <Grid item xs={12} md={4} sm={4} lg={5} xl={4}
                                                              style={{textAlign: 'center'}}>
                                                            <div className={classes.chartWrapper}>

                                                                <Doughnut data={{
                                                                    datasets: [
                                                                        {
                                                                            data: [parseInt(singleTicket.getTicketSold() / singleTicket.getTotalTickets() * 100), parseInt(singleTicket.getTicketAvailable() / singleTicket.getTotalTickets() * 100)],
                                                                            backgroundColor: [
                                                                                '#43a047', '#bf350e'
                                                                            ],
                                                                            color: 'red ',
                                                                            borderWidth: 4,

                                                                        },
                                                                    ],
                                                                    labels: ['Sold', 'Unsold']

                                                                }} width={50} height={50}
                                                                          options={{maintainAspectRatio: false,tooltips: {
                                                                                  callbacks: {
                                                                                      label: function(tooltipItem, data) {
                                                                                          var dataset = data.datasets[tooltipItem.datasetIndex];
                                                                                          var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                                                          var total = meta.total;
                                                                                          var currentValue = dataset.data[tooltipItem.index];
                                                                                          var percentage = parseFloat((currentValue/total*100).toFixed(1));
                                                                                          return   percentage + '%';
                                                                                      },
                                                                                      title: function(tooltipItem, data) {
                                                                                          return data.labels[tooltipItem[0].index];
                                                                                      }
                                                                                  }
                                                                              }}}/>

                                                            </div>
                                                            <Typography>{singleTicket.getName()}</Typography>
                                                            <Typography>Sold:{singleTicket.getTicketSold()}/{singleTicket.getTotalTickets()}</Typography>
                                                        </Grid>

                                                    ))}
                                                </Grid>
                                            </div>


                                        </div>
                                    ) : null}
                                    <br/>
                                    <Typography
                                        variant="h6"
                                        style={{fontWeight: 900}}
                                    >Bought Tickets</Typography>
                                    <div style={{marginTop: 10, marginBottom: 10, textAlign: 'center'}}>
                                        <CheckInDetails eventTicketDetails={eventStats} eventId={eventId}
                                                        userRole={role}/>
                                    </div>
                                </div>) :
                            (<div>Sorry. Could not get events data</div>)
                        }
                    </div>) : (
                    <PleaseWait msg="Loading Dashboard... "/>)}
            </div>
        </DashboardLayout>
    );
};

EventDashboard.propTypes = {
    className: PropTypes.string
};

export default EventDashboard;
