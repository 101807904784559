import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {OutlinedInput, withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
import PlacesAutocomplete, {geocodeByAddress, getLatLng,} from 'react-places-autocomplete';
// Component styles
import styles from './styles';
import {
    CreateEventRequest,
    Event,
    GetEventRequest,
    Organizer,
    Sponsor,
    TicketModel,
    TicketPricing,
    UpdateEventRequest
} from "../../../generated/frontend-community-event-service_pb";
import {LocationInfo} from "../../../generated/frontend-social-common-types_pb";
import {checkIfLogin} from "../../../configurations/global-config";
import {AddCategoryRequest, GetGroupRequest} from "../../../generated/frontend-community-group-service_pb";
import {createNewCategory, getGroupDetails} from "../../../grpcRequests/groups-request";
import usedString, {
    emailFormat,
    getCountryCurrencySymbol,
    getRoleString,
    getStatusString
} from "../../../utils/mapper/enumToStringMapper";
import {formatDate} from "../../../utils/converter/dateConverter";
import {addNewEvent, getEventDetails, updateEventDetails} from "../../../grpcRequests/events-request";
import SnackBar from "../../../components/SnackBar/SnackBar";
import Uploader from "../../../components/ImageUpload/Uploader";
import CircularProgress from "@material-ui/core/CircularProgress";
import PleaseWait from "../../../components/PleaseWait";
import UTC_Suggestions from "../../../components/UTC_Suggestions/UTC_Suggestions";
import {pageViewAnalyst} from "../../../configurations/config";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
import {CloseOutlined as CloseIcon} from "@material-ui/icons";
import {uploadImageToDigitalOcean} from "../../../components/ImageUpload/upload";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddTickets from "../../../components/AddTickets";
import UserConfirmationDialog from "../../../components/UserConfirmationDialog";
import {GetTicketsForEventRequest, RemoveTicketRequest} from "../../../generated/frontend-community-ticket-service_pb";
import {getEventsTickets, removeTicket} from "../../../grpcRequests/tickets-request";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import nepaliYearList from "../../../utils/converter/neplai_date";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

var adbs = require("../../../utils/converter/adToBs.js");

const ranges = [
    {
        value: '_admin',
        label: 'Admin',
        address: ''
    },
];

let eventTime = new Date().toString().split('GMT')[1].split('(')[0].replace(')', '').trim();
let eventTimeWithCol = eventTime.substring(0, 3) + ':' + eventTime.substring(3, 5);
let eventLoc = new Date().toString().split('GMT')[1].split('(')[1].replace(')', '').trim();

let completeTimeZone = eventTimeWithCol + ' (' + eventLoc + ')';

var date = new Date();
var nepDate = adbs.ad2bs(date.getFullYear() + '/' + (parseInt(date.getMonth()) + 1) + '/' + date.getDate()).en;

class AddEvent extends Component {
    state = {
        eventCategory: '_admin',
        startTime: "00:00",
        startDateSelected: new Date(),
        endTime: "23:59",
        endDateSelected: new Date(),
        latitude: 0, longitude: 0,
        eventName: '',
        eventImage: '',
        eventDesc: '',
        address: '',
        timeZone: completeTimeZone,
        isYearlyRepeated: false,
        isPublicHoliday: false,
        isPublicEvent: false,
        addToCalendar: false,
        enableNotification: false,
        userRole: '',
        userRoleStatus: '',
        creatingEvent: false,
        isOneDayEvent: false,
        isFetchingGroups: true,
        offsets: '',
        addTimeOffset: false,
        eventTimeLoc: eventLoc,
        title: '',
        dType: 'ad',
        currentNepYear: nepDate.year,
        startYearNP: nepDate.year,
        startMonthNP: nepDate.month,
        startDayNP: nepDate.day,
        startDayListNP: 30,

        endYearNP: nepDate.year,
        endMonthNP: nepDate.month,
        endDayNP: nepDate.day,
        endDayListNP: 30,

        ticketYearNP: [],
        ticketMonthNP: [],
        ticketDayNP: [],
        ticketDayListNP: 30,
        ticketExpTime: [],

        //Ticketing
        allowedCurrency: '',
        enableTicketing: false,
        allowTicketing: false,
        freeTicket: [],
        ticketName: [],
        ticketDesc: [],
        ticketPrice: [],
        ticketExp: [],
        maxTicket: [],
        maxAllowedTicket: [],
        ticketCount: [1],
        supOrgCount: [1],
        sponsorsCount: [1],
        nextTicketCount: 2,
        nextSupOrgCount: 2,
        nextSponsorsCount: 2,
        eventHostName: '',
        errorMaxTicket: [],
        errorMessageMaxTicket: [],
        errorTicketPrice: [],
        errorMessageTicketPrice: [],
        errorMaxAllowedTicket: [],
        errorMessageMaxAllowedTicket: [],
        errGettingEvent: null,

        eventLists: [],
        openTicket: false,
        addTicket: false,
        ticketDetails: null,
        deletePopup: false,

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null,

        //Edit Event
        isEditing: false,
        toEdit: this.props.match.params.eventId,
        type: this.props.match.params.type,
        eventDetails: [], isLoadingEvent: true,

        //Images from childUploader
        imageFile: null,
        uploadingURL: null,

        //eventOrganizer
        eventOrganizerName: '',
        eventOrganizerEmail: '',
        eventOrganizerPhone: '',
        correctOrgEmail: false,
        enablePosting: false,

        eventVideo: '',
        eventVideoTitle: '',

        supportingOrg: false,
        supOrgName: [],
        supOrgPhone: [],
        supOrgEmail: [],

        enableSponsor: false,
        sponsorLogo: [],
        sponsorLogoImageFile: [],
        sponsorLogoUploadingURL: [],
        sponsorName: [],
        sponsorEmail: [],
        sponsorType: [],
        sponsorLink: [],

    };

    componentDidMount() {
        window.scrollTo(0, 0);
        checkIfLogin(this.props.location.pathname);
        this.getUserRole();
        this.isEditing();
    }

    getImageUploadingURL = (url, file) => {
        this.setState({imageFile: file, uploadingURL: url, eventImage: url.split('?')[0]})
    };

    getSponImageUploadingURL = (url, file, index) => {
        let self = this;
        if (url && file) {
            uploadImageToDigitalOcean(file, url).then(function (response) {
                self.setState({
                    sponsorLogo: {...self.state.sponsorLogo, [index]: response.image},
                })
            }).catch(function (error) {
            })
        }
    };

    getUserRole = () => {
        let self = this;
        const req = new GetGroupRequest();
        req.setGroupId(this.props.match.params.groupId);
        getGroupDetails(req, 3).then(function (response) {
            const group = response.getGroup();
            const title = group.getName();
            const allowTicketing = group.getTicketallowed();
            const allowedCurrency = group.getTransactionCurrencyList()[0];
            const userRole = getRoleString(group.getRole());
            const userRoleStatus = getStatusString(group.getStatus());
            const isFetchingGroups = false;
            self.setState({
                isFetchingGroups
            });
            if (userRoleStatus === usedString.APPROVED && (userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT)) {
                self.setState({
                    userRole, title, allowTicketing, allowedCurrency, userRoleStatus
                })
            }
        }).catch(function (error) {
            const isFetchingGroups = false;
            self.setState({
                isFetchingGroups
            })
        })
    };
    isEditing = () => {
        if (this.state.type === 'edit') {
            pageViewAnalyst('Edit Event', this.props.location.pathname);
            this.setState({isEditing: true});
            this.fetchEventsById();
        } else {
            this.setState({errGettingEvent: false});
            pageViewAnalyst('Add new event', this.props.location.pathname);
        }
    };

    fetchEventsById = () => {
        let self = this;
        const req = new GetEventRequest();
        req.setGroupId(this.props.match.params.groupId);
        req.setEventId(this.state.toEdit);
        getEventDetails(req, 3).then(function (response) {
            const eventDetails = response.getEvent();
            const errGettingEvent = false;
            const isLoadingEvent = false;

            const eventLists = response.getTicketTypesList();
            let enableTicketing = false;
            if (eventLists.length > 0)
                enableTicketing = true;

            self.setState({
                errGettingEvent,
                eventDetails,
                isLoadingEvent,
                eventLists, enableTicketing
            });
            self.setFields();
        }).catch(function (error) {
            const errGettingEvent = true;
            const isLoadingEvent = false;
            self.setState({
                errGettingEvent,
                isLoadingEvent
            });
        })
    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    handleDateChange = (date, dateFor) => {
        this.setState({[dateFor]: date});
        if (dateFor === 'startDateSelected' && this.state.endDateSelected < date) {
            this.setState({
                endDateSelected: date,
                isOneDayEvent: false
            });
        }
    };

    handleTicketDateChange = (date, index, dateFor) => {
        let ticketFor = this.state[dateFor]; //creates the clone of the state
        ticketFor[index] = date;
        this.setState({
            [dateFor]: ticketFor
        });
    };

    handleTimeChange = timeFor => event => {
        this.setState({
            [timeFor]: event.target.value,
        });
    };


    handleTicketTimeChange = (index, name) => event => {
        let ticketDateType = this.state[name]; //creates the clone of the state
        ticketDateType[index] = event.target.value;
        this.setState({
            [name]: ticketDateType
        });
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleEmailChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
        if (event.target.value && emailFormat.test(event.target.value) || event.target.value === 'NA') {
            this.setState({
                correctOrgEmail: true,
            });
        } else {
            this.setState({
                correctOrgEmail: false,
            });
        }
    };

    handleAdBSDateChange = name => event => {
        if (name === 'startYearNP' && this.state.endYearNP < event.target.value) {
            this.setState({
                endYearNP: event.target.value,
                isOneDayEvent: false
            })
        }
        if (name === 'startDayNP' && this.state.endDayNP < event.target.value) {
            this.setState({
                endDayNP: event.target.value,
                isOneDayEvent: false
            })
        }
        if (name === 'startMonthNP') {
            this.setState({
                startDayNP: '1',
                startDayListNP: nepaliYearList.year[this.state.startYearNP][event.target.value]
            });
            if (this.state.endMonthNP < event.target.value) {
                this.setState({
                    endMonthNP: '1',
                    isOneDayEvent: false
                })
            }
        } else if (name === 'endMonthNP') {
            this.setState({
                endDayNP: this.state.startDayNP,
                endDayListNP: nepaliYearList.year[this.state.endYearNP][event.target.value]
            })
        }

        this.setState({
            [name]: event.target.value,
        });
    };

    ticketDateChange = (index, name) => event => {
        let ticketDateType = this.state[name]; //creates the clone of the state
        ticketDateType[index] = event.target.value;
        this.setState({
            [name]: ticketDateType
        });
    };

    handleDateTypeChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
        this.eventDateConversion(event.target.value);
        if (this.state.allowTicketing && this.state.enableTicketing)
            this.ticketDateConversion(event.target.value);
    };

    eventDateConversion = (dType) => {
        if (dType === 'ad') {
            let convertedDate = adbs.bs2ad(this.state.startYearNP + '/' + this.state.startMonthNP + '/' + this.state.startDayNP);
            let convertedEndDate = adbs.bs2ad(this.state.endYearNP + '/' + this.state.endMonthNP + '/' + this.state.endDayNP);
            this.setState({
                endDateSelected: new Date(convertedEndDate.year, convertedEndDate.month - 1, convertedEndDate.day),
                startDateSelected: new Date(convertedDate.year, convertedDate.month - 1, convertedDate.day)
            });

        } else if (dType === 'bs') {
            let year = new Date(this.state.startDateSelected).getFullYear();
            let month = new Date(this.state.startDateSelected).getMonth() + 1;
            let date = new Date(this.state.startDateSelected).getDate();
            let yearEnd = new Date(this.state.endDateSelected).getFullYear();
            let monthEnd = new Date(this.state.endDateSelected).getMonth() + 1;
            let dateEnd = new Date(this.state.endDateSelected).getDate();

            let convertedDateToBs = adbs.ad2bs(year + '/' + month + '/' + date).en;
            let convertedEndDateToBs = adbs.ad2bs(yearEnd + '/' + monthEnd + '/' + dateEnd).en;

            this.setState({
                startYearNP: convertedDateToBs.year,
                startMonthNP: convertedDateToBs.month,
                startDayNP: convertedDateToBs.day,
                endYearNP: convertedEndDateToBs.year,
                endMonthNP: convertedEndDateToBs.month,
                endDayNP: convertedEndDateToBs.day,
            });
        }
    };

    ticketDateConversion = (dType) => {
        if (dType === 'ad') {
            let self = this;
            this.state.ticketCount.forEach(function (count, index) {
                const ticketYear = self.state.ticketYearNP[index] ? self.state.ticketYearNP[index] : nepDate.year;
                const ticketMonth = self.state.ticketMonthNP[index] ? self.state.ticketMonthNP[index] : nepDate.month;
                const ticketDay = self.state.ticketDayNP[index] ? self.state.ticketDayNP[index] : nepDate.day;
                const convertedDate = adbs.bs2ad(ticketYear + '/' + ticketMonth + '/' + ticketDay);
                // self.setState({
                //     ticketExp: {
                //         ...self.state.ticketExp,
                //         [index]: new Date(convertedDate.year, convertedDate.month - 1, convertedDate.day)
                //     },
                // });
                self.state.ticketExp[index] = new Date(convertedDate.year, convertedDate.month - 1, convertedDate.day)
            });
        } else if (dType === 'bs') {
            let self = this;
            let expiryDate = [];
            this.state.ticketCount.forEach(function (count, index) {
                expiryDate[index] = self.state.ticketExp[index] ? self.state.ticketExp[index] : new Date().getTime();

                let year = new Date(expiryDate[index]).getFullYear();
                let month = new Date(expiryDate[index]).getMonth() + 1;
                let date = new Date(expiryDate[index]).getDate();

                let convertedDateToBs = adbs.ad2bs(year + '/' + month + '/' + date).en;

                self.state.ticketYearNP[index] = convertedDateToBs.year;
                self.state.ticketMonthNP[index] = convertedDateToBs.month;
                self.state.ticketDayNP[index] = convertedDateToBs.day;

            });
        }
    };

    getNepaliDigits = (digit) => {
        var nepDate = '';
        for (var i = 0; i < digit.length; i++) {
            nepDate = nepDate + String.fromCharCode(digit.charAt(i).charCodeAt(0) + 2358);
        }
        return nepDate;
    };

    handleTicketChange = (index, name) => event => {
        let a = this.state[name]; //creates the clone of the state
        if (name === 'freeTicket') a[index] = event.target.checked;
        else a[index] = event.target.value;
        this.setState({
            [name]: a
        });
        let stateNow = this;
        this.state.ticketCount.forEach(function (count, index) {
            stateNow.setState({
                errorMaxTicket: {...stateNow.state.errorMaxTicket, [index]: false},
                errorTicketPrice: {...stateNow.state.errorTicketPrice, [index]: false},
                errorMaxAllowedTicket: {...stateNow.state.errorMaxAllowedTicket, [index]: false}
            });
        });
    };

    handleSponsorChange = (index, name) => event => {
        let sponsor = this.state[name]; //creates the clone of the state
        sponsor[index] = event.target.value;
        this.setState({
            [name]: sponsor
        });
    };

    handleSupOrgChange = (index, name) => event => {
        let supOrg = this.state[name]; //creates the clone of the state
        supOrg[index] = event.target.value;
        this.setState({
            [name]: supOrg
        });
    };

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    };

    // uploadToDigital(imageFile) {
    //     let uploadedImages = [];
    //     let self = this;
    //     var length = 0;
    //     var itemsProcessed = 0;
    //     for (var key in imageFile) {
    //         if (imageFile.hasOwnProperty(key)) {
    //             ++length;
    //         }
    //     };
    //     return new Promise(function (resolve, reject) {
    //         Object.keys(imageFile).forEach((file, index) => {
    //             uploadImageToDigitalOcean(self.state.sponsorLogoImageFile[index], self.state.sponsorLogoUploadingURL[index]).then(function (response) {
    //                 uploadedImages.push(response.image);
    //                 itemsProcessed++;
    //                 if (itemsProcessed === length) {
    //                     resolve(uploadedImages)
    //                 }
    //             }).catch(function (error) {
    //
    //             })
    //         });
    //     });
    // }

    handleAddressChange = address => {
        this.setState({address});
    };

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => this.setState({latitude: latLng.lat, longitude: latLng.lng, address: address}))
            .catch(error => console.error('Error', error));
    };

    validateFields = (fieldValue) => {
        let noOfTicket = fieldValue;
        let totalTicket;
        if (!noOfTicket) {
            return {
                'err': true,
                'msg': 'Not a valid number'
            }
        } else if (typeof noOfTicket !== 'number') {
            let result = (noOfTicket - Math.floor(noOfTicket)) !== 0;
            if (result) {
                return {
                    'err': true,
                    'msg': 'Should not contain decimal value'
                }
            } else if (Math.sign(noOfTicket) !== 1) {
                return {
                    'err': true,
                    'msg': 'Should not be negative'
                }
            } else {
                totalTicket = parseInt(noOfTicket);
                return {
                    'err': false,
                    'msg': '',
                    'newValue': totalTicket
                }
            }
        } else {
            totalTicket = noOfTicket;
            return {
                'err': false,
                'msg': '',
                'newValue': totalTicket
            }
        }
    };

    validatePrice = (isFreeTicket, priceInput) => {
        let inputPrice = priceInput;
        let totalPrice;
        if (isFreeTicket) {
            return {
                'err': false,
                'msg': '',
                'newValue': 0.00
            }
        }
        if (!inputPrice) {
            return {
                'err': true,
                'msg': 'Not a valid price'
            }
        } else if (typeof inputPrice !== 'number') {
            if (Math.sign(inputPrice) !== 1) {
                return {
                    'err': true,
                    'msg': 'Should not be negative'
                }
            } else {
                totalPrice = inputPrice;
                return {
                    'err': false,
                    'msg': '',
                    'newValue': totalPrice
                }
            }
        } else {
            totalPrice = inputPrice;
            return {
                'err': false,
                'msg': '',
                'newValue': totalPrice
            }
        }
    };

    createEvent = () => {
        this.setState({
            errorMaxTicket: [],
            errorTicketPrice: [],
            errorMaxAllowedTicket: [],
        });
        let stateNow = this;
        let errorStatus = [];
        let validateMaxTicket;
        let validateTicketPrice;
        let validateMaxTicketAllowed;
        if (!this.state.isEditing) {
            if (!this.state.enableTicketing) {
                validateMaxTicket = {'err': false};
            } else {
                this.state.ticketCount.forEach(function (count, index) {
                    // validate max ticket
                    validateMaxTicket = stateNow.validateFields(stateNow.state.maxTicket[index]);
                    if (validateMaxTicket.err) {
                        errorStatus.push(true);
                        stateNow.setState({
                            errorMaxTicket: {...stateNow.state.errorMaxTicket, [index]: validateMaxTicket.err},
                            errorMessageMaxTicket: {
                                ...stateNow.state.errorMessageMaxTicket,
                                [index]: validateMaxTicket.msg
                            },
                        });
                    } else {
                        errorStatus.push(false);
                        stateNow.setState({
                            maxTicket: {...stateNow.state.maxTicket, [index]: validateMaxTicket.newValue},
                        });
                    }
                    // validate ticket price
                    validateTicketPrice = stateNow.validatePrice(stateNow.state.freeTicket[index], stateNow.state.ticketPrice[index]);
                    if (validateTicketPrice.err) {
                        errorStatus.push(true);
                        stateNow.setState({
                            errorTicketPrice: {...stateNow.state.errorTicketPrice, [index]: validateTicketPrice.err},
                            errorMessageTicketPrice: {
                                ...stateNow.state.errorMessageTicketPrice,
                                [index]: validateTicketPrice.msg
                            },
                        });
                    } else {
                        errorStatus.push(false);
                        stateNow.setState({
                            ticketPrice: {...stateNow.state.ticketPrice, [index]: validateTicketPrice.newValue},
                        });
                    }
                    // validate maxTicketAllowed
                    validateMaxTicketAllowed = stateNow.validateFields(stateNow.state.maxAllowedTicket[index]);
                    if (validateMaxTicketAllowed.err) {
                        errorStatus.push(true);
                        stateNow.setState({
                            errorMaxAllowedTicket: {
                                ...stateNow.state.errorMaxAllowedTicket,
                                [index]: validateMaxTicketAllowed.err
                            },
                            errorMessageMaxAllowedTicket: {
                                ...stateNow.state.errorMessageMaxAllowedTicket,
                                [index]: validateMaxTicketAllowed.msg
                            },
                        });
                    } else {
                        errorStatus.push(false);
                        stateNow.setState({
                            maxAllowedTicket: {
                                ...stateNow.state.maxAllowedTicket,
                                [index]: validateMaxTicketAllowed.newValue
                            },
                        });
                    }
                });
            }
        }

        if (!errorStatus.includes(true) || this.state.isEditing) {
            this.setState({snackOpen: false, creatingEvent: true});
            let self = this;
            if (this.state.imageFile && this.state.uploadingURL) {
                uploadImageToDigitalOcean(this.state.imageFile, this.state.uploadingURL).then(function (response) {
                    self.setState({eventImage: response.image});
                    self.postNewEvent(response.image)
                }).catch(function (error) {
                })
            } else this.postNewEvent('')
        } else {
        }
    };

    postNewEvent = (postingImage) => {
        let self = this;
        const stateNow = this.state;

        let calendarStartDate = this.state.startDateSelected;
        let calendarEndDate = this.state.endDateSelected;
        let ticketExpiryWithTime = [];
        if (this.state.dType === 'bs') {
            let convertedDate = adbs.bs2ad(this.state.startYearNP + '/' + this.state.startMonthNP + '/' + this.state.startDayNP);
            let convertedEndDate = adbs.bs2ad(this.state.endYearNP + '/' + this.state.endMonthNP + '/' + this.state.endDayNP);
            calendarEndDate = new Date(convertedEndDate.year, convertedEndDate.month - 1, convertedEndDate.day);
            calendarStartDate = new Date(convertedDate.year, convertedDate.month - 1, convertedDate.day);

            if (this.state.allowTicketing && this.state.enableTicketing) {
                let self = this;
                let ticketYear = [];
                let ticketMonth = [];
                let ticketDay = [];
                this.state.ticketCount.forEach(function (count, index) {
                    ticketYear[index] = self.state.ticketYearNP[index] ? self.state.ticketYearNP[index] : nepDate.year;
                    ticketMonth[index] = self.state.ticketMonthNP[index] ? self.state.ticketMonthNP[index] : nepDate.month;
                    ticketDay[index] = self.state.ticketDayNP[index] ? self.state.ticketDayNP[index] : nepDate.day;
                    let convertedDate = adbs.bs2ad(ticketYear[index] + '/' + ticketMonth[index] + '/' + ticketDay[index]);
                    let ticketExpDate = new Date(convertedDate.year, convertedDate.month - 1, convertedDate.day);

                    const ticketExpiryTime = self.state.ticketExpTime[index] ? self.state.ticketExpTime[index] : "23:59";
                    var startHour = ticketExpiryTime.split(':')[0];
                    var startMin = ticketExpiryTime.split(':')[1];
                    var ticketExpireDateWithTIme = new Date();
                    ticketExpireDateWithTIme.setFullYear(new Date(ticketExpDate).getFullYear());
                    ticketExpireDateWithTIme.setMonth(new Date(ticketExpDate).getMonth());
                    ticketExpireDateWithTIme.setDate(new Date(ticketExpDate).getDate());
                    ticketExpireDateWithTIme.setHours(startHour);
                    ticketExpireDateWithTIme.setMinutes(startMin);
                    ticketExpireDateWithTIme.setSeconds(0);
                    ticketExpireDateWithTIme.setMilliseconds(0);

                    ticketExpiryWithTime[index] = ticketExpireDateWithTIme;
                });
            }
        } else if (this.state.dType === 'ad') {
            this.state.ticketCount.forEach(function (count, index) {
                let ticketExpDate = self.state.ticketExp[index] ? self.state.ticketExp[index] : new Date().getTime();
                const ticketExpiryTime = self.state.ticketExpTime[index] ? self.state.ticketExpTime[index] : "23:59";
                var startHour = ticketExpiryTime.split(':')[0];
                var startMin = ticketExpiryTime.split(':')[1];
                var ticketExpireDateWithTIme = new Date();
                ticketExpireDateWithTIme.setFullYear(new Date(ticketExpDate).getFullYear());
                ticketExpireDateWithTIme.setMonth(new Date(ticketExpDate).getMonth());
                ticketExpireDateWithTIme.setDate(new Date(ticketExpDate).getDate());
                ticketExpireDateWithTIme.setHours(startHour);
                ticketExpireDateWithTIme.setMinutes(startMin);
                ticketExpireDateWithTIme.setSeconds(0);
                ticketExpireDateWithTIme.setMilliseconds(0);

                ticketExpiryWithTime[index] = ticketExpireDateWithTIme;
            });
        }

        //Ticket Details
        let ticketList = [];
        this.state.ticketCount.forEach(function (count, index) {
            if (self.state.ticketName[index]) {
                const ticketPrice = new TicketPricing();
                ticketPrice.setCurrency(self.state.allowedCurrency);
                ticketPrice.setPrice(self.state.freeTicket[index] ? 0 : self.state.ticketPrice[index]);

                const ticketInfo = new TicketModel();
                ticketInfo.setTitle(self.state.ticketName[index]);
                ticketInfo.setDescription(self.state.ticketDesc[index]);
                ticketInfo.setExpiration(ticketExpiryWithTime[index] ? new Date(ticketExpiryWithTime[index]).getTime() : new Date().getTime());
                ticketInfo.setMaxAvailableLimit(self.state.maxTicket[index]);
                ticketInfo.setMaxPurchaseCount(self.state.maxAllowedTicket[index]);
                ticketInfo.setPricingDetail(ticketPrice);

                ticketInfo.setTicketType(self.state.freeTicket[index] ? "FREE_TICKET" : "EVENT_TICKET");
                ticketInfo.setOwnerBusinessId(self.props.match.params.groupId);

                ticketList.push(ticketInfo);
            }
        });

        const userSelectedStartTime = stateNow.startTime;
        var startHour = userSelectedStartTime.split(':')[0];
        var startMin = userSelectedStartTime.split(':')[1];
        var eventStartStamp = new Date();
        eventStartStamp.setFullYear(new Date(calendarStartDate).getFullYear());
        eventStartStamp.setMonth(new Date(calendarStartDate).getMonth());
        eventStartStamp.setDate(new Date(calendarStartDate).getDate());
        eventStartStamp.setHours(startHour);
        eventStartStamp.setMinutes(startMin);
        eventStartStamp.setSeconds(0);
        eventStartStamp.setMilliseconds(0);


        const userSelectedEndTime = stateNow.endTime;
        var endHour = userSelectedEndTime.split(':')[0];
        var endMin = userSelectedEndTime.split(':')[1];
        var eventEndStamp = new Date();
        eventEndStamp.setFullYear(new Date(calendarEndDate).getFullYear());
        eventEndStamp.setMonth(new Date(calendarEndDate).getMonth());
        eventEndStamp.setDate(new Date(calendarEndDate).getDate());
        eventEndStamp.setHours(endHour);
        eventEndStamp.setMinutes(endMin);
        eventEndStamp.setSeconds(0);
        eventEndStamp.setMilliseconds(0);
        let startDateTimeStamp, endDateTimeStamp;
        if (stateNow.addTimeOffset) {
            var UTC_offset = stateNow.offsets;
            startDateTimeStamp = new Date(eventStartStamp + (UTC_offset)).getTime(); //converted to timeStamp
            endDateTimeStamp = new Date(eventEndStamp + (UTC_offset)).getTime(); //converted to timeStamp
        } else {
            startDateTimeStamp = new Date(eventStartStamp).getTime(); //converted to timeStamp
            endDateTimeStamp = new Date(eventEndStamp).getTime(); //converted to timeStamp
        }
        //Location lat long
        const location = new LocationInfo();
        location.setLat(stateNow.latitude);
        location.setLon(stateNow.longitude);

        let supOrgList = [];
        if (this.state.supportingOrg) {
            this.state.supOrgCount.forEach(function (count, index) {
                if (self.state.supOrgName[index] && self.state.supOrgName[index] !== '') {
                    const organizer = new Organizer();
                    organizer.setName(self.state.supOrgName[index]);
                    organizer.setPhone(self.state.supOrgPhone[index]);
                    organizer.setEmail(self.state.supOrgEmail[index]);
                    supOrgList.push(organizer);
                }
            });
        }

        let sponsorList = [];
        if (this.state.enableSponsor) {
            this.state.sponsorsCount.forEach(function (count, index) {
                if ((self.state.sponsorName[index] && self.state.sponsorName[index] !== '') || (self.state.sponsorLogo[index] && self.state.sponsorLogo[index] !== '')) {
                    const sponsor = new Sponsor();
                    sponsor.setName(self.state.sponsorName[index]);
                    sponsor.setLink(self.state.sponsorLink[index]);
                    sponsor.setEmail(self.state.sponsorEmail[index]);
                    sponsor.setImageUrl(self.state.sponsorLogo[index]);
                    sponsor.setType(self.state.sponsorType[index]);
                    sponsorList.push(sponsor);
                }
            });
        }

        const event = new Event();
        event.setGroupId(this.props.match.params.groupId);
        event.setYear(parseInt(formatDate(calendarStartDate, 'yyyy')));
        event.setMonth(parseInt(formatDate(calendarStartDate, 'MM')));
        event.setDay(parseInt(formatDate(calendarStartDate, 'dd')));
        event.setStartFrom(startDateTimeStamp); //timestamp
        event.setEnds(endDateTimeStamp); //timeStamp
        event.setCategory(stateNow.eventCategory);
        event.setEventDesc(stateNow.eventDesc);
        event.setEventCoverImage(stateNow.eventImage ? stateNow.eventImage : '');
        event.setEventType("EVENT");
        event.setEventStatus(Event.EventStatus.OPEN);
        event.setYearlyRepeated(stateNow.isYearlyRepeated);
        event.setLocation(location);
        event.setLocationString(stateNow.address);
        event.setTimeZone(stateNow.timeZone);
        event.setPublicHoliday(stateNow.isPublicHoliday);
        event.setOrganizerEmail(stateNow.eventOrganizerEmail);
        event.setOrganizerName(stateNow.eventOrganizerName);
        event.setOrganizerPhone(stateNow.eventOrganizerPhone);
        event.setTitle(stateNow.eventName);
        event.setSponsorList(sponsorList);
        event.setOrganizerList(supOrgList);
        event.setIsPrivate(!stateNow.isPublicEvent);
        const metadata = event.getMetaDataMap();
        metadata.set("event_post_enable", (stateNow.enablePosting).toString());
        metadata.set("date_format_type", stateNow.dType);
        metadata.set("event_date_type", (stateNow.isOneDayEvent).toString());
        metadata.set("event_video_url", stateNow.eventVideo);
        metadata.set("event_video_title", stateNow.eventVideoTitle);
        metadata.set("event_hosted_by", stateNow.eventHostName);
        if (stateNow.isEditing) {
            const req = new UpdateEventRequest();
            event.setEventId(stateNow.toEdit);
            req.setEvent(event);
            updateEventDetails(req).then(function (response) {
                self.snackbar(true, 'Event Updated for ' + new Date(startDateTimeStamp).toLocaleDateString(), 'success', 5000);
                if (self.state.enablePosting && response.getUpdated())
                    self.submitNewCat(response.getEventId());
                setTimeout(function () {
                    window.location.href = '/c/' + self.props.match.params.groupId + '/events/' + response.getEventId();
                }, 2000);
            }).catch(function (error) {
                self.setState({
                    creatingEvent: false
                });
                self.snackbar(true, error.message, 'error', 5000);
                if (error.code === 14) {
                    window.location.reload()
                }
            })
        } else {
            const req = new CreateEventRequest();
            req.setEvent(event);
            req.setBusinessAccountId(this.props.match.params.groupId); //GroupID
            req.setTicketsList(ticketList);
            addNewEvent(req).then(function (response) {
                self.snackbar(true, 'Event Added for ' + new Date(startDateTimeStamp).toLocaleDateString(), 'success', 5000);
                if (self.state.enablePosting && response.getCreated())
                    self.submitNewCat(response.getEventId());
                setTimeout(function () {
                    window.location.href = '/c/' + self.props.match.params.groupId + '/events/' + response.getEventId();
                }, 2000);
            }).catch(function (error) {
                self.setState({
                    creatingEvent: false
                });
                self.snackbar(true, error.message, 'error', 5000);
                if (error.code === 14) {
                    window.location.reload()
                }
            })
        }
    };
    submitNewCat = (eventId) => {
        let self = this;
        const req = new AddCategoryRequest();
        req.setGroupId(this.props.match.params.groupId);
        req.setCategoryName('post@' + eventId);
        req.setCategoryDesc('');
        req.setCategoryImage('');
        req.setGeneralWriteAccess(true);
        createNewCategory(req).then(function (response) {
        }).catch(function (error) {
        })
    };
    setFields = () => {
        const eventDetails = this.state.eventDetails;
        this.setState({
            eventCategory: eventDetails.getCategory(),
            startDateSelected: new Date(eventDetails.getStartFrom()),
            startTime: ("0" + new Date(eventDetails.getStartFrom()).getHours()).slice(-2) + ':' + ("0" + new Date(eventDetails.getStartFrom()).getMinutes()).slice(-2),
            endDateSelected: new Date(eventDetails.getEnds()),
            endTime: ("0" + new Date(eventDetails.getEnds()).getHours()).slice(-2) + ':' + ("0" + new Date(eventDetails.getEnds()).getMinutes()).slice(-2),
            latitude: eventDetails.getLocation().getLat(), longitude: eventDetails.getLocation().getLon(),
            eventName: eventDetails.getTitle(),
            eventImage: eventDetails.getEventCoverImage() ? eventDetails.getEventCoverImage() : '',
            eventDesc: eventDetails.getEventDesc(),
            address: eventDetails.getLocationString(),
            // timeZone: eventDetails.getTimeZone(),
            timeZone: completeTimeZone,
            isYearlyRepeated: eventDetails.getYearlyRepeated(),
            isPublicHoliday: eventDetails.getPublicHoliday(),
            isPublicEvent: !eventDetails.getIsPrivate(),
            eventOrganizerEmail: eventDetails.getOrganizerEmail(),
            eventOrganizerName: eventDetails.getOrganizerName(),
            eventOrganizerPhone: eventDetails.getOrganizerPhone(),
            correctOrgEmail: eventDetails.getOrganizerEmail() ? true : false,
            offsets: eventDetails.getTimeZone().split('_')[1],
            // eventTimeLoc: eventDetails.getTimeZone().split('(')[1].replace(')','').trim(),
            eventTimeLoc: eventLoc,
            addTimeOffset: false,
            addToCalendar: false,
            enableNotification: true,
            dType: eventDetails.getMetaDataMap().get('date_format_type') ? eventDetails.getMetaDataMap().get('date_format_type') : 'ad',
            isOneDayEvent: eventDetails.getMetaDataMap().get('event_date_type') === 'true' ? true : false,
            enablePosting: eventDetails.getMetaDataMap().get('event_post_enable') === 'true' ? true : false,
            eventHostName: eventDetails.getMetaDataMap().get('event_hosted_by'),
            eventVideo: eventDetails.getMetaDataMap().get('event_video_url'),
            eventVideoTitle: eventDetails.getMetaDataMap().get('event_video_title'),
            enableSponsor: eventDetails.getSponsorList().length > 0,
            supportingOrg: eventDetails.getOrganizerList().length > 0,
        });
        this.eventDateConversion('bs');
        let self = this;
        if (eventDetails.getSponsorList().length) {
            let sponsCount = [];
            eventDetails.getSponsorList().forEach(function (sponsor, index) {
                sponsCount.push(index);
                self.setState({
                    sponsorLogo: {...self.state.sponsorLogo, [index]: sponsor.getImageUrl()},
                    sponsorName: {...self.state.sponsorName, [index]: sponsor.getName()},
                    sponsorEmail: {...self.state.sponsorEmail, [index]: sponsor.getEmail()},
                    sponsorType: {...self.state.sponsorType, [index]: sponsor.getType()},
                    sponsorLink: {...self.state.sponsorLink, [index]: sponsor.getLink()},
                });
            });
            self.setState({
                sponsorsCount: sponsCount,
            });
        }
        if (eventDetails.getOrganizerList().length) {
            let supOrgList = [];
            eventDetails.getOrganizerList().forEach(function (organizer, index) {
                supOrgList.push(index);
                self.setState({
                    supOrgName: {...self.state.supOrgName, [index]: organizer.getName()},
                    supOrgPhone: {...self.state.supOrgPhone, [index]: organizer.getPhone()},
                    supOrgEmail: {...self.state.supOrgEmail, [index]: organizer.getEmail()},
                });
            });
            self.setState({
                supOrgCount: supOrgList,
            });
        }
    };

    reset() {
        this.setState({
            eventCategory: '_admin',
            startTime: "00:00",
            startDateSelected: new Date(),
            endTime: "23:59",
            endDateSelected: new Date(),
            latitude: 0, longitude: 0,
            eventName: '',
            eventImage: '',
            eventDesc: '',
            address: '',
            timeZone: '',
            isYearlyRepeated: false,
            isPublicHoliday: false,
            isPublicEvent: false,
            addToCalendar: false,
            enableNotification: false,
            eventTimeLoc: ''
        })
    }

    changeOffsets = () => {
        this.setState({
            addTimeOffset: true,
        })
    };
    getNameOffset = (offset, location) => {
        this.setState({
            offsets: offset, eventTimeLoc: location,
            timeZone: offset + ' (' + location + ')'
        })
    };

    changeSwitch = (enableTicketing) => {
        this.setState({enableTicketing: !enableTicketing, ticketCount: [1]});
        let self = this;
        if (!enableTicketing) {
            self.setState({
                ticketName: [],
                ticketDesc: [],
                maxTicket: [],
                maxAllowedTicket: [],
                ticketPrice: [],
                ticketExp: [],
                errorMaxTicket: [],
            })
        }
    };

    sponsorStsChange = (sponsorSts) => {
        this.setState({enableSponsor: !sponsorSts, sponsorsCount: [1]});
        let self = this;
        if (!sponsorSts) {
            self.setState({
                sponsorLogo: [],
                sponsorName: [],
                sponsorEmail: [],
                sponsorType: [],
                sponsorLink: [],
            })
        }
    };

    supportOrgStsChange = (supOrgSts) => {
        this.setState({supportingOrg: !supOrgSts, supOrgCount: [1]});
        let self = this;
        if (!supOrgSts) {
            self.setState({
                supOrgName: [],
                supOrgPhone: [],
                supOrgEmail: [],
            })
        }
    };

    addTicket = () => {
        var joined = this.state.ticketCount.concat(this.state.nextTicketCount++);
        this.setState({ticketCount: joined})
    };

    addSupOrganizer = () => {
        var orgCount = this.state.supOrgCount.concat(this.state.nextSupOrgCount++);
        this.setState({supOrgCount: orgCount})
    };

    addSponsorDetails = () => {
        var sponCount = this.state.sponsorsCount.concat(this.state.nextSponsorsCount++);
        this.setState({sponsorsCount: sponCount})
    };

    removeSupOrg = (index) => {
        var array = [...this.state.supOrgCount];
        if (index > -1) {
            array.splice(index, 1);
            this.setState({supOrgCount: array});
        }
        //    remove all values from index
        let singleSupOrgName = this.state.supOrgName; //creates the clone of the state
        let singleSupOrgPhone = this.state.supOrgPhone; //creates the clone of the state
        let singleSupOrgEmail = this.state.supOrgEmail; //creates the clone of the state

        singleSupOrgName[index] = '';
        singleSupOrgPhone[index] = '';
        singleSupOrgEmail[index] = '';

        this.setState({
            supOrgName: singleSupOrgName,
            supOrgPhone: singleSupOrgPhone,
            supOrgEmail: singleSupOrgEmail,
        });
    };


    removeSponsor = (index) => {
        var array = [...this.state.sponsorsCount];
        if (index > -1) {
            array.splice(index, 1);
            this.setState({sponsorsCount: array});
        }

        let singleSponsorLogo = this.state.sponsorLogo; //creates the clone of the state
        let singleSponsorName = this.state.sponsorName; //creates the clone of the state
        let singleSponsorEmail = this.state.sponsorEmail; //creates the clone of the state
        let singleSponsorType = this.state.sponsorType; //creates the clone of the state
        let singleSponsorLink = this.state.sponsorLink; //creates the clone of the state

        singleSponsorLogo[index] = '';
        singleSponsorName[index] = '';
        singleSponsorEmail[index] = '';
        singleSponsorType[index] = '';
        singleSponsorLink[index] = '';

        this.setState({
            sponsorLogo: singleSponsorLogo,
            sponsorName: singleSponsorName,
            sponsorEmail: singleSponsorEmail,
            sponsorType: singleSponsorType,
            sponsorLink: singleSponsorLink
        });
    };

    remove = (index) => {
        var array = [...this.state.ticketCount];
        if (index > -1) {
            array.splice(index, 1);
            this.setState({ticketCount: array});
        }

        let singleTicketName = this.state.ticketName; //creates the clone of the state
        let singleTicketDesc = this.state.ticketDesc; //creates the clone of the state
        let singleTicketMax = this.state.maxTicket; //creates the clone of the state
        let singleMaxAllowedTicket = this.state.maxAllowedTicket; //creates the clone of the state
        let singleTicketPrice = this.state.ticketPrice; //creates the clone of the state
        let singleTicketExp = this.state.ticketExp; //creates the clone of the state
        let singleTicketExpTime = this.state.ticketExpTime; //creates the clone of the state
        singleTicketName[index] = '';
        singleTicketDesc[index] = '';
        singleTicketMax[index] = '';
        singleTicketPrice[index] = '';
        singleTicketExp[index] = new Date();
        singleTicketExpTime[index] = "23:59";
        this.setState({
            ticketName: singleTicketName,
            ticketDesc: singleTicketDesc,
            maxTicket: singleTicketMax,
            maxAllowedTicket: singleMaxAllowedTicket,
            ticketPrice: singleTicketPrice,
            ticketExp: singleTicketExp,
            ticketExpTime: singleTicketExpTime,
        });
    };

    getResFrmChild = () => {
        this.setState({
            openTicket: false,
            addTicket: false
        })
    };

    openTicketDialog = (ticket) => {
        this.setState({
            openTicket: true,
            ticketDetails: ticket
        })
    };

    deleteTicket = (ticket) => {
        this.setState({
            ticketDetails: ticket,
            deletePopup: true
        })
    };

    addNewTicket = () => {
        this.setState({
            addTicket: true
        })
    };

    getUserResponseFromChild = (clickedButton, key) => {
        this.setState({snackOpen: false});
        let self = this;
        if (clickedButton === "Delete") {
            const req = new RemoveTicketRequest();
            req.setTicketSourceId(this.state.toEdit);
            req.setTicketId(this.state.ticketDetails.getTicketTypeId());
            removeTicket(req).then(function (response) {
                self.setState({
                    deletePopup: false
                });
                self.snackbar(true, 'Ticket deleted successfully', 'success', 5000);
                self.fetchEventTickets();
            }).catch(function (error) {
                self.setState({
                    deletePopup: false
                });
                self.snackbar(true, 'An error occur while deleting', 'error', 5000);
            })
        } else {
            this.setState({deletePopup: false});
        }
    };

    getSuccessResponse = (isSaved, type) => {
        this.setState({snackOpen: false});
        if (isSaved) {
            this.snackbar(true, type === 'add' ? 'Ticket added successfully' : 'Ticket updated successfully', 'success', 5000);
            this.fetchEventTickets();
        } else {
            this.snackbar(true, type === 'add' ? 'Could not add tickets' : 'Could not update tickets', 'error', 5000);
        }
    };

    fetchEventTickets = () => {
        let self = this;
        const req = new GetTicketsForEventRequest();
        req.setEventId(this.state.toEdit);
        req.setCursor('');
        req.setPageSize(20);
        getEventsTickets(req).then(function (response) {
            self.setState({
                eventLists: response.getTicketList(),
            });
        }).catch(function (error) {
        })
    };

    render() {
        const {classes} = this.props;
        const {
            eventDetails, freeTicket, enablePosting, eventHostName, toEdit, title, isEditing, userRole, userRoleStatus,
            endDateSelected, startDateSelected, eventName, eventCategory, eventVideoTitle,
            eventVideo, eventDesc, startTime, eventImage, endTime, isPublicEvent, isPublicHoliday,
            isYearlyRepeated, latitude, longitude, address, timeZone, addToCalendar, enableNotification,
            creatingEvent, isOneDayEvent, isFetchingGroups, offsets, addTimeOffset, eventTimeLoc, enableTicketing,
            ticketName, ticketDesc, ticketPrice, ticketExp, maxTicket, ticketCount, allowTicketing, allowedCurrency,
            eventLists, openTicket, addTicket, ticketDetails, deletePopup, maxAllowedTicket, errorMaxTicket,
            errorMessageMaxTicket, errorTicketPrice, errorMessageTicketPrice, errorMaxAllowedTicket,
            errorMessageMaxAllowedTicket, dType, startYearNP, currentNepYear, startMonthNP, startDayNP, startDayListNP,
            endDayNP, endDayListNP, endMonthNP, endYearNP, ticketYearNP, ticketMonthNP, ticketDayNP, ticketDayListNP,
            ticketExpTime, eventOrganizerName, eventOrganizerEmail, eventOrganizerPhone, supOrgCount, supportingOrg, correctOrgEmail, enableSponsor,
            sponsorsCount, sponsorEmail, sponsorLink, sponsorLogo, sponsorName, sponsorType, supOrgName, supOrgEmail, supOrgPhone, errGettingEvent
        } = this.state;
        return (
            <DashboardLayout
                title={`${isEditing ? "Edit Event Details" : "Add Event"} ${title ? '(' + title + ')' : ''}`}
                onBack={`/c/${this.props.match.params.groupId}`} linkTo={`/c/${this.props.match.params.groupId}`}>
                <div className={classes.root}>
                    {!isFetchingGroups ? (
                        <div>
                            {errGettingEvent === false && userRoleStatus === usedString.APPROVED && (userRole === usedString.ADMIN || (isEditing && userRole === usedString.PARTICIPANT)) ? (
                                <div>
                                    <div>
                                        <div className={classes.formWrapper}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography>
                                                        <b>Event Name</b> <span className={classes.required}>*</span>
                                                    </Typography>
                                                    <TextField
                                                        required
                                                        variant="outlined"
                                                        id="eventName"
                                                        name="eventName"
                                                        fullWidth
                                                        value={eventName}
                                                        onChange={this.handleChange('eventName')}
                                                        autoComplete="Event Name"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography>
                                                        <b>Event Image</b>
                                                    </Typography>
                                                    <Uploader type={"event"}
                                                              imageURL={eventImage ? eventImage : '/images/eventDefault.png'}
                                                              paddingTop={'45%'}
                                                              width={'100%'} index={"0"}
                                                              sendImageURLObtainStatus={this.getImageUploadingURL}/>
                                                </Grid>

                                            </Grid>
                                        </div>
                                        <div className={classes.formWrapper}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="h6" className={classes.eventTitle}>
                                                        Event Time and Location
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FormControl component="fieldset"
                                                                 className={classes.formControl}>
                                                        <RadioGroup
                                                            className={classes.group}
                                                            value={dType}
                                                            onChange={this.handleDateTypeChange('dType')}
                                                            row
                                                        >
                                                            <FormControlLabel value="ad" control={<Radio/>}
                                                                              label="A.D"/>
                                                            <FormControlLabel value="bs" control={<Radio/>}
                                                                              label="B.S"/>
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                {dType === "ad" ? (
                                                    <Grid item xs={12} sm={7}>
                                                        <Typography>
                                                            <b>Start Date</b> <span
                                                            className={classes.required}>*</span>
                                                        </Typography>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                autoOk
                                                                variant="inline"
                                                                maxDate={new Date(2036, 3, 13, 0, 0, 0)}
                                                                inputVariant="outlined"
                                                                format="MM/dd/yyyy"
                                                                value={startDateSelected}
                                                                onChange={(date) => this.handleDateChange(date, "startDateSelected")}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12} sm={7}>
                                                        <Typography>
                                                            <b>Start Date</b> <span
                                                            className={classes.required}>*</span>
                                                        </Typography>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sm={4}>
                                                                <Select
                                                                    value={startYearNP}
                                                                    input={
                                                                        <OutlinedInput
                                                                            name="startYearNP"
                                                                        />
                                                                    }
                                                                    onChange={this.handleAdBSDateChange('startYearNP')}
                                                                    fullWidth
                                                                >

                                                                    {Object.keys(nepaliYearList.year).map(
                                                                        (year) =>
                                                                            currentNepYear > year ?
                                                                                '' : <MenuItem
                                                                                    value={year}>{this.getNepaliDigits(year)}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Select
                                                                    value={startMonthNP}
                                                                    input={
                                                                        <OutlinedInput
                                                                            name="startMonthNP"
                                                                        />
                                                                    }
                                                                    onChange={this.handleAdBSDateChange('startMonthNP')}
                                                                    fullWidth
                                                                >

                                                                    {Object.keys(nepaliYearList.month).map(
                                                                        (month, index) =>
                                                                            index > 0 ?
                                                                                <MenuItem
                                                                                    value={month}>{nepaliYearList.month[month]}</MenuItem> : ''
                                                                    )}
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Select
                                                                    value={startDayNP}
                                                                    input={
                                                                        <OutlinedInput
                                                                            name="startDayNP"
                                                                        />
                                                                    }
                                                                    onChange={this.handleAdBSDateChange('startDayNP')}
                                                                    fullWidth
                                                                >
                                                                    {Object.keys(nepaliYearList[startDayListNP]).map(
                                                                        (month) =>
                                                                            <MenuItem
                                                                                value={nepaliYearList[startDayListNP][month]}>{this.getNepaliDigits(nepaliYearList[startDayListNP][month].toString())}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                <Grid item xs={12} sm={5}>
                                                    <Typography>
                                                        <b>Start Time</b> <span className={classes.required}>*</span>
                                                    </Typography>
                                                    <TextField
                                                        id="time"
                                                        type="time"
                                                        variant="outlined"
                                                        value={startTime}
                                                        className={classes.textField}
                                                        onChange={this.handleTimeChange("startTime")}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox color="secondary" name="oneDayEvent"
                                                                           checked={isOneDayEvent}
                                                                           onChange={() => this.setState({
                                                                               isOneDayEvent: !isOneDayEvent,
                                                                               endDate: new Date(),
                                                                               endDateSelected: startDateSelected,
                                                                               endTime: '23:59',
                                                                               endYearNP: startYearNP,
                                                                               endMonthNP: startMonthNP,
                                                                               endDayNP: startDayNP,
                                                                           })}/>}
                                                        label="This event is whole day event"
                                                    />
                                                </Grid>
                                                {dType === "ad" ? (
                                                    <Grid item xs={12} sm={7}>
                                                        <Typography>
                                                            <b>End Date</b> <span className={classes.required}>*</span>
                                                        </Typography>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                autoOk
                                                                minDate={startDateSelected}
                                                                maxDate={new Date(2036, 3, 13, 0, 0, 0)}
                                                                disabled={isOneDayEvent}
                                                                variant="inline"
                                                                inputVariant="outlined"
                                                                format="MM/dd/yyyy"
                                                                value={isOneDayEvent ? startDateSelected : endDateSelected}
                                                                onChange={(date) => this.handleDateChange(date, "endDateSelected")}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12} sm={7}>
                                                        <Typography>
                                                            <b>End Date</b> <span className={classes.required}>*</span>
                                                        </Typography>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sm={4}>
                                                                <Select
                                                                    value={endYearNP}
                                                                    disabled={isOneDayEvent}
                                                                    input={
                                                                        <OutlinedInput
                                                                            name="endYearNP"
                                                                        />
                                                                    }
                                                                    onChange={this.handleAdBSDateChange('endYearNP')}
                                                                    fullWidth
                                                                >

                                                                    {Object.keys(nepaliYearList.year).map(
                                                                        (year) =>
                                                                            currentNepYear > year || startYearNP > year ?
                                                                                '' : <MenuItem
                                                                                    value={year}>{this.getNepaliDigits(year)}</MenuItem>
                                                                    )}

                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Select
                                                                    value={endMonthNP}
                                                                    disabled={isOneDayEvent}
                                                                    input={
                                                                        <OutlinedInput
                                                                            name="endMonthNP"
                                                                        />
                                                                    }
                                                                    onChange={this.handleAdBSDateChange('endMonthNP')}
                                                                    fullWidth
                                                                >
                                                                    {Object.keys(nepaliYearList.month).map(
                                                                        (month, index) =>
                                                                            index > 0 ?
                                                                                endYearNP > startYearNP ? <MenuItem
                                                                                        value={month}>{nepaliYearList.month[month]}</MenuItem> :
                                                                                    ((endYearNP == startYearNP) && (index >= startMonthNP)) ?
                                                                                        <MenuItem
                                                                                            value={month}>{nepaliYearList.month[month]}</MenuItem> : '' : ''
                                                                    )}
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Select
                                                                    value={endDayNP}
                                                                    disabled={isOneDayEvent}
                                                                    input={
                                                                        <OutlinedInput
                                                                            name="endDayNP"
                                                                        />
                                                                    }
                                                                    onChange={this.handleAdBSDateChange('endDayNP')}
                                                                    fullWidth
                                                                >
                                                                    {Object.keys(nepaliYearList[endDayListNP]).map(
                                                                        (month) =>
                                                                            endYearNP > startYearNP ? <MenuItem
                                                                                    value={nepaliYearList[endDayListNP][month]}>{this.getNepaliDigits(nepaliYearList[endDayListNP][month].toString())}</MenuItem> :
                                                                                endYearNP == startYearNP && endMonthNP > startMonthNP ?
                                                                                    <MenuItem
                                                                                        value={nepaliYearList[endDayListNP][month]}>{this.getNepaliDigits(nepaliYearList[endDayListNP][month].toString())}</MenuItem> :
                                                                                    endMonthNP == startMonthNP && nepaliYearList[endDayListNP][month] >= startDayNP ?
                                                                                        <MenuItem
                                                                                            value={nepaliYearList[endDayListNP][month]}>{this.getNepaliDigits(nepaliYearList[endDayListNP][month].toString())}</MenuItem> : ''
                                                                    )}
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                <Grid item xs={12} sm={5}>
                                                    <Typography>
                                                        <b>End Time </b> <span className={classes.required}>*</span>
                                                    </Typography>
                                                    <TextField
                                                        id="time"
                                                        type="time"
                                                        disabled={isOneDayEvent}
                                                        variant="outlined"
                                                        value={endTime}
                                                        className={classes.textField}
                                                        onChange={this.handleTimeChange("endTime")}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography component="span">
                                                        <span
                                                            className={classes.required}>All Time are set for UTC </span>
                                                    </Typography>
                                                    <Typography variant="body1" component="span">
                                                        <span className={classes.timeZone} onClick={this.changeOffsets}
                                                              title="Change Timezone">{timeZone} </span>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    {addTimeOffset ? (
                                                        <div>
                                                            <Typography>
                                                                <b>Timezone</b> <span
                                                                className={classes.required}>*</span>
                                                            </Typography>
                                                            <UTC_Suggestions country={eventTimeLoc}
                                                                             offset={this.getNameOffset}/>
                                                        </div>
                                                    ) : (<div></div>)}
                                                </Grid>
                                                {/*<Grid item xs={12} sm={4}>*/}
                                                {/*    {addTimeOffset ? (*/}
                                                {/*        <div>*/}
                                                {/*            <Typography>*/}
                                                {/*                <b>Offset</b> <span*/}
                                                {/*                className={classes.required}> *</span>*/}
                                                {/*            </Typography>*/}
                                                {/*            <Select*/}
                                                {/*                fullWidth*/}
                                                {/*                value={offsets}*/}
                                                {/*                onChange={this.handleChange("offsets")}*/}
                                                {/*                input={*/}
                                                {/*                    <TextField*/}
                                                {/*                        name="offsetLists"*/}
                                                {/*                        id="offsets"*/}
                                                {/*                    />*/}
                                                {/*                }*/}
                                                {/*            >*/}
                                                {/*                {Object.keys(UTC_OffsetLists).length ?*/}
                                                {/*                    Object.keys(UTC_OffsetLists).map(*/}
                                                {/*                        (option, index) =>*/}
                                                {/*                            <MenuItem key={index}*/}
                                                {/*                                      value={option}>*/}
                                                {/*                                {option}*/}
                                                {/*                            </MenuItem>*/}
                                                {/*                    ) : null}*/}
                                                {/*            </Select>*/}
                                                {/*        </div>*/}
                                                {/*    ) : (<div></div>)}*/}
                                                {/*</Grid>*/}
                                            </Grid>
                                            {/*<Grid item xs={12} sm={6}>*/}
                                            {/*    <Typography>*/}
                                            {/*        <b>Event Category</b> <span*/}
                                            {/*        className={classes.required}>*</span>*/}
                                            {/*    </Typography>*/}
                                            {/*    <Select*/}
                                            {/*        fullWidth*/}
                                            {/*        value={eventCategory}*/}
                                            {/*        onChange={() => this.handleChange("eventCategory")}*/}
                                            {/*        input={*/}
                                            {/*            <OutlinedInput*/}
                                            {/*                name="eventCategory"*/}
                                            {/*                id="event-category"*/}
                                            {/*            />*/}
                                            {/*        }*/}
                                            {/*    >*/}
                                            {/*        {ranges.map(option => (*/}
                                            {/*            <MenuItem key={option.value} value={option.value}>*/}
                                            {/*                {option.label}*/}
                                            {/*            </MenuItem>*/}
                                            {/*        ))}*/}
                                            {/*    </Select>*/}
                                            {/*</Grid>*/}
                                            <br/>
                                            <Grid item xs={12} sm={12}>
                                                <Typography>
                                                    <b>Event Address</b>
                                                </Typography>
                                                <PlacesAutocomplete
                                                    value={address}
                                                    onChange={this.handleAddressChange}
                                                    onSelect={this.handleSelect}
                                                >
                                                    {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                                        <div>
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                {...getInputProps({
                                                                    placeholder: 'Search Places ...',
                                                                    className: 'location-search-input',
                                                                })}
                                                            />
                                                            <span className="autocomplete-dropdown-container">
                                                                    {loading && <div>Loading...</div>}
                                                                {suggestions.map(suggestion => {
                                                                    const className = suggestion.active
                                                                        ? 'suggestion-item--active'
                                                                        : 'suggestion-item';
                                                                    // inline style for demonstration purpose
                                                                    const style = suggestion.active
                                                                        ? {
                                                                            padding: '10px 5px',
                                                                            backgroundColor: '#fafafa',
                                                                            cursor: 'pointer'
                                                                        }
                                                                        : {
                                                                            padding: '10px 5px',
                                                                            backgroundColor: '#ffffff',
                                                                            cursor: 'pointer'
                                                                        };
                                                                    return (
                                                                        <div
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className,
                                                                                style,
                                                                            })}
                                                                        >
                                                                            <span>{suggestion.description}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                                </span>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            </Grid>
                                            {/*<Grid item xs={12} sm={6}>*/}
                                            {/*    <Typography>*/}
                                            {/*        <b>Latitude</b>*/}
                                            {/*    </Typography>*/}
                                            {/*    <TextField*/}
                                            {/*        variant="outlined"*/}
                                            {/*        id="Latitude"*/}
                                            {/*        name="Latitude"*/}
                                            {/*        type="number"*/}
                                            {/*        value={latitude}*/}
                                            {/*        onChange={this.handleChange('latitude')}*/}
                                            {/*        margin="normal"*/}
                                            {/*        fullWidth={true}*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            {/*<Grid item xs={12} sm={6}>*/}
                                            {/*    <Typography>*/}
                                            {/*        <b>Longitude</b>*/}
                                            {/*    </Typography>*/}
                                            {/*    <TextField*/}
                                            {/*        variant="outlined"*/}
                                            {/*        id="Longitude"*/}
                                            {/*        type="number"*/}
                                            {/*        name="Longitude"*/}
                                            {/*        value={longitude}*/}
                                            {/*        onChange={this.handleChange('longitude')}*/}
                                            {/*        margin="normal"*/}
                                            {/*        fullWidth={true}*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            <br/>
                                            <Grid item xs={12} sm={12}>
                                                <Typography>
                                                    <b>Description</b>
                                                </Typography>
                                                <TextField
                                                    multiline
                                                    fullWidth
                                                    rows="4"
                                                    rowsMax="25"
                                                    value={eventDesc}
                                                    onChange={this.handleChange('eventDesc')}
                                                    className={classes.textField}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <br/>
                                        </div>
                                        <div className={classes.formWrapper}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="h6" className={classes.eventTitle}>
                                                        Event Video
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <Typography component="div">
                                                        <b>Video Title</b>
                                                    </Typography>
                                                    <TextField
                                                        multiline
                                                        required
                                                        rows="1"
                                                        rowsMax="25"
                                                        fullWidth
                                                        value={eventVideoTitle}
                                                        onChange={this.handleChange('eventVideoTitle')}
                                                        className={classes.textField}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <br/>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography component="div">
                                                        <b>Youtube Video Id</b>
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        value={eventVideo}
                                                        onChange={this.handleChange('eventVideo')}
                                                        className={classes.textField}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <br/>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography component="span">
                                                        <small className={classes.timeZone}>Info: Please provide youtube
                                                            video id only.</small>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className={classes.formWrapper}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="h6" className={classes.eventTitle}>
                                                        Additional Setting
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox color="secondary" name="saveAddress"
                                                                           checked={isPublicHoliday}
                                                                           onChange={() => this.setState({isPublicHoliday: !isPublicHoliday})}/>}
                                                        label="Holiday"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox color="secondary" name="saveAddress"
                                                                           checked={isPublicEvent}
                                                                           onChange={() => this.setState({isPublicEvent: !isPublicEvent})}/>}
                                                        label="Public Event"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox color="secondary" name="enablePosting"
                                                                           checked={enablePosting}
                                                                           onChange={() => this.setState({enablePosting: !enablePosting})}/>}
                                                        label="Enable Posts in Event"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                        {/*{isAdmin() ? (*/}
                                        {/*    <div className={classes.formWrapper}>*/}
                                        {/*        <Grid container spacing={3}>*/}
                                        {/*        </Grid>*/}
                                        {/*    </div>*/}
                                        {/*):null}*/}
                                        <div className={classes.formWrapper}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="h6" className={classes.eventTitle}>
                                                        Organizer Contact Information
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography>
                                                        <b>Hosted By</b> <span className={classes.required}>*</span>
                                                    </Typography>
                                                    <TextField
                                                        required
                                                        variant="outlined"
                                                        id="hostedBy"
                                                        name="hostedBy"
                                                        fullWidth
                                                        // helperText="Type NA if no-Email"
                                                        value={eventHostName}
                                                        onChange={this.handleChange('eventHostName')}
                                                        autoComplete="Event Host Name"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography>
                                                        <b>Name</b> <span className={classes.required}>*</span>
                                                    </Typography>
                                                    <TextField
                                                        required
                                                        variant="outlined"
                                                        id="organizerName"
                                                        name="organizerName"
                                                        fullWidth
                                                        // helperText="Type NA if no-Email"
                                                        value={eventOrganizerName}
                                                        onChange={this.handleChange('eventOrganizerName')}
                                                        autoComplete="Organizer Name"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography>
                                                        <b>Email</b> <span className={classes.required}>*</span>
                                                    </Typography>
                                                    <TextField
                                                        required
                                                        variant="outlined"
                                                        id="organizerEmail"
                                                        name="organizerEmail"
                                                        fullWidth
                                                        // helperText="Type NA if no-Email"
                                                        value={eventOrganizerEmail}
                                                        onChange={this.handleEmailChange('eventOrganizerEmail')}
                                                        autoComplete="Organizer Email"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography>
                                                        <b>Phone</b> <span className={classes.required}>*</span>
                                                    </Typography>
                                                    <TextField
                                                        required
                                                        variant="outlined"
                                                        id="organizerPhone"
                                                        name="organizerPhone"
                                                        fullWidth
                                                        value={eventOrganizerPhone}
                                                        onChange={this.handleChange('eventOrganizerPhone')}
                                                        autoComplete="Organizer Phone"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox color="secondary"
                                                                           name="supportingOrg"
                                                                           checked={supportingOrg}
                                                                           onChange={() => this.supportOrgStsChange(supportingOrg)}/>}
                                                        label="Add supporting organizers +"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                        {/*Supporting Organizers*/}
                                        {supportingOrg ? (
                                            <div className={classes.formWrapper}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="h6" className={classes.eventTitle}>
                                                            Supporting Organizers Contact Information
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <div>
                                                    {supOrgCount.map((supOrg, index) => (
                                                        <Grid container spacing={3} style={{
                                                            position: 'relative',
                                                            background: '#d1e9ff',
                                                            marginBottom: '15px'
                                                        }}>
                                                            {index === supOrgCount.length - 1 && index !== 0 ? (
                                                                <IconButton className={classes.floatRight}
                                                                            aria-label="Send"
                                                                            title="Remove Ticket"
                                                                            size="small"
                                                                            color={"primary"}
                                                                            style={{
                                                                                marginBottom: '-19px',
                                                                                zIndex: 1,
                                                                                background: '#b71c1c',
                                                                                color: '#ffffff',
                                                                                padding: '0px',
                                                                                position: 'absolute',
                                                                                right: '10px',
                                                                                top: '10px'
                                                                            }}
                                                                            onClick={() => this.removeSupOrg(index)}>
                                                                    <CloseIcon/>
                                                                </IconButton>
                                                            ) : (<span></span>)}
                                                            <Grid item xs={12} sm={12}>
                                                                <Typography component="span">
                                                                    <span
                                                                        className={classes.required}>#{index + 1} Supporting Organizer </span>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Typography variant="body2">
                                                                    <b>Name</b>
                                                                </Typography>
                                                                <TextField
                                                                    required
                                                                    id="supOrgName"
                                                                    name="supOrgName"
                                                                    fullWidth
                                                                    value={supOrgName[index]}
                                                                    onChange={this.handleSupOrgChange(index, 'supOrgName')}
                                                                    autoComplete="Supporting Org Name"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Typography variant="body2">
                                                                    <b>Email</b>
                                                                </Typography>
                                                                <TextField
                                                                    required
                                                                    id="supOrgEmail"
                                                                    name="supOrgEmail"
                                                                    fullWidth
                                                                    value={supOrgEmail[index]}
                                                                    onChange={this.handleSupOrgChange(index, 'supOrgEmail')}
                                                                    autoComplete="Supporting Org Email"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Typography variant="body2">
                                                                    <b>Phone</b>
                                                                </Typography>
                                                                <TextField
                                                                    required
                                                                    id="supOrgPhone"
                                                                    name="supOrgPhone"
                                                                    fullWidth
                                                                    value={supOrgPhone[index]}
                                                                    onChange={this.handleSupOrgChange(index, 'supOrgPhone')}
                                                                    autoComplete="Supporting Org Phone"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                <div className={classes.simpleUnderline}></div>
                                                            </Grid>
                                                            <br/>
                                                        </Grid>
                                                    ))}
                                                    <Grid item xs={12} sm={12} style={{paddingTop: '10px'}}>
                                                        <Typography component="div">
                                                                <span className={classes.newLink}
                                                                      onClick={this.addSupOrganizer}>Add another organizer+ </span>
                                                        </Typography>
                                                    </Grid>
                                                </div>
                                            </div>
                                        ) : null}

                                        {/*Sponsors*/}
                                        <div className={classes.formWrapperAddition}>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={<Checkbox color="secondary"
                                                                       name="enableSponsor"
                                                                       checked={enableSponsor}
                                                                       onChange={() => this.sponsorStsChange(enableSponsor)}/>}
                                                    label="Add Sponsor"
                                                />
                                            </Grid>
                                        </div>
                                        {enableSponsor ? (
                                            <div className={classes.formWrapper}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="h6" className={classes.eventTitle}>
                                                            Sponsors Information
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <div>
                                                    {sponsorsCount.map((sponsor, index) => (
                                                        <Grid container spacing={3} style={{
                                                            position: 'relative',
                                                            background: '#d1e9ff',
                                                            marginBottom: '15px'
                                                        }}>
                                                            {index === sponsorsCount.length - 1 && index !== 0 ? (
                                                                <IconButton className={classes.floatRight}
                                                                            title="Remove Sponsor"
                                                                            size="small"
                                                                            color={"primary"}
                                                                            style={{
                                                                                marginBottom: '-19px',
                                                                                zIndex: 1,
                                                                                background: '#b71c1c',
                                                                                color: '#ffffff',
                                                                                padding: '0px',
                                                                                position: 'absolute',
                                                                                right: '10px',
                                                                                top: '10px'
                                                                            }}
                                                                            onClick={() => this.removeSponsor(index)}>
                                                                    <CloseIcon/>
                                                                </IconButton>
                                                            ) : (<span></span>)}
                                                            <Grid item xs={12} sm={12}>
                                                                <Typography component="span">
                                                                    <span
                                                                        className={classes.required}>#{index + 1} Sponsor Details </span>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography variant="body2">
                                                                    <b>Logo</b>
                                                                </Typography>
                                                                <Uploader type={"sponsorLogo"}
                                                                          imageURL={sponsorLogo[index] ? sponsorLogo[index] : '/images/defaultLogo.png'}
                                                                          paddingTop={'30%'}
                                                                          width={'100px'}
                                                                          index={index}
                                                                          sendImageURLObtainStatus={this.getSponImageUploadingURL}/>
                                                            </Grid>
                                                            <Grid item xs={12} sm={9}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Typography variant="body2">
                                                                            <b>Name</b>
                                                                        </Typography>
                                                                        <TextField
                                                                            required
                                                                            id="groupName"
                                                                            name="groupName"
                                                                            fullWidth
                                                                            value={sponsorName[index]}
                                                                            className={classes.textField}
                                                                            onChange={this.handleSponsorChange(index, 'sponsorName')}
                                                                            autoComplete="Community Name"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Typography variant="body2">
                                                                            <b>Email</b>
                                                                        </Typography>
                                                                        <TextField
                                                                            required
                                                                            id="url"
                                                                            name="url"
                                                                            fullWidth
                                                                            value={sponsorEmail[index]}
                                                                            onChange={this.handleSponsorChange(index, 'sponsorEmail')}
                                                                            autoComplete="URL"
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={6}>
                                                                        <Typography variant="body2">
                                                                            <b>Link</b>
                                                                        </Typography>
                                                                        <TextField
                                                                            required
                                                                            id="sponsorLink"
                                                                            name="sponsorLink"
                                                                            fullWidth
                                                                            value={sponsorLink[index]}
                                                                            onChange={this.handleSponsorChange(index, 'sponsorLink')}
                                                                            autoComplete="URL"
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={6}>
                                                                        <Typography variant="body2">
                                                                            <b>Type</b>
                                                                        </Typography>
                                                                        <TextField
                                                                            required
                                                                            id="sponsorType"
                                                                            name="sponsorType"
                                                                            fullWidth
                                                                            value={sponsorType[index]}
                                                                            onChange={this.handleSponsorChange(index, 'sponsorType')}
                                                                            autoComplete="URL"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                <div className={classes.simpleUnderline}></div>
                                                            </Grid>
                                                            <br/>
                                                        </Grid>
                                                    ))}
                                                    <Grid item xs={12} sm={12} style={{paddingTop: '10px'}}>
                                                        <Typography component="div">
                                                                <span className={classes.newLink}
                                                                      onClick={this.addSponsorDetails}>Add another sponsor+ </span>
                                                        </Typography>
                                                    </Grid>
                                                </div>
                                            </div>
                                        ) : null}

                                        {/*Ticketing */}
                                        {allowTicketing ? (
                                            <div className={classes.formWrapperAddition}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="h6" className={classes.eventTitle}>
                                                            Ticketing
                                                        </Typography>
                                                        <div className={classes.formSwitch} style={{float: 'left'}}>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={<Switch size="small"
                                                                                     checked={enableTicketing}
                                                                                     color="secondary"
                                                                                     disabled={eventLists.length > 0}
                                                                                     onChange={() => this.changeSwitch(enableTicketing)}/>}
                                                                    label="Add Tickets"
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        {enableTicketing && isEditing ? (
                                                            <Fab
                                                                variant="extended"
                                                                size="small"
                                                                aria-label="Add"
                                                                className={classes.inviteButton}
                                                                onClick={this.addNewTicket}
                                                            >
                                                                Add Ticket <AddIcon/>
                                                            </Fab>
                                                        ) : (<span style={{float: 'right'}}>&nbsp;</span>)}
                                                    </Grid>
                                                </Grid>
                                                <br/>
                                                {enableTicketing ? (
                                                    <div>
                                                        {/*for editing*/}
                                                        {isEditing ? (
                                                            <div>
                                                                {eventLists.length > 0 ? (
                                                                    <div>
                                                                        {eventLists.map((ticket, index) => (
                                                                            <Grid
                                                                                container
                                                                                spacing={3}
                                                                                className={classes.ticketListWrapper}
                                                                            >
                                                                                <Grid item xs={12} md={4} sm={4} lg={4}
                                                                                      className={classes.ticketItems}>
                                                                                    <Typography component="div">
                                                                                        <span
                                                                                            className={classes.availableTicketDesc}>{index + 1}.</span>&nbsp;&nbsp;
                                                                                        <span
                                                                                            className={classes.ticketNameList}>{ticket.getTitle()}</span>
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={12} md={4} sm={4} lg={4}
                                                                                      className={classes.ticketItems}>
                                                                                    <Typography component="div"
                                                                                                className={classes.availableTicketDesc}>
                                                                                        {new Date(ticket.getExpiration()).toLocaleString()}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={12} md={2} sm={2} lg={2}
                                                                                      className={classes.ticketItems}>
                                                                                    <Typography component="div"
                                                                                                className={classes.ticketAmount}>
                                                                                        {ticket.getTicketType() === usedString.FREE_TICKET ? 'FREE TICKET' : (
                                                                                            <span>{allowedCurrency} {getCountryCurrencySymbol(allowedCurrency)}{ticket.getPricingDetail().getPrice()}</span>)}
                                                                                    </Typography>

                                                                                </Grid>
                                                                                <Grid item xs={12} md={2} sm={2} lg={2}
                                                                                      className={classes.ticketItems}>
                                                                                    <IconButton
                                                                                        aria-label="delete"
                                                                                        className={classes.margin}
                                                                                        color="secondary"
                                                                                        size='small'
                                                                                        onClick={() => this.openTicketDialog(ticket)}>
                                                                                        <EditIcon
                                                                                            fontSize="default"/>
                                                                                    </IconButton>
                                                                                    <IconButton
                                                                                        aria-label="delete"
                                                                                        className={classes.margin}
                                                                                        color="primary"
                                                                                        size='small'
                                                                                        onClick={() => this.deleteTicket(ticket)}>
                                                                                        <DeleteIcon
                                                                                            fontSize="default"/>
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            </Grid>
                                                                        ))}
                                                                    </div>
                                                                ) : (<span></span>)}
                                                                <br/>
                                                            </div>
                                                        ) : (<div>
                                                            {ticketCount.map((ticket, index) => (
                                                                <Grid container spacing={3}
                                                                      style={{position: 'relative'}}>
                                                                    {index === ticketCount.length - 1 && index !== 0 ? (
                                                                        <IconButton className={classes.floatRight}
                                                                                    aria-label="Send"
                                                                                    title="Remove Ticket"
                                                                                    size="small"
                                                                                    color={"primary"}
                                                                                    style={{
                                                                                        marginBottom: '-19px',
                                                                                        zIndex: 1,
                                                                                        background: '#b71c1c',
                                                                                        color: '#ffffff',
                                                                                        padding: '0px',
                                                                                        position: 'absolute',
                                                                                        right: '10px',
                                                                                        top: '10px'
                                                                                    }}
                                                                                    onClick={() => this.remove(index)}>
                                                                            <CloseIcon/>
                                                                        </IconButton>
                                                                    ) : (<span></span>)}
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Typography component="span">
                                                                    <span
                                                                        className={classes.required}>#{index + 1} Ticket </span>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Typography>
                                                                            <b>Ticket Name</b> <span
                                                                            className={classes.required}>*</span>
                                                                        </Typography>
                                                                        <TextField
                                                                            required
                                                                            variant="outlined"
                                                                            id="ticketName"
                                                                            name="ticketName"
                                                                            fullWidth
                                                                            value={ticketName[index]}
                                                                            onChange={this.handleTicketChange(index, 'ticketName')}
                                                                            autoComplete="Ticket Name"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Typography>
                                                                            <b>Ticket Description</b>
                                                                        </Typography>
                                                                        <TextField
                                                                            multiline
                                                                            required
                                                                            variant="outlined"
                                                                            id="ticketDesc"
                                                                            name="ticketDesc"
                                                                            fullWidth
                                                                            rows="3"
                                                                            rowsMax="25"
                                                                            value={ticketDesc[index]}
                                                                            onChange={this.handleTicketChange(index, 'ticketDesc')}
                                                                            autoComplete="Ticket Description"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox color="secondary"
                                                                                               name="freeTicket"
                                                                                               checked={freeTicket[index]}
                                                                                               onChange={this.handleTicketChange(index, 'freeTicket')}/>}
                                                                            label="Free Ticket"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={3}>
                                                                        <Typography>
                                                                            <b>Maximum Tickets</b> <span
                                                                            className={classes.required}>*</span>
                                                                        </Typography>
                                                                        <TextField
                                                                            required
                                                                            variant="outlined"
                                                                            id="maxTicket"
                                                                            name="maxTicket"
                                                                            type="number"
                                                                            min="0"
                                                                            fullWidth
                                                                            value={maxTicket[index]}
                                                                            onChange={this.handleTicketChange(index, 'maxTicket')}
                                                                            autoComplete="Maximum Tickets"
                                                                        />
                                                                        {errorMaxTicket[index] ? (<span style={{
                                                                            fontSize: '14px',
                                                                            paddingLeft: '5px',
                                                                            color: '#b71c1c'
                                                                        }}>{errorMessageMaxTicket[index]}</span>) : (
                                                                            <span></span>)}
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <Typography>
                                                                            <b>Ticket Price</b> (per ticket) <span
                                                                            className={classes.required}>*</span>
                                                                        </Typography>
                                                                        <TextField
                                                                            required
                                                                            variant="outlined"
                                                                            id="ticketPrice"
                                                                            name="ticketPrice"
                                                                            fullWidth
                                                                            disabled={freeTicket[index]}
                                                                            helperText={`Note: Price are set for ${allowedCurrency}`}
                                                                            value={freeTicket[index] ? 0 : ticketPrice[index]}
                                                                            onChange={this.handleTicketChange(index, 'ticketPrice')}
                                                                            autoComplete="Ticket Price"
                                                                            type="number"
                                                                            min="0"
                                                                        />
                                                                        {errorTicketPrice[index] ? (<span style={{
                                                                            fontSize: '14px',
                                                                            paddingLeft: '5px',
                                                                            color: '#b71c1c'
                                                                        }}>{errorMessageTicketPrice[index]}</span>) : (
                                                                            <span></span>)}
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={5}>
                                                                        <Typography>
                                                                            <b>Max Purchase</b> (per purchase) <span
                                                                            className={classes.required}>*</span>
                                                                        </Typography>
                                                                        <TextField
                                                                            required
                                                                            variant="outlined"
                                                                            id="ticketPrice"
                                                                            name="ticketPrice"
                                                                            fullWidth
                                                                            helperText={`Note: Allowed no of ticket while purchasing`}
                                                                            value={maxAllowedTicket[index]}
                                                                            onChange={this.handleTicketChange(index, 'maxAllowedTicket')}
                                                                            autoComplete="Maximum ticket purchase"
                                                                            type="number"
                                                                            min="1"
                                                                        />
                                                                        {errorMaxAllowedTicket[index] ? (<span style={{
                                                                            fontSize: '14px',
                                                                            paddingLeft: '5px',
                                                                            color: '#b71c1c'
                                                                        }}>{errorMessageMaxAllowedTicket[index]}</span>) : (
                                                                            <span></span>)}
                                                                    </Grid>
                                                                    {dType === "ad" ? (
                                                                        <Grid item xs={12} sm={7}>
                                                                            <Typography>
                                                                                <b>Ticket Expiry</b> <span
                                                                                className={classes.required}>*</span>
                                                                            </Typography>
                                                                            <MuiPickersUtilsProvider
                                                                                utils={DateFnsUtils}>
                                                                                <KeyboardDatePicker
                                                                                    autoOk
                                                                                    variant="inline"
                                                                                    inputVariant="outlined"
                                                                                    minDate={new Date()}
                                                                                    maxDate={endDateSelected ? endDateSelected : new Date()}
                                                                                    format="MM/dd/yyyy"
                                                                                    value={ticketExp[index] ? ticketExp[index] : endDateSelected}
                                                                                    onChange={(date) => this.handleTicketDateChange(date, index, 'ticketExp')}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </Grid>
                                                                    ) : (
                                                                        <Grid item xs={12} sm={7}>
                                                                            <Typography>
                                                                                <b>Ticket Expiry</b> <span
                                                                                className={classes.required}>*</span>
                                                                            </Typography>
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Select
                                                                                        value={ticketYearNP[index] ? ticketYearNP[index] : nepDate.year}
                                                                                        input={
                                                                                            <OutlinedInput
                                                                                                name="ticketYearNP"
                                                                                            />
                                                                                        }
                                                                                        onChange={this.ticketDateChange(index, 'ticketYearNP')}
                                                                                        fullWidth
                                                                                    >
                                                                                        {Object.keys(nepaliYearList.year).map(
                                                                                            (year) => <MenuItem
                                                                                                value={year}>{this.getNepaliDigits(year)}</MenuItem>
                                                                                        )}
                                                                                    </Select>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Select
                                                                                        value={ticketMonthNP[index] ? ticketMonthNP[index] : nepDate.month}
                                                                                        input={
                                                                                            <OutlinedInput
                                                                                                name="ticketMonthNP"
                                                                                            />
                                                                                        }
                                                                                        onChange={this.ticketDateChange(index, 'ticketMonthNP')}
                                                                                        fullWidth
                                                                                    >

                                                                                        {Object.keys(nepaliYearList.month).map(
                                                                                            (month, index) =>
                                                                                                index > 0 ?
                                                                                                    <MenuItem
                                                                                                        value={month}>{nepaliYearList.month[month]}</MenuItem> : ''
                                                                                        )}
                                                                                    </Select>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={4}>
                                                                                    <Select
                                                                                        value={ticketDayNP[index] ? ticketDayNP[index] : nepDate.day}
                                                                                        input={
                                                                                            <OutlinedInput
                                                                                                name="ticketDayNP"
                                                                                            />
                                                                                        }
                                                                                        onChange={this.ticketDateChange(index, 'ticketDayNP')}
                                                                                        fullWidth
                                                                                    >
                                                                                        {Object.keys(nepaliYearList[ticketDayListNP]).map(
                                                                                            (month) =>
                                                                                                <MenuItem
                                                                                                    value={nepaliYearList[ticketDayListNP][month]}>{this.getNepaliDigits(nepaliYearList[ticketDayListNP][month].toString())}</MenuItem>
                                                                                        )}
                                                                                    </Select>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                    <Grid item xs={12} sm={5}>
                                                                        <Typography>
                                                                            <b>End Time </b> <span
                                                                            className={classes.required}>*</span>
                                                                        </Typography>
                                                                        <TextField
                                                                            id="time"
                                                                            type="time"
                                                                            variant="outlined"
                                                                            value={ticketExpTime[index] ? ticketExpTime[index] : '23:59'}
                                                                            className={classes.textField}
                                                                            onChange={this.handleTicketTimeChange(index, 'ticketExpTime')}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            inputProps={{
                                                                                step: 300, // 5 min
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <div className={classes.simpleUnderline}></div>
                                                                    </Grid>
                                                                    <br/>
                                                                </Grid>
                                                            ))}
                                                            <Grid item xs={12} sm={12} style={{paddingTop: '10px'}}>
                                                                <Typography component="div">
                                                                <span className={classes.newLink}
                                                                      onClick={this.addTicket}>Add another ticket + </span>
                                                                </Typography>
                                                            </Grid>
                                                        </div>)}
                                                    </div>
                                                ) : (<div></div>)}
                                            </div>
                                        ) : (<div></div>)}
                                        <div className={classes.floatRight}>
                                            <Button variant="contained" color="secondary" className={classes.button}
                                                    onClick={() => this.createEvent()}
                                                    disabled={!eventHostName || !eventName || !startDateSelected || !endDateSelected || !startTime || !endTime || creatingEvent || !eventOrganizerPhone || !eventOrganizerEmail || !eventOrganizerName || !correctOrgEmail}
                                            >

                                                {creatingEvent ? (
                                                        <span>{isEditing ? 'Updating Event' : 'Creating Event'}&nbsp; &nbsp;
                                                            <CircularProgress
                                                                size={12}/> </span>) :
                                                    <span>{isEditing ? 'Update Event' : 'Create Event'}</span>}
                                            </Button>
                                        </div>
                                        <div className={classes.clearBoth}></div>
                                    </div>
                                </div>) : (
                                <div>
                                    {errGettingEvent === true ? 'Sorry, Cannot fetch event details.' : ''}
                                </div>
                            )}
                        </div>
                    ) : (<div>
                        <PleaseWait msg="fetching information... "/>
                    </div>)}
                    {/*Snack BAr Show*/}
                    <div>{this.state.snackOpen ? (
                        <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                                  snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                    ) : (<span></span>)}
                    </div>
                    {/*Edit Tickets*/}
                    {addTicket ? (
                        <div>
                            <AddTickets openDialog={addTicket} dialogHeader="Add New Ticket" ticketName='' ticketDesc=''
                                        dateType={dType} eventStartDate={startDateSelected}
                                        eventEndDate={endDateSelected}
                                        ticketPrice=''
                                        freeTicket={false}
                                        ticketExp={endDateSelected ? endDateSelected : new Date().setHours(23, 59, 0, 0)}
                                        maxTicket=''
                                        maxAllowedTicket=''
                                        allowedCurrency={allowedCurrency} sendCloseReport={this.getResFrmChild}
                                        type="add" groupId={this.props.match.params.groupId} eventId={toEdit}
                                        ticketId='' sendSuccessSave={this.getSuccessResponse}/>
                        </div>
                    ) : (<div></div>)}
                    {openTicket ? (
                        <div>
                            <AddTickets openDialog={openTicket} dialogHeader="Edit Ticket"
                                        dateType={dType}
                                        ticketName={ticketDetails.getTitle()}
                                        ticketDesc={ticketDetails.getDescription()}
                                        freeTicket={ticketDetails.getTicketType() === 'FREE_TICKET'}
                                        ticketPrice={ticketDetails.getPricingDetail().getPrice()}
                                        maxAllowedTicket={ticketDetails.getMaxPurchaseCount()}
                                        ticketExp={new Date(ticketDetails.getExpiration())}
                                        maxTicket={ticketDetails.getMaxAvailableLimit()}
                                        allowedCurrency={allowedCurrency}
                                        sendCloseReport={this.getResFrmChild} type="edit"
                                        groupId={this.props.match.params.groupId} eventId={toEdit}
                                        ticketId={ticketDetails.getTicketTypeId()}
                                        eventStartDate={startDateSelected} eventEndDate={endDateSelected}
                                        sendSuccessSave={this.getSuccessResponse}/>
                        </div>
                    ) : (<div></div>)}
                    {deletePopup ? (
                        <UserConfirmationDialog
                            openDialog={deletePopup}
                            dialogHeader="Delete Ticket"
                            dialogContent={"Are you sure want to delete " + ticketDetails.getTitle() + "?"}
                            button1="Cancel" button2="Delete"
                            sendUserResponse={this.getUserResponseFromChild}
                        />
                    ) : (<div></div>)}
                </div>
            </DashboardLayout>
        );
    }
}

AddEvent.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AddEvent);
