/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.com.hamropatro.everestbackend.account.BusinessInfo', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.Location', null, global);
goog.exportSymbol('proto.com.hamropatro.everestbackend.account.UserProfile', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.Location = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.Location.displayName = 'proto.com.hamropatro.everestbackend.account.Location';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.Location.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.Location.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.Location} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.Location.toObject = function (includeInstance, msg) {
        var f, obj = {
            lat: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
            lon: +jspb.Message.getFieldWithDefault(msg, 2, 0.0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.Location}
 */
proto.com.hamropatro.everestbackend.account.Location.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.Location;
    return proto.com.hamropatro.everestbackend.account.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.Location}
 */
proto.com.hamropatro.everestbackend.account.Location.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setLat(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setLon(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.Location.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.Location.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.Location.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLat();
    if (f !== 0.0) {
        writer.writeDouble(
            1,
            f
        );
    }
    f = message.getLon();
    if (f !== 0.0) {
        writer.writeDouble(
            2,
            f
        );
    }
};


/**
 * optional double lat = 1;
 * @return {number}
 */
proto.com.hamropatro.everestbackend.account.Location.prototype.getLat = function () {
    return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.everestbackend.account.Location.prototype.setLat = function (value) {
    jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double lon = 2;
 * @return {number}
 */
proto.com.hamropatro.everestbackend.account.Location.prototype.getLon = function () {
    return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.everestbackend.account.Location.prototype.setLon = function (value) {
    jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.UserProfile = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.everestbackend.account.UserProfile.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.UserProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.UserProfile.displayName = 'proto.com.hamropatro.everestbackend.account.UserProfile';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.everestbackend.account.UserProfile.repeatedFields_ = [10];


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.UserProfile.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.UserProfile.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.UserProfile} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.UserProfile.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            username: jspb.Message.getFieldWithDefault(msg, 2, ""),
            displayname: jspb.Message.getFieldWithDefault(msg, 3, ""),
            verified: jspb.Message.getFieldWithDefault(msg, 4, false),
            about: jspb.Message.getFieldWithDefault(msg, 5, ""),
            email: jspb.Message.getFieldWithDefault(msg, 6, ""),
            coverPhotoUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
            photoUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
            metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
            businessinfolistList: jspb.Message.toObjectList(msg.getBusinessinfolistList(),
                proto.com.hamropatro.everestbackend.account.BusinessInfo.toObject, includeInstance),
            suspended: jspb.Message.getFieldWithDefault(msg, 11, false),
            verifieddate: jspb.Message.getFieldWithDefault(msg, 12, 0),
            suspendeddate: jspb.Message.getFieldWithDefault(msg, 13, 0),
            admin: jspb.Message.getFieldWithDefault(msg, 14, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.UserProfile}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.UserProfile;
    return proto.com.hamropatro.everestbackend.account.UserProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.UserProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.UserProfile}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setUsername(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setDisplayname(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setVerified(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setAbout(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmail(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setCoverPhotoUrl(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setPhotoUrl(value);
                break;
            case 9:
                var value = msg.getMetadataMap();
                reader.readMessage(value, function (message, reader) {
                    jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
                });
                break;
            case 10:
                var value = new proto.com.hamropatro.everestbackend.account.BusinessInfo;
                reader.readMessage(value, proto.com.hamropatro.everestbackend.account.BusinessInfo.deserializeBinaryFromReader);
                msg.addBusinessinfolist(value);
                break;
            case 11:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSuspended(value);
                break;
            case 12:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setVerifieddate(value);
                break;
            case 13:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSuspendeddate(value);
                break;
            case 14:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setAdmin(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.UserProfile.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.UserProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.UserProfile.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getUsername();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getDisplayname();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getVerified();
    if (f) {
        writer.writeBool(
            4,
            f
        );
    }
    f = message.getAbout();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
    f = message.getEmail();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
        );
    }
    f = message.getCoverPhotoUrl();
    if (f.length > 0) {
        writer.writeString(
            7,
            f
        );
    }
    f = message.getPhotoUrl();
    if (f.length > 0) {
        writer.writeString(
            8,
            f
        );
    }
    f = message.getMetadataMap(true);
    if (f && f.getLength() > 0) {
        f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
    }
    f = message.getBusinessinfolistList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            10,
            f,
            proto.com.hamropatro.everestbackend.account.BusinessInfo.serializeBinaryToWriter
        );
    }
    f = message.getSuspended();
    if (f) {
        writer.writeBool(
            11,
            f
        );
    }
    f = message.getVerifieddate();
    if (f !== 0) {
        writer.writeInt64(
            12,
            f
        );
    }
    f = message.getSuspendeddate();
    if (f !== 0) {
        writer.writeInt64(
            13,
            f
        );
    }
    f = message.getAdmin();
    if (f) {
        writer.writeBool(
            14,
            f
        );
    }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.setUserId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getUsername = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.setUsername = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string displayName = 3;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getDisplayname = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.setDisplayname = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool verified = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getVerified = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.setVerified = function (value) {
    jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string about = 5;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getAbout = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.setAbout = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getEmail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.setEmail = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string cover_photo_url = 7;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getCoverPhotoUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.setCoverPhotoUrl = function (value) {
    jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string photo_url = 8;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getPhotoUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.setPhotoUrl = function (value) {
    jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * map<string, string> metadata = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getMetadataMap = function (opt_noLazyCreate) {
    return /** @type {!jspb.Map<string,string>} */ (
        jspb.Message.getMapField(this, 9, opt_noLazyCreate,
            null));
};


proto.com.hamropatro.everestbackend.account.UserProfile.prototype.clearMetadataMap = function () {
    this.getMetadataMap().clear();
};


/**
 * repeated BusinessInfo businessInfoList = 10;
 * @return {!Array<!proto.com.hamropatro.everestbackend.account.BusinessInfo>}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getBusinessinfolistList = function () {
    return /** @type{!Array<!proto.com.hamropatro.everestbackend.account.BusinessInfo>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.everestbackend.account.BusinessInfo, 10));
};


/** @param {!Array<!proto.com.hamropatro.everestbackend.account.BusinessInfo>} value */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.setBusinessinfolistList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessInfo}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.addBusinessinfolist = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.com.hamropatro.everestbackend.account.BusinessInfo, opt_index);
};


proto.com.hamropatro.everestbackend.account.UserProfile.prototype.clearBusinessinfolistList = function () {
    this.setBusinessinfolistList([]);
};


/**
 * optional bool suspended = 11;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getSuspended = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 11, false));
};


/** @param {boolean} value */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.setSuspended = function (value) {
    jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int64 verifiedDate = 12;
 * @return {number}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getVerifieddate = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.setVerifieddate = function (value) {
    jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 suspendedDate = 13;
 * @return {number}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getSuspendeddate = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.setSuspendeddate = function (value) {
    jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool admin = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.getAdmin = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.com.hamropatro.everestbackend.account.UserProfile.prototype.setAdmin = function (value) {
    jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.everestbackend.account.BusinessInfo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.everestbackend.account.BusinessInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.everestbackend.account.BusinessInfo.displayName = 'proto.com.hamropatro.everestbackend.account.BusinessInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.everestbackend.account.BusinessInfo.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.everestbackend.account.BusinessInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.everestbackend.account.BusinessInfo.toObject = function (includeInstance, msg) {
        var f, obj = {
            businessId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            logoUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
            role: jspb.Message.getFieldWithDefault(msg, 4, ""),
            verified: jspb.Message.getFieldWithDefault(msg, 5, false),
            suspended: jspb.Message.getFieldWithDefault(msg, 6, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessInfo}
 */
proto.com.hamropatro.everestbackend.account.BusinessInfo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.everestbackend.account.BusinessInfo;
    return proto.com.hamropatro.everestbackend.account.BusinessInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.everestbackend.account.BusinessInfo}
 */
proto.com.hamropatro.everestbackend.account.BusinessInfo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLogoUrl(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setRole(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setVerified(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSuspended(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.everestbackend.account.BusinessInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.everestbackend.account.BusinessInfo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBusinessId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getLogoUrl();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getRole();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getVerified();
    if (f) {
        writer.writeBool(
            5,
            f
        );
    }
    f = message.getSuspended();
    if (f) {
        writer.writeBool(
            6,
            f
        );
    }
};


/**
 * optional string business_id = 1;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.getBusinessId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.setBusinessId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.setName = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo_url = 3;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.getLogoUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.setLogoUrl = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string role = 4;
 * @return {string}
 */
proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.getRole = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.setRole = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool verified = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.getVerified = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.setVerified = function (value) {
    jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool suspended = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.getSuspended = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.com.hamropatro.everestbackend.account.BusinessInfo.prototype.setSuspended = function (value) {
    jspb.Message.setProto3BooleanField(this, 6, value);
};


goog.object.extend(exports, proto.com.hamropatro.everestbackend.account);
