import React, {Component} from "react";
import PropTypes from "prop-types";
import {OutlinedInput, withStyles} from "@material-ui/core";
import styles from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {AddTicketRequest, UpdateTicketRequest} from "../generated/frontend-community-ticket-service_pb";
import {TicketModel, TicketPricing} from "../generated/frontend-ticket-common-types_pb";
import {addNewTicketDetail, updateTicketDetails} from "../grpcRequests/tickets-request";
import Select from "@material-ui/core/Select";
import nepaliYearList from "../utils/converter/neplai_date";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

var adbs = require("../utils/converter/adToBs.js");

var date = new Date();
var nepDate = adbs.ad2bs(date.getFullYear() + '/' + (parseInt(date.getMonth()) + 1) + '/' + date.getDate()).en;


class AddTickets extends Component {

    state = {
        open: this.props.openDialog,
        dialogHeader: this.props.dialogHeader,
        freeTicket: this.props.freeTicket,
        ticketName: this.props.ticketName,
        ticketDesc: this.props.ticketDesc,
        ticketPrice: this.props.ticketPrice,
        ticketExp: this.props.ticketExp,
        ticketExpTime: this.props.ticketExp ? (("0" + new Date(this.props.ticketExp).getHours()).slice(-2) + ':' + ("0" + new Date(this.props.ticketExp).getMinutes()).slice(-2)) : "23:59",
        maxTicket: this.props.maxTicket,
        allowedCurrency: this.props.allowedCurrency,
        maxAllowedTicket: this.props.maxAllowedTicket,
        groupId: this.props.groupId,
        eventStartDate: this.props.eventStartDate,
        eventEndDate: this.props.eventEndDate,
        eventId: this.props.eventId,
        ticketId: this.props.ticketId,
        dateType: this.props.dateType,
        type: this.props.type,

        ticketYearNP: nepDate.year,
        ticketMonthNP: nepDate.month,
        ticketDayNP: nepDate.day,
        ticketDayListNP: 30,
        creatingTicket: false,

        errorMaxTicket: false,
        errorMessageMaxTicket: '',
        errorTicketPrice: false,
        errorMessageTicketPrice: '',
        errorMaxAllowedTicket: false,
        errorMessageMaxAllowedTicket: '',
    };

    componentDidMount() {
        if (this.props.dateType === 'bs') {
            let year = new Date(this.props.ticketExp).getFullYear();
            let month = new Date(this.props.ticketExp).getMonth() + 1;
            let date = new Date(this.props.ticketExp).getDate();

            let convertedDateToBs = adbs.ad2bs(year + '/' + month + '/' + date).en;
            this.setState({
                ticketYearNP: convertedDateToBs.year,
                ticketMonthNP: convertedDateToBs.month,
                ticketDayNP: convertedDateToBs.day,
            });
        }
    }

    validateFields = (fieldValue) => {
        let noOfTicket = fieldValue;
        let totalTicket;
        if (!noOfTicket) {
            return {
                'err': true,
                'msg': 'Not a valid number'
            }
        } else if (typeof noOfTicket !== 'number') {
            let result = (noOfTicket - Math.floor(noOfTicket)) !== 0;
            if (result) {
                return {
                    'err': true,
                    'msg': 'Should not contain decimal value'
                }
            } else if (Math.sign(noOfTicket) !== 1) {
                return {
                    'err': true,
                    'msg': 'Should not be negative'
                }
            } else {
                totalTicket = parseInt(noOfTicket);
                return {
                    'err': false,
                    'msg': '',
                    'newValue': totalTicket
                }
            }
        } else {
            totalTicket = noOfTicket;
            return {
                'err': false,
                'msg': '',
                'newValue': totalTicket
            }
        }
    };

    validatePrice = (isFreeTicket, priceInput) => {
        let inputPrice = priceInput;
        let totalPrice;
        if (isFreeTicket) {
            return {
                'err': false,
                'msg': '',
                'newValue': 0.00
            }
        }
        if (!inputPrice) {
            return {
                'err': true,
                'msg': 'Not a valid price'
            }
        } else if (typeof inputPrice !== 'number') {
            if (Math.sign(inputPrice) !== 1) {
                return {
                    'err': true,
                    'msg': 'Should not be negative'
                }
            } else {
                totalPrice = inputPrice;
                return {
                    'err': false,
                    'msg': '',
                    'newValue': totalPrice
                }
            }
        } else {
            totalPrice = inputPrice;
            return {
                'err': false,
                'msg': '',
                'newValue': totalPrice
            }
        }
    };

    handleSaveTicket = (type) => {
        this.setState({
            errorMaxTicket: false,
            errorTicketPrice: false,
        });
        // validate max ticket
        const validateMaxTicket = this.validateFields(this.state.maxTicket);
        if (validateMaxTicket.err) {
            this.setState({
                errorMaxTicket: validateMaxTicket.err,
                errorMessageMaxTicket: validateMaxTicket.msg,
            });
        } else {
            this.setState({
                maxTicket: validateMaxTicket.newValue
            });
        }
        //validate ticket price
        const validateTicketPrice = this.validatePrice(this.state.freeTicket, this.state.ticketPrice);
        if (validateTicketPrice.err) {
            this.setState({
                errorTicketPrice: validateTicketPrice.err,
                errorMessageTicketPrice: validateTicketPrice.msg,
            });
        } else {
            this.setState({
                ticketPrice: validateTicketPrice.newValue
            });
        }
        //validate maxTicketAllowed
        const validateMaxTicketAllowed = this.validateFields(this.state.maxAllowedTicket);
        if (validateMaxTicketAllowed.err) {
            this.setState({
                errorMaxAllowedTicket: validateMaxTicketAllowed.err,
                errorMessageMaxAllowedTicket: validateMaxTicketAllowed.msg,
            });
        } else {
            this.setState({
                maxAllowedTicket: validateMaxTicketAllowed.newValue
            });
        }

        if (!validateMaxTicket.err && !validateTicketPrice.err && !validateMaxTicketAllowed.err) {
            let self = this;

            let ticketExpiry = this.state.ticketExp;
            if (this.state.dateType === 'bs') {
                let convertedDate = adbs.bs2ad(this.state.ticketYearNP + '/' + this.state.ticketMonthNP + '/' + this.state.ticketDayNP);
                ticketExpiry = new Date(convertedDate.year, convertedDate.month - 1, convertedDate.day);
            }

            const ticketExpiryTime = this.state.ticketExpTime;
            var startHour = ticketExpiryTime.split(':')[0];
            var startMin = ticketExpiryTime.split(':')[1];
            var ticketExipireDateWithTime = new Date();
            ticketExipireDateWithTime.setFullYear(new Date(ticketExpiry).getFullYear());
            ticketExipireDateWithTime.setMonth(new Date(ticketExpiry).getMonth());
            ticketExipireDateWithTime.setDate(new Date(ticketExpiry).getDate());
            ticketExipireDateWithTime.setHours(startHour);
            ticketExipireDateWithTime.setMinutes(startMin);
            ticketExipireDateWithTime.setSeconds(0);
            ticketExipireDateWithTime.setMilliseconds(0);

            //    update ticket
            const ticketPrice = new TicketPricing();
            ticketPrice.setCurrency(this.state.allowedCurrency);
            ticketPrice.setPrice(this.state.freeTicket ? 0 : this.state.ticketPrice);

            const ticketInfo = new TicketModel();
            ticketInfo.setTitle(this.state.ticketName);
            ticketInfo.setTicketSourceId(this.state.eventId);
            ticketInfo.setDescription(this.state.ticketDesc);
            ticketInfo.setExpiration(ticketExipireDateWithTime ? new Date(ticketExipireDateWithTime).getTime() : new Date().getTime());
            ticketInfo.setMaxAvailableLimit(this.state.maxTicket);
            ticketInfo.setMaxPurchaseCount(this.state.maxAllowedTicket);
            ticketInfo.setPricingDetail(ticketPrice);

            ticketInfo.setTicketType(this.state.freeTicket ? "FREE_TICKET" : "EVENT_TICKET");
            ticketInfo.setOwnerBusinessId(this.state.groupId);
            if (type === 'add') {
                self.setState({
                    creatingTicket: true
                });
                const req = new AddTicketRequest();
                req.setTicket(ticketInfo);
                addNewTicketDetail(req).then(function (response) {
                    self.sendSuccessResponse();
                }).catch(function (error) {
                    self.setState({
                        creatingTicket: false
                    });
                })
            } else {
                self.setState({
                    creatingTicket: true
                });
                const req = new UpdateTicketRequest();
                ticketInfo.setTicketTypeId(this.state.ticketId);
                req.setTicket(ticketInfo);

                updateTicketDetails(req).then(function (response) {
                    self.sendSuccessResponse();
                }).catch(function (error) {

                    self.props.sendSuccessSave(false, self.state.type);
                    self.setState({
                        creatingTicket: false
                    });
                })
            }
        } else {

        }
    };

    getNepaliDigits = (digit) => {
        var nepDate = '';
        for (var i = 0; i < digit.length; i++) {
            nepDate = nepDate + String.fromCharCode(digit.charAt(i).charCodeAt(0) + 2358);
        }
        return nepDate;
    };


    handleClose = () => {
        this.sendResponse();
        this.setState({open: false});
    };

    sendResponse = () => {
        this.props.sendCloseReport(true);
    };

    sendSuccessResponse = () => {
        this.props.sendCloseReport(true);
        this.props.sendSuccessSave(true, this.state.type);
    };

    handleTicketChange = name => event => {
        if (name === 'freeTicket')
            this.setState({
                [name]: event.target.checked
            });
        else {
            this.setState({
                [name]: event.target.value,
                errorMaxTicket: false,
                errorTicketPrice: false,
            });
        }
    };

    handleTicketDateChange = (date, dateFor) => {
        this.setState({[dateFor]: date});
    };

    handleAdBSDateChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleTimeChange = timeFor => event => {
        this.setState({
            [timeFor]: event.target.value,
        });
    };

    render() {
        const {classes} = this.props;
        const {freeTicket, open, dialogHeader, ticketName, ticketDesc, ticketPrice, ticketExp, maxTicket, allowedCurrency, type, errorMaxTicket, errorMessageMaxTicket, errorMessageTicketPrice, errorTicketPrice, errorMaxAllowedTicket, errorMessageMaxAllowedTicket, maxAllowedTicket, dateType, ticketYearNP, ticketDayNP, ticketMonthNP, ticketDayListNP, ticketExpTime, eventStartDate, eventEndDate, creatingTicket} = this.state;
        return (
            <Dialog
                fullWidth
                open={open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="alert-dialog-title">{dialogHeader}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={3} style={{position: 'relative'}}>
                            <Grid item xs={12} sm={12}>
                                <Typography>
                                    <b>Ticket Name</b> <span
                                    className={classes.required}>*</span>
                                </Typography>
                                <TextField
                                    required
                                    variant="outlined"
                                    id="ticketName"
                                    name="ticketName"
                                    fullWidth
                                    value={ticketName}
                                    onChange={this.handleTicketChange('ticketName')}
                                    autoComplete="Ticket Name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography>
                                    <b>Ticket Description</b>
                                </Typography>
                                <TextField
                                    multiline
                                    required
                                    variant="outlined"
                                    id="ticketDesc"
                                    name="ticketDesc"
                                    fullWidth
                                    rows="3"
                                    rowsMax="25"
                                    value={ticketDesc}
                                    onChange={this.handleTicketChange('ticketDesc')}
                                    autoComplete="Ticket Description"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox color="secondary" name="freeTicket"
                                                       checked={freeTicket}
                                                       onChange={this.handleTicketChange('freeTicket')}/>}
                                    label="Free Ticket"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography>
                                    <b>Maximum Tickets</b> <span
                                    className={classes.required}>*</span>
                                </Typography>
                                <TextField
                                    required
                                    variant="outlined"
                                    id="maxTicket"
                                    name="maxTicket"
                                    type="number"
                                    min="0"
                                    fullWidth
                                    value={maxTicket}
                                    onChange={this.handleTicketChange('maxTicket')}
                                    autoComplete="Maximum Tickets"
                                />
                                {errorMaxTicket ? (<span style={{
                                    fontSize: '14px',
                                    paddingLeft: '5px',
                                    color: '#b71c1c'
                                }}>{errorMessageMaxTicket}</span>) : (<span></span>)}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    <b>Ticket Price</b> (per ticket) <span
                                    className={classes.required}>*</span>
                                </Typography>
                                <TextField
                                    required
                                    variant="outlined"
                                    id="ticketPrice"
                                    name="ticketPrice"
                                    fullWidth
                                    disabled={freeTicket}
                                    helperText={`Note: Price are set for ${allowedCurrency}`}
                                    value={freeTicket ? 0 : ticketPrice}
                                    onChange={this.handleTicketChange('ticketPrice')}
                                    autoComplete="Ticket Price"
                                    type="number"
                                    min="0"
                                />
                                {errorTicketPrice ? (<span style={{
                                    fontSize: '14px',
                                    paddingLeft: '5px',
                                    color: '#b71c1c'
                                }}>{errorMessageTicketPrice}</span>) : (<span></span>)}
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Typography>
                                    <b>Max Purchase</b> (per purchase) <span
                                    className={classes.required}>*</span>
                                </Typography>
                                <TextField
                                    required
                                    variant="outlined"
                                    id="ticketPrice"
                                    name="ticketPrice"
                                    fullWidth
                                    helperText={`Note: Allowed no of ticket while purchasing`}
                                    value={maxAllowedTicket}
                                    onChange={this.handleTicketChange('maxAllowedTicket')}
                                    autoComplete="Maximum ticket purchase"
                                    type="number"
                                    min="1"
                                />
                                {errorMaxAllowedTicket ? (<span style={{
                                    fontSize: '14px',
                                    paddingLeft: '5px',
                                    color: '#b71c1c'
                                }}>{errorMessageMaxAllowedTicket}</span>) : (<span></span>)}
                            </Grid>
                            {dateType === "ad" ? (
                                <Grid item xs={12} sm={8}>
                                    <Typography>
                                        <b>Ticket Expiry</b> <span
                                        className={classes.required}>*</span>
                                    </Typography>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            minDate={new Date()}
                                            inputVariant="outlined"
                                            format="MM/dd/yyyy"
                                            value={ticketExp}
                                            onChange={(date) => this.handleTicketDateChange(date, 'ticketExp')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            ) : (
                                <Grid item xs={12} sm={8}>
                                    <Typography>
                                        <b>Ticket Expiry </b> <span
                                        className={classes.required}>*</span>
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4}>
                                            <Select
                                                value={ticketYearNP}
                                                input={
                                                    <OutlinedInput
                                                        name="ticketYearNP"
                                                    />
                                                }
                                                onChange={this.handleAdBSDateChange('ticketYearNP')}
                                                fullWidth
                                            >

                                                {Object.keys(nepaliYearList.year).map(
                                                    (year) => <MenuItem
                                                        value={year}>{this.getNepaliDigits(year)}</MenuItem>
                                                )}

                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Select
                                                value={ticketMonthNP}
                                                input={
                                                    <OutlinedInput
                                                        name="ticketMonthNP"
                                                    />
                                                }
                                                onChange={this.handleAdBSDateChange('ticketMonthNP')}
                                                fullWidth
                                            >
                                                {Object.keys(nepaliYearList.month).map(
                                                    (month, index) =>
                                                        index > 0 ? <MenuItem
                                                            value={month}>{nepaliYearList.month[month]}</MenuItem> : ''
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Select
                                                value={ticketDayNP}
                                                input={
                                                    <OutlinedInput
                                                        name="ticketDayNP"
                                                    />
                                                }
                                                onChange={this.handleAdBSDateChange('ticketDayNP')}
                                                fullWidth
                                            >
                                                {Object.keys(nepaliYearList[ticketDayListNP]).map(
                                                    (month) =>
                                                        <MenuItem
                                                            value={nepaliYearList[ticketDayListNP][month]}>{this.getNepaliDigits(nepaliYearList[ticketDayListNP][month].toString())}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    <b>End Time </b> <span className={classes.required}>*</span>
                                </Typography>
                                <TextField
                                    id="time"
                                    type="time"
                                    variant="outlined"
                                    value={ticketExpTime}
                                    className={classes.textField}
                                    onChange={this.handleTimeChange("ticketExpTime")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {creatingTicket ? (
                        <div> Please Wait...<CircularProgress className={classes.progress} size={15}/>
                        </div>) : (
                        <span className={classes.clearBoth}>&nbsp;</span>)}
                    <Button color="primary" onClick={() => this.handleClose()}>
                        Cancel
                    </Button>
                    <Button onClick={() => this.handleSaveTicket(type)} color="primary" autoFocus
                            disabled={!ticketName || !maxTicket || (freeTicket ? false : !ticketPrice) || !ticketExp || creatingTicket}>
                        {type === 'add' ? 'Add' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

AddTickets.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AddTickets);