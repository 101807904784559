import axios from 'axios';

let APIURL = 'https://api.ipify.org/?format=json';
let getAddress = 'https://loc.hamro-files.com/mp/cc?ip=';


export function getUserLatLng() {
    return new Promise(function (resolve, reject) {
        let userInf = decodeURIComponent(atob(getCookie('c_info')));
        if (userInf && JSON.parse(userInf).lat && JSON.parse(userInf).lng) {
            resolve(JSON.parse(userInf));
        } else {
            getLatLong().then(function (res) {
                resolve(res);
            }).catch(function (error) {
                reject('');
            })
        }
    });
}

export function getLatLong() {
    return new Promise(function (resolve, reject) {
        let clientIP = decodeURIComponent(atob(getCookie('c_from')));
        if (clientIP) {
            axios.get(getAddress + clientIP).then(function (response) {
                if (response.data.latitude && response.data.longitude) {
                    let userJSON = {
                        "lat": response.data.latitude,
                        "lng": response.data.longitude,
                        "country": response.data.country_name,
                        "city": response.data.city
                    };
                    createCookie('c_info', JSON.stringify(userJSON), 1);
                    resolve(userJSON);
                } else
                    reject('');
            }).catch(function (error) {
                createCookie('c_info', '', -6);
                reject('');
            });
        } else {
            reject('');
            // initialize userIP;
            getClientIP();
        }
    });
}

export function getClientIP() {
    return new Promise(function (resolve, reject) {
        let clientIP = decodeURIComponent(atob(getCookie('c_from')));
        if (clientIP) {
            resolve(clientIP);
        } else {
            axios.get(APIURL).then(function (response) {
                if (response.data.ip) {
                    let userIp = response.data.ip;
                    createCookie('c_from', response.data.ip, 1);
                    resolve(userIp);
                } else reject('');
            }).catch(function (error) {
                createCookie('c_from', '', -5);
                reject('');
            });
        }
    });
}

function createCookie(cookieName, cookieValue, daysToExpire) {
    if (cookieValue) cookieValue = window.btoa(unescape(encodeURIComponent(cookieValue)));
    var date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    document.cookie = cookieName + "=" + cookieValue + "; expires=" + date.toGMTString();
}

function getCookie(cookieName) {
    var name = cookieName + "=";
    var allCookieArray = document.cookie.split(';');
    for (var i = 0; i < allCookieArray.length; i++) {
        var temp = allCookieArray[i].trim();
        if (temp.indexOf(name) == 0)
            return temp.substring(name.length, temp.length);
    }
    return "";
}