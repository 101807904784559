export default theme => ({
    root: {
        width: '350px',
        maxWidth: '100%',
        maxHeight: '350px',
        overflow: 'auto'
    },
    header: {
        padding: '15px 10px',
        // backgroundColor: theme.palette.background.default,
        // backgroundImage: 'url("/images/connected_world.svg")',
        // backgroundPositionX: 'right',
        // backgroundPositionY: 'center',
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'contain',
    },
    subtitle: {
        color: theme.palette.text.secondary
    },
    content: {},
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.default
        }
    },
    listItemIcon: {
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        padding: '10px',
        borderRadius: '50%',
        marginRight: theme.spacing(2)
    },
    listItemTextSecondary: {
        marignTop: '4px',
        color: theme.palette.text.secondary
    },
    arrowForward: {
        color: theme.palette.text.secondary,
        height: '16px',
        width: '16px'
    },
    footer: {
        paddingBottom: theme.spacing(),
        display: 'flex',
        justifyContent: 'center'
    },
    empty: {
        textAlign: 'center',
        padding: theme.spacing(2)
    },
    emptyImageWrapper: {
        marginBottom: theme.spacing(2)
    },
    emptyImage: {
        width: '240px',
        maxWidth: '100%',
        height: 'auto'
    },
    product: {
        padding: '15px 10px',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#e5e5e5',
            '& $productTitle': {
                color: theme.palette.primary.main,
            },
            '& $productImage': {
                opacity: 0.8
            },
        }
    },
    userImageWrapper: {
        borderRadius: '50%',
        overflow: 'hidden',
        height: '50px',
        width: '50px',
        border: '1px solid #e5e5e5'
    },
    productImage: {
        width: '100%',
        height: 'auto'
    },
    productDetails: {
        marginLeft: theme.spacing(2),
        flexGrow: 1
    },
    productTitle: {
        fontSize: '1em'
    },
    timeUUID: {
        color: '#757575',
        fontSize: '11px',
    },
    timeUUIDLink:{
        '&:hover': {
            textDecoration:'underline'
        }
    },
    notificationDesc: {
        color: theme.palette.text.secondary,
        lineHeight: 1.3,
        fontSize: '14px',
        paddingTop: '4px',
        clear: 'both',
        cursor:'pointer',
    }
});
