import React, {Component} from "react";
import PropTypes from "prop-types";
import {Grid, withStyles} from "@material-ui/core";
import {Dashboard as DashboardLayout} from 'layouts';
import styles from "./styles";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import Card from "@material-ui/core/Card";
import Icon from '@material-ui/core/Icon';
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import {GetUserGroupEventsRequest} from "../../generated/frontend-community-event-service_pb";
import {getUserGroupsEvents} from "../../grpcRequests/events-request";
import {CustomLoader} from "../../components/Skeleton/EventsSket";
import usedString, {getEventTypeStringInSentence, getUserResponseString} from "../../utils/mapper/enumToStringMapper";
import SnackBar from "../../components/SnackBar/SnackBar";
import {isLogin} from "../../configurations/global-config";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {formatDate} from "../../utils/converter/dateConverter";
import {eventAnalyst, pageViewAnalyst} from "../../configurations/config";
import {metaDataSet} from "../../utils/MetaData";
import NearByEvents from "../Groups/component/NearByEvents";
import SearchBar from "../../components/SearchBar/SearchBar";
import EventStickyInfo from "../Groups/component/StickyInfo/EventStickyInfo";

const defaultPageSize = 60;
let allLists = [];

class Events extends Component {
    state = {
        myEventLists: [],
        history: this.props.history, isLoadingEventList: false,
        cursor: '',
        previousCursor: '',

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null
    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    componentDidMount() {
        allLists = [];
        window.scrollTo(0, 0);
        metaDataSet('Community Events', '', window.location.href, '');
        pageViewAnalyst('Event', this.props.location.pathname);

        if (isLogin())
            this.fetchUpcomingEvents('', defaultPageSize, [], '');

    }

    searchedText = (text) => {
        let newList = [];
        if (text && text !== '') {
            let sortedList = allLists.filter(event => event.getEvent().getTitle().toLowerCase().includes(text));
            if (sortedList.length)
                newList = sortedList;
            else newList = [];
        } else newList = allLists;

        this.setState({
            myEventLists: newList
        });
    };

    loadMore = (cursorValue, pageSize, previousEvents, prevCursor) => {
        this.setState({loadingMore: true});
        this.fetchUpcomingEvents(cursorValue, pageSize, previousEvents, prevCursor)
    };

    fetchUpcomingEvents = (cursorValue, pageSize, previousEvents, prevCursor) => {
        let self = this;
        self.setState({
            isLoadingEventList: !prevCursor
        });
        const req = new GetUserGroupEventsRequest();
        req.setYear(new Date().getFullYear());
        req.setCursor(cursorValue);
        req.setPageSize(pageSize);
        getUserGroupsEvents(req, 3).then(function (response) {
            eventAnalyst('events', 'fetch_upcoming_events', 'Upcoming event fetch success', 'upcoming-event-fetch-success');
            let events = previousEvents;
            for (let i = 0; i < response.getEventDetailList().length; i++) {
                let newEvent = response.getEventDetailList()[i];
                events.push(newEvent);
            }
            const cursor = response.getNextPageCursor();
            const isLoadingEventList = false;
            allLists = events;
            self.setState({
                myEventLists: events,
                isLoadingEventList,
                previousCursor: prevCursor,
                cursor,
                loadingMore: false
            });
        }).catch(function (error) {
            eventAnalyst('events', 'fetch_upcoming_events', 'Upcoming event fetch fail', 'upcoming-event-fetch-fail');
            const isLoadingEventList = false;
            self.setState({
                isLoadingEventList,
            });
            self.snackbar(true, 'Got Error While fetching User Events', 'error', 5000)
        })
    };


    render() {
        const {classes} = this.props;
        const {myEventLists, isLoadingEventList, cursor, previousCursor, loadingMore} = this.state;
        const rootGroupsURl = '/c/';
        return (
            <DashboardLayout title="Events">
                <div style={{padding: '1px 16px'}}>
                    <NearByEvents history={this.props}/>
                </div>
                <div className={classes.root}>
                    {!isLoadingEventList ? (
                        <div>
                            <div className={classes.boxItems}>
                                <div>
                                    <div className={classes.floatLeft}>
                                        <Typography
                                            variant="h6"
                                            style={{fontWeight: 900}}
                                        >
                                            <span>Events in your communities</span>
                                        </Typography>
                                    </div>
                                    <div>
                                        <div className={classes.floatRight}>
                                            {allLists.length > 1 ?
                                                <SearchBar sendSearchText={this.searchedText}/>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.simpleUnderline}></div>
                                <br/>
                                {myEventLists.length > 0 ? (
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        {myEventLists.map(event => (
                                            <Grid item xs={12} md={4} sm={4} lg={6} xl={4}
                                                  key={event.getEvent().getEventId()}>
                                                <Card className={classes.singleCardEvent}>
                                                    <EventStickyInfo event={event.getEvent()}/>
                                                    <Link
                                                        to={`${rootGroupsURl}${event.getEvent().getGroupId()}/events/${event.getEvent().getEventId()}`}
                                                        onClick={() => eventAnalyst('events_card_click', event.getEvent().getTitle() + '_clicked', 'Events-' + event.getEvent().getTitle(), event.getEvent().getTitle() + '-detail-fetch-success')}>
                                                        <div className={classes.groupDetailImageWrapper}>
                                                            <CardMedia
                                                                className={classes.mediaEvent}
                                                                alt={event.getEvent().getTitle()}
                                                                image={event.getEvent().getEventCoverImage() ? event.getEvent().getEventCoverImage() : '/images/eventDefault.png'}
                                                                title={event.getEvent().getTitle()}
                                                            />
                                                        </div>
                                                    </Link>
                                                    <CardContent className={classes.upComingEventsWrapper}>
                                                        <div className={classes.groupName}>
                                                            <Link
                                                                to={`${rootGroupsURl}${event.getEvent().getGroupId()}/events/${event.getEvent().getEventId()}`}
                                                                onClick={() => eventAnalyst('events_card_click', event.getEvent().getTitle() + '_clicked', 'Events-' + event.getEvent().getTitle(), event.getEvent().getTitle() + '-detail-fetch-success')}>
                                                                <Typography
                                                                    component="div"
                                                                    className={classes.name}>
                                                                <span>
                                                                    {event.getEvent().getTitle()}
                                                                </span>
                                                                </Typography>
                                                            </Link>
                                                        </div>
                                                        <Typography variant="body2" className={classes.count}>
                                                            <span>{formatDate(event.getEvent().getStartFrom(), 'MMM dd, p')}</span>
                                                            {getEventTypeStringInSentence(event.getEvent().getEventStatus()) === usedString.Open || getEventTypeStringInSentence(event.getEvent().getEventStatus()) === usedString.Postponed ? (
                                                                <span>
                                                            {getUserResponseString(event.getUserAttendingStatus()) === usedString.GOING ? (
                                                                <div className={classes.goingEvent}
                                                                     title="You are going to this event">
                                                                    <Icon className={classes.icon} color="primary">
                                                                        check_circle
                                                                    </Icon>
                                                                </div>
                                                            ) : (<span></span>)}
                                                                    {getUserResponseString(event.getUserAttendingStatus()) === usedString.INTERESTED ? (
                                                                        <div className={classes.goingEvent}
                                                                             title="You are Interested to this event">
                                                                            <Icon className={classes.icon}
                                                                                  color="primary">
                                                                                stars
                                                                            </Icon>
                                                                        </div>
                                                                    ) : (<span></span>)}
                                                             </span>
                                                            ) : null}
                                                        </Typography>
                                                    </CardContent>
                                                    <div className={classes.topBarWrapper}>
                                                        <div className={classes.createdBy} title="Hosted By">
                                                            <Link
                                                                to={`${rootGroupsURl}${event.getEvent().getGroupId()}`}>
                                                                {event.getEvent().getMetaDataMap().get('event_hosted_by') ? event.getEvent().getMetaDataMap().get('event_hosted_by') : event.getEvent().getMetaDataMap().get('OWNER_GROUP') ? JSON.parse(event.getEvent().getMetaDataMap().get('OWNER_GROUP')).accountName : event.getEvent().getOwnerInfo().getAccountName()}
                                                            </Link>
                                                        </div>
                                                        {/*<div className={classes.daysRemain}*/}
                                                        {/*     title="Event Time">{timeEvent(new Date(event.getEvent().getStartFrom()))}</div>*/}
                                                    </div>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                ) : null}
                                {cursor && previousCursor !== cursor ? (
                                    <div>
                                        <Button vavariant="contained" size="medium" color="secondary" fullWidth
                                                style={{margin: '15px 0'}}
                                                onClick={() => this.loadMore(cursor, defaultPageSize, allLists, cursor)}>
                                            {loadingMore ? (<span> Loading <CircularProgress size={15}/></span>) : (
                                                <span>Load more</span>)}
                                        </Button>
                                    </div>
                                ) : (<span></span>)}
                            </div>
                        </div>
                    ) : (<CustomLoader/>)}
                </div>
                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
            </DashboardLayout>

        )
    }

}


Events.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Events);