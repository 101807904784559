/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
goog.object.extend(proto, frontend$social$common$types_pb);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.EventNotificationRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.EventNotificationResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.PushCountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.PushCountResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.PushLog', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.PushLogForContentRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.PushLogRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.PushLogResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.EventNotificationRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.EventNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.EventNotificationRequest.displayName = 'proto.com.hamropatro.sociallayer.io.EventNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.EventNotificationResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.EventNotificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.EventNotificationResponse.displayName = 'proto.com.hamropatro.sociallayer.io.EventNotificationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.PushLogRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.PushLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.PushLogRequest.displayName = 'proto.com.hamropatro.sociallayer.io.PushLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.PushLogResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.PushLogResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.PushLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.PushLogResponse.displayName = 'proto.com.hamropatro.sociallayer.io.PushLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.PushCountRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.PushCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.PushCountRequest.displayName = 'proto.com.hamropatro.sociallayer.io.PushCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.PushCountResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.PushCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.PushCountResponse.displayName = 'proto.com.hamropatro.sociallayer.io.PushCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.PushLogForContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.displayName = 'proto.com.hamropatro.sociallayer.io.PushLogForContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.PushLog = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.PushLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.PushLog.displayName = 'proto.com.hamropatro.sociallayer.io.PushLog';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.EventNotificationRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.EventNotificationRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.EventNotificationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.EventNotificationRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      eventId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      message: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.EventNotificationRequest}
 */
proto.com.hamropatro.sociallayer.io.EventNotificationRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.EventNotificationRequest;
  return proto.com.hamropatro.sociallayer.io.EventNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.EventNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.EventNotificationRequest}
 */
proto.com.hamropatro.sociallayer.io.EventNotificationRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.EventNotificationRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.EventNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.EventNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.EventNotificationRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.EventNotificationRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.EventNotificationRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.EventNotificationRequest.prototype.getEventId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.EventNotificationRequest.prototype.setEventId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.EventNotificationRequest.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.EventNotificationRequest.prototype.setMessage = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.EventNotificationResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.EventNotificationResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.EventNotificationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.EventNotificationResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      started: jspb.Message.getFieldWithDefault(msg, 1, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.EventNotificationResponse}
 */
proto.com.hamropatro.sociallayer.io.EventNotificationResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.EventNotificationResponse;
  return proto.com.hamropatro.sociallayer.io.EventNotificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.EventNotificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.EventNotificationResponse}
 */
proto.com.hamropatro.sociallayer.io.EventNotificationResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setStarted(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.EventNotificationResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.EventNotificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.EventNotificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.EventNotificationResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getStarted();
  if (f) {
    writer.writeBool(
        1,
        f
    );
  }
};


/**
 * optional bool started = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.EventNotificationResponse.prototype.getStarted = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.EventNotificationResponse.prototype.setStarted = function (value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.PushLogRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.PushLogRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.PushLogRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.PushLogRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
      cursor: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.PushLogRequest}
 */
proto.com.hamropatro.sociallayer.io.PushLogRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.PushLogRequest;
  return proto.com.hamropatro.sociallayer.io.PushLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.PushLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.PushLogRequest}
 */
proto.com.hamropatro.sociallayer.io.PushLogRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCursor(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.PushLogRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.PushLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.PushLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.PushLogRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        2,
        f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushLogRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushLogRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.PushLogRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.PushLogRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string cursor = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushLogRequest.prototype.getCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushLogRequest.prototype.setCursor = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.PushLogResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.PushLogResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.PushLogResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.PushLogResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.PushLogResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      pushlogsList: jspb.Message.toObjectList(msg.getPushlogsList(),
          proto.com.hamropatro.sociallayer.io.PushLog.toObject, includeInstance),
      pushCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      nextPageCursor: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.PushLogResponse}
 */
proto.com.hamropatro.sociallayer.io.PushLogResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.PushLogResponse;
  return proto.com.hamropatro.sociallayer.io.PushLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.PushLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.PushLogResponse}
 */
proto.com.hamropatro.sociallayer.io.PushLogResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.com.hamropatro.sociallayer.io.PushLog;
        reader.readMessage(value, proto.com.hamropatro.sociallayer.io.PushLog.deserializeBinaryFromReader);
        msg.addPushlogs(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setPushCount(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextPageCursor(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.PushLogResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.PushLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.PushLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.PushLogResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPushlogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        proto.com.hamropatro.sociallayer.io.PushLog.serializeBinaryToWriter
    );
  }
  f = message.getPushCount();
  if (f !== 0) {
    writer.writeInt64(
        2,
        f
    );
  }
  f = message.getNextPageCursor();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
};


/**
 * repeated PushLog pushLogs = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.PushLog>}
 */
proto.com.hamropatro.sociallayer.io.PushLogResponse.prototype.getPushlogsList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.PushLog>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.PushLog, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.PushLog>} value */
proto.com.hamropatro.sociallayer.io.PushLogResponse.prototype.setPushlogsList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.PushLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.PushLog}
 */
proto.com.hamropatro.sociallayer.io.PushLogResponse.prototype.addPushlogs = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.PushLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.PushLogResponse.prototype.clearPushlogsList = function () {
  this.setPushlogsList([]);
};


/**
 * optional int64 push_count = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.PushLogResponse.prototype.getPushCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.PushLogResponse.prototype.setPushCount = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string next_page_cursor = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushLogResponse.prototype.getNextPageCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushLogResponse.prototype.setNextPageCursor = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.PushCountRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.PushCountRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.PushCountRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.PushCountRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.PushCountRequest}
 */
proto.com.hamropatro.sociallayer.io.PushCountRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.PushCountRequest;
  return proto.com.hamropatro.sociallayer.io.PushCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.PushCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.PushCountRequest}
 */
proto.com.hamropatro.sociallayer.io.PushCountRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.PushCountRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.PushCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.PushCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.PushCountRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushCountRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushCountRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.PushCountResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.PushCountResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.PushCountResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.PushCountResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      role: jspb.Message.getFieldWithDefault(msg, 2, ""),
      count: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.PushCountResponse}
 */
proto.com.hamropatro.sociallayer.io.PushCountResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.PushCountResponse;
  return proto.com.hamropatro.sociallayer.io.PushCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.PushCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.PushCountResponse}
 */
proto.com.hamropatro.sociallayer.io.PushCountResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setRole(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setCount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.PushCountResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.PushCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.PushCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.PushCountResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
        3,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushCountResponse.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushCountResponse.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string role = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushCountResponse.prototype.getRole = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushCountResponse.prototype.setRole = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 count = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.PushCountResponse.prototype.getCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.PushCountResponse.prototype.setCount = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.PushLogForContentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      contentType: jspb.Message.getFieldWithDefault(msg, 3, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
      cursor: jspb.Message.getFieldWithDefault(msg, 5, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.PushLogForContentRequest}
 */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.PushLogForContentRequest;
  return proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.PushLogForContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.PushLogForContentRequest}
 */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setContentId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setContentType(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCursor(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.PushLogForContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getContentType();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        4,
        f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        5,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.prototype.getContentId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.prototype.setContentId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content_type = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.prototype.getContentType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.prototype.setContentType = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string cursor = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.prototype.getCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushLogForContentRequest.prototype.setCursor = function (value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.PushLog.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.PushLog.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.PushLog} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.PushLog.toObject = function (includeInstance, msg) {
    var f, obj = {
      appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      contentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
      contentType: jspb.Message.getFieldWithDefault(msg, 4, ""),
      message: jspb.Message.getFieldWithDefault(msg, 5, ""),
      user: (f = msg.getUser()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
      role: jspb.Message.getFieldWithDefault(msg, 7, ""),
      sendTime: jspb.Message.getFieldWithDefault(msg, 8, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.PushLog}
 */
proto.com.hamropatro.sociallayer.io.PushLog.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.PushLog;
  return proto.com.hamropatro.sociallayer.io.PushLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.PushLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.PushLog}
 */
proto.com.hamropatro.sociallayer.io.PushLog.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAppId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setContentId(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setContentType(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      case 6:
        var value = new frontend$social$common$types_pb.AccountInfo;
        reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
        msg.setUser(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setRole(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setSendTime(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.PushLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.PushLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.PushLog.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getContentType();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
        5,
        f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
        6,
        f,
        frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
        7,
        f
    );
  }
  f = message.getSendTime();
  if (f !== 0) {
    writer.writeInt64(
        8,
        f
    );
  }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.getAppId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.setAppId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.getContentId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.setContentId = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string content_type = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.getContentType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.setContentType = function (value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string message = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.setMessage = function (value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AccountInfo user = 6;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.getUser = function () {
  return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
      jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 6));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.setUser = function (value) {
  jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.clearUser = function () {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.hasUser = function () {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string role = 7;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.getRole = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.setRole = function (value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 send_time = 8;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.getSendTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.PushLog.prototype.setSendTime = function (value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
