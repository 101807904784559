import React, {useEffect, useState} from 'react';
import {
    GetTicketPaymentForEventRequest,
    TicketStatusUpdateRequest
} from "../../../generated/frontend-community-ticket-service_grpc_web_pb";
import {TicketStatus} from "../../../generated/frontend-ticket-common-types_pb";
import {getUsersForEvent, updateUserTickets} from "../../../grpcRequests/tickets-request";
import {makeStyles} from "@material-ui/styles";
import {formatDate} from "../../../utils/converter/dateConverter";
import Button from "@material-ui/core/Button";
import ExportIcon from "@material-ui/icons/CloudQueue";
import DownloadCSV from "@material-ui/icons/CloudDownload";
import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";

import MaterialTable from "material-table";

const defaultPageSize = 50;

const useStyles = makeStyles(theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function CheckInDetails(props) {
    const [ticketData, setTicketData] = React.useState('');
    const [tableData, setTableData] = React.useState([]);
    const [dataToExport, setDataToExport] = React.useState([]);
    const [eventId, setEventId] = React.useState(props.eventId);
    const [singleTicketData, setSingleTicketData] = React.useState([]);
    const [userData, setUserData] = React.useState('');
    const [checkedInBy, setTicketChecker] = React.useState('');
    const [ticketStatus, setTicketStatus] = React.useState('');
    const [loadingMore, setLoadingMore] = React.useState(false);
    const [progressMap, setUpdateTicket] = React.useState(new Map());
    const [updated, setUpdated] = React.useState(false);
    const [eventTicketDetails, setEventDetails] = React.useState(props.eventTicketDetails);
    const [cursor, setCursor] = useState('');
    const [previousCursor, setPreviousCursor] = useState('');
    const [nameOfBuyer, setnameOfBuyer] = useState('');
    const [isLoadingTickets, setIsLoadingTickets] = useState(true);
    const {className, ...rest} = props;
    const classes = useStyles();

    useEffect(() => {
        fetchUserDetails([], '', defaultPageSize, '');
    }, []);

    function onchange(e) {
        setnameOfBuyer(e.target.value)
    }


    function getMoreUsers(previousData, cursor, pageSize, PreviousCursor) {
        setLoadingMore(true);
        fetchUserDetails(previousData, cursor, pageSize, PreviousCursor)
    }


    function changeChecked(userId, ticketStatus, ticketType, ticketId, index) {
        // tableData.inprogress=true;
        // setTableData(tableData)
        progressMap.set(index, true);
        setUpdateTicket(progressMap);
        setUpdated(true)

        let statusType;
        if (ticketStatus === 'USED')
            statusType = TicketStatus.UNUSED;
        else statusType = TicketStatus.USED;

        let ticketIds = [];
        ticketIds.push(ticketId);

        const req = new TicketStatusUpdateRequest();
        req.setTicketSourceId(eventId);
        req.setUserId(userId);
        req.setTicketTypeId(ticketType);
        req.setTicketIdList(ticketIds);
        req.setStatusToUpdate(statusType);

        updateUserTickets(req).then(function (response) {
            setTicketStatus(ticketStatus === 'USED' ? 'UNUSED' : 'USED');
            // console.log('checked');
            // console.log(index);
            // console.log(ticketId);

            fetchUserDetails([], '', defaultPageSize, '');
            progressMap.set(index, false);
            setUpdateTicket(progressMap);


        }).catch(function (error) {


        })


    }


    function fetchUserDetails(previousData, cursor, noOfFetch, PreviousCursor) {

        const req = new GetTicketPaymentForEventRequest();
        req.setTicketSourceId(eventId);
        req.setCursor(cursor);
        req.setPageSize(noOfFetch);
        getUsersForEvent(req, 3).then(function (response) {
            let newData = previousData;
            response.getTicketPaymentList().forEach(function (ticket) {
                newData.push(ticket);
            });
            if (response.getNextPageCursor() && PreviousCursor !== response.getNextPageCursor()) {
                fetchUserDetails(newData, response.getNextPageCursor(), defaultPageSize, response.getNextPageCursor())
            } else {
                setTableData(newData);


                var dataToExport = [];
                newData.forEach(function (data, $index) {
                    dataToExport.push({
                        SN: $index + 1,
                        Name: data.getBuyerInfo().getName(),
                        Email: data.getBuyerInfo().getEmail(),
                        Phone_No: data.getBuyerInfo().getPhone() ? data.getBuyerInfo().getPhone() : '-',
                        Type: data.getTicketName(),
                        Confirmation_No: `${data.getTicketid()}`,
                        Date_Of_Purchase: `${formatDate(data.getPaymentTime(), 'MMMM dd yyyy p')}`,
                        Status: `${data.getStatus()}`,
                        Description: data.getMetaDataMap().get('payee_description') ? data.getMetaDataMap().get('payee_description') : '-'
                    })

                });
                setDataToExport(dataToExport);
            }


        }).catch(function (error) {
            setIsLoadingTickets(false);
        })
    }

    function getAllDatas() {
        var tabledatas = [];
        if(tableData && tableData.length )
            tableData.forEach(function (data, $index) {
                tabledatas.push({
                    SN: $index + 1,
                    name: data.getBuyerInfo().getName(),
                    email: data.getBuyerInfo().getEmail(),
                    phoneNo: data.getBuyerInfo().getPhone() ? data.getBuyerInfo().getPhone() : '-',
                    type: data.getTicketName(),
                    confirmationNo: `${data.getTicketid()}`,
                    dateOfPurchase: `${formatDate(data.getPaymentTime(), 'MMMM dd, yyyy p')}`,
                    ticketUsed: `${data.getStatus()}`,
                    checkIn: <div><Button variant="contained"
                                          color={data.getStatus() === 'USED' ? "default" : "secondary"}
                                          onClick={() => {
                                              changeChecked(data.getUserId(), data.getStatus(), data.getTicketTypeId(), data.getTicketid(), $index)
                                          }}
                                          fullWidth
                                          style={{fontSize: '11px', fontWeight: 600, width: '100px'}}
                                          className={classNames(data.getStatus() === 'USED' ? 'tikUsed' : 'tikNotUsed')}>
                                                <span
                                                    className={classNames('hoverText')}>{data.getStatus() === 'USED' ? 'Checked' : 'Check In'}</span>{progressMap.get($index) ?
                        <CircularProgress style={{color:"white"}} size={12}/> : ''}
                    </Button>
                        {data.getStatus() === 'USED' && data.getCheckedInBy() ? (
                            <div style={{fontSize: '11px', marginTop: '5px'}}>Checked in
                                by {data.getCheckedInBy().getAccountName()}</div>
                        ) : null}
                    </div>

                });

            });
        return tabledatas;

    }

    return (
        <div style={{overflowX: 'auto'}}>
            {fetchUserDetails()}
            <div style={{marginTop: 10, marginBottom: 10}}>
                <MaterialTable
                    columns={[
                        {title: 'S.N', field: 'SN', type: 'numeric', export: false, grouping: false},
                        {title: 'Name', field: 'name'},
                        {title: 'Email', field: 'email', hidden: true, export: true},
                        {title: 'Phone No', field: 'phoneNo', hidden: true, export: true},
                        {title: 'Type', field: 'type'},
                        {title: 'Confirmation No', field: 'confirmationNo', cellStyle: {fontSize: 15}, grouping: false},
                        {
                            title: 'Date Of Purchase', field: 'dateOfPurchase', cellStyle: {
                                fontSize: 12, defaultSort: "desc"
                            }
                        },
                        {
                            title: 'Ticket Status', field: 'ticketUsed', cellStyle: {
                                fontSize: 12
                            }
                        },
                        {
                            title: 'Check In/Out', field: 'checkIn', export: false, sorting: false, grouping: false
                        },

                    ]}
                    data={getAllDatas()}
                    icons={{
                        Export: (tableData && tableData.length) ? DownloadCSV : ExportIcon
                    }
                    }
                    localization={
                        {
                            body: {
                                emptyDataSourceMessage: (tableData && tableData.length) ? '' : <CircularProgress></CircularProgress>,
                            },
                            toolbar: {
                                exportAriaLabel: (tableData && tableData.length) ? 'Export' : 'Loading Data...',
                                exportName: (tableData && tableData.length) ? 'Export CSV' : 'Please Wait.Loading data...'
                            },

                        }

                    }
                    options={{
                        exportButton: true,
                        grouping: true,
                        pageSize: 15,
                        maxBodyHeight: '380vh',
                        exportAllData: true,
                        pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                        groupRowSeparator: ':-',
                        showTitle: false,
                        loadingType: "linear",

                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF',
                            fontSize: 15,
                        },
                        rowStyle: {
                            backgroundColor: '#EEE',
                            textAlign: 'left',
                        },
                        exportCsv: (columns, data) => {
                            var data, filename, link;

                            var csv = convertArrayOfObjectsToCSV({
                                data: dataToExport
                            });

                            function convertArrayOfObjectsToCSV(args) {
                                var result, ctr, keys, columnDelimiter, lineDelimiter, data;

                                data = args.data || null;
                                if (data == null || !data.length) {
                                    return null;
                                }

                                columnDelimiter = args.columnDelimiter || ',';
                                lineDelimiter = args.lineDelimiter || '\n';

                                keys = Object.keys(data[0]);

                                result = '';
                                result += keys.join(columnDelimiter);
                                result += lineDelimiter;

                                data.forEach(function (item) {
                                    ctr = 0;
                                    keys.forEach(function (key) {
                                        if (ctr > 0) result += columnDelimiter;

                                        result += item[key];
                                        ctr++;
                                    });
                                    result += lineDelimiter;
                                });

                                return result;
                            }


                            if (csv == null) return;

                            filename = 'Ticket Details.csv';

                            if (!csv.match(/^data:text\/csv/i)) {
                                csv = 'data:text/csv;charset=utf-8,' + csv;
                            }
                            data = encodeURI(csv);

                            link = document.createElement('a');
                            link.setAttribute('href', data);
                            link.setAttribute('download', filename);
                            link.click();
                        },

                    }}
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => fetchUserDetails([], '', defaultPageSize, ''),
                        }
                    ]}
                />
            </div>


        </div>

    );
}