import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material components
import {withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styling
import styles from './styles';
//Content loader
//GRPC setting
import {isLogin} from "../../configurations/global-config";
import {pageViewAnalyst} from "../../configurations/config";
import {metaDataSet} from "../../utils/MetaData";
import NearByCommunity from "../Groups/component/NearByCommunity";
import NearByEvents from "../Groups/component/NearByEvents";
import SuggestedCommunity from "../Groups/component/SuggestedCommunity";
import PendingCommunity from "../Groups/component/PendingCommunity";
import MyCommunity from "../Groups/component/MyCommunity";
import UpcomingEvents from "../Groups/component/UpcomingEvents";

class Home extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        metaDataSet('', '', window.location.href, '');
        pageViewAnalyst('Home', this.props.location.pathname);
    }

    render() {
        const {classes} = this.props;
        return (
            <DashboardLayout title="Hamro Patro Community">
                <div className={classes.root}>
                    <NearByCommunity history={this.props}/>

                    <NearByEvents history={this.props}/>

                    <SuggestedCommunity history={this.props}/>

                    {isLogin() ? (
                        <div>
                            <MyCommunity history={this.props}/>
                            <PendingCommunity history={this.props}/>
                            <UpcomingEvents history={this.props}/>
                        </div>
                    ) : null}

                </div>
            </DashboardLayout>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);
