import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styles
import styles from './styles';
import {CommunityServiceClient} from '../../generated/frontend-community-group-service_grpc_web_pb'
import {checkIfLogin} from "../../configurations/global-config";
import {pageViewAnalyst} from "../../configurations/config";
// import grpc from 'grpc';

const srv = new CommunityServiceClient('http://127.0.0.1:9080');

class Channels extends Component {
    state = {
        response: null,
    };

    componentDidMount() {
        pageViewAnalyst('Channels', this.props.location.pathname);
        checkIfLogin(this.props.location.pathname);
    }


    render() {
        const {classes} = this.props;

        return (
            <DashboardLayout title="Channels">
                <div className={classes.root}>
                </div>
            </DashboardLayout>
        );
    }
}

Channels.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Channels);
