import {fill} from "../../common/colors";

export default theme => ({
    chatBox: {
        background: fill.white,
        margin: 'auto 5px',
        boxShadow: 'none',
        display: 'flex',
    },
    editChatBox: {
        background: '#f4f6f7',
        margin: 'auto 5px',
        boxShadow: 'none',
        display: 'flex',
    },
    postContent: {
        width: '100%',
        padding: '2px 5px'
    },
    floatRight: {
        float: 'right'
    },
    floatLeft: {
        float: 'left'
    },
    chatBoxAvatar: {
        height: 30,
        width: 30,
    },
    commentClass: {
        color: '#338cd9',
        margin: '5px',
        padding: '5px',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    listCoverComment: {
        background: '#f4f6f700',
        padding: '2px 5px',
        borderRadius: '5px'
    },
    commentPost: {
        color: fill.textColor,
        fontSize: '15px',
        lineHeight: 1.5,
        marginTop: '5px'
    },
    moreVertPost: {
        float: 'right'
    },
    timeUUID: {
        color: fill.timeColor,
        fontSize: '12px',
    },
    commentWrapper: {
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    iconWrapper: {
        padding: '3px 5px',
        width: '60px'
    },
    progress: {
        margin: '0px 7px'
    },
    buttonWrapper:{
        textAlign:'right',
        paddingRight:'10px'
    },
    postButton:{
        width: 'fit-content'
    },
    closeEditBox: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});