// Material helpers
import {createMuiTheme} from '@material-ui/core';

import palette from './palette';

const theme = createMuiTheme({
    palette,
    typography: {
        fontFamily: '"Nunito", serif',
        fontStyle: 'normal',
        lineHeight: '1.5',
        color: '#000000',
    },
    zIndex: {
        appBar: 900,
        drawer: 900
    },
    body: {
        fontFamily: '"Nunito", serif',
    }
});

export default theme;
