export default theme => ({
    root: {
        padding: theme.spacing(1),
        minHeight: '85vh'
    },
    floatRight: {
        float: 'right'
    },
    clearBoth: {
        clear: 'both'
    },
    formWrapper: {
        borderBottom: '1px solid #e5e5e5',
        padding: '10px 5px',
        margin: '10px 5px'
    },
    formWrapperAddition: {
        padding: '10px 5px',
        margin: '10px 5px'
    },
    eventTitle: {
        color: 'brown'
    },
    imageWrapper: {
        width: '150px',
        overflow: 'hidden',
        borderRadius: '5px',
        cursor: 'pointer',
        border: '1px dotted #e5e5e59e',
    },
    imageToUpload: {
        width: '100%'
    },
    required: {
        marginRight: '2px',
        marginLeft: '2px',
        color: '#af3d30',
        fontSize: '13px'
    },
    timeZone: {
        marginRight: '2px',
        marginLeft: '2px',
        color: '#1f89ff',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    anotherTicket: {
        marginRight: '2px',
        marginLeft: '2px',
        color: '#af3d30',
        fontSize: '13px',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    newLink: {
        marginRight: '2px',
        marginLeft: '2px',
        color: '#1f89ff',
        fontSize: '13px',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    simpleUnderline: {
        paddingTop: '5px',
        clear: 'both',
        borderBottom: '1px solid #e5e5e5'
    },
    inviteButton: {
        float: 'right',
        width: 'fit-content !important',
        fontSize: '12px',
        padding: '7px 30px !important',
        boxShadow: 'none',
        color: '#ffffff',
        background: '#3b5998',
        '&:hover': {
            background: '#3b5998',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    ticketListWrapper: {
        borderTop: '1px solid #ffffff',
        padding: '10px',
        background: 'aliceblue',
        '&:hover': {
            background: '#efefef'
        }
    },
    ticketItems: {
        padding: '10px 5px'
    },
    ticketNameList: {
        fontSize: '15px', fontWeight: 600
    },
    userTicketCount: {
        fontSize: '11px', color: '#3377e0', textDecoration: 'underline'
    },
    availableTicketDesc: {
        fontSize: '13px', fontWeight: 600, color: '#616263'
    },
    ticketAmount: {
        fontSize: '14px', fontWeight: 600
    },
});
