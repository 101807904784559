export default theme => ({
    topbar: {
        position: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
        right: 'auto',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    topbarShift: {
        position: 'fixed',
        width: '40%',
        marginLeft: '30%',
        marginRight: '30%',
        top: 0,
        left: 0,
        right: 'auto',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    drawerPaper: {
        overflow:'auto',
        zIndex: 900,
        width: '30%',
        backgroundColor: theme.palette.background.sideBar,
        borderRight: 'none',
        borderLeft: 'none'
    },
    drawerPaperMobile: {
        zIndex: 900,
        width: 'auto%',
        backgroundColor: theme.palette.background.default,
        borderRight: 'none',
        borderLeft: 'none'
    },
    sidebar: {
        width: 'fit-content',
        margin: 'auto'
    },
    sidebarMobile: {
        width: 'fit-content',
        margin: 'auto',
        paddingLeft: '10%'
    },
    sidebarRight: {
        width: 'fit-content',
    },
    content: {
        marginTop: '64px',
        backgroundColor: theme.palette.background.default,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    contentShift: {
        marginTop: '64px',
        marginLeft: '30%',
        marginRight: '30%',
        width: '40%',
        backgroundColor: theme.palette.background.default,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    }
});
