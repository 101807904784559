import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles, withWidth} from "@material-ui/core";
import styles from "./styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {
    CommentOnContentRequest,
    GetCommentRequest,
    ListCommentsRequest
} from "../../generated/frontend-comment-service_pb";
import {createCommentOnPost, getCommentDetails, getPostComments} from "../../grpcRequests/comments-request";
import {CommentMessage, MessageType} from "../../generated/frontend-social-common-types_pb";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import {checkIfLogin, isAdmin, isLogin, userInfo, userWhoCanPost} from "../../configurations/global-config";
import InputBase from "@material-ui/core/InputBase";
import {Send as SendIcon} from "@material-ui/icons";
import UserCommentMenus from "../UserCommentMenus";
import SnackBar from "../SnackBar/SnackBar";
import {timeEvent, timeRemaining} from "../../utils/converter/dateConverter";
import CircularProgress from "@material-ui/core/CircularProgress";
import {eventAnalyst} from "../../configurations/config";
import usedString from "../../utils/mapper/enumToStringMapper";
import LoginRequiredDialog from "../Dialogs/LoginRequiredDialog";
import UserReply from "../ReplyBox/UserReply";
import compose from "recompose/compose";
import {Link} from "react-router-dom";
import RenderText from "../../pages/Groups/component/RenderText";

const defaultPageSize = 10;

class GetUserComments extends Component {
    state = {
        userCommentText: [],
        isLoadingComment: true,
        allComments: [],
        disable: [],
        multiline: [],
        loading: false,
        cursor: '',
        previousCursor: '',
        showComment: false,

        userComments: [],
        commentPostID: [],
        userCommentLists: [],

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null,

        //Login
        openLoginDialog: false,
        openJoinGroupDialog: false,
    };

    sendAction = (action, postID) => {
        this.props.sendResponse(action, true, postID);
    };

    componentDidMount() {
        if (this.props.type === 'event') {
            this.viewComment(this.props.commentPostId, '', defaultPageSize, [], '')
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.type === 'event' && nextProps.commentPostId !== this.props.commentPostId) {
            this.viewComment(nextProps.commentPostId, '', defaultPageSize, [], '')
        }
    }

    getLoginDialogRes = (clickedButton, key) => {
        this.setState({openLoginDialog: false});
        if (clickedButton === "login") {
            checkIfLogin(window.location.pathname);
        }
    };

    openLoginDialog = () => {
        this.setState({openLoginDialog: true});
    };

    canInteractInGroup= () => {
        let userRole = this.props.userRole;
        let groupRoleStatus = this.props.userRoleStatus;
        return userWhoCanPost(userRole, groupRoleStatus);
    };

    getJoinGroupDialogRes = (clickedButton, key) => {
        this.setState({openJoinGroupDialog: false});
        if (clickedButton === "view community") {
            // console.log('clicked join');
            window.location.pathname = '/c/' + this.props.groupId
        }
    };

    openJoinDialog = () => {
        this.setState({openJoinGroupDialog: true});
    };

    getUserResponseFromUserComment = (actionName, isActionCompleted, postId, commentKey,indexVal) => {
        this.setState({snackOpen: false});
        let self = this;
        if (isActionCompleted && actionName === 'delete') {
            eventAnalyst('community_comment', 'community_comment_delete', postId + '-comment-deleted', postId + '-delete-success');
            self.snackbar(true, 'Comment deleted Successfully', 'success', 5000);
            self.sendAction('delete', postId);
            const allComments = this.state.allComments.filter(i => i.getCommentId() !== commentKey);
            this.setState({allComments});
        }
        else if (isActionCompleted && actionName === 'edited') {
            const allComments = this.state.allComments.filter(i => i.getCommentId() !== commentKey);
            this.setState({allComments});
            const req = new GetCommentRequest();
            req.setPostUri(postId);
            req.setCommentId(commentKey);
            getCommentDetails(req, 3).then(function (response) {
                let currentComment = response.getCommentdetail().getComment();
                let userPost=self.state.allComments;
                // userPost.push(currentPosts);
                userPost.splice(indexVal, 0, currentComment);
                self.setState({
                    allComments: userPost
                });
            }).catch(function (error) {
            })

        }
        else {
            this.snackbar(true, 'Something went wrong', 'error', 5000);
        }
    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    submitComment = (postID) => {
        if (!isLogin()) {
            this.openLoginDialog()
        }
        else if(!this.canInteractInGroup()){
            this.openJoinDialog()
        }
        else {
            let self = this;
            self.setState({disable: {...self.state.disable, [postID]: true}, snackOpen: false});
            const req = new CommentOnContentRequest();
            const commentObj = new CommentMessage();
            commentObj.setText(this.state.userCommentText[postID]);

            req.setPostUri(postID);
            // req.setCommentId('');
            req.setComment(commentObj);
            req.setCommentType(MessageType.TEXT);
            //req.setBusinessAccountInfo(accountInfo); // only used when the user is logged in to business account
            createCommentOnPost(req).then(function (response) {
                eventAnalyst('community_comment', 'community_comment_create', postID + '-comment-created', postID + '-create-success');
                self.setState({
                    userCommentText: {...self.state.userCommentText, [postID]: ''},
                    disable: {...self.state.disable, [postID]: false},
                    multiline: {...self.state.multiline, [postID]: true},
                });
                self.sendAction('create', postID);
                self.viewComment(postID, '', defaultPageSize, [], '');
            }).catch(function (error) {
                eventAnalyst('community_comment', 'community_comment_create', postID + '-comment-failed', postID + '-create-fail');
                self.setState({
                    disable: {...self.state.disable, [postID]: false},
                    multiline: {...self.state.multiline, [postID]: true},
                });
                self.snackbar(true, 'Something Went Wrong', 'error', 5000);
            })
        }
    };

    viewComment = (postID, pageToken, pageSize, previousComment, prevCursor) => {
        if (!isLogin() && prevCursor) {
            this.openLoginDialog()
        }
        else if(!this.canInteractInGroup() && prevCursor){
            this.openJoinDialog()
        }else {
            let self = this;
            self.setState({
                loading: true,
                userComments: [],
                showComment: true
            });
            const req = new ListCommentsRequest();
            req.setPostUri(postID);
            req.setPageSize(pageSize);
            req.setPageToken(pageToken);

            getPostComments(req, 3).then(function (response) {
                self.sendAction('view', postID);
                self.setState({
                    showComment: true
                });
                eventAnalyst('community_comment', 'community_comment_load', postID + '-comment-loaded', postID + '-load-success');
                const cursor = response.getNextPageToken();
                let comments = previousComment;
                for (let i = 0; i < response.getCommentsList().length; i++) {
                    let newComments = response.getCommentsList()[i];
                    comments.push(newComments);
                }
                const isLoadingComment = false;
                const loading = false;
                self.setState({
                    allComments: comments,
                    previousCursor: prevCursor,
                    cursor,
                    isLoadingComment,
                    loading
                });
            }).catch(function (error) {
                eventAnalyst('community_comment', 'community_comment_load', postID + '-comment-fail', postID + '-load-fail');
                const loading = false;
                self.setState({
                    loading
                });
            })
        }
    };

    postCommentChange = (postId, event) => {
        this.setState({
            userCommentText: {...this.state.userCommentText, [postId]: event.target.value}
        });
    };

    handleKeyPress = (postId, event) => {
        if (!isLogin()) {
            this.openLoginDialog()
        }
        else if(!this.canInteractInGroup()){
            this.openJoinDialog()
        }
        else {
            if (!event.shiftKey && event.key === 'Enter') {
                this.setState({
                    multiline: {...this.state.multiline, [postId]: false},
                });
                if (this.state.userCommentText[postId] && this.state.userCommentText[postId].trim().length !== 0) {
                    this.submitComment(postId);
                }
            }
        }
    };

    render() {
        const {classes, commentPostId, userRole, viewComments, width, groupId, postTitle, totalComments, groupName, userRoleStatus, type} = this.props;
        const {allComments, isLoadingComment, userCommentText, disable, loading, cursor, previousCursor, openLoginDialog, openJoinGroupDialog} = this.state;
        const isMobile = ['xs', 'sm', 'md'].includes(width);
        return (
            <div>
                <div>
                    <Paper className={classes.chatBox}>
                        <IconButton
                            size="small"
                            className={classes.floatLeft}
                            aria-label="Menu">
                            <Avatar
                                alt={userInfo().displayName}
                                style={{
                                    backgroundImage: `url('${userInfo().photo_url}')`,
                                    backgroundRepeat: 'round',
                                }}
                                className={classes.chatBoxAvatar}/>
                        </IconButton>
                        <InputBase
                            disabled={disable[commentPostId]}
                            className={classes.postContent}
                            placeholder="Comment on this post"
                            multiline={true}
                            inputProps={{'aria-label': 'Add Comment'}}
                            value={userCommentText[commentPostId]}
                            onChange={this.postCommentChange.bind(this, commentPostId)}
                            onKeyPress={this.handleKeyPress.bind(this, commentPostId)}
                        />
                        <div className={classes.iconWrapper}>
                            {userCommentText[commentPostId] ? (
                                <IconButton
                                    size="small"
                                    disabled={disable[commentPostId] || userCommentText[commentPostId].trim().length === 0}
                                    className={classes.floatRight}
                                    onClick={() => this.submitComment(commentPostId)}
                                    aria-label="Send">
                                    <SendIcon/>
                                </IconButton>
                            ) : (<span></span>)}
                        </div>
                    </Paper>
                </div>
                <div>
                    {commentPostId && !isLoadingComment && allComments.length > 0 ? (
                        <div>
                            <div className={classes.commentClass}></div>
                            {allComments.map((comments,index) => (
                                commentPostId === comments.getPostUri() ? (
                                    <ListItem alignItems="flex-start" className={classes.commentWrapper}
                                              style={isMobile ? {
                                                  display: "inline-block", paddingTop: '1px',
                                                  paddingBottom: '1px'
                                              } : {
                                                  display: "flex", paddingTop: '1px',
                                                  paddingBottom: '1px'
                                              }}>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={comments.getAccountInfo().getAccountName()}
                                                style={isMobile ? {display: 'none'} : {
                                                    backgroundImage: `url('${comments.getAccountInfo().getAccountImage() ? comments.getAccountInfo().getAccountImage().getImageUrl() : ''}')`,
                                                    backgroundRepeat: 'round',
                                                    margin: 'auto',
                                                    marginTop: '5px'
                                                }}
                                                className={classes.chatBoxAvatar}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            className={classes.listCoverComment}
                                            primary={
                                                <React.Fragment>
                                                    <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                                        <Avatar
                                                            alt={comments.getAccountInfo().getAccountName()}
                                                            style={isMobile ? {
                                                                backgroundImage: `url('${comments.getAccountInfo().getAccountImage() ? comments.getAccountInfo().getAccountImage().getImageUrl() : ''}')`,
                                                                backgroundRepeat: 'round',
                                                                width: '25px',
                                                                height: '25px',
                                                                marginRight: '8px'
                                                            } : {display: 'none'}}
                                                        />
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            className={classes.inline}
                                                            color="textPrimary"
                                                            style={{fontWeight: 600, lineHeight: 1.2}}
                                                        >
                                                            {comments.getAccountInfo().getAccountName()}
                                                            <Link
                                                                to={type === 'event' ? `/c/${groupId}/p/${comments.getPostUri()}/${comments.getCommentId()}/?title=${postTitle}&eventId=${commentPostId}` : `/c/${groupId}/p/${comments.getPostUri()}/${comments.getCommentId()}/?title=${postTitle}`}>
                                                                <div className={classes.timeUUID}><span
                                                                    title={new Date(comments.getCommentTimestamp()).toLocaleString()}>{timeRemaining(new Date(comments.getCommentTimestamp()))}</span>
                                                                    <span
                                                                        title={timeEvent(comments.getCommentTimestamp())}
                                                                        className={classes.postTime}>{comments.getEdited() ? '(edited)' : null}</span>
                                                                </div>
                                                            </Link>
                                                        </Typography>
                                                    </div>
                                                    {comments.getAccountInfo().getAccountId() === userInfo().user_id || isAdmin() || userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT ? (
                                                        <UserCommentMenus commentID={comments.getCommentId()}
                                                                          postID={commentPostId}
                                                                          commentText={comments.getComment().getText()}
                                                                          ownerID={comments.getAccountInfo().getAccountId()}
                                                                          userRole={userRole}
                                                                          indexValue={index}
                                                                          sendUserResponse={this.getUserResponseFromUserComment}
                                                        />) : (<span>&nbsp;</span>)}
                                                </React.Fragment>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        component="div"
                                                        className={classes.commentPost}
                                                        color="textPrimary"
                                                        variant={"body2"}
                                                        style={{
                                                            // fontWeight: 600,
                                                            whiteSpace: 'pre-line'
                                                        }}
                                                    >
                                                        <RenderText text={comments.getComment().getText()}/>
                                                    </Typography>
                                                    <div>
                                                        <UserReply
                                                            groupName={groupName}
                                                            groupId={groupId}
                                                            userRole={userRole}
                                                            userRoleStatus={userRoleStatus}
                                                            commentId={comments.getCommentId()}
                                                            commentPostId={comments.getPostUri()}
                                                            commentLikes={comments.getLikes()}
                                                            commentReplies={comments.getReplies()}
                                                            userLikeSts={comments.getLiked()}/>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                ) : null
                            ))}
                        </div>
                    ) : (<div>
                        {!isLoadingComment && allComments.length <= 0 ? (
                            <Typography
                                component="div"
                                variant={"body2"}
                                style={{
                                    marginTop: '7px'
                                }}
                            >
                                No comments yet. Be the first one to comment.
                            </Typography>
                        ) : (<span></span>)}
                    </div>)}
                    {cursor && previousCursor !== cursor ? (
                        <div className={classes.commentClass}
                             onClick={() => this.viewComment(commentPostId, cursor, defaultPageSize, allComments, cursor)}>
                            <span>Load More Comments</span>
                            {loading ? (
                                <CircularProgress className={classes.progress} size={12}/>
                            ) : (<span></span>)}
                        </div>
                    ) : (<span></span>)}
                    {/*<span>{viewComments ? 'true' : 'false' }{totalComments}</span>*/}
                    {viewComments && totalComments > 3 ? (
                        <div className={classes.commentClass}
                             onClick={() => this.viewComment(commentPostId, '', defaultPageSize, [], '')}>
                            <span>View All Comments</span>
                            {loading ? (
                                <CircularProgress className={classes.progress} size={12}/>
                            ) : (<span></span>)}
                        </div>
                    ) : <div></div>}
                </div>
                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
                <div>
                    {openLoginDialog ? (
                        <LoginRequiredDialog
                            openDialog={openLoginDialog} dialogHeader=""
                            postId="124"
                            dialogContent={"Please login to continue"} button1="cancel"
                            button2="login"
                            sendUserResponse={this.getLoginDialogRes}
                        />
                    ) : (<div></div>)}
                </div>
                <div>
                    {openJoinGroupDialog ? (
                        <LoginRequiredDialog
                            openDialog={openJoinGroupDialog} dialogHeader=""
                            postId="124"
                            dialogContent={"You are not associated with '"+groupName+"'. Please join the community to continue and start posting to the community."}
                            button1="cancel"
                            button2="view community"
                            sendUserResponse={this.getJoinGroupDialogRes}
                        />
                    ) : (<div></div>)}
                </div>
            </div>
        )
    }
}

GetUserComments.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withWidth())(GetUserComments);