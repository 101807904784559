export default theme => ({
    eventLists: {
        display: 'flex',
        padding: '10px',
        margin: '5px 0',
        borderBottom: '1px solid #e5e5e5',
        '&:hover': {
            background: '#f4f6f7',
            cursor: 'pointer'
        }
    },
    dateWrapper: {
        width: '100px',
        textAlign: 'center',
        borderRight: '1px solid #e5e5e5'
    },
    eventWrapper: {
        width: '90%',
        padding: '0 10px'
    },
    monthEvent: {
        fontWeight: '900',
        fontSize: '14px',
        padding: '0 5px'
    },
    dateEvent: {
        fontWeight: '900',
        fontSize: '20px',
        padding: '0 5px'
    },
    dayEvent: {
        fontSize: '12px',
        fontWeight: '900'
    },
    eventTitle: {
        fontWeight: '900'
    },
    location: {
        fontSize: '14px',
    },
    timeRemaining: {
        textAlign: 'right',
        fontSize: '12px',
        padding: '0 5px'
    },
    linkTO: {
        color: 'unset'
    },
    holiday: {
        color: '#b71c1c'
    }
});