/**
 * @fileoverview gRPC-Web generated client stub for io
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.io = require('./frontend-everestdb-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.io.EverestDbServiceClient =
    function (hostname, credentials, options) {
      if (!options) options = {};
      options['format'] = 'text';

      /**
       * @private @const {!grpc.web.GrpcWebClientBase} The client
       */
      this.client_ = new grpc.web.GrpcWebClientBase(options);

      /**
       * @private @const {string} The hostname
       */
      this.hostname_ = hostname;

      /**
       * @private @const {?Object} The credentials to be used to connect
       *    to the server
       */
      this.credentials_ = credentials;

      /**
       * @private @const {?Object} Options for the client
       */
      this.options_ = options;
    };


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.io.EverestDbServicePromiseClient =
    function (hostname, credentials, options) {
      if (!options) options = {};
      options['format'] = 'text';

      /**
       * @private @const {!grpc.web.GrpcWebClientBase} The client
       */
      this.client_ = new grpc.web.GrpcWebClientBase(options);

      /**
       * @private @const {string} The hostname
       */
      this.hostname_ = hostname;

      /**
       * @private @const {?Object} The credentials to be used to connect
       *    to the server
       */
      this.credentials_ = credentials;

      /**
       * @private @const {?Object} Options for the client
       */
      this.options_ = options;
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.GetEverestObjectRequest,
 *   !proto.io.EverestObject>}
 */
const methodDescriptor_EverestDbService_GetEverestObject = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/GetEverestObject',
    grpc.web.MethodType.UNARY,
    proto.io.GetEverestObjectRequest,
    proto.io.EverestObject,
    /** @param {!proto.io.GetEverestObjectRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.EverestObject.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.GetEverestObjectRequest,
 *   !proto.io.EverestObject>}
 */
const methodInfo_EverestDbService_GetEverestObject = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.EverestObject,
    /** @param {!proto.io.GetEverestObjectRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.EverestObject.deserializeBinary
);


/**
 * @param {!proto.io.GetEverestObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.EverestObject)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.EverestObject>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.getEverestObject =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/GetEverestObject',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_GetEverestObject,
          callback);
    };


/**
 * @param {!proto.io.GetEverestObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.EverestObject>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.getEverestObject =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/GetEverestObject',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_GetEverestObject);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.ListEverestObjectsRequest,
 *   !proto.io.ListEverestObjectsResponse>}
 */
const methodDescriptor_EverestDbService_ListEverestObjects = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/ListEverestObjects',
    grpc.web.MethodType.UNARY,
    proto.io.ListEverestObjectsRequest,
    proto.io.ListEverestObjectsResponse,
    /** @param {!proto.io.ListEverestObjectsRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.ListEverestObjectsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.ListEverestObjectsRequest,
 *   !proto.io.ListEverestObjectsResponse>}
 */
const methodInfo_EverestDbService_ListEverestObjects = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.ListEverestObjectsResponse,
    /** @param {!proto.io.ListEverestObjectsRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.ListEverestObjectsResponse.deserializeBinary
);


/**
 * @param {!proto.io.ListEverestObjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.ListEverestObjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.ListEverestObjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.listEverestObjects =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/ListEverestObjects',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_ListEverestObjects,
          callback);
    };


/**
 * @param {!proto.io.ListEverestObjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.ListEverestObjectsResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.listEverestObjects =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/ListEverestObjects',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_ListEverestObjects);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.CreateEverestObjectRequest,
 *   !proto.io.CreateEverestObjectResponse>}
 */
const methodDescriptor_EverestDbService_CreateEverestObject = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/CreateEverestObject',
    grpc.web.MethodType.UNARY,
    proto.io.CreateEverestObjectRequest,
    proto.io.CreateEverestObjectResponse,
    /** @param {!proto.io.CreateEverestObjectRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.CreateEverestObjectResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.CreateEverestObjectRequest,
 *   !proto.io.CreateEverestObjectResponse>}
 */
const methodInfo_EverestDbService_CreateEverestObject = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.CreateEverestObjectResponse,
    /** @param {!proto.io.CreateEverestObjectRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.CreateEverestObjectResponse.deserializeBinary
);


/**
 * @param {!proto.io.CreateEverestObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.CreateEverestObjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.CreateEverestObjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.createEverestObject =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/CreateEverestObject',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_CreateEverestObject,
          callback);
    };


/**
 * @param {!proto.io.CreateEverestObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.CreateEverestObjectResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.createEverestObject =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/CreateEverestObject',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_CreateEverestObject);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.UpdateEverestObjectRequest,
 *   !proto.io.UpdateEverestObjectResponse>}
 */
const methodDescriptor_EverestDbService_UpdateEverestObject = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/UpdateEverestObject',
    grpc.web.MethodType.UNARY,
    proto.io.UpdateEverestObjectRequest,
    proto.io.UpdateEverestObjectResponse,
    /** @param {!proto.io.UpdateEverestObjectRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.UpdateEverestObjectResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.UpdateEverestObjectRequest,
 *   !proto.io.UpdateEverestObjectResponse>}
 */
const methodInfo_EverestDbService_UpdateEverestObject = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.UpdateEverestObjectResponse,
    /** @param {!proto.io.UpdateEverestObjectRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.UpdateEverestObjectResponse.deserializeBinary
);


/**
 * @param {!proto.io.UpdateEverestObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.UpdateEverestObjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.UpdateEverestObjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.updateEverestObject =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/UpdateEverestObject',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_UpdateEverestObject,
          callback);
    };


/**
 * @param {!proto.io.UpdateEverestObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.UpdateEverestObjectResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.updateEverestObject =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/UpdateEverestObject',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_UpdateEverestObject);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.DeleteEverestObjectRequest,
 *   !proto.io.DeleteEverestObjectResponse>}
 */
const methodDescriptor_EverestDbService_DeleteEverestObject = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/DeleteEverestObject',
    grpc.web.MethodType.UNARY,
    proto.io.DeleteEverestObjectRequest,
    proto.io.DeleteEverestObjectResponse,
    /** @param {!proto.io.DeleteEverestObjectRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.DeleteEverestObjectResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.DeleteEverestObjectRequest,
 *   !proto.io.DeleteEverestObjectResponse>}
 */
const methodInfo_EverestDbService_DeleteEverestObject = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.DeleteEverestObjectResponse,
    /** @param {!proto.io.DeleteEverestObjectRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.DeleteEverestObjectResponse.deserializeBinary
);


/**
 * @param {!proto.io.DeleteEverestObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.DeleteEverestObjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.DeleteEverestObjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.deleteEverestObject =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/DeleteEverestObject',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_DeleteEverestObject,
          callback);
    };


/**
 * @param {!proto.io.DeleteEverestObjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.DeleteEverestObjectResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.deleteEverestObject =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/DeleteEverestObject',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_DeleteEverestObject);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.GetEverestCounterRequest,
 *   !proto.io.GetEverestCounterResponse>}
 */
const methodDescriptor_EverestDbService_GetEverestCounter = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/GetEverestCounter',
    grpc.web.MethodType.UNARY,
    proto.io.GetEverestCounterRequest,
    proto.io.GetEverestCounterResponse,
    /** @param {!proto.io.GetEverestCounterRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.GetEverestCounterResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.GetEverestCounterRequest,
 *   !proto.io.GetEverestCounterResponse>}
 */
const methodInfo_EverestDbService_GetEverestCounter = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.GetEverestCounterResponse,
    /** @param {!proto.io.GetEverestCounterRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.GetEverestCounterResponse.deserializeBinary
);


/**
 * @param {!proto.io.GetEverestCounterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.GetEverestCounterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.GetEverestCounterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.getEverestCounter =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/GetEverestCounter',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_GetEverestCounter,
          callback);
    };


/**
 * @param {!proto.io.GetEverestCounterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.GetEverestCounterResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.getEverestCounter =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/GetEverestCounter',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_GetEverestCounter);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.GetEverestCounterListRequest,
 *   !proto.io.GetEverestCounterListResponse>}
 */
const methodDescriptor_EverestDbService_GetEverestCounterList = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/GetEverestCounterList',
    grpc.web.MethodType.UNARY,
    proto.io.GetEverestCounterListRequest,
    proto.io.GetEverestCounterListResponse,
    /** @param {!proto.io.GetEverestCounterListRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.GetEverestCounterListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.GetEverestCounterListRequest,
 *   !proto.io.GetEverestCounterListResponse>}
 */
const methodInfo_EverestDbService_GetEverestCounterList = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.GetEverestCounterListResponse,
    /** @param {!proto.io.GetEverestCounterListRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.GetEverestCounterListResponse.deserializeBinary
);


/**
 * @param {!proto.io.GetEverestCounterListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.GetEverestCounterListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.GetEverestCounterListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.getEverestCounterList =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/GetEverestCounterList',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_GetEverestCounterList,
          callback);
    };


/**
 * @param {!proto.io.GetEverestCounterListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.GetEverestCounterListResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.getEverestCounterList =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/GetEverestCounterList',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_GetEverestCounterList);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.IncrementCountRequest,
 *   !proto.io.IncrementCountResponse>}
 */
const methodDescriptor_EverestDbService_IncrementCounter = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/IncrementCounter',
    grpc.web.MethodType.UNARY,
    proto.io.IncrementCountRequest,
    proto.io.IncrementCountResponse,
    /** @param {!proto.io.IncrementCountRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.IncrementCountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.IncrementCountRequest,
 *   !proto.io.IncrementCountResponse>}
 */
const methodInfo_EverestDbService_IncrementCounter = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.IncrementCountResponse,
    /** @param {!proto.io.IncrementCountRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.IncrementCountResponse.deserializeBinary
);


/**
 * @param {!proto.io.IncrementCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.IncrementCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.IncrementCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.incrementCounter =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/IncrementCounter',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_IncrementCounter,
          callback);
    };


/**
 * @param {!proto.io.IncrementCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.IncrementCountResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.incrementCounter =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/IncrementCounter',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_IncrementCounter);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.GetEverestUniqueCounterRequest,
 *   !proto.io.GetEverestUniqueCounterResponse>}
 */
const methodDescriptor_EverestDbService_GetUniqueCounter = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/GetUniqueCounter',
    grpc.web.MethodType.UNARY,
    proto.io.GetEverestUniqueCounterRequest,
    proto.io.GetEverestUniqueCounterResponse,
    /** @param {!proto.io.GetEverestUniqueCounterRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.GetEverestUniqueCounterResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.GetEverestUniqueCounterRequest,
 *   !proto.io.GetEverestUniqueCounterResponse>}
 */
const methodInfo_EverestDbService_GetUniqueCounter = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.GetEverestUniqueCounterResponse,
    /** @param {!proto.io.GetEverestUniqueCounterRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.GetEverestUniqueCounterResponse.deserializeBinary
);


/**
 * @param {!proto.io.GetEverestUniqueCounterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.GetEverestUniqueCounterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.GetEverestUniqueCounterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.getUniqueCounter =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/GetUniqueCounter',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_GetUniqueCounter,
          callback);
    };


/**
 * @param {!proto.io.GetEverestUniqueCounterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.GetEverestUniqueCounterResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.getUniqueCounter =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/GetUniqueCounter',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_GetUniqueCounter);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.GetEverestCounterListRequest,
 *   !proto.io.GetEverestUniqueCounterListResponse>}
 */
const methodDescriptor_EverestDbService_GetUniqueCounterList = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/GetUniqueCounterList',
    grpc.web.MethodType.UNARY,
    proto.io.GetEverestCounterListRequest,
    proto.io.GetEverestUniqueCounterListResponse,
    /** @param {!proto.io.GetEverestCounterListRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.GetEverestUniqueCounterListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.GetEverestCounterListRequest,
 *   !proto.io.GetEverestUniqueCounterListResponse>}
 */
const methodInfo_EverestDbService_GetUniqueCounterList = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.GetEverestUniqueCounterListResponse,
    /** @param {!proto.io.GetEverestCounterListRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.GetEverestUniqueCounterListResponse.deserializeBinary
);


/**
 * @param {!proto.io.GetEverestCounterListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.GetEverestUniqueCounterListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.GetEverestUniqueCounterListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.getUniqueCounterList =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/GetUniqueCounterList',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_GetUniqueCounterList,
          callback);
    };


/**
 * @param {!proto.io.GetEverestCounterListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.GetEverestUniqueCounterListResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.getUniqueCounterList =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/GetUniqueCounterList',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_GetUniqueCounterList);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.IncrementUniqueCountRequest,
 *   !proto.io.IncrementCountResponse>}
 */
const methodDescriptor_EverestDbService_IncrementUniqueCounter = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/IncrementUniqueCounter',
    grpc.web.MethodType.UNARY,
    proto.io.IncrementUniqueCountRequest,
    proto.io.IncrementCountResponse,
    /** @param {!proto.io.IncrementUniqueCountRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.IncrementCountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.IncrementUniqueCountRequest,
 *   !proto.io.IncrementCountResponse>}
 */
const methodInfo_EverestDbService_IncrementUniqueCounter = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.IncrementCountResponse,
    /** @param {!proto.io.IncrementUniqueCountRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.IncrementCountResponse.deserializeBinary
);


/**
 * @param {!proto.io.IncrementUniqueCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.IncrementCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.IncrementCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.incrementUniqueCounter =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/IncrementUniqueCounter',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_IncrementUniqueCounter,
          callback);
    };


/**
 * @param {!proto.io.IncrementUniqueCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.IncrementCountResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.incrementUniqueCounter =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/IncrementUniqueCounter',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_IncrementUniqueCounter);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.DecrementCounterRequest,
 *   !proto.io.DecrementCounterResponse>}
 */
const methodDescriptor_EverestDbService_DecrementCounter = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/DecrementCounter',
    grpc.web.MethodType.UNARY,
    proto.io.DecrementCounterRequest,
    proto.io.DecrementCounterResponse,
    /** @param {!proto.io.DecrementCounterRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.DecrementCounterResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.DecrementCounterRequest,
 *   !proto.io.DecrementCounterResponse>}
 */
const methodInfo_EverestDbService_DecrementCounter = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.DecrementCounterResponse,
    /** @param {!proto.io.DecrementCounterRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.DecrementCounterResponse.deserializeBinary
);


/**
 * @param {!proto.io.DecrementCounterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.DecrementCounterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.DecrementCounterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.decrementCounter =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/DecrementCounter',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_DecrementCounter,
          callback);
    };


/**
 * @param {!proto.io.DecrementCounterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.DecrementCounterResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.decrementCounter =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/DecrementCounter',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_DecrementCounter);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.DecrementAndIncrementRequest,
 *   !proto.io.DecrementAndIncrementResponse>}
 */
const methodDescriptor_EverestDbService_DecrAndIncrCounter = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/DecrAndIncrCounter',
    grpc.web.MethodType.UNARY,
    proto.io.DecrementAndIncrementRequest,
    proto.io.DecrementAndIncrementResponse,
    /** @param {!proto.io.DecrementAndIncrementRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.DecrementAndIncrementResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.DecrementAndIncrementRequest,
 *   !proto.io.DecrementAndIncrementResponse>}
 */
const methodInfo_EverestDbService_DecrAndIncrCounter = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.DecrementAndIncrementResponse,
    /** @param {!proto.io.DecrementAndIncrementRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.DecrementAndIncrementResponse.deserializeBinary
);


/**
 * @param {!proto.io.DecrementAndIncrementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.DecrementAndIncrementResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.DecrementAndIncrementResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.decrAndIncrCounter =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/DecrAndIncrCounter',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_DecrAndIncrCounter,
          callback);
    };


/**
 * @param {!proto.io.DecrementAndIncrementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.DecrementAndIncrementResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.decrAndIncrCounter =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/DecrAndIncrCounter',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_DecrAndIncrCounter);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.SyncEverestObjectsRequest,
 *   !proto.io.SyncEverestObjectsResponse>}
 */
const methodDescriptor_EverestDbService_SyncEverestObjects = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/SyncEverestObjects',
    grpc.web.MethodType.UNARY,
    proto.io.SyncEverestObjectsRequest,
    proto.io.SyncEverestObjectsResponse,
    /** @param {!proto.io.SyncEverestObjectsRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.SyncEverestObjectsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.SyncEverestObjectsRequest,
 *   !proto.io.SyncEverestObjectsResponse>}
 */
const methodInfo_EverestDbService_SyncEverestObjects = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.SyncEverestObjectsResponse,
    /** @param {!proto.io.SyncEverestObjectsRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.SyncEverestObjectsResponse.deserializeBinary
);


/**
 * @param {!proto.io.SyncEverestObjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.SyncEverestObjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.SyncEverestObjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.syncEverestObjects =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/SyncEverestObjects',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_SyncEverestObjects,
          callback);
    };


/**
 * @param {!proto.io.SyncEverestObjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.SyncEverestObjectsResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.syncEverestObjects =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/SyncEverestObjects',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_SyncEverestObjects);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.WriteRequest,
 *   !proto.io.WriteResponse>}
 */
const methodDescriptor_EverestDbService_Write = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/Write',
    grpc.web.MethodType.UNARY,
    proto.io.WriteRequest,
    proto.io.WriteResponse,
    /** @param {!proto.io.WriteRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.WriteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.WriteRequest,
 *   !proto.io.WriteResponse>}
 */
const methodInfo_EverestDbService_Write = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.WriteResponse,
    /** @param {!proto.io.WriteRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.WriteResponse.deserializeBinary
);


/**
 * @param {!proto.io.WriteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.WriteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.WriteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.write =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/Write',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_Write,
          callback);
    };


/**
 * @param {!proto.io.WriteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.WriteResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.write =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/Write',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_Write);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.BatchWriteRequest,
 *   !proto.io.BatchWriteResponse>}
 */
const methodDescriptor_EverestDbService_BatchWrite = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/BatchWrite',
    grpc.web.MethodType.UNARY,
    proto.io.BatchWriteRequest,
    proto.io.BatchWriteResponse,
    /** @param {!proto.io.BatchWriteRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.BatchWriteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.BatchWriteRequest,
 *   !proto.io.BatchWriteResponse>}
 */
const methodInfo_EverestDbService_BatchWrite = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.BatchWriteResponse,
    /** @param {!proto.io.BatchWriteRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.BatchWriteResponse.deserializeBinary
);


/**
 * @param {!proto.io.BatchWriteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.BatchWriteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.BatchWriteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.batchWrite =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/BatchWrite',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_BatchWrite,
          callback);
    };


/**
 * @param {!proto.io.BatchWriteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.BatchWriteResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.batchWrite =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/BatchWrite',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_BatchWrite);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.BatchCrudRequest,
 *   !proto.io.BatchCrudResponse>}
 */
const methodDescriptor_EverestDbService_BatchCrud = new grpc.web.MethodDescriptor(
    '/io.EverestDbService/BatchCrud',
    grpc.web.MethodType.UNARY,
    proto.io.BatchCrudRequest,
    proto.io.BatchCrudResponse,
    /** @param {!proto.io.BatchCrudRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.BatchCrudResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.io.BatchCrudRequest,
 *   !proto.io.BatchCrudResponse>}
 */
const methodInfo_EverestDbService_BatchCrud = new grpc.web.AbstractClientBase.MethodInfo(
    proto.io.BatchCrudResponse,
    /** @param {!proto.io.BatchCrudRequest} request */
    function (request) {
      return request.serializeBinary();
    },
    proto.io.BatchCrudResponse.deserializeBinary
);


/**
 * @param {!proto.io.BatchCrudRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.io.BatchCrudResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.BatchCrudResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.EverestDbServiceClient.prototype.batchCrud =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/io.EverestDbService/BatchCrud',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_BatchCrud,
          callback);
    };


/**
 * @param {!proto.io.BatchCrudRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.BatchCrudResponse>}
 *     A native promise that resolves to the response
 */
proto.io.EverestDbServicePromiseClient.prototype.batchCrud =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/io.EverestDbService/BatchCrud',
          request,
          metadata || {},
          methodDescriptor_EverestDbService_BatchCrud);
    };


module.exports = proto.io;

