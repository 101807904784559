import {fill} from "../../common/colors";

export default theme => ({
    root: {
        padding: theme.spacing(2),
        minHeight: '85vh'
    },
    paperRoot: {
        padding: '24px 8px',
        background: 'aliceblue',
        margin: '15px 0'
    },
    ticketPaperRoot: {
        margin: '15px 0',
        padding: '15px 0',
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
        borderRadius: '5px',
    },
    timeUUID: {
        color: fill.timeColor,
        fontSize: '12px',
    },
    noMemberWrapper: {
        padding: '10px',
        borderRadius: '5px',
        background: '#66aef1d4',
        color: '#253d49',
        fontSize: '16px',
        clear: 'both'
    },
    hover: {
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});
