import React, {Component} from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
// Material helpers
import {ThemeProvider} from '@material-ui/styles';
// Theme
import theme from './theme';
// Styles
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
// Routes
import Routes from './Routes';

import {initializeFirebase} from "./configurations/config";
import {getClientIP} from "./utils/Client";
import {isTokenExpiredBefore, reFetchToken} from "./configurations/global-config";
import HomePleaseWait from "./components/HomePleaseWait";

// Browser history
const browserHistory = createBrowserHistory();

export default class App extends Component {
    state = {
        canRender: false, msg: 'Please Wait...', loading: true, themeColor:localStorage.getItem('mode') ? localStorage.getItem('mode') : 'light'
    };

    componentDidMount() {
        let self = this;
        getClientIP().then(function (res) {
        }).catch(function (error) {
        });
        self.setState({
            loading: true
        });
        let search = window.location.search;
        let refreshSts;
        if (search && search !== '') {
            const searchedParameters = new URLSearchParams(search);
            refreshSts = searchedParameters.get('refresh');
        }
        if (isTokenExpiredBefore() || refreshSts === 'true') {
            initializeFirebase().then(function (response) {
                if (response.status) {
                    self.setState({canRender: true});
                } else self.setState({msg: 'An error occur please try again.', loading: false});
            }).catch(function (error) {
                self.setState({msg: 'An Error occur please try again.', loading: false});
            });
        } else {
            reFetchToken();
            self.setState({canRender: true});
        }

    }

    render() {
        const {canRender, msg, loading,themeColor} = this.state;
        return (
            <div>
                {canRender ? (
                    <ThemeProvider theme={theme}>
                        <Router history={browserHistory}>
                            <Routes/>
                        </Router>
                    </ThemeProvider>
                ) : (
                    <div>
                        {loading ? (
                            <HomePleaseWait msg={msg}/>
                        ): (<HomePleaseWait msg={msg} stopLoad={true}/>)}
                    </div>
                )}
            </div>
        );
    }
}
