import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
// Externals
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
// Material helpers
// Material components
import {Button, Grid, Typography, withStyles} from '@material-ui/core';
// Material icons
// Component styles
import styles from './styles';
// Shared components
import {Facebook as FacebookIcon, Google as GoogleIcon} from '../../icons';
import {eventAnalyst, pageViewAnalyst, socialLogin} from "../../configurations/config";
import {isLogin} from "../../configurations/global-config";
import Footer from "../../layouts/Dashboard/components/Footer";
import CircularProgress from "@material-ui/core/CircularProgress";

class Login extends Component {
    state = {
        redirectTo: '/',
        logIn: null,
        logInMsg: null,
        err: false
    };

    handleLogin = (type) => {
        let self = this;
        this.setState({err: false, logIn: true, logInMsg: 'Please Wait...'});
        socialLogin(type).then(function (response) {
            self.setState({err: false, logIn: true, logInMsg: 'Just a moment...'});
            if (response) {
                eventAnalyst('login', 'login_clicked', 'Login Success', 'user-login-success');
                window.location.href = self.state.redirectTo;
            } else {
                eventAnalyst('login', 'login_clicked', 'Login Fail', 'user-login-fail');
                self.setState({logIn: false, err: true, logInMsg: 'Something went wrong.'});
            }
        }).catch(function (error) {
            eventAnalyst('login', 'login_clicked', 'Login failed', 'user-login-failed');
            self.setState({logIn: false, err: true, logInMsg: 'An error occur while logging. Please try again.'});
        });
    };

    componentDidMount() {
        const {history} = this.props;
        pageViewAnalyst('Login', this.props.location.pathname);
        if (isLogin()) {
            const search = this.props.location.search;
            if (search && search !== '') {
                const searchedParameters = new URLSearchParams(search);
                const redirectTo = searchedParameters.get('redirectURL');
                if (redirectTo && redirectTo !== '') {
                    this.setState({
                        redirectTo,
                    });
                    history.push(redirectTo);
                }
            } else history.push('/home');
        }
    }

    render() {
        const {classes, history} = this.props;
        const {logIn, logInMsg, err} = this.state;

        return (
            <div>
                {!isLogin() ?
                    (
                        <div className={classes.root}>
                            <Grid
                                className={classes.grid}
                                container
                            >
                                <Grid
                                    className={classes.quoteWrapper}
                                    item
                                    lg={5}
                                >
                                    <div className={classes.quote}>
                                        <div className={classes.quoteInner}>
                                            <Typography
                                                className={classes.quoteText}
                                                variant="h4"
                                                style={{fontWeight: 900}}
                                            >
                                                Welcome to hamro community. Create and start building a happy community.
                                            </Typography>
                                            <div className={classes.person}>
                                                <Typography
                                                    className={classes.name}
                                                    variant="body1"
                                                >
                                                    Hamro Community
                                                </Typography>
                                                <Typography
                                                    className={classes.bio}
                                                    variant="body2"
                                                >
                                                    Hamro Patro
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    className={classes.content}
                                    item
                                    lg={7}
                                    xs={12}
                                >
                                    <div className={classes.content}>
                                        <div className={classes.contentBody}>
                                            <form className={classes.form}>
                                                <div className={classes.imageWrapper}>
                                                    <img className={classes.imageToUpload} src="/hamropatro.png"
                                                         onClick={() => history.push('/home')}/>
                                                </div>
                                                <Typography
                                                    className={classes.title}
                                                    variant="h6"
                                                    style={{fontWeight: 600}}
                                                >
                                                    Welcome to Hamro Community. Please Sign-In to continue.
                                                </Typography>
                                                <Typography
                                                    className={classes.subtitle}
                                                    variant="body1"
                                                >
                                                    You can log-in with social media accounts.
                                                </Typography>

                                                <Button
                                                    className={classes.googleButton}
                                                    color="primary"
                                                    onClick={() => this.handleLogin("g")}
                                                    size="large"
                                                    variant="contained"
                                                >
                                                    <GoogleIcon className={classes.googleIcon}/>
                                                    <span style={{fontWeight: 900}}>Login with Google</span>
                                                </Button>
                                                <Button
                                                    className={classes.facebookButton}
                                                    color="primary"
                                                    onClick={() => this.handleLogin("f")}
                                                    size="large"
                                                    variant="contained"
                                                >
                                                    <FacebookIcon className={classes.facebookIcon}/>
                                                    <span style={{fontWeight: 900}}>Login with Facebook</span>
                                                </Button>
                                                <br/>
                                                <br/>
                                                <div>
                                                    {logIn === true ? (
                                                        <div style={{
                                                            color: '#0077e0',
                                                            fontSize: '18px'
                                                        }}>{logInMsg} &nbsp; &nbsp;<CircularProgress size={17}
                                                                                                     color={"secondary"}/>
                                                        </div>
                                                    ) : (<div></div>)}
                                                    {err === true ? (
                                                        <div style={{
                                                            color: '#b71c1c',
                                                            fontSize: '18px'
                                                        }}>{logInMsg}</div>
                                                    ) : (<div></div>)}
                                                </div>
                                            </form>
                                        </div>
                                        <Footer/>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    ) : (<div></div>)}
            </div>
        );
    }
}

Login.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles)
)(Login);
