export function metaDataSet(title, desc, url, image) {
    title = title ? title : 'Hamro Patro Community';
    desc = desc ? desc : 'Welcome to Hamro Patro Community. Create and start building a happy community.';
    url = url ? url : 'https://we.hamropatro.com';
    image = image ? image : 'https://everestdb.sgp1.digitaloceanspaces.com/usable-images/usable-images/hamropatro.png';

    let link = document.createElement('meta');
    link.setAttribute('property', 'og:title');
    link.content = title;
    document.getElementsByTagName('head')[0].appendChild(link);

    let link2 = document.createElement('meta');
    link2.setAttribute('property', 'og:url');
    link2.content = url;
    document.getElementsByTagName('head')[0].appendChild(link2);

    let link3 = document.createElement('meta');
    link3.setAttribute('property', 'og:image');
    link3.content = image;
    document.getElementsByTagName('head')[0].appendChild(link3);

    let link4 = document.createElement('meta');
    link4.setAttribute('property', 'og:description');
    link4.content = desc;
    document.getElementsByTagName('head')[0].appendChild(link4);

    //Twitter
    let link5 = document.createElement('meta');
    link5.setAttribute('property', 'twitter:url');
    link5.content = url;
    document.getElementsByTagName('head')[0].appendChild(link5);

    let link6 = document.createElement('meta');
    link6.setAttribute('name', 'twitter:title');
    link6.content = title;
    document.getElementsByTagName('head')[0].appendChild(link6);

    let link7 = document.createElement('meta');
    link7.setAttribute('property', 'twitter:image:src');
    link7.content = image;
    document.getElementsByTagName('head')[0].appendChild(link7);

    let link8 = document.createElement('meta');
    link8.setAttribute('name', 'twitter:image');
    link8.content = image;
    document.getElementsByTagName('head')[0].appendChild(link8);
}

export function setPageTitle(pageTitle) {
    document.title = pageTitle;
}