import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";

class HomePleaseWait extends Component {
    state = {};

    render() {
        const {msg,stopLoad} = this.props;
        return (
            <div data-role="page" id="page1">
                <div id="cont" role="main" className="ui-content">
                    <div className="splashDiv" style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center'
                    }}>
                        {!stopLoad ? (<div style={{textAlign: 'center'}}><CircularProgress size={20}/></div>):null}
                        <div style={{fontSize: '18px'}}>{msg}</div>
                    </div>
                </div>
            </div>
        )
    }
}

HomePleaseWait.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HomePleaseWait);