export default theme => ({
    success: {
        backgroundColor: theme.palette.success.dark,
    },
    error: {
        backgroundColor: theme.palette.primary.main,
    },
    info: {
        backgroundColor: theme.palette.info.main,
    },
    warning: {
        backgroundColor: theme.palette.warning.dark,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '11px'
    },
    iconVarient: {
        opacity: 0.7,
        marginRight: theme.spacing(),
    }
});