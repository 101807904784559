let isThisProduction = true;

let globalConfig;
let firebaseConfig;

if (isThisProduction) {
    /* hamropatro firebase config for production */
    firebaseConfig = {
        apiKey: 'AIzaSyDz9DzH59ZfYKqwMJpE1Id0LtCyVkAvYY8',
        authDomain: 'hamropatro.firebaseapp.com',
        databaseURL: 'https://hamropatro.firebaseio.com',
        projectId: 'hamropatro',
        storageBucket: '',
        messagingSenderId: '366682898353'
    };

    globalConfig = {
        /* Production config */
        clientUrl: 'https://web-grpc-envoy.hamro-files.com',
        BackendUrl: {'url': 'https://everestbackend-api.hamropatro.com'},
        appSecreteGoogle: {'key': 'AIzaSyCmuuoSSykIiwZAA0XlNLdknZwkNljgA8M'},
        appId: 'hamropatro', // production
        apiKey: 'EeEf8dDr9kyF95FyKW6SNCAj',
        IMEBusinessID: '8c30bc80-8374-11e9-b839-0242ac120023', // production

        PlaceKey: 'AIzaSyCmuuoSSykIiwZAA0XlNLdknZwkNljgA8M',

        //Stripe Keys
        stripeKey: 'pk_live_Gl5iznIRlASpUaIKGT9OWBg400KibsEqrV', //production
        //khalti Key
        khaltiKey: 'live_public_key_03626f80f87c4b12b554290a7e6baf9f', //prod-khalti

        //Esewa-Payment
        esewaURL:'https://esewa.com.np/epay/main',
        esewaSCD:'NP-ES-HAMROPATRO',
        //Uploading_Bucket
        uploadingBucket: "Mandala",

        web_apiKey: 'ce15f30b-fb9b-4baf-b0f1-6ab88b3baa2f',
        firebaseApps: {},
        firebaseConfig: firebaseConfig

    };
} else {
    /* hamropatro-android firebase config for development */
    firebaseConfig = {
        apiKey: 'AIzaSyAx1rN_KoTMfqI8O5dU1INfhmUtnRH6XcU',
        authDomain: 'hamropatro-android-test.firebaseapp.com',
        databaseURL: 'https://hamropatro-android-test.firebaseio.com',
        projectId: 'hamropatro-android-test',
        storageBucket: 'hamropatro-android-test.appspot`.com',
        messagingSenderId: '27021292206'
    };

    globalConfig = {
        /* Development config */
        clientUrl: 'https://dev-dot-web-grpc-envoy.lax-hamrostack.com',
        BackendUrl: {'url': 'https://dev-dot-everestbackend-api-dev.lax-hamrostack.com'},
        appSecreteGoogle: {'key': 'v1DSRxAMFyjWYKSH7Itj'}, // hamropatro-android-test
        appId: 'hamropatro-android', // development
        apiKey: 'NQXZRh4XrfCKZstzfADP',
        IMEBusinessID: '30c05e40-8370-11e9-9b6c-0242ac14007d', // dev

        PlaceKey: 'AIzaSyCmuuoSSykIiwZAA0XlNLdknZwkNljgA8M',

        //Stripe Keys
        stripeKey: 'pk_test_HXfeCxpZScmzOLsyXfwshVCJ00Xa1xZi3X', //dev-paritosh
        //khalti Key
        khaltiKey: 'test_public_key_c00880d159d74fd9833b2d728be9eea1', //dev-khalti

        //Esewa-Payment
        esewaURL:'https://uat.esewa.com.np/epay/main',
        esewaSCD:'epay_payment',

        //Uploading_Bucket
        uploadingBucket: "Mandala-dev",

        web_apiKey: 'ce15f30b-fb9b-4baf-b0f1-6ab88b3baa2f',
        firebaseApps: {},
        firebaseConfig: firebaseConfig
    };
}

export default globalConfig;

/* hamropatro-android firebase config for android-example */

// let firebaseConfig = {
//     apiKey: 'AIzaSyAdjWf7I7g8pbJ5hlIpZMLOjJLfSDaicns',
//     authDomain: 'everestdb-android-sdk.firebaseapp.com',
//     databaseURL: 'https://everestdb-android-sdk.firebaseio.com',
//     projectId: 'hamropatro-android-test',
//     storageBucket: 'everestdb-android-sdk.appspot.com',
//     messagingSenderId: '1046531804531'
// };

// android examples
// clientUrl: 'https://dev-dot-web-grpc-envoy.lax-hamrostack.com',
// BackendUrl: {'url': 'https://dev-dot-everestbackend-api-dev.lax-hamrostack.com'},
// appSecreteGoogle: {'key': 'v1DSRxAMFyjWYKSH7Itj'}, // hamropatro-android-test
// appId: 'everest-db-example', // development
// apiKey: 'NQXZRh4XrfCKZstzfADP',
