export default theme => ({
    timeUUID: {
        color: '#757575',
        fontSize: '12px',
    },
    chatBoxAvatar: {
        height: 30,
        width: 30,
    },
    commentWrapper: {
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    commentClass: {
        color: '#0077e0',
        margin: '5px',
        padding: '5px',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    listCover: {
        background: '#f4f6f7',
        padding: '10px 10px 15px',
        borderRadius: '5px'
    },
    listCoverComment: {
        background: '#f4f6f700',
        padding: '2px 5px',
        borderRadius: '5px'
    },
    commentPost: {
        color: '#000000',
        fontSize: '15px',
        lineHeight: 1.5,
        marginTop: '5px'
    },
    postTime:{
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
});
