export default theme => ({
    root: {
        padding: theme.spacing(2),
        minHeight: '85vh'
    },
    inviteButton: {
        width: 'fit-content !important',
        margin: '20px 5px',
        fontSize: '12px',
        padding: '7px 30px !important',
        boxShadow: 'none',
        color: '#d23c3c',
        background: '#f4f6f7',
        '&:hover': {
            background: '#f4f6f7',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
        float: 'right',
        cursor: 'pointer'
    },
    sharableText: {
        fontFamily: 'monospace'
    },
    sharableLinkWrapper: {
        border: '1px solid #e5e5e5',
        padding: '10px',
        borderRadius: '5px',
        overflow: 'auto'
    },
    contentInvite: {
        margin: '5px 10px',
        color: '#009dff'
    },
    moreVertPost: {
        float: 'right',
        padding: '5px'
    },
    menuThreeVert: {
        boxShadow: 'none'
    },
    directHead: {
        padding: '7px 0',
        fontWeight: 600,
        float: 'left'
    },
    directHeadText: {
        padding: '7px 0',
        fontWeight: 600,
    },
    formSwitch: {
        float: 'right'
    },
    directText: {
        clear: 'both',
        fontSize: '14px',
        color: '#000000'
    },
    clearBoth: {
        clear: 'both'
    },
    required: {
        marginRight: '2px',
        marginLeft: '2px',
        color: '#af3d30',
        fontSize: '13px'
    },
    closeEditBox: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    notificationWrapper: {
        padding: '10px',
        borderRadius: '5px',
        background: '#66aef1d4',
        color: '#253d49',
        fontSize: '16px'
    }
});