import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import globalConfig from "./configVariables";
import axios from 'axios';
import {isLogin, isTokenExpiredBefore} from "./global-config";

const gtag = window.gtag;
// const analystTrackerID = 'UA-148423188-1';

const analystTrackerID = 'G-LHCZH74YXM';

export function eventAnalyst(eventCat, eventAction, eventLabel, eventValue) {
    gtag('event', eventAction, {
        'event_category': eventCat,
        'event_label': eventLabel,
        'event_value': eventValue,
    });
}

export function pageViewAnalyst(title, path) {
    gtag('config', analystTrackerID, {
        'page_title': title,
        'page_path': path
    });
}

export function socialLogin(params) {
    return new Promise(function (resolve, reject) {
        let appId = globalConfig.appId;
        let provider;
        if (params === 'g') {
            eventAnalyst('login', 'login_click', 'Google', 'google-login');
            provider = new firebase.auth.GoogleAuthProvider();
        } else if (params === 'f') {
            eventAnalyst('login', 'login_click', 'Facebook', 'facebook-login');
            provider = new firebase.auth.FacebookAuthProvider();
        } else if (params === 't') {
            eventAnalyst('login', 'login_click', 'Twitter', 'twitter-login');
            provider = new firebase.auth.TwitterAuthProvider();
        } else {
            provider = null;
        }
        const appFirebase = globalConfig.firebaseApps[appId];

        appFirebase.auth().signInWithPopup(provider).then(function () {
            localStorage.removeItem(appId + '_access_token');
            firebaseCallWithAppID(globalConfig.appId).then(function () {
                resolve(true);
            }).catch(function (error) {
                if (error.status == '403') {
                    reject(error);
                } else {
                    reject(error);
                }
            });
        }).catch(function (error) {
            reject(error);
        });
    });
}

export function initializeFirebase() {
    const script = document.createElement('script');
    script.src = 'https://www.gstatic.com/firebasejs/4.1.2/firebase.js';
    script.async = true;
    document.body.appendChild(script);
    script.src = 'https://www.gstatic.com/firebasejs/ui/live/0.4/firebase-ui-auth.js';
    script.async = true;
    document.body.appendChild(script);
    globalConfig.firebaseApps[globalConfig.appId] = firebase.initializeApp(globalConfig.firebaseConfig, globalConfig.appId);
    return new Promise(function (resolve, reject) {
        firebaseCallWithAppID(globalConfig.appId)
            .then(function (response) {
                resolve(response);
            }).catch(function (error) {
            reject(error);
        });
    });
}

export function firebaseCallWithAppID(appId) {
    return new Promise(function (resolve, reject) {
        const firebaseApp = globalConfig.firebaseApps[appId];
        firebaseApp.auth().onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                callAuth(firebaseApp, 3).then(function (response) {
                    resolve(Object.assign({user: user}, response));
                }).catch(function (error) {
                    reject(error);
                });
            } else {
                resolve({
                    status: 'Login'
                });
            }
        });
    });
}

export function MetaHeaders() {
    return new Promise(function (resolve, reject) {
        if (isLogin()) {
            let token;
            if (isTokenExpiredBefore()) {
                let appId = globalConfig.appId;
                const firebaseApp = globalConfig.firebaseApps[appId];
                callAuth(firebaseApp, 3).then(function (res) {
                    if (res.status) {
                        res = localStorage.getItem(appId + '_access_token');
                        token = res;
                        let metaHeader = metaDataHeader(token);
                        resolve(metaHeader);
                    }
                }).catch(function (error) {
                })
            } else {
                token = localStorage.getItem(globalConfig.appId + '_access_token');
                let metaHeader = metaDataHeader(token);
                resolve(metaHeader);
            }
        } else
            resolve(metaDataHeader());
    });
}

function metaDataHeader(accessToken) {
    let headers;

    if (accessToken) {
        headers =
            {
                'app-id': globalConfig.appId,
                'api-key': globalConfig.apiKey,
                'Authorization': 'Bearer ' + accessToken
            };
    } else {
        headers =
            {
                'app-id': globalConfig.appId,
                'api-key': globalConfig.apiKey,
            };
    }
    return headers;
}

export function callAuth(firebaseApp, retryTimes) {
    return new Promise(function (resolve, reject) {
        firebaseApp.auth().currentUser.getIdToken(false).then(function (idToken) {
            let token = idToken;
            let obj = {
                token_type: 'firebase-token',
                token: token
            };
            axios.post(globalConfig.BackendUrl.url + '/account/auth', obj, {
                headers: {
                    'Grpc-Metadata-app-id': globalConfig.appId,
                    'Grpc-Metadata-web-api-key': globalConfig.web_apiKey
                }
            }).then(function (response) {
                if (response.data.user_profile) {
                    localStorage.setItem(globalConfig.appId + '_user', JSON.stringify(response.data.user_profile));
                    localStorage.setItem(globalConfig.appId + '_access_token', response.data.access_token);
                    localStorage.setItem(globalConfig.appId + '_time', new Date().getTime() + (parseInt(response.data.expire_in) - (15 * 60000))); //  15 min before
                    let obj = {
                        status: true
                    };
                    resolve(obj);
                } else {
                    let obj = {
                        message: 'Sorry, You are unauthorized for this application',
                        status: '403',
                        data: ''
                    };
                    reject(obj);
                }
            }).catch(function (secondError) {
                setTimeout(() => {
                    if (retryTimes === 1) reject(secondError);
                    else {
                        const firebaseApp = globalConfig.firebaseApps[globalConfig.appId];
                        callAuth(firebaseApp, retryTimes - 1).then(function (response) {
                            resolve(response);
                        }).catch(function (error) {
                            reject(error);
                        })
                    }
                }, 1200); //retry after 1 sec
            });
        });
    });
}



export function removeLocalStorage() {
    return new Promise(function (resolve, reject) {
        let appId = globalConfig.appId;
        const firebaseApp = globalConfig.firebaseApps[appId];
        firebaseApp.auth().signOut().then(function () {
            localStorage.clear();
            resolve()
        }, function (error) {
            reject();
        });
    });
}