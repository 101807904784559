/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.Status', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetReplyRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetReplyResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.SpamReplyRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.SpamReplyResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetReplyRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetReplyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetReplyRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetReplyRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetReplyRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetReplyRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetReplyRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            replyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            accountInfo: (f = msg.getAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetReplyRequest}
 */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetReplyRequest;
    return proto.com.hamropatro.sociallayer.io.GetReplyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetReplyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetReplyRequest}
 */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setReplyId(value);
                break;
            case 4:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setAccountInfo(value);
                break;
            case 5:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetReplyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetReplyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getReplyId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getAccountInfo();
    if (f != null) {
        writer.writeMessage(
            4,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            5,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
};


/**
 * optional string post_uri = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reply_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.getReplyId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.setReplyId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AccountInfo account_info = 4;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.getAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 4));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.setAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 4, value);
};


proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.clearAccountInfo = function () {
    this.setAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.hasAccountInfo = function () {
    return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AccountInfo business_account_info = 5;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 5));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 5, value);
};


proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.GetReplyRequest.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 5) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetReplyResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetReplyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetReplyResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetReplyResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetReplyResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetReplyResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetReplyResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetReplyResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userreply: (f = msg.getUserreply()) && frontend$social$common$types_pb.UserReply.toObject(includeInstance, f),
            postownerid: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetReplyResponse}
 */
proto.com.hamropatro.sociallayer.io.GetReplyResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetReplyResponse;
    return proto.com.hamropatro.sociallayer.io.GetReplyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetReplyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetReplyResponse}
 */
proto.com.hamropatro.sociallayer.io.GetReplyResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new frontend$social$common$types_pb.UserReply;
                reader.readMessage(value, frontend$social$common$types_pb.UserReply.deserializeBinaryFromReader);
                msg.setUserreply(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostownerid(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetReplyResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetReplyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetReplyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetReplyResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserreply();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            frontend$social$common$types_pb.UserReply.serializeBinaryToWriter
        );
    }
    f = message.getPostownerid();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional UserReply userReply = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.UserReply}
 */
proto.com.hamropatro.sociallayer.io.GetReplyResponse.prototype.getUserreply = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.UserReply} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.UserReply, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.UserReply|undefined} value */
proto.com.hamropatro.sociallayer.io.GetReplyResponse.prototype.setUserreply = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.sociallayer.io.GetReplyResponse.prototype.clearUserreply = function () {
    this.setUserreply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.GetReplyResponse.prototype.hasUserreply = function () {
    return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string postOwnerId = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetReplyResponse.prototype.getPostownerid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetReplyResponse.prototype.setPostownerid = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.displayName = 'proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            replyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            accountInfo: (f = msg.getAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
            reply: (f = msg.getReply()) && frontend$social$common$types_pb.ReplyMessage.toObject(includeInstance, f),
            replyType: jspb.Message.getFieldWithDefault(msg, 7, 0),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest;
    return proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setReplyId(value);
                break;
            case 5:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setAccountInfo(value);
                break;
            case 6:
                var value = new frontend$social$common$types_pb.ReplyMessage;
                reader.readMessage(value, frontend$social$common$types_pb.ReplyMessage.deserializeBinaryFromReader);
                msg.setReply(value);
                break;
            case 7:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.MessageType} */ (reader.readEnum());
                msg.setReplyType(value);
                break;
            case 8:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getReplyId();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getAccountInfo();
    if (f != null) {
        writer.writeMessage(
            5,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getReply();
    if (f != null) {
        writer.writeMessage(
            6,
            f,
            frontend$social$common$types_pb.ReplyMessage.serializeBinaryToWriter
        );
    }
    f = message.getReplyType();
    if (f !== 0.0) {
        writer.writeEnum(
            7,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            8,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
};


/**
 * optional string post_uri = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string reply_id = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.getReplyId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.setReplyId = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional AccountInfo account_info = 5;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.getAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 5));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.setAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 5, value);
};


proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.clearAccountInfo = function () {
    this.setAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.hasAccountInfo = function () {
    return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ReplyMessage reply = 6;
 * @return {?proto.com.hamropatro.sociallayer.io.ReplyMessage}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.getReply = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.ReplyMessage} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.ReplyMessage, 6));
};


/** @param {?proto.com.hamropatro.sociallayer.io.ReplyMessage|undefined} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.setReply = function (value) {
    jspb.Message.setWrapperField(this, 6, value);
};


proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.clearReply = function () {
    this.setReply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.hasReply = function () {
    return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MessageType reply_type = 7;
 * @return {!proto.com.hamropatro.sociallayer.io.MessageType}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.getReplyType = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.MessageType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.MessageType} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.setReplyType = function (value) {
    jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional AccountInfo business_account_info = 8;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 8));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 8, value);
};


proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentRequest.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 8) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.displayName = 'proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            postUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            replyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            accountInfo: (f = msg.getAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
            postOwnerBusinessId: jspb.Message.getFieldWithDefault(msg, 7, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse;
    return proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setReplyId(value);
                break;
            case 5:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setAccountInfo(value);
                break;
            case 6:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostOwnerBusinessId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getReplyId();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getAccountInfo();
    if (f != null) {
        writer.writeMessage(
            5,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            6,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getPostOwnerBusinessId();
    if (f.length > 0) {
        writer.writeString(
            7,
            f
        );
    }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string post_uri = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string reply_id = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.getReplyId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.setReplyId = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional AccountInfo account_info = 5;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.getAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 5));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.setAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 5, value);
};


proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.clearAccountInfo = function () {
    this.setAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.hasAccountInfo = function () {
    return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AccountInfo business_account_info = 6;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 6));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 6, value);
};


proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string post_owner_business_id = 7;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.getPostOwnerBusinessId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReplyOnCommentResponse.prototype.setPostOwnerBusinessId = function (value) {
    jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.displayName = 'proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            replyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            accountId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            reactionType: jspb.Message.getFieldWithDefault(msg, 5, 0),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest;
    return proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setReplyId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 5:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (reader.readEnum());
                msg.setReactionType(value);
                break;
            case 6:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getReplyId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getReactionType();
    if (f !== 0.0) {
        writer.writeEnum(
            5,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            6,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
};


/**
 * optional string post_uri = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reply_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.getReplyId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.setReplyId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_id = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ReactionType reaction_type = 5;
 * @return {!proto.com.hamropatro.sociallayer.io.ReactionType}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.getReactionType = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.ReactionType} value */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.setReactionType = function (value) {
    jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional AccountInfo business_account_info = 6;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 6));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 6, value);
};


proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplyRequest.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 6) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse.displayName = 'proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse;
    return proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ReactOnCommentReplytResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.displayName = 'proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            replyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            status: jspb.Message.getFieldWithDefault(msg, 4, 0),
            accountId: jspb.Message.getFieldWithDefault(msg, 5, ""),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest;
    return proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setReplyId(value);
                break;
            case 4:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.Status} */ (reader.readEnum());
                msg.setStatus(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 6:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getReplyId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getStatus();
    if (f !== 0.0) {
        writer.writeEnum(
            4,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            6,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
};


/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.Status = {
    APPROVED: 0,
    ACTIVE: 1,
    BLOCKED: 2,
    PENDING: 3
};

/**
 * optional string post_uri = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reply_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.getReplyId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.setReplyId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.Status}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.getStatus = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.Status} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.setStatus = function (value) {
    jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string account_id = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AccountInfo business_account_info = 6;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 6));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 6, value);
};


proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyRequest.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 6) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.displayName = 'proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            postUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            replyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            postOwnerBusinessId: jspb.Message.getFieldWithDefault(msg, 5, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse;
    return proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setReplyId(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostOwnerBusinessId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getReplyId();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getPostOwnerBusinessId();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string post_uri = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string reply_id = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.prototype.getReplyId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.prototype.setReplyId = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string post_owner_business_id = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.prototype.getPostOwnerBusinessId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DeleteCommentReplyResponse.prototype.setPostOwnerBusinessId = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.displayName = 'proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
            pageToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest;
    return proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setPageSize(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 5:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeUint32(
            3,
            f
        );
    }
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            5,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
};


/**
 * optional string post_uri = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 page_size = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string page_token = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.setPageToken = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional AccountInfo business_account_info = 5;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 5));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 5, value);
};


proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesRequest.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 5) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.displayName = 'proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            postUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            repliesList: jspb.Message.toObjectList(msg.getRepliesList(),
                frontend$social$common$types_pb.UserReply.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
            postOwnerBusinessId: jspb.Message.getFieldWithDefault(msg, 7, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse;
    return proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 4:
                var value = new frontend$social$common$types_pb.UserReply;
                reader.readMessage(value, frontend$social$common$types_pb.UserReply.deserializeBinaryFromReader);
                msg.addReplies(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 6:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostOwnerBusinessId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getRepliesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            4,
            f,
            frontend$social$common$types_pb.UserReply.serializeBinaryToWriter
        );
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            6,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getPostOwnerBusinessId();
    if (f.length > 0) {
        writer.writeString(
            7,
            f
        );
    }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.getAppId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.setAppId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string post_uri = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated UserReply replies = 4;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.UserReply>}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.getRepliesList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.UserReply>} */ (
        jspb.Message.getRepeatedWrapperField(this, frontend$social$common$types_pb.UserReply, 4));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.UserReply>} value */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.setRepliesList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UserReply=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.UserReply}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.addReplies = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.hamropatro.sociallayer.io.UserReply, opt_index);
};


proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.clearRepliesList = function () {
    this.setRepliesList([]);
};


/**
 * optional string next_page_token = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.setNextPageToken = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AccountInfo business_account_info = 6;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 6));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 6, value);
};


proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string post_owner_business_id = 7;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.getPostOwnerBusinessId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ListCommentRepliesResponse.prototype.setPostOwnerBusinessId = function (value) {
    jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.SpamReplyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.SpamReplyRequest.displayName = 'proto.com.hamropatro.sociallayer.io.SpamReplyRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.SpamReplyRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.SpamReplyRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.SpamReplyRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
            commentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            replyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            ownerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            accountId: jspb.Message.getFieldWithDefault(msg, 5, ""),
            accountType: jspb.Message.getFieldWithDefault(msg, 6, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.SpamReplyRequest}
 */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.SpamReplyRequest;
    return proto.com.hamropatro.sociallayer.io.SpamReplyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.SpamReplyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.SpamReplyRequest}
 */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCommentId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setReplyId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setOwnerId(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.SpamReplyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.SpamReplyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCommentId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getReplyId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getOwnerId();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
    f = message.getAccountType();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
        );
    }
};


/**
 * optional string post_uri = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.getCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.setCommentId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reply_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.getReplyId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.setReplyId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string owner_id = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.getOwnerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.setOwnerId = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_id = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_type = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.getAccountType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.SpamReplyRequest.prototype.setAccountType = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.SpamReplyResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.SpamReplyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.SpamReplyResponse.displayName = 'proto.com.hamropatro.sociallayer.io.SpamReplyResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.SpamReplyResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.SpamReplyResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.SpamReplyResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.SpamReplyResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.SpamReplyResponse}
 */
proto.com.hamropatro.sociallayer.io.SpamReplyResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.SpamReplyResponse;
    return proto.com.hamropatro.sociallayer.io.SpamReplyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.SpamReplyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.SpamReplyResponse}
 */
proto.com.hamropatro.sociallayer.io.SpamReplyResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.SpamReplyResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.SpamReplyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.SpamReplyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.SpamReplyResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};


goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
