import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styles
import styles from './styles';
import {checkIfLogin} from "../../configurations/global-config";
import {pageViewAnalyst} from "../../configurations/config";
import {GetTicketPaymentRequest} from "../../generated/frontend-community-ticket-service_pb";
import {getTicketPaymentDetail} from "../../grpcRequests/tickets-request";
import PleaseWait from "../../components/PleaseWait";

import PaymentDetails from "../../components/Tickets/PaymentDetails";

var QRCode = require('qrcode.react');

class TicketPaymentDetails extends Component {

    state = {
        sourceId: this.props.match.params.ticketSourceId,
        ticketTypeId: this.props.match.params.ticketTypeId,
        ticketId: this.props.match.params.ticketId,
        loading: true,
        gotTicketInfo: false,
        ticketInfo: '',
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        pageViewAnalyst('Ticket Details', this.props.location.pathname);
        checkIfLogin(this.props.location.pathname);
        this.getTicketInformation(this.props.match.params.ticketSourceId, this.props.match.params.ticketTypeId, this.props.match.params.ticketId);
    }

    getTicketInformation = (sourceId, typeId, ticketId) => {
        let self = this;
        const req = new GetTicketPaymentRequest();
        req.setTicketSourceId(sourceId);
        req.setTicketTypeId(typeId);
        req.setTicketId(ticketId);
        getTicketPaymentDetail(req, 3).then(function (response) {
            const ticketInfo = response.getTicketPayment();
            const gotTicketInfo = true;
            const loading = false;
            self.setState({
                ticketInfo, gotTicketInfo, loading
            });
        }).catch(function (error) {
            const loading = false;
            self.setState({
                loading
            });
        });
    };

    render() {
        const {classes} = this.props;
        const {ticketInfo, gotTicketInfo, loading} = this.state;

        return (
            <DashboardLayout title="Ticket Payment Details" onBack='/tickets' linkTo='/tickets'>
                <div className={classes.root}>
                    {!loading ? (
                            <div>
                                {gotTicketInfo ? (
                                    <div className={classes.ticketPaperRoot}>
                                        <PaymentDetails ticketInfo={ticketInfo}/>
                                    </div>
                                ) : 'Sorry we Could not get ticket details now.'}
                            </div>
                        ) :
                        (<PleaseWait msg="Loading ticket details... "/>)
                    }
                </div>
            </DashboardLayout>
        );
    }
}

TicketPaymentDetails.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TicketPaymentDetails);
