/**
 * @fileoverview gRPC-Web generated client stub for com.hamropatro.sociallayer.io
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');


var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js')
const proto = {};
proto.com = {};
proto.com.hamropatro = {};
proto.com.hamropatro.sociallayer = {};
proto.com.hamropatro.sociallayer.io = require('./frontend-post-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.PostServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.PostServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetPostDetailRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPostDetailResponse>}
 */
const methodDescriptor_PostService_GetPostDetail = new grpc.web.MethodDescriptor(
  '/com.hamropatro.sociallayer.io.PostService/GetPostDetail',
  grpc.web.MethodType.UNARY,
  proto.com.hamropatro.sociallayer.io.GetPostDetailRequest,
  proto.com.hamropatro.sociallayer.io.GetPostDetailResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.GetPostDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetPostDetailRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPostDetailResponse>}
 */
const methodInfo_PostService_GetPostDetail = new grpc.web.AbstractClientBase.MethodInfo(
  proto.com.hamropatro.sociallayer.io.GetPostDetailResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.GetPostDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetPostDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetPostDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.PostServiceClient.prototype.getPostDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/GetPostDetail',
      request,
      metadata || {},
      methodDescriptor_PostService_GetPostDetail,
      callback);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetPostDetailResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.PostServicePromiseClient.prototype.getPostDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/GetPostDetail',
      request,
      metadata || {},
      methodDescriptor_PostService_GetPostDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse>}
 */
const methodDescriptor_PostService_GetPostSummary = new grpc.web.MethodDescriptor(
  '/com.hamropatro.sociallayer.io.PostService/GetPostSummary',
  grpc.web.MethodType.UNARY,
  proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest,
  proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse>}
 */
const methodInfo_PostService_GetPostSummary = new grpc.web.AbstractClientBase.MethodInfo(
  proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.PostServiceClient.prototype.getPostSummary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/GetPostSummary',
      request,
      metadata || {},
      methodDescriptor_PostService_GetPostSummary,
      callback);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.PostServicePromiseClient.prototype.getPostSummary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/GetPostSummary',
      request,
      metadata || {},
      methodDescriptor_PostService_GetPostSummary);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest,
 *   !proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse>}
 */
const methodDescriptor_PostService_InsertContentMetaData = new grpc.web.MethodDescriptor(
  '/com.hamropatro.sociallayer.io.PostService/InsertContentMetaData',
  grpc.web.MethodType.UNARY,
  proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest,
  proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest,
 *   !proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse>}
 */
const methodInfo_PostService_InsertContentMetaData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.PostServiceClient.prototype.insertContentMetaData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/InsertContentMetaData',
      request,
      metadata || {},
      methodDescriptor_PostService_InsertContentMetaData,
      callback);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.PostServicePromiseClient.prototype.insertContentMetaData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/InsertContentMetaData',
      request,
      metadata || {},
      methodDescriptor_PostService_InsertContentMetaData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.AddPostRequest,
 *   !proto.com.hamropatro.sociallayer.io.AddPostResponse>}
 */
const methodDescriptor_PostService_AddPost = new grpc.web.MethodDescriptor(
  '/com.hamropatro.sociallayer.io.PostService/AddPost',
  grpc.web.MethodType.UNARY,
  proto.com.hamropatro.sociallayer.io.AddPostRequest,
  proto.com.hamropatro.sociallayer.io.AddPostResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.AddPostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.AddPostResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.AddPostRequest,
 *   !proto.com.hamropatro.sociallayer.io.AddPostResponse>}
 */
const methodInfo_PostService_AddPost = new grpc.web.AbstractClientBase.MethodInfo(
  proto.com.hamropatro.sociallayer.io.AddPostResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.AddPostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.AddPostResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AddPostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.AddPostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.AddPostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.PostServiceClient.prototype.addPost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/AddPost',
      request,
      metadata || {},
      methodDescriptor_PostService_AddPost,
      callback);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AddPostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.AddPostResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.PostServicePromiseClient.prototype.addPost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/AddPost',
      request,
      metadata || {},
      methodDescriptor_PostService_AddPost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse>}
 */
const methodDescriptor_PostService_GetGroupPostsByCategory = new grpc.web.MethodDescriptor(
  '/com.hamropatro.sociallayer.io.PostService/GetGroupPostsByCategory',
  grpc.web.MethodType.UNARY,
  proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest,
  proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse>}
 */
const methodInfo_PostService_GetGroupPostsByCategory = new grpc.web.AbstractClientBase.MethodInfo(
  proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.PostServiceClient.prototype.getGroupPostsByCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/GetGroupPostsByCategory',
      request,
      metadata || {},
      methodDescriptor_PostService_GetGroupPostsByCategory,
      callback);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.PostServicePromiseClient.prototype.getGroupPostsByCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/GetGroupPostsByCategory',
      request,
      metadata || {},
      methodDescriptor_PostService_GetGroupPostsByCategory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse>}
 */
const methodDescriptor_PostService_GetGroupPostById = new grpc.web.MethodDescriptor(
  '/com.hamropatro.sociallayer.io.PostService/GetGroupPostById',
  grpc.web.MethodType.UNARY,
  proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest,
  proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse>}
 */
const methodInfo_PostService_GetGroupPostById = new grpc.web.AbstractClientBase.MethodInfo(
  proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.PostServiceClient.prototype.getGroupPostById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/GetGroupPostById',
      request,
      metadata || {},
      methodDescriptor_PostService_GetGroupPostById,
      callback);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.PostServicePromiseClient.prototype.getGroupPostById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/GetGroupPostById',
      request,
      metadata || {},
      methodDescriptor_PostService_GetGroupPostById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetPostsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPostsResponse>}
 */
const methodDescriptor_PostService_GetGroupPosts = new grpc.web.MethodDescriptor(
  '/com.hamropatro.sociallayer.io.PostService/GetGroupPosts',
  grpc.web.MethodType.UNARY,
  proto.com.hamropatro.sociallayer.io.GetPostsRequest,
  proto.com.hamropatro.sociallayer.io.GetPostsResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.GetPostsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.GetPostsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetPostsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPostsResponse>}
 */
const methodInfo_PostService_GetGroupPosts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.com.hamropatro.sociallayer.io.GetPostsResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.GetPostsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.GetPostsResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetPostsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetPostsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.PostServiceClient.prototype.getGroupPosts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/GetGroupPosts',
      request,
      metadata || {},
      methodDescriptor_PostService_GetGroupPosts,
      callback);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetPostsResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.PostServicePromiseClient.prototype.getGroupPosts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/GetGroupPosts',
      request,
      metadata || {},
      methodDescriptor_PostService_GetGroupPosts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.RemovePostRequest,
 *   !proto.com.hamropatro.sociallayer.io.RemovePostResponse>}
 */
const methodDescriptor_PostService_RemovePost = new grpc.web.MethodDescriptor(
  '/com.hamropatro.sociallayer.io.PostService/RemovePost',
  grpc.web.MethodType.UNARY,
  proto.com.hamropatro.sociallayer.io.RemovePostRequest,
  proto.com.hamropatro.sociallayer.io.RemovePostResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.RemovePostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.RemovePostResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.RemovePostRequest,
 *   !proto.com.hamropatro.sociallayer.io.RemovePostResponse>}
 */
const methodInfo_PostService_RemovePost = new grpc.web.AbstractClientBase.MethodInfo(
  proto.com.hamropatro.sociallayer.io.RemovePostResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.RemovePostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.RemovePostResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.RemovePostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.RemovePostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.RemovePostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.PostServiceClient.prototype.removePost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/RemovePost',
      request,
      metadata || {},
      methodDescriptor_PostService_RemovePost,
      callback);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.RemovePostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.RemovePostResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.PostServicePromiseClient.prototype.removePost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/RemovePost',
      request,
      metadata || {},
      methodDescriptor_PostService_RemovePost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.UpdatePostRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdatePostResponse>}
 */
const methodDescriptor_PostService_UpdatePost = new grpc.web.MethodDescriptor(
  '/com.hamropatro.sociallayer.io.PostService/UpdatePost',
  grpc.web.MethodType.UNARY,
  proto.com.hamropatro.sociallayer.io.UpdatePostRequest,
  proto.com.hamropatro.sociallayer.io.UpdatePostResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.UpdatePostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.UpdatePostResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.UpdatePostRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdatePostResponse>}
 */
const methodInfo_PostService_UpdatePost = new grpc.web.AbstractClientBase.MethodInfo(
  proto.com.hamropatro.sociallayer.io.UpdatePostResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.UpdatePostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.UpdatePostResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdatePostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.UpdatePostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.UpdatePostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.PostServiceClient.prototype.updatePost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/UpdatePost',
      request,
      metadata || {},
      methodDescriptor_PostService_UpdatePost,
      callback);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdatePostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.UpdatePostResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.PostServicePromiseClient.prototype.updatePost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/UpdatePost',
      request,
      metadata || {},
      methodDescriptor_PostService_UpdatePost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.UploadImageRequest,
 *   !proto.com.hamropatro.sociallayer.io.UploadImageResponse>}
 */
const methodDescriptor_PostService_UploadImage = new grpc.web.MethodDescriptor(
  '/com.hamropatro.sociallayer.io.PostService/UploadImage',
  grpc.web.MethodType.UNARY,
  proto.com.hamropatro.sociallayer.io.UploadImageRequest,
  proto.com.hamropatro.sociallayer.io.UploadImageResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.UploadImageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.UploadImageResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.UploadImageRequest,
 *   !proto.com.hamropatro.sociallayer.io.UploadImageResponse>}
 */
const methodInfo_PostService_UploadImage = new grpc.web.AbstractClientBase.MethodInfo(
  proto.com.hamropatro.sociallayer.io.UploadImageResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.UploadImageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.UploadImageResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UploadImageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.UploadImageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.UploadImageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.PostServiceClient.prototype.uploadImage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/UploadImage',
      request,
      metadata || {},
      methodDescriptor_PostService_UploadImage,
      callback);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UploadImageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.UploadImageResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.PostServicePromiseClient.prototype.uploadImage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/UploadImage',
      request,
      metadata || {},
      methodDescriptor_PostService_UploadImage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetPostRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPostResponse>}
 */
const methodDescriptor_PostService_GetPostById = new grpc.web.MethodDescriptor(
  '/com.hamropatro.sociallayer.io.PostService/GetPostById',
  grpc.web.MethodType.UNARY,
  proto.com.hamropatro.sociallayer.io.GetPostRequest,
  proto.com.hamropatro.sociallayer.io.GetPostResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.GetPostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.GetPostResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetPostRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPostResponse>}
 */
const methodInfo_PostService_GetPostById = new grpc.web.AbstractClientBase.MethodInfo(
  proto.com.hamropatro.sociallayer.io.GetPostResponse,
  /**
   * @param {!proto.com.hamropatro.sociallayer.io.GetPostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.hamropatro.sociallayer.io.GetPostResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetPostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetPostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.PostServiceClient.prototype.getPostById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/GetPostById',
      request,
      metadata || {},
      methodDescriptor_PostService_GetPostById,
      callback);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetPostResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.PostServicePromiseClient.prototype.getPostById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.hamropatro.sociallayer.io.PostService/GetPostById',
      request,
      metadata || {},
      methodDescriptor_PostService_GetPostById);
};


module.exports = proto.com.hamropatro.sociallayer.io;

