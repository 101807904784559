import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";
import styles from "./styles";
import UserConfirmationDialog from "../../../components/UserConfirmationDialog";
import {GetGroupRequest} from "../../../generated/frontend-community-group-service_pb";
import {getGroupDetails} from "../../../grpcRequests/groups-request";
import {checkIfLogin, forceTokenRefresh} from "../../../configurations/global-config";
import {AcceptInvitationToBusinessAccountRequest} from "../../../generated/frontend-business-account-service_pb";
import {acceptDirectJoinAsRole} from "../../../grpcRequests/business-groups-request";
import {eventAnalyst, pageViewAnalyst} from "../../../configurations/config";
import usedString, {getGroupTypeStringInSentence, getRoleString} from "../../../utils/mapper/enumToStringMapper";
import PleaseWait from "../../../components/PleaseWait";
import {memberCountValue} from "../../../utils/converter/shortenString";
import {Dashboard as DashboardLayout} from 'layouts';
import About from "../GroupDetails/components/About";


class DirectAdmin extends Component {
    state = {
        openConfirmation: false,
        group: null,
        fetchedGroup: false,
        fetchingMsg: null,
        userRole: null,
        userStatus: null,
        isAlreadyMember: false
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        pageViewAnalyst('Direct Admin', this.props.location.pathname);
        checkIfLogin(this.props.location.pathname);
        this.fetchGroupDetails();
    }

    fetchGroupDetails() {
        let self = this;
        const req = new GetGroupRequest();
        req.setGroupId(this.props.match.params.groupId);
        this.setState({fetchingMsg: 'Please Wait..'});
        getGroupDetails(req, 3).then(function (response) {
            const group = response.getGroup();
            const userRole = group.getRole();
            const userStatus = group.getStatus();
            const fetchedGroup = true;
            self.setState({
                fetchedGroup,
                group,
                userStatus,
                userRole,
            });
            if (getRoleString(userRole) === usedString.ADMIN || getRoleString(userRole) === usedString.PARTICIPANT) {
                self.setState({isAlreadyMember: true});
                self.goToGroupDirect(self.props.match.params.groupId)
            }
            else {
                self.openConfirmation();
            }
        }).catch(function (error) {
            self.setState({fetchedGroup: false, fetchingMsg: 'Something went wrong'});
        })
    }

    openConfirmation = () => {
        this.setState({openConfirmation: true});
    };

    getUserResponseFromChild = (clickedButton, key) => {
        if (clickedButton === 'Accept') {
            this.directJoinAsRole();
        } else window.location.href = "/home" + forceTokenRefresh();
    };

    getUserResponseFromChildIfAlreadyLogin = (clickedButton, key) => {
        this.setState({openConfirmation: false});
        if (clickedButton === "community") {
            this.goToGroup(this.props.match.params.groupId)
        } else
            window.location.href = '/home' + forceTokenRefresh();
        ;
    };


    directJoinAsRole = () => {
        let self = this;
        const req = new AcceptInvitationToBusinessAccountRequest();
        req.setSecretKey(this.props.match.params.token.split('=')[1]);
        req.setRedirectlink(window.location.origin + "/_admin/c/" + this.props.match.params.groupId + "/type/join");
        acceptDirectJoinAsRole(req).then(function (response) {
            eventAnalyst('directAdmin_link', self.props.match.params.groupId + '_direct_admin_accept', self.props.match.params.groupId + ' direct admin join success', self.state.groupId + '-direct-admin-join-success');
            self.goToGroup(self.props.match.params.groupId)
        }).catch(function (error) {
            eventAnalyst('directAdmin_link', self.props.match.params.groupId + '_direct_admin_accept', self.props.match.params.groupId + ' direct admin join fail', self.state.groupId + '-direct-admin-join-fail');
        })
    };

    goToGroup = (groupId) => {
        window.location.href = '/c/' + groupId + forceTokenRefresh();
    };

    goToGroupDirect = (groupId) => {
        const {history} = this.props;
        history.push('/c/' + groupId + forceTokenRefresh());
    };

    render() {
        const {classes} = this.props;
        const {openConfirmation, fetchedGroup, isAlreadyMember, group, fetchingMsg} = this.state;
        return (
            <div>
                {fetchedGroup ? (
                    <div>
                        {isAlreadyMember ? (
                            <PleaseWait msg="Already admin or moderator. Redirecting...."/>
                        ) : (
                            <div>
                                {group ? (
                                    <div>
                                        <div>
                                            {openConfirmation ? (
                                                <UserConfirmationDialog
                                                    openDialog={openConfirmation}
                                                    dialogHeader="Community Join Invitation"
                                                    postId="124"
                                                    dialogContent={"You are Invited to join as ADMIN/MODERATOR "}
                                                    button1="Reject" button2="Accept"
                                                    toDeleteContent={<div className={classes.sharableLinkWrapper}>
                                                <span
                                                    className={classes.sharableText}> {group.getName()}</span>
                                                    </div>}
                                                    sendUserResponse={this.getUserResponseFromChild}
                                                />
                                            ) : (<div></div>)}
                                        </div>
                                        <DashboardLayout title='Community Admin Invitation' onBack="/home"
                                                         linkTo="/home">
                                            <div>
                                                <div style={{background: 'aliceblue', opacity: 0.5}}>
                                                    <div style={{
                                                        paddingTop: '56.25%',
                                                        position: 'relative',
                                                        width: '100%',
                                                        overflow: 'hidden'
                                                    }}>
                                                        <img
                                                            alt="Community_Image"
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                position: 'absolute',
                                                                top: '0',
                                                                left: '0',
                                                                bottom: '0',
                                                                right: '0',
                                                                objectFit: 'cover'
                                                            }}
                                                            src={group.getCoverimage() ? group.getCoverimage() : '/images/defaultCover.png'}
                                                        />
                                                    </div>
                                                    <br/>
                                                    <div style={{textAlign: 'center', padding: '15px 10px'}}>
                                                        <Typography
                                                            className={classes.groupName}
                                                            variant="h5"
                                                            style={{fontWeight: 600}}
                                                        >
                                                            {group.getName()}<br/>
                                                        </Typography>
                                                        <Typography
                                                            className={classes.groupTypeMember}
                                                            variant="subtitle1"
                                                        >
                                                            <span>{getGroupTypeStringInSentence(group.getGrouptype())} Community </span>| <span
                                                            title='Community members'
                                                            className={classes.followerCount}>{memberCountValue(group.getFollowerscount())} Members</span>
                                                        </Typography>
                                                    </div>
                                                    <About group={group}
                                                           groupType={getGroupTypeStringInSentence(group.getGrouptype())}
                                                           history={this.props}/>
                                                </div>
                                            </div>
                                        </DashboardLayout>
                                    </div>
                                ) : (<div>Something went wrong</div>)}
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <PleaseWait msg="Getting ready.."/>
                    </div>
                )}
            </div>
        )
    }
}

DirectAdmin.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DirectAdmin);