import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, withStyles} from "@material-ui/core";
import styles from "../../Home/styles";
import Typography from "@material-ui/core/Typography";
import {eventAnalyst} from "../../../configurations/config";
import {getUserLatLng} from "../../../utils/Client";
import {LocationInfo} from "../../../generated/frontend-social-common-types_pb";
import {GetNearbyGroupsRequest} from "../../../generated/frontend-community-group-service_pb";
import {EventsLoader} from "../../../components/Skeleton/EventsSket";
import {getNearByEvent} from "../../../grpcRequests/events-request";
import NearByEventCard from "./NearByEventCard";
import IconButton from "@material-ui/core/IconButton";
import {ArrowRightAltOutlined as CalendarIcon} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import {removeSpace} from "../../../utils/converter/common";


const defaultPageSize = 20;

class NearByCommunity extends Component {
    state = {
        history: this.props.history,
        isLoadingNearByEvents: false,
        nearByEventLists: [],
        errNearByEventMsg: null,
        title: 'Events Near You',
        userAddress: '',
        nearEventsCursor: '',
        nearEventsPreviousCursor: '',
        loadingMoreNearEvents: false,
        locationData: '',
    };

    componentDidMount() {
        this.fetchNearByEvent();
    }

    nearByEvent = (cursorValue, pageSize, previousData, prevCursor) => {
        let self = this;
        this.setState({
            isLoadingNearByEvents: !prevCursor
        });
        let res = this.state.locationData;
        let userAddress = res;
        const location = new LocationInfo();
        location.setLat(userAddress.lat);
        location.setLon(userAddress.lng);

        const req = new GetNearbyGroupsRequest();
        req.setLocation(location);
        req.setAlgorithm();
        req.setCursor(cursorValue);
        req.setPageSize(pageSize);
        getNearByEvent(req, 3).then(function (response) {
            eventAnalyst('home', 'fetch_near_events', 'Near events fetch success', 'pending-events-fetch-success');
            let nearByEventLists = previousData;
            for (let i = 0; i < response.getEventList().length; i++) {
                let newData = response.getEventList()[i];
                nearByEventLists.push(newData);
            }
            const isLoadingNearByEvents = false;
            const loadingMoreNearEvents = false;
            const nearEventsCursor = response.getNextPageCursor();
            self.setState({
                isLoadingNearByEvents,
                nearByEventLists,
                nearEventsCursor,
                loadingMoreNearEvents,
                nearEventsPreviousCursor: prevCursor,
                userAddress: res.city + ', ' + res.country
            });
        }).catch(function (error) {
            const isLoadingNearByEvents = false;
            const errNearByEventMsg = "Something went wrong";
            const loadingMoreNearEvents = false;
            self.setState({
                isLoadingNearByEvents,
                errNearByEventMsg,
                loadingMoreNearEvents,
                userAddress: ''
            });
        })
    };

    fetchNearByEvent = () => {
        let self = this;
        getUserLatLng().then(function (res) {
            self.setState({locationData: res});
            self.nearByEvent('', defaultPageSize, [], '');
        }).catch(function (error) {
        });
    };

    goToEvents() {
        eventAnalyst('home_view_link_redirect', 'view_upcoming_events', 'View Upcoming event click success', 'view-upcoming-event-click-success');
        const {history} = this.state.history;
        let countryName = this.state.locationData.country;
        history.push('/explore/events/' + removeSpace(countryName));
    };

    render() {
        const {classes} = this.props;
        const {isLoadingNearByEvents, nearByEventLists, errNearByEventMsg, title, nearEventsCursor, loadingMoreNearEvents, nearEventsPreviousCursor} = this.state;
        return (
            <div>
                {!isLoadingNearByEvents ? (
                    <div>
                        {nearByEventLists.length > 0 ? (
                            <div className={classes.boxItems}>
                                <div>
                                    <div>
                                        <div className={classes.floatLeft}>
                                            <Typography
                                                variant="h6"
                                                style={{fontWeight: 900}}
                                            >
                                                <span>{title}</span>
                                            </Typography>
                                        </div>
                                        <div className={classes.floatRight}>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                onClick={() => this.goToEvents()}
                                                className={classes.buttonView}
                                            >
                                                Explore
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={classes.simpleUnderline}></div>
                                </div>
                                <div className={classes.homeCardWrapper}>
                                    <NearByEventCard eventList={nearByEventLists} title={title}/>
                                    {nearEventsCursor && nearEventsPreviousCursor !== nearEventsCursor ? (
                                        <div className={classes.seeMoreWrapper}>
                                            <div
                                                onClick={() => this.loadMore(nearEventsCursor, defaultPageSize, nearByEventLists, nearEventsCursor)}
                                                className={classes.iconTextWrapper}>
                                                <div className={classes.iconWrapper}>
                                                    <IconButton aria-label="See More"
                                                                title="See More"
                                                                size="small"
                                                                color={"primary"}>
                                                        <CalendarIcon style={{fontSize: '38px'}}/>
                                                    </IconButton>
                                                </div>
                                                <div className={classes.textSeeMore}>
                                                    {loadingMoreNearEvents ? (
                                                        <CircularProgress size={20}/>) : 'See More'}
                                                </div>
                                            </div>
                                        </div>

                                    ) : (<span></span>)}
                                </div>
                            </div>
                        ) : (
                            <div>
                                {errNearByEventMsg ? (
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid item>
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                {errNearByEventMsg}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        )}
                    </div>
                ) : (<EventsLoader/>)}
            </div>
        )
    }
}


NearByCommunity.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NearByCommunity);