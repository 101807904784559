import axios from 'axios';

let retryTimes = 3;
export function uploadImageToDigitalOcean(file, uploadImageURL) {
    return new Promise(function (resolve, reject) {
        axios({
            method: 'put',
            url: uploadImageURL,
            headers: {
                'Content-Type': file.type, 'x-amz-acl': 'public-read'
            },
            data: file
        }).then(function (response) {
            if (response.status === 200) {
                let obj = {
                    status: true,
                    image: uploadImageURL.split('?')[0]
                };
                resolve(obj);
            } else {
                let obj = {
                    status: false
                };
                reject(obj);
            }
        }).catch(function (error) {
            let obj = {
                status: false
            };
            reject(obj);
        });
    });

}