import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";
import styles from "./styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import {ContentUserReactionInfoRequest} from "../../generated/frontend-user-reaction-to-content-service_pb"
import {ReactionType} from "../../generated/frontend-social-common-types_pb";
import {getUserLikedBy} from "../../grpcRequests/userContent-reaction-request";
import {UserLoader} from "../Skeleton/EventsSket";


function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class PostLikedUsers extends Component {
    state = {
        value: 0,
        openDialog: true,
        likedUser: null
    };

    componentDidMount() {
        this.fetchLikedUsers();
    }

    sendResponse = () => {
        this.props.sendCloseReport(true);
    };

    handleChangeIndex = (event, value) => {
        this.setState({value});
    };

    closeEventDialog = () => {
        this.setState({openDialog: false});
        this.sendResponse();
    };
    fetchLikedUsers = () => {
        let self = this;
        const req = new ContentUserReactionInfoRequest();
        req.setContentid(this.props.postId);
        req.setType(ReactionType.LIKE);
        req.setCursor('');
        req.setPagesize();
        getUserLikedBy(req, 3).then(function (response) {
            var likedBy = response.getContentuserreactioninfoList();
            self.setState({likedUser: likedBy})
        }).catch(function (error) {
        })
    };

    render() {
        const {classes, postId} = this.props;
        const {value, openDialog, likedUser} = this.state;
        return (
            <div>
                <Dialog
                    fullWidth
                    open={openDialog}
                    onClose={this.closeEventDialog}
                    aria-labelledby="responsive-dialog-title"
                >
                    <div>
                        <AppBar position="static" color="" className={classes.detailTabs}>
                            <Tabs
                                centered
                                value={value}
                                onChange={this.handleChangeIndex}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label="LIKED BY"/>
                            </Tabs>
                        </AppBar>
                        {value === 0 &&
                        <TabContainer>
                            {likedUser ? (
                                <div>
                                    {likedUser.length > 0 ? (
                                        <div>
                                            {likedUser.map(user => (
                                                <div
                                                    className={classes.product}
                                                >
                                                    <div className={classes.userImageWrapper}>
                                                        <img
                                                            alt={user.getReactionuserinfo().getAccountName()}
                                                            className={classes.productImage}
                                                            src={user.getReactionuserinfo().getAccountImage().getImageUrl()}
                                                        />
                                                    </div>
                                                    <div className={classes.productDetails}>
                                                        <Typography
                                                            className={classes.productTitle}
                                                            variant="body1"
                                                            style={{fontWeight: 600}}
                                                        >
                                                            {user.getReactionuserinfo().getAccountName()}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>) : (
                                        <div className={classes.noMemberWrapper}>No liked user yet.</div>)}
                                </div>) : (<div><UserLoader/></div>)}
                        </TabContainer>}
                    </div>
                </Dialog>
            </div>
        )
    }
}

PostLikedUsers.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PostLikedUsers);