/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$ticket$common$types_pb = require('./frontend-ticket-common-types_pb.js');
goog.object.extend(proto, frontend$ticket$common$types_pb);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.PaymentMethod', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.PaymentRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.PaymentResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.PaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.PaymentRequest.displayName = 'proto.com.hamropatro.sociallayer.io.PaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.PaymentResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.PaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.PaymentResponse.displayName = 'proto.com.hamropatro.sociallayer.io.PaymentResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.PaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.PaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ticketTypeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paymentmethod: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paymentToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    purchaseQuantity: jspb.Message.getFieldWithDefault(msg, 6, 0),
    payeeInfo: (f = msg.getPayeeInfo()) && frontend$ticket$common$types_pb.PayeeInfo.toObject(includeInstance, f),
    uniqueIdentifier: jspb.Message.getFieldWithDefault(msg, 8, ""),
    metaDataMap: (f = msg.getMetaDataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.PaymentRequest}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.PaymentRequest;
  return proto.com.hamropatro.sociallayer.io.PaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.PaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.PaymentRequest}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketTypeId(value);
      break;
    case 4:
      var value = /** @type {!proto.com.hamropatro.sociallayer.io.PaymentMethod} */ (reader.readEnum());
      msg.setPaymentmethod(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentToken(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPurchaseQuantity(value);
      break;
    case 7:
      var value = new frontend$ticket$common$types_pb.PayeeInfo;
      reader.readMessage(value,frontend$ticket$common$types_pb.PayeeInfo.deserializeBinaryFromReader);
      msg.setPayeeInfo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueIdentifier(value);
      break;
    case 9:
      var value = msg.getMetaDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.PaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.PaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTicketTypeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaymentmethod();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPaymentToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPurchaseQuantity();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPayeeInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      frontend$ticket$common$types_pb.PayeeInfo.serializeBinaryToWriter
    );
  }
  f = message.getUniqueIdentifier();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMetaDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.getAppId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.setAppId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string source_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.getSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.setSourceId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ticket_type_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.getTicketTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.setTicketTypeId = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PaymentMethod paymentMethod = 4;
 * @return {!proto.com.hamropatro.sociallayer.io.PaymentMethod}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.getPaymentmethod = function() {
  return /** @type {!proto.com.hamropatro.sociallayer.io.PaymentMethod} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.PaymentMethod} value */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.setPaymentmethod = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string payment_token = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.getPaymentToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.setPaymentToken = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 purchase_quantity = 6;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.getPurchaseQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.setPurchaseQuantity = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional PayeeInfo payee_info = 7;
 * @return {?proto.com.hamropatro.sociallayer.io.PayeeInfo}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.getPayeeInfo = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.PayeeInfo} */ (
    jspb.Message.getWrapperField(this, frontend$ticket$common$types_pb.PayeeInfo, 7));
};


/** @param {?proto.com.hamropatro.sociallayer.io.PayeeInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.setPayeeInfo = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.clearPayeeInfo = function() {
  this.setPayeeInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.hasPayeeInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string unique_identifier = 8;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.getUniqueIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.setUniqueIdentifier = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * map<string, string> meta_data = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.getMetaDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.com.hamropatro.sociallayer.io.PaymentRequest.prototype.clearMetaDataMap = function() {
  this.getMetaDataMap().clear();
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.PaymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.PaymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketTypeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    chargedAmount: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    paymentTimestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    paymentCurrency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paymentSuccessful: jspb.Message.getFieldWithDefault(msg, 5, false),
    ticketIdList: jspb.Message.getRepeatedField(msg, 6),
    paymentConfirmationId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.PaymentResponse}
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.PaymentResponse;
  return proto.com.hamropatro.sociallayer.io.PaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.PaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.PaymentResponse}
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketTypeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChargedAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaymentTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentCurrency(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaymentSuccessful(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addTicketId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentConfirmationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.PaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.PaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketTypeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChargedAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getPaymentTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPaymentCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPaymentSuccessful();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTicketIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getPaymentConfirmationId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string ticket_type_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.getTicketTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.setTicketTypeId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double charged_amount = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.getChargedAmount = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.setChargedAmount = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 payment_timestamp = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.getPaymentTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.setPaymentTimestamp = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string payment_currency = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.getPaymentCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.setPaymentCurrency = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool payment_successful = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.getPaymentSuccessful = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.setPaymentSuccessful = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated string ticket_id = 6;
 * @return {!Array<string>}
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.getTicketIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/** @param {!Array<string>} value */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.setTicketIdList = function(value) {
  jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.addTicketId = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.clearTicketIdList = function() {
  this.setTicketIdList([]);
};


/**
 * optional string payment_confirmation_id = 7;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.getPaymentConfirmationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PaymentResponse.prototype.setPaymentConfirmationId = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.PaymentMethod = {
  STRIPE: 0,
  BANK_DEBIT_CARD: 1,
  RSVP: 2,
  KHALTI: 3,
  ESEWA: 4
};

goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
