export default theme => ({
    root: {
        padding: theme.spacing(1),
        minHeight: '85vh'
    },
    floatRight: {
        float: 'right'
    },
    formWrapper: {
        padding: '10px 5px',
        margin: '10px 5px'
    },
    formWrapperAddition: {
        padding: '10px 5px',
        margin: '10px 5px'
    },
    eventTitle: {
        color: 'brown'
    },
    groupImgWrapper: {
        width: '150px',
        overflow: 'hidden',
    },
    image: {
        width: '100%'
    },
    imageWrapper: {
        width: '150px',
        overflow: 'hidden',
        borderRadius: '5px',
        cursor: 'pointer',
        border: '1px dotted #e5e5e59e',
    },
    imageToUpload: {
        width: '100%'
    },
    required: {
        marginRight: '2px',
        marginLeft: '2px',
        color: '#af3d30',
        fontSize: '13px'
    },
    backgroundImage: {
        width: '100%',
        height: '250px',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%'
    },
    coverImageText: {
        margin: '10px',
        width: 'fit-content',
        background: '#ffffff',
        padding: '10px',
        borderRadius: '5px',
        textAlign: 'center',
        opacity: '0.9'
    },
    textField: {
        '&input': {
            padding: '5px'
        }
    }
});
