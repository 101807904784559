import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles, withWidth} from "@material-ui/core";
import styles from "./styles";
import Avatar from "@material-ui/core/Avatar";
import {GetReplyRequest, ReactOnCommentReplyRequest} from "../../generated/frontend-reply-service_pb";
import {getReplyDetails, reactOnReply} from "../../grpcRequests/reply-request";
import {ReactionType} from "../../generated/frontend-social-common-types_pb";
import SnackBar from "../SnackBar/SnackBar";
import compose from "recompose/compose";
import PostLikedUsers from "../Dialogs/PostLikedUsers";
import LoginRequiredDialog from "../Dialogs/LoginRequiredDialog";
import {checkIfLogin, isLogin, userWhoCanPost} from "../../configurations/global-config";


const defaultPageSize = 5;
const disableLike = [];
const userLiked = [];
const likeCount = [];

class UserReply extends Component {
    state = {
        commentId: this.props.commentId,
        commentPostId: this.props.commentPostId,
        replyId: this.props.replyId,
        addMe: 0,
        openShowUsers: false, sendReplyId: '',

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null,
        //Login
        openLoginDialog: false,
        openJoinGroupDialog: false,
    };

    componentDidMount() {
        userLiked[this.props.replyId] = this.props.userLikeSts;
        likeCount[this.props.replyId] = this.props.commentLikes;
        this.setState({
            addMe: 0,
        });
    }

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    getJoinGroupDialogRes = (clickedButton, key) => {
        this.setState({openJoinGroupDialog: false});
        if (clickedButton === "view community") {
            // console.log('clicked join');
            window.location.pathname = '/c/' + this.props.groupId
        }
    };

    openJoinDialog = () => {
        this.setState({openJoinGroupDialog: true});
    };


    likeComment = (replyID) => {
        if (!isLogin()) {
            this.openLoginDialog()
        } else if (!userWhoCanPost(this.props.userRole, this.props.userRoleStatus)) {
            this.openJoinDialog()
        } else {
            let self = this;
            disableLike[replyID] = true;
            const req = new ReactOnCommentReplyRequest();

            req.setPostUri(this.props.commentPostId);
            req.setCommentId(this.props.commentId);
            req.setReplyId(replyID);
            // req.setAccountId();
            self.setState({addMe: 0});
            if (userLiked[replyID]) {
                userLiked[replyID] = false;
                likeCount[replyID] -= 1;
                req.setReactionType(ReactionType.UNLIKE);
            } else {
                userLiked[replyID] = true;
                likeCount[replyID] += 1;
                req.setReactionType(ReactionType.LIKE);
            }
            reactOnReply(req).then(function (response) {
                disableLike[replyID] = false;
                self.getReplyDetails(replyID);
            }).catch(function (error) {
                disableLike[replyID] = false;
            })
        }

    };


    getReplyDetails = (replyID) => {
        let self = this;
        const req = new GetReplyRequest();
        req.setPostUri(this.props.commentPostId);
        req.setCommentId(this.props.commentId);
        req.setReplyId(replyID);

        getReplyDetails(req, 3).then(function (response) {

            let responseMsg = response.getUserreply();
            userLiked[replyID] = responseMsg.getLiked();
            likeCount[replyID] = responseMsg.getLikes();
            self.setState({
                addme: 0
            })
        }).catch(function (error) {

        })
    };

    openLoginDialog = () => {
        this.setState({openLoginDialog: true});
    };

    getLoginDialogRes = (clickedButton, key) => {
        this.setState({openLoginDialog: false});
        if (clickedButton === "login") {
            checkIfLogin(window.location.pathname);
        }
    };

    getResFrmChild = (status) => {
        this.setState({openShowUsers: false});
    };

    openLikedByDialogs = (replyID) => {
        this.setState({openShowUsers: true, sendReplyId: replyID});
    };

    render() {
        const {classes, commentId, replyId, width, userRole, groupName,groupId} = this.props;
        const {openShowUsers,addMe, sendReplyId, openLoginDialog,openJoinGroupDialog} = this.state;
        return (
            <div>
                <div className={classes.userAction}>
                    <Typography
                        className={classes.commentClass}
                        component="span"
                        color="textPrimary"
                        variant={"body2"}
                        style={userLiked[replyId] ? {
                            fontWeight: 600,
                            color: '#4ea3fe',
                            padding: '0',
                            margin: '0'
                        } : {color: '#4ea3fe', padding: '0', margin: '0'}}
                        onClick={disableLike[replyId] ? '' : () => this.likeComment(replyId)}>
                        {userLiked[replyId] ? 'Liked' : 'Like'}
                    </Typography>
                    <span
                        style={{padding: '0 5px'}}> {likeCount[replyId] && likeCount[replyId] > 0 ? likeCount[replyId] + addMe : ''} </span>
                </div>
                <div className={classes.likedUser}
                     onClick={() => this.openLikedByDialogs(replyId)}>
                    {likeCount[replyId] && likeCount[replyId] > 0 ? (
                        <Avatar
                            className={classes.likedUserImg}
                            style={{
                                margin: '1px',
                                marginLeft: '-7px'
                            }}>
                                                                <span
                                                                    className={classes.morePeople}
                                                                    title="more likes">
                                                                    +{likeCount[replyId] + addMe}
                                                                </span>
                        </Avatar>
                    ) : (<div></div>)}
                </div>

                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
                <div>{openShowUsers ? (
                    <PostLikedUsers sendCloseReport={this.getResFrmChild} postId={sendReplyId}/>) : (
                    <div></div>)}
                </div>
                <div>
                    {openLoginDialog ? (
                        <LoginRequiredDialog
                            openDialog={openLoginDialog} dialogHeader=""
                            postId="124"
                            dialogContent={"Please login to continue"} button1="cancel"
                            button2="login"
                            sendUserResponse={this.getLoginDialogRes}
                        />
                    ) : (<div></div>)}
                </div>
                <div>
                    {openJoinGroupDialog ? (
                        <LoginRequiredDialog
                            openDialog={openJoinGroupDialog} dialogHeader=""
                            postId="124"
                            dialogContent={"You are not associated with '"+groupName+"'. Please join the community to continue and start posting to the community."}
                            button1="cancel"
                            button2="view community"
                            sendUserResponse={this.getJoinGroupDialogRes}
                        />
                    ) : (<div></div>)}
                </div>
            </div>
        )
    }
}

UserReply.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withWidth())(UserReply);