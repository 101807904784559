import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";
import {Link} from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import styles from "../styles";
import {GetPreviousEventsRequest,} from "../../../generated/frontend-community-event-service_pb";
import {getPreviousEvents} from "../../../grpcRequests/events-request";
import {EventsLoader} from "../../../components/Skeleton/EventsSket";
import usedString, {getUserResponseString} from "../../../utils/mapper/enumToStringMapper";
import {formatDate} from "../../../utils/converter/dateConverter";
import Icon from "@material-ui/core/Icon";
import {eventAnalyst} from "../../../configurations/config";
import IconButton from "@material-ui/core/IconButton";
import {CalendarTodayRounded as CalendarIcon} from "@material-ui/icons";

const userEventStat = [];

class PreviousEvents extends Component {
    state = {
        groupId: this.props.groupID,
        usrGrpRole: this.props.role,
        groupEvents: [],
        changeStatus: true,
        isLoadingGroupEvents: true,
        errGroupMessage: ''
    };

    componentDidMount() {
        this.fetchUpcomingEvents();
    }

    fetchUpcomingEvents = () => {
        let self = this;
        const req = new GetPreviousEventsRequest();
        req.setGroupId(this.state.groupId);
        req.setYear(new Date().getFullYear());
        req.setCurrentMonth(new Date().getMonth() + 1);
        req.setBeforeDay(new Date().getDate());
        req.setCursor();
        req.setPageSize(20);
        getPreviousEvents(req, 3).then(function (response) {
            eventAnalyst('community_detail_event', 'community_detail_event_fetch', self.state.groupId + '-community event detail fetch success', 'community-event-detail-fetch-success');
            const groupEvents = response.getEventDetailList();
            groupEvents.forEach(function (singleEvent) {
                userEventStat[singleEvent.getEvent().getEventId()] = getUserResponseString(singleEvent.getUserAttendingStatus());
            });
            const isLoadingGroupEvents = false;
            self.setState({
                isLoadingGroupEvents,
                groupEvents
            });
        }).catch(function (error) {
            eventAnalyst('community_detail_event', 'community_detail_event_fetch', self.state.groupId + '-community event detail fetch fail', 'community-event-detail-fetch-fail');
            const isLoadingGroupEvents = false;
            const errGroupMessage = error.message;
            self.setState({
                isLoadingGroupEvents,
                errGroupMessage
            });
        })
    };

    render() {
        const {classes} = this.props;
        const {isLoadingGroupEvents, groupEvents, errGroupMessage, groupId, changeStatus} = this.state;
        const rootGroupsURl = '/c/' + groupId;
        return (
            <div>
                {!isLoadingGroupEvents ? (
                    <div>
                        {groupEvents.length > 0 ? (
                                <div className={classes.eventsCard}>
                                    <div className={classes.headersWrapper}>
                                        <div>
                                            <div>
                                                <Typography
                                                    variant="h6"
                                                    style={{fontWeight: 900, padding: '15px 0 0'}}
                                                >
                                                    Previous Events
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className={classes.simpleUnderline}></div>
                                    </div>
                                    <div className={classes.eventCardsWrapper}>
                                        {groupEvents.map(event => (
                                            <div className={classes.eventCard}>
                                                <Card className={classes.card}>
                                                    <Link
                                                        to={`${rootGroupsURl}/events/${event.getEvent().getEventId()}`}
                                                        onClick={() => eventAnalyst('group_event_card_click', event.getEvent().getTitle() + '_clicked', event.getEvent().getTitle(), event.getEvent().getTitle() + '-detail-click-success')}>
                                                        <CardMedia
                                                            className={classes.media}
                                                            image={event.getEvent().getEventCoverImage() ? event.getEvent().getEventCoverImage() : '/images/eventDefault.png'}
                                                            title={event.getEvent().getTitle()}
                                                        />
                                                    </Link>
                                                    <CardContent className={classes.cardName}>
                                                        <div>
                                                            <Link
                                                                to={`${rootGroupsURl}/events/${event.getEvent().getEventId()}`}
                                                                onClick={() => eventAnalyst('group_event_link_click', event.getEvent().getTitle() + '_clicked', event.getEvent().getTitle(), event.getEvent().getTitle() + '-detail-click-success')}>
                                                                <Typography
                                                                    component="div"
                                                                    className={classes.eventTime}>
                                                                <span>
                                                                    {event.getEvent().getTitle()}
                                                                </span>
                                                                </Typography>
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            <Typography
                                                                className={classes.eventName}
                                                            >
                                                                <span>{formatDate(event.getEvent().getStartFrom(), 'MMM dd, p')}</span>
                                                                <div className={classes.interested}>
                                                                    {(userEventStat[event.getEvent().getEventId()] === usedString.NOT_GOING || userEventStat[event.getEvent().getEventId()] === usedString.GOING) && changeStatus && event.getEvent().getEnds() >= new Date().getTime() ? (
                                                                        <div>
                                                                            {userEventStat[event.getEvent().getEventId()] === usedString.GOING ? (
                                                                                <span
                                                                                    title="You are going to this event">
                                                                                    <Icon className={classes.icon}
                                                                                          color="primary">
                                                                                        check_circle
                                                                                    </Icon>
                                                                                </span>
                                                                            ) : (
                                                                                <span></span>
                                                                            )}
                                                                        </div>) : (<div></div>)}
                                                                </div>
                                                            </Typography>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        ))}
                                        <div style={{marginTop: '35px'}}>
                                            <Link
                                                to={`${rootGroupsURl}/calendar`}>
                                                <div style={{textAlign: 'center', margin: '10px 25px', cursor: "pointer"}}>
                                                    <div style={{
                                                        padding: '50px', background: '#f4f6f7', borderRadius: '50%'
                                                    }}>
                                                        <IconButton aria-label="View Calendar"
                                                                    title="View Calendar"
                                                                    size="small"
                                                                    color={"primary"}>
                                                            <CalendarIcon style={{fontSize: '38px'}}/>
                                                        </IconButton>
                                                    </div>
                                                    <div style={{paddingTop: '10px', fontWeight: 600}}>Calendar
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>) :
                            (<div></div>)}
                    </div>
                ) : (<span>{EventsLoader()}</span>)}
            </div>
        )
    }
}

PreviousEvents.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PreviousEvents);