import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styles
import styles from './styles';

import {checkIfLogin} from "../../configurations/global-config";
import {pageViewAnalyst} from "../../configurations/config";

class Profile extends Component {
    state = {tabIndex: 0};

    componentDidMount() {
        pageViewAnalyst('Profile', this.props.location.pathname);
        checkIfLogin(this.props.location.pathname);
    }

    render() {
        const {classes} = this.props;
        return (
            <DashboardLayout title="Profile">
                <div className={classes.root}>
                    You will see your profile soon.
                    {/*UserID:{userInfo().user_id} <br/>*/}
                    {/*User Name:{userInfo().displayName} <br/>*/}
                    {/*User Email:{userInfo().email} <br/>*/}
                    {/*Photo URl:{userInfo().photo_url} <br/>*/}
                    {/*User Id:{userInfo().user_id} <br/>*/}
                    {/*diff ={new Date().getTime() - localStorage.getItem(globalConfig.appId + "_time")}*/}
                    {/*expire*/}
                    {/*after:{new Date(JSON.parse((localStorage.getItem(globalConfig.appId + "_time")))).toLocaleString()}<br/>*/}
                    {/*{localStorage.getItem(globalConfig.appId + "_time") > new Date().getTime() ? (*/}
                    {/*    <span>  timeRemaining:{timeRemaining(new Date(JSON.parse(localStorage.getItem(globalConfig.appId + "_time"))))}<br/></span>*/}
                    {/*) : (*/}
                    {/*    <span>  expired:{timeRemaining(new Date(JSON.parse(localStorage.getItem(globalConfig.appId + "_time"))))}<br/></span>)}*/}
                </div>
            </DashboardLayout>
        );
    }
}

Profile.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Profile);
