export default [
    {
        title: 'A',
        countries: [
            {
                name: 'Afghanistan',
                offset: '+04:30'
            },
            {
                name: 'Aland Islands (Finland)',
                offset: '+02:00'
            },
            {
                name: 'Albania',
                offset: '+01:00'
            },
            {
                name: 'Algeria',
                offset: '+01:00'
            },
            {
                name: 'ACWST',
                offset: '+08:45'
            },
            {
                name: 'ACWST',
                offset: '+12:45'
            },
            {
                name: 'Anadyr',
                offset: '+12:00'
            },
            {
                name: 'ANAT',
                offset: '+12:00'
            },
            {
                name: 'AEST',
                offset: '+10:00'
            },
            {
                name: 'Alofi',
                offset: '-11:00'
            },
            {
                name: 'AoE',
                offset: '-12:00'
            },
            {
                name: 'ACST',
                offset: '+09:30'
            },
            {
                name: 'Adelaide',
                offset: '+09:30'
            },
            {
                name: 'Adak',
                offset: '-09:00'
            },
            {
                name: 'ART',
                offset: '-03:00'
            },
            {
                name: 'AKDT',
                offset: '-08:00'
            },
            {
                name: 'Anchorage',
                offset: '-08:00'
            },
            {
                name: 'Accra',
                offset: '+00:00'
            },
        ]
    },
    {
        title: 'N',
        countries: [
            {
                name: 'Nepal',
                offset: '+05:45'
            },
            {
                name: 'NPT',
                offset: '+05:45'
            },
            {
                name: 'Nepal Time',
                offset: '+05:45'
            },
            {
                name: 'Kathmandu',
                offset: '+05:45'
            },
            {
                name: 'New Zealand',
                offset: '+12:00'
            },
            {
                name: 'SBT',
                offset: '+11:00'
            },
            {
                name: 'Honiara',
                offset: '+11:00'
            },

            {
                name: 'Melbourne',
                offset: '+10:00'
            },
            {
                name: 'JST',
                offset: '+09:00'
            },
            {
                name: 'CST',
                offset: '+08:00'
            },
            {
                name: 'Beijing',
                offset: '+08:00'
            },
            {
                name: 'WIB',
                offset: '+07:00'
            },
            {
                name: 'Jakarta',
                offset: '+07:00'
            },
            {
                name: 'BST',
                offset: '+06:00'
            },
            {
                name: 'Dhaka',
                offset: '+06:00'
            },
            {
                name: 'UZT',
                offset: '+05:00'
            },
            {
                name: 'Tashkent',
                offset: '+05:00'
            },
            {
                name: 'GST',
                offset: '+04:00'
            },
            {
                name: 'Dubai',
                offset: '+04:00'
            },
            {
                name: 'MSK',
                offset: '+03:00'
            },
            {
                name: 'Moscow',
                offset: '+03:00'
            },
            {
                name: 'CEST',
                offset: '+02:00'
            },
            {
                name: 'Brussels',
                offset: '+02:00'
            },
            {
                name: 'BST',
                offset: '+01:00'
            },
            {
                name: 'London',
                offset: '+01:00'
            },
            {
                name: 'GMT',
                offset: '+00:00'
            },
            {
                name: 'CVT',
                offset: '-01:00'
            },
            {
                name: 'Praia',
                offset: '-01:00'
            },
            {
                name: 'WGST',
                offset: '-02:00'
            },
            {
                name: 'Nuuk',
                offset: '-02:00'
            },
            {
                name: 'Buenos Aires',
                offset: '-03:00'
            },
            {
                name: 'EDT',
                offset: '-04:00'
            },
            {
                name: 'New York',
                offset: '-04:00'
            },
            {
                name: 'CDT',
                offset: '-05:00'
            },
            {
                name: 'Mexico City',
                offset: '-05:00'
            },
            {
                name: 'CST',
                offset: '-06:00'
            },
            {
                name: 'Guatemala City',
                offset: '-06:00'
            },
            {
                name: 'PDT',
                offset: '-07:00'
            },
            {
                name: 'Los Angeles',
                offset: '-07:00'
            },
            {
                name: 'HDT',
                offset: '-09:00'
            },
            {
                name: 'HST',
                offset: '-10:00'
            },
            {
                name: 'Honolulu',
                offset: '-10:00'
            },
            {
                name: 'NUT',
                offset: '-11:00'
            },
            {
                name: 'Baker Island',
                offset: '-12:00'
            },
            {
                name: 'LINT',
                offset: '+14:00'
            },
            {
                name: 'Kiritimati',
                offset: '+14:00'
            },
            {
                name: 'TOT',
                offset: '+13:00'
            },
            {
                name: 'Nukualofa',
                offset: '+13:00'
            },
            {
                name: 'LHST',
                offset: '+10:30'
            },
            {
                name: 'Lord Howe Island',
                offset: '+10:30'
            },
            {
                name: 'MMT',
                offset: '+06:30'
            },
            {
                name: 'Yangon',
                offset: '+06:30'
            },
            {
                name: 'IST',
                offset: '+05:30'
            },
            {
                name: 'New Delhi',
                offset: '+05:30'
            },
            {
                name: 'IRDT',
                offset: '+04:30'
            },
            {
                name: 'Tehran',
                offset: '+04:30'
            },
            {
                name: 'NDT',
                offset: '-02:30'
            },
            {
                name: 'St. Johns',
                offset: '-02:30'
            },
            {
                name: 'MART',
                offset: '-09:30'
            },
            {
                name: 'Taiohae',
                offset: '-09:30'
            },
            {
                name: 'CHAST',
                offset: '+12:45'
            },
            {
                name: 'Chatham Islands',
                offset: '+12:45'
            },
            {
                name: 'Eucla',
                offset: '+12:45'
            },
            {
                name: 'Eucla',
                offset: '+08:45'
            },
        ]
    },
];