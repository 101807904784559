import globalConfig from "./configVariables";
import {initializeFirebase} from "./config";
import usedString from "../utils/mapper/enumToStringMapper";

export function isTokenExpired() {
    if (localStorage.getItem(globalConfig.appId + "_time") <= new Date().getTime()) {
        return true;
    } else return false;
}

export function isTokenExpiredBefore() {
    if ((new Date().getTime() - localStorage.getItem(globalConfig.appId + "_time")) >= 300000) {
        return true;
    } else return false;
}

export function isLogin() {
    if (localStorage.getItem(globalConfig.appId + '_access_token') !== null) {
        return true;
    }
}

export function initializeStripe() {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    document.body.appendChild(script);
}

export function initializePlaces() {
    return new Promise(function (resolve, reject) {
        let placecKey = globalConfig.PlaceKey;
        const script = document.createElement('script');
        script.type = "text/javascript";
        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + placecKey + '&libraries=places';
        document.body.appendChild(script);
        resolve(true);
    });
}

export function checkIfLogin(redirectURL) {
    if (!isLogin()) {
        window.location.href = "/login?redirectURL=" + redirectURL;
    } else if (isTokenExpired()) {
        reFetchToken();
    }
}
export function reFetchToken() {
    // let appId = globalConfig.appId;
    // const firebaseApp = globalConfig.firebaseApps[appId];
    // callAuth(firebaseApp, 2).then(function (response) {
    // }).catch(function (error) {
    // })
    initializeFirebase();
}

export function isAdmin() {
    if (localStorage.getItem(globalConfig.appId + '_access_token')) {
        let access_token = JSON.stringify(localStorage.getItem(globalConfig.appId + '_access_token'));
        let base64Url = access_token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        if (JSON.parse(window.atob(base64)).admin) {
            return true;
        }
    } else {
        return false
    }
}

export function userInfo() {
    if (localStorage.getItem(globalConfig.appId + '_user') !== null) {
        return JSON.parse(localStorage.getItem(globalConfig.appId + '_user'));
    } else return {"displayName": '', "photo_url": '', "user_id": '', "email": ''}
}

export function removeUserData() {
    let localData = localStorage.getItem('userData');
    if (localData) localStorage.removeItem('userData');
}

export function userWhoCanPost(userRole, groupRoleStatus) {
    return groupRoleStatus === usedString.APPROVED && (userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT || userRole === usedString.MEMBER || userRole === usedString.MODERATOR);
}

export function forceTokenRefresh() {
    return '?refresh=true';
}