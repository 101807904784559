import {fill} from "../../common/colors";

export default theme => ({
    chatBox: {
        background: fill.white,
        margin: 'auto 5px',
        borderRadius: '50px',
        boxShadow: 'none',
        display: 'flex',
    },
    postContent: {
        width: '100%',
        padding: '2px 5px',
        fontSize: '13px'
    },
    floatRight: {
        float: 'right'
    },
    floatLeft: {
        float: 'left'
    },
    clearBoth: {
        clear: 'both'
    },
    chatBoxAvatar: {
        height: 30,
        width: 30,
    },
    commentClass: {
        color: '#338cd9',
        margin: '5px',
        padding: '5px',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    listCoverComment: {
        background: '#f4f6f700',
        padding: '2px 5px',
        borderRadius: '5px'
    },
    commentPost: {
        color: fill.textColor,
        fontSize: '14px',
        lineHeight: 1.5,
        marginTop: '5px'
    },
    moreVertPost: {
        float: 'right'
    },
    timeUUID: {
        color: fill.timeColor,
        fontSize: '11px',
    },
    commentWrapper: {
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    iconWrapper: {
        padding: '3px 5px',
        width: '60px'
    },
    progress: {
        margin: '0px 7px'
    },
    userAction: {
        margin: '5px 0',
        float: 'left',
    },
    likedUser: {
        float: 'right',
        margin: '5px 0',
        display: 'flex'
    },

    likedUnOrder: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
    },
    likedList: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        margin: '0 2px',
        border: '1px solid #ffffff',
        display: 'inline-block',
        position: 'relative',
        '&:nth-child(n+2)': {
            marginLeft: '-7px'
        }
    },
    likedUserImg: {
        cursor: 'pointer',
        width: '20px',
        height: '20px',
    },
    morePeople: {
        fontSize: '8px',
    },
});