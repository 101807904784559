/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
goog.object.extend(proto, frontend$social$common$types_pb);
var frontend$ticket$common$types_pb = require('./frontend-ticket-common-types_pb.js');
goog.object.extend(proto, frontend$ticket$common$types_pb);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.Attender', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.CreateEventRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.CreateEventResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.Event', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.Event.EventStatus', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.EventDetail', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ExploreEventsRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ExploreEventsResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetEventRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetEventResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GoingStatus', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.Organizer', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.RemoveEventRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.RemoveEventResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.Sponsor', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateEventRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateEventResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ExploreEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.displayName = 'proto.com.hamropatro.sociallayer.io.ExploreEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ExploreEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.displayName = 'proto.com.hamropatro.sociallayer.io.ExploreEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.EventDetail = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.EventDetail.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.EventDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.EventDetail.displayName = 'proto.com.hamropatro.sociallayer.io.EventDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.Event = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.Event.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.Event.displayName = 'proto.com.hamropatro.sociallayer.io.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.CreateEventRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.CreateEventRequest.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.CreateEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.CreateEventRequest.displayName = 'proto.com.hamropatro.sociallayer.io.CreateEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.CreateEventResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.CreateEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.CreateEventResponse.displayName = 'proto.com.hamropatro.sociallayer.io.CreateEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateEventRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.UpdateEventRequest.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateEventResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.UpdateEventResponse.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetEventRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetEventRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetEventResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetEventResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.RemoveEventRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.RemoveEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.RemoveEventRequest.displayName = 'proto.com.hamropatro.sociallayer.io.RemoveEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.RemoveEventResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.RemoveEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.RemoveEventResponse.displayName = 'proto.com.hamropatro.sociallayer.io.RemoveEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.Attender = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.Attender, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.Attender.displayName = 'proto.com.hamropatro.sociallayer.io.Attender';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.displayName = 'proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.Organizer = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.Organizer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.Organizer.displayName = 'proto.com.hamropatro.sociallayer.io.Organizer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.Sponsor = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.Sponsor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.com.hamropatro.sociallayer.io.Sponsor.displayName = 'proto.com.hamropatro.sociallayer.io.Sponsor';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ExploreEventsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            country: jspb.Message.getFieldWithDefault(msg, 1, ""),
            city: jspb.Message.getFieldWithDefault(msg, 2, ""),
            nextPageCurse: jspb.Message.getFieldWithDefault(msg, 3, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ExploreEventsRequest}
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ExploreEventsRequest;
    return proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ExploreEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ExploreEventsRequest}
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setCountry(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCity(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCurse(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ExploreEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCountry();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCity();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getNextPageCurse();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeInt32(
            4,
            f
        );
    }
};


/**
 * optional string country = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.prototype.getCountry = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.prototype.setCountry = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string city = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.prototype.getCity = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.prototype.setCity = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string next_page_curse = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.prototype.getNextPageCurse = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.prototype.setNextPageCurse = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ExploreEventsRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ExploreEventsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            eventList: jspb.Message.toObjectList(msg.getEventList(),
                proto.com.hamropatro.sociallayer.io.Event.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ExploreEventsResponse}
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ExploreEventsResponse;
    return proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ExploreEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ExploreEventsResponse}
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.Event;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Event.deserializeBinaryFromReader);
                msg.addEvent(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ExploreEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEventList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.Event.serializeBinaryToWriter
        );
    }
    f = message.getNextPageCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * repeated Event event = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.Event>}
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.prototype.getEventList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.Event>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.Event, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.Event>} value */
proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.prototype.setEventList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.Event}
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.prototype.addEvent = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.prototype.clearEventList = function () {
    this.setEventList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ExploreEventsResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.EventDetail.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.EventDetail.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.EventDetail.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.EventDetail} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.EventDetail.toObject = function (includeInstance, msg) {
        var f, obj = {
            event: (f = msg.getEvent()) && proto.com.hamropatro.sociallayer.io.Event.toObject(includeInstance, f),
            goingCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
            interestedCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
            recentAttendersList: jspb.Message.toObjectList(msg.getRecentAttendersList(),
                proto.com.hamropatro.sociallayer.io.Attender.toObject, includeInstance),
            userAttendingStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
            minTicketPrice: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
            maxTicketPrice: +jspb.Message.getFieldWithDefault(msg, 7, 0.0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.EventDetail}
 */
proto.com.hamropatro.sociallayer.io.EventDetail.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.EventDetail;
    return proto.com.hamropatro.sociallayer.io.EventDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.EventDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.EventDetail}
 */
proto.com.hamropatro.sociallayer.io.EventDetail.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.Event;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Event.deserializeBinaryFromReader);
                msg.setEvent(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setGoingCount(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setInterestedCount(value);
                break;
            case 4:
                var value = new proto.com.hamropatro.sociallayer.io.Attender;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Attender.deserializeBinaryFromReader);
                msg.addRecentAttenders(value);
                break;
            case 5:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.GoingStatus} */ (reader.readEnum());
                msg.setUserAttendingStatus(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setMinTicketPrice(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setMaxTicketPrice(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.EventDetail.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.EventDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.EventDetail.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEvent();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.Event.serializeBinaryToWriter
        );
    }
    f = message.getGoingCount();
    if (f !== 0) {
        writer.writeInt64(
            2,
            f
        );
    }
    f = message.getInterestedCount();
    if (f !== 0) {
        writer.writeInt64(
            3,
        f
    );
  }
  f = message.getRecentAttendersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        4,
        f,
        proto.com.hamropatro.sociallayer.io.Attender.serializeBinaryToWriter
    );
  }
  f = message.getUserAttendingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
        5,
        f
    );
  }
  f = message.getMinTicketPrice();
  if (f !== 0.0) {
    writer.writeDouble(
        6,
        f
    );
  }
  f = message.getMaxTicketPrice();
  if (f !== 0.0) {
    writer.writeDouble(
        7,
        f
    );
  }
};


/**
 * optional Event event = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.Event}
 */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.getEvent = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.Event} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.Event, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Event|undefined} value */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.setEvent = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.clearEvent = function () {
    this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.hasEvent = function () {
    return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 going_count = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.getGoingCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.setGoingCount = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 interested_count = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.getInterestedCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.setInterestedCount = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Attender recent_attenders = 4;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.Attender>}
 */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.getRecentAttendersList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.Attender>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.Attender, 4));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.Attender>} value */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.setRecentAttendersList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.Attender=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.Attender}
 */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.addRecentAttenders = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.hamropatro.sociallayer.io.Attender, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.clearRecentAttendersList = function () {
    this.setRecentAttendersList([]);
};


/**
 * optional GoingStatus user_attending_status = 5;
 * @return {!proto.com.hamropatro.sociallayer.io.GoingStatus}
 */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.getUserAttendingStatus = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.GoingStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.GoingStatus} value */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.setUserAttendingStatus = function (value) {
    jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional double min_ticket_price = 6;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.getMinTicketPrice = function () {
    return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.setMinTicketPrice = function (value) {
    jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double max_ticket_price = 7;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.getMaxTicketPrice = function () {
    return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.EventDetail.prototype.setMaxTicketPrice = function (value) {
    jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.Event.repeatedFields_ = [26, 27];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.Event.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.Event.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.Event} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.Event.toObject = function (includeInstance, msg) {
        var f, obj = {
            eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            year: jspb.Message.getFieldWithDefault(msg, 3, 0),
            month: jspb.Message.getFieldWithDefault(msg, 4, 0),
            day: jspb.Message.getFieldWithDefault(msg, 5, 0),
            startFrom: jspb.Message.getFieldWithDefault(msg, 6, 0),
            ends: jspb.Message.getFieldWithDefault(msg, 7, 0),
            category: jspb.Message.getFieldWithDefault(msg, 8, ""),
            eventDesc: jspb.Message.getFieldWithDefault(msg, 9, ""),
            eventCoverImage: jspb.Message.getFieldWithDefault(msg, 10, ""),
            eventType: jspb.Message.getFieldWithDefault(msg, 11, ""),
            eventStatus: jspb.Message.getFieldWithDefault(msg, 12, 0),
            yearlyRepeated: jspb.Message.getFieldWithDefault(msg, 13, false),
            ownerInfo: (f = msg.getOwnerInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
            metaDataMap: (f = msg.getMetaDataMap()) ? f.toObject(includeInstance, undefined) : [],
            location: (f = msg.getLocation()) && frontend$social$common$types_pb.LocationInfo.toObject(includeInstance, f),
            locationString: jspb.Message.getFieldWithDefault(msg, 17, ""),
            timeZone: jspb.Message.getFieldWithDefault(msg, 18, ""),
            publicHoliday: jspb.Message.getFieldWithDefault(msg, 19, false),
            title: jspb.Message.getFieldWithDefault(msg, 20, ""),
            isPrivate: jspb.Message.getFieldWithDefault(msg, 21, false),
            organizerEmail: jspb.Message.getFieldWithDefault(msg, 22, ""),
            organizerPhone: jspb.Message.getFieldWithDefault(msg, 23, ""),
            ticketAvailable: jspb.Message.getFieldWithDefault(msg, 24, false),
            organizerName: jspb.Message.getFieldWithDefault(msg, 25, ""),
            organizerList: jspb.Message.toObjectList(msg.getOrganizerList(),
                proto.com.hamropatro.sociallayer.io.Organizer.toObject, includeInstance),
            sponsorList: jspb.Message.toObjectList(msg.getSponsorList(),
                proto.com.hamropatro.sociallayer.io.Sponsor.toObject, includeInstance)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.Event}
 */
proto.com.hamropatro.sociallayer.io.Event.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.Event;
    return proto.com.hamropatro.sociallayer.io.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.Event}
 */
proto.com.hamropatro.sociallayer.io.Event.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setEventId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setYear(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setDay(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setStartFrom(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setEnds(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setCategory(value);
                break;
            case 9:
                var value = /** @type {string} */ (reader.readString());
                msg.setEventDesc(value);
                break;
            case 10:
                var value = /** @type {string} */ (reader.readString());
                msg.setEventCoverImage(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());
                msg.setEventType(value);
                break;
            case 12:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.Event.EventStatus} */ (reader.readEnum());
                msg.setEventStatus(value);
                break;
            case 13:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setYearlyRepeated(value);
                break;
            case 14:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setOwnerInfo(value);
                break;
            case 15:
                var value = msg.getMetaDataMap();
                reader.readMessage(value, function (message, reader) {
                    jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
                });
                break;
            case 16:
                var value = new frontend$social$common$types_pb.LocationInfo;
                reader.readMessage(value, frontend$social$common$types_pb.LocationInfo.deserializeBinaryFromReader);
                msg.setLocation(value);
                break;
            case 17:
                var value = /** @type {string} */ (reader.readString());
                msg.setLocationString(value);
                break;
            case 18:
                var value = /** @type {string} */ (reader.readString());
                msg.setTimeZone(value);
                break;
            case 19:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setPublicHoliday(value);
                break;
            case 20:
                var value = /** @type {string} */ (reader.readString());
                msg.setTitle(value);
                break;
            case 21:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsPrivate(value);
                break;
            case 22:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizerEmail(value);
                break;
            case 23:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizerPhone(value);
                break;
            case 24:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setTicketAvailable(value);
                break;
            case 25:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizerName(value);
                break;
            case 26:
                var value = new proto.com.hamropatro.sociallayer.io.Organizer;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Organizer.deserializeBinaryFromReader);
                msg.addOrganizer(value);
                break;
            case 27:
                var value = new proto.com.hamropatro.sociallayer.io.Sponsor;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Sponsor.deserializeBinaryFromReader);
                msg.addSponsor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.Event.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.Event.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEventId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt32(
            3,
            f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
        4,
        f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
        5,
        f
    );
  }
  f = message.getStartFrom();
  if (f !== 0) {
    writer.writeInt64(
        6,
        f
    );
  }
  f = message.getEnds();
  if (f !== 0) {
    writer.writeInt64(
        7,
        f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
        8,
        f
    );
  }
  f = message.getEventDesc();
  if (f.length > 0) {
    writer.writeString(
        9,
        f
    );
  }
  f = message.getEventCoverImage();
  if (f.length > 0) {
    writer.writeString(
        10,
        f
    );
  }
  f = message.getEventType();
  if (f.length > 0) {
    writer.writeString(
        11,
        f
    );
  }
  f = message.getEventStatus();
  if (f !== 0.0) {
    writer.writeEnum(
        12,
        f
    );
  }
  f = message.getYearlyRepeated();
  if (f) {
    writer.writeBool(
        13,
        f
    );
  }
  f = message.getOwnerInfo();
  if (f != null) {
    writer.writeMessage(
        14,
        f,
        frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getMetaDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(15, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
        16,
        f,
        frontend$social$common$types_pb.LocationInfo.serializeBinaryToWriter
    );
  }
  f = message.getLocationString();
  if (f.length > 0) {
    writer.writeString(
        17,
        f
    );
  }
  f = message.getTimeZone();
  if (f.length > 0) {
    writer.writeString(
        18,
        f
    );
  }
  f = message.getPublicHoliday();
  if (f) {
    writer.writeBool(
        19,
        f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
        20,
        f
    );
  }
  f = message.getIsPrivate();
  if (f) {
    writer.writeBool(
        21,
        f
    );
  }
  f = message.getOrganizerEmail();
  if (f.length > 0) {
    writer.writeString(
        22,
        f
    );
  }
  f = message.getOrganizerPhone();
  if (f.length > 0) {
    writer.writeString(
        23,
        f
    );
  }
  f = message.getTicketAvailable();
  if (f) {
    writer.writeBool(
        24,
        f
    );
  }
  f = message.getOrganizerName();
  if (f.length > 0) {
    writer.writeString(
        25,
        f
    );
  }
  f = message.getOrganizerList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        26,
        f,
        proto.com.hamropatro.sociallayer.io.Organizer.serializeBinaryToWriter
    );
  }
  f = message.getSponsorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        27,
        f,
        proto.com.hamropatro.sociallayer.io.Sponsor.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.Event.EventStatus = {
  OPEN: 0,
  CLOSED: 1,
  CANCELED: 2,
  POSTPONED: 3
};

/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setEventId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setYear = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 month = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setMonth = function (value) {
    jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 day = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getDay = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setDay = function (value) {
    jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 start_from = 6;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getStartFrom = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setStartFrom = function (value) {
    jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 ends = 7;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getEnds = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setEnds = function (value) {
    jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string category = 8;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getCategory = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setCategory = function (value) {
    jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string event_desc = 9;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getEventDesc = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setEventDesc = function (value) {
    jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string event_cover_image = 10;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getEventCoverImage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setEventCoverImage = function (value) {
    jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string event_type = 11;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getEventType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setEventType = function (value) {
    jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional EventStatus event_status = 12;
 * @return {!proto.com.hamropatro.sociallayer.io.Event.EventStatus}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getEventStatus = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.Event.EventStatus} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.Event.EventStatus} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setEventStatus = function (value) {
    jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional bool yearly_repeated = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getYearlyRepeated = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setYearlyRepeated = function (value) {
    jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional AccountInfo owner_info = 14;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getOwnerInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 14));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setOwnerInfo = function (value) {
    jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.clearOwnerInfo = function () {
    this.setOwnerInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.hasOwnerInfo = function () {
    return jspb.Message.getField(this, 14) != null;
};


/**
 * map<string, string> meta_data = 15;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getMetaDataMap = function (opt_noLazyCreate) {
    return /** @type {!jspb.Map<string,string>} */ (
        jspb.Message.getMapField(this, 15, opt_noLazyCreate,
            null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.clearMetaDataMap = function () {
    this.getMetaDataMap().clear();
};


/**
 * optional LocationInfo location = 16;
 * @return {?proto.com.hamropatro.sociallayer.io.LocationInfo}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getLocation = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.LocationInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.LocationInfo, 16));
};


/** @param {?proto.com.hamropatro.sociallayer.io.LocationInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setLocation = function (value) {
    jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.clearLocation = function () {
    this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.hasLocation = function () {
    return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string location_string = 17;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getLocationString = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setLocationString = function (value) {
    jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string time_zone = 18;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getTimeZone = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setTimeZone = function (value) {
    jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional bool public_holiday = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getPublicHoliday = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setPublicHoliday = function (value) {
    jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional string title = 20;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setTitle = function (value) {
    jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool is_private = 21;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getIsPrivate = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 21, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setIsPrivate = function (value) {
    jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional string organizer_email = 22;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getOrganizerEmail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setOrganizerEmail = function (value) {
    jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string organizer_phone = 23;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getOrganizerPhone = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setOrganizerPhone = function (value) {
    jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional bool ticket_available = 24;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getTicketAvailable = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 24, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setTicketAvailable = function (value) {
    jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional string organizer_name = 25;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getOrganizerName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setOrganizerName = function (value) {
    jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * repeated Organizer organizer = 26;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.Organizer>}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getOrganizerList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.Organizer>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.Organizer, 26));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.Organizer>} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setOrganizerList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.Organizer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.Organizer}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.addOrganizer = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.com.hamropatro.sociallayer.io.Organizer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.clearOrganizerList = function () {
    this.setOrganizerList([]);
};


/**
 * repeated Sponsor sponsor = 27;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.Sponsor>}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.getSponsorList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.Sponsor>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.Sponsor, 27));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.Sponsor>} value */
proto.com.hamropatro.sociallayer.io.Event.prototype.setSponsorList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.Sponsor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.Sponsor}
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.addSponsor = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.com.hamropatro.sociallayer.io.Sponsor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.Event.prototype.clearSponsorList = function () {
    this.setSponsorList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.CreateEventRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.CreateEventRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.CreateEventRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.CreateEventRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            event: (f = msg.getEvent()) && proto.com.hamropatro.sociallayer.io.Event.toObject(includeInstance, f),
            businessAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            ticketsList: jspb.Message.toObjectList(msg.getTicketsList(),
                frontend$ticket$common$types_pb.TicketModel.toObject, includeInstance)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.CreateEventRequest}
 */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.CreateEventRequest;
    return proto.com.hamropatro.sociallayer.io.CreateEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.CreateEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.CreateEventRequest}
 */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.Event;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Event.deserializeBinaryFromReader);
                msg.setEvent(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessAccountId(value);
                break;
            case 3:
                var value = new frontend$ticket$common$types_pb.TicketModel;
                reader.readMessage(value, frontend$ticket$common$types_pb.TicketModel.deserializeBinaryFromReader);
                msg.addTickets(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.CreateEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.CreateEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEvent();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.Event.serializeBinaryToWriter
        );
    }
    f = message.getBusinessAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getTicketsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            3,
        f,
        frontend$ticket$common$types_pb.TicketModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional Event event = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.Event}
 */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.prototype.getEvent = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.Event} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.Event, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Event|undefined} value */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.prototype.setEvent = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.prototype.clearEvent = function () {
    this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.prototype.hasEvent = function () {
    return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string business_account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.prototype.getBusinessAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.prototype.setBusinessAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated TicketModel tickets = 3;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.TicketModel>}
 */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.prototype.getTicketsList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.TicketModel>} */ (
        jspb.Message.getRepeatedWrapperField(this, frontend$ticket$common$types_pb.TicketModel, 3));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.TicketModel>} value */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.prototype.setTicketsList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.TicketModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.TicketModel}
 */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.prototype.addTickets = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.hamropatro.sociallayer.io.TicketModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.CreateEventRequest.prototype.clearTicketsList = function () {
    this.setTicketsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.CreateEventResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.CreateEventResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.CreateEventResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.CreateEventResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            created: jspb.Message.getFieldWithDefault(msg, 2, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.CreateEventResponse}
 */
proto.com.hamropatro.sociallayer.io.CreateEventResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.CreateEventResponse;
    return proto.com.hamropatro.sociallayer.io.CreateEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.CreateEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.CreateEventResponse}
 */
proto.com.hamropatro.sociallayer.io.CreateEventResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setEventId(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setCreated(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.CreateEventResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.CreateEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.CreateEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.CreateEventResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEventId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCreated();
    if (f) {
        writer.writeBool(
            2,
            f
        );
    }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.CreateEventResponse.prototype.getEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.CreateEventResponse.prototype.setEventId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool created = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.CreateEventResponse.prototype.getCreated = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.CreateEventResponse.prototype.setCreated = function (value) {
    jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.UpdateEventRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.UpdateEventRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateEventRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.UpdateEventRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            event: (f = msg.getEvent()) && proto.com.hamropatro.sociallayer.io.Event.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateEventRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateEventRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.UpdateEventRequest;
    return proto.com.hamropatro.sociallayer.io.UpdateEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateEventRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateEventRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 2:
                var value = new proto.com.hamropatro.sociallayer.io.Event;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Event.deserializeBinaryFromReader);
                msg.setEvent(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateEventRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.UpdateEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateEventRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEvent();
    if (f != null) {
        writer.writeMessage(
            2,
            f,
            proto.com.hamropatro.sociallayer.io.Event.serializeBinaryToWriter
        );
    }
};


/**
 * optional Event event = 2;
 * @return {?proto.com.hamropatro.sociallayer.io.Event}
 */
proto.com.hamropatro.sociallayer.io.UpdateEventRequest.prototype.getEvent = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.Event} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.Event, 2));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Event|undefined} value */
proto.com.hamropatro.sociallayer.io.UpdateEventRequest.prototype.setEvent = function (value) {
    jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.UpdateEventRequest.prototype.clearEvent = function () {
    this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdateEventRequest.prototype.hasEvent = function () {
    return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.UpdateEventResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.UpdateEventResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateEventResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.UpdateEventResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            updated: jspb.Message.getFieldWithDefault(msg, 2, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateEventResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateEventResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.UpdateEventResponse;
    return proto.com.hamropatro.sociallayer.io.UpdateEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateEventResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateEventResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setEventId(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setUpdated(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateEventResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.UpdateEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateEventResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEventId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getUpdated();
    if (f) {
        writer.writeBool(
            2,
            f
        );
    }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateEventResponse.prototype.getEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateEventResponse.prototype.setEventId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool updated = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdateEventResponse.prototype.getUpdated = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UpdateEventResponse.prototype.setUpdated = function (value) {
    jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetEventRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetEventRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetEventRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            eventId: jspb.Message.getFieldWithDefault(msg, 6, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventRequest}
 */
proto.com.hamropatro.sociallayer.io.GetEventRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetEventRequest;
    return proto.com.hamropatro.sociallayer.io.GetEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventRequest}
 */
proto.com.hamropatro.sociallayer.io.GetEventRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setEventId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetEventRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetEventRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getEventId();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
        );
    }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event_id = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventRequest.prototype.getEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventRequest.prototype.setEventId = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.repeatedFields_ = [4, 6, 7];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetEventResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetEventResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            event: (f = msg.getEvent()) && proto.com.hamropatro.sociallayer.io.Event.toObject(includeInstance, f),
            goingCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
            interestedCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
            recentAttendersList: jspb.Message.toObjectList(msg.getRecentAttendersList(),
                proto.com.hamropatro.sociallayer.io.Attender.toObject, includeInstance),
            status: jspb.Message.getFieldWithDefault(msg, 5, 0),
            ticketTypesList: jspb.Message.toObjectList(msg.getTicketTypesList(),
                frontend$ticket$common$types_pb.TicketModel.toObject, includeInstance),
            userTicketsList: jspb.Message.toObjectList(msg.getUserTicketsList(),
                frontend$ticket$common$types_pb.UserTicket.toObject, includeInstance),
            ticketAlreadyBought: jspb.Message.getFieldWithDefault(msg, 8, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventResponse}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetEventResponse;
    return proto.com.hamropatro.sociallayer.io.GetEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventResponse}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.Event;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Event.deserializeBinaryFromReader);
                msg.setEvent(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setGoingCount(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setInterestedCount(value);
                break;
            case 4:
                var value = new proto.com.hamropatro.sociallayer.io.Attender;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Attender.deserializeBinaryFromReader);
                msg.addRecentAttenders(value);
                break;
            case 5:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.GoingStatus} */ (reader.readEnum());
                msg.setStatus(value);
                break;
            case 6:
                var value = new frontend$ticket$common$types_pb.TicketModel;
                reader.readMessage(value, frontend$ticket$common$types_pb.TicketModel.deserializeBinaryFromReader);
                msg.addTicketTypes(value);
                break;
            case 7:
                var value = new frontend$ticket$common$types_pb.UserTicket;
                reader.readMessage(value, frontend$ticket$common$types_pb.UserTicket.deserializeBinaryFromReader);
                msg.addUserTickets(value);
                break;
            case 8:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setTicketAlreadyBought(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEvent();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.Event.serializeBinaryToWriter
        );
    }
    f = message.getGoingCount();
    if (f !== 0) {
        writer.writeInt64(
            2,
            f
        );
    }
    f = message.getInterestedCount();
    if (f !== 0) {
        writer.writeInt64(
            3,
        f
    );
  }
  f = message.getRecentAttendersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        4,
        f,
        proto.com.hamropatro.sociallayer.io.Attender.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
        5,
        f
    );
  }
  f = message.getTicketTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        6,
        f,
        frontend$ticket$common$types_pb.TicketModel.serializeBinaryToWriter
    );
  }
  f = message.getUserTicketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        7,
        f,
        frontend$ticket$common$types_pb.UserTicket.serializeBinaryToWriter
    );
  }
  f = message.getTicketAlreadyBought();
  if (f) {
    writer.writeBool(
        8,
        f
    );
  }
};


/**
 * optional Event event = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.Event}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.getEvent = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.Event} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.Event, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Event|undefined} value */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.setEvent = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.clearEvent = function () {
    this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.hasEvent = function () {
    return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 going_count = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.getGoingCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.setGoingCount = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 interested_count = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.getInterestedCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.setInterestedCount = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Attender recent_attenders = 4;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.Attender>}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.getRecentAttendersList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.Attender>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.Attender, 4));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.Attender>} value */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.setRecentAttendersList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.Attender=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.Attender}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.addRecentAttenders = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.hamropatro.sociallayer.io.Attender, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.clearRecentAttendersList = function () {
    this.setRecentAttendersList([]);
};


/**
 * optional GoingStatus status = 5;
 * @return {!proto.com.hamropatro.sociallayer.io.GoingStatus}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.getStatus = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.GoingStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.GoingStatus} value */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.setStatus = function (value) {
    jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated TicketModel ticket_types = 6;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.TicketModel>}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.getTicketTypesList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.TicketModel>} */ (
        jspb.Message.getRepeatedWrapperField(this, frontend$ticket$common$types_pb.TicketModel, 6));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.TicketModel>} value */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.setTicketTypesList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.TicketModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.TicketModel}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.addTicketTypes = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.hamropatro.sociallayer.io.TicketModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.clearTicketTypesList = function () {
    this.setTicketTypesList([]);
};


/**
 * repeated UserTicket user_tickets = 7;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.getUserTicketsList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>} */ (
        jspb.Message.getRepeatedWrapperField(this, frontend$ticket$common$types_pb.UserTicket, 7));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>} value */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.setUserTicketsList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicket}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.addUserTickets = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.com.hamropatro.sociallayer.io.UserTicket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.clearUserTicketsList = function () {
    this.setUserTicketsList([]);
};


/**
 * optional bool ticket_already_bought = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.getTicketAlreadyBought = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.GetEventResponse.prototype.setTicketAlreadyBought = function (value) {
    jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 3, 0),
            month: jspb.Message.getFieldWithDefault(msg, 4, 0),
            day: jspb.Message.getFieldWithDefault(msg, 5, 0),
            cursor: jspb.Message.getFieldWithDefault(msg, 6, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 7, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest;
    return proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setYear(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setDay(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt32(
            3,
            f
        );
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt32(
            4,
            f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
        5,
        f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        6,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        7,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.setYear = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 month = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.setMonth = function (value) {
    jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 day = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.getDay = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.setDay = function (value) {
    jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string cursor = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 page_size = 7;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            eventDetailList: jspb.Message.toObjectList(msg.getEventDetailList(),
                proto.com.hamropatro.sociallayer.io.EventDetail.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse;
    return proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.EventDetail;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.EventDetail.deserializeBinaryFromReader);
                msg.addEventDetail(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEventDetailList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.EventDetail.serializeBinaryToWriter
        );
    }
    f = message.getNextPageCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * repeated EventDetail event_detail = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.prototype.getEventDetailList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.EventDetail, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} value */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.prototype.setEventDetailList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.EventDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.EventDetail}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.prototype.addEventDetail = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.EventDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.prototype.clearEventDetailList = function () {
    this.setEventDetailList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventsOfDayResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            cursor: jspb.Message.getFieldWithDefault(msg, 4, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest;
    return proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt32(
            2,
            f
        );
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt32(
            3,
            f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        5,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.prototype.setYear = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 month = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.prototype.setMonth = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string cursor = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 page_size = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetGroupEventsRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            eventDetailList: jspb.Message.toObjectList(msg.getEventDetailList(),
                proto.com.hamropatro.sociallayer.io.EventDetail.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse;
    return proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.EventDetail;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.EventDetail.deserializeBinaryFromReader);
                msg.addEventDetail(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEventDetailList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.EventDetail.serializeBinaryToWriter
        );
    }
    f = message.getNextPageCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * repeated EventDetail event_detail = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.prototype.getEventDetailList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.EventDetail, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} value */
proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.prototype.setEventDetailList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.EventDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.EventDetail}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.prototype.addEventDetail = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.EventDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.prototype.clearEventDetailList = function () {
    this.setEventDetailList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupEventsResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            year: jspb.Message.getFieldWithDefault(msg, 1, 0),
            cursor: jspb.Message.getFieldWithDefault(msg, 2, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest;
    return proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setYear(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt32(
            1,
            f
        );
    }
    f = message.getCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeInt32(
            3,
            f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.prototype.setYear = function (value) {
    jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            eventDetailList: jspb.Message.toObjectList(msg.getEventDetailList(),
                proto.com.hamropatro.sociallayer.io.EventDetail.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse;
    return proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.EventDetail;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.EventDetail.deserializeBinaryFromReader);
                msg.addEventDetail(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEventDetailList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.EventDetail.serializeBinaryToWriter
        );
    }
    f = message.getNextPageCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * repeated EventDetail event_detail = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.prototype.getEventDetailList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.EventDetail, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} value */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.prototype.setEventDetailList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.EventDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.EventDetail}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.prototype.addEventDetail = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.EventDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.prototype.clearEventDetailList = function () {
    this.setEventDetailList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUserGroupEventsResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            eventId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            year: jspb.Message.getFieldWithDefault(msg, 3, 0),
            month: jspb.Message.getFieldWithDefault(msg, 4, 0),
            day: jspb.Message.getFieldWithDefault(msg, 5, 0),
            status: jspb.Message.getFieldWithDefault(msg, 7, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest;
    return proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setEventId(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setYear(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setDay(value);
                break;
            case 7:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.GoingStatus} */ (reader.readEnum());
                msg.setStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getEventId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt32(
            3,
            f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
        4,
        f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
        5,
        f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
        7,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.getEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.setEventId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.setYear = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 month = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.setMonth = function (value) {
    jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 day = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.getDay = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.setDay = function (value) {
    jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional GoingStatus status = 7;
 * @return {!proto.com.hamropatro.sociallayer.io.GoingStatus}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.getStatus = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.GoingStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.GoingStatus} value */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusRequest.prototype.setStatus = function (value) {
    jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            updatedStatus: jspb.Message.getFieldWithDefault(msg, 1, 0),
            eventId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse;
    return proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.GoingStatus} */ (reader.readEnum());
                msg.setUpdatedStatus(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setEventId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUpdatedStatus();
    if (f !== 0.0) {
        writer.writeEnum(
            1,
            f
        );
    }
    f = message.getEventId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional GoingStatus updated_status = 1;
 * @return {!proto.com.hamropatro.sociallayer.io.GoingStatus}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.prototype.getUpdatedStatus = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.GoingStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.GoingStatus} value */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.prototype.setUpdatedStatus = function (value) {
    jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string event_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.prototype.getEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateGoingStatusResponse.prototype.setEventId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.RemoveEventRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.RemoveEventRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.RemoveEventRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.RemoveEventRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            eventId: jspb.Message.getFieldWithDefault(msg, 5, ""),
            businessId: jspb.Message.getFieldWithDefault(msg, 6, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveEventRequest}
 */
proto.com.hamropatro.sociallayer.io.RemoveEventRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.RemoveEventRequest;
    return proto.com.hamropatro.sociallayer.io.RemoveEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveEventRequest}
 */
proto.com.hamropatro.sociallayer.io.RemoveEventRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setEventId(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setBusinessId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.RemoveEventRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.RemoveEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemoveEventRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getEventId();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
    f = message.getBusinessId();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveEventRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveEventRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event_id = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveEventRequest.prototype.getEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveEventRequest.prototype.setEventId = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string business_id = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveEventRequest.prototype.getBusinessId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveEventRequest.prototype.setBusinessId = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.RemoveEventResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.RemoveEventResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.RemoveEventResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.RemoveEventResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            removedEventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            isRemoved: jspb.Message.getFieldWithDefault(msg, 2, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveEventResponse}
 */
proto.com.hamropatro.sociallayer.io.RemoveEventResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.RemoveEventResponse;
    return proto.com.hamropatro.sociallayer.io.RemoveEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveEventResponse}
 */
proto.com.hamropatro.sociallayer.io.RemoveEventResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setRemovedEventId(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsRemoved(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.RemoveEventResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.RemoveEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemoveEventResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRemovedEventId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getIsRemoved();
    if (f) {
        writer.writeBool(
            2,
            f
        );
    }
};


/**
 * optional string removed_event_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveEventResponse.prototype.getRemovedEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveEventResponse.prototype.setRemovedEventId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_removed = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.RemoveEventResponse.prototype.getIsRemoved = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.RemoveEventResponse.prototype.setIsRemoved = function (value) {
    jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            eventId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            cursor: jspb.Message.getFieldWithDefault(msg, 3, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
            status: jspb.Message.getFieldWithDefault(msg, 5, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest;
    return proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setEventId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getEventId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getCursor();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        4,
        f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
        5,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.prototype.getEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.prototype.setEventId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cursor = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventAttendersRequest.prototype.setStatus = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.Attender.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.Attender.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.Attender} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.Attender.toObject = function (includeInstance, msg) {
        var f, obj = {
            attenders: (f = msg.getAttenders()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
            attendingStatus: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.Attender}
 */
proto.com.hamropatro.sociallayer.io.Attender.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.Attender;
    return proto.com.hamropatro.sociallayer.io.Attender.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.Attender} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.Attender}
 */
proto.com.hamropatro.sociallayer.io.Attender.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setAttenders(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setAttendingStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.Attender.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.Attender.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.Attender} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.Attender.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAttenders();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getAttendingStatus();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional AccountInfo attenders = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.Attender.prototype.getAttenders = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.Attender.prototype.setAttenders = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.Attender.prototype.clearAttenders = function () {
    this.setAttenders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Attender.prototype.hasAttenders = function () {
    return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string attending_status = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Attender.prototype.getAttendingStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Attender.prototype.setAttendingStatus = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            eventAttendersList: jspb.Message.toObjectList(msg.getEventAttendersList(),
                proto.com.hamropatro.sociallayer.io.Attender.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 4, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse;
    return proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setEventId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 3:
                var value = new proto.com.hamropatro.sociallayer.io.Attender;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Attender.deserializeBinaryFromReader);
                msg.addEventAttenders(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEventId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getEventAttendersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            3,
            f,
        proto.com.hamropatro.sociallayer.io.Attender.serializeBinaryToWriter
    );
  }
  f = message.getNextPageCursor();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.prototype.getEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.prototype.setEventId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Attender event_attenders = 3;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.Attender>}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.prototype.getEventAttendersList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.Attender>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.Attender, 3));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.Attender>} value */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.prototype.setEventAttendersList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.Attender=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.Attender}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.prototype.addEventAttenders = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.hamropatro.sociallayer.io.Attender, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.prototype.clearEventAttendersList = function () {
    this.setEventAttendersList([]);
};


/**
 * optional string next_page_cursor = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventAttendersResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.toObject = function (includeInstance, msg) {
        var f, obj = {
            cursor: jspb.Message.getFieldWithDefault(msg, 1, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent}
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent;
    return proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent}
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCursor();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeInt32(
            2,
            f
        );
    }
};


/**
 * optional string cursor = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEvent.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            attendingEventList: jspb.Message.toObjectList(msg.getAttendingEventList(),
                proto.com.hamropatro.sociallayer.io.EventDetail.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse}
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse;
    return proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse}
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.EventDetail;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.EventDetail.deserializeBinaryFromReader);
                msg.addAttendingEvent(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAttendingEventList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.EventDetail.serializeBinaryToWriter
        );
    }
    f = message.getNextPageCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * repeated EventDetail attending_event = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>}
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.prototype.getAttendingEventList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.EventDetail, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} value */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.prototype.setAttendingEventList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.EventDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.EventDetail}
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.prototype.addAttendingEvent = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.EventDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.prototype.clearAttendingEventList = function () {
    this.setAttendingEventList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUserAttendingEventResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            categoryName: jspb.Message.getFieldWithDefault(msg, 2, ""),
            year: jspb.Message.getFieldWithDefault(msg, 3, 0),
            cursor: jspb.Message.getFieldWithDefault(msg, 4, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest;
    return proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCategoryName(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setYear(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCategoryName();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt32(
            3,
            f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        5,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_name = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.prototype.getCategoryName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.prototype.setCategoryName = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.prototype.setYear = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string cursor = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 page_size = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            eventDetailList: jspb.Message.toObjectList(msg.getEventDetailList(),
                proto.com.hamropatro.sociallayer.io.EventDetail.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse;
    return proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.EventDetail;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.EventDetail.deserializeBinaryFromReader);
                msg.addEventDetail(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEventDetailList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.EventDetail.serializeBinaryToWriter
        );
    }
    f = message.getNextPageCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * repeated EventDetail event_detail = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.prototype.getEventDetailList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.EventDetail, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} value */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.prototype.setEventDetailList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.EventDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.EventDetail}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.prototype.addEventDetail = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.EventDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.prototype.clearEventDetailList = function () {
    this.setEventDetailList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventByCategoryResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            currentMonth: jspb.Message.getFieldWithDefault(msg, 3, 0),
            currentDay: jspb.Message.getFieldWithDefault(msg, 4, 0),
            cursor: jspb.Message.getFieldWithDefault(msg, 5, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 6, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest;
    return proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setCurrentMonth(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setCurrentDay(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt32(
            2,
            f
        );
    }
    f = message.getCurrentMonth();
    if (f !== 0) {
        writer.writeInt32(
            3,
            f
    );
  }
  f = message.getCurrentDay();
  if (f !== 0) {
    writer.writeInt32(
        4,
        f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        5,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        6,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.setYear = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 current_month = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.getCurrentMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.setCurrentMonth = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 current_day = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.getCurrentDay = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.setCurrentDay = function (value) {
    jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string cursor = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 page_size = 6;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            eventDetailList: jspb.Message.toObjectList(msg.getEventDetailList(),
                proto.com.hamropatro.sociallayer.io.EventDetail.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse;
    return proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.EventDetail;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.EventDetail.deserializeBinaryFromReader);
                msg.addEventDetail(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEventDetailList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.EventDetail.serializeBinaryToWriter
        );
    }
    f = message.getNextPageCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * repeated EventDetail event_detail = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.prototype.getEventDetailList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.EventDetail, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} value */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.prototype.setEventDetailList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.EventDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.EventDetail}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.prototype.addEventDetail = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.EventDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.prototype.clearEventDetailList = function () {
    this.setEventDetailList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUpComingEventsResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            currentMonth: jspb.Message.getFieldWithDefault(msg, 3, 0),
            beforeDay: jspb.Message.getFieldWithDefault(msg, 4, 0),
            cursor: jspb.Message.getFieldWithDefault(msg, 5, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 6, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest;
    return proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setCurrentMonth(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setBeforeDay(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt32(
            2,
            f
        );
    }
    f = message.getCurrentMonth();
    if (f !== 0) {
        writer.writeInt32(
            3,
            f
    );
  }
  f = message.getBeforeDay();
  if (f !== 0) {
    writer.writeInt32(
        4,
        f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        5,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        6,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.setYear = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 current_month = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.getCurrentMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.setCurrentMonth = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 before_day = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.getBeforeDay = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.setBeforeDay = function (value) {
    jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string cursor = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 page_size = 6;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            eventDetailList: jspb.Message.toObjectList(msg.getEventDetailList(),
                proto.com.hamropatro.sociallayer.io.EventDetail.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse;
    return proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.EventDetail;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.EventDetail.deserializeBinaryFromReader);
                msg.addEventDetail(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEventDetailList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.EventDetail.serializeBinaryToWriter
        );
    }
    f = message.getNextPageCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * repeated EventDetail event_detail = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.prototype.getEventDetailList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.EventDetail, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.EventDetail>} value */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.prototype.setEventDetailList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.EventDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.EventDetail}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.prototype.addEventDetail = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.EventDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.prototype.clearEventDetailList = function () {
    this.setEventDetailList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPreviousEventsResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            location: (f = msg.getLocation()) && frontend$social$common$types_pb.LocationInfo.toObject(includeInstance, f),
            cursor: jspb.Message.getFieldWithDefault(msg, 2, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
            algorithm: jspb.Message.getFieldWithDefault(msg, 4, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest;
    return proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new frontend$social$common$types_pb.LocationInfo;
                reader.readMessage(value, frontend$social$common$types_pb.LocationInfo.deserializeBinaryFromReader);
                msg.setLocation(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setAlgorithm(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLocation();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            frontend$social$common$types_pb.LocationInfo.serializeBinaryToWriter
        );
    }
    f = message.getCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeInt32(
            3,
        f
    );
  }
  f = message.getAlgorithm();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
};


/**
 * optional LocationInfo location = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.LocationInfo}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.prototype.getLocation = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.LocationInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.LocationInfo, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.LocationInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.prototype.setLocation = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.prototype.clearLocation = function () {
    this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.prototype.hasLocation = function () {
    return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string algorithm = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.prototype.getAlgorithm = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetNearbyEventRequest.prototype.setAlgorithm = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            eventList: jspb.Message.toObjectList(msg.getEventList(),
                proto.com.hamropatro.sociallayer.io.Event.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse;
    return proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.Event;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.Event.deserializeBinaryFromReader);
                msg.addEvent(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEventList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.Event.serializeBinaryToWriter
        );
    }
    f = message.getNextPageCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * repeated Event event = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.Event>}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.prototype.getEventList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.Event>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.Event, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.Event>} value */
proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.prototype.setEventList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.Event}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.prototype.addEvent = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.prototype.clearEventList = function () {
    this.setEventList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetNearbyEventResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.Organizer.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.Organizer.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.Organizer} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.Organizer.toObject = function (includeInstance, msg) {
        var f, obj = {
            name: jspb.Message.getFieldWithDefault(msg, 1, ""),
            phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
            email: jspb.Message.getFieldWithDefault(msg, 3, ""),
            type: jspb.Message.getFieldWithDefault(msg, 4, ""),
            priority: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.Organizer}
 */
proto.com.hamropatro.sociallayer.io.Organizer.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.Organizer;
    return proto.com.hamropatro.sociallayer.io.Organizer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.Organizer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.Organizer}
 */
proto.com.hamropatro.sociallayer.io.Organizer.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPhone(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmail(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setType(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPriority(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.Organizer.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.Organizer.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.Organizer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.Organizer.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPhone();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getEmail();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt32(
        5,
        f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Organizer.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Organizer.prototype.setName = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Organizer.prototype.getPhone = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Organizer.prototype.setPhone = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Organizer.prototype.getEmail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Organizer.prototype.setEmail = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Organizer.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Organizer.prototype.setType = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 priority = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Organizer.prototype.getPriority = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Organizer.prototype.setPriority = function (value) {
    jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.Sponsor.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.Sponsor.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.Sponsor} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.Sponsor.toObject = function (includeInstance, msg) {
        var f, obj = {
            name: jspb.Message.getFieldWithDefault(msg, 1, ""),
            phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
            email: jspb.Message.getFieldWithDefault(msg, 3, ""),
            type: jspb.Message.getFieldWithDefault(msg, 4, ""),
            link: jspb.Message.getFieldWithDefault(msg, 5, ""),
            priority: jspb.Message.getFieldWithDefault(msg, 6, 0),
            imageUrl: jspb.Message.getFieldWithDefault(msg, 7, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.Sponsor}
 */
proto.com.hamropatro.sociallayer.io.Sponsor.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.Sponsor;
    return proto.com.hamropatro.sociallayer.io.Sponsor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.Sponsor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.Sponsor}
 */
proto.com.hamropatro.sociallayer.io.Sponsor.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPhone(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmail(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setType(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setLink(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPriority(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setImageUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.Sponsor.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.Sponsor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.Sponsor.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPhone();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getEmail();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
        4,
        f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
        5,
        f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt32(
        6,
        f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
        7,
        f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.setName = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.getPhone = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.setPhone = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.getEmail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.setEmail = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.setType = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string link = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.getLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.setLink = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 priority = 6;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.getPriority = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.setPriority = function (value) {
    jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string image_url = 7;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.getImageUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Sponsor.prototype.setImageUrl = function (value) {
    jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.GoingStatus = {
  NOT_GOING: 0,
  INTERESTED: 1,
  GOING: 2,
  DECLINED: 3
};

goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
