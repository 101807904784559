import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";
import styles from "./styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import {GetEventAttendersRequest} from "../../generated/frontend-community-event-service_pb"
import {eventAttendersList} from "../../grpcRequests/events-request";
import {UserLoader} from "../Skeleton/EventsSket";
import {eventAnalyst} from "../../configurations/config";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";


const defaultPageSize = 100;

function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class EventsUsers extends Component {
    state = {
        value: 0,
        openDialog: this.props.open,
        eventId: this.props.eventID,
        groupId: this.props.groupID,
        goingCount: this.props.goingCount,
        interestedCount: this.props.interestedCount,
        loadingGoingCount: false,
        loadingInterestedCount: false,
        goingUsers: [],
        interestedUsers: [],

        //    pagination
        goingCursor: '',
        goingPreviousCursor: '',
        goingLoadingMore: false,
        interestedCursor: '',
        interestedPreviousCursor: '',
        interestedLoadingMore: false,
    };

    componentDidMount() {
        this.fetchEventUsers('', defaultPageSize, [], '');
    }

    sendResponse = () => {
        this.props.sendCloseReport(true);
    };

    handleChangeIndex = (event, value) => {
        this.setState({value});
    };

    closeEventDialog = () => {
        this.setState({openDialog: false});
        this.sendResponse();
    };

    loadMoreGoingMember = (cursorValue, pageSize, previousGoing, prevCursor) => {
        this.setState({goingLoadingMore: true});
        this.fetchEventUsers(cursorValue, pageSize, previousGoing, prevCursor)
    };
    loadMoreInterestedMember = (cursorValue, pageSize, previousInterested, prevCursor) => {
        this.setState({interestedLoadingMore: true});
        this.fetchEventInterestedUsers(cursorValue, pageSize, previousInterested, prevCursor)
    };

    fetchEventUsers = (cursorValue, pageSize, previousUsers, prevCursor) => {
        let self = this;
        if (!prevCursor)
            self.setState({
                loadingGoingCount: true
            });
        const req = new GetEventAttendersRequest();
        req.setGroupId(this.state.groupId);
        req.setEventId(this.state.eventId);
        req.setCursor(cursorValue);
        req.setPageSize(pageSize);
        req.setStatus('GOING');
        eventAttendersList(req).then(function (response) {
            let memberLists = previousUsers;
            for (let i = 0; i < response.getEventAttendersList().length; i++) {
                let newMember = response.getEventAttendersList()[i];
                memberLists.push(newMember);
            }
            const cursorValue = response.getNextPageCursor();
            self.setState({
                goingUsers: memberLists,
                loadingGoingCount: false,
                goingCursor: cursorValue,
                goingPreviousCursor: prevCursor,
                goingLoadingMore: false
            });
            eventAnalyst('community_event_users', 'community_event_users_view', self.state.groupId + '-' + self.state.eventId + '-community event user view success', 'community-event-user-view-success');
        }).catch(function (error) {
            self.setState({loadingGoingCount: false, goingLoadingMore: false});
            eventAnalyst('community_event_users', 'community_event_users_view', self.state.groupId + '-' + self.state.eventId + '-community event user view fail', 'community-event-user-view-fail');
        })
    };

    fetchEventInterestedUsers = (cursorValue, pageSize, previousUsers, prevCursor) => {
        let self = this;
        if (!prevCursor)
            self.setState({
                loadingInterestedCount: true
            });
        const req = new GetEventAttendersRequest();
        req.setGroupId(this.state.groupId);
        req.setEventId(this.state.eventId);
        req.setCursor(cursorValue);
        req.setPageSize(pageSize);
        req.setStatus('INTERESTED');
        eventAttendersList(req).then(function (response) {
            let interestedLists = previousUsers;
            for (let i = 0; i < response.getEventAttendersList().length; i++) {
                let newMember = response.getEventAttendersList()[i];
                interestedLists.push(newMember);
            }
            const cursorValue = response.getNextPageCursor();
            self.setState({
                interestedUsers: interestedLists,
                loadingInterestedCount: false,
                interestedCursor: cursorValue,
                interestedPreviousCursor: prevCursor,
                interestedLoadingMore: false
            });
            eventAnalyst('community_event_users', 'community_event_users_view', self.state.groupId + '-' + self.state.eventId + '-community event user view success', 'community-event-user-view-success');
        }).catch(function (error) {
            self.setState({loadingInterestedCount: false, interestedLoadingMore: false})
        })
    };

    render() {
        const {classes, eventText} = this.props;
        const {value, openDialog, goingCount, interestedCount, loadingGoingCount, loadingInterestedCount, goingUsers, interestedUsers, goingCursor, goingLoadingMore, goingPreviousCursor, interestedCursor, interestedLoadingMore, interestedPreviousCursor} = this.state;
        return (
            <div>
                <Dialog
                    fullWidth
                    open={openDialog}
                    onClose={this.closeEventDialog}
                    aria-labelledby="responsive-dialog-title"
                >
                    <div>
                        <AppBar position="static" color="" className={classes.detailTabs}>
                            <Tabs
                                centered
                                value={value}
                                onChange={this.handleChangeIndex}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label={eventText}/>
                                <Tab label="Interested"
                                     onClick={() => this.fetchEventInterestedUsers('', defaultPageSize, [], '')}/>
                            </Tabs>
                        </AppBar>
                        {value === 0 &&
                        <TabContainer>
                            {!loadingGoingCount ? (
                                <div>
                                    {goingUsers.length > 0 ? (
                                        <div>
                                            {goingUsers.map(user => (
                                                <div
                                                    className={classes.product}
                                                >
                                                    <div className={classes.userImageWrapper}>
                                                        <img
                                                            alt={user.getAttenders().getAccountName()}
                                                            className={classes.productImage}
                                                            src={user.getAttenders().getAccountImage().getImageUrl()}
                                                        />
                                                    </div>
                                                    <div className={classes.productDetails}>
                                                        <Typography
                                                            className={classes.productTitle}
                                                            variant="body1"
                                                            style={{fontWeight: 600}}
                                                        >
                                                            {user.getAttenders().getAccountName()}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>) : (
                                        <div className={classes.noMemberWrapper}>No Going members yet.</div>)}
                                    {goingCursor && goingPreviousCursor !== goingCursor ? (
                                        <div>
                                            <Button variant="outlined" size="large" fullWidth
                                                    style={{margin: '15px 0'}}
                                                    onClick={() => this.loadMoreGoingMember(goingCursor, defaultPageSize, goingUsers, goingCursor)}>
                                                Load More
                                            </Button>
                                            {goingLoadingMore ? (<CircularProgress size={20}/>) : (
                                                <span></span>)}
                                        </div>
                                    ) : (<span></span>)}
                                </div>) : (<div><UserLoader/></div>)}
                        </TabContainer>}
                        {value === 1 &&
                        <TabContainer>
                            {!loadingInterestedCount ? (
                                <div>
                                    {interestedUsers.length > 0 ? (
                                        <div>
                                            {interestedUsers.map(user => (
                                                <div
                                                    className={classes.product}
                                                >
                                                    <div className={classes.userImageWrapper}>
                                                        <img
                                                            alt={user.getAttenders().getAccountName()}
                                                            className={classes.productImage}
                                                            src={user.getAttenders().getAccountImage().getImageUrl()}
                                                        />
                                                    </div>
                                                    <div className={classes.productDetails}>
                                                        <Typography
                                                            className={classes.productTitle}
                                                            variant="body1"
                                                            style={{fontWeight: 600}}
                                                        >
                                                            {user.getAttenders().getAccountName()}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>) : (
                                        <div className={classes.noMemberWrapper}>No interested users yet.</div>)}
                                    {interestedCursor && interestedPreviousCursor !== interestedCursor ? (
                                        <div>
                                            <Button variant="outlined" size="large" fullWidth
                                                    style={{margin: '15px 0'}}
                                                    onClick={() => this.loadMoreInterestedMember(interestedCursor, defaultPageSize, interestedUsers, interestedCursor)}>
                                                Load More
                                            </Button>
                                            {interestedLoadingMore ? (<CircularProgress size={20}/>) : (
                                                <span></span>)}
                                        </div>
                                    ) : (<span></span>)}
                                </div>) : (<div><UserLoader/></div>)}
                        </TabContainer>}
                    </div>
                </Dialog>
            </div>
        )
    }
}

EventsUsers.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EventsUsers);