export default theme => ({
    root: {
        padding: theme.spacing(2),
        minHeight: '95vh'
    },
    buttonDashboard: {
        marginLeft: 5,
        marginTop: 10,
        borderRadius: 10,
    },
    cards:
        {
            marginBottom: 5,
            marginLeft: 5,
            marginRight: 5,
            width: 'auto',
        },
    cards1:
        {
            marginBottom: 5,
            marginLeft: 5,
            marginRight: 5,
            height: 50,
            width: 'auto',
        },
    cardWrapper: {
        padding: '15px 10px !important'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        color: '#868585',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    avatar: {
        backgroundColor: theme.palette.error.main,
        height: 56,
        width: 56
    },
    money: {
        backgroundColor: '#238623',
        height: 56,
        width: 56
    },
    totalSold: {
        backgroundColor: '#5b96ca',
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32
    },
    difference: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    chartContainer: {
        position: 'relative',
        height: '300px',
        width: 'auto',
    },
    header1: {
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 5,
        marginRight: 5,
        display: 'flex',
    },
    differenceIcon: {
        color: theme.palette.error.dark
    },
    differenceValue: {
        color: theme.palette.error.dark,
        marginRight: theme.spacing(1)
    },

    upperHead: {
        display: 'block',
    },
    mobileView: {
        display: 'flex',
    },

    chartWrapper: {
        height: '200px', width: '200px', margin: 'auto'
    },


//    my css
    dashboardIcon: {
        width: 18, height: 18, marginTop: 2, marginRight: 5
    },
    eventWrapper: {
        background: '#bddefb',
        padding: '10px 15px'
    },

    eventDate: {
        clear: 'both',
        fontWeight: '600',
        fontSize: '16px',
        color: '#615f5f',
        padding: '3px 0'
    },
    amount: {
        fontWeight: 600
    },
    used: {
        // '&:hover': {
        //     hoverText: {
        //         display:'none'
        //     },
        //     '&:before': {
        //         color: '#000',
        //         background: '#e0e0e0',
        //     }
        // },

    },
    notUsed: {
        // '&:hover': {
        //     color:'#fff',
        //     contest:'test',
        //     background:'#3377e0',
        // },
    },
    hover: {
        textDecoration: 'underline'
    },



});
