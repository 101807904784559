import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from "../styles";
import TextField from "@material-ui/core/TextField";

class UserConfirmationWithText extends Component {
    state = {
        open: this.props.openDialog,
        dialogHeader: this.props.dialogHeader,
        dialogContent: this.props.dialogContent,
        dialogContent2: this.props.toDeleteContent,
        postKey: this.props.postId,
        button1: this.props.button1,
        button2: this.props.button2,
        confirmationText: this.props.confirmationText,
        myConfirmationText: ''
    };

    sendResponse = (clickedButton) => {
        this.props.sendUserResponse(clickedButton, this.state.postKey);
    };

    handleClose = (buttonValue) => {
        this.sendResponse(buttonValue);
        this.setState({open: false});
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    render() {
        const {open, dialogContent, dialogContent2, dialogHeader, button1, button2, myConfirmationText, confirmationText} = this.state;
        return (
            <div>
                <Dialog
                    fullWidth
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="alert-dialog-title">{dialogHeader}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>{dialogContent}</div>
                            <br/>
                            {dialogContent2}
                        </DialogContentText>
                        <Typography component="div" style={{padding: '5px 0'}}>
                            Please type '<span style={{
                            fontWeight: 600,
                            textDecoration: 'underline',
                            color: '#ce0101'
                        }}>{confirmationText}</span>' in the box below.
                        </Typography>
                        <TextField
                            autoFocus
                            id="myConfirmationText"
                            name="myConfirmationText"
                            value={myConfirmationText}
                            onChange={this.handleChange('myConfirmationText')}
                            autoComplete="My Confirmation Text"
                            helperText='Your text here'
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => this.handleClose(button1)}>
                            {button1}
                        </Button>
                        <Button onClick={() => this.handleClose(button2)} color="primary"
                                disabled={myConfirmationText !== confirmationText}>
                            {button2}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

UserConfirmationWithText.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserConfirmationWithText);