import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, withStyles} from "@material-ui/core";
import styles from "../../Home/styles";
import Typography from "@material-ui/core/Typography";
import {eventAnalyst} from "../../../configurations/config";
import {GetUserGroupsRequest} from "../../../generated/frontend-community-group-service_pb";
import {getAllGroups} from "../../../grpcRequests/groups-request";
import {EventsLoader} from "../../../components/Skeleton/EventsSket";
import GroupsCard from "./GroupsCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {ArrowRightAltOutlined as CalendarIcon} from "@material-ui/icons";
import SnackBar from "../../../components/SnackBar/SnackBar";


const defaultPageSize = 15;

class NearByCommunity extends Component {
    state = {
        history: this.props.history,
        isLoadingGroups: false,
        myGroupLists: [],
        errGroupsMsg: null,
        title: 'My Communities',
        userAddress: '',
        myCommCursor: '',
        myCommPreviousCursor: '',
        loadingMoreComm: false,

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null
    };

    componentDidMount() {
        this.fetchUserGroups('', defaultPageSize, [], '');
    }

    loadMore = (cursorValue, pageSize, previousSuggested, prevCursor) => {
        this.setState({loadingMoreComm: true});
        this.fetchUserGroups(cursorValue, pageSize, previousSuggested, prevCursor)
    };

    goToGroups() {
        eventAnalyst('home_view_link_redirect', this.state.title + '_view_events', this.state.title + ' click success', this.state.title + '-click-success');
        const {history} = this.state.history;
        history.push('/communities');
    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    fetchUserGroups = (cursorValue, pageSize, previousData, prevCursor) => {
        let self = this;
        self.setState({
            isLoadingGroups: !prevCursor
        });
        const req = new GetUserGroupsRequest();
        req.setCursor(cursorValue);
        req.setPagesize(pageSize);
        getAllGroups(req, 3).then(function (response) {
            eventAnalyst('home', 'fetch_user_community', 'User community fetch success', 'user-community-fetch-success');
            let myGroupLists = previousData;
            for (let i = 0; i < response.getGroupInfosList().length; i++) {
                let newData = response.getGroupInfosList()[i];
                myGroupLists.push(newData);
            }
            const isLoadingGroups = false;
            const loadingMoreComm = false;
            const myCommCursor = response.getNextpagetoken();
            self.setState({
                isLoadingGroups,
                myGroupLists,
                myCommCursor,
                myCommPreviousCursor: prevCursor,
                loadingMoreComm
            });
        }).catch(function (error) {
            eventAnalyst('home', 'fetch_user_community', 'User community fetch fail', 'user-community-fetch-fail');
            const isLoadingGroups = false;
            const errGroupsMsg = "Something went wrong";
            const loadingMoreComm = false;
            self.setState({
                isLoadingGroups,
                errGroupsMsg,
                loadingMoreComm
            });
            self.snackbar(true, 'Got Error While fetching User Community', 'error', 5000)
        })
    };

    render() {
        const {classes} = this.props;
        const {errGroupsMsg, myGroupLists, isLoadingGroups, title, myCommCursor, loadingMoreComm, myCommPreviousCursor} = this.state;
        return (
            <div>
                {!isLoadingGroups ? (
                    <div>
                        {myGroupLists.length > 0 ? (
                            <div className={classes.boxItems}>
                                <div>
                                    <div>
                                        <div className={classes.floatLeft}>
                                            <Typography
                                                variant="h6"
                                                style={{fontWeight: 900}}
                                            >
                                                <span>{title}</span>
                                            </Typography>
                                        </div>
                                        <div className={classes.floatRight}>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                onClick={() => this.goToGroups()}
                                                className={classes.buttonView}
                                            >
                                                View All
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={classes.simpleUnderline}></div>
                                </div>
                                <div className={classes.homeCardWrapper}>
                                    <GroupsCard groupList={myGroupLists} title={title}/>
                                    {myCommCursor && myCommPreviousCursor !== myCommCursor ? (
                                        <div className={classes.seeMoreWrapper}>
                                            <div
                                                onClick={() => this.loadMore(myCommCursor, defaultPageSize, myGroupLists, myCommCursor)}
                                                className={classes.iconTextWrapper}>
                                                <div className={classes.iconWrapper}>
                                                    <IconButton aria-label="See More"
                                                                title="See More"
                                                                size="small"
                                                                color={"primary"}>
                                                        <CalendarIcon style={{fontSize: '38px'}}/>
                                                    </IconButton>
                                                </div>
                                                <div className={classes.textSeeMore}>
                                                    {loadingMoreComm ? (<CircularProgress size={20}/>) : 'See More'}
                                                </div>
                                            </div>
                                        </div>

                                    ) : (<span></span>)}
                                </div>
                            </div>
                        ) : (
                            <div>
                                {errGroupsMsg ? (
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid item>
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                {errGroupsMsg}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        )}
                    </div>
                ) : (<EventsLoader/>)}
                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
            </div>
        )
    }
}


NearByCommunity.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NearByCommunity);