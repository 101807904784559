export default theme => ({
    label: {
        color: '#6b7c93',
        fontWeight: '300',
        letterSpacing: '0.025em'
    },
    form: {
        marginBottom: '40px',
    },
    buttonSubmit: {
        float: 'right'
    }

});