import React, {Component, Fragment} from 'react';
// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
// Material helpers
// Material components
import {Drawer, withStyles, withWidth} from '@material-ui/core';
// Custom components
import {Footer, Sidebar, SidebarRight, Topbar} from './components';
// Component styles
import styles from './styles';
import {setPageTitle} from "../../utils/MetaData";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        const isMobile = ['xs', 'sm', 'md'].includes(props.width);

        this.state = {
            isOpen: !isMobile
        };
    }

    handleClose = () => {
        this.setState({isOpen: false});
    };

    handleToggleOpen = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    };

    render() {
        const {classes, width, title, children, onBack, linkTo, setTitle, groupId} = this.props;
        if (setTitle !== false)
            setPageTitle(title);
        const {isOpen} = this.state;
        let checkIfMobile;
        checkIfMobile = ['xs', 'sm', 'md'].includes(width);
        const isMobile = checkIfMobile;
        const shiftTopbar = isOpen && !isMobile;
        const shiftContent = isOpen && !isMobile;

        if (isMobile) {
            return (
                <Fragment>
                    <Topbar
                        className={classNames(classes.topbar)}
                        title={title}
                        linkTo={linkTo}
                        onBack={onBack}
                        isMobile={isMobile}
                        isSidebarOpen={isOpen}
                        onToggleSidebar={this.handleToggleOpen}
                    />
                    <Drawer
                        anchor="left"
                        classes={{paper: classes.drawerPaperMobile}}
                        open={isOpen}
                        onClose={this.handleClose}
                        variant={isMobile ? 'temporary' : 'persistent'}
                    >
                        <Sidebar className={classes.sidebarMobile} isMobile={isMobile}/>
                        {isMobile}
                    </Drawer>
                    <main
                        className={classNames(classes.content, {
                            [classes.contentShift]: shiftContent
                        })}
                    >
                        {children}
                        <Footer/>
                    </main>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Topbar
                        className={classes.topbarShift}
                        title={title}
                        linkTo={linkTo}
                        onBack={onBack}
                    />
                    <Drawer
                        anchor="left"
                        classes={{paper: classes.drawerPaper}}
                        open={true}
                        variant={isMobile ? 'temporary' : 'persistent'}
                    >
                        <Sidebar className={classes.sidebar} isMobile={isMobile}/>
                    </Drawer>
                    <main
                        className={classes.contentShift}
                    >
                        {children}
                        <Footer/>
                    </main>
                    <Drawer
                        anchor="right"
                        classes={{paper: classes.drawerPaper}}
                        open={true}
                        variant={isMobile ? 'temporary' : 'persistent'}
                    >
                        <SidebarRight className={classes.sidebarRight} groupId={groupId}/>
                    </Drawer>
                </Fragment>
            );
        }
    }
}

Dashboard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    linkTo: PropTypes.string,
    groupId: PropTypes.string,
    setTitle: PropTypes.bool,
    width: PropTypes.string.isRequired
};

export default compose(
    withStyles(styles),
    withWidth()
)(Dashboard);
