import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import styles from "../../Home/styles";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import {eventAnalyst} from "../../../configurations/config";
import {shortenString} from "../../../utils/converter/shortenString";
import {formatDate} from "../../../utils/converter/dateConverter";
import usedString, {getEventTypeStringInSentence} from "../../../utils/mapper/enumToStringMapper";
import EventStickyInfo from "./StickyInfo/EventStickyInfo";


class NearByEventCard extends Component {
    state = {
        myEventLists: this.props.eventList,
        title: this.props.titleHeader
    };

    render() {
        const {classes} = this.props;
        const {myEventLists, title} = this.state;
        const rootGroupsURl = '/c/';
        return (
            myEventLists.map(event => (
                <div className={classes.groupCard}
                     key={event.getEventId()}>

                    <Card className={classes.singleCardEvent}>
                        <EventStickyInfo event={event}/>
                        <Link
                            to={`${rootGroupsURl}${event.getGroupId()}/events/${event.getEventId()}`}
                            onClick={() => eventAnalyst(title + '_card_click', event.getTitle() + '_clicked', event.getTitle(), event.getTitle() + '-detail-click-success')}>
                            <div className={classes.groupDetailImageWrapper}>
                                <CardMedia
                                    className={classes.mediaEvent}
                                    alt={event.getTitle()}
                                    image={event.getEventCoverImage() ? event.getEventCoverImage() : '/images/eventDefault.png'}
                                    title={event.getTitle()}
                                />
                            </div>
                        </Link>
                        <CardContent className={classes.upComingEventsWrapper}>
                            <div className={classes.groupName}>
                                <Link
                                    to={`${rootGroupsURl}${event.getGroupId()}/events/${event.getEventId()}`}
                                    onClick={() => eventAnalyst(title + '_link_click', event.getTitle() + '_clicked', event.getTitle(), event.getTitle() + '-detail-click-success')}>
                                    <Typography
                                        component="div"
                                        className={classes.name} title={event.getTitle()}>
                                                                <span>
                                                                    {shortenString(event.getTitle(), 25)}
                                                                </span>
                                    </Typography>
                                </Link>
                            </div>
                        </CardContent>
                        <div className={classes.topBarWrapper}>
                            <div className={classes.createdBy} title="Hosted By">
                                <Link to={`${rootGroupsURl}${event.getGroupId()}`}
                                      onClick={() => eventAnalyst('home_event_group_click', 'groupName_clicked', 'groupName clicked', 'groupName_clicked-success')}>
                                    {event.getMetaDataMap().get('event_hosted_by') ? event.getMetaDataMap().get('event_hosted_by') : event.getMetaDataMap().get('OWNER_GROUP') ? JSON.parse(event.getMetaDataMap().get('OWNER_GROUP')).accountName : event.getOwnerInfo().getAccountName()}
                                </Link>
                            </div>
                            <div
                                className={classes.floatRight}>{getEventTypeStringInSentence(event.getEventStatus()) === usedString.Open || getEventTypeStringInSentence(event.getEventStatus()) === usedString.Postponed ? (
                                <span title={`Event Time - ${formatDate(event.getStartFrom(), 'MMM dd yyyy, p')}`}>
                                {formatDate(event.getStartFrom(), 'MMM dd')}
                                </span>
                            ) : null}</div>
                        </div>
                    </Card>
                </div>
            ))
        )
    }

}


NearByEventCard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NearByEventCard);