import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles, withWidth} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import styles from "../styles";
import TextField from "@material-ui/core/TextField";
import compose from "recompose/compose";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpanssionList from "../ExpanssionList/ExpanssionList";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    EventNotificationRequest,
    PushLogForContentRequest,
    PushLogRequest
} from "../../generated/frontend-admin-notification-push-service_pb";
import {
    contentNotificationHistory,
    groupNotificationHistory,
    sendAdminPush
} from "../../grpcRequests/admin-notification-request";
import SnackBar from "../SnackBar/SnackBar";
import {isAdmin} from "../../configurations/global-config";


const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeEditBox} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
class NotificationConfirmation extends Component {
    state = {
        open: this.props.openDialog,
        dialogHeader: this.props.dialogHeader,
        myConfirmationText: '',
        expanded: '',
        notifying: false,

        notificationLogsList: [],
        totalCount: '',
        loadingList: false,
        enableSend: false
    };

    componentDidMount() {
        if (this.props.eventId)
            this.receiveNotifHistory();
        else this.receiveGroupNotificationtHistory();

    }

    //Snackbar Calling function
    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    sendResponse = () => {
        this.props.sendUserResponse();
    };

    handleClose = () => {
        this.sendResponse();
        this.setState({open: false});
    };


    sendNotification = () => {
        let self = this;
        this.setState({
            notifying: true,
            snackOpen: false
        });

        const req = new EventNotificationRequest();
        req.setGroupId(this.props.postId);
        req.setEventId(this.props.eventId);
        req.setMessage(this.state.myConfirmationText);
        sendAdminPush(req).then(function (response) {
            self.snackbar(true, 'Notification sent for Event', 'success', 5000);
            if (response.getStarted()) {
                self.setState({
                    myConfirmationText: '',
                    notifying: false,
                });
            }
            self.receiveNotifHistory();
        }).catch(function (error) {
            self.snackbar(true, 'Error sending Notification', 'error', 5000);
            self.setState({
                notifying: false,
            });
        })
    };

    receiveNotifHistory = () => {
        let self = this;
        this.setState({
            loadingList: true,
        });
        const req = new PushLogForContentRequest();
        req.setGroupId(this.props.postId);
        req.setContentId(this.props.eventId);
        req.setContentType('EVENT');
        req.setPageSize();
        req.setCursor();
        contentNotificationHistory(req).then(function (response) {
            const notificationLogsList = response.getPushlogsList();
            const totalCount = response.getPushCount();
            let enableSend;

            if (isAdmin() || (totalCount < self.props.maxEventSendCount && totalCount >= 0)) {
                enableSend = true
            }
            self.setState({
                notificationLogsList: notificationLogsList,
                totalCount: totalCount,
                loadingList: false,
                enableSend
            });

        }).catch(function (error) {
            self.setState({
                loadingList: false,
            });
        })
    };

    receiveGroupNotificationtHistory = () => {
        let self = this;
        this.setState({
            loadingList: true,
        });
        const req = new PushLogRequest();
        req.setGroupId(this.props.postId);
        req.setPageSize();
        req.setCursor();
        groupNotificationHistory(req).then(function (response) {
            const notificationLogsList = response.getPushlogsList();
            const totalCount = response.getPushCount();
            self.setState({
                notificationLogsList: notificationLogsList,
                totalCount: totalCount,
                loadingList: false,
            });

        }).catch(function (error) {
            self.setState({
                loadingList: false,
            });
        })
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    render() {
        const {width, classes, maxEventSendCount} = this.props;
        const {open, dialogHeader, myConfirmationText, notifying, notificationLogsList, totalCount, loadingList, enableSend} = this.state;
        const isMobile = ['xs', 'sm'].includes(width);
        return (
            <div>
                <Dialog
                    fullScreen={isMobile}
                    fullWidth
                    open={open}
                    maxWidth={'sm'}
                    onClose={this.handleClose}
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        {dialogHeader}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{padding: '10px 0'}}>
                            <Typography component="div" variant="subtitle1" style={{color: '#615f5f'}}>
                                Send notification to group members.
                            </Typography>
                            {!loadingList ? (
                                    <div>
                                        <Typography component="div" variant="h6" style={{color: '#615f5f'}}>
                                            Notification History
                                        </Typography>
                                        {totalCount ? (
                                            <div
                                                className={classes.notificationWrapper}> {totalCount}/{maxEventSendCount} Notification
                                                has been
                                                sent already.</div>
                                        ) : null}
                                    </div>)
                                : null}
                            <br/>
                            {!loadingList ? (
                                <div>
                                    {notificationLogsList.length ? (
                                        <ExpanssionList notifList={notificationLogsList}/>
                                    ) : <span>No Previous Logs</span>}
                                </div>
                            ) : <span>Please Wait&nbsp; &nbsp;<CircularProgress size={12}/> </span>}
                        </div>
                        <br/>
                        {enableSend ? (
                            <div>
                                <Typography component="div" style={{padding: '5px 0'}}>
                                    Please type message in the box below.
                                </Typography>
                                <TextField
                                    multiline
                                    required
                                    variant="outlined"
                                    id="notificationMsg"
                                    name="notificationMsg"
                                    fullWidth
                                    rows="3"
                                    rowsMax="25"
                                    value={myConfirmationText}
                                    onChange={this.handleChange('myConfirmationText')}
                                    autoComplete="Notification Message"
                                />
                            </div>
                        ) : null}
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => this.handleClose()}>
                            Cancel
                        </Button>
                        {enableSend ? (
                            <Button onClick={() => this.sendNotification()} color="secondary" variant="contained"
                                    disabled={!myConfirmationText || notifying || myConfirmationText.trim().length === 0}>
                                {notifying ? (<span>Notifying&nbsp; &nbsp;<CircularProgress
                                    size={12}/> </span>) : 'Notify Now'}
                            </Button>
                        ) : null}
                    </DialogActions>
                </Dialog>
                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor}
                              snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
            </div>
        )
    }
}

NotificationConfirmation.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withWidth())(NotificationConfirmation);