import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {withStyles} from '@material-ui/core';
// Shared layouts
// Component styles
import styles from './styles';
import usedString, {getEventTypeStringInSentence} from "../../../../utils/mapper/enumToStringMapper";

class EventStickyInfo extends Component {
    render() {
        const {classes, event} = this.props;

        return (
            <div>
                {getEventTypeStringInSentence(event.getEventStatus()) === usedString.Open || getEventTypeStringInSentence(event.getEventStatus()) === usedString.Postponed ?
                    event.getTicketAvailable() ? (
                        <div>
                            {(event.getMetaDataMap().get('max_price') === '0.0' && event.getMetaDataMap().get('min_price') === '0.0') ? (
                                <div className={classes.freeTicketsInfo}>
                                    Free Tickets
                                </div>) : (
                                <div className={classes.buyTicketsInfo}>
                                    Get Tickets
                                </div>
                            )}
                        </div>
                    ) : null :
                    <div className={classes.eventStsInfo}>
                        {getEventTypeStringInSentence(event.getEventStatus())}
                    </div>
                }
            </div>
        );
    }
}

EventStickyInfo.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EventStickyInfo);
