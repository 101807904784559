/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
goog.object.extend(proto, frontend$social$common$types_pb);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.AddPostRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.AddPostResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.Content', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPostDetailRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPostDetailResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPostRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPostResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPostsRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPostsResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.Post', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.Post.PostType', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.PostDetail', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ReactionDetail', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.RemovePostRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.RemovePostResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdatePostRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdatePostResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UploadImageRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UploadImageResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPostDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetPostDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPostDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetPostDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ReactionDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.ReactionDetail.displayName = 'proto.com.hamropatro.sociallayer.io.ReactionDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.displayName = 'proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse.displayName = 'proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdatePostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdatePostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.UpdatePostRequest.displayName = 'proto.com.hamropatro.sociallayer.io.UpdatePostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdatePostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdatePostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.UpdatePostResponse.displayName = 'proto.com.hamropatro.sociallayer.io.UpdatePostResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.RemovePostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.RemovePostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.RemovePostRequest.displayName = 'proto.com.hamropatro.sociallayer.io.RemovePostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.RemovePostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.RemovePostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.RemovePostResponse.displayName = 'proto.com.hamropatro.sociallayer.io.RemovePostResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.AddPostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.AddPostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.AddPostRequest.displayName = 'proto.com.hamropatro.sociallayer.io.AddPostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.AddPostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.AddPostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.AddPostResponse.displayName = 'proto.com.hamropatro.sociallayer.io.AddPostResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.Post = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.Post, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.Post.displayName = 'proto.com.hamropatro.sociallayer.io.Post';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.Content = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.Content, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.Content.displayName = 'proto.com.hamropatro.sociallayer.io.Content';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPostsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPostsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetPostsRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetPostsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPostsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetPostsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPostsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetPostsResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetPostsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.PostDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.PostDetail.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.PostDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.PostDetail.displayName = 'proto.com.hamropatro.sociallayer.io.PostDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UploadImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UploadImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.UploadImageRequest.displayName = 'proto.com.hamropatro.sociallayer.io.UploadImageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UploadImageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UploadImageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.UploadImageResponse.displayName = 'proto.com.hamropatro.sociallayer.io.UploadImageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetPostRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetPostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.hamropatro.sociallayer.io.GetPostResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetPostResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reaction: (f = msg.getReaction()) && frontend$social$common$types_pb.Reaction.toObject(includeInstance, f),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
    contentmetadatalogged: jspb.Message.getFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostDetailRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetPostDetailRequest;
  return proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostDetailRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new frontend$social$common$types_pb.Reaction;
      reader.readMessage(value,frontend$social$common$types_pb.Reaction.deserializeBinaryFromReader);
      msg.setReaction(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 4:
      var value = new frontend$social$common$types_pb.AccountInfo;
      reader.readMessage(value,frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
      msg.setBusinessAccountInfo(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContentmetadatalogged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      frontend$social$common$types_pb.Reaction.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessAccountInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getContentmetadatalogged();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional Reaction reaction = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.Reaction}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.getReaction = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.Reaction} */ (
    jspb.Message.getWrapperField(this, frontend$social$common$types_pb.Reaction, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Reaction|undefined} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.setReaction = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.clearReaction = function() {
  this.setReaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.hasReaction = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 page_size = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.setPageSize = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.setPageToken = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AccountInfo business_account_info = 4;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.getBusinessAccountInfo = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
    jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 4));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.setBusinessAccountInfo = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.clearBusinessAccountInfo = function() {
  this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.hasBusinessAccountInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool contentMetadataLogged = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.getContentmetadatalogged = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailRequest.prototype.setContentmetadatalogged = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.repeatedFields_ = [2,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reactionDetail: (f = msg.getReactionDetail()) && proto.com.hamropatro.sociallayer.io.ReactionDetail.toObject(includeInstance, f),
    commentDetailList: jspb.Message.toObjectList(msg.getCommentDetailList(),
    frontend$social$common$types_pb.CommentDetail.toObject, includeInstance),
    liked: jspb.Message.getFieldWithDefault(msg, 3, false),
    disliked: jspb.Message.getFieldWithDefault(msg, 4, false),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
    postOwnerBusinessId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    contentmetadatalogged: jspb.Message.getFieldWithDefault(msg, 8, false),
    listofreactedusersList: jspb.Message.toObjectList(msg.getListofreactedusersList(),
    frontend$social$common$types_pb.AccountInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostDetailResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetPostDetailResponse;
  return proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostDetailResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.hamropatro.sociallayer.io.ReactionDetail;
      reader.readMessage(value,proto.com.hamropatro.sociallayer.io.ReactionDetail.deserializeBinaryFromReader);
      msg.setReactionDetail(value);
      break;
    case 2:
      var value = new frontend$social$common$types_pb.CommentDetail;
      reader.readMessage(value,frontend$social$common$types_pb.CommentDetail.deserializeBinaryFromReader);
      msg.addCommentDetail(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiked(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisliked(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 6:
      var value = new frontend$social$common$types_pb.AccountInfo;
      reader.readMessage(value,frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
      msg.setBusinessAccountInfo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOwnerBusinessId(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContentmetadatalogged(value);
      break;
    case 9:
      var value = new frontend$social$common$types_pb.AccountInfo;
      reader.readMessage(value,frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
      msg.addListofreactedusers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReactionDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.hamropatro.sociallayer.io.ReactionDetail.serializeBinaryToWriter
    );
  }
  f = message.getCommentDetailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      frontend$social$common$types_pb.CommentDetail.serializeBinaryToWriter
    );
  }
  f = message.getLiked();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDisliked();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBusinessAccountInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getPostOwnerBusinessId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getContentmetadatalogged();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getListofreactedusersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReactionDetail reaction_detail = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.ReactionDetail}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.getReactionDetail = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.ReactionDetail} */ (
    jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.ReactionDetail, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.ReactionDetail|undefined} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.setReactionDetail = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.clearReactionDetail = function() {
  this.setReactionDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.hasReactionDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated CommentDetail comment_detail = 2;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.CommentDetail>}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.getCommentDetailList = function() {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.CommentDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, frontend$social$common$types_pb.CommentDetail, 2));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.CommentDetail>} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.setCommentDetailList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.CommentDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.CommentDetail}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.addCommentDetail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.hamropatro.sociallayer.io.CommentDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.clearCommentDetailList = function() {
  this.setCommentDetailList([]);
};


/**
 * optional bool liked = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.getLiked = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.setLiked = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool disliked = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.getDisliked = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.setDisliked = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string next_page_token = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.setNextPageToken = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AccountInfo business_account_info = 6;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.getBusinessAccountInfo = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
    jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 6));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.setBusinessAccountInfo = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.clearBusinessAccountInfo = function() {
  this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.hasBusinessAccountInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string post_owner_business_id = 7;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.getPostOwnerBusinessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.setPostOwnerBusinessId = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool contentMetadataLogged = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.getContentmetadatalogged = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.setContentmetadatalogged = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated AccountInfo listOfReactedUsers = 9;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.getListofreactedusersList = function() {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, frontend$social$common$types_pb.AccountInfo, 9));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>} value */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.setListofreactedusersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.addListofreactedusers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.com.hamropatro.sociallayer.io.AccountInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetPostDetailResponse.prototype.clearListofreactedusersList = function() {
  this.setListofreactedusersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reaction: (f = msg.getReaction()) && frontend$social$common$types_pb.Reaction.toObject(includeInstance, f),
    userReactionCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
    contentmetadatalogged: jspb.Message.getFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest;
  return proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new frontend$social$common$types_pb.Reaction;
      reader.readMessage(value,frontend$social$common$types_pb.Reaction.deserializeBinaryFromReader);
      msg.setReaction(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserReactionCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 4:
      var value = new frontend$social$common$types_pb.AccountInfo;
      reader.readMessage(value,frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
      msg.setBusinessAccountInfo(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContentmetadatalogged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      frontend$social$common$types_pb.Reaction.serializeBinaryToWriter
    );
  }
  f = message.getUserReactionCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessAccountInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getContentmetadatalogged();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional Reaction reaction = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.Reaction}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.getReaction = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.Reaction} */ (
    jspb.Message.getWrapperField(this, frontend$social$common$types_pb.Reaction, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Reaction|undefined} value */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.setReaction = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.clearReaction = function() {
  this.setReaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.hasReaction = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 user_reaction_count = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.getUserReactionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.setUserReactionCount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.setPageToken = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AccountInfo business_account_info = 4;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.getBusinessAccountInfo = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
    jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 4));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.setBusinessAccountInfo = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.clearBusinessAccountInfo = function() {
  this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.hasBusinessAccountInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool contentMetadataLogged = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.getContentmetadatalogged = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.GetPostSummaryRequest.prototype.setContentmetadatalogged = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reactionDetail: (f = msg.getReactionDetail()) && proto.com.hamropatro.sociallayer.io.ReactionDetail.toObject(includeInstance, f),
    liked: jspb.Message.getFieldWithDefault(msg, 3, false),
    disliked: jspb.Message.getFieldWithDefault(msg, 4, false),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
    postOwnerBusinessId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    listofreactedusersList: jspb.Message.toObjectList(msg.getListofreactedusersList(),
    frontend$social$common$types_pb.AccountInfo.toObject, includeInstance),
    contentmetadatalogged: jspb.Message.getFieldWithDefault(msg, 9, false),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse;
  return proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.hamropatro.sociallayer.io.ReactionDetail;
      reader.readMessage(value,proto.com.hamropatro.sociallayer.io.ReactionDetail.deserializeBinaryFromReader);
      msg.setReactionDetail(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiked(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisliked(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 6:
      var value = new frontend$social$common$types_pb.AccountInfo;
      reader.readMessage(value,frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
      msg.setBusinessAccountInfo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOwnerBusinessId(value);
      break;
    case 8:
      var value = new frontend$social$common$types_pb.AccountInfo;
      reader.readMessage(value,frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
      msg.addListofreactedusers(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContentmetadatalogged(value);
      break;
    case 10:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReactionDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.hamropatro.sociallayer.io.ReactionDetail.serializeBinaryToWriter
    );
  }
  f = message.getLiked();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDisliked();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBusinessAccountInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getPostOwnerBusinessId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getListofreactedusersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getContentmetadatalogged();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional ReactionDetail reaction_detail = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.ReactionDetail}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.getReactionDetail = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.ReactionDetail} */ (
    jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.ReactionDetail, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.ReactionDetail|undefined} value */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.setReactionDetail = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.clearReactionDetail = function() {
  this.setReactionDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.hasReactionDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool liked = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.getLiked = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.setLiked = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool disliked = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.getDisliked = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.setDisliked = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string next_page_token = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.setNextPageToken = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AccountInfo business_account_info = 6;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.getBusinessAccountInfo = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
    jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 6));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.setBusinessAccountInfo = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.clearBusinessAccountInfo = function() {
  this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.hasBusinessAccountInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string post_owner_business_id = 7;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.getPostOwnerBusinessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.setPostOwnerBusinessId = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated AccountInfo listOfReactedUsers = 8;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.getListofreactedusersList = function() {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, frontend$social$common$types_pb.AccountInfo, 8));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>} value */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.setListofreactedusersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.addListofreactedusers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.com.hamropatro.sociallayer.io.AccountInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.clearListofreactedusersList = function() {
  this.setListofreactedusersList([]);
};


/**
 * optional bool contentMetadataLogged = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.getContentmetadatalogged = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.setContentmetadatalogged = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * map<string, string> metadata = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.com.hamropatro.sociallayer.io.GetPostSummaryResponse.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.ReactionDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.ReactionDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    appId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    postUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    likes: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dislikes: jspb.Message.getFieldWithDefault(msg, 4, 0),
    views: jspb.Message.getFieldWithDefault(msg, 5, 0),
    uniqueViews: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalComments: jspb.Message.getFieldWithDefault(msg, 7, 0),
    approvedComments: jspb.Message.getFieldWithDefault(msg, 8, 0),
    spamComments: jspb.Message.getFieldWithDefault(msg, 9, 0),
    reactionType: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactionDetail}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.ReactionDetail;
  return proto.com.hamropatro.sociallayer.io.ReactionDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactionDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactionDetail}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostUri(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLikes(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDislikes(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setViews(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUniqueViews(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalComments(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setApprovedComments(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSpamComments(value);
      break;
    case 10:
      var value = /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (reader.readEnum());
      msg.setReactionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.ReactionDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactionDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPostUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLikes();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getDislikes();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getViews();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getUniqueViews();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getTotalComments();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getApprovedComments();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getSpamComments();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getReactionType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional string app_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.getAppId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.setAppId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string post_uri = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.getPostUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.setPostUri = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 likes = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.getLikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.setLikes = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 dislikes = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.getDislikes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.setDislikes = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 views = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.getViews = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.setViews = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 unique_views = 6;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.getUniqueViews = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.setUniqueViews = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 total_comments = 7;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.getTotalComments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.setTotalComments = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 approved_comments = 8;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.getApprovedComments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.setApprovedComments = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 spam_comments = 9;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.getSpamComments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.setSpamComments = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional ReactionType reaction_type = 10;
 * @return {!proto.com.hamropatro.sociallayer.io.ReactionType}
 */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.getReactionType = function() {
  return /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.ReactionType} value */
proto.com.hamropatro.sociallayer.io.ReactionDetail.prototype.setReactionType = function(value) {
  jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    owner: (f = msg.getOwner()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
    createdDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    contentType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    metaDataMap: (f = msg.getMetaDataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest;
  return proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new frontend$social$common$types_pb.AccountInfo;
      reader.readMessage(value,frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCreatedDate(value);
      break;
    case 4:
      var value = /** @type {!proto.com.hamropatro.sociallayer.io.ContentType} */ (reader.readEnum());
      msg.setContentType(value);
      break;
    case 5:
      var value = msg.getMetaDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getCreatedDate();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getContentType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMetaDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AccountInfo owner = 2;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.getOwner = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
    jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 2));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.setOwner = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.clearOwner = function() {
  this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 created_date = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.getCreatedDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.setCreatedDate = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ContentType content_type = 4;
 * @return {!proto.com.hamropatro.sociallayer.io.ContentType}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.getContentType = function() {
  return /** @type {!proto.com.hamropatro.sociallayer.io.ContentType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.ContentType} value */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.setContentType = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * map<string, string> meta_data = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.getMetaDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataRequest.prototype.clearMetaDataMap = function() {
  this.getMetaDataMap().clear();
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse;
  return proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ContentMetaDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.UpdatePostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.UpdatePostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdatePostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    post: (f = msg.getPost()) && proto.com.hamropatro.sociallayer.io.Post.toObject(includeInstance, f),
    businessId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdatePostRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UpdatePostRequest;
  return proto.com.hamropatro.sociallayer.io.UpdatePostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdatePostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdatePostRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.hamropatro.sociallayer.io.Post;
      reader.readMessage(value,proto.com.hamropatro.sociallayer.io.Post.deserializeBinaryFromReader);
      msg.setPost(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UpdatePostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdatePostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdatePostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPost();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.hamropatro.sociallayer.io.Post.serializeBinaryToWriter
    );
  }
  f = message.getBusinessId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Post post = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.Post}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostRequest.prototype.getPost = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.Post} */ (
    jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.Post, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Post|undefined} value */
proto.com.hamropatro.sociallayer.io.UpdatePostRequest.prototype.setPost = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.UpdatePostRequest.prototype.clearPost = function() {
  this.setPost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostRequest.prototype.hasPost = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string business_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostRequest.prototype.getBusinessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdatePostRequest.prototype.setBusinessId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.UpdatePostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.UpdatePostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdatePostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    post: (f = msg.getPost()) && proto.com.hamropatro.sociallayer.io.Post.toObject(includeInstance, f),
    businessId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdatePostResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UpdatePostResponse;
  return proto.com.hamropatro.sociallayer.io.UpdatePostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdatePostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdatePostResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.hamropatro.sociallayer.io.Post;
      reader.readMessage(value,proto.com.hamropatro.sociallayer.io.Post.deserializeBinaryFromReader);
      msg.setPost(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UpdatePostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdatePostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdatePostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPost();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.hamropatro.sociallayer.io.Post.serializeBinaryToWriter
    );
  }
  f = message.getBusinessId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Post post = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.Post}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostResponse.prototype.getPost = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.Post} */ (
    jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.Post, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Post|undefined} value */
proto.com.hamropatro.sociallayer.io.UpdatePostResponse.prototype.setPost = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.UpdatePostResponse.prototype.clearPost = function() {
  this.setPost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostResponse.prototype.hasPost = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string business_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdatePostResponse.prototype.getBusinessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdatePostResponse.prototype.setBusinessId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.RemovePostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.RemovePostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.RemovePostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemovePostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.RemovePostRequest}
 */
proto.com.hamropatro.sociallayer.io.RemovePostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.RemovePostRequest;
  return proto.com.hamropatro.sociallayer.io.RemovePostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.RemovePostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.RemovePostRequest}
 */
proto.com.hamropatro.sociallayer.io.RemovePostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.RemovePostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.RemovePostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.RemovePostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemovePostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemovePostRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemovePostRequest.prototype.setGroupId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemovePostRequest.prototype.getContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemovePostRequest.prototype.setContentId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_name = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemovePostRequest.prototype.getCategoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemovePostRequest.prototype.setCategoryName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.RemovePostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.RemovePostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.RemovePostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemovePostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    postId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.RemovePostResponse}
 */
proto.com.hamropatro.sociallayer.io.RemovePostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.RemovePostResponse;
  return proto.com.hamropatro.sociallayer.io.RemovePostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.RemovePostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.RemovePostResponse}
 */
proto.com.hamropatro.sociallayer.io.RemovePostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.RemovePostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.RemovePostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.RemovePostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemovePostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPostId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string post_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemovePostResponse.prototype.getPostId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemovePostResponse.prototype.setPostId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemovePostResponse.prototype.getBusinessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemovePostResponse.prototype.setBusinessId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.AddPostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.AddPostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.AddPostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.AddPostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    post: (f = msg.getPost()) && proto.com.hamropatro.sociallayer.io.Post.toObject(includeInstance, f),
    businessId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.AddPostRequest}
 */
proto.com.hamropatro.sociallayer.io.AddPostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.AddPostRequest;
  return proto.com.hamropatro.sociallayer.io.AddPostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.AddPostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.AddPostRequest}
 */
proto.com.hamropatro.sociallayer.io.AddPostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.hamropatro.sociallayer.io.Post;
      reader.readMessage(value,proto.com.hamropatro.sociallayer.io.Post.deserializeBinaryFromReader);
      msg.setPost(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.AddPostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.AddPostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.AddPostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.AddPostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPost();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.hamropatro.sociallayer.io.Post.serializeBinaryToWriter
    );
  }
  f = message.getBusinessId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Post post = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.Post}
 */
proto.com.hamropatro.sociallayer.io.AddPostRequest.prototype.getPost = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.Post} */ (
    jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.Post, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Post|undefined} value */
proto.com.hamropatro.sociallayer.io.AddPostRequest.prototype.setPost = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.AddPostRequest.prototype.clearPost = function() {
  this.setPost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.AddPostRequest.prototype.hasPost = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string business_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AddPostRequest.prototype.getBusinessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AddPostRequest.prototype.setBusinessId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.AddPostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.AddPostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.AddPostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.AddPostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    added: jspb.Message.getFieldWithDefault(msg, 1, false),
    postId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    postUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.AddPostResponse}
 */
proto.com.hamropatro.sociallayer.io.AddPostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.AddPostResponse;
  return proto.com.hamropatro.sociallayer.io.AddPostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.AddPostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.AddPostResponse}
 */
proto.com.hamropatro.sociallayer.io.AddPostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdded(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.AddPostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.AddPostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.AddPostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.AddPostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdded();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPostId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPostUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool added = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.AddPostResponse.prototype.getAdded = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.AddPostResponse.prototype.setAdded = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string post_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AddPostResponse.prototype.getPostId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AddPostResponse.prototype.setPostId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string post_url = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AddPostResponse.prototype.getPostUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AddPostResponse.prototype.setPostUrl = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.Post.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.Post} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.Post.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    category: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    postType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    content: (f = msg.getContent()) && proto.com.hamropatro.sociallayer.io.Content.toObject(includeInstance, f),
    postOwner: (f = msg.getPostOwner()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f),
    edited: jspb.Message.getFieldWithDefault(msg, 8, false),
    lastUpdated: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.Post}
 */
proto.com.hamropatro.sociallayer.io.Post.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.Post;
  return proto.com.hamropatro.sociallayer.io.Post.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.Post} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.Post}
 */
proto.com.hamropatro.sociallayer.io.Post.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostUrl(value);
      break;
    case 5:
      var value = /** @type {!proto.com.hamropatro.sociallayer.io.Post.PostType} */ (reader.readEnum());
      msg.setPostType(value);
      break;
    case 6:
      var value = new proto.com.hamropatro.sociallayer.io.Content;
      reader.readMessage(value,proto.com.hamropatro.sociallayer.io.Content.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 7:
      var value = new frontend$social$common$types_pb.AccountInfo;
      reader.readMessage(value,frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
      msg.setPostOwner(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEdited(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.Post.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.Post} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.Post.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPostType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.hamropatro.sociallayer.io.Content.serializeBinaryToWriter
    );
  }
  f = message.getPostOwner();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getEdited();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getLastUpdated();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.hamropatro.sociallayer.io.Post.PostType = {
  DEFAULT: 0,
  IMAGE: 1,
  TEXT: 2,
  IMAGE_TEXT: 3,
  AUDIO: 4,
  VIDEO: 5,
  LINK: 6
};

/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Post.prototype.setGroupId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contentId = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.getContentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Post.prototype.setContentid = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Post.prototype.setCategory = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string post_url = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.getPostUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.Post.prototype.setPostUrl = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional PostType post_type = 5;
 * @return {!proto.com.hamropatro.sociallayer.io.Post.PostType}
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.getPostType = function() {
  return /** @type {!proto.com.hamropatro.sociallayer.io.Post.PostType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.Post.PostType} value */
proto.com.hamropatro.sociallayer.io.Post.prototype.setPostType = function(value) {
  jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Content content = 6;
 * @return {?proto.com.hamropatro.sociallayer.io.Content}
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.getContent = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.Content} */ (
    jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.Content, 6));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Content|undefined} value */
proto.com.hamropatro.sociallayer.io.Post.prototype.setContent = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.clearContent = function() {
  this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.hasContent = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AccountInfo post_owner = 7;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.getPostOwner = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
    jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 7));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.Post.prototype.setPostOwner = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.clearPostOwner = function() {
  this.setPostOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.hasPostOwner = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool edited = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.getEdited = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.Post.prototype.setEdited = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int64 last_updated = 9;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.Post.prototype.getLastUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.Post.prototype.setLastUpdated = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.Content.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.Content.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.Content} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.Content.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, undefined) : [],
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.Content}
 */
proto.com.hamropatro.sociallayer.io.Content.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.Content;
  return proto.com.hamropatro.sociallayer.io.Content.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.Content} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.Content}
 */
proto.com.hamropatro.sociallayer.io.Content.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
         });
      break;
    case 2:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.Content.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.Content.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.Content} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.Content.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> data = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.Content.prototype.getDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.com.hamropatro.sociallayer.io.Content.prototype.clearDataMap = function() {
  this.getDataMap().clear();
};


/**
 * map<string, string> metadata = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.Content.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.com.hamropatro.sociallayer.io.Content.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    category: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cursor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest;
  return proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.prototype.setGroupId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.prototype.setCategory = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cursor = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.prototype.setCursor = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetGroupPostByCategoryRequest.prototype.setPageSize = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.GetPostsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.GetPostsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cursor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPostsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetPostsRequest;
  return proto.com.hamropatro.sociallayer.io.GetPostsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostsRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPostsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPostsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetPostsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPostsRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPostsRequest.prototype.setGroupId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cursor = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPostsRequest.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPostsRequest.prototype.setCursor = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetPostsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetPostsRequest.prototype.setPageSize = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    postId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cursor: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest;
  return proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPostId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.prototype.setGroupId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_name = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.prototype.getCategoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.prototype.setCategoryName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string post_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.prototype.getPostId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.prototype.setPostId = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cursor = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.prototype.setCursor = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 page_size = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdRequest.prototype.setPageSize = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    posts: (f = msg.getPosts()) && proto.com.hamropatro.sociallayer.io.Post.toObject(includeInstance, f),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse;
  return proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.hamropatro.sociallayer.io.Post;
      reader.readMessage(value,proto.com.hamropatro.sociallayer.io.Post.deserializeBinaryFromReader);
      msg.setPosts(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.hamropatro.sociallayer.io.Post.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Post posts = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.Post}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.prototype.getPosts = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.Post} */ (
    jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.Post, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Post|undefined} value */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.prototype.setPosts = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.prototype.clearPosts = function() {
  this.setPosts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.prototype.hasPosts = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupPostByIdResponse.prototype.setNextPageToken = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetPostsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.GetPostsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.GetPostsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    postDetailsList: jspb.Message.toObjectList(msg.getPostDetailsList(),
    proto.com.hamropatro.sociallayer.io.PostDetail.toObject, includeInstance),
    nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPostsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetPostsResponse;
  return proto.com.hamropatro.sociallayer.io.GetPostsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPostsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.hamropatro.sociallayer.io.PostDetail;
      reader.readMessage(value,proto.com.hamropatro.sociallayer.io.PostDetail.deserializeBinaryFromReader);
      msg.addPostDetails(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageCursor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPostsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetPostsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPostDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.hamropatro.sociallayer.io.PostDetail.serializeBinaryToWriter
    );
  }
  f = message.getNextPageCursor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated PostDetail post_details = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.PostDetail>}
 */
proto.com.hamropatro.sociallayer.io.GetPostsResponse.prototype.getPostDetailsList = function() {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.PostDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.PostDetail, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.PostDetail>} value */
proto.com.hamropatro.sociallayer.io.GetPostsResponse.prototype.setPostDetailsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.PostDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.PostDetail}
 */
proto.com.hamropatro.sociallayer.io.GetPostsResponse.prototype.addPostDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.PostDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetPostsResponse.prototype.clearPostDetailsList = function() {
  this.setPostDetailsList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPostsResponse.prototype.getNextPageCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPostsResponse.prototype.setNextPageCursor = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.PostDetail.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.PostDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.PostDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.PostDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    post: (f = msg.getPost()) && proto.com.hamropatro.sociallayer.io.Post.toObject(includeInstance, f),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    postOwnerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    listofreactedusersList: jspb.Message.toObjectList(msg.getListofreactedusersList(),
    frontend$social$common$types_pb.AccountInfo.toObject, includeInstance),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.PostDetail}
 */
proto.com.hamropatro.sociallayer.io.PostDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.PostDetail;
  return proto.com.hamropatro.sociallayer.io.PostDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.PostDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.PostDetail}
 */
proto.com.hamropatro.sociallayer.io.PostDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.hamropatro.sociallayer.io.Post;
      reader.readMessage(value,proto.com.hamropatro.sociallayer.io.Post.deserializeBinaryFromReader);
      msg.setPost(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOwnerId(value);
      break;
    case 6:
      var value = new frontend$social$common$types_pb.AccountInfo;
      reader.readMessage(value,frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
      msg.addListofreactedusers(value);
      break;
    case 7:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.PostDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.PostDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.PostDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPost();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.hamropatro.sociallayer.io.Post.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPostOwnerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getListofreactedusersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional Post post = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.Post}
 */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.getPost = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.Post} */ (
    jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.Post, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Post|undefined} value */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.setPost = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.clearPost = function() {
  this.setPost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.hasPost = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string next_page_token = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.setNextPageToken = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string post_owner_id = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.getPostOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.setPostOwnerId = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated AccountInfo listOfReactedUsers = 6;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>}
 */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.getListofreactedusersList = function() {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, frontend$social$common$types_pb.AccountInfo, 6));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>} value */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.setListofreactedusersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.addListofreactedusers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.hamropatro.sociallayer.io.AccountInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.clearListofreactedusersList = function() {
  this.setListofreactedusersList([]);
};


/**
 * map<string, string> metadata = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 */
proto.com.hamropatro.sociallayer.io.PostDetail.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    postList: jspb.Message.toObjectList(msg.getPostList(),
    proto.com.hamropatro.sociallayer.io.Post.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse;
  return proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.hamropatro.sociallayer.io.Post;
      reader.readMessage(value,proto.com.hamropatro.sociallayer.io.Post.deserializeBinaryFromReader);
      msg.addPost(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPostList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.hamropatro.sociallayer.io.Post.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Post post = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.Post>}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.prototype.getPostList = function() {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.Post>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.Post, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.Post>} value */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.prototype.setPostList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.Post=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.Post}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.prototype.addPost = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.Post, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.prototype.clearPostList = function() {
  this.setPostList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupPostsResponse.prototype.setNextPageToken = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.UploadImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.UploadImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.UploadImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UploadImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    location: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UploadImageRequest}
 */
proto.com.hamropatro.sociallayer.io.UploadImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UploadImageRequest;
  return proto.com.hamropatro.sociallayer.io.UploadImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UploadImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UploadImageRequest}
 */
proto.com.hamropatro.sociallayer.io.UploadImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UploadImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UploadImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UploadImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UploadImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string location = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UploadImageRequest.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UploadImageRequest.prototype.setLocation = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UploadImageRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UploadImageRequest.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.UploadImageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.UploadImageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.UploadImageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UploadImageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadingUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UploadImageResponse}
 */
proto.com.hamropatro.sociallayer.io.UploadImageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UploadImageResponse;
  return proto.com.hamropatro.sociallayer.io.UploadImageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UploadImageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UploadImageResponse}
 */
proto.com.hamropatro.sociallayer.io.UploadImageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadingUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UploadImageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UploadImageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UploadImageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UploadImageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadingUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uploading_url = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UploadImageResponse.prototype.getUploadingUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UploadImageResponse.prototype.setUploadingUrl = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.GetPostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.GetPostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    postId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetPostRequest;
  return proto.com.hamropatro.sociallayer.io.GetPostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetPostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPostId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPostRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPostRequest.prototype.setGroupId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string post_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPostRequest.prototype.getPostId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPostRequest.prototype.setPostId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.hamropatro.sociallayer.io.GetPostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.hamropatro.sociallayer.io.GetPostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    post: (f = msg.getPost()) && proto.com.hamropatro.sociallayer.io.Post.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetPostResponse;
  return proto.com.hamropatro.sociallayer.io.GetPostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPostResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.hamropatro.sociallayer.io.Post;
      reader.readMessage(value,proto.com.hamropatro.sociallayer.io.Post.deserializeBinaryFromReader);
      msg.setPost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetPostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPost();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.hamropatro.sociallayer.io.Post.serializeBinaryToWriter
    );
  }
};


/**
 * optional Post post = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.Post}
 */
proto.com.hamropatro.sociallayer.io.GetPostResponse.prototype.getPost = function() {
  return /** @type{?proto.com.hamropatro.sociallayer.io.Post} */ (
    jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.Post, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Post|undefined} value */
proto.com.hamropatro.sociallayer.io.GetPostResponse.prototype.setPost = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 */
proto.com.hamropatro.sociallayer.io.GetPostResponse.prototype.clearPost = function() {
  this.setPost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetPostResponse.prototype.hasPost = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
