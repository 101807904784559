import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles, withWidth} from "@material-ui/core";
import styles from "./styles";
import {Dashboard as DashboardLayout} from 'layouts';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {timeEvent, timeRemaining} from "../../../utils/converter/dateConverter";
import {GroupDetailLoader} from "../../../components/Skeleton/EventsSket";
import {GetGroupRequest} from "../../../generated/frontend-community-group-service_pb";
import {getGroupDetails} from "../../../grpcRequests/groups-request";
import usedString, {
    getGroupTypeStringInSentence,
    getRoleString,
    getStatusString
} from "../../../utils/mapper/enumToStringMapper";
import SnackBar from "../../../components/SnackBar/SnackBar";
import {isAdmin, userInfo} from "../../../configurations/global-config";
import UserCommentMenus from "../../../components/UserCommentMenus";
import UserReply from "../../../components/ReplyBox/UserReply";
import compose from "recompose/compose";
import {GetCommentRequest} from "../../../generated/frontend-comment-service_pb";
import {getCommentDetails} from "../../../grpcRequests/comments-request";
import {Link} from "react-router-dom";
import RenderText from "../component/RenderText";
import {GetEventRequest} from "../../../generated/frontend-community-event-service_pb";
import {getEventDetails} from "../../../grpcRequests/events-request";

let defaultPageSize = 10;

class CommentDetails extends Component {
    state = {
        mainGroupID: this.props.match.params.groupId,
        postId: this.props.match.params.postId ? this.props.match.params.postId : this.props.match.params.eventId ? this.props.match.params.eventId : '',
        commentId: this.props.match.params.commentId,
        commentDetails: null, isLoadingComment: true,
        eventId: window.location.search ? (new URLSearchParams(window.location.search).get('eventId')) : '',
        title: 'Comments',
        postTitle: '',
        cType: '',
        userRole: '',
        userRoleStatus: '',
        eventType: '',


        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getUserRole(this.props.match.params.groupId);
        this.getCommentDetails(this.props.match.params.postId ? this.props.match.params.postId : this.props.match.params.eventId ? this.props.match.params.eventId : '', this.props.match.params.commentId);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.match.params.commentId !== this.props.match.params.commentId) {
            this.setState({
                mainGroupID: nextProps.match.params.groupId,
                postId: nextProps.match.params.postId ? nextProps.match.params.postId : nextProps.match.params.eventId ? nextProps.match.params.eventId : '',
                commentId: nextProps.match.params.commentId,
            });
            this.getUserRole(nextProps.match.params.groupId);
            this.getCommentDetails(nextProps.match.params.postId ? nextProps.match.params.postId : nextProps.match.params.eventId ? nextProps.match.params.eventId : '', nextProps.match.params.commentId);
        }
    }

    fetchEventDetails = (groupID, eventID) => {
        let self = this;
        const req = new GetEventRequest();
        req.setGroupId(groupID);
        req.setEventId(eventID);
        getEventDetails(req, 3).then(function (response) {
            const eventDetails = response.getEvent();
            const eventType = eventDetails.getIsPrivate() ? usedString.PRIVATE : usedString.PUBLIC
            self.setState({
                eventType
            })
        }).catch(function (error) {
        })
    };

    getUserRole = (groupId) => {
        let self = this;
        const req = new GetGroupRequest();
        req.setGroupId(groupId);
        getGroupDetails(req, 3).then(function (response) {
            const group = response.getGroup();
            const title = group.getName();
            const cType = getGroupTypeStringInSentence(group.getGrouptype());
            const userRole = getRoleString(group.getRole());
            const userRoleStatus = getStatusString(group.getStatus());
            self.setState({
                cType, userRole, title, userRoleStatus
            });
            if (self.state.eventId) {
                self.fetchEventDetails(groupId, self.state.eventId)
            }
        }).catch(function (error) {

        })
    };

    getUserResponseFromUserComment = (actionName, isActionCompleted, postId, commentKey,indexVal) => {
        this.setState({snackOpen: false});
        let self = this;
        if (isActionCompleted && actionName === 'delete') {
            self.snackbar(true, 'Comment deleted Successfully', 'success', 5000);
            window.location.href = "/c/" + self.props.match.params.groupId + "/p/" + self.props.match.params.postId;
        }
        else if (isActionCompleted && actionName === 'edited') {
            self.snackbar(true, 'Comment edited Successfully', 'success', 5000);
            self.getCommentDetails(postId, commentKey);
        }
        else {
            self.snackbar(true, 'Error while deleting comment', 'error', 5000);
        }
    };

    getCommentDetails = (postId, commentID) => {
        let self = this;
        this.setState({
            isLoadingComment: true,
            postTitle: window.location.search ? (new URLSearchParams(window.location.search).get('title')) : '',
            postId: postId
        });
        const req = new GetCommentRequest();
        req.setPostUri(postId);
        req.setCommentId(commentID);
        getCommentDetails(req, 3).then(function (response) {
            const isLoadingComment = false;
            let commentDetails = response.getCommentdetail();
            self.setState({
                isLoadingComment,
                commentDetails
            })
        }).catch(function (error) {
            const isLoadingComment = false;
            self.setState({
                isLoadingComment,
            })
        })
    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    render() {
        const {classes, width} = this.props;
        const {commentDetails, isLoadingComment, title, mainGroupID, userRole, postId, postTitle, userRoleStatus, eventId, eventType} = this.state;
        const isMobile = ['xs', 'sm', 'md'].includes(width);
        return (
            <DashboardLayout title={`${title ? title + " Post's comment" : "Community Post's comment"}`}
                             onBack={`/c/${mainGroupID}/p/${postId}`}
                             linkTo={`/c/${mainGroupID}/p/${postId}`} groupId={mainGroupID}>
                <div className={classes.root}>
                    {!isLoadingComment ? (
                        <div>
                            {commentDetails ? (
                                <div>
                                    <div>
                                        <Typography
                                            component="div"
                                            className={classes.textPost}
                                            color="textPrimary"
                                            style={{
                                                fontWeight: 600,
                                                whiteSpace: 'pre-line',
                                                color: '#676666',
                                                padding: '10px 5px'
                                            }}
                                        > Showing comment detail {postTitle ? 'from' : ''} <Link
                                            to={eventId ? `/c/${mainGroupID}/events/${eventId}` : `/c/${mainGroupID}/p/${postId}`}
                                            className={classes.commentClass}>
                                            {postTitle ? postTitle : eventId ? '(view event)' : '(view post)'}
                                        </Link>.
                                        </Typography>
                                    </div>
                                    <ListItem alignItems="flex-start"
                                              className={classes.commentWrapper}
                                              style={isMobile ? {
                                                  display: "inline-block", paddingTop: '1px',
                                                  paddingBottom: '1px'
                                              } : {
                                                  display: "flex", paddingTop: '1px',
                                                  paddingBottom: '1px'
                                              }}>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={commentDetails.getComment().getAccountInfo().getAccountName()}
                                                style={isMobile ? {display: 'none'} : {
                                                    backgroundImage: `url('${commentDetails.getComment().getAccountInfo().getAccountImage() ? commentDetails.getComment().getAccountInfo().getAccountImage().getImageUrl() : ''}')`,
                                                    backgroundRepeat: 'round'
                                                }}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            className={classes.listCover}
                                            primary={
                                                <React.Fragment>
                                                    <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                                        <Avatar
                                                            alt={commentDetails.getComment().getAccountInfo().getAccountName()}
                                                            style={
                                                                isMobile ?
                                                                    {
                                                                        backgroundImage: `url('${commentDetails.getComment().getAccountInfo().getAccountImage() ? commentDetails.getComment().getAccountInfo().getAccountImage().getImageUrl() : ''}')`,
                                                                        backgroundRepeat: 'round',
                                                                        width: '25px',
                                                                        height: '25px',
                                                                        marginRight: '8px'
                                                                    } : {display: 'none'}}/>
                                                        <Typography
                                                            component="div"
                                                            variant="body1"
                                                            className={classes.inline}
                                                            color="textPrimary"
                                                            style={{fontWeight: 600, lineHeight: 1.2}}
                                                        >
                                                            {commentDetails.getComment().getAccountInfo().getAccountName()}
                                                            <div
                                                                className={classes.timeUUID}><span
                                                                title={new Date(commentDetails.getComment().getCommentTimestamp()).toLocaleString()}>{timeRemaining(new Date(commentDetails.getComment().getCommentTimestamp()))}</span>
                                                                <span
                                                                    title={timeEvent(commentDetails.getComment().getCommentTimestamp())}
                                                                    className={classes.postTime}>{commentDetails.getComment().getEdited() ? '(edited)' : null}</span>
                                                            </div>
                                                        </Typography>
                                                    </div>
                                                    {commentDetails.getComment().getAccountInfo().getAccountId() === userInfo().user_id || isAdmin() || userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT ? (
                                                        <UserCommentMenus
                                                            commentID={commentDetails.getComment().getCommentId()}
                                                            postID={postId}
                                                            commentText={commentDetails.getComment().getComment().getText()}
                                                            ownerID={commentDetails.getComment().getAccountInfo().getAccountId()}
                                                            userRole={userRole}
                                                            sendUserResponse={this.getUserResponseFromUserComment}
                                                        />) : (
                                                        <span>&nbsp;</span>)}
                                                </React.Fragment>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        component="div"
                                                        className={classes.textPost}
                                                        color="textPrimary"
                                                        style={{
                                                            // fontWeight: 600,
                                                            whiteSpace: 'pre-line'
                                                        }}
                                                    >
                                                        <RenderText
                                                            text={commentDetails.getComment().getComment().getText()}/>
                                                    </Typography>
                                                    <div>
                                                        <UserReply
                                                            groupId={mainGroupID}
                                                            userRole={eventId && eventType === usedString.PUBLIC ? usedString.MEMBER : userRole}
                                                            userRoleStatus={eventId && eventType === usedString.PUBLIC ? usedString.APPROVED : userRoleStatus}
                                                            groupName={title}
                                                            commentId={commentDetails.getComment().getCommentId()}
                                                            commentPostId={commentDetails.getComment().getPostUri()}
                                                            commentLikes={commentDetails.getComment().getLikes()}
                                                            commentReplies={commentDetails.getComment().getReplies()}
                                                            userLikeSts={commentDetails.getLiked()}
                                                            openReply={true}/>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                </div>
                            ) : (<div></div>)}
                        </div>
                    ) : (<div><GroupDetailLoader/></div>)}
                </div>
                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
            </DashboardLayout>
        )
    }
}

CommentDetails.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withWidth())(CommentDetails);