/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.AddFolloweeRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.AddFolloweeResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.AddMemberRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.AddMemberResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.BlockMemberRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.BlockMemberResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.DirectJoinRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.DirectJoinResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetMemberListRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetMemberListResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.MemberDetail', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.RemoveMemberRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.RemoveMemberResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetMemberListRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetMemberListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetMemberListRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetMemberListRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetMemberListRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetMemberListRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetMemberListRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetMemberListRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            cursor: jspb.Message.getFieldWithDefault(msg, 2, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetMemberListRequest}
 */
proto.com.hamropatro.sociallayer.io.GetMemberListRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetMemberListRequest;
    return proto.com.hamropatro.sociallayer.io.GetMemberListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetMemberListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetMemberListRequest}
 */
proto.com.hamropatro.sociallayer.io.GetMemberListRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetMemberListRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetMemberListRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetMemberListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetMemberListRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeInt32(
            3,
            f
        );
    }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetMemberListRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetMemberListRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetMemberListRequest.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetMemberListRequest.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetMemberListRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetMemberListRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetMemberListResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetMemberListResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetMemberListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetMemberListResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetMemberListResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetMemberListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetMemberListResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetMemberListResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetMemberListResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetMemberListResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            membersList: jspb.Message.toObjectList(msg.getMembersList(),
                proto.com.hamropatro.sociallayer.io.MemberDetail.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetMemberListResponse}
 */
proto.com.hamropatro.sociallayer.io.GetMemberListResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetMemberListResponse;
    return proto.com.hamropatro.sociallayer.io.GetMemberListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetMemberListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetMemberListResponse}
 */
proto.com.hamropatro.sociallayer.io.GetMemberListResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.MemberDetail;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.MemberDetail.deserializeBinaryFromReader);
                msg.addMembers(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetMemberListResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetMemberListResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetMemberListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetMemberListResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMembersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.MemberDetail.serializeBinaryToWriter
        );
    }
    f = message.getNextPageCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * repeated MemberDetail members = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.MemberDetail>}
 */
proto.com.hamropatro.sociallayer.io.GetMemberListResponse.prototype.getMembersList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.MemberDetail>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.MemberDetail, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.MemberDetail>} value */
proto.com.hamropatro.sociallayer.io.GetMemberListResponse.prototype.setMembersList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.MemberDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.MemberDetail}
 */
proto.com.hamropatro.sociallayer.io.GetMemberListResponse.prototype.addMembers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.MemberDetail, opt_index);
};


proto.com.hamropatro.sociallayer.io.GetMemberListResponse.prototype.clearMembersList = function () {
    this.setMembersList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetMemberListResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetMemberListResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.MemberDetail = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.MemberDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.MemberDetail.displayName = 'proto.com.hamropatro.sociallayer.io.MemberDetail';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.MemberDetail.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.MemberDetail} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.MemberDetail.toObject = function (includeInstance, msg) {
        var f, obj = {
            appid: jspb.Message.getFieldWithDefault(msg, 1, ""),
            accountid: jspb.Message.getFieldWithDefault(msg, 2, ""),
            userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
            username: jspb.Message.getFieldWithDefault(msg, 4, ""),
            profileImage: jspb.Message.getFieldWithDefault(msg, 5, ""),
            email: jspb.Message.getFieldWithDefault(msg, 6, ""),
            role: jspb.Message.getFieldWithDefault(msg, 7, 0),
            status: jspb.Message.getFieldWithDefault(msg, 8, ""),
            since: jspb.Message.getFieldWithDefault(msg, 9, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.MemberDetail}
 */
proto.com.hamropatro.sociallayer.io.MemberDetail.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.MemberDetail;
    return proto.com.hamropatro.sociallayer.io.MemberDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.MemberDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.MemberDetail}
 */
proto.com.hamropatro.sociallayer.io.MemberDetail.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAppid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountid(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserid(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setUsername(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setProfileImage(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmail(value);
                break;
            case 7:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.MemberRole} */ (reader.readEnum());
                msg.setRole(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setStatus(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSince(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.MemberDetail.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.MemberDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.MemberDetail.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAppid();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getAccountid();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getUserid();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getUsername();
    if (f.length > 0) {
        writer.writeString(
            4,
            f
        );
    }
    f = message.getProfileImage();
    if (f.length > 0) {
        writer.writeString(
            5,
            f
        );
    }
    f = message.getEmail();
    if (f.length > 0) {
        writer.writeString(
            6,
            f
        );
    }
    f = message.getRole();
    if (f !== 0.0) {
        writer.writeEnum(
            7,
            f
        );
    }
    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(
            8,
            f
        );
    }
    f = message.getSince();
    if (f !== 0) {
        writer.writeInt64(
            9,
            f
        );
    }
};


/**
 * optional string appId = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.getAppid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.setAppid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string accountId = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.getAccountid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.setAccountid = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string userId = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.getUserid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.setUserid = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.getUsername = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.setUsername = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string profile_image = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.getProfileImage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.setProfileImage = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.getEmail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.setEmail = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional MemberRole role = 7;
 * @return {!proto.com.hamropatro.sociallayer.io.MemberRole}
 */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.getRole = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.MemberRole} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.MemberRole} value */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.setRole = function (value) {
    jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.setStatus = function (value) {
    jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 since = 9;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.getSince = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.MemberDetail.prototype.setSince = function (value) {
    jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
            cursor: jspb.Message.getFieldWithDefault(msg, 3, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest}
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest;
    return proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest}
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeInt32(
            2,
            f
        );
    }
    f = message.getCursor();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string cursor = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListRequest.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            adminlistList: jspb.Message.toObjectList(msg.getAdminlistList(),
                proto.com.hamropatro.sociallayer.io.MemberDetail.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse}
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse;
    return proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse}
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.com.hamropatro.sociallayer.io.MemberDetail;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.MemberDetail.deserializeBinaryFromReader);
                msg.addAdminlist(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAdminlistList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            1,
            f,
            proto.com.hamropatro.sociallayer.io.MemberDetail.serializeBinaryToWriter
        );
    }
    f = message.getNextPageCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * repeated MemberDetail adminList = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.MemberDetail>}
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.prototype.getAdminlistList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.MemberDetail>} */ (
        jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.MemberDetail, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.MemberDetail>} value */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.prototype.setAdminlistList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.MemberDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.MemberDetail}
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.prototype.addAdminlist = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.MemberDetail, opt_index);
};


proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.prototype.clearAdminlistList = function () {
    this.setAdminlistList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetAdminModeratorListResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.AddMemberRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.AddMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.AddMemberRequest.displayName = 'proto.com.hamropatro.sociallayer.io.AddMemberRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.AddMemberRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.AddMemberRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.AddMemberRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.AddMemberRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            member: (f = msg.getMember()) && proto.com.hamropatro.sociallayer.io.MemberDetail.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.AddMemberRequest}
 */
proto.com.hamropatro.sociallayer.io.AddMemberRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.AddMemberRequest;
    return proto.com.hamropatro.sociallayer.io.AddMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.AddMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.AddMemberRequest}
 */
proto.com.hamropatro.sociallayer.io.AddMemberRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 2:
                var value = new proto.com.hamropatro.sociallayer.io.MemberDetail;
                reader.readMessage(value, proto.com.hamropatro.sociallayer.io.MemberDetail.deserializeBinaryFromReader);
                msg.setMember(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.AddMemberRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.AddMemberRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.AddMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.AddMemberRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getMember();
    if (f != null) {
        writer.writeMessage(
            2,
            f,
            proto.com.hamropatro.sociallayer.io.MemberDetail.serializeBinaryToWriter
        );
    }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AddMemberRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AddMemberRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MemberDetail member = 2;
 * @return {?proto.com.hamropatro.sociallayer.io.MemberDetail}
 */
proto.com.hamropatro.sociallayer.io.AddMemberRequest.prototype.getMember = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.MemberDetail} */ (
        jspb.Message.getWrapperField(this, proto.com.hamropatro.sociallayer.io.MemberDetail, 2));
};


/** @param {?proto.com.hamropatro.sociallayer.io.MemberDetail|undefined} value */
proto.com.hamropatro.sociallayer.io.AddMemberRequest.prototype.setMember = function (value) {
    jspb.Message.setWrapperField(this, 2, value);
};


proto.com.hamropatro.sociallayer.io.AddMemberRequest.prototype.clearMember = function () {
    this.setMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.AddMemberRequest.prototype.hasMember = function () {
    return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.AddMemberResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.AddMemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.AddMemberResponse.displayName = 'proto.com.hamropatro.sociallayer.io.AddMemberResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.AddMemberResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.AddMemberResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.AddMemberResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.AddMemberResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            success: jspb.Message.getFieldWithDefault(msg, 2, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.AddMemberResponse}
 */
proto.com.hamropatro.sociallayer.io.AddMemberResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.AddMemberResponse;
    return proto.com.hamropatro.sociallayer.io.AddMemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.AddMemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.AddMemberResponse}
 */
proto.com.hamropatro.sociallayer.io.AddMemberResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSuccess(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.AddMemberResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.AddMemberResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.AddMemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.AddMemberResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getSuccess();
    if (f) {
        writer.writeBool(
            2,
            f
        );
    }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AddMemberResponse.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AddMemberResponse.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool success = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.AddMemberResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.AddMemberResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.AddFolloweeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.displayName = 'proto.com.hamropatro.sociallayer.io.AddFolloweeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.AddFolloweeRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            followeeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            followeeType: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.AddFolloweeRequest}
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.AddFolloweeRequest;
    return proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.AddFolloweeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.AddFolloweeRequest}
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setFolloweeId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setFolloweeType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.AddFolloweeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getFolloweeId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getFolloweeType();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string followee_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.prototype.getFolloweeId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.prototype.setFolloweeId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string followee_type = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.prototype.getFolloweeType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AddFolloweeRequest.prototype.setFolloweeType = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.AddFolloweeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.displayName = 'proto.com.hamropatro.sociallayer.io.AddFolloweeResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.AddFolloweeResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            followeeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            followed: jspb.Message.getFieldWithDefault(msg, 2, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.AddFolloweeResponse}
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.AddFolloweeResponse;
    return proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.AddFolloweeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.AddFolloweeResponse}
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setFolloweeId(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setFollowed(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.AddFolloweeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getFolloweeId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getFollowed();
    if (f) {
        writer.writeBool(
            2,
            f
        );
    }
};


/**
 * optional string followee_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.prototype.getFolloweeId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.prototype.setFolloweeId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool followed = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.prototype.getFollowed = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.AddFolloweeResponse.prototype.setFollowed = function (value) {
    jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.displayName = 'proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            followeeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            followeeType: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest}
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest;
    return proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest}
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setFolloweeId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setFolloweeType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getFolloweeId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getFolloweeType();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string followee_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.prototype.getFolloweeId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.prototype.setFolloweeId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string followee_type = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.prototype.getFolloweeType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeRequest.prototype.setFolloweeType = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.displayName = 'proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            removedFolloweeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            removed: jspb.Message.getFieldWithDefault(msg, 2, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse}
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse;
    return proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse}
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setRemovedFolloweeId(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setRemoved(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRemovedFolloweeId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getRemoved();
    if (f) {
        writer.writeBool(
            2,
            f
        );
    }
};


/**
 * optional string removed_followee_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.prototype.getRemovedFolloweeId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.prototype.setRemovedFolloweeId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool removed = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.prototype.getRemoved = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.RemoveFolloweeResponse.prototype.setRemoved = function (value) {
    jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            cursor: jspb.Message.getFieldWithDefault(msg, 2, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest;
    return proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest}
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeInt32(
            3,
            f
        );
    }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetPendingMembersRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            pendingUsersList: jspb.Message.toObjectList(msg.getPendingUsersList(),
                frontend$social$common$types_pb.AccountInfo.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 3, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse;
    return proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse}
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 2:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.addPendingUsers(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getPendingUsersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            2,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getNextPageCursor();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated AccountInfo pending_users = 2;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>}
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.prototype.getPendingUsersList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>} */ (
        jspb.Message.getRepeatedWrapperField(this, frontend$social$common$types_pb.AccountInfo, 2));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>} value */
proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.prototype.setPendingUsersList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.prototype.addPendingUsers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.hamropatro.sociallayer.io.AccountInfo, opt_index);
};


proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.prototype.clearPendingUsersList = function () {
    this.setPendingUsersList([]);
};


/**
 * optional string next_page_cursor = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetPendingMembersResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            cursor: jspb.Message.getFieldWithDefault(msg, 2, ""),
            pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest}
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest;
    return proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest}
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCursor(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setPageSize(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getCursor();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getPageSize();
    if (f !== 0) {
        writer.writeInt32(
            3,
            f
        );
    }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.prototype.getCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.prototype.setCursor = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.prototype.getPageSize = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersRequest.prototype.setPageSize = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            blockedUsersList: jspb.Message.toObjectList(msg.getBlockedUsersList(),
                frontend$social$common$types_pb.AccountInfo.toObject, includeInstance),
            nextPageCursor: jspb.Message.getFieldWithDefault(msg, 3, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse}
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse;
    return proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse}
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 2:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.addBlockedUsers(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageCursor(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getBlockedUsersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(
            2,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
    f = message.getNextPageCursor();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated AccountInfo blocked_users = 2;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>}
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.prototype.getBlockedUsersList = function () {
    return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>} */ (
        jspb.Message.getRepeatedWrapperField(this, frontend$social$common$types_pb.AccountInfo, 2));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.AccountInfo>} value */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.prototype.setBlockedUsersList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AccountInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.prototype.addBlockedUsers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.hamropatro.sociallayer.io.AccountInfo, opt_index);
};


proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.prototype.clearBlockedUsersList = function () {
    this.setBlockedUsersList([]);
};


/**
 * optional string next_page_cursor = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.prototype.getNextPageCursor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetBlockedMembersResponse.prototype.setNextPageCursor = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            followerType: jspb.Message.getFieldWithDefault(msg, 2, ""),
            followerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            status: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest;
    return proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setFollowerType(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setFollowerId(value);
                break;
            case 4:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.Status} */ (reader.readEnum());
                msg.setStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getFollowerType();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getFollowerId();
    if (f.length > 0) {
        writer.writeString(
            3,
            f
        );
    }
    f = message.getStatus();
    if (f !== 0.0) {
        writer.writeEnum(
            4,
            f
        );
    }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string follower_type = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.prototype.getFollowerType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.prototype.setFollowerType = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string follower_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.prototype.getFollowerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.prototype.setFollowerId = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.com.hamropatro.sociallayer.io.Status}
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.prototype.getStatus = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.Status} value */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusRequest.prototype.setStatus = function (value) {
    jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            followerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            updatedStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
            updated: jspb.Message.getFieldWithDefault(msg, 3, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse;
    return proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setFollowerId(value);
                break;
            case 2:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.Status} */ (reader.readEnum());
                msg.setUpdatedStatus(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setUpdated(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getFollowerId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getUpdatedStatus();
    if (f !== 0.0) {
        writer.writeEnum(
            2,
            f
        );
    }
    f = message.getUpdated();
    if (f) {
        writer.writeBool(
            3,
            f
        );
    }
};


/**
 * optional string follower_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.prototype.getFollowerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.prototype.setFollowerId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Status updated_status = 2;
 * @return {!proto.com.hamropatro.sociallayer.io.Status}
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.prototype.getUpdatedStatus = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.Status} value */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.prototype.setUpdatedStatus = function (value) {
    jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool updated = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.prototype.getUpdated = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UpdateFollowerStatusResponse.prototype.setUpdated = function (value) {
    jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.BlockMemberRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.BlockMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.BlockMemberRequest.displayName = 'proto.com.hamropatro.sociallayer.io.BlockMemberRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.BlockMemberRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.BlockMemberRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.BlockMemberRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.BlockMemberRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            idToBlock: jspb.Message.getFieldWithDefault(msg, 1, ""),
            groupId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.BlockMemberRequest}
 */
proto.com.hamropatro.sociallayer.io.BlockMemberRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.BlockMemberRequest;
    return proto.com.hamropatro.sociallayer.io.BlockMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.BlockMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.BlockMemberRequest}
 */
proto.com.hamropatro.sociallayer.io.BlockMemberRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setIdToBlock(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.BlockMemberRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.BlockMemberRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.BlockMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.BlockMemberRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIdToBlock();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string id_to_block = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.BlockMemberRequest.prototype.getIdToBlock = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.BlockMemberRequest.prototype.setIdToBlock = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.BlockMemberRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.BlockMemberRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.BlockMemberResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.BlockMemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.BlockMemberResponse.displayName = 'proto.com.hamropatro.sociallayer.io.BlockMemberResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.BlockMemberResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.BlockMemberResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.BlockMemberResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.BlockMemberResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            blockedUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            blocked: jspb.Message.getFieldWithDefault(msg, 3, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.BlockMemberResponse}
 */
proto.com.hamropatro.sociallayer.io.BlockMemberResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.BlockMemberResponse;
    return proto.com.hamropatro.sociallayer.io.BlockMemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.BlockMemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.BlockMemberResponse}
 */
proto.com.hamropatro.sociallayer.io.BlockMemberResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setBlockedUserId(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setBlocked(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.BlockMemberResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.BlockMemberResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.BlockMemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.BlockMemberResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getBlockedUserId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getBlocked();
    if (f) {
        writer.writeBool(
            3,
            f
        );
    }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.BlockMemberResponse.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.BlockMemberResponse.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string blocked_user_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.BlockMemberResponse.prototype.getBlockedUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.BlockMemberResponse.prototype.setBlockedUserId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool blocked = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.BlockMemberResponse.prototype.getBlocked = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.BlockMemberResponse.prototype.setBlocked = function (value) {
    jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.RemoveMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.displayName = 'proto.com.hamropatro.sociallayer.io.RemoveMemberRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.RemoveMemberRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            memberId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            groupId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveMemberRequest}
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.RemoveMemberRequest;
    return proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveMemberRequest}
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setMemberId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMemberId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string member_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.prototype.getMemberId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.prototype.setMemberId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveMemberRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.RemoveMemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.displayName = 'proto.com.hamropatro.sociallayer.io.RemoveMemberResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.RemoveMemberResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            removedMemberId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            groupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            success: jspb.Message.getFieldWithDefault(msg, 3, false)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveMemberResponse}
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.RemoveMemberResponse;
    return proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveMemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveMemberResponse}
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setRemovedMemberId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSuccess(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveMemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRemovedMemberId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getSuccess();
    if (f) {
        writer.writeBool(
            3,
            f
        );
    }
};


/**
 * optional string removed_member_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.prototype.getRemovedMemberId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.prototype.setRemovedMemberId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool success = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.RemoveMemberResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            groupId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest}
 */
proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest;
    return proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest}
 */
proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetMemberRoleRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            role: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse}
 */
proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse;
    return proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse}
 */
proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.MemberRole} */ (reader.readEnum());
                msg.setRole(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRole();
    if (f !== 0.0) {
        writer.writeEnum(
            1,
            f
        );
    }
};


/**
 * optional MemberRole role = 1;
 * @return {!proto.com.hamropatro.sociallayer.io.MemberRole}
 */
proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.prototype.getRole = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.MemberRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.MemberRole} value */
proto.com.hamropatro.sociallayer.io.GetMemberRoleResponse.prototype.setRole = function (value) {
    jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.DirectJoinRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.DirectJoinRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.DirectJoinRequest.displayName = 'proto.com.hamropatro.sociallayer.io.DirectJoinRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.DirectJoinRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.DirectJoinRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.DirectJoinRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.DirectJoinRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            link: jspb.Message.getFieldWithDefault(msg, 1, ""),
            groupId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.DirectJoinRequest}
 */
proto.com.hamropatro.sociallayer.io.DirectJoinRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.DirectJoinRequest;
    return proto.com.hamropatro.sociallayer.io.DirectJoinRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.DirectJoinRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.DirectJoinRequest}
 */
proto.com.hamropatro.sociallayer.io.DirectJoinRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLink(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.DirectJoinRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.DirectJoinRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.DirectJoinRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.DirectJoinRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLink();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string link = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DirectJoinRequest.prototype.getLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DirectJoinRequest.prototype.setLink = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DirectJoinRequest.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DirectJoinRequest.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.DirectJoinResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.DirectJoinResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.DirectJoinResponse.displayName = 'proto.com.hamropatro.sociallayer.io.DirectJoinResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.DirectJoinResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.DirectJoinResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.DirectJoinResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.DirectJoinResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            success: jspb.Message.getFieldWithDefault(msg, 1, false),
            groupId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.DirectJoinResponse}
 */
proto.com.hamropatro.sociallayer.io.DirectJoinResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.DirectJoinResponse;
    return proto.com.hamropatro.sociallayer.io.DirectJoinResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.DirectJoinResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.DirectJoinResponse}
 */
proto.com.hamropatro.sociallayer.io.DirectJoinResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSuccess(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setGroupId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.DirectJoinResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.DirectJoinResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.DirectJoinResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.DirectJoinResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSuccess();
    if (f) {
        writer.writeBool(
            1,
            f
        );
    }
    f = message.getGroupId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.DirectJoinResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.DirectJoinResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string group_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.DirectJoinResponse.prototype.getGroupId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.DirectJoinResponse.prototype.setGroupId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
