import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core";
import styles from "../../Home/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {eventAnalyst} from "../../../configurations/config";
import {memberCountValue, shortenString} from "../../../utils/converter/shortenString";
import GroupsStickyInfo from "./StickyInfo/GroupsStickyInfo";

class GroupsCard extends Component {
    state = {
        myGroupLists: this.props.groupList,
        title: this.props.titleHeader,
    };

    render() {
        const {classes} = this.props;
        const {myGroupLists, title} = this.state;
        const rootGroupsURl = '/c/';
        return (
            myGroupLists.map(group => (
                <div key={group.getGroupId()} className={classes.groupCard}>
                    <Card className={classes.singleCard}>
                        <Link to={`${rootGroupsURl}${group.getGroupId()}`}
                              onClick={() => eventAnalyst('home_card_click', group.getGroupName() + '_clicked', title + '-' + group.getGroupName(), group.getGroupName() + '-detail-click-success')}>
                            <CardMedia
                                className={classes.media}
                                alt={group.getGroupName()}
                                image={group.getImage() ? group.getImage() : '/images/defaultCover.png'}
                                title={group.getGroupName()}
                            />
                        </Link>
                        <CardContent className={classes.nameWrapper}>
                            <div className={classes.groupName}>
                                <Link to={`${rootGroupsURl}${group.getGroupId()}`}
                                      onClick={() => eventAnalyst('home_link_click', group.getGroupName() + '_clicked', title + '-' + group.getGroupName(), group.getGroupName() + '-detail-click-success')}>
                                    <Typography variant="body1" className={classes.name}
                                                title={group.getGroupName()}>
                                        <GroupsStickyInfo group={group} size={'15px'}/>
                                        {shortenString(group.getGroupName(), 25)}
                                    </Typography>
                                </Link>
                            </div>
                            <div>
                                {group.getFollowerCount() > 0 ? (
                                    <Typography variant="body2" className={classes.count}
                                                title={group.getFollowerCount()}>
                                        {memberCountValue(group.getFollowerCount())} {group.getFollowerCount() > 1 ? 'members' : 'member'}
                                    </Typography>
                                ) : (<span></span>)}
                            </div>
                        </CardContent>
                    </Card>
                </div>
            ))
        )
    }
}


GroupsCard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GroupsCard);