import {ReactionServiceServer as srv} from "../grpcClients/service-clients-factory";
import {MetaHeaders} from "../configurations/config";

export function likeUnlikePost(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.reactOnContent(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}
