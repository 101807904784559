/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetReactionCountRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetReactionCountResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ReactOnContentRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ReactOnContentResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ViewContentRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.ViewContentResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ViewContentRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ViewContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ViewContentRequest.displayName = 'proto.com.hamropatro.sociallayer.io.ViewContentRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ViewContentRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ViewContentRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ViewContentRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ViewContentRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
            accountId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ViewContentRequest}
 */
proto.com.hamropatro.sociallayer.io.ViewContentRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ViewContentRequest;
    return proto.com.hamropatro.sociallayer.io.ViewContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ViewContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ViewContentRequest}
 */
proto.com.hamropatro.sociallayer.io.ViewContentRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ViewContentRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ViewContentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ViewContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ViewContentRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
    f = message.getAccountId();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
};


/**
 * optional string post_uri = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ViewContentRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ViewContentRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.ViewContentRequest.prototype.getAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.ViewContentRequest.prototype.setAccountId = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ViewContentResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ViewContentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ViewContentResponse.displayName = 'proto.com.hamropatro.sociallayer.io.ViewContentResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ViewContentResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ViewContentResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ViewContentResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ViewContentResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ViewContentResponse}
 */
proto.com.hamropatro.sociallayer.io.ViewContentResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ViewContentResponse;
    return proto.com.hamropatro.sociallayer.io.ViewContentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ViewContentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ViewContentResponse}
 */
proto.com.hamropatro.sociallayer.io.ViewContentResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ViewContentResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ViewContentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ViewContentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ViewContentResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ReactOnContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.displayName = 'proto.com.hamropatro.sociallayer.io.ReactOnContentRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ReactOnContentRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            reaction: (f = msg.getReaction()) && frontend$social$common$types_pb.Reaction.toObject(includeInstance, f),
            businessAccountInfo: (f = msg.getBusinessAccountInfo()) && frontend$social$common$types_pb.AccountInfo.toObject(includeInstance, f)
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactOnContentRequest}
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ReactOnContentRequest;
    return proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactOnContentRequest}
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new frontend$social$common$types_pb.Reaction;
                reader.readMessage(value, frontend$social$common$types_pb.Reaction.deserializeBinaryFromReader);
                msg.setReaction(value);
                break;
            case 2:
                var value = new frontend$social$common$types_pb.AccountInfo;
                reader.readMessage(value, frontend$social$common$types_pb.AccountInfo.deserializeBinaryFromReader);
                msg.setBusinessAccountInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getReaction();
    if (f != null) {
        writer.writeMessage(
            1,
            f,
            frontend$social$common$types_pb.Reaction.serializeBinaryToWriter
        );
    }
    f = message.getBusinessAccountInfo();
    if (f != null) {
        writer.writeMessage(
            2,
            f,
            frontend$social$common$types_pb.AccountInfo.serializeBinaryToWriter
        );
    }
};


/**
 * optional Reaction reaction = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.Reaction}
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.prototype.getReaction = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.Reaction} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.Reaction, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.Reaction|undefined} value */
proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.prototype.setReaction = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.prototype.clearReaction = function () {
    this.setReaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.prototype.hasReaction = function () {
    return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AccountInfo business_account_info = 2;
 * @return {?proto.com.hamropatro.sociallayer.io.AccountInfo}
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.prototype.getBusinessAccountInfo = function () {
    return /** @type{?proto.com.hamropatro.sociallayer.io.AccountInfo} */ (
        jspb.Message.getWrapperField(this, frontend$social$common$types_pb.AccountInfo, 2));
};


/** @param {?proto.com.hamropatro.sociallayer.io.AccountInfo|undefined} value */
proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.prototype.setBusinessAccountInfo = function (value) {
    jspb.Message.setWrapperField(this, 2, value);
};


proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.prototype.clearBusinessAccountInfo = function () {
    this.setBusinessAccountInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentRequest.prototype.hasBusinessAccountInfo = function () {
    return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.ReactOnContentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.ReactOnContentResponse.displayName = 'proto.com.hamropatro.sociallayer.io.ReactOnContentResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.ReactOnContentResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.ReactOnContentResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.ReactOnContentResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.ReactOnContentResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactOnContentResponse}
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.ReactOnContentResponse;
    return proto.com.hamropatro.sociallayer.io.ReactOnContentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnContentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.ReactOnContentResponse}
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.ReactOnContentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.ReactOnContentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.ReactOnContentResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetReactionCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetReactionCountRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetReactionCountRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetReactionCountRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetReactionCountRequest.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetReactionCountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetReactionCountRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 1, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetReactionCountRequest}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetReactionCountRequest;
    return proto.com.hamropatro.sociallayer.io.GetReactionCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetReactionCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetReactionCountRequest}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetReactionCountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetReactionCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            1,
            f
        );
    }
};


/**
 * optional string post_uri = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountRequest.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetReactionCountRequest.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetReactionCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetReactionCountResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.toObject(opt_includeInstance, this);
    };


    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.com.hamropatro.sociallayer.io.GetReactionCountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            postUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
            likes: jspb.Message.getFieldWithDefault(msg, 3, 0),
            dislikes: jspb.Message.getFieldWithDefault(msg, 4, 0),
            views: jspb.Message.getFieldWithDefault(msg, 5, 0),
            uniqueViews: jspb.Message.getFieldWithDefault(msg, 6, 0),
            totalComments: jspb.Message.getFieldWithDefault(msg, 7, 0),
            approvedComments: jspb.Message.getFieldWithDefault(msg, 8, 0),
            spamComments: jspb.Message.getFieldWithDefault(msg, 9, 0),
            reactionType: jspb.Message.getFieldWithDefault(msg, 10, 0),
            postOwnerBusinessId: jspb.Message.getFieldWithDefault(msg, 11, "")
        };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetReactionCountResponse}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.com.hamropatro.sociallayer.io.GetReactionCountResponse;
    return proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetReactionCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetReactionCountResponse}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostUri(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLikes(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setDislikes(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setViews(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setUniqueViews(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setTotalComments(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setApprovedComments(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setSpamComments(value);
                break;
            case 10:
                var value = /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (reader.readEnum());
                msg.setReactionType(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostOwnerBusinessId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetReactionCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPostUri();
    if (f.length > 0) {
        writer.writeString(
            2,
            f
        );
    }
    f = message.getLikes();
    if (f !== 0) {
        writer.writeUint64(
            3,
            f
        );
    }
    f = message.getDislikes();
    if (f !== 0) {
        writer.writeUint64(
            4,
            f
        );
    }
    f = message.getViews();
    if (f !== 0) {
        writer.writeUint64(
            5,
            f
        );
    }
    f = message.getUniqueViews();
    if (f !== 0) {
        writer.writeUint64(
            6,
            f
        );
    }
    f = message.getTotalComments();
    if (f !== 0) {
        writer.writeUint64(
            7,
            f
        );
    }
    f = message.getApprovedComments();
    if (f !== 0) {
        writer.writeUint64(
            8,
            f
        );
    }
    f = message.getSpamComments();
    if (f !== 0) {
        writer.writeUint64(
            9,
            f
        );
    }
    f = message.getReactionType();
    if (f !== 0.0) {
        writer.writeEnum(
            10,
            f
        );
    }
    f = message.getPostOwnerBusinessId();
    if (f.length > 0) {
        writer.writeString(
            11,
            f
        );
    }
};


/**
 * optional string post_uri = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.getPostUri = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.setPostUri = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 likes = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.getLikes = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.setLikes = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 dislikes = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.getDislikes = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.setDislikes = function (value) {
    jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 views = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.getViews = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.setViews = function (value) {
    jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 unique_views = 6;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.getUniqueViews = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.setUniqueViews = function (value) {
    jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 total_comments = 7;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.getTotalComments = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.setTotalComments = function (value) {
    jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 approved_comments = 8;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.getApprovedComments = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.setApprovedComments = function (value) {
    jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 spam_comments = 9;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.getSpamComments = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.setSpamComments = function (value) {
    jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional ReactionType reaction_type = 10;
 * @return {!proto.com.hamropatro.sociallayer.io.ReactionType}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.getReactionType = function () {
    return /** @type {!proto.com.hamropatro.sociallayer.io.ReactionType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.ReactionType} value */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.setReactionType = function (value) {
    jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string post_owner_business_id = 11;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.getPostOwnerBusinessId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetReactionCountResponse.prototype.setPostOwnerBusinessId = function (value) {
    jspb.Message.setProto3StringField(this, 11, value);
};


goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
