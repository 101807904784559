export default theme => ({
    root: {
        padding: theme.spacing(2),
        minHeight: '85vh'
    },
    minHeight: {
        minHeight: '85vh'
    },
    card: {
        background: '#f4f6f7',
        boxShadow: 'none',
        width: '200px',
    },
    cardDetails: {
        background: '#f4f6f7',
        boxShadow: 'none',
    },
    media: {
        height: 0,
        paddingTop: '56.25%'
    },
    mediaEvent: {
        height: 0,
        paddingTop: '56.25%',
        borderRadius: '10px'
    },
    mediaEventTicket: {
        height: 0,
        paddingTop: '59.25%',
    },
    mediaEventDetails: {
        height: 0,
        paddingTop: '56.25%',
        borderRadius: '2px'
    },
    eventsCard: {
        padding: '5px 10px 15px'
    },
    latestEvent: {
        padding: '20px 0'
    },
    eventTime: {
        fontSize: '16px',
        padding: '5px 0',
        fontWeight: '600',
        float: 'left',
        height: '57px',
        overflow: 'hidden'
    },
    eventCardsWrapper: {
        display: 'inline-flex',
        overflow: 'auto',
        width: '100%',
    },
    eventCard: {
        margin: '10px'
    },
    cardName: {
        padding: '5px 10px'
    },
    interested: {
        color: '#b71c1c',
        float: 'right',
        margin: '-4px 0px',
        fontSize: '13px',
        display: 'inline-flex'
    },
    interestedBtn: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    interestedLink: {
        pointerEvents: 'none'
    },
    eventName: {
        clear: 'both',
        fontWeight: '200',
        fontSize: '13px',
        padding: '5px 0',
        color: '#615f5f',
    },
    eventSts: {
        color: '#615f5f',
    },
    eventLocation: {
        padding: '5px 0',
        color: '#615f5f',
    },
    eventMember: {
        cursor: 'pointer',
        float: 'left',
    },
    actionBar: {
        clear: 'both',
        margin: '15px auto'
    },
    beforeInterested: {
        width: '200px',
        padding: '7px 25px !important',
        boxShadow: 'none',
        color: '#777272',
        background: '#ffffff',
        fontWeight: 900,
        border: 'none',
        margin: '10px',
        borderRadius: '10px',
        '&:hover': {
            color: '#ffffff',
            background: '#0094ffe0',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    beforeGoing: {
        width: '200px',
        padding: '7px 25px !important',
        boxShadow: 'none',
        color: '#777272',
        background: '#ffffff',
        fontWeight: 900,
        border: 'none',
        margin: '10px',
        borderRadius: '10px',
        '&:hover': {
            color: '#ffffff',
            background: '#75B47C',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    beforeMayBe: {
        width: '200px',
        padding: '7px 25px !important',
        boxShadow: 'none',
        color: '#777272',
        background: '#ffffff',
        fontWeight: 900,
        border: 'none',
        margin: '10px',
        borderRadius: '10px',
        '&:hover': {
            color: '#ffffff',
            background: '#f9000078',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    interestedButton: {
        width: '200px',
        margin: '10px',
        padding: '7px 25px !important',
        boxShadow: 'none',
        color: '#ffffff',
        background: '#0094ffe0',
        fontWeight: 900,
        border: 'none',
        borderRadius: '10px',
        '&:hover': {
            color: '#ffffff',
            background: '#0094ffe0',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    textCenter: {
        textAlign: 'center'
    },
    fitContent: {
        width: 'fit-content !important'
    },
    invitationAccepted: {
        width: '200px',
        margin: '10px',
        padding: '7px 25px !important',
        boxShadow: 'none',
        color: '#ffffff',
        background: '#75B47C',
        fontWeight: 900,
        border: 'none',
        borderRadius: '10px',
        '&:hover': {
            color: '#ffffff',
            background: '#75B47C',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    invitationRejected: {
        width: '200px',
        margin: '10px',
        padding: '7px 25px !important',
        boxShadow: 'none',
        color: '#ffffff',
        background: '#f9000078',
        fontWeight: 900,
        border: 'none',
        borderRadius: '10px',
        '&:hover': {
            color: '#ffffff',
            background: '#f9000078',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    floatRight: {
        float: 'right'
    },
    floatLeft: {
        float: 'left'
    },
    clearBoth: {
        clear: 'both'
    },
    detailTabs: {
        boxShadow: 'unset',
        alignItems: 'center'
    },
    commentBoxWrapper: {
        background: '#f4f6f7',
        padding: '10px 0'
    },
    pageDivider: {
        width: '100%',
        padding: '5px',
        background: '#f4f6f7'
    },
    mapImage: {
        width: '100%',
        margin: '10px 0'
    },
    fab: {
        margin: '2px 5px'
    },
    notifyWrapper: {
        float: 'left',
        marginTop: '-65px',
    },
    actionWrapper: {
        float: 'right',
        marginTop: '-70px',
        display: 'inline-flex'
    },
    actionTicketWrapper: {
        float: 'right',
        marginRight: '-8px',
        marginTop: '-30px'
    },
    eventActionWrapper: {
        float: 'right',
        marginTop: '-40px'
    },
    sharableText: {
        fontFamily: 'monospace'
    },
    sharableLinkWrapper: {
        border: '1px solid #e5e5e5',
        padding: '10px',
        borderRadius: '5px'
    },
    noMemberWrapper: {
        padding: '10px',
        borderRadius: '5px',
        background: '#66aef1d4',
        color: '#253d49',
        fontSize: '16px',
        clear: 'both'
    },
    item: {
        height: '100%',
    },
    avatar: {
        margin: 5,
        width: 25,
        height: 25,
        border: '1px solid #e5e5e5'
    },
    nameWrapper: {
        textAlign: 'center',
        paddingTop: '15px',
        paddingBottom: '15px',
        height: '80px',
        overflow: 'hidden'
    },
    upComingEventsWrapper: {
        padding: '10px 5px',
    },
    ticketsCardWrapper: {
        padding: '15px 15px',
    },
    goingEvent: {
        float: 'right',
        marginTop: '-5px'
    },
    eventsDesc: {
        fontSize: '15px',
        height: '68px',
        overflow: 'hidden',
        fontWeight: 400,
        color: theme.palette.text.primary,
        padding: '5px 5px 10px',
        textAlign: 'center'
    },
    avatarWrapper: {
        padding: '10px',
        paddingTop: '5px',
        height: '60px',
    },
    titleTopBar: {
        marginTop: '25px',
        marginBottom: '15px'
    },
    boxItems: {
        marginTop: '15px',
        marginBottom: '30px'
    },
    buttonView: {
        padding: '5px 30px !important',
        boxShadow: 'none',
        color: '#ffffff',
        background: '#ed2124',
        '&:hover': {
            color: '#ffffff',
            background: '#ed2124',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    homeCardWrapper: {
        display: 'inline-flex',
        overflow: 'auto',
        width: '100%',
    },
    groupCard: {
        margin: '10px'
    },
    singleCard: {
        background: '#f4f6f7',
        boxShadow: 'none',
        width: '210px',
        height: '100%',
    },
    singleCardEvent: {
        boxShadow: 'none',
        minWidth: '210px',
        height: '100%',
    },
    flexWrapper: {
        display: 'inline-flex',
    },
    morePeople: {
        fontSize: '13px',
        fontWeight: '600',
    },
    topBarWrapper: {
        fontSize: '10px',
        color: '#5a5858',
        padding: '5px'
    },
    createdBy: {
        float: 'left',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    hostedBy: {
        color: '#0077e0',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    daysRemain: {
        float: 'right', padding: '5px'
    },
    likedUser: {
        float: 'right',
        display: 'flex'
    },
    likedUnOrder: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
    },
    likedList: {
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        margin: '0 -2px',
        border: '1px solid #ffffff',
        display: 'inline-block',
        position: 'relative',
        '&:nth-child(n+2)': {
            marginLeft: '-7px'
        }
    },
    likedUserImg: {
        cursor: 'pointer',
        width: '25px',
        height: '25px',
    },
    moreLikePeople: {
        fontSize: '9px',
    },
    discussion: {
        padding: '15px 15px 10px',
    },
    eventComment: {
        background: '#f4f6f7',
        padding: '20px 15px 15px',
        margin: '15px'
    },
    eventPost: {
        padding: '20px 15px 15px',
    },
    simpleUnderline: {
        paddingTop: '5px',
        clear: 'both',
        borderBottom: '1px solid #e5e5e5'
    },
    name: {
        fontSize: '17px',
        fontWeight: 600,
        color: theme.palette.text.primary,
        clear: 'both',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    ticketName: {
        fontSize: '16px',
        fontWeight: 800,
        color: theme.palette.text.primary,
        clear: 'both',
    },
    ticketDesc: {
        fontSize: '13px',
        color: theme.palette.text.primary,
        clear: 'both',
    },
    userTicketInfo: {
        fontSize: '13px',
        textAlign: 'center',
        fontStyle: 'italics',
        color: '#56c121'
    },
    count: {
        fontSize: '13px',
        color: theme.palette.text.primary,
        clear: 'both'
    },
    groupName: {
        maxHeight: '77px',
        overflow: 'hidden'
    },
    tableWrapper: {
        width: '100%',
        overflowX: 'auto',
        boxShadow: 'none'
    },
    table: {
        minWidth: 650,
    },
    headCell: {
        fontSize: '15px',
        fontWeight: '600'
    },
    ticketPriceCard: {
        padding: '5px 10px',
        background: '#f0a40f',
        fontWeight: 600,
        borderRadius: '5px'
    },
    ticketListWrapper: {
        borderTop: '1px solid #ffffff',
        padding: '10px',
        background: 'aliceblue',
        '&:hover': {
            background: '#efefef'
        }
    },
    ticketListWrapperMobile: {
        borderTop: '6px solid #ffffff',
        padding: '8px !important',
        background: 'aliceblue',
        '&:hover': {
            background: '#efefef'
        }
    },
    eventTicketLists: {},
    ticketItems: {
        padding: '10px 5px'
    },
    ticketNameList: {
        fontSize: '15px', fontWeight: 600
    },
    userTicketCount: {
        fontSize: '11px', color: '#3377e0', textDecoration: 'underline'
    },
    availableTicketDesc: {
        fontSize: '13px', fontWeight: 600, color: '#616263'
    },
    ticketAmount: {
        fontSize: '14px', fontWeight: 600
    },
    simpleLine: {
        borderTop: '3px solid #ffffff',
    },
    contactHover: {
        fontWeight: 600,
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(2),
    },
    sponsorImg: {
        maxHeight: "85px",
        width: '115px',
        overflow: 'hidden',
        // borderRadius: '5px',
        margin: '10px 15px',
    },
    eventStsWrapper: {
        color: '#e5e5e5'
    },
    eventStsCard: {
        padding: '15px 25px !important',
        boxShadow: 'none',
        color: '#ffffff',
        background: '#d25050',
        fontWeight: 900,
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        fontSize: '20px',
        maxWidth: '400px',
        margin: 'auto'
    },
    eventChangeSts: {
        margin: '10px 0',
        padding: '10px',
        borderRadius: '5px',
        background: '#f6b818',
        color: '#ffffff',
        fontSize: '16px',
        fontWeight: '600'
    },
    // videoWrapper:{
    //     position: 'relative',
    //     paddingBottom: '56.25%', /* 16:9 */
    //     paddingTop: '25px',
    //     height: 0,
    //     '&iframe':{
    //         position: 'absolute',
    //         top: 0,
    //         left: 0,
    //         width: '100%',
    //         height: '100%',
    //     }
    // },
    buyTicketsInfo: {
        margin: '10px 0px 0px',
        float: 'right',
        background: '#0a62c5',
        padding: '2px 10px',
        borderRadius: '5px 0px 0px 5px',
        fontSize: '12px',
        width: 'fit-content',
        color: '#ffffff',
    },
    eventStsInfo: {
        margin: '10px 0px 0px',
        float: 'right',
        background: '#f67718',
        padding: '2px 10px',
        borderRadius: '5px 0px 0px 5px',
        fontSize: '12px',
        width: 'fit-content',
        color: '#ffffff',
    },
    buyTicketsDetails: {
        margin: '15px 0px 0px',
        float: 'right',
        background: '#0a62c5',
        padding: '7px 15px',
        borderRadius: '5px 0px 0px 5px',
        fontSize: '17px',
        width: 'fit-content',
        color: '#ffffff',
        '&:hover': {
            padding: '8px 16px',
            cursor: 'pointer',
        }
    },
    eventStsDetails: {
        margin: '15px 0px 0px',
        float: 'right',
        background: '#f67718',
        padding: '7px 10px',
        borderRadius: '5px 0px 0px 5px',
        fontSize: '17px',
        width: 'fit-content',
        color: '#ffffff',
    },
    stickyNotesWrapper: {
        background: '#e1e4e6',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        margin: '0 20%',
        zIndex: '999'
    },
    stickyNotesMobileWrapper: {
        margin: 'auto',
        zIndex: '999',
        background: '#e1e4e6',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
    },
    stickyNotes: {
        padding: '5px 10px'
    },
    ticketButton: {
        width: '100%',
        maxWidth: '450px',
        padding: '10px 25px !important',
        boxShadow: 'none',
        color: '#ffffff',
        background: '#0094ffe0',
        fontWeight: 900,
        fontSize: '15px',
        border: 'none',
        borderRadius: '5px',
        '&:hover': {
            color: '#ffffff',
            background: '#0094ffe0',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        },
    },
    stickyDesc: {
        padding: '10px 5px',
        fontSize: '13px', fontWeight: 600, color: '#616263',
        textAlign: 'center'
    },
    closeIcon: {
        float: 'right',
        right: '-10px',
        top: '-17px',
        color: '#ffffff',
        padding: '1px',
        background: '#b31f22',
        '&:hover': {
            color: '#ffffff',
            background: '#b31f22',
        }
    }
});
