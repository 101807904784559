import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import IconButton from "@material-ui/core/IconButton";
import {MoreVert as MoreVertIcon} from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import UserConfirmationDialog from "./UserConfirmationDialog";
import {isAdmin, userInfo} from "../configurations/global-config";
import {RemovePostRequest} from "../generated/frontend-post-service_pb";
import {deletePost} from "../grpcRequests/posts-request";
import SnackBar from "./SnackBar/SnackBar";
import usedString from "../utils/mapper/enumToStringMapper";
import EditDialogBox from "./PostBox/EditDialogBox";

class UserPostMenus extends Component {
    state = {
        anchorEl: null,
        openConfirmation: false,
        openEditDialog: false,

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null
    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut,
        });
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget, openConfirmation: false, openEditDialog: false});
    };

    handleCloseMenu = () => {
        this.setState({anchorEl: null});
    };

    openConfirmation = () => {
        this.setState({openConfirmation: true, anchorEl: null});
    };

    editPost = () => {
        this.setState({openEditDialog: true, anchorEl: null});
    };

    getUserResponseFromChild = (clickedButton, postKey) => {
        if (clickedButton === "YES") {
            //delete
            this.deletePost(postKey);
        }
    };

    getEditResponseFromChild = (actionType, actionStatus, editedCategory, editedKey, msg) => {
      if(actionStatus){
            this.handleCloseMenu();
            this.sendResponseToPostedContent(actionType, actionStatus, editedKey);
        }
    };

    deletePost = (deleteKey) => {
        this.setState({snackOpen: false});
        let self = this;
        this.snackbar(true, 'Deleting Post', 'info', 2000);
        const req = new RemovePostRequest();
        req.setGroupId(this.props.groupID);
        req.setContentId(deleteKey);
        req.setCategoryName(this.props.groupCategory);
        deletePost(req).then(function (response) {
            self.sendResponseToPostedContent("delete", true, deleteKey);
        }).catch(function (error) {
            self.sendResponseToPostedContent("delete", false, deleteKey);
        })
    };

    editMyPost = (editKey) => {
        this.setState({snackOpen: false});
        let self = this;
        this.snackbar(true, 'Editing Post', 'info', 2000);
    };

    sendResponseToPostedContent = (actionName, isActionCompleted, key) => {
        this.props.sendUserResponse(actionName, isActionCompleted, key, this.props.indexValue);
    };

    render() {
        const {classes, postText, ownerID, postID, userRole, userPostData} = this.props;
        const {anchorEl, openConfirmation,openEditDialog} = this.state;
        const open = Boolean(anchorEl);
        return (
            <span>
                <IconButton
                    aria-label="Account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                    size="small"
                    className={classes.moreVertPost}
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="menu-id"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    className={classes.menuThreeVert}
                    onClose={this.handleCloseMenu}
                >
                    {ownerID === userInfo().user_id ? (
                        <MenuItem onClick={this.editPost}>Edit Post</MenuItem>
                    ):null}
                    {ownerID === userInfo().user_id || isAdmin() || userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT ? (
                        <div>
                            <MenuItem onClick={this.openConfirmation}>Delete Post</MenuItem>
                        </div>
                    ) : (
                        <div>
                            <MenuItem>Save</MenuItem>
                            <MenuItem>Report</MenuItem>
                        </div>
                    )}

                </Menu>
                {openConfirmation ? (
                    <UserConfirmationDialog
                        openDialog={openConfirmation} dialogHeader="Delete Post"
                        postId={postID}
                        dialogContent="Are you sure want to delete your post ? " button1="NO" button2="YES"
                        toDeleteContent={<div className={classes.sharableLinkWrapper}>
                            <span className={classes.sharableText}> {postText}</span>
                        </div>}
                        sendUserResponse={this.getUserResponseFromChild}
                    />
                ) : (<div></div>)}

                {openEditDialog ? (
                    <EditDialogBox
                        userRole={userRole}
                        openDialog={openEditDialog} dialogHeader="Edit Post"
                        postId={postID}
                        postData={userPostData}
                        button1="NO" button2="YES"
                        sendUserResponse={this.getEditResponseFromChild}
                    />
                ) : (<div></div>)}

                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
            </span>
        )
    }
}

UserPostMenus.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserPostMenus);