import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import IconButton from "@material-ui/core/IconButton";
import {MoreVert as MoreVertIcon} from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
    ChangeBusinessAccountMemberRoleRequest,
    DeleteBusinessAccountMemberRequest,
    Role
} from "../generated/frontend-business-account-service_grpc_web_pb";
import {changeUserGroupRole, deleteMemberFromGroup} from "../grpcRequests/business-groups-request";
import UserConfirmationDialog from "./UserConfirmationDialog";
import {userInfo} from "../configurations/global-config";
import usedString from "../utils/mapper/enumToStringMapper";

class AdminMemberMenus extends Component {
    state = {
        anchorEl: null,
        removeConfirm: false,
        leaveConfirm: false,
        makeAdminConfirm: false,
        makeModeratorConfirm: false,

    };

    removeConfirm = () => {
        this.setState({removeConfirm: true});
    };

    leaveConfirm = () => {
        this.setState({leaveConfirm: true});
    };

    makeAdminConfirm = () => {
        this.setState({makeAdminConfirm: true});
    };

    makeModeratorConfirm = () => {
        this.setState({makeModeratorConfirm: true});
    };

    getRemoveResponse = (clickedButton, key) => {
        this.setState({removeConfirm: false, anchorEl: null});
        if (clickedButton === "YES") {
            this.removeUser(this.props.userNAME);
        }
    };
    getMakeAdminResponse = (clickedButton, key) => {
        this.setState({makeAdminConfirm: false, anchorEl: null});
        if (clickedButton === "YES") {
            this.changeGroupRole(this.props.userNAME, usedString.ADMIN, "make admin");
        }
    };
    getMakeModeratorResponse = (clickedButton, key) => {
        this.setState({makeModeratorConfirm: false, anchorEl: null});
        if (clickedButton === "YES") {
            this.changeGroupRole(this.props.userNAME, usedString.PARTICIPANT, "make moderator");
        }
    };
    getLeaveResponse = (clickedButton, key) => {
        this.setState({leaveConfirm: false, anchorEl: null});
        if (clickedButton === "YES") {
            //  leave groups
            this.leaveCommunity(this.props.userNAME)
        }
    };

    sendResponseToUserComment = (username, actionName, isActionCompleted) => {
        this.props.sendCompletedResponse(username, actionName, isActionCompleted);
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleCloseMenu = () => {
        this.setState({anchorEl: null});
    };

    removeUser = (userName) => {
        let self = this;
        const req = new DeleteBusinessAccountMemberRequest();
        req.setBusinessAccountId(self.props.groupId);
        req.setUserId(this.props.userId);

        deleteMemberFromGroup(req).then(function (response) {
            self.handleCloseMenu();
            self.sendResponseToUserComment(userName, "remove", true);
        }).catch(function (error) {
            self.handleCloseMenu();
            self.sendResponseToUserComment(userName, "remove", false);
        })
    };

    leaveCommunity = (userName) => {
        let self = this;
        const req = new DeleteBusinessAccountMemberRequest();
        req.setBusinessAccountId(self.props.groupId);
        req.setUserId(this.props.userId);

        deleteMemberFromGroup(req).then(function (response) {
            self.handleCloseMenu();
            self.sendResponseToUserComment(userName, "leave", true);
        }).catch(function (error) {
            self.handleCloseMenu();
            self.sendResponseToUserComment(userName, "leave", false);
        })
    };

    changeGroupRole = (userName, role, actionName) => {
        let self = this;
        let sts;
        if (role === usedString.ADMIN)
            sts = Role.ADMIN;
        else if (role === usedString.PARTICIPANT)
            sts = Role.PARTICIPANT;
        else sts = Role.DEFAULT;
        const req = new ChangeBusinessAccountMemberRoleRequest();
        req.setBusinessAccountId(self.props.groupId);
        req.setUserId(this.props.userId);
        req.setRole(sts);
        changeUserGroupRole(req).then(function (response) {
            self.handleCloseMenu();
            self.sendResponseToUserComment(userName, actionName, true);
        }).catch(function (error) {
            self.handleCloseMenu();
            self.sendResponseToUserComment(userName, actionName, false);
        })
    };

    render() {
        const {classes, userROLE, userNAME, userId, myROLE} = this.props;
        const {anchorEl, removeConfirm, makeAdminConfirm, makeModeratorConfirm, leaveConfirm} = this.state;
        const open = Boolean(anchorEl);
        return (
            <div>
                <IconButton
                    aria-label="Account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={this.handleCloseMenu}
                >
                    {userId === userInfo().user_id ? (
                        <div>
                            <MenuItem onClick={this.leaveConfirm}>Leave</MenuItem>
                        </div>
                    ) : (<div>
                        {myROLE === usedString.ADMIN ? (
                            <div>
                                <MenuItem onClick={this.removeConfirm}>Remove Member</MenuItem>
                                {userROLE === usedString.ADMIN ? (
                                    <MenuItem onClick={this.makeModeratorConfirm}>Make Moderator</MenuItem>
                                ) : <MenuItem onClick={this.makeAdminConfirm}>Make Admin</MenuItem>}
                            </div>
                        ) : <MenuItem onClick={this.makeAdminConfirm}>Make Admin</MenuItem>}
                    </div>)}
                </Menu>
                {removeConfirm ? (
                    <UserConfirmationDialog
                        openDialog={removeConfirm} dialogHeader="Remove User"
                        postId={userId}
                        dialogContent={"Are you sure want to remove ?"} button1="NO" button2="YES"
                        toDeleteContent={<div className={classes.sharableLinkWrapper}>
                            <span className={classes.sharableText}> {userNAME}</span>
                        </div>}
                        sendUserResponse={this.getRemoveResponse}
                    />
                ) : (<div></div>)}
                {makeAdminConfirm ? (
                    <UserConfirmationDialog
                        openDialog={makeAdminConfirm} dialogHeader="Make Admin"
                        postId={userId}
                        dialogContent={"Are you sure want to make " + userNAME + " admin ?"} button1="NO" button2="YES"
                        toDeleteContent={<div className={classes.sharableLinkWrapper}>
                            <span className={classes.sharableText}> {userNAME}</span>
                        </div>}
                        sendUserResponse={this.getMakeAdminResponse}
                    />
                ) : (<div></div>)}
                {makeModeratorConfirm ? (
                    <UserConfirmationDialog
                        openDialog={makeModeratorConfirm} dialogHeader="Make Moderator"
                        postId={userId}
                        dialogContent={"Are you sure want to make " + userNAME + " moderator ?"} button1="NO"
                        button2="YES"
                        toDeleteContent={<div className={classes.sharableLinkWrapper}>
                            <span className={classes.sharableText}> {userNAME}</span>
                        </div>}
                        sendUserResponse={this.getMakeModeratorResponse}
                    />
                ) : (<div></div>)}
                {leaveConfirm ? (
                    <UserConfirmationDialog
                        openDialog={leaveConfirm} dialogHeader="Leave"
                        postId={userId}
                        dialogContent={"Are you sure want to leave the community ? "} button1="NO" button2="YES"
                        toDeleteContent={<div className={classes.sharableLinkWrapper}>
                            <span className={classes.sharableText}> {userNAME}</span>
                        </div>}
                        sendUserResponse={this.getLeaveResponse}
                    />
                ) : (<div></div>)}
            </div>
        )
    }
}

AdminMemberMenus.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AdminMemberMenus);