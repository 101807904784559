/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js');
var frontend$ticket$common$types_pb = require('./frontend-ticket-common-types_pb.js');
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.AddTicketRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.AddTicketResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetTicketRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetTicketResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetUserTicketRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.GetUserTicketResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.RemoveTicketRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.RemoveTicketResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.TicketTypeOverview', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.TransactionDetailRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.TransactionDetailResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateTicketRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UpdateTicketResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest', null, global);
goog.exportSymbol('proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.AddTicketRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.AddTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.AddTicketRequest.displayName = 'proto.com.hamropatro.sociallayer.io.AddTicketRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.AddTicketRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.AddTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.AddTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.AddTicketRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticket: (f = msg.getTicket()) && frontend$ticket$common$types_pb.TicketModel.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.AddTicketRequest}
 */
proto.com.hamropatro.sociallayer.io.AddTicketRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.AddTicketRequest;
  return proto.com.hamropatro.sociallayer.io.AddTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.AddTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.AddTicketRequest}
 */
proto.com.hamropatro.sociallayer.io.AddTicketRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new frontend$ticket$common$types_pb.TicketModel;
        reader.readMessage(value, frontend$ticket$common$types_pb.TicketModel.deserializeBinaryFromReader);
        msg.setTicket(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.AddTicketRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.AddTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.AddTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.AddTicketRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicket();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        frontend$ticket$common$types_pb.TicketModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional TicketModel ticket = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.TicketModel}
 */
proto.com.hamropatro.sociallayer.io.AddTicketRequest.prototype.getTicket = function () {
  return /** @type{?proto.com.hamropatro.sociallayer.io.TicketModel} */ (
      jspb.Message.getWrapperField(this, frontend$ticket$common$types_pb.TicketModel, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.TicketModel|undefined} value */
proto.com.hamropatro.sociallayer.io.AddTicketRequest.prototype.setTicket = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.sociallayer.io.AddTicketRequest.prototype.clearTicket = function () {
  this.setTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.AddTicketRequest.prototype.hasTicket = function () {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.AddTicketResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.AddTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.AddTicketResponse.displayName = 'proto.com.hamropatro.sociallayer.io.AddTicketResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.AddTicketResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.AddTicketResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.AddTicketResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.AddTicketResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      success: jspb.Message.getFieldWithDefault(msg, 1, false),
      ticketTypeId: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.AddTicketResponse}
 */
proto.com.hamropatro.sociallayer.io.AddTicketResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.AddTicketResponse;
  return proto.com.hamropatro.sociallayer.io.AddTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.AddTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.AddTicketResponse}
 */
proto.com.hamropatro.sociallayer.io.AddTicketResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSuccess(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketTypeId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.AddTicketResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.AddTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.AddTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.AddTicketResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
        1,
        f
    );
  }
  f = message.getTicketTypeId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.AddTicketResponse.prototype.getSuccess = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.AddTicketResponse.prototype.setSuccess = function (value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string ticket_type_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.AddTicketResponse.prototype.getTicketTypeId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.AddTicketResponse.prototype.setTicketTypeId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateTicketRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UpdateTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticket: (f = msg.getTicket()) && frontend$ticket$common$types_pb.TicketModel.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateTicketRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UpdateTicketRequest;
  return proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateTicketRequest}
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new frontend$ticket$common$types_pb.TicketModel;
        reader.readMessage(value, frontend$ticket$common$types_pb.TicketModel.deserializeBinaryFromReader);
        msg.setTicket(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicket();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        frontend$ticket$common$types_pb.TicketModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional TicketModel ticket = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.TicketModel}
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.prototype.getTicket = function () {
  return /** @type{?proto.com.hamropatro.sociallayer.io.TicketModel} */ (
      jspb.Message.getWrapperField(this, frontend$ticket$common$types_pb.TicketModel, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.TicketModel|undefined} value */
proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.prototype.setTicket = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.prototype.clearTicket = function () {
  this.setTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketRequest.prototype.hasTicket = function () {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UpdateTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.displayName = 'proto.com.hamropatro.sociallayer.io.UpdateTicketResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UpdateTicketResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      success: jspb.Message.getFieldWithDefault(msg, 1, false),
      ticketId: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateTicketResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UpdateTicketResponse;
  return proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UpdateTicketResponse}
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSuccess(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
        1,
        f
    );
  }
  f = message.getTicketId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.prototype.getSuccess = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.prototype.setSuccess = function (value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string ticket_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.prototype.getTicketId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UpdateTicketResponse.prototype.setTicketId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetTicketRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetTicketRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetTicketRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetTicketRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetTicketRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketSourceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      ticketTypeId: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketRequest}
 */
proto.com.hamropatro.sociallayer.io.GetTicketRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetTicketRequest;
  return proto.com.hamropatro.sociallayer.io.GetTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketRequest}
 */
proto.com.hamropatro.sociallayer.io.GetTicketRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketSourceId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketTypeId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetTicketRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetTicketRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketSourceId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getTicketTypeId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * optional string ticket_source_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketRequest.prototype.getTicketSourceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketRequest.prototype.setTicketSourceId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ticket_type_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketRequest.prototype.getTicketTypeId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketRequest.prototype.setTicketTypeId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetTicketResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetTicketResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetTicketResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetTicketResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetTicketResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetTicketResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      model: (f = msg.getModel()) && frontend$ticket$common$types_pb.TicketModel.toObject(includeInstance, f),
      availableTickets: jspb.Message.getFieldWithDefault(msg, 2, 0),
      soldTickets: jspb.Message.getFieldWithDefault(msg, 3, 0),
      alreadyBought: jspb.Message.getFieldWithDefault(msg, 4, false),
      boughtOn: jspb.Message.getFieldWithDefault(msg, 5, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketResponse}
 */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetTicketResponse;
  return proto.com.hamropatro.sociallayer.io.GetTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketResponse}
 */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new frontend$ticket$common$types_pb.TicketModel;
        reader.readMessage(value, frontend$ticket$common$types_pb.TicketModel.deserializeBinaryFromReader);
        msg.setModel(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setAvailableTickets(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setSoldTickets(value);
        break;
      case 4:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAlreadyBought(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setBoughtOn(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        frontend$ticket$common$types_pb.TicketModel.serializeBinaryToWriter
    );
  }
  f = message.getAvailableTickets();
  if (f !== 0) {
    writer.writeInt64(
        2,
        f
    );
  }
  f = message.getSoldTickets();
  if (f !== 0) {
    writer.writeInt64(
        3,
        f
    );
  }
  f = message.getAlreadyBought();
  if (f) {
    writer.writeBool(
        4,
        f
    );
  }
  f = message.getBoughtOn();
  if (f !== 0) {
    writer.writeInt64(
        5,
        f
    );
  }
};


/**
 * optional TicketModel model = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.TicketModel}
 */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.getModel = function () {
  return /** @type{?proto.com.hamropatro.sociallayer.io.TicketModel} */ (
      jspb.Message.getWrapperField(this, frontend$ticket$common$types_pb.TicketModel, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.TicketModel|undefined} value */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.setModel = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.clearModel = function () {
  this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.hasModel = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 available_tickets = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.getAvailableTickets = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.setAvailableTickets = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 sold_tickets = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.getSoldTickets = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.setSoldTickets = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool already_bought = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.getAlreadyBought = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.setAlreadyBought = function (value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 bought_on = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.getBoughtOn = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetTicketResponse.prototype.setBoughtOn = function (value) {
  jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      cursor: jspb.Message.getFieldWithDefault(msg, 2, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest}
 */
proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest;
  return proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest}
 */
proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCursor(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        3,
        f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.prototype.getEventId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.prototype.setEventId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.prototype.getCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.prototype.setCursor = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetTicketsForEventRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketList: jspb.Message.toObjectList(msg.getTicketList(),
          frontend$ticket$common$types_pb.TicketModel.toObject, includeInstance),
      eventId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      nextPageCursor: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse}
 */
proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse;
  return proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse}
 */
proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new frontend$ticket$common$types_pb.TicketModel;
        reader.readMessage(value, frontend$ticket$common$types_pb.TicketModel.deserializeBinaryFromReader);
        msg.addTicket(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextPageCursor(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        frontend$ticket$common$types_pb.TicketModel.serializeBinaryToWriter
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getNextPageCursor();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
};


/**
 * repeated TicketModel ticket = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.TicketModel>}
 */
proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.prototype.getTicketList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.TicketModel>} */ (
      jspb.Message.getRepeatedWrapperField(this, frontend$ticket$common$types_pb.TicketModel, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.TicketModel>} value */
proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.prototype.setTicketList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.TicketModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.TicketModel}
 */
proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.prototype.addTicket = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.TicketModel, opt_index);
};


proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.prototype.clearTicketList = function () {
  this.setTicketList([]);
};


/**
 * optional string event_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.prototype.getEventId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.prototype.setEventId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string next_page_cursor = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.prototype.getNextPageCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketForEventResponse.prototype.setNextPageCursor = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetUserTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetUserTicketRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetUserTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      cursor: jspb.Message.getFieldWithDefault(msg, 1, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserTicketRequest}
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetUserTicketRequest;
  return proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserTicketRequest}
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCursor(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        2,
        f
    );
  }
};


/**
 * optional string cursor = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.prototype.getCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.prototype.setCursor = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetUserTicketRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetUserTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetUserTicketResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetUserTicketResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketsList: jspb.Message.toObjectList(msg.getTicketsList(),
          frontend$ticket$common$types_pb.UserTicket.toObject, includeInstance),
      nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserTicketResponse}
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetUserTicketResponse;
  return proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetUserTicketResponse}
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new frontend$ticket$common$types_pb.UserTicket;
        reader.readMessage(value, frontend$ticket$common$types_pb.UserTicket.deserializeBinaryFromReader);
        msg.addTickets(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextPageCursor(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        frontend$ticket$common$types_pb.UserTicket.serializeBinaryToWriter
    );
  }
  f = message.getNextPageCursor();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * repeated UserTicket tickets = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>}
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.prototype.getTicketsList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>} */ (
      jspb.Message.getRepeatedWrapperField(this, frontend$ticket$common$types_pb.UserTicket, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>} value */
proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.prototype.setTicketsList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicket}
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.prototype.addTickets = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.UserTicket, opt_index);
};


proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.prototype.clearTicketsList = function () {
  this.setTicketsList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.prototype.getNextPageCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetUserTicketResponse.prototype.setNextPageCursor = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.displayName = 'proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketTypeIdList: jspb.Message.getRepeatedField(msg, 1),
      cursor: jspb.Message.getFieldWithDefault(msg, 2, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest;
  return proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addTicketTypeId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCursor(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketTypeIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
        1,
        f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        3,
        f
    );
  }
};


/**
 * repeated string ticket_type_id = 1;
 * @return {!Array<string>}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.prototype.getTicketTypeIdList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.prototype.setTicketTypeIdList = function (value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.prototype.addTicketTypeId = function (value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.prototype.clearTicketTypeIdList = function () {
  this.setTicketTypeIdList([]);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.prototype.getCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.prototype.setCursor = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.displayName = 'proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketTypeIdList: jspb.Message.getRepeatedField(msg, 1),
      cursor: jspb.Message.getFieldWithDefault(msg, 2, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
      purchaseTime: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest;
  return proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addTicketTypeId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCursor(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setPurchaseTime(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketTypeIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
        1,
        f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        3,
        f
    );
  }
  f = message.getPurchaseTime();
  if (f !== 0) {
    writer.writeInt64(
        4,
        f
    );
  }
};


/**
 * repeated string ticket_type_id = 1;
 * @return {!Array<string>}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.prototype.getTicketTypeIdList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.prototype.setTicketTypeIdList = function (value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.prototype.addTicketTypeId = function (value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.prototype.clearTicketTypeIdList = function () {
  this.setTicketTypeIdList([]);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.prototype.getCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.prototype.setCursor = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 purchase_time = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.prototype.getPurchaseTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionRequest.prototype.setPurchaseTime = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.displayName = 'proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      userTicketList: jspb.Message.toObjectList(msg.getUserTicketList(),
          frontend$ticket$common$types_pb.UserTicket.toObject, includeInstance),
      nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, ""),
      buyerInfoMap: (f = msg.getBuyerInfoMap()) ? f.toObject(includeInstance, undefined) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse;
  return proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new frontend$ticket$common$types_pb.UserTicket;
        reader.readMessage(value, frontend$ticket$common$types_pb.UserTicket.deserializeBinaryFromReader);
        msg.addUserTicket(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextPageCursor(value);
        break;
      case 3:
        var value = msg.getBuyerInfoMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserTicketList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        frontend$ticket$common$types_pb.UserTicket.serializeBinaryToWriter
    );
  }
  f = message.getNextPageCursor();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getBuyerInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * repeated UserTicket user_ticket = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.prototype.getUserTicketList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>} */ (
      jspb.Message.getRepeatedWrapperField(this, frontend$ticket$common$types_pb.UserTicket, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>} value */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.prototype.setUserTicketList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicket}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.prototype.addUserTicket = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.UserTicket, opt_index);
};


proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.prototype.clearUserTicketList = function () {
  this.setUserTicketList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.prototype.getNextPageCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.prototype.setNextPageCursor = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, string> buyer_info = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.prototype.getBuyerInfoMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
          null));
};


proto.com.hamropatro.sociallayer.io.UserTicketsForTransactionResponse.prototype.clearBuyerInfoMap = function () {
  this.getBuyerInfoMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.displayName = 'proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      userTicketList: jspb.Message.toObjectList(msg.getUserTicketList(),
          frontend$ticket$common$types_pb.UserTicket.toObject, includeInstance),
      nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, ""),
      buyerInfoMap: (f = msg.getBuyerInfoMap()) ? f.toObject(includeInstance, undefined) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse;
  return proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new frontend$ticket$common$types_pb.UserTicket;
        reader.readMessage(value, frontend$ticket$common$types_pb.UserTicket.deserializeBinaryFromReader);
        msg.addUserTicket(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextPageCursor(value);
        break;
      case 3:
        var value = msg.getBuyerInfoMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserTicketList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        frontend$ticket$common$types_pb.UserTicket.serializeBinaryToWriter
    );
  }
  f = message.getNextPageCursor();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getBuyerInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * repeated UserTicket user_ticket = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.prototype.getUserTicketList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>} */ (
      jspb.Message.getRepeatedWrapperField(this, frontend$ticket$common$types_pb.UserTicket, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>} value */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.prototype.setUserTicketList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicket}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.prototype.addUserTicket = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.UserTicket, opt_index);
};


proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.prototype.clearUserTicketList = function () {
  this.setUserTicketList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.prototype.getNextPageCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.prototype.setNextPageCursor = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, string> buyer_info = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.prototype.getBuyerInfoMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
          null));
};


proto.com.hamropatro.sociallayer.io.UserTicketsForTicketTypeResponse.prototype.clearBuyerInfoMap = function () {
  this.getBuyerInfoMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.RemoveTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.displayName = 'proto.com.hamropatro.sociallayer.io.RemoveTicketRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.RemoveTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketSourceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      ticketId: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveTicketRequest}
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.RemoveTicketRequest;
  return proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveTicketRequest}
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketSourceId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketSourceId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getTicketId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * optional string ticket_source_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.prototype.getTicketSourceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.prototype.setTicketSourceId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ticket_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.prototype.getTicketId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveTicketRequest.prototype.setTicketId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.RemoveTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.displayName = 'proto.com.hamropatro.sociallayer.io.RemoveTicketResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.RemoveTicketResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      remove: jspb.Message.getFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveTicketResponse}
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.RemoveTicketResponse;
  return proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.RemoveTicketResponse}
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketId(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setRemove(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getRemove();
  if (f) {
    writer.writeBool(
        2,
        f
    );
  }
};


/**
 * optional string ticket_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.prototype.getTicketId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.prototype.setTicketId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool remove = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.prototype.getRemove = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.RemoveTicketResponse.prototype.setRemove = function (value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.displayName = 'proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketSourceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      ticketTypeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
      ticketIdList: jspb.Message.getRepeatedField(msg, 4),
      statusToUpdate: jspb.Message.getFieldWithDefault(msg, 5, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest}
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest;
  return proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest}
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketSourceId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketTypeId(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.addTicketId(value);
        break;
      case 5:
        var value = /** @type {!proto.com.hamropatro.sociallayer.io.TicketStatus} */ (reader.readEnum());
        msg.setStatusToUpdate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketSourceId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getTicketTypeId();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getTicketIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
        4,
        f
    );
  }
  f = message.getStatusToUpdate();
  if (f !== 0.0) {
    writer.writeEnum(
        5,
        f
    );
  }
};


/**
 * optional string ticket_source_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.getTicketSourceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.setTicketSourceId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.getUserId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.setUserId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ticket_type_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.getTicketTypeId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.setTicketTypeId = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string ticket_id = 4;
 * @return {!Array<string>}
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.getTicketIdList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<string>} value */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.setTicketIdList = function (value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.addTicketId = function (value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.clearTicketIdList = function () {
  this.setTicketIdList([]);
};


/**
 * optional TicketStatus status_to_update = 5;
 * @return {!proto.com.hamropatro.sociallayer.io.TicketStatus}
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.getStatusToUpdate = function () {
  return /** @type {!proto.com.hamropatro.sociallayer.io.TicketStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.TicketStatus} value */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateRequest.prototype.setStatusToUpdate = function (value) {
  jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.displayName = 'proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      updatedStatus: jspb.Message.getFieldWithDefault(msg, 1, 0),
      success: jspb.Message.getFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse}
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse;
  return proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse}
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.com.hamropatro.sociallayer.io.TicketStatus} */ (reader.readEnum());
        msg.setUpdatedStatus(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSuccess(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUpdatedStatus();
  if (f !== 0.0) {
    writer.writeEnum(
        1,
        f
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
        2,
        f
    );
  }
};


/**
 * optional TicketStatus updated_status = 1;
 * @return {!proto.com.hamropatro.sociallayer.io.TicketStatus}
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.prototype.getUpdatedStatus = function () {
  return /** @type {!proto.com.hamropatro.sociallayer.io.TicketStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.com.hamropatro.sociallayer.io.TicketStatus} value */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.prototype.setUpdatedStatus = function (value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool success = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.prototype.getSuccess = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.com.hamropatro.sociallayer.io.TicketStatusUpdateResponse.prototype.setSuccess = function (value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketSourceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      cursor: jspb.Message.getFieldWithDefault(msg, 2, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest;
  return proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketSourceId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCursor(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketSourceId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        3,
        f
    );
  }
};


/**
 * optional string ticket_source_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.prototype.getTicketSourceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.prototype.setTicketSourceId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.prototype.getCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.prototype.setCursor = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketPaymentList: jspb.Message.toObjectList(msg.getTicketPaymentList(),
          frontend$ticket$common$types_pb.TicketPaymentModel.toObject, includeInstance),
      nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse;
  return proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new frontend$ticket$common$types_pb.TicketPaymentModel;
        reader.readMessage(value, frontend$ticket$common$types_pb.TicketPaymentModel.deserializeBinaryFromReader);
        msg.addTicketPayment(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextPageCursor(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketPaymentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        frontend$ticket$common$types_pb.TicketPaymentModel.serializeBinaryToWriter
    );
  }
  f = message.getNextPageCursor();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * repeated TicketPaymentModel ticket_payment = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.TicketPaymentModel>}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.prototype.getTicketPaymentList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.TicketPaymentModel>} */ (
      jspb.Message.getRepeatedWrapperField(this, frontend$ticket$common$types_pb.TicketPaymentModel, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.TicketPaymentModel>} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.prototype.setTicketPaymentList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.TicketPaymentModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.TicketPaymentModel}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.prototype.addTicketPayment = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.TicketPaymentModel, opt_index);
};


proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.prototype.clearTicketPaymentList = function () {
  this.setTicketPaymentList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.prototype.getNextPageCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForEventResponse.prototype.setNextPageCursor = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketSourceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      ticketTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      cursor: jspb.Message.getFieldWithDefault(msg, 3, ""),
      pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest;
  return proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketSourceId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketTypeId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCursor(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPageSize(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketSourceId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getTicketTypeId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getCursor();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
        4,
        f
    );
  }
};


/**
 * optional string ticket_source_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.prototype.getTicketSourceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.prototype.setTicketSourceId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ticket_type_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.prototype.getTicketTypeId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.prototype.setTicketTypeId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cursor = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.prototype.getCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.prototype.setCursor = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.prototype.getPageSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeRequest.prototype.setPageSize = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketPaymentList: jspb.Message.toObjectList(msg.getTicketPaymentList(),
          frontend$ticket$common$types_pb.TicketPaymentModel.toObject, includeInstance),
      nextPageCursor: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse;
  return proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new frontend$ticket$common$types_pb.TicketPaymentModel;
        reader.readMessage(value, frontend$ticket$common$types_pb.TicketPaymentModel.deserializeBinaryFromReader);
        msg.addTicketPayment(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setNextPageCursor(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketPaymentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        1,
        f,
        frontend$ticket$common$types_pb.TicketPaymentModel.serializeBinaryToWriter
    );
  }
  f = message.getNextPageCursor();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * repeated TicketPaymentModel ticket_payment = 1;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.TicketPaymentModel>}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.prototype.getTicketPaymentList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.TicketPaymentModel>} */ (
      jspb.Message.getRepeatedWrapperField(this, frontend$ticket$common$types_pb.TicketPaymentModel, 1));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.TicketPaymentModel>} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.prototype.setTicketPaymentList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.TicketPaymentModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.TicketPaymentModel}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.prototype.addTicketPayment = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.hamropatro.sociallayer.io.TicketPaymentModel, opt_index);
};


proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.prototype.clearTicketPaymentList = function () {
  this.setTicketPaymentList([]);
};


/**
 * optional string next_page_cursor = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.prototype.getNextPageCursor = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentForTicketTypeResponse.prototype.setNextPageCursor = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketSourceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      ticketTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      ticketId: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest;
  return proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketSourceId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketTypeId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTicketId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketSourceId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getTicketTypeId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getTicketId();
  if (f.length > 0) {
    writer.writeString(
        3,
        f
    );
  }
};


/**
 * optional string ticket_source_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.prototype.getTicketSourceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.prototype.setTicketSourceId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ticket_type_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.prototype.getTicketTypeId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.prototype.setTicketTypeId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ticket_id = 3;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.prototype.getTicketId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentRequest.prototype.setTicketId = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      ticketPayment: (f = msg.getTicketPayment()) && frontend$ticket$common$types_pb.TicketPaymentModel.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse;
  return proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new frontend$ticket$common$types_pb.TicketPaymentModel;
        reader.readMessage(value, frontend$ticket$common$types_pb.TicketPaymentModel.deserializeBinaryFromReader);
        msg.setTicketPayment(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTicketPayment();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        frontend$ticket$common$types_pb.TicketPaymentModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional TicketPaymentModel ticket_payment = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.TicketPaymentModel}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.prototype.getTicketPayment = function () {
  return /** @type{?proto.com.hamropatro.sociallayer.io.TicketPaymentModel} */ (
      jspb.Message.getWrapperField(this, frontend$ticket$common$types_pb.TicketPaymentModel, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.TicketPaymentModel|undefined} value */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.prototype.setTicketPayment = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.prototype.clearTicketPayment = function () {
  this.setTicketPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.GetTicketPaymentResponse.prototype.hasTicketPayment = function () {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.TransactionDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.TransactionDetailRequest.displayName = 'proto.com.hamropatro.sociallayer.io.TransactionDetailRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.TransactionDetailRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.TransactionDetailRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.TransactionDetailRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.TransactionDetailRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      transactionId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.TransactionDetailRequest}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.TransactionDetailRequest;
  return proto.com.hamropatro.sociallayer.io.TransactionDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.TransactionDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.TransactionDetailRequest}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTransactionId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.TransactionDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.TransactionDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
};


/**
 * optional string transaction_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailRequest.prototype.getTransactionId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TransactionDetailRequest.prototype.setTransactionId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.TransactionDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.displayName = 'proto.com.hamropatro.sociallayer.io.TransactionDetailResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.TransactionDetailResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      transactionDetail: (f = msg.getTransactionDetail()) && frontend$ticket$common$types_pb.TransactionDetail.toObject(includeInstance, f),
      userTicketList: jspb.Message.toObjectList(msg.getUserTicketList(),
          frontend$ticket$common$types_pb.UserTicket.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.TransactionDetailResponse}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.TransactionDetailResponse;
  return proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.TransactionDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.TransactionDetailResponse}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new frontend$ticket$common$types_pb.TransactionDetail;
        reader.readMessage(value, frontend$ticket$common$types_pb.TransactionDetail.deserializeBinaryFromReader);
        msg.setTransactionDetail(value);
        break;
      case 2:
        var value = new frontend$ticket$common$types_pb.UserTicket;
        reader.readMessage(value, frontend$ticket$common$types_pb.UserTicket.deserializeBinaryFromReader);
        msg.addUserTicket(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.TransactionDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTransactionDetail();
  if (f != null) {
    writer.writeMessage(
        1,
        f,
        frontend$ticket$common$types_pb.TransactionDetail.serializeBinaryToWriter
    );
  }
  f = message.getUserTicketList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        2,
        f,
        frontend$ticket$common$types_pb.UserTicket.serializeBinaryToWriter
    );
  }
};


/**
 * optional TransactionDetail transaction_detail = 1;
 * @return {?proto.com.hamropatro.sociallayer.io.TransactionDetail}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.prototype.getTransactionDetail = function () {
  return /** @type{?proto.com.hamropatro.sociallayer.io.TransactionDetail} */ (
      jspb.Message.getWrapperField(this, frontend$ticket$common$types_pb.TransactionDetail, 1));
};


/** @param {?proto.com.hamropatro.sociallayer.io.TransactionDetail|undefined} value */
proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.prototype.setTransactionDetail = function (value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.prototype.clearTransactionDetail = function () {
  this.setTransactionDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.prototype.hasTransactionDetail = function () {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated UserTicket user_ticket = 2;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.prototype.getUserTicketList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>} */ (
      jspb.Message.getRepeatedWrapperField(this, frontend$ticket$common$types_pb.UserTicket, 2));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.UserTicket>} value */
proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.prototype.setUserTicketList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UserTicket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.UserTicket}
 */
proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.prototype.addUserTicket = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.hamropatro.sociallayer.io.UserTicket, opt_index);
};


proto.com.hamropatro.sociallayer.io.TransactionDetailResponse.prototype.clearUserTicketList = function () {
  this.setUserTicketList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest}
 */
proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest;
  return proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest}
 */
proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetGroupSalesReportRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse.toObject = function (includeInstance, msg) {
    var f, obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse}
 */
proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse;
  return proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse}
 */
proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetGroupSalesReportResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.displayName = 'proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      eventId: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest;
  return proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.prototype.getEventId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportRequest.prototype.setEventId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.repeatedFields_, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.displayName = 'proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.toObject = function (includeInstance, msg) {
    var f, obj = {
      groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      eventId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      grossSalesAmount: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
      groupName: jspb.Message.getFieldWithDefault(msg, 5, ""),
      eventName: jspb.Message.getFieldWithDefault(msg, 6, ""),
      eventStartingTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
      venue: jspb.Message.getFieldWithDefault(msg, 8, ""),
      typeOverviewList: jspb.Message.toObjectList(msg.getTypeOverviewList(),
          proto.com.hamropatro.sociallayer.io.TicketTypeOverview.toObject, includeInstance),
      currency: jspb.Message.getFieldWithDefault(msg, 10, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse;
  return proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventId(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setGrossSalesAmount(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setGroupName(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventName(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setEventStartingTime(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setVenue(value);
        break;
      case 9:
        var value = new proto.com.hamropatro.sociallayer.io.TicketTypeOverview;
        reader.readMessage(value, proto.com.hamropatro.sociallayer.io.TicketTypeOverview.deserializeBinaryFromReader);
        msg.addTypeOverview(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setCurrency(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getGrossSalesAmount();
  if (f !== 0.0) {
    writer.writeDouble(
        3,
        f
    );
  }
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
        5,
        f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
        6,
        f
    );
  }
  f = message.getEventStartingTime();
  if (f !== 0) {
    writer.writeInt64(
        7,
        f
    );
  }
  f = message.getVenue();
  if (f.length > 0) {
    writer.writeString(
        8,
        f
    );
  }
  f = message.getTypeOverviewList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
        9,
        f,
        proto.com.hamropatro.sociallayer.io.TicketTypeOverview.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
        10,
        f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.getGroupId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.setGroupId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event_id = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.getEventId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.setEventId = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double gross_sales_amount = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.getGrossSalesAmount = function () {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.setGrossSalesAmount = function (value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string group_name = 5;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.getGroupName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.setGroupName = function (value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string event_name = 6;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.getEventName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.setEventName = function (value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 event_starting_time = 7;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.getEventStartingTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.setEventStartingTime = function (value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string venue = 8;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.getVenue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.setVenue = function (value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated TicketTypeOverview type_overview = 9;
 * @return {!Array<!proto.com.hamropatro.sociallayer.io.TicketTypeOverview>}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.getTypeOverviewList = function () {
  return /** @type{!Array<!proto.com.hamropatro.sociallayer.io.TicketTypeOverview>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.com.hamropatro.sociallayer.io.TicketTypeOverview, 9));
};


/** @param {!Array<!proto.com.hamropatro.sociallayer.io.TicketTypeOverview>} value */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.setTypeOverviewList = function (value) {
  jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.com.hamropatro.sociallayer.io.TicketTypeOverview=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.hamropatro.sociallayer.io.TicketTypeOverview}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.addTypeOverview = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.com.hamropatro.sociallayer.io.TicketTypeOverview, opt_index);
};


proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.clearTypeOverviewList = function () {
  this.setTypeOverviewList([]);
};


/**
 * optional string currency = 10;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.getCurrency = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.GetEventSalesReportResponse.prototype.setCurrency = function (value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.hamropatro.sociallayer.io.TicketTypeOverview, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.hamropatro.sociallayer.io.TicketTypeOverview.displayName = 'proto.com.hamropatro.sociallayer.io.TicketTypeOverview';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.toObject = function (opt_includeInstance) {
    return proto.com.hamropatro.sociallayer.io.TicketTypeOverview.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.hamropatro.sociallayer.io.TicketTypeOverview} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.hamropatro.sociallayer.io.TicketTypeOverview.toObject = function (includeInstance, msg) {
    var f, obj = {
      typeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      totalTickets: jspb.Message.getFieldWithDefault(msg, 3, 0),
      ticketSold: jspb.Message.getFieldWithDefault(msg, 4, 0),
      ticketAvailable: jspb.Message.getFieldWithDefault(msg, 5, 0),
      totalSalesAmount: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
      currentPrice: +jspb.Message.getFieldWithDefault(msg, 9, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketTypeOverview}
 */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.hamropatro.sociallayer.io.TicketTypeOverview;
  return proto.com.hamropatro.sociallayer.io.TicketTypeOverview.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketTypeOverview} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.hamropatro.sociallayer.io.TicketTypeOverview}
 */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTypeId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setTotalTickets(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setTicketSold(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setTicketAvailable(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setTotalSalesAmount(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCurrentPrice(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.hamropatro.sociallayer.io.TicketTypeOverview.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.hamropatro.sociallayer.io.TicketTypeOverview} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTypeId();
  if (f.length > 0) {
    writer.writeString(
        1,
        f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
        2,
        f
    );
  }
  f = message.getTotalTickets();
  if (f !== 0) {
    writer.writeInt64(
        3,
        f
    );
  }
  f = message.getTicketSold();
  if (f !== 0) {
    writer.writeInt64(
        4,
        f
    );
  }
  f = message.getTicketAvailable();
  if (f !== 0) {
    writer.writeInt64(
        5,
        f
    );
  }
  f = message.getTotalSalesAmount();
  if (f !== 0.0) {
    writer.writeDouble(
        6,
        f
    );
  }
  f = message.getCurrentPrice();
  if (f !== 0.0) {
    writer.writeDouble(
        9,
        f
    );
  }
};


/**
 * optional string type_id = 1;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.getTypeId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.setTypeId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.setName = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 total_tickets = 3;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.getTotalTickets = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.setTotalTickets = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 ticket_sold = 4;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.getTicketSold = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.setTicketSold = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 ticket_available = 5;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.getTicketAvailable = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.setTicketAvailable = function (value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double total_sales_amount = 6;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.getTotalSalesAmount = function () {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.setTotalSalesAmount = function (value) {
  jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double current_price = 9;
 * @return {number}
 */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.getCurrentPrice = function () {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 9, 0.0));
};


/** @param {number} value */
proto.com.hamropatro.sociallayer.io.TicketTypeOverview.prototype.setCurrentPrice = function (value) {
  jspb.Message.setProto3FloatField(this, 9, value);
};


goog.object.extend(exports, proto.com.hamropatro.sociallayer.io);
