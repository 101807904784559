import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import IconButton from "@material-ui/core/IconButton";
import {MoreVert as MoreVertIcon} from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {DeleteCommentReplyRequest} from "../generated/frontend-reply-service_pb";
import {isAdmin, userInfo} from "../configurations/global-config";
import UserConfirmationDialog from "./UserConfirmationDialog";
import SnackBar from "./SnackBar/SnackBar";
import usedString from "../utils/mapper/enumToStringMapper";
import {deleteCommentReply} from "../grpcRequests/reply-request";

class UserReplyMenus extends Component {
    state = {
        anchorEl: null,
        openConfirmation: false,

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null

    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut,
        });
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget, openConfirmation: false});
    };

    handleCloseMenu = () => {
        this.setState({anchorEl: null});
    };
    openConfirmation = () => {
        this.setState({openConfirmation: true, anchorEl: null});
    };

    getUserResponseFromChild = (clickedButton, key) => {
        this.setState({openConfirmation: false});
        if (clickedButton === "YES") {
            this.deleteReply(key);
        }
    };

    sendResponseToUserReply = (actionName, isActionCompleted, commentId) => {
        this.props.sendUserResponse(actionName, isActionCompleted, commentId);
    };

    deleteReply = (key) => {
        this.setState({snackOpen: false});
        let self = this;
        this.snackbar(true, 'Deleting Reply', 'info', 2000);
        const req = new DeleteCommentReplyRequest();
        req.setPostUri(this.props.postID);
        req.setCommentId(this.props.commentID);
        req.setReplyId(key);
        req.setStatus(DeleteCommentReplyRequest.Status.APPROVED);
        deleteCommentReply(req).then(function (response) {
            self.sendResponseToUserReply("delete", true, self.props.commentID)
        }).catch(function (error) {
            self.sendResponseToUserReply("delete", false, self.props.commentID)
        })
    };

    render() {
        const {classes, replyText, postID, ownerID, commentID, userRole, replyID} = this.props;
        const {anchorEl, openConfirmation} = this.state;
        const open = Boolean(anchorEl);
        return (
            <span>
                <IconButton
                    size="small"
                    aria-label="Account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                    className={classes.moreVertPost}
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="menu-id"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    className={classes.menuThreeVert}
                    onClose={this.handleCloseMenu}
                >
                     {ownerID === userInfo().user_id || isAdmin() || userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT ? (
                         <div>
                             <MenuItem onClick={this.openConfirmation}>Delete</MenuItem>
                             {/*<MenuItem onClick={this.handleCloseMenu}>Edit</MenuItem>*/}
                         </div>
                     ) : (<div>
                         <MenuItem>Save</MenuItem>
                         <MenuItem>Report</MenuItem>
                     </div>)}
                </Menu>
                {openConfirmation ? (
                    <UserConfirmationDialog
                        openDialog={openConfirmation} dialogHeader="Delete Reply"
                        postId={replyID}
                        dialogContent={"Are you sure want to delete your reply ?"} button1="NO" button2="YES"
                        toDeleteContent={<div className={classes.sharableLinkWrapper}>
                            <span className={classes.sharableText}> {replyText}</span>
                        </div>}
                        sendUserResponse={this.getUserResponseFromChild}
                    />
                ) : (<div></div>)}
                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
            </span>
        )
    }
}

UserReplyMenus.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserReplyMenus);