import React, {Component} from "react";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core";
import Icon from '@material-ui/core/Icon';

import {UploadImageRequest} from "../../generated/frontend-post-service_pb";
import {getVerifiedUrlForImage} from "../../grpcRequests/posts-request";

import styles from "./styles";
import './image.css';
import globalConfig from "../../configurations/configVariables";

class Uploader extends Component {
    state = {
        file: '',
        imagePreviewUrl: '',
        uploadingURL: null
    };

    sendImageUploading = (url, file) => {
        this.props.sendImageURLObtainStatus(url, file, this.props.index);
    };

    handleImageChange = (e) => {
        let self = this;
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            if (self.state.uploadingURL) {
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result
                });
                self.sendImageUploading(self.state.uploadingURL, this.state.file)
            }
        };
        reader.readAsDataURL(file);
    };

    lunchFilePicker = () => {
        this.getUploadingUrl();
        this.refs.file.click();
    };

    getUploadingUrl = () => {
        let self = this;
        const req = new UploadImageRequest();
        req.setLocation(globalConfig.uploadingBucket);
        req.setType(this.props.type);
        getVerifiedUrlForImage(req).then(function (response) {
            self.setState({uploadingURL: response.getUploadingUrl()})
        }).then(function (error) {
        })
    };


    render() {
        const {classes, paddingTop, imageURL, width} = this.props;
        const {imagePreviewUrl} = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (
                <div title='Change image' className="image-upload-container"
                     style={{paddingTop: `${paddingTop}`, width: `${width}`}} onClick={this.lunchFilePicker}>
                    <img src={imagePreviewUrl} alt="Avatar" className="image-upload-imageShown"/>
                    <div className="image-upload-middle">
                        <div className="image-upload-text">Change Image</div>
                    </div>
                </div>
            );
        } else if (imageURL) {
            $imagePreview = (
                <div className="image-upload-container" style={{paddingTop: `${paddingTop}`, width: `${width}`}}
                     onClick={this.lunchFilePicker}>
                    <img src={imageURL} alt="Avatar" className="image-upload-imageShown"/>
                    <div className="image-upload-middle">
                        <div className="image-upload-text">Change Image</div>
                    </div>
                </div>
            );
        } else {
            $imagePreview = (
                <div title='Upload image' className={classes.imageWrapperBefore} style={{width: `${width}`}}
                     onClick={this.lunchFilePicker}>
                    <Icon className={classes.icon} style={{fontSize: 50, color: '#3a3737'}}>
                        add_photo_alternate
                    </Icon>
                </div>);
        }
        return (
            <div className={classes.imageBox}>
                <div>
                    <input type="file"
                           hidden
                           ref="file"
                           onChange={this.handleImageChange}
                           accept="image/jpeg,image/jpg,image/png"/>
                    <div>
                        <div>
                            {$imagePreview}
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

Uploader.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Uploader);