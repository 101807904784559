import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {FileCopyOutlined as CopyFile} from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import {Typography, withStyles} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import styles from './styles';
import {CopyToClipboard} from "react-copy-to-clipboard";


import {
    CreateDirectJoinInvitationLinkRequest,
    CreateInvitationLinkRequest,
    RemoveDirectJoinInvitationLinkRequest
} from "../generated/frontend-community-group-service_pb";

import {getDirectGeneratedLink, getSharableLink, removeDirectLink} from "../grpcRequests/groups-request";
import {eventAnalyst} from "../configurations/config";
import usedString from "../utils/mapper/enumToStringMapper";
import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton} from "react-share";

class InviteDialog extends Component {
    state = {
        openDialog: false,
        sharableLink: null,
        directLink: true,
        copiedDirectLink: false,
        copied: false,
        groupId: this.props.groupId,
        enableDirectLink: false,
        shareLink: this.props.shareLink
    };

    getGeneratedLink = () => {
        let self = this;
        self.setState({
            sharableLink: null,
            copied: false
        });
        const req = new CreateInvitationLinkRequest();
        req.setGroupId(this.state.groupId);
        getSharableLink(req).then(function (response) {
            eventAnalyst('join_link', self.state.groupId + '_join_link_generated', self.state.groupId + ' join link generated', self.state.groupId + '-join-link-generated');
            const sharableLink = response.getLinkString();
            self.setState({
                sharableLink
            });
        }).catch(function (error) {
            eventAnalyst('join_link', self.state.groupId + '_join_link_generated', self.state.groupId + ' join link fail', self.state.groupId + '-join-link-fail');
        })
    };

    generateLink = () => {
        let self = this;
        self.setState({
            shareLink: null,
            copiedDirectLink: false,
            generatingLink: true
        });
        const req = new CreateDirectJoinInvitationLinkRequest();
        req.setGroupId(this.state.groupId);
        getDirectGeneratedLink(req).then(function (response) {
            eventAnalyst('directJoin_link', self.state.groupId + '_direct_link_create', self.state.groupId + ' link create success', self.state.groupId + '-link-create-success');
            const shareLink = response.getDirectJoinLink();
            self.setState({
                shareLink, generatingLink: false
            });
        }).catch(function (error) {
            eventAnalyst('directJoin_link', self.state.groupId + '_direct_link_create', self.state.groupId + ' link create fail', self.state.groupId + '-link-create-fail');
            self.setState({
                generatingLink: false
            });
        })
    };

    deleteLink = () => {
        let self = this;
        const req = new RemoveDirectJoinInvitationLinkRequest();
        req.setGroupId(this.state.groupId);
        removeDirectLink(req).then(function (response) {
            eventAnalyst('directJoin_link', self.state.groupId + '_direct_link_remove', self.state.groupId + ' link remove success', self.state.groupId + '-link-remove-success');
        }).catch(function (error) {

        })
    };

    openInviteDialog = () => {
        this.setState({
            openDialog: true,
            sharableLink: window.location.origin + '/c/' + this.props.groupId,
            copiedDirectLink: false,
            copied: false
        });
        // this.getGeneratedLink();
    };

    closeInviteDialog = () => {
        this.setState({openDialog: false});
    };

    changeType = (event) => {
        const linkEnableType = event.target.checked;
        if (linkEnableType) {
            this.generateLink();
        } else {
            this.setState({shareLink: ''});
            this.deleteLink();
        }
    };

    render() {
        const {fullScreen, classes, userRole} = this.props;
        const {sharableLink, copied, directLink, copiedDirectLink, enableDirectLink, shareLink, generatingLink} = this.state;

        const shareUrl = window.location.origin + '/c/' + this.props.groupId;
        const title = 'You are invited to join community.';
        return (
            <div>
                <Fab
                    variant="extended"
                    size="small"
                    aria-label="Add"
                    className={classes.inviteButton}
                    onClick={this.openInviteDialog}
                >
                    Invite <AddIcon/>
                </Fab>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.openDialog}
                    onClose={this.closeInviteDialog}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"INVITE MEMBERS"}</DialogTitle>
                    <Divider/>
                    <DialogContent>
                        <div>
                            <DialogContentText>
                                You can invite members to the community by sending the generated links directly to your
                                friends.
                            </DialogContentText>
                            <div className={classes.directHead}>Invite Members</div>
                            <div className={classes.formSwitch}>
                                <div style={{textAlign: 'end'}}>
                                    <div style={{display: "inline-flex"}}>
                                        <FacebookShareButton
                                            url={shareUrl}
                                            quote={title}
                                            className="socialShareIcons">
                                            <FacebookIcon
                                                size={25}
                                                round/>
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="socialShareIcons">
                                            <TwitterIcon
                                                size={25}
                                                round/>
                                        </TwitterShareButton>
                                    </div>
                                </div>
                            </div>
                            <Typography
                                className={classes.directText}
                                variant="subtitle1"
                            > <span>Share Url with friends and request them to join the community.</span>
                            </Typography>
                            {sharableLink ? (
                                <Typography

                                >
                                    <div className={classes.sharableLinkWrapper}>
                                        <span className={classes.sharableText}> {sharableLink}</span>
                                        <CopyToClipboard text={sharableLink}
                                                         onCopy={() => this.setState({
                                                             copied: true,
                                                             copiedDirectLink: false
                                                         })}>
                                    <span title="copy invitation link" className={classes.rightIcon}><CopyFile
                                        color="primary"
                                        onClick={() => eventAnalyst('join_link', this.state.groupId + '_join_link_copy', this.state.groupId + ' join link copy', this.state.groupId + '-join-link-copy')}/></span>
                                        </CopyToClipboard>
                                    </div>
                                    {copied ? (
                                        <DialogContentText className={classes.contentInvite}>
                                            Invite member Link is now copied to clipboard.
                                        </DialogContentText>
                                    ) : (<DialogContentText
                                        className={classes.contentInvite}>&nbsp;</DialogContentText>)}
                                </Typography>
                            ) : (<div>
                                <CircularProgress className={classes.progress} size={22}/>
                            </div>)}
                            <Divider/>
                            <br/>
                            {userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT ? (
                                <div>
                                    <div className={classes.directHead}>Direct Join Members</div>
                                    <div className={classes.formSwitch}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch size="small" checked={shareLink} color="primary"
                                                                 onChange={this.changeType}/>}
                                                label="Enable Direct Join"
                                            />
                                        </FormGroup>
                                    </div>
                                    <Typography
                                        className={classes.directText}
                                        variant="subtitle1"
                                    > <span> You can directly join members to the community by sharing the direct links your friends.</span>
                                    </Typography>
                                    {generatingLink ? (
                                        <div><CircularProgress className={classes.progress} size={22}/></div>) : (
                                        <div></div>)}
                                    {shareLink ? (
                                        <div>
                                            <div className={classes.sharableLinkWrapper}>
                                                <span className={classes.sharableText}> {shareLink}</span>
                                                <CopyToClipboard text={shareLink}
                                                                 onCopy={() => this.setState({
                                                                     copiedDirectLink: true,
                                                                     copied: false
                                                                 })}>
                                    <span title="copy direct join link" className={classes.rightIcon}>
                                        <CopyFile color="primary"
                                                  onClick={() => eventAnalyst('directJoin_link', this.state.groupId + '_direct_link_copy', this.state.groupId + ' direct link copy', this.state.groupId + '-direct-link-copy')}/>
                                    </span>
                                                </CopyToClipboard>
                                            </div>
                                            {copiedDirectLink ? (
                                                <DialogContentText className={classes.contentInvite}>
                                                    Direct Join Link is now copied to clipboard.
                                                </DialogContentText>
                                            ) : (<DialogContentText
                                                className={classes.contentInvite}>&nbsp;</DialogContentText>)}
                                        </div>) : (<div className={classes.directText}>&nbsp;</div>)}
                                </div>
                            ) : <div></div>}
                        </div>
                    </DialogContent>
                    <DialogActions></DialogActions>
                </Dialog>
            </div>
        );
    }
}

InviteDialog.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InviteDialog);

