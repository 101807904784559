import {EverestDBServiceServer as srv} from "../grpcClients/service-clients-factory";
import {MetaHeaders} from "../configurations/config";


export function incrementCount(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.incrementCounter(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getPageCount(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getEverestCounter(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function writeToEverestDB(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.createEverestObject(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}

export function getFromEverestDB(userReq) {
    return new Promise(function (resolve, reject) {
        MetaHeaders().then(function (metaDataResponse) {
            srv.getEverestObject(userReq, metaDataResponse, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        })
    });
}