import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styles
import styles from './styles';
import {checkIfLogin} from "../../../configurations/global-config";

class GroupsEvents extends Component {

    componentDidMount() {
        checkIfLogin(this.props.location.pathname);
    }

    render() {
        const {classes} = this.props;
        return (
            <DashboardLayout title="Community Events">
                <div className={classes.root}>

                </div>
            </DashboardLayout>
        );
    }
}

GroupsEvents.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GroupsEvents);
