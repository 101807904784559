import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";
import {Link} from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import styles from "../styles";
import {
    GetUpComingEventsRequest,
    GoingStatus,
    UpdateGoingStatusRequest
} from "../../../generated/frontend-community-event-service_pb";
import {getUpComingEvents, updateUserResponseToEvent} from "../../../grpcRequests/events-request";
import usedString, {
    getEventTypeStringInSentence,
    getUserResponseString
} from "../../../utils/mapper/enumToStringMapper";
import {formatDate} from "../../../utils/converter/dateConverter";
import Icon from "@material-ui/core/Icon";
import {eventAnalyst} from "../../../configurations/config";
import IconButton from "@material-ui/core/IconButton";
import {CalendarTodayRounded as CalendarIcon} from "@material-ui/icons";
import {isLogin} from "../../../configurations/global-config";
import EventStickyInfo from "../../Groups/component/StickyInfo/EventStickyInfo";

const userEventStat = [];

class EventCard extends Component {
    state = {
        groupId: this.props.groupID,
        usrGrpRole: this.props.role,
        groupEvents: [],
        changeStatus: true,
        isLoadingGroupEvents: true,
        errGroupMessage: '',
        isLoggedIn:false
    };

    componentDidMount() {
        this.fetchUpcomingEvents();
        if(isLogin()){
            this.setState({isLoggedIn:true})
        }
    }

    fetchUpcomingEvents = () => {
        let self = this;
        const req = new GetUpComingEventsRequest();
        req.setGroupId(this.state.groupId);
        req.setYear(new Date().getFullYear());
        req.setCurrentMonth(new Date().getMonth() + 1);
        req.setCurrentDay(new Date().getDate());
        req.setCursor();
        req.setPageSize(20);
        getUpComingEvents(req, 3).then(function (response) {
            eventAnalyst('community_detail_event', 'community_detail_event_fetch', self.state.groupId + '-community event detail fetch success', 'community-event-detail-fetch-success');
            const groupEvents = response.getEventDetailList();
            groupEvents.forEach(function (singleEvent) {
                userEventStat[singleEvent.getEvent().getEventId()] = getUserResponseString(singleEvent.getUserAttendingStatus());
            });
            const isLoadingGroupEvents = false;
            self.setState({
                isLoadingGroupEvents,
                groupEvents
            });
        }).catch(function (error) {
            eventAnalyst('community_detail_event', 'community_detail_event_fetch', self.state.groupId + '-community event detail fetch fail', 'community-event-detail-fetch-fail');
            const isLoadingGroupEvents = false;
            const errGroupMessage = error.message;
            self.setState({
                isLoadingGroupEvents,
                errGroupMessage
            });
        })
    };

    userResponseToEvent = (status, event) => {
        eventAnalyst('community_detail_event_action', status, status + '-users', 'event-users');
        let self = this;
        userEventStat[event.getEvent().getEventId()] = status;
        this.setState({
            changeStatus: true
        });
        var userSts;
        if (status === usedString.GOING) {
            userSts = GoingStatus.GOING
        } else if (status === usedString.INTERESTED) {
            userSts = GoingStatus.INTERESTED
        } else if (status === usedString.DECLINED) {
            userSts = GoingStatus.DECLINED
        }
        const req = new UpdateGoingStatusRequest();
        req.setGroupId(this.state.groupId);
        req.setEventId(event.getEvent().getEventId());
        req.setYear(event.getEvent().getYear());
        req.setMonth(event.getEvent().getMonth());
        req.setDay(event.getEvent().getDay());
        req.setStatus(userSts);
        updateUserResponseToEvent(req).then(function (response) {
            self.fetchUpcomingEvents();
        }).catch(function (error) {
        })
    };

    render() {
        const {classes} = this.props;
        const {isLoadingGroupEvents, groupEvents, errGroupMessage, groupId, changeStatus,isLoggedIn} = this.state;
        const rootGroupsURl = '/c/' + groupId;
        return (
            <div>
                {!isLoadingGroupEvents ? (
                    <div>
                        {groupEvents.length > 0 ? (
                                <div className={classes.eventsCard}>
                                    <div className={classes.headersWrapper}>
                                        <div>
                                            <div>
                                                <Typography
                                                    variant="h6"
                                                    style={{fontWeight: 900, padding: '15px 0 0'}}
                                                >
                                                    Events
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className={classes.simpleUnderline}></div>
                                    </div>
                                    <div className={classes.eventCardsWrapper}>
                                        {groupEvents.map(event => (
                                            <div className={classes.eventCard}>
                                                <Card className={classes.card}>
                                                    <EventStickyInfo event={event.getEvent()}/>
                                                    <Link
                                                        to={`${rootGroupsURl}/events/${event.getEvent().getEventId()}`}
                                                        onClick={() => eventAnalyst('group_event_card_click', event.getEvent().getTitle() + '_clicked', event.getEvent().getTitle(), event.getEvent().getTitle() + '-detail-click-success')}>
                                                        <CardMedia
                                                            className={classes.media}
                                                            image={event.getEvent().getEventCoverImage() ? event.getEvent().getEventCoverImage() : '/images/eventDefault.png'}
                                                            title={event.getEvent().getTitle()}
                                                        />
                                                    </Link>
                                                    <CardContent className={classes.cardName}>
                                                        <div>
                                                            <Link
                                                                to={`${rootGroupsURl}/events/${event.getEvent().getEventId()}`}
                                                                onClick={() => eventAnalyst('group_event_link_click', event.getEvent().getTitle() + '_clicked', event.getEvent().getTitle(), event.getEvent().getTitle() + '-detail-click-success')}>
                                                                <Typography
                                                                    component="div"
                                                                    className={classes.eventTime}>
                                                                <span>
                                                                    {event.getEvent().getTitle()}
                                                                </span>
                                                                </Typography>
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            <Typography
                                                                className={classes.eventName}
                                                            >
                                                                <span>{formatDate(event.getEvent().getStartFrom(), 'MMM dd, p')}</span>
                                                                {getEventTypeStringInSentence(event.getEvent().getEventStatus()) === usedString.Open || getEventTypeStringInSentence(event.getEvent().getEventStatus()) === usedString.Postponed ? (
                                                                    <span>
                                                                    {isLoggedIn ? (
                                                                        <div className={classes.interested}>
                                                                            {(userEventStat[event.getEvent().getEventId()] === usedString.NOT_GOING || userEventStat[event.getEvent().getEventId()] === usedString.INTERESTED) && changeStatus && event.getEvent().getEnds() >= new Date().getTime() ? (
                                                                                <div>
                                                                                    {userEventStat[event.getEvent().getEventId()] === usedString.INTERESTED ? (
                                                                                        <span
                                                                                            onClick={() => this.userResponseToEvent(usedString.INTERESTED, event)}
                                                                                            className={classes.interestedBtn}
                                                                                            title="You are Interested to this event">
                                                                            <StarIcon
                                                                                color="primary"/>
                                                                        </span>) : (
                                                                                        <span
                                                                                            onClick={() => this.userResponseToEvent(usedString.INTERESTED, event)}
                                                                                            className={classes.interestedBtn}
                                                                                            title="are you interested to this event ?">
                                                                            <StarBorderIcon
                                                                                color="primary"/>
                                                                        </span>
                                                                                    )}
                                                                                </div>) : (<div></div>)}
                                                                            {(userEventStat[event.getEvent().getEventId()] === usedString.NOT_GOING || userEventStat[event.getEvent().getEventId()] === usedString.GOING) && changeStatus && event.getEvent().getEnds() >= new Date().getTime() ? (
                                                                                <div>
                                                                                    {userEventStat[event.getEvent().getEventId()] === usedString.GOING ? (
                                                                                        <span
                                                                                            title="You are going to this event">
                                                                                    <Icon className={classes.icon}
                                                                                          color="primary">
                                                                                        check_circle
                                                                                    </Icon>
                                                                                </span>
                                                                                    ) : (
                                                                                        <span></span>
                                                                                    )}
                                                                                </div>) : (<div></div>)}
                                                                        </div>
                                                                    ) : null}
                                                                </span>
                                                                ) : null}
                                                            </Typography>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        ))}
                                        {isLoggedIn ? (
                                            <div style={{marginTop: '35px'}}>
                                                <Link
                                                    to={`${rootGroupsURl}/calendar`}>
                                                    <div style={{
                                                        textAlign: 'center',
                                                        margin: '10px 25px',
                                                        cursor: "pointer"
                                                    }}>
                                                        <div style={{
                                                            padding: '50px', background: '#f4f6f7', borderRadius: '50%'
                                                        }}>
                                                            <IconButton aria-label="View Calendar"
                                                                        title="View Calendar"
                                                                        size="small"
                                                                        color={"primary"}>
                                                                <CalendarIcon style={{fontSize: '38px'}}/>
                                                            </IconButton>
                                                        </div>
                                                        <div style={{paddingTop: '10px', fontWeight: 600}}>Calendar
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ):null}
                                    </div>
                                </div>) :
                            (<div></div>)}
                    </div>
                ) : (<span></span>)}
            </div>
        )
    }
}

EventCard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EventCard);