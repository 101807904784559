import React, {Component} from 'react';
import {CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe,} from 'react-stripe-elements';
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./styles";
import {PaymentMethod, PaymentRequest} from "../../generated/frontend-community-ticket-payment-service_pb";
import {PayeeInfo} from "../../generated/frontend-ticket-common-types_pb";
import {makeTicketPayment} from "../../grpcRequests/tickets-request";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import globalConfig from "../../configurations/configVariables";

let uniqueRequestId;
class StripeCheckOut extends Component {

    state = {
        paying: false,
        payingMsg: '',
        paymentConfirm: false

    };

    componentDidMount() {
        uniqueRequestId = '';
    }

    checkoutConfirm = () => {
        this.createStripeReq();
        // this.setState({paymentConfirm: true});
    };

    // getPaymentConfirmation = (clickedButton, key) => {
    //     this.setState({paymentConfirm: false});
    //     if (clickedButton === 'YES')
    //         this.handleSubmit();
    // };

    createStripeReq = () => {
        let self = this;
        this.setState({paying: true, payingMsg: ''});
        if (this.props.stripe) {
            this.props.stripe
                .createToken()
                .then(
                    function (response) {
                        if (response.error) {
                            self.setState({paying: false, payingMsg: response.error.message});
                        } else if (response.token.id) {
                            if (!uniqueRequestId)
                                uniqueRequestId = response.token.id;
                            self.paymentRequests(response.token.id, uniqueRequestId, PaymentMethod.STRIPE)
                        } else {
                            self.setState({paying: false, payingMsg: 'Error: Could not get token'});
                        }
                    }
                ).catch(function (error) {
                self.setState({paying: false, payingMsg: error.message});
            });
        } else {
            this.setState({paying: false, payingMsg: 'Payment failed'});
        }
    };

    paymentRequests = (token, uniqueIdentifer, paymentType) => {
        let self = this;
        let payeeInfo = new PayeeInfo();
        payeeInfo.setName(self.props.buyerName);
        payeeInfo.setEmail(self.props.buyerEmail);
        payeeInfo.setAddress(self.props.buyerAddress);
        payeeInfo.setPhone(self.props.buyerPhone);
        const req = new PaymentRequest();
        req.setSourceId(self.props.passEventId); //event ID
        req.setTicketTypeId(self.props.passTicketId); //tickcet iD
        req.setPaymentmethod(paymentType);
        req.setPaymentToken(token); //token id
        req.setPurchaseQuantity(self.props.noOfTicket);
        req.setUniqueIdentifier(uniqueIdentifer);
        req.setPayeeInfo(payeeInfo);
        req.setAppId(globalConfig.appId);
        const metadata = req.getMetaDataMap();
        metadata.set("buyer_desc", (self.props.buyersDesc));
        self.paymentAPI(req, 3);
    };

    paymentAPI = (req, retry) => {
        let self = this;
        makeTicketPayment(req).then(function (response) {
            self.setState({paying: false});
            if (response.getPaymentSuccessful() && response.getPaymentConfirmationId() && response.getPaymentConfirmationId() !== '') {
                self.setState({payingMsg: 'Payment success'});
                self.sendCheckoutResponse(true, response.getPaymentConfirmationId(), response.getTicketTypeId(), response.getTicketIdList().length, response.getChargedAmount(), response.getPaymentTimestamp());
            } else {
                self.setState({payingMsg: 'Payment failed'});
                self.sendCheckoutResponse(false, true, '', '', '', '');
            }
        }).catch(function (error) {
            if (error.code === 14) {
                if (retry === 1) {
                    self.setState({paying: false, payingMsg: 'Payment failed'});
                } else {
                    setTimeout(() => {
                        self.paymentAPI(req, retry - 1)
                    }, 10);
                }
            } else self.setState({paying: false, payingMsg: error.message});
        })
    };

    handleChange = (change) => {
        this.setState({
            payingMsg: ''
        })
    };

    sendCheckoutResponse = (checkoutStatus, confirmationId, ticketTypeId, totalTicket, totalAmount, timeStamp) => {
        this.props.sendCloseCheckout(checkoutStatus, confirmationId, ticketTypeId, totalTicket, totalAmount, timeStamp)
    };

    createOptions = (fontSize, padding) => {
        return {
            style: {
                base: {
                    fontSize,
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    padding,
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };
    };

    render() {
        const {classes} = this.props;
        const {paying, payingMsg, paymentConfirm} = this.state;
        return (

            <div className="checkout">
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <label className={classes.label}>
                                Card Number
                                <CardNumberElement
                                    onBlur={this.handleBlur}
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                    onReady={this.handleReady}
                                    style={{padding: '10px'}}
                                    {...this.createOptions(this.props.fontSize)}
                                />
                            </label>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <label className={classes.label}>
                                Expiration date
                                <CardExpiryElement
                                    onBlur={this.handleBlur}
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                    onReady={this.handleReady}
                                    {...this.createOptions(this.props.fontSize)}
                                />
                            </label>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <label className={classes.label}>
                                CVC
                                <CardCVCElement
                                    onBlur={this.handleBlur}
                                    onChange={this.handleChange}
                                    onFocus={this.handleFocus}
                                    onReady={this.handleReady}
                                    {...this.createOptions(this.props.fontSize)}
                                />
                            </label>
                        </Grid>
                    </Grid>
                </div>
                <br/>
                {payingMsg ? (<div style={{
                    padding: '10px 15px',
                    margin: '10px 0',
                    background: "#ffbd21",
                    borderRadius: '5px'
                }}>{payingMsg}&nbsp;</div>) : (<div>&nbsp;</div>)}
                <Button variant="contained" color="secondary" onClick={this.checkoutConfirm}
                        className={classes.buttonSubmit} disabled={paying}>
                    {paying ? (<span>Paying&nbsp; &nbsp;<CircularProgress size={12}/> </span>) : 'Pay'}
                </Button>

                {/*{paymentConfirm ? (*/}
                {/*    <UserConfirmationDialog*/}
                {/*        openDialog={paymentConfirm} dialogHeader="Payment Confirmation"*/}
                {/*        postId=''*/}
                {/*        dialogContent={"Are you sure want to Pay for the ticket ? "} button1="NO" button2="YES"*/}
                {/*        toDeleteContent={<div className={classes.sharableLinkWrapper}>*/}
                {/*            <span className={classes.sharableText}>The amount will be deducted from the provided card account number. </span>*/}
                {/*        </div>}*/}
                {/*        sendUserResponse={this.getPaymentConfirmation}*/}
                {/*    />*/}
                {/*) : (<div></div>)}*/}

            </div>
        );
    }
}

StripeCheckOut.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(injectStripe(StripeCheckOut));