import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {Grid, withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styles
import styles from './styles';
import {GetSuggestedGroupRequest} from "../../generated/frontend-community-group-service_pb";
import {getAllSuggestedGroups} from "../../grpcRequests/groups-request";
import {CustomLoader} from "../../components/Skeleton/EventsSket";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {Link} from 'react-router-dom';
import SnackBar from "../../components/SnackBar/SnackBar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {pageViewAnalyst} from "../../configurations/config";
import {memberCountValue} from "../../utils/converter/shortenString";
import {metaDataSet} from "../../utils/MetaData";
import SearchBar from "../../components/SearchBar/SearchBar";
import GroupsStickyInfo from "../Groups/component/StickyInfo/GroupsStickyInfo";

const defaultPageSize = 50;
let allLists = [];

class ExploreCommunity extends Component {
    state = {
        isLoadingGroups: false,
        myGroupLists: [],
        errGroupsMsg: null,
        cursor: '',
        previousCursor: '',
        loadingMore: false,
        isLoadingNearByCommunity: false, nearByCommunityLists: [], errNearByCommunityMsg: '',

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null
    };

    componentDidMount() {
        allLists = [];
        window.scrollTo(0, 0);
        metaDataSet('', '', window.location.href, '');
        pageViewAnalyst('Community', this.props.location.pathname);
        this.exploreAllCommunities('', defaultPageSize, [], '');
    }

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    searchedText = (text) => {
        let newList = [];
        if (text && text !== '') {
            let sortedList = allLists.filter(group => group.getGroupName().toLowerCase().includes(text));
            if (sortedList.length)
                newList = sortedList;
            else newList = [];
        } else newList = allLists;

        this.setState({
            myGroupLists: newList
        });
    };

    loadMore = (cursorValue, pageSize, previousGroups, prevCursor) => {
        this.setState({loadingMore: true});
        this.exploreAllCommunities(cursorValue, pageSize, previousGroups, prevCursor)
    };

    exploreAllCommunities = (cursorValue, pageSize, previousGroups, prevCursor) => {
        let self = this;
        self.setState({
            isLoadingGroups: !prevCursor
        });
        const req = new GetSuggestedGroupRequest();
        req.setCursor(cursorValue);
        req.setPageSize(pageSize);
        getAllSuggestedGroups(req, 3).then(function (response) {
            let groups = previousGroups;
            for (let i = 0; i < response.getGroupinfoList().length; i++) {
                let newGroup = response.getGroupinfoList()[i];
                groups.push(newGroup);
            }
            const cursor = response.getNextPageToken();
            const isLoadingGroups = false;
            allLists = groups;
            self.setState({
                isLoadingGroups,
                myGroupLists: groups,
                previousCursor: prevCursor,
                cursor,
                loadingMore: false
            });
        }).catch(function (error) {
            const isLoadingGroups = false;
            const errGroupsMsg = "Something went wrong";
            self.setState({
                isLoadingGroups,
                errGroupsMsg,
                loadingMore: false
            });
            self.snackbar(true, 'Got Error While fetching User Community', 'error', 5000)
        })
    };

    render() {
        const {classes} = this.props;
        const {isLoadingGroups, errGroupsMsg, myGroupLists, cursor, previousCursor, loadingMore} = this.state;
        const rootGroupsURl = '/c/';
        return (
            <DashboardLayout title="Explore Communities" onBack="/explore">
                <div className={classes.root}>

                    <div>
                        <div className={classes.boxItems}>
                            <div>
                                <div className={classes.floatLeft}>
                                    <Typography
                                        variant="h6"
                                        style={{fontWeight: 900}}
                                    >
                                        <span>All Communities</span>
                                    </Typography>
                                </div>
                                <div>
                                    <div className={classes.floatRight}>
                                        {allLists.length > 1 ?
                                            <SearchBar sendSearchText={this.searchedText}/>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.simpleUnderline}></div>
                            {!isLoadingGroups ? (
                                <div>
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        {myGroupLists.map(group => (
                                            <Grid item xs={12} md={4} sm={4} lg={5} xl={4} key={group.getGroupId()}
                                                  className={classes.gridWrapper}>
                                                <Card className={classes.singleCard}>
                                                    <Link to={`${rootGroupsURl}${group.getGroupId()}`}>
                                                        <CardMedia
                                                            className={classes.media}
                                                            alt={group.getGroupName()}
                                                            image={group.getImage() ? group.getImage() : '/images/defaultCover.png'}
                                                            title={group.getGroupName()}
                                                        />
                                                    </Link>
                                                    <CardContent className={classes.nameWrapper}>
                                                        <div className={classes.groupName}>
                                                            <Link to={`${rootGroupsURl}${group.getGroupId()}`}>
                                                                <Typography variant="body1" className={classes.name}>
                                                                    <GroupsStickyInfo group={group} size={'19px'}/>
                                                                    {group.getGroupName()}
                                                                </Typography>
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            {group.getFollowerCount() > 0 ? (
                                                                <Typography variant="body2" className={classes.count}>
                                                                    {memberCountValue(group.getFollowerCount())} {group.getFollowerCount() > 1 ? 'members' : 'member'}
                                                                </Typography>
                                                            ) : (<span></span>)}
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    {cursor && previousCursor !== cursor ? (
                                        <div style={{textAlign: 'center'}}>
                                            <Button variant="contained" size="medium" color="secondary"
                                                    style={{margin: '15px 0', width: '300px'}}
                                                    onClick={() => this.loadMore(cursor, defaultPageSize, allLists, cursor)}
                                                    disabled={loadingMore}>
                                                {loadingMore ? (<span> Loading <CircularProgress size={15}/></span>) : (
                                                    <span>Load more</span>)}
                                            </Button>
                                        </div>
                                    ) : (<span></span>)}
                                </div>
                            ) : (<CustomLoader/>)}
                        </div>
                    </div>
                </div>
                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
            </DashboardLayout>
        );
    }
}

ExploreCommunity.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ExploreCommunity);
