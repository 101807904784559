import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {Typography, withStyles} from '@material-ui/core';
// Shared layouts
// Component styles
import styles from '../styles';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {categoryRegex, removeLastSpace} from "../../../../utils/converter/common";
import {eventAnalyst} from "../../../../configurations/config";
import usedString, {getRoleString, getStatusString} from "../../../../utils/mapper/enumToStringMapper";
import {isAdmin, isLogin} from "../../../../configurations/global-config";
import GetPostedContent from "../../../../components/PostBox/GetPostedContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Uploader from "../../../../components/ImageUpload/Uploader";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {uploadImageToDigitalOcean} from "../../../../components/ImageUpload/upload";
import {AddCategoryRequest} from "../../../../generated/frontend-community-group-service_pb";
import {createNewCategory} from "../../../../grpcRequests/groups-request";
import {Link} from "react-router-dom";

class ChannelsName extends Component {

    state={
        category:this.props.category ? this.props.category : '_feed',
        createCat: false,
        catName: '',
        catDesc: '',
        catImageFile: '',
        catUploadingURL: '',
        creatingCat:false
    };

    componentDidMount() {
        if(this.props.category){
            this.scrollToMyRef();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.category !== this.props.category) {
            this.setState({
                category: nextProps.category ? nextProps.category : '_feed'
            });
            this.scrollToMyRef();
        }
    }

    handleCategoryChange = (event, category) => {
        this.scrollToMyRef();
        this.setState({category});
        // if (category !== '_createCat')
        // window.history.pushState('', '', window.location.origin + '/c/' + this.props.mainGroupID + '/channel/' + category);
    };

    scrollToMyRef = () => {window.scrollTo(0, this.scrollToCat.offsetTop)};

    getCatImageUploadingURL = (url, file) => {
        this.setState({catImageFile: file, catUploadingURL: url})
    };

    postTextChange = (event) => {
        this.setState({catName: categoryRegex(event.target.value)});
    };
    postDescChange = (event) => {
        this.setState({catDesc: event.target.value});
    };

    handleClose = () => {
        this.setState({
            createCat: false,
            category: '_feed',
            catName: '',
            catDesc: '',
            catImageFile: '',
            catUploadingURL: ''
        });
    };

    createCat = () => {
        this.setState({
            createCat: true
        })
    };

    sendResponse = (type,status, message) => {
        this.props.sendChannelResponse(type, status,message);
    };

    createNewCat = () => {
        this.setState({disableCatBtn: true, creatingCat: true});
        let self = this;
        if (this.state.catImageFile && this.state.catUploadingURL) {
            uploadImageToDigitalOcean(this.state.catImageFile, this.state.catUploadingURL).then(function (response) {
                self.submitNewCat(response.image)
            }).catch(function (error) {
            })
        } else this.submitNewCat('')
    };

    submitNewCat = (categoryImage) => {
        let self = this;
        if (this.state.catName !== '') {
            const req = new AddCategoryRequest();
            req.setGroupId(this.props.mainGroupID);
            req.setCategoryName(this.state.catName);
            req.setCategoryDesc(this.state.catDesc);
            req.setCategoryImage(categoryImage);
            req.setGeneralWriteAccess(true);
            createNewCategory(req).then(function (response) {
                const disableCatBtn = false;
                const creatingCat = false;
                self.setState({
                    disableCatBtn, creatingCat
                });
                self.handleClose();
                self.sendResponse('created', true, '');
            }).catch(function (error) {
                const disableCatBtn = false;
                const creatingCat = false;
                self.setState({
                    disableCatBtn, creatingCat
                });
                self.sendResponse('created', false, error.message);
            })
        }
    };

    render() {
        const {classes, categoriesList, group, cType, mainGroupID} = this.props;
        const {category,catDesc,catName,createCat,disableCatBtn,creatingCat} = this.state;
        return (
            <div>
                <div ref={(ref) => this.scrollToCat = ref}></div>
                <div className={classes.headersWrapper}>
                    <div>
                        <div>
                            <Typography
                                variant="h6"
                                style={{fontWeight: 900}}
                            >
                                #Channels
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.simpleUnderline}></div>
                </div>
                {/*<div>{getRoleString(group.getRole())}||{isAdmin()}||{mainGroupID}||{cType}||{category}</div>*/}
                <div>
                    <AppBar position="static" color=""
                            className={classes.detailTabs}>
                        <Tabs
                            value={category}
                            onChange={this.handleCategoryChange}
                            indicatorColor=""
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            style={{width: '100%', padding: '16px 0'}}
                        >
                            <Tab label={<span
                                className={classes.tabLabel}>#Feeds</span>}
                                 value='_feed'
                                 className={classes.singleTab}
                                 classes={{selected: classes.selectedButton}}/>
                            {((cType === usedString.Public && isLogin()) || getRoleString(group.getRole()) === usedString.ADMIN || getRoleString(group.getRole()) === usedString.PARTICIPANT || getRoleString(group.getRole()) === usedString.MEMBER || (cType === usedString.Private) && !getRoleString(group.getRole()) === usedString.DEFAULT) && categoriesList.map(category =>
                                !category.getCategoryName().includes('post@') ? (
                                    <Tab label={
                                        <Link
                                            to={`/c/${mainGroupID}/channel/${removeLastSpace(category.getCategoryName())}`}>
                                        <span
                                            className={classes.tabLabel}>#{category.getCategoryName() === '_admin' ? 'admin' : category.getCategoryName()}</span>
                                        </Link>}
                                         value={removeLastSpace(category.getCategoryName())}
                                         className={classes.singleTab}
                                         onClick={() => eventAnalyst('community_category', 'community_category_clicked', group.getName() + ' --' + category.getCategoryName() + '-category', category.getCategoryName() + '-category-click-success')}
                                         classes={{selected: classes.selectedButton}}/>
                                ) : null
                            )}

                            {getRoleString(group.getRole()) === usedString.ADMIN ? (
                                <Tab label={<span
                                    className={classes.tabLabel}>Channel +</span>}
                                     value={'_createCat'}
                                     className={classes.createCat}
                                     onClick={this.createCat}/>
                            ) : <span></span>}
                        </Tabs>
                    </AppBar>
                    <div>
                        <div>
                            {category==='_feed' ? (
                                    <div>
                                        <div style={{padding: '5px 20px'}}>
                                            <Typography
                                                variant="h6"
                                                style={{fontWeight: 900}}
                                            >
                                                All Feeds
                                            </Typography>
                                        </div>
                                        <div style={{padding: '0 20px'}}>
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                Find the latest feed here
                                            </Typography>
                                        </div>
                                        <div className={classes.comentBox}>
                                            <GetPostedContent
                                                groupName={group.getName()}
                                                groupId={mainGroupID}
                                                groupMembers={group.getFollowerscount()}
                                                groupType={cType}
                                                channelName="_feed"
                                                userRoleStatus={getStatusString(group.getStatus())}
                                                userRole={getRoleString(group.getRole())}/>
                                        </div>
                                    </div>):
                                (cType === usedString.Public && isLogin() || getRoleString(group.getRole()) === usedString.ADMIN || getRoleString(group.getRole()) === usedString.PARTICIPANT || getRoleString(group.getRole()) === usedString.MEMBER || (cType === usedString.Private && !getRoleString(group.getRole()) === usedString.DEFAULT)) && categoriesList.map(categoryName =>
                                    <div>
                                        <div>
                                            {removeLastSpace(category) === removeLastSpace(categoryName.getCategoryName()) &&
                                            <div>
                                                <div style={{padding: '5px 20px'}}>
                                                    <Typography
                                                        variant="h6"
                                                        style={{fontWeight: 900}}
                                                    >
                                                        {category === '_admin' ? 'admin' : category} Feeds
                                                    </Typography>
                                                    {getRoleString(group.getRole()) === usedString.ADMIN || getRoleString(group.getRole()) === usedString.PARTICIPANT || isAdmin() ? (
                                                        <div>
                                                                                        <span
                                                                                            className={classes.channelLink}><a
                                                                                            href={`/quickView/${mainGroupID}/${category}`}
                                                                                            target="_blank"> All Images</a></span>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div style={{padding: '0 20px'}}>
                                                    <Typography
                                                        variant="subtitle1"
                                                    >
                                                        {categoryName.getCategoryDesc()}
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle1"
                                                        style={{lineHeight: 1}}
                                                    >
                                                    </Typography>
                                                </div>

                                                <div className={classes.comentBox}>
                                                    <GetPostedContent
                                                        groupName={group.getName()}
                                                        groupId={mainGroupID}
                                                        groupMembers={group.getFollowerscount()}
                                                        groupType={cType}
                                                        channelName={categoryName.getCategoryName()}
                                                        userRoleStatus={getStatusString(group.getStatus())}
                                                        userRole={getRoleString(group.getRole())}/>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <Dialog open={createCat} aria-labelledby="form-dialog-title" fullWidth>
                    <DialogTitle id="form-dialog-title">Create New Channel</DialogTitle>
                    <DialogContent>
                        <Typography>
                            <b>Channel Name</b>
                        </Typography>
                        <div>
                            <TextField
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">#</InputAdornment>,
                                }}
                                autoFocus
                                fullWidth
                                variant="outlined"
                                id="catName"
                                name="catName"
                                className={classes.CatContent}
                                placeholder="channel-name"
                                value={categoryRegex(catName)}
                                onChange={this.postTextChange}
                            />
                        </div>
                        <div className={classes.clearBoth}></div>
                        <br/>
                        <Typography>
                            <b>Channel Description</b>
                        </Typography>
                        <Paper className={classes.searchBoxBroad}>
                            <InputBase
                                className={classes.CatContent}
                                placeholder="Description here.."
                                multiline={true}
                                rows="3"
                                value={catDesc}
                                onChange={this.postDescChange}
                            />
                        </Paper>
                        <br/>
                        <Typography className={classes.userAction}>
                            <div><b>Channel Image</b></div>
                        </Typography>
                        <div className={classes.clearBoth}>
                            <Uploader type={"category"} paddingTop={'23%'} width={'150px'} index="0"
                                      sendImageURLObtainStatus={this.getCatImageUploadingURL}/>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            cancel
                        </Button>
                        <Button disabled={!catName || disableCatBtn} onClick={() => this.createNewCat()}
                                variant="contained" color="secondary">
                            {creatingCat ? (<span>Creating&nbsp; &nbsp;<CircularProgress size={12}/> </span>) : 'create'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ChannelsName.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChannelsName);
