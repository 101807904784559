import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {withStyles} from '@material-ui/core';
// Shared layouts
// Component styles
import styles from './styles';
import usedString, {getGroupTypeStringInSentence} from "../../../../utils/mapper/enumToStringMapper";
import {Lock as LockIcon, Public as PublicIcon} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

class GroupsStickyInfo extends Component {
    render() {
        const {classes, group, size} = this.props;

        return (
            <div>
                {getGroupTypeStringInSentence(group.getGroupType()) === usedString.Private ? (
                    <div>
                        <IconButton
                            size="small"
                            title='Private Community'
                            className={classes.privateIcon}>
                            <LockIcon style={{fontSize: size}}/>
                        </IconButton>
                    </div>
                ) : (
                    <div>
                        <IconButton
                            size="small"
                            title='Public Community'
                            className={classes.publicIcon}>
                            <PublicIcon style={{fontSize: size}}/>
                        </IconButton>
                    </div>
                )}
            </div>
        );
    }
}

GroupsStickyInfo.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GroupsStickyInfo);
