import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import IconButton from "@material-ui/core/IconButton";
import {MoreVert as MoreVertIcon} from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {DeleteCommentRequest} from "../generated/frontend-comment-service_pb";
import {deletePostComment} from "../grpcRequests/comments-request";
import {isAdmin, userInfo} from "../configurations/global-config";
import UserConfirmationDialog from "./UserConfirmationDialog";
import SnackBar from "./SnackBar/SnackBar";
import usedString from "../utils/mapper/enumToStringMapper";
import EditCommentBox from "./CommentBox/EditCommentBox";

class UserCommentMenus extends Component {
    state = {
        anchorEl: null,
        openConfirmation: false,
        openEditDialog: false,

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null

    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut,
        });
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget, openConfirmation: false,openEditDialog:false});
    };

    handleCloseMenu = () => {
        this.setState({anchorEl: null});
    };

    editComment = () => {
        this.setState({openEditDialog: true, anchorEl: null});
    };

    openConfirmation = () => {
        this.setState({openConfirmation: true, anchorEl: null});
    };

    getUserResponseFromChild = (clickedButton, key) => {
        this.setState({openConfirmation: false});
        if (clickedButton === "YES") {
            this.deleteComment(key);
        }
    };

    getEditResponseFromChild = (actionType, actionStatus, editedKey) => {
        if(actionStatus){
            this.handleCloseMenu();
            this.sendResponseToUserComment(actionType, actionStatus, this.props.postID, editedKey);
        }
    };

    sendResponseToUserComment = (actionName, isActionCompleted, postId, commentKey) => {
        this.props.sendUserResponse(actionName, isActionCompleted, postId, commentKey,this.props.indexValue);
    };

    deleteComment = (key) => {
        this.setState({snackOpen: false});
        let self = this;
        this.snackbar(true, 'Deleting Comment', 'info', 2000);
        const req = new DeleteCommentRequest();
        req.setPostUri(this.props.postID);
        req.setCommentId(key);
        req.setStatus(DeleteCommentRequest.Status.APPROVED);
        deletePostComment(req).then(function (response) {
            self.sendResponseToUserComment("delete", true, self.props.postID, key)
        }).catch(function (error) {
            self.sendResponseToUserComment("delete", false, self.props.postID, key)
        })
    };

    render() {
        const {classes, commentText, postID, ownerID, commentID, userRole} = this.props;
        const {anchorEl, openConfirmation,openEditDialog} = this.state;
        const open = Boolean(anchorEl);
        return (
            <span>
                <IconButton
                    size="small"
                    aria-label="Account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                    className={classes.moreVertPost}
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="menu-id"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    className={classes.menuThreeVert}
                    onClose={this.handleCloseMenu}
                >
                    {ownerID === userInfo().user_id ? (
                        <MenuItem onClick={this.editComment}>Edit</MenuItem>
                    ):null}
                    {ownerID === userInfo().user_id || isAdmin() || userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT ? (
                        <div>
                            <MenuItem onClick={this.openConfirmation}>Delete</MenuItem>
                        </div>
                    ) : (<div>
                        <MenuItem>Save</MenuItem>
                        <MenuItem>Report</MenuItem>
                    </div>)}
                </Menu>
                {openConfirmation ? (
                    <UserConfirmationDialog
                        openDialog={openConfirmation} dialogHeader="Delete Comment"
                        postId={commentID}
                        dialogContent={"Are you sure want to delete your comment ?"} button1="NO" button2="YES"
                        toDeleteContent={<div className={classes.sharableLinkWrapper}>
                            <span className={classes.sharableText}> {commentText}</span>
                        </div>}
                        sendUserResponse={this.getUserResponseFromChild}
                    />
                ) : (<div></div>)}

                {openEditDialog ? (
                    <EditCommentBox
                        openDialog={openEditDialog} dialogHeader="Edit Comment"
                        commentId={commentID}
                        postId={postID}
                        userCommentText={commentText}
                        sendUserResponse={this.getEditResponseFromChild}
                    />
                ) : (<div></div>)}

                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
            </span>
        )
    }
}

UserCommentMenus.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserCommentMenus);