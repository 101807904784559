/**
 * @fileoverview gRPC-Web generated client stub for com.hamropatro.sociallayer.io
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');


var frontend$social$common$types_pb = require('./frontend-social-common-types_pb.js')
const proto = {};
proto.com = {};
proto.com.hamropatro = {};
proto.com.hamropatro.sociallayer = {};
proto.com.hamropatro.sociallayer.io = require('./frontend-community-group-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient =
    function (hostname, credentials, options) {
      if (!options) options = {};
      options['format'] = 'text';

      /**
       * @private @const {!grpc.web.GrpcWebClientBase} The client
       */
      this.client_ = new grpc.web.GrpcWebClientBase(options);

      /**
       * @private @const {string} The hostname
       */
      this.hostname_ = hostname;

    };


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient =
    function (hostname, credentials, options) {
      if (!options) options = {};
      options['format'] = 'text';

      /**
       * @private @const {!grpc.web.GrpcWebClientBase} The client
       */
      this.client_ = new grpc.web.GrpcWebClientBase(options);

      /**
       * @private @const {string} The hostname
       */
      this.hostname_ = hostname;

    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse>}
 */
const methodDescriptor_CommunityService_GetUserGroups = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/GetUserGroups',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest,
    proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse>}
 */
const methodInfo_CommunityService_GetUserGroups = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.getUserGroups =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetUserGroups',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetUserGroups,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetUserGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetUserGroupsResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.getUserGroups =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetUserGroups',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetUserGroups);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetGroupRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetGroupResponse>}
 */
const methodDescriptor_CommunityService_GetGroup = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/GetGroup',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetGroupRequest,
    proto.com.hamropatro.sociallayer.io.GetGroupResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetGroupRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetGroupResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetGroupRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetGroupResponse>}
 */
const methodInfo_CommunityService_GetGroup = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetGroupResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetGroupRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetGroupResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.getGroup =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetGroup',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetGroup,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetGroupResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.getGroup =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetGroup',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetGroup);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse>}
 */
const methodDescriptor_CommunityService_GetPendingGroups = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/GetPendingGroups',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest,
    proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse>}
 */
const methodInfo_CommunityService_GetPendingGroups = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.getPendingGroups =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetPendingGroups',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetPendingGroups,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetPendingGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetPendingGroupsResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.getPendingGroups =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetPendingGroups',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetPendingGroups);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse>}
 */
const methodDescriptor_CommunityService_UpdateGroupType = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/UpdateGroupType',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest,
    proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse>}
 */
const methodInfo_CommunityService_UpdateGroupType = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.updateGroupType =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/UpdateGroupType',
          request,
          metadata || {},
          methodDescriptor_CommunityService_UpdateGroupType,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.UpdateGroupTypeResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.updateGroupType =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/UpdateGroupType',
          request,
          metadata || {},
          methodDescriptor_CommunityService_UpdateGroupType);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetCategoriesRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetCategoriesResponse>}
 */
const methodDescriptor_CommunityService_GetCategories = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/GetCategories',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetCategoriesRequest,
    proto.com.hamropatro.sociallayer.io.GetCategoriesResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetCategoriesRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetCategoriesRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetCategoriesResponse>}
 */
const methodInfo_CommunityService_GetCategories = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetCategoriesResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetCategoriesRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetCategoriesResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetCategoriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetCategoriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.getCategories =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetCategories',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetCategories,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetCategoriesResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.getCategories =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetCategories',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetCategories);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.AddCategoryRequest,
 *   !proto.com.hamropatro.sociallayer.io.AddCategoryResponse>}
 */
const methodDescriptor_CommunityService_AddCategory = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/AddCategory',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.AddCategoryRequest,
    proto.com.hamropatro.sociallayer.io.AddCategoryResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.AddCategoryRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.AddCategoryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.AddCategoryRequest,
 *   !proto.com.hamropatro.sociallayer.io.AddCategoryResponse>}
 */
const methodInfo_CommunityService_AddCategory = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.AddCategoryResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.AddCategoryRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.AddCategoryResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AddCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.AddCategoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.AddCategoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.addCategory =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/AddCategory',
          request,
          metadata || {},
          methodDescriptor_CommunityService_AddCategory,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.AddCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.AddCategoryResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.addCategory =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/AddCategory',
          request,
          metadata || {},
          methodDescriptor_CommunityService_AddCategory);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse>}
 */
const methodDescriptor_CommunityService_UpdateCategoryAccess = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/UpdateCategoryAccess',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest,
    proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse>}
 */
const methodInfo_CommunityService_UpdateCategoryAccess = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.updateCategoryAccess =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/UpdateCategoryAccess',
          request,
          metadata || {},
          methodDescriptor_CommunityService_UpdateCategoryAccess,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.UpdateCategoryAccessResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.updateCategoryAccess =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/UpdateCategoryAccess',
          request,
          metadata || {},
          methodDescriptor_CommunityService_UpdateCategoryAccess);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest,
 *   !proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse>}
 */
const methodDescriptor_CommunityService_GetInvitationLink = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/GetInvitationLink',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest,
    proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest,
 *   !proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse>}
 */
const methodInfo_CommunityService_GetInvitationLink = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.getInvitationLink =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetInvitationLink',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetInvitationLink,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.CreateInvitationLinkResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.getInvitationLink =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetInvitationLink',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetInvitationLink);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest,
 *   !proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse>}
 */
const methodDescriptor_CommunityService_GenerateDirectJoinLink = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/GenerateDirectJoinLink',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest,
    proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest,
 *   !proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse>}
 */
const methodInfo_CommunityService_GenerateDirectJoinLink = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.generateDirectJoinLink =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GenerateDirectJoinLink',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GenerateDirectJoinLink,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.CreateDirectJoinInvitationLinkResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.generateDirectJoinLink =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GenerateDirectJoinLink',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GenerateDirectJoinLink);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest,
 *   !proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse>}
 */
const methodDescriptor_CommunityService_RemoveDirectJoinLink = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/RemoveDirectJoinLink',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest,
    proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest,
 *   !proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse>}
 */
const methodInfo_CommunityService_RemoveDirectJoinLink = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.removeDirectJoinLink =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/RemoveDirectJoinLink',
          request,
          metadata || {},
          methodDescriptor_CommunityService_RemoveDirectJoinLink,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.RemoveDirectJoinInvitationLinkResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.removeDirectJoinLink =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/RemoveDirectJoinLink',
          request,
          metadata || {},
          methodDescriptor_CommunityService_RemoveDirectJoinLink);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse>}
 */
const methodDescriptor_CommunityService_GetSuggestedGroup = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/GetSuggestedGroup',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest,
    proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse>}
 */
const methodInfo_CommunityService_GetSuggestedGroup = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.getSuggestedGroup =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetSuggestedGroup',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetSuggestedGroup,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetSuggestedGroupResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.getSuggestedGroup =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetSuggestedGroup',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetSuggestedGroup);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest,
 *   !proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse>}
 */
const methodDescriptor_CommunityService_UpdateTickingPrivilege = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/UpdateTickingPrivilege',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest,
    proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest,
 *   !proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse>}
 */
const methodInfo_CommunityService_UpdateTickingPrivilege = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.updateTickingPrivilege =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/UpdateTickingPrivilege',
          request,
          metadata || {},
          methodDescriptor_CommunityService_UpdateTickingPrivilege,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.TicketingPrivilegeResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.updateTickingPrivilege =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/UpdateTickingPrivilege',
          request,
          metadata || {},
          methodDescriptor_CommunityService_UpdateTickingPrivilege);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse>}
 */
const methodDescriptor_CommunityService_GetNearbyGroups = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/GetNearbyGroups',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest,
    proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest,
 *   !proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse>}
 */
const methodInfo_CommunityService_GetNearbyGroups = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.getNearbyGroups =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetNearbyGroups',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetNearbyGroups,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.GetNearbyGroupsResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.getNearbyGroups =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/GetNearbyGroups',
          request,
          metadata || {},
          methodDescriptor_CommunityService_GetNearbyGroups);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse>}
 */
const methodDescriptor_CommunityService_UpdateGroupNotificationPrivilege = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/UpdateGroupNotificationPrivilege',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest,
    proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse>}
 */
const methodInfo_CommunityService_UpdateGroupNotificationPrivilege = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.updateGroupNotificationPrivilege =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/UpdateGroupNotificationPrivilege',
          request,
          metadata || {},
          methodDescriptor_CommunityService_UpdateGroupNotificationPrivilege,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.UpdateNotificationPrivilegeResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.updateGroupNotificationPrivilege =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/UpdateGroupNotificationPrivilege',
          request,
          metadata || {},
          methodDescriptor_CommunityService_UpdateGroupNotificationPrivilege);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse>}
 */
const methodDescriptor_CommunityService_UpdateGroupMaxPushCounts = new grpc.web.MethodDescriptor(
    '/com.hamropatro.sociallayer.io.CommunityService/UpdateGroupMaxPushCounts',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest,
    proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest,
 *   !proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse>}
 */
const methodInfo_CommunityService_UpdateGroupMaxPushCounts = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse,
    /**
     * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.sociallayer.io.CommunityServiceClient.prototype.updateGroupMaxPushCounts =
    function (request, metadata, callback) {
      return this.client_.rpcCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/UpdateGroupMaxPushCounts',
          request,
          metadata || {},
          methodDescriptor_CommunityService_UpdateGroupMaxPushCounts,
          callback);
    };


/**
 * @param {!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.sociallayer.io.UpdateGroupPushCountsResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.sociallayer.io.CommunityServicePromiseClient.prototype.updateGroupMaxPushCounts =
    function (request, metadata) {
      return this.client_.unaryCall(this.hostname_ +
          '/com.hamropatro.sociallayer.io.CommunityService/UpdateGroupMaxPushCounts',
          request,
          metadata || {},
          methodDescriptor_CommunityService_UpdateGroupMaxPushCounts);
    };


module.exports = proto.com.hamropatro.sociallayer.io;

