export default theme => ({
    root: {
        padding: theme.spacing(2),
        minHeight: '85vh'
    },
    item: {
        height: '100%',
    },
    avatar: {
        margin: 5,
        width: 33,
        height: 33,
        border: '1px solid #e5e5e5'
    },
    upComingEventsWrapper: {
        height: '70px',
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '5px',
        overflow: 'hidden'
    },
    goingEvent: {
        float: 'right',
        marginTop: '-5px'
    },
    eventsDesc: {
        fontSize: '15px',
        height: '68px',
        overflow: 'hidden',
        fontWeight: 400,
        color: theme.palette.text.primary,
        padding: '5px 15px 10px',
        textAlign: 'center'
    },
    avatarWrapper: {
        padding: '10px',
        paddingTop: '5px',
        height: '60px',
    },
    card: {
        background: '#f4f6f7',
        boxShadow: 'none',
        width: '150px'
    },
    titleTopBar: {
        marginTop: '25px',
        marginBottom: '15px'
    },
    boxItems: {
        marginTop: '15px',
        marginBottom: '30px'
    },
    floatRight: {
        float: 'right'
    },
    floatLeft: {
        float: 'left'
    },
    buttonView: {
        fontSize: '16px',
        boxShadow: 'none',
        color: '#000000',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    homeCardWrapper: {
        overflow: 'auto',
        width: '100%',
    },
    groupCard: {
        margin: '10px'
    },
    singleCard: {
        boxShadow: 'none',
        minWidth: '170px',
        height: '100%',
    },
    noMemberWrapper: {
        padding: '10px',
        borderRadius: '5px',
        background: '#66aef1d4',
        color: '#253d49',
        fontSize: '16px'
    },
    actionWrapper: {
        height: '100%'
    },
    flexWrapper: {
        display: 'inline-flex',
    },
    morePeople: {
        fontSize: '13px',
        fontWeight: '600',
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
        borderRadius: '10px'
    },
    nameWrapper: {
        padding: '10px 5px',
    },
    groupName: {
        maxHeight: '77px',
        overflow: 'hidden'
    },
    name: {
        fontSize: '17px',
        fontWeight: 600,
        color: theme.palette.text.primary,
        clear: 'both',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    count: {
        fontSize: '13px',
        color: theme.palette.text.primary,
        clear: 'both'
    },
    simpleUnderline: {
        paddingTop: '5px',
        clear: 'both',
        borderBottom: '1px solid #e5e5e5'
    },
});
