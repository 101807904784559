import React, {Component, Fragment} from 'react';
// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Material helpers
// Material components
import {Divider, List, Typography, withStyles} from '@material-ui/core';
// Material icons
import {
    Code as CodeIcon,
    Payment as PaymentIcon,
    PeopleOutlined as PeopleIcon,
    Store as StoreIcon
} from '@material-ui/icons';
// Component styles
import styles from './styles';
import {formatDate, timeEvent} from "../../../../../../utils/converter/dateConverter";
import Avatar from "@material-ui/core/Avatar";
import {UserLoader} from "../../../../../../components/Skeleton/EventsSket";
import {Link} from "react-router-dom";
import {shortenString} from "../../../../../../utils/converter/shortenString";

const icons = {
    order: {
        icon: <PaymentIcon/>,
        color: 'blue'
    },
    user: {
        icon: <PeopleIcon/>,
        color: 'red'
    },
    product: {
        icon: <StoreIcon/>,
        color: 'green'
    },
    feature: {
        icon: <CodeIcon/>,
        color: 'purple'
    }
};

class NotificationList extends Component {
    render() {
        const {className, classes, notifications, onSelect, loadingNotification, onGoto} = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <div className={rootClassName}>
                <div className={classes.header}>
                    <Typography variant="h6" style={{fontWeight: 600}}>User Notifications</Typography>
                </div>
                {!loadingNotification ? (
                    <div>
                        {notifications.length > 0 ? (
                            <Fragment>
                                <div className={classes.content}>
                                    <List component="div">
                                        {notifications.map(notification => (
                                            <div>
                                                {/*notification.getDeeplink().split('hamropatro://communities/')[1].split('&')[0] -->get links with title*/}
                                                {notification.getDeeplink() && notification.getDeeplink().includes('hamropatro://communities') ? (
                                                    <div className={classes.product}
                                                         onClick={('/' + notification.getDeeplink().split('hamropatro://communities/')[1].split('?')[0]) !== window.location.pathname ? (() => onGoto(notification)) : onSelect}>
                                                        <div>
                                                            <Avatar alt={notification.getTitle()}
                                                                    style={{
                                                                        backgroundImage: `url('${notification.getActorimageurl()}')`,
                                                                        backgroundRepeat: 'round'
                                                                    }}
                                                                    className={classes.loggedInUserAvatar}/>
                                                        </div>
                                                        <div className={classes.productDetails}>

                                                            <div className={classes.timeUUID}>
                                                                <Link
                                                                    // to={`/${notification.getDeeplink().split('hamropatro://communities/')[1].split('?')[0]}`}>
                                                                    to={notification.getMetadataMap().get("parent_deeplink").split('hamropatro://communities/')[1].split('/events/')[1] ? notification.getMetadataMap().get("parent_deeplink").split('hamropatro://communities/')[1].split('/events/')[1].split('?')[0] ? '/' + notification.getDeeplink().split('hamropatro://communities/')[1].split('?')[0] + `?eventId=${notification.getMetadataMap().get("parent_deeplink").split('hamropatro://communities/')[1].split('/events/')[1].split('?')[0]}` : `/${notification.getDeeplink().split('hamropatro://communities/')[1].split('?')[0]}` : `/${notification.getDeeplink().split('hamropatro://communities/')[1].split('?')[0]}`}>
                                                                    <div
                                                                        title={formatDate(notification.getTimestamp(), 'MMM dd, p')}
                                                                        className={classes.timeUUIDLink}
                                                                        style={{float: "right"}}>{formatDate(notification.getTimestamp(), 'MMM dd, p')}</div>
                                                                    <div
                                                                        title={formatDate(notification.getTimestamp(), 'MMM dd, p')}
                                                                        className={classes.timeUUIDLink}
                                                                        style={{float: "left"}}>{timeEvent(notification.getTimestamp())}</div>
                                                                </Link>
                                                            </div>
                                                            <Typography
                                                                className={classes.notificationDesc}
                                                                variant="body1"
                                                            >
                                                                {shortenString(notification.getDescription(), 130)}{notification.getDescription() && notification.getDescription().length >= 130 ? '..."' : ''}
                                                            </Typography>
                                                        </div>
                                                        <Divider/>
                                                    </div>
                                                    // </Link>
                                                ) : null
                                                }
                                            </div>
                                        ))}
                                    </List>
                                    {/*<div className={classes.footer}>*/}
                                    {/*    <Button*/}
                                    {/*        color="primary"*/}
                                    {/*        component={Link}*/}
                                    {/*        size="small"*/}
                                    {/*        to="#"*/}
                                    {/*        variant="contained"*/}
                                    {/*    >*/}
                                    {/*        See all*/}
                                    {/*    </Button>*/}
                                    {/*</div>*/}
                                </div>
                            </Fragment>
                        ) : (
                            <div className={classes.empty}>
                                {/*<div className={classes.emptyImageWrapper}>*/}
                                {/*    <img*/}
                                {/*        alt="Empty list"*/}
                                {/*        className={classes.emptyImage}*/}
                                {/*        src="/images/empty.png"*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <Typography variant="h6">No Notifications</Typography>
                            </div>
                        )}
                    </div>
                ) : <div style={{padding: '15px 10px'}}><UserLoader/></div>}
            </div>
        );
    }
}

NotificationList.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    onGoto: PropTypes.func
};

NotificationList.defaultProps = {
    notifications: [],
    onSelect: () => {},
    onGoto: () => {},
};

export default withStyles(styles)(NotificationList);
