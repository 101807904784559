import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {Grid, Typography, withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styles
import styles from './styles';
import {pageViewAnalyst} from "../../configurations/config";
import {TransactionDetailRequest} from "../../generated/frontend-community-ticket-service_pb";
import PleaseWait from "../../components/PleaseWait";
import {getUserTicketsByPaymentId} from "../../grpcRequests/tickets-request";
import {Link} from "react-router-dom";
import {formatDate, get_date_obj, timeEvent} from "../../utils/converter/dateConverter";
import {b64Encode} from "../../utils/converter/base64";
import usedString, {getCountryCurrencySymbol} from "../../utils/mapper/enumToStringMapper";
import CircleIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";

var QRCode = require('qrcode.react');

class TicketPaymentByPaymentId extends Component {

    state = {
        sourceId: this.props.match.params.ticketSourceId,
        ticketTypeId: this.props.match.params.ticketTypeId,
        paymentId: this.props.match.params.paymentID,
        loading: true,
        gotTicketInfo: false,
        ticketInfo: [],
        transactionInfo: '',
        transactionCurrency: '',
        transactionGroupId: '',
        buyerInfo: '',
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        pageViewAnalyst('Ticket Details By payment id', this.props.location.pathname);
        this.getTicketInformation(this.props.match.params.ticketSourceId, this.props.match.params.ticketTypeId, this.props.match.params.paymentID);
    }

    getTicketInformation = (sourceId, typeId, paymentId) => {
        let self = this;
        const req = new TransactionDetailRequest();
        req.setTransactionId(paymentId);
        getUserTicketsByPaymentId(req, 3).then(function (response) {
            const ticketInfo = response.getUserTicketList();
            const transactionInfo = response.getTransactionDetail();
            let transactionCurrency, transactionGroupId, eventName;
            if (response.getUserTicketList().length > 0) {
                transactionCurrency = response.getUserTicketList()[0].getCurrency();
                transactionGroupId = response.getUserTicketList()[0].getGroupId();
                eventName = response.getUserTicketList()[0].getEventMetadataMap().get('event_name');
            }
            const gotTicketInfo = true;
            const loading = false;
            self.setState({
                ticketInfo, transactionInfo, gotTicketInfo, loading, transactionCurrency, transactionGroupId, eventName
            });
        }).catch(function (error) {
            const loading = false;
            self.setState({
                loading
            });
        });
    };

    render() {
        const {classes} = this.props;
        const {ticketInfo, gotTicketInfo, loading, transactionInfo, transactionCurrency, transactionGroupId, eventName} = this.state;

        return (
            <DashboardLayout title="Ticket Payment Details" onBack='/tickets' linkTo='/tickets'>
                <div className={classes.root}>
                    {!loading ? (
                            <div>
                                {gotTicketInfo ? (
                                    <div>
                                        {ticketInfo.length > 0 ? (
                                            <div style={{margin: '25px 0'}}>
                                                <Typography
                                                    variant="subtitle1"
                                                    style={{fontWeight: 600, color: '#686868'}}
                                                >
                                                    {timeEvent(get_date_obj(transactionInfo.getTransactionTime()))}
                                                </Typography>
                                                <Paper className={classes.paperRoot}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                    >
                                                        <Grid item xs={2} md={1} sm={1} lg={1}
                                                              style={{borderRight: '1px solid #e5e5e5'}}>
                                                            <Avatar alt='ticket Name'
                                                                    style={{
                                                                        backgroundImage: `url('/images/icons/tickets.png')`,
                                                                        backgroundRepeat: 'round',
                                                                        backgroundColor: 'unset',
                                                                        margin: 'auto',
                                                                        top: '5px',
                                                                        width: '30px',
                                                                        height: '30px',
                                                                    }}/>
                                                        </Grid>
                                                        <Grid item xs={10} md={11} sm={11} lg={11}
                                                              style={{paddingLeft: '16px'}}>
                                                            <Typography
                                                                variant="div"
                                                                style={{fontWeight: 600}}
                                                            >{transactionInfo.getTotalCharge() === 0 ? (
                                                                <span>
                                                                You bought {transactionInfo.getQuantity()} free tickets
                                                                </span>) : (
                                                                <span>
                                                                You bought {transactionInfo.getQuantity()} tickets
                                                                at {transactionCurrency} {getCountryCurrencySymbol(transactionCurrency)}{transactionInfo.getTotalCharge()}
                                                                </span>
                                                            )}
                                                            </Typography>
                                                            <Typography component="div" style={{
                                                                fontSize: '15px',
                                                                fontWeight: 600,
                                                                lineHeight: '1.7',
                                                                color: '#686868'
                                                            }}>
                                                                <Link className={classes.hover}
                                                                      to={`/c/${transactionGroupId}/events/${transactionInfo.getTicketsourceId()}`}>
                                                                    {eventName}
                                                                </Link>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </div>
                                        ) : null}
                                        <div>
                                            {ticketInfo.length > 0 ? (
                                                <div>
                                                    {ticketInfo.map(boughtTicket => (
                                                        <div className={classes.ticketPaperRoot}>
                                                            <Grid
                                                                container
                                                                spacing={3}
                                                            >
                                                                <Grid item xs={12} sm={12} style={{padding: '0 10px'}}>
                                                                    <Typography component="div" style={{
                                                                        fontSize: '15px',
                                                                        color: '#7b798d',
                                                                        padding: '10px 16px',
                                                                        fontWeight: 900,
                                                                        borderBottom: '1px solid #e5e5e5'
                                                                    }}>
                                                                        Ticket Details
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={8}>
                                                                    <div style={{padding: '0 16px'}}>
                                                                        <Typography component="div" style={{
                                                                            fontSize: '17px',
                                                                            fontWeight: 600,
                                                                            lineHeight: '1.7'
                                                                        }}>
                                                                            <Link className={classes.hover}
                                                                                  to={`/c/${boughtTicket.getGroupId()}/events/${boughtTicket.getEventId()}`}>
                                                                                {boughtTicket.getEventMetadataMap().get('event_name')}
                                                                            </Link>
                                                                        </Typography>
                                                                        <Typography component="div"
                                                                                    style={{
                                                                                        fontSize: '14px',
                                                                                        color: '#7b798d'
                                                                                    }}>
                                                                            {boughtTicket.getEventMetadataMap().get('event_location')}
                                                                        </Typography>
                                                                        <Typography component="div"
                                                                                    style={{
                                                                                        fontSize: '13px',
                                                                                        color: '#7b798d'
                                                                                    }}>
                                                                            {formatDate(Number(boughtTicket.getEventMetadataMap().get('event_startTime')), 'MMMM dd, yyyy p')}
                                                                        </Typography>
                                                                        {boughtTicket.getAmount() === 0 ? null : (
                                                                            <Typography component="div" style={{
                                                                                fontSize: '12px',
                                                                                color: '#7b798d'
                                                                            }}>
                                                                                Payment Mode : Credit Card
                                                                            </Typography>)}
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <div style={{
                                                                        padding: '0 16px',
                                                                        textAlign: 'center'
                                                                    }}>
                                                                        <QRCode
                                                                            id="123456"
                                                                            value={b64Encode(boughtTicket.getEventId() + ':' + boughtTicket.getTicketTypeId() + ':' + boughtTicket.getTicketId() + ':' + boughtTicket.getUserId())}
                                                                            size={130}
                                                                            level={"L"}
                                                                            renderAs={'svg'}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12}
                                                                      style={{padding: '0 0 10px'}}>
                                                                    <div style={{
                                                                        padding: '0 16px',
                                                                        textAlign: 'center'
                                                                    }}>
                                                                        <Typography component="div"
                                                                                    style={{
                                                                                        fontSize: '13px',
                                                                                        color: '#7b798d'
                                                                                    }}>
                                                                            Ticket Summary
                                                                        </Typography>
                                                                        <Typography component="span" style={{
                                                                            fontSize: '17px',
                                                                            fontWeight: 600,
                                                                            lineHeight: '1.1'
                                                                        }}>
                                                                            Ticket No.
                                                                            #{boughtTicket.getTicketId()}&nbsp;
                                                                            {boughtTicket.getStatus() === usedString.USED ? (
                                                                                <span title="This ticket has been used"><CircleIcon
                                                                                    fontSize='small'
                                                                                    style={{color: '#458c48'}}/></span>
                                                                            ) : null}
                                                                        </Typography>
                                                                        <Typography component="div"
                                                                                    style={{
                                                                                        fontSize: '13px',
                                                                                        color: '#7b798d'
                                                                                    }}>
                                                                            {formatDate(boughtTicket.getPurchasedTimestamp(), 'MMMM dd, yyyy p')}
                                                                        </Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <div style={{padding: '0 16px'}}>
                                                                        <Typography component="div"
                                                                                    style={{
                                                                                        fontSize: '13px',
                                                                                        color: '#7b798d'
                                                                                    }}>
                                                                            Ticket Name
                                                                        </Typography>
                                                                        <Typography component="div" style={{
                                                                            fontSize: '15px',
                                                                            fontWeight: 600
                                                                        }}>
                                                                            {boughtTicket.getTicketName()}
                                                                        </Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={4}>
                                                                    <div style={{padding: '0 16px'}}>
                                                                        <Typography component="div"
                                                                                    style={{
                                                                                        fontSize: '13px',
                                                                                        color: '#7b798d'
                                                                                    }}>
                                                                            Quantity
                                                                        </Typography>
                                                                        <Typography component="div" style={{
                                                                            fontSize: '15px',
                                                                            fontWeight: 600
                                                                        }}>
                                                                            1
                                                                        </Typography>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={4}>
                                                                    <div style={{padding: '0 16px'}}>
                                                                        <Typography component="div"
                                                                                    style={{
                                                                                        fontSize: '13px',
                                                                                        color: '#7b798d'
                                                                                    }}>
                                                                            Amount
                                                                        </Typography>
                                                                        <Typography component="div" style={{
                                                                            fontSize: '15px',
                                                                            fontWeight: 600
                                                                        }}>
                                                                            {boughtTicket.getAmount() === 0 ? usedString.FREE : (
                                                                                <span>{boughtTicket.getCurrency()} {getCountryCurrencySymbol(boughtTicket.getCurrency())}{boughtTicket.getAmount()}</span>)}
                                                                        </Typography>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className={classes.noMemberWrapper}>
                                                        Your ticket list is empty.
                                                    </div>
                                                </div>)}
                                        </div>
                                    </div>
                                ) : 'Sorry we Could not get ticket details now.'}
                            </div>
                        ) :
                        (<PleaseWait msg="Loading ticket details... "/>)}
                </div>
            </DashboardLayout>
        );
    }
}

TicketPaymentByPaymentId.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TicketPaymentByPaymentId);
