import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {withStyles} from '@material-ui/core';
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
// Component styles
import styles from './styles';
import Calendar from "../../components/Calendar/Calendar.jsx";
import {checkIfLogin} from "../../configurations/global-config";
import {pageViewAnalyst} from "../../configurations/config";

class Messages extends Component {
    componentDidMount() {
        pageViewAnalyst('Message', this.props.location.pathname);
        checkIfLogin(this.props.location.pathname);
    }

    render() {
        const {classes} = this.props;

        return (
            <DashboardLayout title="Messages">
                <div className={classes.root}>
                    <Calendar/>
                </div>
            </DashboardLayout>
        );
    }
}

Messages.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Messages);
