import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles, withWidth} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import styles from "./styles";
import compose from "recompose/compose";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import {userInfo} from "../../configurations/global-config";
import InputBase from "@material-ui/core/InputBase";
import {AddPhotoAlternate as AddImage} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {Content, Post, UpdatePostRequest} from "../../generated/frontend-post-service_pb";
import {updateUsersPost} from "../../grpcRequests/posts-request";
import {uploadImageToDigitalOcean} from "../ImageUpload/upload";


const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeEditBox} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class EditDialogBox extends Component {
    state = {
        open: this.props.openDialog,
        dialogHeader: this.props.dialogHeader,
        dialogContent: this.props.dialogContent,
        dialogContent2: this.props.toDeleteContent,
        postKey: this.props.postId,
        button1: this.props.button1,
        button2: this.props.button2,

        //    post box
        multiline: true,
        postingText:this.props.postData ? this.props.postData.getContent().getDataMap().get("TEXT") : '',
        creatingPost:false,
        disable:false,

        //Images from childUploader
        uploadImage:false,
        imageFile: [],
        uploadingURL: [],
        imageCount: [],
        nextImageCount: 2,

    };
    componentDidMount() {
        const postData = this.props.postData;
        let self= this;
        self.setState({
            imageCount:[],
            imageFile: [],
            uploadingURL: [],
            nextImageCount: 2
        });
        if(postData) {
            let postImages=[];
            this.props.postData.getContent().getDataMap().forEach(function (value, key) {
                if (key.includes('IMAGE_URL_') || key.includes('IMAGE') || key.includes('IMAGE_')) {
                    postImages.push(value);
                }
            });
            self.setState({imageCount: postImages, imageFile: postImages});

        }
    }

    getImageUploadingURL = (url, file, index) => {
        if (url && file) {
            this.setState({
                imageFile: {...this.state.imageFile, [index]: file},
                uploadingURL: {...this.state.uploadingURL, [index]: url}
            })
        }
    };

    uploadToDigital(imageFile) {
        let uploadedImages = [];
        let self = this;
        var length = 0;
        var itemsProcessed = 0;
        for (var key in imageFile) {
            if (imageFile.hasOwnProperty(key)) {
                ++length;
            }
        }
        let resolved =false;
        return new Promise(function (resolve, reject) {
            Object.keys(imageFile).forEach((file, index) => {
                if(typeof self.state.imageFile[index] != "string") {
                    uploadImageToDigitalOcean(self.state.imageFile[index], self.state.uploadingURL[index]).then(function (response) {
                        uploadedImages.push(response.image);
                        itemsProcessed++;
                        if (!resolved && itemsProcessed === length) {
                            resolved=true;
                            resolve(uploadedImages)
                        }

                    }).catch(function (error) {
                    })
                }else {
                    uploadedImages.push(self.state.imageFile[index]);
                    itemsProcessed++;
                    if (!resolved && itemsProcessed === length) {
                        resolved=true;
                        resolve(uploadedImages)
                    }
                }
            });
        });
    }
    changeUploadSts = () => {
        {
            let currentUploadSts = this.state.uploadImage;
            if(!currentUploadSts)
                this.setState({uploadImage: true});
        }
    };
    postTextChange = (event) => {
        this.setState({postingText: event.target.value});
    };
    sendResponse = (actionType, actionStatus, editedCategory, editedKey, msg) => {
        this.props.sendUserResponse(actionType, actionStatus, editedCategory, editedKey, msg);
    };

    handleClose = () => {
        this.setState({
            open: false, uploadImage: false
        });
    };
    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    };
    createPost = () => {
        this.setState({snackOpen: false, disable: true, creatingPost: true});
        let self = this;
        if (!this.isEmpty(this.state.imageFile) && !this.isEmpty(this.state.uploadingURL) && this.state.imageCount.length >= 1) {
            this.uploadToDigital(this.state.imageFile).then(function (response) {
                self.setState({imageCount: response});
                self.postNewPost(response);
                // self.state.imageCount.forEach(function (imageOnly,index) {
                //     if(isNaN(imageOnly)){
                //         self.state.imageCount.splice(index, 1);
                //     }
                // });
            })
        } else this.postNewPost(this.state.imageCount);
    };

    postNewPost = (postingImage) => {
        let self = this;
        let postDetails= this.props.postData;
        if (this.state.postingText !== '') {
            var post = this.state.postingText;
            var hashTags = "";
            const req = new UpdatePostRequest();
            const postRew = new Post();
            const content = new Content();

            const metadata = content.getMetadataMap();
            metadata.set("HASH_TAGS", hashTags);

            //https://images.hamro-files.com/_1g1r3M5cP0NzMZZu8ShUBxghKA=/fit-in/1280x720/filters:quality(85):max_bytes(250000)/https://sgp1.digitaloceanspaces.com/everestdb/hamropatro-android/Mandala/community/6c7b4390-48a6-11ea-80e7-0242ac140006
            const contentData = content.getDataMap();
            if (postingImage !== '' && postingImage) {
                postingImage.forEach(function (singleImage, index) {
                    if (isNaN(singleImage))
                        contentData.set("IMAGE" + '_URL_' + new Date().getTime() + index + 1, singleImage);
                });
                contentData.set("TEXT", post);
            } else contentData.set("TEXT", post);
            let toPostToChannel= postDetails.getCategory();
            postRew.setGroupId(postDetails.getGroupId());
            postRew.setContentid(postDetails.getContentid());
            postRew.setPostUrl(postDetails.getPostUrl());
            postRew.setCategory(toPostToChannel);

            if (postingImage !== '' && postingImage)
                postRew.setPostType(Post.PostType.IMAGE_TEXT);
            else postRew.setPostType(Post.PostType.TEXT);
            postRew.setContent(content);
            req.setPost(postRew);
            req.setBusinessId(postDetails.getGroupId());
            // return ;
            updateUsersPost(req).then(function (response) {
                // const postingText = '';
                // const postingImage = '';
                // const imageFile = '';
                // const uploadingURL = '';
                // const disable = false;
                // const multiline = true;
                // const creatingPost = false;
                // const uploadImage = false;
                // const channelName='';
                // const imageCount=[1];
                // self.setState({
                //     creatingPost,channelName, imageCount,uploadImage,
                //     postingText, postingImage, imageFile, uploadingURL, disable, multiline
                // });
                self.handleClose();
                self.sendResponse('edited', true, toPostToChannel, postDetails.getContentid(),'');
            }).catch(function (error) {
                self.setState({
                    disable: false, creatingPost: false, multiline: true
                });
                self.sendResponse('edited', false, toPostToChannel,postDetails.getContentid(), error.message);
            })
        }
    };

    addImage = () => {
        var joined = this.state.imageCount.concat(this.state.nextImageCount++);
        this.setState({imageCount: joined})
    };

    remove = (index) => {
        var array = [...this.state.imageCount];
        if (index > -1) {
            array.splice(index, 1);
            this.setState({imageCount: array});
        }
    };

    render() {
        const {open,postingText, multiline,disable,creatingPost, imageCount,uploadImage} = this.state;
        const {width, classes,postData} = this.props;
        const isMobile = ['xs', 'sm'].includes(width);
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    fullScreen={isMobile}
                    fullWidth
                    maxWidth={'sm'}
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Edit Post
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>
                                <div>
                                    <Paper className={classes.searchBoxBroad}>
                                        <IconButton className={classes.userProfileButton} aria-label="Menu">
                                            <Avatar alt={userInfo().displayName}
                                                    style={{
                                                        backgroundImage: `url('${userInfo().photo_url}')`,
                                                        backgroundRepeat: 'round',
                                                        backgroundColor: 'unset'
                                                    }}
                                                    className={classes.chatBoxAvatar}/>
                                        </IconButton>
                                        <InputBase
                                            className={classes.postContent}
                                            placeholder="Your Text Here"
                                            multiline={multiline}
                                            rows={3}
                                            rowsMin={5}
                                            inputProps={{'aria-label': 'Enter to post content'}}
                                            value={postingText}
                                            onChange={this.postTextChange}
                                        />
                                        {imageCount && imageCount.length ? (
                                            <div className={classes.iconWrapper}>
                                                <IconButton className={classes.floatRight} aria-label="Image"
                                                            title="Post with images"
                                                            size="small"
                                                            color={imageCount.length>0 ? 'secondary' : ''}
                                                            onClick={this.changeUploadSts}
                                                >
                                                    <AddImage/>
                                                </IconButton>
                                            </div>):null}
                                    </Paper>
                                    <div className={classes.clearBoth}></div>
                                    <br/>
                                    <div>
                                        <div>
                                            {uploadImage || (imageCount && imageCount.length)  ? (
                                                <div>
                                                    <div style={{display: 'flex', clear: 'both', overflow: 'auto'}}>
                                                        {imageCount.map((singleImage, index) => (
                                                            <div className={classes.clearBoth} style={{margin: '0px 5px'}}>
                                                                {/*{index === imageCount.length - 1 ? (*/}
                                                                {/*    <IconButton className={classes.floatRight}*/}
                                                                {/*                aria-label="Send"*/}
                                                                {/*                title="remove image"*/}
                                                                {/*                size="small"*/}
                                                                {/*                color={"primary"}*/}
                                                                {/*                style={{*/}
                                                                {/*                    marginBottom: '-19px',*/}
                                                                {/*                    zIndex: 1,*/}
                                                                {/*                    background: '#b71c1c',*/}
                                                                {/*                    color: '#ffffff',*/}
                                                                {/*                    padding: '0px'*/}
                                                                {/*                }}*/}
                                                                {/*                onClick={() => this.remove(index)}>*/}
                                                                {/*        <CloseIcon/>*/}
                                                                {/*    </IconButton>*/}
                                                                {/*) : (<span></span>)}*/}
                                                                <div style={{width:'150px'}}>
                                                                    <img src={singleImage && isNaN(singleImage) ? singleImage : ''} alt='post_images'  style={{width:'100%'}}/>
                                                                </div>
                                                                {/*<Uploader type={"community"} paddingTop={'70%'} width={'150px'}*/}
                                                                {/*          imageURL={singleImage && isNaN(singleImage) ? singleImage : ''}*/}
                                                                {/*          index={index}*/}
                                                                {/*          sendImageURLObtainStatus={this.getImageUploadingURL}/>*/}
                                                            </div>
                                                        ))}
                                                        {/*<div>*/}
                                                        {/*    <div style={{*/}
                                                        {/*        width: '110px',*/}
                                                        {/*        height: '100px',*/}
                                                        {/*        cursor: 'pointer',*/}
                                                        {/*        border: '2px dotted #e5e5e5',*/}
                                                        {/*        textAlign: 'center',*/}
                                                        {/*        margin: '11px'*/}
                                                        {/*    }} onClick={this.addImage}>*/}
                                                        {/*        <div style={{lineHeight: '6'}}>*/}
                                                        {/*            <IconButton aria-label="Send"*/}
                                                        {/*                        title="Add image"*/}
                                                        {/*                        size="small"*/}
                                                        {/*                        color={"primary"}>*/}
                                                        {/*                <AddIcon/>*/}
                                                        {/*            </IconButton>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            ):null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.buttonWrapper}>
                            <Button variant="contained" color="secondary" onClick={this.createPost}
                                    style={{minWidth:'200px'}}
                                    className={classes.postButton} disabled={!postingText || disable || postingText.trim().length === 0}>
                                {creatingPost ? (<span>Saving Changes&nbsp; &nbsp;<CircularProgress
                                    size={12}/> </span>) : 'Save Changes'}
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

EditDialogBox.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles),
    withWidth()
)(EditDialogBox);