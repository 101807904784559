/**
 * @fileoverview gRPC-Web generated client stub for com.hamropatro.everestbackend.account
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!
/*eslint-disable*/


const grpc = {};
grpc.web = require('grpc-web');


var frontend$account$common$types_pb = require('./frontend-account-common-types_pb.js');
const proto = {};
proto.com = {};
proto.com.hamropatro = {};
proto.com.hamropatro.everestbackend = {};
proto.com.hamropatro.everestbackend.account = require('./frontend-business-account-service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient =
    function (hostname, credentials, options) {
        if (!options) options = {};
        options['format'] = 'text';

        /**
         * @private @const {!grpc.web.GrpcWebClientBase} The client
         */
        this.client_ = new grpc.web.GrpcWebClientBase(options);

        /**
         * @private @const {string} The hostname
         */
        this.hostname_ = hostname;

        /**
         * @private @const {?Object} The credentials to be used to connect
         *    to the server
         */
        this.credentials_ = credentials;

        /**
         * @private @const {?Object} Options for the client
         */
        this.options_ = options;
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse>}
 */
const methodDescriptor_BusinessAccountService_CreateBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/CreateBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest,
    proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse>}
 */
const methodInfo_BusinessAccountService_CreateBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.createBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/CreateBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_CreateBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.CreateBusinessAccountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.createBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/CreateBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_CreateBusinessAccount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse>}
 */
const methodDescriptor_BusinessAccountService_UpdateBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/UpdateBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest,
    proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse>}
 */
const methodInfo_BusinessAccountService_UpdateBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.updateBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/UpdateBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_UpdateBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.UpdateBusinessAccountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.updateBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/UpdateBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_UpdateBusinessAccount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse>}
 */
const methodDescriptor_BusinessAccountService_DeleteBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest,
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse>}
 */
const methodInfo_BusinessAccountService_DeleteBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.deleteBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_DeleteBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.deleteBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_DeleteBusinessAccount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest,
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse>}
 */
const methodDescriptor_BusinessAccountService_GetBusinessAccountProfileAsUser = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessAccountProfileAsUser',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest,
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest,
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse>}
 */
const methodInfo_BusinessAccountService_GetBusinessAccountProfileAsUser = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.getBusinessAccountProfileAsUser =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessAccountProfileAsUser',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetBusinessAccountProfileAsUser,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.getBusinessAccountProfileAsUser =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessAccountProfileAsUser',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetBusinessAccountProfileAsUser);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest,
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse>}
 */
const methodDescriptor_BusinessAccountService_GetBusinessAccountProfileAsPublic = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessAccountProfileAsPublic',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest,
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest,
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse>}
 */
const methodInfo_BusinessAccountService_GetBusinessAccountProfileAsPublic = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.getBusinessAccountProfileAsPublic =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessAccountProfileAsPublic',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetBusinessAccountProfileAsPublic,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsPublicRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsUserResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.getBusinessAccountProfileAsPublic =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessAccountProfileAsPublic',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetBusinessAccountProfileAsPublic);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse>}
 */
const methodDescriptor_BusinessAccountService_GetBusinessAccountProfileAsBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessAccountProfileAsBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest,
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse>}
 */
const methodInfo_BusinessAccountService_GetBusinessAccountProfileAsBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.getBusinessAccountProfileAsBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessAccountProfileAsBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetBusinessAccountProfileAsBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.GetBusinessAccountProfileAsBusinessAccountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.getBusinessAccountProfileAsBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessAccountProfileAsBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetBusinessAccountProfileAsBusinessAccount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest,
 *   !proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse>}
 */
const methodDescriptor_BusinessAccountService_ListBusinessAccountsAsUser = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/ListBusinessAccountsAsUser',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest,
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest,
 *   !proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse>}
 */
const methodInfo_BusinessAccountService_ListBusinessAccountsAsUser = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.listBusinessAccountsAsUser =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/ListBusinessAccountsAsUser',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_ListBusinessAccountsAsUser,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.ListBusinessAccountsAsUserResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.listBusinessAccountsAsUser =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/ListBusinessAccountsAsUser',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_ListBusinessAccountsAsUser);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest,
 *   !proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse>}
 */
const methodDescriptor_BusinessAccountService_GenerateBusinessAccountShareLink = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/GenerateBusinessAccountShareLink',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest,
    proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest,
 *   !proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse>}
 */
const methodInfo_BusinessAccountService_GenerateBusinessAccountShareLink = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.generateBusinessAccountShareLink =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GenerateBusinessAccountShareLink',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GenerateBusinessAccountShareLink,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.GenerateBusinessAccountShareLinkResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.generateBusinessAccountShareLink =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GenerateBusinessAccountShareLink',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GenerateBusinessAccountShareLink);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse>}
 */
const methodDescriptor_BusinessAccountService_InviteUsersToBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/InviteUsersToBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest,
    proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse>}
 */
const methodInfo_BusinessAccountService_InviteUsersToBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.inviteUsersToBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/InviteUsersToBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_InviteUsersToBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.InviteUsersToBusinessAccountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.inviteUsersToBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/InviteUsersToBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_InviteUsersToBusinessAccount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse>}
 */
const methodDescriptor_BusinessAccountService_AcceptInvitationToBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/AcceptInvitationToBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest,
    proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse>}
 */
const methodInfo_BusinessAccountService_AcceptInvitationToBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.acceptInvitationToBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/AcceptInvitationToBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_AcceptInvitationToBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.AcceptInvitationToBusinessAccountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.acceptInvitationToBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/AcceptInvitationToBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_AcceptInvitationToBusinessAccount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest,
 *   !proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse>}
 */
const methodDescriptor_BusinessAccountService_ListBusinessAccountMembers = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/ListBusinessAccountMembers',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest,
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest,
 *   !proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse>}
 */
const methodInfo_BusinessAccountService_ListBusinessAccountMembers = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.listBusinessAccountMembers =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/ListBusinessAccountMembers',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_ListBusinessAccountMembers,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.ListBusinessAccountMembersResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.listBusinessAccountMembers =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/ListBusinessAccountMembers',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_ListBusinessAccountMembers);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest,
 *   !proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse>}
 */
const methodDescriptor_BusinessAccountService_ChangeBusinessAccountMemberRole = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/ChangeBusinessAccountMemberRole',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest,
    proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest,
 *   !proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse>}
 */
const methodInfo_BusinessAccountService_ChangeBusinessAccountMemberRole = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.changeBusinessAccountMemberRole =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/ChangeBusinessAccountMemberRole',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_ChangeBusinessAccountMemberRole,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.ChangeBusinessAccountMemberRoleResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.changeBusinessAccountMemberRole =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/ChangeBusinessAccountMemberRole',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_ChangeBusinessAccountMemberRole);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest,
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse>}
 */
const methodDescriptor_BusinessAccountService_DeleteBusinessAccountMember = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteBusinessAccountMember',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest,
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest,
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse>}
 */
const methodInfo_BusinessAccountService_DeleteBusinessAccountMember = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.deleteBusinessAccountMember =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteBusinessAccountMember',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_DeleteBusinessAccountMember,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.deleteBusinessAccountMember =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteBusinessAccountMember',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_DeleteBusinessAccountMember);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest,
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse>}
 */
const methodDescriptor_BusinessAccountService_DeleteBusinessAccountMemberInvitation = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteBusinessAccountMemberInvitation',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest,
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest,
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse>}
 */
const methodInfo_BusinessAccountService_DeleteBusinessAccountMemberInvitation = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.deleteBusinessAccountMemberInvitation =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteBusinessAccountMemberInvitation',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_DeleteBusinessAccountMemberInvitation,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountMemberInvitationResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.deleteBusinessAccountMemberInvitation =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteBusinessAccountMemberInvitation',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_DeleteBusinessAccountMemberInvitation);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse>}
 */
const methodDescriptor_BusinessAccountService_GetBusinessOwnerOfDomain = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessOwnerOfDomain',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest,
    proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse>}
 */
const methodInfo_BusinessAccountService_GetBusinessOwnerOfDomain = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.getBusinessOwnerOfDomain =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessOwnerOfDomain',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetBusinessOwnerOfDomain,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.GetDomainToBusinessAccountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.getBusinessOwnerOfDomain =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessOwnerOfDomain',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetBusinessOwnerOfDomain);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse>}
 */
const methodDescriptor_BusinessAccountService_AddDomainToBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/AddDomainToBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest,
    proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse>}
 */
const methodInfo_BusinessAccountService_AddDomainToBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.addDomainToBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/AddDomainToBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_AddDomainToBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.CreateDomainToBusinessAccountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.addDomainToBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/AddDomainToBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_AddDomainToBusinessAccount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse>}
 */
const methodDescriptor_BusinessAccountService_DeleteDomainToBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteDomainToBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest,
    proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse>}
 */
const methodInfo_BusinessAccountService_DeleteDomainToBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.deleteDomainToBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteDomainToBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_DeleteDomainToBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.DeleteDomainToBusinessAccountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.deleteDomainToBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteDomainToBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_DeleteDomainToBusinessAccount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest,
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse>}
 */
const methodDescriptor_BusinessAccountService_GetBusinessAccountSharedInvitationLink = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessAccountSharedInvitationLink',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest,
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest,
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse>}
 */
const methodInfo_BusinessAccountService_GetBusinessAccountSharedInvitationLink = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.getBusinessAccountSharedInvitationLink =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessAccountSharedInvitationLink',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetBusinessAccountSharedInvitationLink,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationLinkResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.getBusinessAccountSharedInvitationLink =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetBusinessAccountSharedInvitationLink',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetBusinessAccountSharedInvitationLink);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse>}
 */
const methodDescriptor_BusinessAccountService_VerifyBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/VerifyBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest,
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse>}
 */
const methodInfo_BusinessAccountService_VerifyBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.verifyBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/VerifyBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_VerifyBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.verifyBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/VerifyBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_VerifyBusinessAccount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse>}
 */
const methodDescriptor_BusinessAccountService_UnVerifyBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/UnVerifyBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest,
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse>}
 */
const methodInfo_BusinessAccountService_UnVerifyBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.unVerifyBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/UnVerifyBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_UnVerifyBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.VerifyBusinessAccountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.unVerifyBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/UnVerifyBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_UnVerifyBusinessAccount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse>}
 */
const methodDescriptor_BusinessAccountService_SuspendBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/SuspendBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest,
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse>}
 */
const methodInfo_BusinessAccountService_SuspendBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.suspendBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/SuspendBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_SuspendBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.suspendBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/SuspendBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_SuspendBusinessAccount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse>}
 */
const methodDescriptor_BusinessAccountService_UnSuspendBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/UnSuspendBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest,
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest,
 *   !proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse>}
 */
const methodInfo_BusinessAccountService_UnSuspendBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.unSuspendBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/UnSuspendBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_UnSuspendBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.SuspendBusinessAccountResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.unSuspendBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/UnSuspendBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_UnSuspendBusinessAccount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest,
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse>}
 */
const methodDescriptor_BusinessAccountService_ListBusinessAccountInvitation = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/ListBusinessAccountInvitation',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest,
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest,
 *   !proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse>}
 */
const methodInfo_BusinessAccountService_ListBusinessAccountInvitation = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.listBusinessAccountInvitation =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/ListBusinessAccountInvitation',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_ListBusinessAccountInvitation,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.GetBusinessAccountSharedInvitationResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.listBusinessAccountInvitation =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/ListBusinessAccountInvitation',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_ListBusinessAccountInvitation);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest,
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse>}
 */
const methodDescriptor_BusinessAccountService_DeleteBusinessAccountInvitation = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteBusinessAccountInvitation',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest,
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest,
 *   !proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse>}
 */
const methodInfo_BusinessAccountService_DeleteBusinessAccountInvitation = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.deleteBusinessAccountInvitation =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteBusinessAccountInvitation',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_DeleteBusinessAccountInvitation,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.DeleteBusinessAccountInvitationResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.deleteBusinessAccountInvitation =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/DeleteBusinessAccountInvitation',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_DeleteBusinessAccountInvitation);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.BusinessAccount,
 *   !proto.com.hamropatro.everestbackend.account.BusinessAccount>}
 */
const methodDescriptor_BusinessAccountService_GetAllBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/GetAllBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.BusinessAccount,
    proto.com.hamropatro.everestbackend.account.BusinessAccount,
    /** @param {!proto.com.hamropatro.everestbackend.account.BusinessAccount} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.BusinessAccount.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.BusinessAccount,
 *   !proto.com.hamropatro.everestbackend.account.BusinessAccount>}
 */
const methodInfo_BusinessAccountService_GetAllBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.BusinessAccount,
    /** @param {!proto.com.hamropatro.everestbackend.account.BusinessAccount} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.BusinessAccount.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccount} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.BusinessAccount)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.BusinessAccount>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.getAllBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetAllBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetAllBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccount} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.BusinessAccount>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.getAllBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetAllBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetAllBusinessAccount);
    };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest,
 *   !proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse>}
 */
const methodDescriptor_BusinessAccountService_GetAllDomainsOfBusinessAccount = new grpc.web.MethodDescriptor(
    '/com.hamropatro.everestbackend.account.BusinessAccountService/GetAllDomainsOfBusinessAccount',
    grpc.web.MethodType.UNARY,
    proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest,
    proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest,
 *   !proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse>}
 */
const methodInfo_BusinessAccountService_GetAllDomainsOfBusinessAccount = new grpc.web.AbstractClientBase.MethodInfo(
    proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse,
    /** @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest} request */
    function (request) {
        return request.serializeBinary();
    },
    proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse.deserializeBinary
);


/**
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServiceClient.prototype.getAllDomainsOfBusinessAccount =
    function (request, metadata, callback) {
        return this.client_.rpcCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetAllDomainsOfBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetAllDomainsOfBusinessAccount,
            callback);
    };


/**
 * @param {!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.hamropatro.everestbackend.account.BusinessAccountDomainsResponse>}
 *     A native promise that resolves to the response
 */
proto.com.hamropatro.everestbackend.account.BusinessAccountServicePromiseClient.prototype.getAllDomainsOfBusinessAccount =
    function (request, metadata) {
        return this.client_.unaryCall(this.hostname_ +
            '/com.hamropatro.everestbackend.account.BusinessAccountService/GetAllDomainsOfBusinessAccount',
            request,
            metadata || {},
            methodDescriptor_BusinessAccountService_GetAllDomainsOfBusinessAccount);
    };


module.exports = proto.com.hamropatro.everestbackend.account;

