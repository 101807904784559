import {black, white} from '../common/colors';

let themeMode = localStorage.getItem('mode') ? localStorage.getItem('mode') : '' ;

let palette;

if(themeMode === 'dark') {
    palette = {
        type: "dark",
        common: {
            black,
            white,
            neutral: '#E4E7EB',
            muted: '#9EA0A4'
        },
        primary: {
            contrastText: white,
            main: 'rgb(233, 111, 111)',
            light: '#F6F9FD',
            dark: '#0B48A0'
        },
        secondary: {
            contrastText: white,
            main: '#0077e0',
            light: '',
            dark: '#37248F'
        },
        success: {
            contrastText: white,
            main: '#45B880',
            light: '#F1FAF5',
            dark: '#00783E'
        },
        info: {
            contrastText: white,
            main: '#1070CA',
            light: '#F1FBFC',
            dark: '#007489'
        },
        warning: {
            contrastText: white,
            main: '#FFB822',
            light: '#FDF8F3',
            dark: '#95591E'
        },
        danger: {
            contrastText: white,
            main: '#ED4740',
            light: '#FEF6F6',
            dark: '#BF0E08'
        },
        text: {
            primary: 'rgb(216, 213, 208)',
            secondary: 'rgb(214,211,205)',
            disabled: '#A6B1BB'
        },
        background: {
            default: 'rgb(24, 26, 27)',
            dark: '#172B4D',
            paper: 'rgb(24, 26, 27)'
        },
        border: 'rgb(51, 60, 71)',
        divider: 'rgb(51, 60, 71)',
        // overrides:{
        //     body:{
        //     '&::-webkit-scrollbar':{background:'#000000'}}}

    };
}else {
    palette = {
        common: {
            black,
            white,
            neutral: '#E4E7EB',
            muted: '#9EA0A4'
        },
        primary: {
            contrastText: white,
            main: '#b71c1c',
            light: '#F6F9FD',
            dark: '#0B48A0'
        },
        secondary: {
            contrastText: white,
            main: '#0077e0',
            light: '',
            dark: '#37248F'
        },
        success: {
            contrastText: white,
            main: '#45B880',
            light: '#F1FAF5',
            dark: '#00783E'
        },
        info: {
            contrastText: white,
            main: '#1070CA',
            light: '#F1FBFC',
            dark: '#007489'
        },
        warning: {
            contrastText: white,
            main: '#FFB822',
            light: '#FDF8F3',
            dark: '#95591E'
        },
        danger: {
            contrastText: white,
            main: '#ED4740',
            light: '#FEF6F6',
            dark: '#BF0E08'
        },
        text: {
            primary: '#223137',
            secondary: '#223136',
            disabled: '#A6B1BB'
        },
        background: {
            default: '#ffffff',
            sideBar:'#f4f6f7',
            dark: '#172B4D',
            paper: white
        },
        border: '#DFE3E8',
        divider: '#DFE3E8'
    };
}

export default palette;
