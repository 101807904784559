import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import {Typography, withStyles, withWidth} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from "./styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {
    AddPostRequest,
    Content,
    GetGroupPostByCategoryRequest,
    GetPostDetailRequest,
    GetPostRequest,
    GetPostsRequest,
    Post,
    ReactionDetail
} from "../../generated/frontend-post-service_pb";
import {
    createPost,
    getFeedPosts,
    getPostById,
    getPostDetails,
    getPostsByCategory
} from "../../grpcRequests/posts-request";
import IconButton from "@material-ui/core/IconButton";
import {
    Add as AddIcon,
    AddPhotoAlternate as AddImage,
    CloseOutlined as CloseIcon,
    Message as MessageIcon,
    Send as SendIcon,
    ThumbUp,
    ThumbUpAltOutlined as ThumbOutline
} from "@material-ui/icons";
import {AccountInfo, ProfileImage, Reaction, ReactionType} from "../../generated/frontend-social-common-types_pb";
import {ReactOnContentRequest} from "../../generated/frontend-reaction-service_pb";
import {likeUnlikePost} from "../../grpcRequests/reaction-request";
import GetUserComments from "../CommentBox/GetUserComments";
import Paper from "@material-ui/core/Paper";
import {checkIfLogin, isAdmin, isLogin, userInfo, userWhoCanPost} from "../../configurations/global-config";
import InputBase from "@material-ui/core/InputBase";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import UserPostMenus from "../UserPostMenus";
import usedString from "../../utils/mapper/enumToStringMapper";
import SnackBar from "../SnackBar/SnackBar";
import {get_date_obj, timeEvent, timeRemaining} from "../../utils/converter/dateConverter";
import Uploader from "../ImageUpload/Uploader";
import {uploadImageToDigitalOcean} from "../ImageUpload/upload";
import {PostLoader} from "../Skeleton/EventsSket";
import PostLikedUsers from "../Dialogs/PostLikedUsers";
import {eventAnalyst} from "../../configurations/config";
import {Link} from "react-router-dom";
import Images from "../MultipleImages";
import {getPostClass, removeLastSpace} from "../../utils/converter/common";
import LoginRequiredDialog from "../Dialogs/LoginRequiredDialog";
import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton} from "react-share";
import compose from "recompose/compose";
import Comments from "../../pages/Groups/component/Comments";
import {shortenString} from "../../utils/converter/shortenString";
import RenderText from "../../pages/Groups/component/RenderText";

const allReactions = [];
const disableLike = [];
const userLiked = [];
const likeCount = [];
const likedBy = [];
const usrComments = [];
const postedImages = [];
const resizedImages = [];
let shouldLoadMore = false;
const defaultPageSize = 5;

class GetPostedContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            postingText: '',
            postingImage: null,
            isLoadingAdminPost: true,
            groupId: this.props.groupId, //Required
            userRole: this.props.userRole, //Required
            groupType: this.props.groupType, //Required
            errorGettingPosts: null,
            adminPosts: [],
            isFetchingUserReaction: true,
            isCommented: false,
            commentedId: null,
            creatingPost: false,
            disable: false,
            multiline: true,
            addMe: 0,
            openShowUsers: false,

            //Snackbar initial State
            snackOpen: false,
            snackMsg: null,
            snackColor: null,
            snackTimeOut: null,

            //Images from childUploader
            imageFile: [],
            uploadingURL: [],
            imageCount: [1],
            nextImageCount: 2,

            cursor: '',
            previousCursor: '',
            loadingMore: false,

            isViewed: [],
            viewedId: null,

            //Login
            openLoginDialog: false,
            openJoinGroupDialog: false,
        };

        // Binds our scroll event handler
        window.onscroll = () => {
            const {
                state: {
                    loadingMore,
                    cursor,
                    previousCursor,
                    adminPosts
                },
            } = this;

            if (loadingMore) return;
            let lastLi = document.querySelector("#postListItem");
            if (lastLi) {
                let lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
                let pageOffset = window.pageYOffset + window.innerHeight;
                if ((pageOffset > lastLiOffset - 1000) && cursor && (previousCursor !== cursor)) {
                    this.loadMore(this.props.channelName, cursor, defaultPageSize, adminPosts, cursor)
                }
            }
        };
    }


    componentDidMount() {
        this.setState({
            previousCursor:'',
            cursor:'',
            channelName:this.props.channelName
        });
        shouldLoadMore = false;
        this.fetchByPostCategory(this.props.channelName, '', 15, [], '');
    }

    getStatusFromChild = (action, status, postID) => {
        if (action === 'view') {
            usrComments[postID] = [];
            this.setState({isViewed: {...this.state.isViewed, [postID]: status}, viewedId: postID});
        }else if (action === 'delete') {
            let userReactions= new ReactionDetail();
            userReactions.setTotalComments(allReactions[postID].getTotalComments()-1);
            allReactions[postID]=userReactions;
            this.setState({addMe: 0});
        } else {
            usrComments[postID] = [];
            this.setState({
                isCommented: status,
                commentedId: postID,
                isViewed: {...this.state.isViewed, [postID]: status}
            });
            if (this.state.isCommented) {
                this.fetchPostReactions(this.state.commentedId, false);
            }
        }
    };

    getImageUploadingURL = (url, file, index) => {
        if (url && file) {
            this.setState({
                imageFile: {...this.state.imageFile, [index]: file},
                uploadingURL: {...this.state.uploadingURL, [index]: url}
            })
        }
    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    getUserResponseFromUserPost = (actionName, isActionCompleted,key,indexVal) => {
        this.setState({snackOpen: false});
        let self = this;
        if (isActionCompleted && actionName === 'delete') {
            eventAnalyst('community_posts', 'community_posts_delete', self.state.groupId + '--' + self.props.channelName + '-posts-deleted', self.state.groupId + '-post-delete-success');
            self.snackbar(true, 'Post deleted Successfully', 'success', 5000);
            const adminPosts = this.state.adminPosts.filter(i => i.getContentid() !== key);
            this.setState({adminPosts});
        }
        else if (isActionCompleted && actionName === 'edited') {
            self.snackbar(true, 'Post edited Successfully', 'success', 5000);
            // this.fetchByPostCategory(this.props.channelName, '', defaultPageSize, [], '');
            //remove previous ID from list
            const adminPosts = this.state.adminPosts.filter(i => i.getContentid() !== key);
            this.setState({adminPosts});

            //add new edited item to lists
            const req = new GetPostRequest();
            req.setGroupId(this.state.groupId);
            req.setPostId(key);
            getPostById(req, 3).then(function (response) {
                let currentPosts = response.getPost();
                resizedImages[key]=[];
                postedImages[key]=[];
                let imageList = [];
                currentPosts.getContent().getDataMap().forEach(function (value, key) {
                    if (key.includes('IMAGE_URL_') || key.includes('IMAGE') || key.includes('IMAGE_')) {
                        imageList.push(value);
                    }
                });
                let resizedImageList = [];

                currentPosts.getContent().getMetadataMap().forEach(function (value, key) {
                    if (key.includes('RESIZED_IMAGE')) {
                        resizedImageList.push(value);
                    }
                });
                postedImages[key] = imageList;
                resizedImages[key] = resizedImageList.length ? resizedImageList : imageList;

                // self.state.adminPosts.splice(2, 0, currentPosts);
                // self.setState({ data: update(self.state.data,{rowArray : {$set : self.state.adminPosts}} ) });

                let userPost=self.state.adminPosts;
                // userPost.push(currentPosts);
                userPost.splice(indexVal, 0, currentPosts);
                self.setState({
                    adminPosts: userPost
                });


                // self.state.adminPosts.map(obj => userPost.find(o => o.getContentid() === obj.getContentid()) || obj);

            }).catch(function (error)  {
            })
        }
        else {
            eventAnalyst('community_posts', 'community_posts_delete', self.state.groupId + '--' + self.props.channelName + '-posts-delete-fail', self.state.groupId + '-post-delete-fail');
            self.snackbar(true, 'Error while deleting post', 'error', 5000);
        }
    };

    postTextChange = (event) => {
        this.setState({postingText: event.target.value});
    };

    handleKeyPress = (event) => {
        if (!isLogin()) {
            this.openLoginDialog()
        }
        else if(!this.canInteractInGroup()){
            this.openJoinDialog()
        }
    };

    loadMore = (category, cursor, pageSize, previousPosts, prevCursor) => {
        this.setState({loadingMore: true});
        if (previousPosts && cursor) {
            this.fetchByPostCategory(category, cursor, pageSize, previousPosts, prevCursor)
        }
    };

    uploadToDigital(imageFile) {
        let uploadedImages = [];
        let self = this;
        var length = 0;
        var itemsProcessed = 0;
        for (var key in imageFile) {
            if (imageFile.hasOwnProperty(key)) {
                ++length;
            }
        }
        return new Promise(function (resolve, reject) {
            Object.keys(imageFile).forEach((file, index) => {
                uploadImageToDigitalOcean(self.state.imageFile[index], self.state.uploadingURL[index]).then(function (response) {
                    uploadedImages.push(response.image);
                    itemsProcessed++;
                    if (itemsProcessed === length) {
                        resolve(uploadedImages)
                    }
                }).catch(function (error) {

                })
            });
        });
    }

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    };

    createPost = () => {
        if (!isLogin()) {
            this.openLoginDialog()
        }
        else if(!this.canInteractInGroup()){
            this.openJoinDialog()
        }
        else {
            this.setState({snackOpen: false, disable: true, creatingPost: true});
            let self = this;
            if (!this.isEmpty(this.state.imageFile) && !this.isEmpty(this.state.uploadingURL) && this.state.imageCount.length >= 1) {
                this.uploadToDigital(this.state.imageFile).then(function (response) {
                    self.postNewPost(response);
                })
            } else this.postNewPost('');
        }
    };

    postNewPost = (postingImage) => {
        let self = this;
        if (this.state.postingText !== '') {
            var post = this.state.postingText;
            var hashTags = "";
            const req = new AddPostRequest();
            const postRew = new Post();
            const content = new Content();

            const metadata = content.getMetadataMap();
            metadata.set("HASH_TAGS", hashTags);

            const contentData = content.getDataMap();
            if (postingImage !== '' && postingImage) {
                postingImage.forEach(function (singleImage, index) {
                    contentData.set("IMAGE" + '_URL_' + new Date().getTime() + index + 1, singleImage);
                });
                contentData.set("TEXT", post);
            } else contentData.set("TEXT", post);

            postRew.setGroupId(this.state.groupId);
            postRew.setCategory(this.props.channelName);

            if (postingImage !== '' && postingImage)
                postRew.setPostType(Post.PostType.IMAGE_TEXT);
            else postRew.setPostType(Post.PostType.TEXT);
            postRew.setContent(content);
            req.setPost(postRew);
            req.setBusinessId(this.state.groupId);
            let userPostingImage=postingImage;
            createPost(req,userPostingImage).then(function (response) {
                eventAnalyst('community_posts', 'community_posts_create', self.state.groupId + '--' + self.props.channelName + '-posts-created', self.state.groupId + '-post-create-success');


                let userImg = new ProfileImage();
                userImg.setImageUrl(userInfo().photo_url);
                let userData = new AccountInfo();
                userData.setAccountName(userInfo().displayName);
                userData.setAccountId(userInfo().user_id);
                userData.setAccountImage(userImg);

                postRew.setPostOwner(userData);
                postRew.setContentid(response.getPostId());

                if (userPostingImage !== '' && userPostingImage) {
                    resizedImages[response.getPostId()] = userPostingImage;
                    postedImages[response.getPostId()] = userPostingImage;
                }
                let userPost=[];
                userPost.push(postRew);
                self.setState({
                    adminPosts: userPost.concat(self.state.adminPosts)
                });
                const postingText = '';
                const postingImage = '';
                const imageFile = '';
                const uploadingURL = '';
                const disable = false;
                const multiline = true;
                const creatingPost = false;
                self.setState({
                    creatingPost,
                    postingText, postingImage, imageFile, uploadingURL, disable, multiline
                });
                self.fetchPostReactions(response.getPostId(),false);
                self.snackbar(true, 'Post Created', 'success', 5000);
                self.handleClose();
            }).catch(function (error) {
                self.setState({
                    disable: false, creatingPost: false, multiline: true
                });
                self.snackbar(true, 'Something Went Wrong', 'error', 5000);
            })
        }
    };
    fetchByPostCategory = (category, cursor, pageSize, previousPosts, prevCursor) => {
        shouldLoadMore=false;
        let self = this;
        if (previousPosts.length < 0) {
            self.setState({
                adminPosts: previousPosts,
                isLoadingAdminPost: true,
            });
        }
        let req;
        let reqMethod;
        if(category==='_feed'){
            req= new GetPostsRequest();
            req.setGroupId(this.state.groupId);
            req.setCursor(cursor);
            req.setPageSize(pageSize);
            reqMethod = getFeedPosts(req, 4);
        }else{
            req = new GetGroupPostByCategoryRequest();
            req.setCategory(category);
            req.setGroupId(this.state.groupId);
            req.setCursor(cursor);
            req.setPageSize(pageSize);
            reqMethod = getPostsByCategory(req, 4);
        }

        reqMethod.then(function (response) {
            let postLists,cursorNew;
            let allPostFormFeed=[];
            if(category==='_feed'){
                response.getPostDetailsList().forEach(function (postItem) {
                    allPostFormFeed.push(postItem.getPost())
                });
                postLists= allPostFormFeed;
                cursorNew = response.getNextPageCursor();
            }else{
                postLists=response.getPostList();
                cursorNew = response.getNextPageToken();
            }
            eventAnalyst('community_posts', 'community_posts_load', self.state.groupId + '-posts-loaded', self.state.groupId + '-post-load-success');

            for (let i = 0; i < postLists.length; i++) {
                let newFetchedPost = [];
                newFetchedPost.push(postLists[i]);
                self.setState({
                    adminPosts: self.state.adminPosts.concat(newFetchedPost),
                });

                let newPosts = postLists[i];

                self.setState({
                    isViewed: {...self.state.isViewed, [newPosts.getContentid()]: false}
                });
                let imageList = [];
                newPosts.getContent().getDataMap().forEach(function (value, key) {
                    if (key.includes('IMAGE_URL_') || key.includes('IMAGE') || key.includes('IMAGE_')) {
                        imageList.push(value);

                        postedImages[newPosts.getContentid()] = imageList;
                    }
                });
                let resizedImageList = [];

                newPosts.getContent().getMetadataMap().forEach(function (value, key) {
                    if (key.includes('RESIZED_IMAGE')) {
                        resizedImageList.push(value);
                        resizedImages[newPosts.getContentid()] = resizedImageList;
                    }
                });
                self.fetchPostReactions(newPosts.getContentid(), true);
            }
            const isLoadingAdminPost = false;
            self.setState({
                category:category,
                cursor: cursorNew,
                previousCursor: prevCursor,
                // adminPosts: posts,
                isLoadingAdminPost, loadingMore: false
            });
            shouldLoadMore = true;
        }).catch(function (error) {
            eventAnalyst('community_posts', 'community_posts_load', self.state.groupId + '-posts-fail', self.state.groupId + '-post-load-fail');
            const errorGettingPosts = error.message;
            const isLoadingAdminPost = false;
            shouldLoadMore = false;
            self.setState({
                isLoadingAdminPost,
                errorGettingPosts,
                loadingMore: false
            });
        })
    };

    fetchPostReactions = (postID, fetchComment) => {
        let self = this;
        self.setState({
            isFetchingUserReaction: true
        });
        const req = new GetPostDetailRequest();

        const reactionObj = new Reaction();
        reactionObj.setPostUri(postID);
        reactionObj.setReactionType(ReactionType.LIKE);

        req.setReaction(reactionObj);
        req.setPageSize(3);
        req.setPageToken();

        getPostDetails(req, 3).then(function (response) {
            const reactions = response.getReactionDetail();
            const doUserLike = response.getLiked();
            const likedUser = response.getListofreactedusersList();
            let userCommentsList;
            if (fetchComment)
                userCommentsList = response.getCommentDetailList();
            else
                userCommentsList = [];

            allReactions[postID] = reactions;
            userLiked[postID] = doUserLike;
            likeCount[postID] = 0;
            likedBy[postID] = likedUser;
            usrComments[postID] = userCommentsList;
            const isFetchingUserReaction = false;
            self.setState({
                isFetchingUserReaction
            });

        }).catch(function (error) {
        })
    };

    likePost = (postID) => {
        if (!isLogin())
            this.openLoginDialog();
        else if(!this.canInteractInGroup()){
            this.openJoinDialog()
        }
        else {
            let self = this;
            disableLike[postID] = true;
            const req = new ReactOnContentRequest();
            const reactionObj = new Reaction();
            reactionObj.setPostUri(postID);
            self.setState({addMe: 0});
            if (userLiked[postID]) {
                userLiked[postID] = false;
                likeCount[postID] -= 1;
                reactionObj.setReactionType(ReactionType.UNLIKE);
            } else {
                userLiked[postID] = true;
                likeCount[postID] += 1;
                reactionObj.setReactionType(ReactionType.LIKE);
            }
            req.setReaction(reactionObj);
            likeUnlikePost(req).then(function (response) {
                disableLike[postID] = false;
                if (usrComments[postID].length <= 3 && self.state.isViewed[postID])
                    self.fetchPostReactions(postID, false);
                else
                    self.fetchPostReactions(postID, true);
            }).catch(function (error) {
                disableLike[postID] = false;
            })
        }
    };


    handleClickOpen = () => {
        if (!isLogin()) {
            this.openLoginDialog()
        }
        else if(!this.canInteractInGroup()){
            this.openJoinDialog()
        }
        else
            this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false, imageCount: [1]});
    };

    getResFrmChild = (status) => {
        this.setState({openShowUsers: false});
    };

    openLikedByDialogs = (postId) => {
        this.setState({openShowUsers: true, sendPostId: postId});
    };

    addImage = () => {
        var joined = this.state.imageCount.concat(this.state.nextImageCount++);
        this.setState({imageCount: joined})
    };

    remove = (index) => {
        var array = [...this.state.imageCount];
        if (index > -1) {
            array.splice(index, 1);
            this.setState({imageCount: array});
        }
    };

    getLoginDialogRes = (clickedButton, key) => {
        this.setState({openLoginDialog: false});
        if (clickedButton === "login") {
            checkIfLogin(window.location.pathname);
        }
    };

    canInteractInGroup= () => {
        let userRole = this.props.userRole;
        let groupRoleStatus = this.props.userRoleStatus;
        return userWhoCanPost(userRole, groupRoleStatus);
    };

    getJoinGroupDialogRes = (clickedButton, key) => {
        this.setState({openJoinGroupDialog: false});
        if (clickedButton === "view community") {
            window.location.pathname = '/c/' + this.state.groupId
        }
    };

    openLoginDialog = () => {
        this.setState({openLoginDialog: true});
    };

    openJoinDialog = () => {
        this.setState({openJoinGroupDialog: true});
    };

    render() {
        const {groupType, classes, channelName, groupMembers, width, groupName, userRoleStatus} = this.props;
        const {open, adminPosts, postingText, addMe, userRole, isLoadingAdminPost, groupId, disable, creatingPost, multiline, openShowUsers, sendPostId, loadingMore, isViewed, imageCount, openLoginDialog, openJoinGroupDialog, previousCursor, cursor, showMore} = this.state;
        const rootGroupsURl = '/c/' + groupId;

        const isMobile = ['xs', 'sm', 'md'].includes(width);

        return (
            <div style={{minHeight: '450px'}}>
                {channelName !== '_feed' && (userRole === usedString.ADMIN || channelName !== '_admin') ? (
                    <div>
                        <Paper className={classes.searchBox}>
                            <IconButton className={classes.floatLeft} aria-label="Menu">
                                <Avatar alt={userInfo().displayName}
                                        style={{
                                            backgroundImage: `url('${userInfo().photo_url}')`,
                                            backgroundRepeat: 'round'
                                        }}
                                        className={classes.chatBoxAvatar}/>
                            </IconButton>
                            <InputBase
                                className={classes.postContent}
                                placeholder="Your Text Here"
                                inputProps={{'aria-label': 'Enter to post content'}}
                                value={postingText}
                                multiline={multiline}
                                onKeyPress={this.handleKeyPress.bind(this)}
                                onChange={this.postTextChange}
                            />
                            <div className={classes.iconWrapper}>
                                <IconButton disabled={!postingText || disable || postingText.trim().length === 0}
                                            className={classes.floatRight}
                                            aria-label="Send"
                                            size="small"
                                            onClick={() => this.createPost()}>
                                    <SendIcon/>
                                </IconButton>
                                <IconButton className={classes.floatRight} aria-label="Image"
                                            title="Post with images"
                                            size="small"
                                            onClick={this.handleClickOpen}>
                                    <AddImage/>
                                </IconButton>

                                {/*    Dialogs Open*/}
                                <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth disableBackdropClick
                                        disableEscapeKeyDown>
                                    <DialogTitle id="form-dialog-title">Create Post</DialogTitle>
                                    <DialogContent>
                                        <Paper className={classes.searchBoxBroad}>
                                            <IconButton className={classes.floatLeft} aria-label="Menu">
                                                <Avatar alt={userInfo().displayName}
                                                        style={{
                                                            backgroundImage: `url('${userInfo().photo_url}')`,
                                                            backgroundRepeat: 'round'
                                                        }}
                                                        className={classes.chatBoxAvatar}/>
                                            </IconButton>
                                            <InputBase
                                                autoFocus
                                                className={classes.postContent}
                                                placeholder="Your Text Here"
                                                multiline={multiline}
                                                rows="5"
                                                inputProps={{'aria-label': 'Enter to post content'}}
                                                value={postingText}
                                                onKeyPress={this.handleKeyPress.bind(this)}
                                                onChange={this.postTextChange}
                                            />
                                        </Paper>
                                        <div className={classes.clearBoth}></div>
                                        <Typography className={classes.userAction}>
                                            <div><b>Upload Image</b></div>
                                        </Typography>
                                        <div style={{display: 'flex', clear: 'both', overflow: 'auto'}}>
                                            {imageCount.map((group, index) => (
                                                <div className={classes.clearBoth} style={{margin: '0px 5px'}}>
                                                    {index === imageCount.length - 1 ? (
                                                        <IconButton className={classes.floatRight}
                                                                    aria-label="Send"
                                                                    title="remove image"
                                                                    size="small"
                                                                    color={"primary"}
                                                                    style={{
                                                                        marginBottom: '-19px',
                                                                        zIndex: 1,
                                                                        background: '#b71c1c',
                                                                        color: '#ffffff',
                                                                        padding: '0px'
                                                                    }}
                                                                    onClick={() => this.remove(index)}>
                                                            <CloseIcon/>
                                                        </IconButton>
                                                    ) : (<span></span>)}
                                                    <Uploader type={"community"} paddingTop={'70%'} width={'150px'}
                                                              index={index}
                                                              sendImageURLObtainStatus={this.getImageUploadingURL}/>
                                                </div>
                                            ))}
                                            <div>
                                                <div style={{
                                                    width: '110px',
                                                    height: '100px',
                                                    cursor: 'pointer',
                                                    border: '2px dotted #e5e5e5',
                                                    textAlign: 'center',
                                                    margin: '11px'
                                                }} onClick={this.addImage}>
                                                    <div style={{lineHeight: '6'}}>
                                                        <IconButton aria-label="Send"
                                                                    title="Add image"
                                                                    size="small"
                                                                    color={"primary"}>
                                                            <AddIcon/>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleClose} color="primary">
                                            cancel
                                        </Button>
                                        <Button
                                            disabled={creatingPost || !postingText || disable || postingText.trim().length === 0}
                                            onClick={() => this.createPost()}
                                            color="secondary" variant="contained">
                                            {creatingPost ? (<span>Posting &nbsp; &nbsp;<CircularProgress
                                                className={classes.progress} size={15}/></span>) : 'Post'}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </Paper>
                    </div>
                ) : (<div></div>)}
                <div>
                    {creatingPost ? (
                        <div><LinearProgress/></div>) : (
                        <span></span>)}
                </div>
                <div>
                    {!isLoadingAdminPost ? (
                        <div>
                            {adminPosts.length > 0 ? (
                                <div>
                                    <div id="postListItem">
                                        {adminPosts.map((post,index) => (
                                            <div>
                                                <ListItem alignItems="flex-start" className={classes.commentWrapper}
                                                          style={isMobile ? {
                                                              display: "inline-block", paddingTop: '1px',
                                                              paddingBottom: '1px'
                                                          } : {
                                                              display: "flex", paddingTop: '1px',
                                                              paddingBottom: '1px'
                                                          }}>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            alt={post.getPostOwner().getAccountName()}
                                                            style={isMobile ? {display: 'none'} : {
                                                                backgroundImage: `url('${post.getPostOwner().getAccountImage()}')`,
                                                                backgroundRepeat: 'round'
                                                            }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        className={classNames((post.getContentid() && (post.getPostOwner().getAccountId() === userInfo().user_id || isAdmin() || userRole === usedString.ADMIN)) ? getPostClass(get_date_obj(post.getContentid()).getTime(), groupMembers, allReactions[post.getContentid()] ? allReactions[post.getContentid()].getLikes() : 0.01, allReactions[post.getContentid()] ? allReactions[post.getContentid()].getTotalComments() : 0) : '', classes.listCover)}
                                                        primary={
                                                            <React.Fragment>
                                                                <div style={{
                                                                    display: 'inline-flex',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <Avatar
                                                                        alt={post.getPostOwner().getAccountName()}
                                                                        style={isMobile ? {
                                                                            backgroundImage: `url('${post.getPostOwner().getAccountImage()}')`,
                                                                            backgroundRepeat: 'round',
                                                                            width: '25px',
                                                                            height: '25px',
                                                                            marginRight: '8px'
                                                                        } : {display: 'none'}}
                                                                    />
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body1"
                                                                        style={{fontWeight: 600, lineHeight: 1.2}}
                                                                        className={classes.inline}
                                                                        color="textPrimary"
                                                                    >{post.getPostOwner().getAccountName()}
                                                                        <Link
                                                                            to={`${rootGroupsURl}/p/${post.getContentid()}`}>
                                                                            <div className={classes.timeUUID}><span className={classes.postTime}
                                                                                                                    title={get_date_obj(post.getContentid()).toLocaleString()}>{timeRemaining(get_date_obj(post.getContentid()))}</span> <span title={timeEvent(post.getLastUpdated())} className={classes.postTime}>{post.getEdited() ? '(edited)':null}</span>
                                                                            </div>
                                                                        </Link>
                                                                    </Typography>
                                                                </div>
                                                                {/*    menu Here*/}
                                                                <div style={{
                                                                    display: "inline-flex",
                                                                    float: 'right'
                                                                }}>
                                                                    {groupType === 'Public' ? (
                                                                            <div style={{display: "inline-flex"}}>
                                                                                <FacebookShareButton
                                                                                    url={window.location.origin + `${rootGroupsURl}/p/${post.getContentid()}`}
                                                                                    quote={post.getContent().getDataMap().get("TEXT")}
                                                                                    className="socialShareIcons">
                                                                                    <FacebookIcon
                                                                                        size={25}
                                                                                        round/>
                                                                                </FacebookShareButton>
                                                                                <TwitterShareButton
                                                                                    url={window.location.origin + `${rootGroupsURl}/p/${post.getContentid()}`}
                                                                                    title={post.getContent().getDataMap().get("TEXT")}
                                                                                    className="socialShareIcons">
                                                                                    <TwitterIcon
                                                                                        size={25}
                                                                                        round/>
                                                                                </TwitterShareButton>
                                                                            </div>) :
                                                                        null}
                                                                    {(post.getPostOwner().getAccountId() === userInfo().user_id || isAdmin() || userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT) ? (
                                                                        <UserPostMenus
                                                                            postText={post.getContent().getDataMap().get("TEXT")}
                                                                            ownerID={post.getPostOwner().getAccountId()}
                                                                            sendUserResponse={this.getUserResponseFromUserPost}
                                                                            postID={post.getContentid()}
                                                                            groupID={groupId}
                                                                            userRole={userRole}
                                                                            userPostData={post}
                                                                            indexValue={index}
                                                                            groupCategory={channelName === '_feed' ? post.getCategory() : channelName}
                                                                        />) : (<span>&nbsp;</span>)}
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                        secondary={
                                                            <React.Fragment>
                                                                {post.getContent().getDataMap().get("TEXT") ? (
                                                                    <Typography
                                                                        component="span"
                                                                        className={classes.textPost}
                                                                        color="textPrimary"
                                                                        style={{
                                                                            // fontWeight: 600,
                                                                            whiteSpace: 'pre-line'
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className={classes.postedText}>
                                                                            <RenderText
                                                                                text={post.getContent().getDataMap().get("TEXT")}/>
                                                                        </div>
                                                                    </Typography>
                                                                ) : (<div></div>)}
                                                                {postedImages[post.getContentid()] ? (
                                                                    <div
                                                                        className={classes.msgImageWrapper}>
                                                                        <Images
                                                                            images={postedImages[post.getContentid()]}
                                                                            resizedImages={resizedImages[post.getContentid()] ? resizedImages[post.getContentid()] : postedImages[post.getContentid()]}/>
                                                                    </div>
                                                                ) : (<div></div>)}
                                                                {/*Post Reaction and Comment*/}
                                                                <div>
                                                                    <div className={classes.userAction}>
                                                                        <IconButton aria-label="Like"
                                                                                    onClick={disableLike[post.getContentid()] ? '' : () => this.likePost(post.getContentid())}>
                                                                            {userLiked[post.getContentid()] ?
                                                                                <ThumbUp color="secondary"/> :
                                                                                <ThumbOutline/>}
                                                                        </IconButton>
                                                                        <span> {allReactions[post.getContentid()] && allReactions[post.getContentid()].getLikes() > 0 ? allReactions[post.getContentid()].getLikes() + (likeCount[post.getContentid()]) + addMe : ''} </span>
                                                                        <Link
                                                                            to={`${rootGroupsURl}/p/${post.getContentid()}`}>
                                                                            <IconButton

                                                                                aria-label="Message">
                                                                                <MessageIcon/>
                                                                            </IconButton>
                                                                        </Link>
                                                                        <span> {allReactions[post.getContentid()] && allReactions[post.getContentid()].getTotalComments() > 0 ? (allReactions[post.getContentid()].getTotalComments())+ addMe : ''} </span>
                                                                    </div>
                                                                    <div className={classes.likedUser}
                                                                         onClick={() => this.openLikedByDialogs(post.getContentid())}>
                                                                        {likedBy[post.getContentid()] ? (
                                                                            <ul className={classes.likedUnOrder}>
                                                                                {likedBy[post.getContentid()].map(liked => (
                                                                                    <li className={classes.likedList}>
                                                                                        <Avatar
                                                                                            alt={liked.getAccountName()}
                                                                                            key={liked.getAccountId()}
                                                                                            title={liked.getAccountName()}
                                                                                            style={{
                                                                                                backgroundImage: `url('${liked.getAccountImage() ? liked.getAccountImage().getImageUrl() : ''}')`,
                                                                                                backgroundRepeat: 'round'
                                                                                            }}
                                                                                            className={classes.likedUserImg}/>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        ) : (<div></div>)}

                                                                        {((allReactions[post.getContentid()] && allReactions[post.getContentid()].getLikes() > 0 ? allReactions[post.getContentid()].getLikes() + (likeCount[post.getContentid()]) + addMe : 0) - (likedBy[post.getContentid()] ? likedBy[post.getContentid()].length : 0)) > 0 ? (
                                                                            <Avatar
                                                                                className={classes.likedUserImg}
                                                                                style={{
                                                                                    margin: '1px',
                                                                                    marginLeft: '-7px'
                                                                                }}>
                                                                <span
                                                                    className={classes.morePeople}
                                                                    title="more likes">
                                                                    +{(allReactions[post.getContentid()] && allReactions[post.getContentid()].getLikes() > 0 ? allReactions[post.getContentid()].getLikes() + (likeCount[post.getContentid()]) + addMe : 0) - (likedBy[post.getContentid()] ? likedBy[post.getContentid()].length : 0)}
                                                                </span>
                                                                            </Avatar>
                                                                        ) : (<div></div>)}
                                                                    </div>
                                                                </div>
                                                                {channelName === '_feed' ? (
                                                                    <div className={classes.clearBoth}>
                                                                        <Typography
                                                                            color="textPrimary"
                                                                            style={{
                                                                                fontSize: '13px'
                                                                            }}
                                                                            variant="subtitle1"
                                                                        >
                                                                            {isLogin() && !post.getCategory().includes('post@') && (groupType === 'Public') || (userRoleStatus === usedString.APPROVED && groupType === 'Private' && userRole === usedString.MEMBER) || isAdmin() ? (
                                                                                <Link
                                                                                    to={`${rootGroupsURl}/channel/${removeLastSpace(post.getCategory())}`}
                                                                                    className={classes.channelName}>
                                                                                    #{post.getCategory() === '_admin' ? 'Admin' : post.getCategory()}
                                                                                </Link>
                                                                            ) : (<span className={classes.channelName}>
                                                                            #{post.getCategory() === '_admin' ? 'Admin' : post.getCategory()}
                                                                            </span>
                                                                            )}
                                                                        </Typography>
                                                                    </div>
                                                                ):null}
                                                                <br/>
                                                                {/*All User Comments*/}
                                                                <div className={classes.clearBoth}>
                                                                    {usrComments[post.getContentid()] || isViewed[post.getContentid] ? (
                                                                        <GetUserComments
                                                                            groupName={groupName}
                                                                            groupId={post.getGroupId()}
                                                                            commentPostId={post.getContentid()}
                                                                            userRole={userRole}
                                                                            userRoleStatus={userRoleStatus}
                                                                            totalComments={allReactions[post.getContentid()] && allReactions[post.getContentid()].getTotalComments() > 0 ? allReactions[post.getContentid()].getTotalComments() : ''}
                                                                            viewComments={(usrComments[post.getContentid()] && usrComments[post.getContentid()].length) > 0}
                                                                            postTitle={post.getContent().getDataMap().get("TEXT") ? shortenString(post.getContent().getDataMap().get("TEXT"), 25) : ''}
                                                                            sendResponse={this.getStatusFromChild}/>
                                                                    ) : (<div></div>)}

                                                                </div>
                                                                <div>
                                                                    {usrComments[post.getContentid()] ? (
                                                                        <div>
                                                                            {(usrComments[post.getContentid()].length > 0 && usrComments[post.getContentid()].length <= 3) ? (
                                                                                <Comments
                                                                                    groupId={groupId}
                                                                                    groupName={groupName}
                                                                                    userComments={usrComments[post.getContentid()]}
                                                                                    isMobile={isMobile}
                                                                                    rootGroupsURl={rootGroupsURl}
                                                                                    userRole={userRole}
                                                                                    userRoleStatus={userRoleStatus}
                                                                                    postTitle={post.getContent().getDataMap().get("TEXT") ? shortenString(post.getContent().getDataMap().get("TEXT"), 25) : ''}
                                                                                    postId={post.getContentid()}
                                                                                    sendResponse={this.getStatusFromChild}/>
                                                                            ) : (<div></div>)}
                                                                        </div>
                                                                    ) : (<div style={{textAlign: 'center'}}>
                                                                        <CircularProgress size={20}/></div>)}
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                                <div className={classes.postDivider}></div>
                                            </div>
                                        ))}
                                    </div>
                                    <br/>
                                    <br/>
                                    {loadingMore ? (
                                        <div style={{textAlign: 'center'}}><CircularProgress size={16}/> <span style={{
                                            fontSize: '17px',
                                            fontWeight: '600',
                                            margin: '5px'
                                        }}>More posts...</span></div>) : (<span></span>)}
                                </div>
                            ) : (<div></div>)}
                        </div>
                    ) : (<div>{PostLoader()}</div>)}
                </div>
                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
                <div>{openShowUsers ? (
                    <PostLikedUsers sendCloseReport={this.getResFrmChild} postId={sendPostId}/>) : (
                    <div></div>)}
                </div>
                <div>
                    {openLoginDialog ? (
                        <LoginRequiredDialog
                            openDialog={openLoginDialog} dialogHeader=""
                            postId="124"
                            dialogContent={"You are not logged in. Please login to continue and start posting to the community."}
                            button1="cancel"
                            button2="login"
                            sendUserResponse={this.getLoginDialogRes}
                        />
                    ) : (<div></div>)}
                </div>
                <div>
                    {openJoinGroupDialog ? (
                        <LoginRequiredDialog
                            openDialog={openJoinGroupDialog} dialogHeader=""
                            postId="124"
                            dialogContent={"You are not associated with '" + groupName + "' Please join the community to continue and start posting to the community."}
                            button1="cancel"
                            button2="view community"
                            sendUserResponse={this.getJoinGroupDialogRes}
                        />
                    ) : (<div></div>)}
                </div>
            </div>
        )
    }
}

GetPostedContent.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withWidth())(GetPostedContent);