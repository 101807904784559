import React, {Component} from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import {Badge, Typography, withStyles} from '@material-ui/core';
// Material icons
// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Chip from '@material-ui/core/Chip';

import styles from './styles';


import {
    GetAdminModeratorListRequest,
    GetMemberListRequest,
    GetPendingMembersRequest,
    UpdateFollowerStatusRequest
} from "../../../generated/frontend-community-members-service_grpc_web_pb";
import {getAllAdmins, getAllMembers, getAllPendingLists, updateUserStatus} from "../../../grpcRequests/members-request";
import {Status} from "../../../generated/frontend-social-common-types_pb";
import InviteDialog from "../../../components/inviteDialogs";
import AdminMemberMenus from "../../../components/AdminMemberMenus";
import {checkIfLogin, forceTokenRefresh, isAdmin, userInfo} from "../../../configurations/global-config";
import {GetGroupRequest} from "../../../generated/frontend-community-group-service_pb";
import {getGroupDetails} from "../../../grpcRequests/groups-request";
import usedString, {getRoleString, getStatusString} from "../../../utils/mapper/enumToStringMapper";
import SnackBar from "../../../components/SnackBar/SnackBar";
import {UserLoader} from "../../../components/Skeleton/EventsSket";
import AddAdmin from "../../../components/AddAdmin";
import GroupMemberMenus from "../../../components/GroupMemberMenus";
import PleaseWait from "../../../components/PleaseWait";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {eventAnalyst, pageViewAnalyst} from "../../../configurations/config";
import Avatar from "@material-ui/core/Avatar";
import {getUsrNotificationCount} from "../../../utils/converter/shortenString";
import {titleCase} from "../../../utils/converter/common";

let userList = [];

function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: '10px'}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const defaultPageSize = 50;

class Members extends Component {
    state = {
        value: 0,
        anchorEl: null,
        isLoadingGroupMembers: true,
        isLoadingAdmins: true,
        isLoadingPendingList: true,
        members: [],
        admins: [],
        pendingList: [],
        errMsg: null,
        userRole: '',
        userStatus: '',
        shareLink: '',

        title: '',

        // SNACKBAR
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null,

        adminCursor: '',
        adminPreviousCursor: '',
        adminLoadingMore: false,
        memberCursor: '',
        memberPreviousCursor: '',
        memberLoadingMore: false,
        pendingCursor: '',
        pendingPreviousCursor: '',
        pendingLoadingMore: false,
        pendingCount: '',
        requestedList: []
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        pageViewAnalyst('Community Member', this.props.location.pathname);
        checkIfLogin(this.props.location.pathname);
        this.getUserRole();
    }

    //Snackbar Calling function
    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };
    //

    getUserStatusFromChild = (userName, actionName, isActionCompleted) => {
        this.setState({snackOpen: false});
        let self = this;
        eventAnalyst('member', 'community_member_action', self.props.match.params.groupId + '-' + userName + '-' + actionName, isActionCompleted);
        if (isActionCompleted && actionName === 'remove') {
            self.snackbar(true, userName + ' is removed successfully', 'success', 5000);
            self.fetchGroupsMembers('', defaultPageSize, [], '');
            self.fetchGroupAdmins('', defaultPageSize, [], '');
            self.beforeContentUpdate();
        } else if (isActionCompleted && actionName === 'make admin') {
            self.snackbar(true, userName + ' is now admin', 'success', 5000);
            self.fetchGroupAdmins('', defaultPageSize, [], '');
            self.beforeContentUpdate();
        } else if (isActionCompleted && actionName === 'make moderator') {
            self.snackbar(true, userName + ' is now moderator', 'success', 5000);
            self.fetchGroupAdmins('', defaultPageSize, [], '');
            self.beforeContentUpdate();
        } else if (isActionCompleted && actionName === 'leave') {
            self.snackbar(true, userName + ' has left the community', 'success', 5000);
            window.location.href = "/c/" + self.props.match.params.groupId + forceTokenRefresh();
        } else {
            self.snackbar(true, 'An error occur while performing ' + actionName + ' action for ' + userName, 'error', 5000);
        }
    };

    beforeContentUpdate() {
        this.fetchGroupAdmins('', defaultPageSize, [], '');
        this.fetchPendingUsers('', defaultPageSize, [], '');
    }

    getUserRole = () => {
        let self = this;
        const req = new GetGroupRequest();
        req.setGroupId(this.props.match.params.groupId);
        getGroupDetails(req, 3).then(function (response) {
            const group = response.getGroup();
            const title = group.getName();
            const shareLink = group.getDirectJoinLink();
            const userRole = getRoleString(group.getRole());
            const userStatus = getStatusString(group.getStatus());
            self.setState({
                userStatus,
                userRole,
                shareLink, title
            });
            if ((userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT) && userStatus !== usedString.PENDING) {
                self.beforeContentUpdate();
            }
            if ((userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT || userRole === usedString.MEMBER) && userStatus !== usedString.PENDING){
                self.fetchGroupsMembers('', defaultPageSize, [], '');
            }else {
                self.setState({
                    isLoadingGroupMembers:false,
                });
            }
        }).catch(function (error) {

        })
    };

    fetchGroupsMembers = (cursorValue, pageSize, previousMembers, prevCursor) => {
        let self = this;
        const req = new GetMemberListRequest();
        req.setGroupId(this.props.match.params.groupId);
        req.setCursor(cursorValue);
        req.setPageSize(pageSize);
        getAllMembers(req, 3).then(function (response) {
            eventAnalyst('member', 'fetch_community_member', self.props.match.params.groupId + '-community user fetch success', 'community-user-fetch-success');
            let memberLists = previousMembers;
            for (let i = 0; i < response.getMembersList().length; i++) {
                let newMember = response.getMembersList()[i];
                memberLists.push(newMember);
            }
            const isLoadingGroupMembers = false;
            const cursorValue = response.getNextPageCursor();
            self.setState({
                isLoadingGroupMembers,
                members: memberLists,
                memberCursor: cursorValue,
                memberPreviousCursor: prevCursor,
                memberLoadingMore: false
            });
        }).catch(function (error) {
            eventAnalyst('member', 'fetch_community_member', self.props.match.params.groupId + '-community user fetch fail', 'community-user-fetch-fail');
            const errMsg = error.message;
            const isLoadingGroupMembers = false;
            self.setState({
                isLoadingGroupMembers,
                errMsg,
                memberLoadingMore: false
            });
        })
    };
    fetchGroupAdmins = (cursorValue, pageSize, previousAdmin, prevCursor) => {
        let self = this;
        const req = new GetAdminModeratorListRequest();
        req.setGroupId(this.props.match.params.groupId);
        req.setCursor(cursorValue);
        req.setPageSize(pageSize);
        getAllAdmins(req).then(function (response) {
            eventAnalyst('member', 'fetch_community_admin', self.props.match.params.groupId + '-community admin fetch success', 'community-admin-fetch-success');
            let adminLists = previousAdmin;
            for (let i = 0; i < response.getAdminlistList().length; i++) {
                let newAdmin = response.getAdminlistList()[i];
                adminLists.push(newAdmin);
            }
            const cursorValue = response.getNextPageCursor();
            const isLoadingAdmins = false;
            self.setState({
                isLoadingAdmins,
                admins: adminLists,
                adminCursor: cursorValue,
                adminPreviousCursor: prevCursor,
                adminLoadingMore: false
            });
        }).catch(function (error) {
            eventAnalyst('member', 'fetch_community_admin', self.props.match.params.groupId + '-community admin fetch fail', 'community-admin-fetch-fail');
            const errMsg = error.message;
            const isLoadingAdmins = false;
            self.setState({
                isLoadingAdmins,
                errMsg,
                adminLoadingMore: false
            });
        })
    };
    fetchPendingUsers = (cursorValue, pageSize, previousPending, prevCursor) => {
        let self = this;
        const req = new GetPendingMembersRequest();
        req.setAccountId(this.props.match.params.groupId);
        req.setCursor(cursorValue);
        req.setPageSize(pageSize);
        getAllPendingLists(req).then(function (response) {
            eventAnalyst('member', 'fetch_community_pending', self.props.match.params.groupId + '-community pending fetch success', 'community-pending-fetch-success');
            let pendingLists = previousPending;
            for (let i = 0; i < response.getPendingUsersList().length; i++) {
                let newPending = response.getPendingUsersList()[i];
                pendingLists.push(newPending);
            }
            const cursorValue = response.getNextPageCursor();
            const isLoadingPendingList = false;
            const allCount = response.getPendingUsersList().length;
            const pendingCount = getUsrNotificationCount(allCount);
            self.setState({
                isLoadingPendingList,
                pendingList: pendingLists,
                pendingCursor: cursorValue,
                pendingPreviousCursor: prevCursor,
                pendingLoadingMore: false,
                pendingCount
            });
        }).catch(function (error) {
            eventAnalyst('member', 'fetch_community_pending', self.props.match.params.groupId + '-community pending fetch fail', 'community-pending-fetch-fail');
            const errMsg = error.message;
            const isLoadingPendingList = false;
            self.setState({
                isLoadingPendingList,
                errMsg,
                pendingLoadingMore: false
            });
        })
    };

    handleChangeIndex = (event, value) => {
        this.setState({value});
    };

    updateUserReq = (accountName, accountType, accountID, statusType) => {
        this.setState({snackOpen: false});
        let self = this;
        let sts;
        if (statusType === usedString.APPROVED) {
            sts = Status.APPROVED;
        } else sts = Status.EMPTY;

        if (statusType === usedString.EMPTY) {
            statusType = "REJECTED"
        }
        userList.push(accountID);
        this.setState({
            requestedList: userList
        });
        const req = new UpdateFollowerStatusRequest();
        req.setAccountId(this.props.match.params.groupId);
        req.setFollowerType(accountType);
        req.setFollowerId(accountID);
        req.setStatus(sts);
        updateUserStatus(req).then(function (response) {
            self.snackbar(true, accountName + ' is ' + statusType, 'success', 5000);
            const pendingList = self.state.pendingList.filter(i => i.getAccountId() !== accountID);
            ;
            const pendingCount = getUsrNotificationCount(pendingList.length);
            const index = userList.indexOf(accountID);
            if (index > -1) {
                userList.splice(index, 1);
            }
            self.setState({pendingList, pendingCount, requestedList: userList});
        }).catch(function (error) {
            const index = userList.indexOf(accountID);
            if (index > -1) {
                userList.splice(index, 1);
            }
            self.setState({
                requestedList: userList
            });
            self.snackbar(true, accountName + ' Got Err ', 'error', 5000)
        })

    };

    loadMoreAdmin = (cursorValue, pageSize, previousAdmins, prevCursor) => {
        this.setState({adminLoadingMore: true});
        this.fetchGroupAdmins(cursorValue, pageSize, previousAdmins, prevCursor)
    };

    loadMoreMember = (cursorValue, pageSize, previousMembers, prevCursor) => {
        this.setState({memberLoadingMore: true});
        this.fetchGroupsMembers(cursorValue, pageSize, previousMembers, prevCursor)
    };

    loadMorePending = (cursorValue, pageSize, previousPendings, prevCursor) => {
        this.setState({pendingLoadingMore: true});
        this.fetchPendingUsers(cursorValue, pageSize, previousPendings, prevCursor)
    };


    render() {
        const {classes} = this.props;
        const {title, value, isLoadingGroupMembers, members, admins, isLoadingAdmins, isLoadingPendingList, pendingList, userRole, userStatus, shareLink, adminCursor, adminLoadingMore, adminPreviousCursor, memberCursor, memberLoadingMore, memberPreviousCursor, pendingCursor, pendingPreviousCursor, pendingLoadingMore, pendingCount, requestedList} = this.state;

        return (
            <DashboardLayout title={`${title} Members`} onBack={`/c/${this.props.match.params.groupId}`}
                             linkTo={`/c/${this.props.match.params.groupId}`}>
                <div className={classes.root}>
                    {!isLoadingGroupMembers ? (
                        <div>
                            {((userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT || userRole === usedString.MEMBER) && userStatus !== usedString.PENDING) ? (
                                <div>
                                    <div>
                                        <AppBar position="static" color="" className={classes.detailTabs}>
                                            <Tabs
                                                value={value}
                                                onChange={this.handleChangeIndex}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                variant="scrollable"
                                                scrollButtons="auto"
                                            >
                                                <Tab label="Members"
                                                     onClick={() => this.fetchGroupsMembers('', defaultPageSize, [], '')}/>
                                                {!isLoadingAdmins ? (
                                                    <Tab label="Admin"
                                                         onClick={() => this.fetchGroupAdmins('', defaultPageSize, [], '')}/>) : (
                                                    <span></span>)}
                                                {!isLoadingPendingList ? (
                                                    <Tab label={
                                                        pendingCount ?
                                                            (<Badge className={classes.badge} color="primary"
                                                                    badgeContent={pendingCount}><span>PENDING</span></Badge>) : 'PENDING'}
                                                         onClick={() => this.fetchPendingUsers('', defaultPageSize, [], '')}/>) : (
                                                    <span></span>)}
                                            </Tabs>
                                        </AppBar>
                                        {value === 0 &&
                                        <TabContainer style={{padding: 0}}>
                                            {!isLoadingGroupMembers ? (
                                                <div>
                                                    {(userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT || userRole === usedString.MEMBER) ? (
                                                        <div className={classes.detailHeader}>
                                                            <InviteDialog groupId={this.props.match.params.groupId}
                                                                          userRole={userRole}
                                                                          shareLink={shareLink}/>
                                                        </div>) : (<div></div>)}
                                                    <div>
                                                        <Typography
                                                            className={classes.productTimestamp}
                                                            variant="body2"
                                                        >
                                                            <span
                                                                title={` ${titleCase(userStatus)} ${titleCase(userRole)} of ${title}`}>You are currently {titleCase(userRole === usedString.PARTICIPANT ? usedString.MODERATOR : userRole)} of {title} community.</span>
                                                        </Typography>
                                                        <br/>
                                                        {members.length > 0 ? (
                                                            <div>
                                                                {members.map(member => (
                                                                    <div
                                                                        className={classes.product}
                                                                    >
                                                                        <div>
                                                                            <Avatar alt={member.getUsername()}
                                                                                    style={{
                                                                                        backgroundImage: `url('${member.getProfileImage()}')`,
                                                                                        backgroundRepeat: 'round'
                                                                                    }}
                                                                                    title={member.getUsername()}
                                                                                    className={classes.avatar}/>
                                                                        </div>
                                                                        <div className={classes.productDetails}>
                                                                            <Typography
                                                                                className={classes.productTitle}
                                                                                variant="h6"
                                                                                style={{fontWeight: 600}}
                                                                            >
                                                                                {member.getUsername()}
                                                                            </Typography>
                                                                            <Typography
                                                                                className={classes.productTimestamp}
                                                                                variant="body2"
                                                                            >
                                                                                {member.getEmail()}
                                                                            </Typography>
                                                                        </div>
                                                                        <div>
                                                                            {((userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT || member.getUserid() === userInfo().user_id ) && userStatus !== usedString.PENDING) ? (
                                                                                <GroupMemberMenus
                                                                                    userId={member.getUserid()}
                                                                                    userNAME={member.getUsername()}
                                                                                    sendCompletedResponse={this.getUserStatusFromChild}
                                                                                    groupId={this.props.match.params.groupId}/>
                                                                            ) : (<div></div>)}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>) : (
                                                            <div className={classes.noMemberWrapper}>No Member Yet. You
                                                                can
                                                                add members
                                                                by inviting your friends by sending them community URL.
                                                                Community
                                                                URL is
                                                                available by pressing button above.</div>)}
                                                        {memberCursor && memberPreviousCursor !== memberCursor ? (
                                                            <div>
                                                                <Button variant="contained" color='secondary'
                                                                        size="large" fullWidth
                                                                        style={{margin: '15px 0'}}
                                                                        disabled={memberLoadingMore}
                                                                        onClick={() => this.loadMoreMember(memberCursor, defaultPageSize, members, memberCursor)}>
                                                                    {memberLoadingMore ? (
                                                                        <span>Loading More&nbsp; &nbsp;<CircularProgress
                                                                            size={12}/> </span>) : 'Load More'}
                                                                </Button>
                                                            </div>
                                                        ) : (<span></span>)}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <UserLoader/>
                                                </div>
                                            )}
                                        </TabContainer>}
                                        {value === 1 &&
                                        <TabContainer>
                                            {!isLoadingAdmins ? (
                                                <div>
                                                    {userRole === usedString.ADMIN || isAdmin() ? (
                                                        <div className={classes.detailHeader}>
                                                            <AddAdmin groupId={this.props.match.params.groupId}/>
                                                        </div>
                                                    ) : null}
                                                    <div>
                                                        {admins.length > 0 ? (
                                                            <div>
                                                                {admins.map(admin => (
                                                                    <div>
                                                                        <div
                                                                            className={classes.product}
                                                                        >
                                                                            <div>
                                                                                <Avatar alt={admin.getUsername()}
                                                                                        style={{
                                                                                            backgroundImage: `url('${admin.getProfileImage()}')`,
                                                                                            backgroundRepeat: 'round'
                                                                                        }}
                                                                                        title={admin.getUsername()}
                                                                                        className={classes.avatar}/>
                                                                            </div>
                                                                            <div className={classes.productDetails}>
                                                                                <Typography
                                                                                    className={classes.productTitle}
                                                                                    variant="h6"
                                                                                    style={{fontWeight: 600}}
                                                                                >
                                                                                    {admin.getUsername()}
                                                                                </Typography>
                                                                                <Typography
                                                                                    className={classes.productTimestamp}
                                                                                    variant="body2"
                                                                                >
                                                                                    {admin.getEmail()} {getRoleString(admin.getRole()) === usedString.PARTICIPANT ? usedString.MODERATOR : getRoleString(admin.getRole())}
                                                                                </Typography>
                                                                            </div>
                                                                            <div>
                                                                                {((userRole === usedString.ADMIN || admin.getUserid() === userInfo().user_id) && userStatus !== usedString.PENDING) ? (
                                                                                    <AdminMemberMenus
                                                                                        userId={admin.getUserid()}
                                                                                        userNAME={admin.getUsername()}
                                                                                        userROLE={getRoleString(admin.getRole())}
                                                                                        myROLE={userRole}
                                                                                        sendCompletedResponse={this.getUserStatusFromChild}
                                                                                        groupId={this.props.match.params.groupId}/>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (<div className={classes.noMemberWrapper}>No Admins Yet. You
                                                            can
                                                            make community
                                                            members as admin by following the three dots in members
                                                            section.</div>)}
                                                    </div>
                                                    {adminCursor && adminPreviousCursor !== adminCursor ? (
                                                        <div>
                                                            <Button variant="contained" color='secondary' size="large"
                                                                    fullWidth
                                                                    style={{margin: '15px 0'}}
                                                                    disabled={adminLoadingMore}
                                                                    onClick={() => this.loadMoreAdmin(adminCursor, defaultPageSize, admins, adminCursor)}>
                                                                {adminLoadingMore ? (
                                                                    <span>Loading More&nbsp; &nbsp;<CircularProgress
                                                                        size={12}/> </span>) : 'Load More'}
                                                            </Button>
                                                        </div>
                                                    ) : (<span></span>)}
                                                </div>
                                            ) : (
                                                <div>
                                                    <UserLoader/>
                                                </div>
                                            )}
                                        </TabContainer>}
                                        {value === 2 &&
                                        <TabContainer>
                                            {!isLoadingPendingList ? (
                                                <div>
                                                    {pendingList.length > 0 ? (
                                                        <div>
                                                            {pendingList.map(pending => (
                                                                <div
                                                                    className={classes.product}
                                                                >
                                                                    <div>
                                                                        <Avatar alt={pending.getAccountName()}
                                                                                style={{
                                                                                    backgroundImage: `url('${pending.getAccountImage().getImageUrl()}')`,
                                                                                    backgroundRepeat: 'round'
                                                                                }}
                                                                                title={pending.getAccountName()}
                                                                                className={classes.avatar}/>
                                                                    </div>
                                                                    <div className={classes.productDetails}>
                                                                        <Typography
                                                                            className={classes.productTitle}
                                                                            variant="h5"
                                                                        >
                                                                            {pending.getAccountName()}
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.displayFlex}>
                                                                        <Chip
                                                                            label="Accept"
                                                                            clickable
                                                                            className={classes.chip}
                                                                            color="secondary"
                                                                            disabled={requestedList.length ? requestedList.includes(pending.getAccountId()) : false}
                                                                            onClick={() => this.updateUserReq(pending.getAccountName(), "PERSONAL", pending.getAccountId(), "APPROVED")}
                                                                            variant="outlined"
                                                                        />
                                                                        <Chip
                                                                            label="Reject"
                                                                            clickable
                                                                            className={classes.chip}
                                                                            color="primary"
                                                                            disabled={requestedList.length ? requestedList.includes(pending.getAccountId()) : false}
                                                                            onClick={() => this.updateUserReq(pending.getAccountName(), "PERSONAL", pending.getAccountId(), "EMPTY")}
                                                                            variant="outlined"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (<div className={classes.noMemberWrapper}>No Pending List. You
                                                        can see
                                                        pending members here when users request to join your
                                                        community.</div>)}

                                                    {pendingCursor && pendingPreviousCursor !== pendingCursor ? (
                                                        <div>
                                                            <Button variant="contained" color='secondary' size="large"
                                                                    fullWidth
                                                                    style={{margin: '15px 0'}}
                                                                    disabled={pendingLoadingMore}
                                                                    onClick={() => this.loadMorePending(pendingCursor, defaultPageSize, pendingList, pendingCursor)}>
                                                                {pendingLoadingMore ? (
                                                                    <span>Loading More&nbsp; &nbsp;<CircularProgress
                                                                        size={12}/> </span>) : 'Load More Users'}
                                                            </Button>
                                                        </div>
                                                    ) : (<span></span>)}
                                                </div>
                                            ) : (
                                                <div>
                                                    <UserLoader/>
                                                </div>
                                            )}
                                        </TabContainer>}
                                    </div>
                                    {/*Snack BAr Show*/}
                                    <div>{this.state.snackOpen ? (
                                        <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                                                  snackCOLOR={this.state.snackColor}
                                                  snackTIMEOUT={this.state.snackTimeOut}/>
                                    ) : (<span></span>)}
                                    </div>
                                </div>
                            ) : (<span>You are not authorized.</span>)}
                        </div>
                    ) : (<div>
                        <PleaseWait msg="Loading community members... "/>
                    </div>)}
                </div>
            </DashboardLayout>
        );
    }
}

Members.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Members);
