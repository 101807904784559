import React, {Component} from "react";
import PropTypes from "prop-types";
import {Typography, withStyles, withWidth} from "@material-ui/core";
import styles from "./styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import {checkIfLogin, isAdmin, isLogin, userInfo, userWhoCanPost} from "../../configurations/global-config";
import InputBase from "@material-ui/core/InputBase";
import {Send as SendIcon} from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import usedString from "../../utils/mapper/enumToStringMapper";
import {timeRemaining} from "../../utils/converter/dateConverter";
import UserReplyMenus from "../UserReplyMenus";
import {ListCommentRepliesRequest, ReplyOnCommentRequest} from "../../generated/frontend-reply-service_pb";
import {createReplyOnComment, getRepliesOfComment} from "../../grpcRequests/reply-request";
import {MessageType, ReactionType, ReplyMessage} from "../../generated/frontend-social-common-types_pb";
import {GetCommentRequest, ReactOnCommentRequest} from "../../generated/frontend-comment-service_pb";
import {getCommentDetails, reactOnCommentedPost} from "../../grpcRequests/comments-request";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackBar from "../SnackBar/SnackBar";
import compose from "recompose/compose";
import PostLikedUsers from "../Dialogs/PostLikedUsers";
import LikeReply from "./LikeReply";
import LoginRequiredDialog from "../Dialogs/LoginRequiredDialog";
import RenderText from "../../pages/Groups/component/RenderText";


const defaultPageSize = 5;
const disableLike = [];
const userLiked = [];
const likeCount = [];
const userReply = [];

class UserReply extends Component {
    state = {
        userReplyText: [],
        openReplyBox: [],
        allReplies: [],
        commentId: this.props.commentId,
        commentPostId: this.props.commentPostId,
        addMe: 0,
        disable: [],
        multiline: [],
        isLoadingReplies: true,
        loading: false,
        cursor: '',
        previousCursor: '',
        openShowUsers: false, sendCommentId: '',

        //Snackbar initial State
        snackOpen: false,
        snackMsg: null,
        snackColor: null,
        snackTimeOut: null,

        //Login
        openLoginDialog: false,
        openJoinGroupDialog: false,
    };

    componentDidMount() {
        userLiked[this.props.commentId] = this.props.userLikeSts;
        likeCount[this.props.commentId] = this.props.commentLikes;
        userReply[this.props.commentId] = this.props.commentReplies;
        this.setState({
            addMe: 0,
            allReplies: []
        });
        if (this.props.openReply) {
            this.setState({
                openReplyBox: {...this.state.openReplyBox, [this.props.commentId]: true},
            });
            this.viewReply(this.props.commentId, '', 5, [], '')
        }
    }

    openCommentBox = (commentId) => {
        this.setState({
            openReplyBox: {...this.state.openReplyBox, [commentId]: true},
        });
        this.viewReply(commentId, '', defaultPageSize, [], '')
    };

    snackbar = (open, msg, color, timeOut) => {
        this.setState({
            snackOpen: open,
            snackMsg: msg,
            snackColor: color,
            snackTimeOut: timeOut
        });
    };

    openLoginDialog = () => {
        this.setState({openLoginDialog: true});
    };

    getJoinGroupDialogRes = (clickedButton, key) => {
        this.setState({openJoinGroupDialog: false});
        if (clickedButton === "view community") {
            // console.log('clicked join');
            window.location.pathname = '/c/' + this.props.groupId + '/'
        }
    };

    openJoinDialog = () => {
        this.setState({openJoinGroupDialog: true});
    };

    submitReply = (commentID) => {
        if (!isLogin()) {
            this.openLoginDialog()
        } else if (!userWhoCanPost(this.props.userRole, this.props.userRoleStatus)) {
            this.openJoinDialog()
        } else {
            let self = this;
            self.setState({disable: {...self.state.disable, [commentID]: true}, snackOpen: false});
            const req = new ReplyOnCommentRequest();
            const replyObj = new ReplyMessage();
            replyObj.setText(this.state.userReplyText[commentID]);

            req.setPostUri(this.props.commentPostId);
            req.setCommentId(commentID);
            req.setReplyType(MessageType.TEXT);
            req.setReply(replyObj);
            //req.setBusinessAccountInfo(accountInfo); // only used when the user is logged in to business account
            createReplyOnComment(req).then(function (response) {
                self.setState({
                    userReplyText: {...self.state.userReplyText, [commentID]: ''},
                    disable: {...self.state.disable, [commentID]: false},
                    multiline: {...self.state.multiline, [commentID]: true},
                });
                self.viewReply(commentID, '', defaultPageSize, [], '');
                self.getCommentDetails(commentID);
            }).catch(function (error) {
                self.setState({
                    disable: {...self.state.disable, [commentID]: false},
                    multiline: {...self.state.multiline, [commentID]: true},
                });
            })
        }
    };


    viewReply = (commentID, pageToken, pageSize, previousReplies, prevCursor) => {
        if (!isLogin() && prevCursor) {
            this.openLoginDialog()
        } else {
            let self = this;
            self.setState({
                loading: true,
            });
            const req = new ListCommentRepliesRequest();
            req.setPostUri(this.props.commentPostId);
            req.setCommentId(commentID);
            req.setPageSize(pageSize);
            req.setPageToken(pageToken);

            getRepliesOfComment(req, 3).then(function (response) {
                const cursor = response.getNextPageToken();
                let replies = previousReplies;
                for (let i = 0; i < response.getRepliesList().length; i++) {
                    let newReply = response.getRepliesList()[i];
                    replies.push(newReply);
                }
                const isLoadingReplies = false;
                const loading = false;
                self.setState({
                    allReplies: replies,
                    previousCursor: prevCursor,
                    cursor,
                    isLoadingReplies,
                    loading
                });
            }).catch(function (error) {
                const loading = false;
                self.setState({
                    loading
                });
            })
        }
    };


    likeComment = (commentID) => {
        if (!isLogin()) {
            this.openLoginDialog()
        } else if (!userWhoCanPost(this.props.userRole, this.props.userRoleStatus)) {
            this.openJoinDialog()
        } else {
            let self = this;
            disableLike[commentID] = true;
            const req = new ReactOnCommentRequest();

            req.setPostUri(this.props.commentPostId);
            req.setCommentId(commentID);
            // req.setAccountId();
            self.setState({addMe: 0});
            if (userLiked[commentID]) {
                userLiked[commentID] = false;
                likeCount[commentID] -= 1;
                req.setReactionType(ReactionType.UNLIKE);
            } else {
                userLiked[commentID] = true;
                likeCount[commentID] += 1;
                req.setReactionType(ReactionType.LIKE);
            }
            reactOnCommentedPost(req).then(function (response) {
                disableLike[commentID] = false;
                self.getCommentDetails(commentID);
            }).catch(function (error) {
                disableLike[commentID] = false;
            })
        }
    };

    getUserResponseFromUserReply = (actionName, isActionCompleted, commentId) => {
        this.setState({snackOpen: false});
        let self = this;
        if (isActionCompleted && actionName === 'delete') {
            self.snackbar(true, 'Reply deleted Successfully', 'success', 5000);
            // self.sendAction('delete', postId);
            self.setState({
                openReplyBox: {...this.state.openReplyBox, [commentId]: true}
            });
            self.getCommentDetails(commentId);
            self.viewReply(commentId, '', defaultPageSize, [], '');
        } else {
            self.snackbar(true, 'Error while deleting reply', 'error', 5000);
        }
    };

    getCommentDetails = (commentID) => {
        let self = this;
        const req = new GetCommentRequest();
        req.setPostUri(this.props.commentPostId);
        req.setCommentId(commentID);

        getCommentDetails(req, 3).then(function (response) {
            let responseMsg = response.getCommentdetail().getComment();
            userLiked[commentID] = responseMsg.getLiked();
            likeCount[commentID] = responseMsg.getLikes();
            userReply[commentID] = responseMsg.getReplies();
            self.setState({
                addme: 0
            })
        }).catch(function (error) {

        })
    };

    commentReplyChange = (commentId, event) => {
        this.setState({
            userReplyText: {...this.state.userReplyText, [commentId]: event.target.value}
        });
    };

    getLoginDialogRes = (clickedButton, key) => {
        this.setState({openLoginDialog: false});
        if (clickedButton === "login") {
            checkIfLogin(window.location.pathname);
        }
    };

    getResFrmChild = (status) => {
        this.setState({openShowUsers: false});
    };

    openLikedByDialogs = (commentId) => {
        this.setState({openShowUsers: true, sendCommentId: commentId});
    };

    handleKeyPress = (commentId, event) => {
        if (!isLogin()) {
            this.openLoginDialog()
        } else if (!userWhoCanPost(this.props.userRole, this.props.userRoleStatus)) {
            this.openJoinDialog()
        } else {
            if (!event.shiftKey && event.key === 'Enter') {
                this.setState({
                    multiline: {...this.state.multiline, [commentId]: false},
                });
                if (this.state.userReplyText[commentId] && this.state.userReplyText[commentId].trim().length !== 0) {
                    this.submitReply(commentId);
                }
            }
        }
    };

    render() {
        const {classes, commentId, commentPostId, commentLikes, commentReplies, userLikeSts, width, groupName, userRole, groupId, userRoleStatus} = this.props;
        const {openShowUsers, sendCommentId, openReplyBox, allReplies, userReplyText, addMe, disable, cursor, loading, previousCursor, isLoadingReplies, multiline, snackColor, snackMsg, snackOpen, snackTimeOut, openLoginDialog, openJoinGroupDialog} = this.state;
        const isMobile = ['xs', 'sm', 'md'].includes(width);
        return (
            <div>
                <div className={classes.userAction}>
                    <Typography
                        className={classes.commentClass}
                        component="span"
                        color="textPrimary"
                        variant={"body2"}
                        style={userLiked[commentId] ? {
                            fontWeight: 600,
                            color: '#4ea3fe',
                            padding: '0',
                            margin: '0'
                        } : {color: '#4ea3fe', padding: '0', margin: '0'}}
                        onClick={disableLike[commentId] ? '' : () => this.likeComment(commentId)}>
                        {userLiked[commentId] ? 'Liked' : 'Like'}
                    </Typography>
                    <span
                        style={{padding: '0 5px'}}> {likeCount[commentId] && likeCount[commentId] > 0 ? likeCount[commentId] + addMe : ''} </span>

                    <Typography
                        className={classes.commentClass}
                        component="span"
                        color="textPrimary"
                        variant={"body2"}
                        style={{color: '#4ea3fe', padding: '0', margin: '0'}}
                        onClick={() => this.openCommentBox(commentId)}
                    >
                        Reply
                    </Typography>
                    <span
                        style={{padding: '0 5px'}}>{userReply[commentId] && userReply[commentId] > 0 ? userReply [commentId] + addMe : ''} </span>
                </div>
                <div className={classes.likedUser}
                     onClick={() => this.openLikedByDialogs(commentId)}>
                    {likeCount[commentId] && likeCount[commentId] > 0 ? (
                        <Avatar
                            className={classes.likedUserImg}
                            style={{
                                margin: '1px',
                                marginLeft: '-7px'
                            }}>
                                                                <span
                                                                    className={classes.morePeople}
                                                                    title="more likes">
                                                                    +{likeCount[commentId] + addMe}
                                                                </span>
                        </Avatar>
                    ) : (<div></div>)}
                </div>
                <div className={classes.clearBoth}>
                    {openReplyBox[commentId] ? (
                        <div>
                            <Paper className={classes.chatBox}>
                                <IconButton
                                    size="small"
                                    className={classes.floatLeft}
                                    aria-label="Menu">
                                    <Avatar
                                        alt={userInfo().displayName}
                                        className={classes.chatBoxAvatar}
                                        style={
                                            isMobile ?
                                                {
                                                    backgroundImage: `url('${userInfo().photo_url}')`,
                                                    backgroundRepeat: 'round',
                                                    width: '20px',
                                                    height: '20px',
                                                } : {
                                                    backgroundImage: `url('${userInfo().photo_url}')`,
                                                    backgroundRepeat: 'round',
                                                    width: '25px',
                                                    height: '25px'
                                                }
                                        }/>
                                </IconButton>
                                <InputBase
                                    disabled={disable[commentId]}
                                    className={classes.postContent}
                                    placeholder="Add Reply"
                                    value={userReplyText[commentId]}
                                    multiline={true}
                                    inputProps={{'aria-label': 'Add Reply'}}
                                    onChange={this.commentReplyChange.bind(this, commentId)}
                                    onKeyPress={this.handleKeyPress.bind(this, commentId)}
                                />
                                <div className={classes.iconWrapper}>
                                    {userReplyText[commentId] ? (
                                        <IconButton
                                            size="small"
                                            disabled={disable[commentId] || userReplyText[commentId].trim().length === 0}
                                            className={classes.floatRight}
                                            onClick={() => this.submitReply(commentId)}
                                            aria-label="Send">
                                            <SendIcon/>
                                        </IconButton>
                                    ) : (<span></span>)}
                                </div>
                            </Paper>
                            {loading ? (
                                <div className={classes.timeUUID} style={{margin: '10px 5px'}}>

                                    <span>  <span>Loading Replies</span> <CircularProgress className={classes.progress}
                                                                                           size={12}/> </span>
                                </div>
                            ) : null}
                            <div style={{borderLeft: '1px solid #e5e5e5', marginTop: '8px'}}>
                                {allReplies.length > 0 && allReplies.map(reply => (
                                    <ListItem alignItems="flex-start" className={classes.commentWrapper}
                                              style={isMobile ? {
                                                  display: "inline-block", paddingTop: '1px',
                                                  paddingBottom: '1px'
                                              } : {
                                                  display: "flex", paddingTop: '1px',
                                                  paddingBottom: '1px'
                                              }}>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={reply.getAccountInfo().getAccountName()}
                                                style={isMobile ? {display: 'none'} :
                                                    {
                                                        backgroundImage: `url('${reply.getAccountInfo().getAccountImage() ? reply.getAccountInfo().getAccountImage().getImageUrl() : ''}')`,
                                                        width: '25px',
                                                        height: '25px',
                                                        backgroundRepeat: 'round', margin: 'auto', marginTop: '5px'
                                                    }}
                                                className={classes.chatBoxAvatar}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            className={classes.listCoverComment}
                                            primary={
                                                <React.Fragment>
                                                    <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                                        <Avatar
                                                            alt={reply.getAccountInfo().getAccountName()}
                                                            style={
                                                                isMobile ?
                                                                    {
                                                                        backgroundImage: `url('${reply.getAccountInfo().getAccountImage() ? reply.getAccountInfo().getAccountImage().getImageUrl() : ''}')`,
                                                                        backgroundRepeat: 'round',
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        marginRight: '8px'
                                                                    } : {display: 'none'}}/>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            className={classes.inline}
                                                            color="textPrimary"
                                                            style={isMobile ? {
                                                                fontWeight: 600,
                                                                lineHeight: 1.2,
                                                                fontSize: '12px'
                                                            } : {fontWeight: 600, lineHeight: 1.2}}
                                                        >
                                                            {reply.getAccountInfo().getAccountName()}
                                                            <div className={classes.timeUUID}><span
                                                                title={new Date(reply.getReplyTimestamp()).toLocaleString()}>{timeRemaining(new Date(reply.getReplyTimestamp()))}</span>
                                                            </div>
                                                        </Typography>
                                                    </div>
                                                    {reply.getAccountInfo().getAccountId() === userInfo().user_id || isAdmin() || userRole === usedString.ADMIN || userRole === usedString.PARTICIPANT ? (
                                                        <UserReplyMenus commentID={commentId}
                                                                        postID={commentPostId}
                                                                        replyID={reply.getReplyId()}
                                                                        replyText={reply.getReply().getText()}
                                                                        ownerID={reply.getAccountInfo().getAccountId()}
                                                                        userRole={userRole}
                                                                        userRoleStatus={userRoleStatus}
                                                                        sendUserResponse={this.getUserResponseFromUserReply}
                                                        />) : (<span>&nbsp;</span>)}
                                                </React.Fragment>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        component="div"
                                                        className={classes.commentPost}
                                                        color="textPrimary"
                                                        variant={"body2"}
                                                        style={{whiteSpace: 'pre-line', lineHeight: '1.4'}}
                                                    >
                                                        <RenderText text={reply.getReply().getText()}/>
                                                    </Typography>
                                                    <div>
                                                        <LikeReply groupId={groupId}
                                                                   groupName={groupName}
                                                                   commentId={reply.getCommentId()}
                                                                   commentPostId={reply.getPostUri()}
                                                                   replyId={reply.getReplyId()}
                                                                   commentLikes={reply.getLikes()}
                                                                   userRole={userRole}
                                                                   userRoleStatus={userRoleStatus}
                                                                   userLikeSts={reply.getLiked()}/>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </div>
                            {cursor && previousCursor !== cursor ? (
                                <div className={classes.commentClass}
                                     onClick={() => this.viewReply(commentId, cursor, defaultPageSize, allReplies, cursor)}>
                                    <span>Load More Replies</span>
                                    {loading ? (
                                        <CircularProgress className={classes.progress} size={12}/>
                                    ) : (<span></span>)}
                                </div>
                            ) : (<span></span>)}
                        </div>
                    ) : null}

                </div>
                {/*Snack BAr Show*/}
                <div>{this.state.snackOpen ? (
                    <SnackBar snackOPEN={this.state.snackOpen} snackMSG={this.state.snackMsg}
                              snackCOLOR={this.state.snackColor} snackTIMEOUT={this.state.snackTimeOut}/>
                ) : (<span></span>)}
                </div>
                <div>{openShowUsers ? (
                    <PostLikedUsers sendCloseReport={this.getResFrmChild} postId={sendCommentId}/>) : (
                    <div></div>)}
                </div>

                <div>
                    {openLoginDialog ? (
                        <LoginRequiredDialog
                            openDialog={openLoginDialog} dialogHeader=""
                            postId="124"
                            dialogContent={"Please login to continue"} button1="cancel"
                            button2="login"
                            sendUserResponse={this.getLoginDialogRes}
                        />
                    ) : (<div></div>)}
                </div>
                <div>
                    {openJoinGroupDialog ? (
                        <LoginRequiredDialog
                            openDialog={openJoinGroupDialog} dialogHeader=""
                            postId="124"
                            dialogContent={"You are not associated with '"+groupName+"'. Please join the community to continue and start posting to the community."}
                            button1="cancel"
                            button2="view community"
                            sendUserResponse={this.getJoinGroupDialogRes}
                        />
                    ) : (<div></div>)}
                </div>
            </div>
        )
    }
}

UserReply.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withWidth())(UserReply);